// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ddpNH12hIzYVCBU25g1ANg\\=\\={width:100%}.ddpNH12hIzYVCBU25g1ANg\\=\\=>:first-child{margin:2em 6em;border-radius:0.25em}.WNY3wLBh6uTxM1KkIgI7yQ\\=\\=:hover{cursor:pointer}.bW9e3zbbE7KktEYw96fzLA\\=\\={text-align:center}\n", "",{"version":3,"sources":["webpack://./../OrganisationList.scss"],"names":[],"mappings":"AAMA,4BACI,UAAW,CADf,yCAGQ,cAAe,CACf,oBAAqB,CACxB,kCAGD,cAAe,CAClB,4BAEG,iBAAkB","sourcesContent":["/**\n * OrganisationList styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    width: 100%;\n    & > :first-child {\n        margin: 2em 6em;\n        border-radius: 0.25em;\n    }\n}\n.item:hover {\n    cursor: pointer;\n}\n.spinner {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ddpNH12hIzYVCBU25g1ANg==",
	"item": "WNY3wLBh6uTxM1KkIgI7yQ==",
	"spinner": "bW9e3zbbE7KktEYw96fzLA=="
};
export default ___CSS_LOADER_EXPORT___;
