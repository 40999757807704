// @flow

import styles from "./AddRecruit.scss";

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Search from "@material-ui/icons/Search";

import {
    type State as MembersState,
    type Member,
} from "../../../redux/modules/members.d";
import { type State as AsyncState } from "../../../redux/modules/event-recruits-add.d";

import AddRecruitLine from "./AddRecruitLine";

type Props = {
    id: string,
    translate: *,
    asyncState: AsyncState,
    members: MembersState,
    load: (filter: string) => *,
    onSubmit: (uuids: string[]) => Promise<void>,
    onClose: () => *,
    excluded: string[],
    disabled: boolean,
};

type State = {
    filter: string,
    selected: string[],
};

/**
 * Add Recruit Dialog
 */
export default class AddRecruit extends Component<Props, State> {
    // Default props
    static defaultProps = {
        disabled: false,
        excluded: [],
    };

    // Initial state
    state = {
        filter: "",
        selected: [],
    };

    /**
     * Get the disabled state
     */
    get disabled() {
        const { asyncState, disabled } = this.props;

        return disabled || asyncState.loading;
    }

    /**
     * Test if can submit
     */
    get canSubmit() {
        const { selected } = this.state;

        if (this.disabled) {
            return false;
        }

        return !!selected.length;
    }

    /**
     * Get the item list
     */
    get members(): Member[] {
        const { members, excluded } = this.props;

        if (!members.data) {
            return [];
        }

        return members.data.content.filter(
            member => !excluded.includes(member.uuid),
        );
    }

    /**
     * Clear the list on component mount and do initial load
     */
    componentDidMount() {
        this.load();
    }

    /**
     * Toggle handler
     */
    handleToggle(uuid: string) {
        const { selected } = this.state;

        if (selected.includes(uuid)) {
            this.setState({
                selected: selected.filter(active => active !== uuid),
            });
        } else {
            this.setState({
                selected: selected.concat([uuid]),
            });
        }
    }

    /**
     * Submit handler
     */
    handleSubmit(event: *) {
        event.preventDefault();

        const { onSubmit, onClose } = this.props;
        const { selected } = this.state;

        onSubmit(selected).then(() => onClose());
    }

    /**
     * Load the data
     */
    load() {
        const { load } = this.props;
        const { filter } = this.state;

        load(filter);
    }

    /**
     * Render
     */
    render() {
        const { id, translate, onClose, members } = this.props;
        const { filter, selected } = this.state;

        return (
            <Dialog onClose={onClose} open>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <DialogTitle disableTypography>
                        <Typography variant="h2">
                            {translate("Add recruit(s)")}
                        </Typography>
                        <Typography>
                            {translate(
                                "Add one or more members from your organisation to this recruitment group.",
                            )}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            id={`${id}-filter`}
                            disabled={this.disabled}
                            value={filter}
                            onChange={({ target: { value } }) =>
                                this.setState(
                                    {
                                        filter: value,
                                    },
                                    () => this.load(),
                                )
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={translate(
                                "Filter by name or phone number",
                            )}
                            autoFocus
                            fullWidth
                        />
                        {!members.data && members.loading && (
                            <div className={styles.loading}>
                                <CircularProgress />
                            </div>
                        )}
                        {members.data && (
                            <List>
                                {this.members.map((member, index) => (
                                    <AddRecruitLine
                                        translate={translate}
                                        key={member.uuid}
                                        id={`${id}-member-${index}`}
                                        disabled={this.disabled}
                                        member={member}
                                        selected={selected.includes(
                                            member.uuid,
                                        )}
                                        onToggle={() =>
                                            this.handleToggle(member.uuid)
                                        }
                                    />
                                ))}
                            </List>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            id={`${id}-cancel`}
                            onClick={onClose}
                            disabled={this.disabled}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id={`${id}-submit`}
                            type="submit"
                            disabled={!this.canSubmit}
                            color="primary"
                        >
                            {translate("Add")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
