// @flow

import moment from "moment";
import React from "react";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";

import { DatePicker } from "@material-ui/pickers";

type Props = {
    value: ?string,
    onChange: (value: string) => void,
    earliest?: ?string,
    allowPast: boolean,
};

const shouldDisableDate =
    (earliest = moment.utc().toISOString()) =>
    date =>
        date.isBefore(earliest, "day");

/**
 * DateField with datepicker
 */
const DateField = ({
    value,
    onChange,
    earliest,
    allowPast,
    ...rest
}: Props) => (
    <DatePicker
        {...rest}
        autoOk
        leftArrowIcon={<LeftIcon />}
        rightArrowIcon={<RightIcon />}
        format={"DD/MM/YYYY"}
        value={value}
        onChange={value => onChange(value)}
        shouldDisableDate={
            allowPast ? () => false : shouldDisableDate(earliest)
        }
    />
);

export default DateField;
