// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SPexAnaBJtekyFF\\+JtkLAw\\=\\={display:flex;flex-wrap:wrap;justify-content:flex-end;position:-webkit-sticky;position:sticky;width:100%;bottom:0;pointer-events:none;padding:0.5em;box-sizing:border-box}.SPexAnaBJtekyFF\\+JtkLAw\\=\\=>button{margin:0.5em;pointer-events:auto}.SPexAnaBJtekyFF\\+JtkLAw\\=\\=.yhKN-b31WxmR4iHXGxvsPA\\=\\={position:relative}.SPexAnaBJtekyFF\\+JtkLAw\\=\\=.bkvzgVmtjP0ebFexpXUXGA\\=\\={justify-content:center}\n", "",{"version":3,"sources":["webpack://./../FabContainer.scss"],"names":[],"mappings":"AAAA,6BACI,YAAa,CACb,cAAe,CACf,wBAAyB,CACzB,uBAAgB,CAAhB,eAAgB,CAChB,UAAW,CACX,QAAS,CACT,mBAAoB,CACpB,aAAc,CACd,qBAAsB,CAT1B,oCAYQ,YAAa,CACb,mBAAoB,CAb5B,wDAiBQ,iBAAkB,CAjB1B,wDAqBQ,sBAAuB","sourcesContent":[".container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-end;\n    position: sticky;\n    width: 100%;\n    bottom: 0;\n    pointer-events: none;\n    padding: 0.5em;\n    box-sizing: border-box;\n\n    > button {\n        margin: 0.5em;\n        pointer-events: auto;\n    }\n\n    &.no-float {\n        position: relative;\n    }\n\n    &.center {\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SPexAnaBJtekyFF+JtkLAw==",
	"no-float": "yhKN-b31WxmR4iHXGxvsPA==",
	"center": "bkvzgVmtjP0ebFexpXUXGA=="
};
export default ___CSS_LOADER_EXPORT___;
