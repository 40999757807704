// @flow

import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { format as formatPhone } from "../../../utils/phonenumber";

import { type Member } from "../../../redux/modules/members.d";

type Props = {
    id: string,
    translate: *,
    disabled: boolean,
    member: Member,
    selected: boolean,
    onToggle: () => void,
};

const SELECTABLE = ["ACCEPTED"];

/**
 * Line component
 */
const AddRecruitLine = ({
    id,
    member,
    selected,
    onToggle,
    disabled,
    translate,
}: Props) => (
    <ListItem
        key={member.uuid}
        role={undefined}
        dense
        button
        onClick={onToggle}
        disabled={disabled || !SELECTABLE.includes(member.inviteStatus)}
    >
        <Checkbox checked={selected} tabIndex={-1} disableRipple />
        <ListItemText
            id={id}
            primary={`${
                ((!member.firstName || !member.lastName) &&
                    `${member.description}`) ||
                `${member.firstName} ${member.lastName}`
            } ${
                (member.inviteStatus == "INVITED" &&
                    ` - (${translate("Invited")})`) ||
                ""
            }`}
            secondary={formatPhone(member.phoneNumber, "International")}
        />
    </ListItem>
);

AddRecruitLine.defaultProos = {
    disabled: false,
};

export default AddRecruitLine;
