//@flow
import createValueModule from "../create-value-module";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";

const LOCALE_KEY = "ays.locale";
const LANGUAGES = ["en", "nl", "fr"];

/**
 * Fetch the locale stored in the local store
 */
const getStoredLanguage = () => {
    if (!localStorage) {
        return;
    }

    return localStorage.getItem(LOCALE_KEY);
};

/**
 * Determine the initial language that is stored.
 */
const getInitialLanguage = () => {
    const stored = getStoredLanguage();

    if (stored) {
        return stored;
    }

    const browserLanguage = ((navigator && navigator.language) || "").slice(
        0,
        2,
    );

    if (LANGUAGES.includes(browserLanguage)) {
        return browserLanguage;
    }

    return LANGUAGES[0];
};

const initialLanguage = getInitialLanguage();

localStorage.setItem(LOCALE_KEY, initialLanguage);
moment.locale(initialLanguage);

const { reducer, actions } = createValueModule("language", initialLanguage);

export const set = (language: string) => (dispatch: *) => {
    dispatch(actions.set(language));
    localStorage.setItem(LOCALE_KEY, language);
    moment.locale(language);
};
export const clear = actions.clear;
export const update = actions.update;
export default reducer;
