// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X0FRe6Nn5iKg77pHBuZyMw\\=\\= :first-child>div{display:flex}.X0FRe6Nn5iKg77pHBuZyMw\\=\\= :first-child>div span{align-self:center}\n", "",{"version":3,"sources":["webpack://./../SelectStatus.scss"],"names":[],"mappings":"AAAA,6CAGY,YAAa,CAHzB,kDAKgB,iBAAkB","sourcesContent":[".element {\n    & :first-child {\n        & > div {\n            display: flex;\n            span {\n                align-self: center;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "X0FRe6Nn5iKg77pHBuZyMw=="
};
export default ___CSS_LOADER_EXPORT___;
