// @flow

import styles from "./Pools.scss";

import moment from "moment";
import classnames from "classnames";
import React from "react";

import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Add from "@material-ui/icons/AddCircle";

import { type Pool as PoolType } from "../../../redux/modules/event-recruitment.d";
import { type State as DeletePoolState } from "../../../redux/modules/event-recruitment-delete.d";

import IconMenu from "../../IconMenu";
import FabContainer from "../../FabContainer/FabContainer";

type PoolProps = {
    id: string,
    index: number,
    translate: *,
    pool: PoolType,
    selected: ?string,
    onSelect: () => void,
    onDelete: () => void,

    disabled: boolean,
    canDelete: boolean,
};

type Props = {
    acl: *,
    disabled: boolean,
    translate: *,
    selected: ?string,
    onSelect: (uuid: string) => void,
    onAdd: () => void,
    onDelete: (uuid: string) => void,
    pools: PoolType[],
    selectFirst: (uuid: string) => void,
    clearPools: () => *,
    deleteState: DeletePoolState,
    planable: boolean,
};

/**
 * Pool component
 */
const Pool = ({
    id,
    index,
    pool,
    selected,
    onSelect,
    onDelete,
    translate,
    canDelete,
    disabled,
}: PoolProps) => (
    <ListItem
        id={`${id}-item-${index}`}
        key={pool.uuid}
        className={classnames({
            [styles.selected]: selected === pool.uuid,
        })}
        onClick={() => onSelect()}
        button
    >
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <ListItemText secondary={pool.name} />
            </Grid>
            <Grid item xs={4}>
                <ListItemText
                    secondary={`${moment(pool.start).format(
                        "HH:mm",
                    )} - ${moment(pool.end).format("HH:mm")}`}
                />
            </Grid>
        </Grid>
        {!disabled && canDelete && (
            <ListItemSecondaryAction
                className={classnames({
                    [styles["selected-secondary"]]: selected === pool.uuid,
                })}
            >
                <IconMenu
                    id={`${id}-menu-${index}`}
                    options={[
                        {
                            id: `${id}-delete`,
                            label: translate("Delete pool"),
                            onClick: () => onDelete(),
                        },
                    ]}
                />
            </ListItemSecondaryAction>
        )}
    </ListItem>
);

/**
 * Pools component
 */
const Pools = ({
    acl,
    pools,
    selected,
    translate,
    onAdd,
    onSelect,
    onDelete,
    deleteState,
    selectFirst,
    disabled,
    planable,
}: Props) => {
    const canAdd = acl("events.recruitment.create");
    const canDelete = acl("events.recruitment.delete") && !deleteState.loading;
    return (
        <div className={styles.wrapper}>
            <List className={styles.list}>
                <ListItem>
                    <ListItemText
                        primary={translate("Recruitment pools")}
                        primaryTypographyProps={{ variant: "h5" }}
                        secondary={
                            !pools.length &&
                            translate(
                                "No recruitment pools here yet! Let's add some...",
                            )
                        }
                    />
                </ListItem>
                {pools.map((pool, index) => {
                    index === 0 && selectFirst(pools[0].uuid);
                    return (
                        <Pool
                            key={pool.uuid}
                            id="event-recruitment-pool"
                            index={index}
                            pool={pool}
                            selected={selected}
                            canDelete={canDelete}
                            translate={translate}
                            disabled={disabled}
                            onSelect={() => onSelect(pool.uuid)}
                            onDelete={() => onDelete(pool.uuid)}
                        />
                    );
                })}
            </List>
            {!disabled && canAdd && (
                <FabContainer center>
                    <Fab
                        id="event-recruitment-pool-add"
                        onClick={onAdd}
                        color="primary"
                        variant="extended"
                        disabled={planable !== true}
                    >
                        <Add />
                        {translate("Add group")}
                    </Fab>
                </FabContainer>
            )}
        </div>
    );
};

export default Pools;
