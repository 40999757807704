// @flow

import React from "react";

import styles from "./style.module.scss";

import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircle";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import classnames from "classnames";

import EditableTitle from "../Title";
import { type Data as State } from "../../../redux/modules/staffing-template-details.d";
import { type State as UpdateNameState } from "../../../redux/modules/update-staffing-template-name.d";
import FabContainer from "../../FabContainer/FabContainer";

type Props = {
    translate: *,
    loading: boolean,
    data: State,
    onAdd: () => void,
    onClick: (line: *) => void,
    selected: *,
    updateName: (uuid: string, name: string) => void,
    updateNameState: UpdateNameState,
    refetchTemplate: () => void,
};

const Lines = ({
    translate,
    loading,
    data,
    onAdd,
    updateName,
    updateNameState,
    refetchTemplate,
    selected,
    onClick,
}: Props) => {
    return (
        <div className={styles.wrapper}>
            {data?.staffingTemplateLines && (
                <List
                    id={"staffingtemplate-lines-list"}
                    className={styles.list}
                >
                    <EditableTitle
                        name={data?.name}
                        uuid={data?.uuid}
                        updateName={updateName}
                        updateNameState={updateNameState}
                        refetchTemplate={refetchTemplate}
                    />
                    {data?.staffingTemplateLines.length < 1 ? (
                        <Typography variant="h5" align="center">
                            {translate("No lines here yet! Let's add some...")}
                        </Typography>
                    ) : (
                        data?.staffingTemplateLines.map(line => (
                            <ListItem
                                key={line.lineUuid}
                                className={classnames({
                                    [styles.selected]: line === selected,
                                })}
                                button
                                onClick={() => onClick(line)}
                            >
                                {line.lineName}
                            </ListItem>
                        ))
                    )}
                </List>
            )}
            <FabContainer>
                <Fab
                    id="staffingtemplate-add"
                    color="primary"
                    variant="extended"
                    onClick={() => onAdd()}
                    disabled={loading}
                >
                    <AddIcon />
                    {translate("Add custom eventline")}
                </Fab>
            </FabContainer>
        </div>
    );
};

export default Lines;
