// @flow

// Import styles
import styles from "./Tasks.scss";

// Import libs
import React, { Component } from "react";
import DoneIcon from "@material-ui/icons/Done";

// Define props
type PropsType = {
    amount: number,
};
/**
 * A dummy component
 */
export default class VenueBox extends Component<PropsType> {
    /**
     * Render
     */
    render() {
        const { amount } = this.props;

        return (
            <div
                className={styles.task}
                style={{ backgroundColor: amount > 0 ? "#651f82" : "#EDEDEE" }}
            >
                {amount > 0 ? (
                    <p className={styles.inner}>{amount}</p>
                ) : (
                    <DoneIcon className={styles.icon} />
                )}
            </div>
        );
    }
}
