// @flow

import styles from "./List.scss";

import React, { Component, Fragment } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import _List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import {
    type Line as LineType,
    type State as LinesState,
} from "../../redux/modules/lines.d";

import Line from "./Line";

// Define props
type PropsType = {
    busy?: ?boolean,
    lines: LinesState,
    selected: string[],
    onChange: (selected: string[]) => void,
};

/**
 * A preferredlineslist component
 */
export default class List extends Component<PropsType> {
    /**
     * Test if current line is selected
     */
    isSelected(line: LineType) {
        const { selected } = this.props;
        return selected.includes(line.uuid);
    }

    /**
     * Handle a line toggle
     */
    handleToggle(uuid: string) {
        const { selected, onChange } = this.props;
        if (selected.includes(uuid)) {
            onChange(selected.filter(id => id !== uuid));
        } else {
            onChange(selected.concat([uuid]));
        }
    }

    /**
     * Render
     */
    render() {
        const { lines, busy } = this.props;

        // Add spinner
        if (!lines.data) {
            return (
                <div className={styles.spinner}>
                    <CircularProgress />
                </div>
            );
        }

        return (
            <_List>
                {lines.data.map(group => (
                    <Fragment key={group.uuid}>
                        <ListItem>
                            <ListItemText disableTypography>
                                <Typography variant="caption">
                                    {group.name}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        {group.lines.map(line => (
                            <Line
                                busy={busy}
                                key={line.uuid}
                                line={line}
                                selected={this.isSelected(line)}
                                onToggle={() => this.handleToggle(line.uuid)}
                            />
                        ))}
                    </Fragment>
                ))}
            </_List>
        );
    }
}
