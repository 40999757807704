// @flow

/**
 * Module: Add Show
 */
import createApiModule from "../create-api-module";
import { type PathParams } from "./event-recruits-delete.d";

const { reducer, actions } = createApiModule(
    "eventRecruitsDelete",
    ({ uuid }: PathParams) => `/ays-series-event-management/recruits/${uuid}`,
    {
        method: "DELETE",
    },
);
export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
        }),
    );
};
export const ERROR = "Could not remove recruit!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
