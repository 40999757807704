//@flow
import styles from "./DayItem.scss";

import React, { Component } from "react";
import ContextMenu from "./ContextMenu";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { Divider } from "@material-ui/core";

type Props = {
    translate: *,
    id: string,
    day: *,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
};

type State = {
    contextMenuPosition: *,
};

export default class EmptyDayItem extends Component<Props, State> {
    state = {
        contextMenuPosition: null,
    };
    /**
     * Render
     */
    render() {
        const { translate, id, day, onAddOptionToSeries, onAddEventToSeries } =
            this.props;
        const { contextMenuPosition } = this.state;

        return (
            <div
                key={`${id}`}
                className={styles.emptyCell}
                onContextMenu={e => {
                    e.preventDefault();
                    this.setState({
                        contextMenuPosition: {
                            mouseX: e.clientX,
                            mouseY: e.clientY,
                        },
                    });
                }}
            >
                {contextMenuPosition && (
                    <ContextMenu
                        contextMenuPosition={contextMenuPosition}
                        onClose={() =>
                            this.setState({ contextMenuPosition: null })
                        }
                    >
                        <MenuItem
                            onClick={() => {
                                onAddOptionToSeries("none", id);
                            }}
                        >
                            <Typography>
                                {translate("Add Option on %{day}", {
                                    day,
                                })}
                            </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                onAddEventToSeries("none", id);
                            }}
                        >
                            <Typography>
                                {translate("Add Event on %{day}", {
                                    day,
                                })}
                            </Typography>
                        </MenuItem>
                    </ContextMenu>
                )}
            </div>
        );
    }
}
