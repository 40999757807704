// @flow

import { pdf } from "@react-pdf/renderer";

const downloadFile = (url: string, filename: ?string) => {
    const saveFile = document.createElement("a");
    saveFile.href = url;
    saveFile.target = "_blank";
    // Download does not work for files on a different domain
    if (filename) {
        saveFile.download = filename;
    }
    document.body?.appendChild(saveFile);
    saveFile.style.display = "none";
    saveFile.click();
    saveFile.remove();
};

export const open = (pdfDocument: any, filename: ?string) =>
    pdf(pdfDocument)
        .toBlob()
        .then(blob => downloadFile(URL.createObjectURL(blob), filename));
