// @flow
import React from "react";

import {
    Switch,
    Route,
    Redirect,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import List from "./List";

type Props = {
    match: Match,
    history: RouterHistory,
    uuid: ?string,
};

import stripslash from "../../../../utils/stripslash";

const Attendees = ({ match, history, uuid }: Props) => (
    <Switch>
        <Route path={`${match.path}`} exact>
            <Redirect to={`${stripslash(match.url)}/page/1/`} />
        </Route>
        <Route path={`${match.path}page/:page/`}>
            <List
                uuid={uuid}
                onPageChange={page =>
                    history.push(`${stripslash(match.url)}/page/${page}/`)
                }
            />
        </Route>
    </Switch>
);

export default withRouter<any>(Attendees);
