// @flow

import createApiModule from "../create-api-module";
import { type NewEvent, type PathParams } from "./add-show.d";

const { reducer, actions } = createApiModule(
    "addShow",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/series/${eventUuid}/events`,
    {
        method: "POST",
    },
);
export const load = (eventUuid: string, data: NewEvent) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { eventUuid },
            data,
        }),
    );
};
export const ERROR = "Could not add event!";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
