// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4907OVwp7uT2EPv4zkWPjQ\\=\\={padding:2em}._6WW66PYEaCSHa50k7Uq6WA\\=\\={overflow:hidden}\n", "",{"version":3,"sources":["webpack://./../Organisations.scss"],"names":[],"mappings":"AAEA,6BACI,WAAY,CACf,6BAGG,eAAgB","sourcesContent":["// Line staffing details\n\n.wrapper {\n    padding: 2em;\n}\n\n.inner {\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_4907OVwp7uT2EPv4zkWPjQ==",
	"inner": "_6WW66PYEaCSHa50k7Uq6WA=="
};
export default ___CSS_LOADER_EXPORT___;
