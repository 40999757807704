// @flow

import createApiModule from "../create-api-module";
import type { PathParams } from "./event-line-member-phonenumbers.d";

const { reducer, actions } = createApiModule(
    "showLineMemberPhoneNumbers",
    ({ teamUuid }: PathParams) =>
        `/ays-series-event-management/teams/${teamUuid}/members/bulk/_verify`,
    {
        method: "POST",
    },
);

export const load =
    (teamUuid: string, organisationUuid: ?string, phoneNumbers: string) =>
    (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { teamUuid },
                data: { organisationUuid, phoneNumbers },
            }),
        );
    };

export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not verify phonenumbers";
export default reducer;
