// @flow

import styles from "./Login.scss";

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import PhoneNumberField from "../PhoneNumberField/PhoneNumberField.js";

type Props = {
    translate: *,
    onSubmit: (value: string) => void,
    error: ?string,
    busy: boolean,
};

const Hi = ({ translate, onSubmit, error, busy }: Props) => {
    const [value, setValue] = useState<string>("");

    return (
        <form
            className={styles.form}
            onSubmit={event => {
                event.preventDefault();
                onSubmit(value);
            }}
        >
            <Typography variant="h2">{translate("Hi there,")}</Typography>
            <Typography>
                {translate(
                    "Fill in your mobile phone number below to get started.",
                )}
            </Typography>
            <div className={styles.cell}>
                <PhoneNumberField
                    id="login-hi-phonenumber"
                    label={translate("Mobile phone number")}
                    onChange={value => setValue(value)}
                    value={value}
                    error={!!error}
                    helperText={error}
                    disabled={busy}
                    fullWidth
                    autoFocus
                    autocomplete="username"
                />
            </div>
            <div className={styles.cell}>
                <Button
                    id="login-hi-submit"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={busy}
                >
                    {translate("Get started")}
                </Button>
            </div>
        </form>
    );
};

export default Hi;
