// @flow

import styles from "./FilterField.scss";

import classnames from "classnames";
import React from "react";
import Typography from "@material-ui/core/Typography";

import SearchIcon from "../icons/Search";

type Props = {
    placeholder: string,
    value: string,
    onChange: (value: string) => void,
    id?: ?string,
    disabled: boolean,
    className: string,
};

/**
 * Filter field
 */
const FilterField = ({
    value,
    onChange,
    placeholder,
    id,
    disabled,
    className,
}: Props) => (
    <div className={classnames(styles.wrapper, className)}>
        <Typography className={styles.style}>
            <SearchIcon />
            <input
                id={id}
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={({ target: { value } }) => onChange(value)}
                disabled={disabled}
            />
        </Typography>
    </div>
);

FilterField.defaultProps = {
    disabled: false,
    className: "",
};

export default FilterField;
