// @flow

import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { type Organisation as OrganisationType } from "../../../redux/modules/organisations.d";

type Props = {
    busy?: ?boolean,
    organisation: OrganisationType,
    onClick: () => void,
    preferred: boolean,
};

const FIND_SPACES = / /g;

const slugify = input => input.replace(FIND_SPACES, "-");

/**
 * Line component
 */
const Organisation = ({ organisation, onClick, busy, preferred }: Props) => (
    <ListItem
        key={organisation.organisationUuid}
        role={undefined}
        dense
        button
        onClick={onClick}
        disabled={!!busy}
    >
        {" "}
        <ListItemText
            id={`organisationselector-item-${slugify(
                organisation.organisationName,
            )}`}
            primary={organisation.organisationName}
            secondary={preferred ? "Preferred" : null}
        />
    </ListItem>
);

export default Organisation;
