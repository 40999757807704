// @flow
// Import styles
import styles from "./ConfirmNotification.scss";

import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import NotificationCheckbox from "../../NotificationCheckbox";

type Props = {
    translate: *,
    onSubmit: () => *,
    onClose: () => void,
    isBriefingChanged: boolean,
    isPrebriefingChanged: boolean,
};

const ConfirmNotification = ({
    onSubmit,
    onClose,
    translate,
    isBriefingChanged,
    isPrebriefingChanged,
}: Props) => {
    const [preBriefingNotification, setPreBriefingNotification] =
        useState(false);
    const [briefingNotification, setBriefingNotification] = useState(false);

    const msg = translate(
        "Would you like to send push notifications of your changes?",
    );

    const handleSubmit = e => {
        onSubmit(e, preBriefingNotification, briefingNotification);
    };

    return (
        <Dialog onClose={onClose} open>
            <DialogTitle disableTypography>
                <Typography variant="h2">
                    {translate("Send notification")}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <DialogContentText
                    dangerouslySetInnerHTML={{ __html: msg }}
                    className={styles.msg}
                ></DialogContentText>
                <NotificationCheckbox
                    onCheckboxChange={() => {
                        setPreBriefingNotification(!preBriefingNotification);
                    }}
                    isChecked={preBriefingNotification}
                    className={styles.notificationCheckbox}
                    label={translate("Send push notification for briefing.")}
                    disabled={!isPrebriefingChanged}
                ></NotificationCheckbox>
                <NotificationCheckbox
                    onCheckboxChange={() => {
                        setBriefingNotification(!briefingNotification);
                    }}
                    isChecked={briefingNotification}
                    className={styles.notificationCheckbox}
                    label={translate(
                        "Send push notification for onsite briefing.",
                    )}
                    disabled={!isBriefingChanged}
                ></NotificationCheckbox>
            </DialogContent>

            <DialogActions>
                <Button
                    id="modal-push-cancel"
                    onClick={() => {
                        onClose();
                    }}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    id="modal-push-confirm"
                    onClick={e => handleSubmit(e)}
                    color="primary"
                >
                    {translate("Send")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmNotification;
