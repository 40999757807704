import moment from "moment";

export const format = datestamp => {
    const date = new Date(datestamp);

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
    )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
};

export const formatWithoutTime = datestamp => {
    const date = new Date(datestamp);

    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
    )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
};

export const mapToLocalTimezone = datestamp => {
    const localDateTime = moment.utc(datestamp).local();
    return localDateTime;
};
