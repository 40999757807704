// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

export const Client = {
    /**
     * Fetch all venues
     */
    getTemplatesForVenue(venue: string) {
        return this.instance
            .get(`/ays-venue-facility-management/venues/${venue}/templates`)
            .then(({ data: { templates } }) => templates)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
