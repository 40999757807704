// @flow

import createApiModule from "../create-api-module";
import { type User, type PathParams } from "./admin-user-edit.d";

const { reducer, actions } = createApiModule(
    "adminUserEdit",
    ({ uuid }: PathParams) => `/ays-user-management/users/${uuid}`,
    { method: "PUT" },
);

export const load = (uuid: string, data: User) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
            data,
        }),
    );
};

export const ERROR = "Could not edit user!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
