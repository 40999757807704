//@flow

import { fromJS } from "immutable";
import invariant from "invariant";
//import { isEqual } from "../../utils/isEqual";

import { typeof Client } from "../../utils/api-client/modules/organisation";
import { type Action, type IState } from "./members.d";

const ERROR_MEMBERS = "No members found!";
const ERROR_ROLE_SWITCH = "Update failed!";
const ERROR_APPROVE = "Could not approve member!";
const ERROR_DENY = "Could not deny member!";

const CLEAR: string = "members/clear";

const PENDING: string = "members/pending";
const SUCCESS: string = "members/success";
const FAILED: string = "members/failed";

const ROLE_SWITCH_PENDING = "members/role-switch/pending";
const ROLE_SWITCH_SUCCESS = "members/role-switch/success";
const ROLE_SWITCH_FAILED = "members/role-switch/failed";

const APPROVE_PENDING = "members/approve/pending";
const APPROVE_SUCCESS = "members/approve/success";
const APPROVE_FAILURE = "members/approve/failure";

const DENY_PENDING = "members/deny/pending";
const DENY_SUCCESS = "members/deny/success";
const DENY_FAILURE = "members/deny/failure";

const UPDATE_MEMBER = "members/update/member";
const UPDATE_MEMBER_STATUS = "members/update/member/status";

const initialState: IState = (fromJS({
    loading: false,
    error: null,
    data: null,
    page: 0,
    organisationUUID: null,
    filter: "",
    sorting: [],
    filterDeclined: false,
}): any);

/**
 * Reducer
 */
export default (state: IState = initialState, { type, payload }: Action): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        const { organisationUUID, page, filter, sorting, filterDeclined } =
            (payload: any);

        invariant(page, "Wrong action format!");

        return state.merge({
            loading: true,
            page,
            filter,
            sorting,
            filterDeclined,
            organisationUUID,
            error: null,
        });
    }

    if (type === SUCCESS) {
        return state.merge({
            loading: false,
            data: fromJS(payload),
        });
    }

    if (type === FAILED) {
        return state.merge({
            loading: false,
            error: payload,
        });
    }

    if (type === ROLE_SWITCH_PENDING) {
        invariant(payload && payload.uuid && payload.role, "No payload!");

        if (!state.get("data")) {
            return state;
        }

        return (state: any).updateIn(["data", "content"], content =>
            content.map(item =>
                item.get("uuid") === payload.uuid
                    ? item.set("role", payload.role)
                    : item,
            ),
        );
    }

    if (type === ROLE_SWITCH_SUCCESS) {
        return state.set("loading", false);
    }

    if (type === ROLE_SWITCH_FAILED) {
        invariant(
            payload && payload.error && payload.role && payload.uuid,
            "Wrong payload!",
        );

        if (!state.get("data")) {
            return state;
        }

        return (state: any)
            .updateIn(["data", "content"], content =>
                content.map(item =>
                    item.get("uuid") === payload.uuid
                        ? item.set("role", payload.role)
                        : item,
                ),
            )
            .set("error", payload.error);
    }

    if (type === UPDATE_MEMBER) {
        invariant(payload && payload.uuid, "Wrong payload!");

        if (!state.get("data")) {
            return;
        }

        return (state: any).updateIn(["data", "content"], content =>
            content.map(item => {
                if (item.get("uuid") === payload.uuid) {
                    return item
                        .set("description", payload.description || "")
                        .set("noSmartphone", payload.noSmartphone || "")
                        .set("userFirstName", payload.userFirstName || "")
                        .set("userLastName", payload.userLastName || "");
                } else {
                    return item;
                }
            }),
        );
    }

    if (type === UPDATE_MEMBER_STATUS) {
        invariant(payload && payload.uuid, "Wrong payload!");

        if (!state.get("data")) {
            return;
        }

        return (state: any).updateIn(["data", "content"], content =>
            content.map(item => {
                if (item.get("uuid") === payload.uuid) {
                    return item.set("inviteStatus", payload.inviteStatus || "");
                } else {
                    return item;
                }
            }),
        );
    }

    if (type === APPROVE_PENDING) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === APPROVE_SUCCESS) {
        return state.merge({
            loading: false,
        });
    }

    if (type === APPROVE_FAILURE) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === DENY_PENDING) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === DENY_SUCCESS) {
        return state.merge({
            loading: false,
        });
    }

    if (type === DENY_FAILURE) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load
 */
export const load =
    (
        organisationUUID: string,
        page: number,
        filter: string,
        sorting: string[],
        force: boolean = false,
        filterDeclined: boolean = false,
    ) =>
    (dispatch: *, getState: *, client: Client) => {
        const state = getState().members;

        if (!force && state.get("loading")) {
            return Promise.resolve();
        }
        if (
            !force &&
            state.get("organisationUUID") === organisationUUID &&
            state.get("page") === page &&
            state.get("filter") === filter &&
            state.get("sorting") === sorting &&
            state.get("filterDeclined") === filterDeclined
        ) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: {
                organisationUUID,
                page,
                filter,
                sorting,
                filterDeclined,
            },
        });

        return client
            .getOrganisationMembers(
                organisationUUID,
                page,
                filter,
                filterDeclined,
                sorting,
            )
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error =>
                dispatch({
                    type: FAILED,
                    payload: (error && error.message) || ERROR_MEMBERS,
                }),
            );
    };

/**
 * Reload
 */
export const reload = () => (dispatch: *, getState: *) => {
    const { organisationUUID, page, filter, sorting } =
        getState().members.toJS();
    return dispatch(load(organisationUUID, page, filter, sorting, true));
};

/**
 * ActionCreator: update a member if present
 */
export const updateMember = (
    uuid: string,
    description: string,
    noSmartphone: boolean,
    userFirstName: string,
    userLastName: string,
) => ({
    type: UPDATE_MEMBER,
    payload: {
        uuid,
        description,
        noSmartphone,
        userFirstName,
        userLastName,
    },
});

/**
 * ActionCreator: update a member if present
 */
export const updateMemberInviteStatus = (
    uuid: string,
    inviteStatus: string,
) => ({
    type: UPDATE_MEMBER_STATUS,
    payload: {
        uuid,
        inviteStatus,
    },
});

/**
 * ActionCreator: make leader
 */
export const makeLeader =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().members;

        if (state.get("loading")) {
            return Promise.resolve();
        }

        dispatch({
            type: ROLE_SWITCH_PENDING,
            payload: {
                uuid,
                role: "LEADER",
            },
        });

        return client
            .makeLeader(uuid)
            .then(() =>
                dispatch({
                    type: ROLE_SWITCH_SUCCESS,
                }),
            )
            .catch(error => {
                dispatch({
                    type: ROLE_SWITCH_FAILED,
                    payload: {
                        error: (error && error.message) || ERROR_ROLE_SWITCH,
                        uuid,
                        role: "MEMBER",
                    },
                });

                throw error;
            });
    };

/**
 * ActionCreator: make member
 */
export const makeMember =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().members;

        if (state.get("loading")) {
            return new Promise(() => undefined);
        }

        dispatch({
            type: ROLE_SWITCH_PENDING,
            payload: {
                uuid,
                role: "MEMBER",
            },
        });

        return client
            .makeMember(uuid)
            .then(() =>
                dispatch({
                    type: ROLE_SWITCH_SUCCESS,
                }),
            )
            .catch(error => {
                dispatch({
                    type: ROLE_SWITCH_FAILED,
                    payload: {
                        error: (error && error.message) || ERROR_ROLE_SWITCH,
                        uuid,
                        role: "LEADER",
                    },
                });

                throw error;
            });
    };

/**
 * ActionCreator: approve non smartphone user
 */
export const approveNonSmartphoneUser =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().members;

        if (state.get("loading")) {
            return new Promise(() => undefined);
        }

        dispatch({
            type: APPROVE_PENDING,
            payload: {
                uuid,
            },
        });

        return client
            .approveNonSmartphoneUser(uuid)
            .then(() => {
                dispatch({
                    type: APPROVE_SUCCESS,
                    payload: { uuid },
                });
                dispatch(updateMemberInviteStatus(uuid, "MANUALLY_APPROVED"));
            })
            .catch(error => {
                dispatch({
                    type: APPROVE_FAILURE,
                    payload: {
                        error: (error && error.message) || ERROR_APPROVE,
                        uuid,
                    },
                });

                throw error;
            });
    };

/**
 * ActionCreator: deny non smartphone user
 */
export const denyNonSmartphoneUser =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().members;

        if (state.get("loading")) {
            return new Promise(() => undefined);
        }

        dispatch({
            type: DENY_PENDING,
            payload: {
                uuid,
            },
        });

        return client
            .denyNonSmartphoneUser(uuid)
            .then(() => {
                dispatch({
                    type: DENY_SUCCESS,
                    payload: { uuid },
                });
                dispatch(updateMemberInviteStatus(uuid, "MANUALLY_DENIED"));
            })
            .catch(error => {
                dispatch({
                    type: DENY_FAILURE,
                    payload: {
                        error: (error && error.message) || ERROR_DENY,
                        uuid,
                    },
                });

                throw error;
            });
    };
