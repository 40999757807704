// @flow
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
    Switch,
    Route,
    Redirect,
    type Match,
    type RouterHistory,
    type Location,
    withRouter,
} from "react-router";
import { getIn } from "immutable";

import stripslash from "../../utils/stripslash";
import TabbedHeader from "../../components/Authenticated/TabbedHeader";

import List from "./List";
import Calendar from "./Calendar";
import Detail from "./Detail";
import FacilityView from "../FacilityView";
import NotFound from "../../components/NotFound";
import ExportDataContainer from "./ExportData";

import { withAcl } from "../Acl";
import { withTranslate } from "../Translate";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    history: RouterHistory,
    location: Location,
    page: number,
};

type StateType = {
    selected: number,
};

const TABS = (translate, acl) => {
    const tabs = [];

    tabs.push(["/list/page/1/", translate("List"), "list"]);

    if (acl("facilityView.read")) {
        tabs.push([
            "/facility-view/",
            translate("Facility view"),
            "facility-view",
        ]);
    }

    if (acl("calendarOverview.read")) {
        tabs.push(["/calendar", translate("Calendar"), "calendar"]);
    }
    return tabs;
};

class Events extends Component<Props, StateType> {
    // Initial state
    state: StateType = {
        selected: 0,
    };

    static getDerivedStateFromProps(props: Props) {
        const path = props.location.pathname;
        const tabs = TABS(props.translate, props.acl);
        const found = tabs.findIndex(tab => path.includes(tab[2]));
        return {
            selected: Math.max(Math.min(found, tabs.length - 1), 0),
        };
    }

    /**
     * Handle tab change
     */
    handleTabChange(tabIndex: number) {
        const { selected } = this.state;
        const { translate, history, match, acl } = this.props;

        if (selected !== tabIndex) {
            history.push(
                `${stripslash(match.url)}${TABS(translate, acl)[tabIndex][0]}`,
            );
            this.setState({
                selected: tabIndex,
            });
        }
    }

    render() {
        const { match, history, translate, acl, page } = this.props;
        const { selected } = this.state;
        const canReadCalendar = acl("calendarOverview.read");

        return (
            <Switch>
                <Route path={match.path} exact>
                    <Redirect
                        to={`${stripslash(match.url)}/list/page/${
                            page === 0 ? 1 : page
                        }`}
                    />
                </Route>

                <Route path={`${match.path}calendar`}>
                    {(canReadCalendar && (
                        <Fragment>
                            <TabbedHeader
                                id="detail-header"
                                actions={acl("events.exportData.read")}
                                translate={translate}
                                title={translate("events & shows")}
                                tabs={TABS(translate, acl).map(tab => tab[1])}
                                active={selected}
                                onChange={this.handleTabChange.bind(this)}
                                onExportData={() =>
                                    history.push(
                                        `${stripslash(
                                            match.url,
                                        )}/calendar/export/data/`,
                                    )
                                }
                            >
                                <Route
                                    path={`${stripslash(
                                        match.path,
                                    )}/export/data/`}
                                >
                                    <ExportDataContainer
                                        onClose={() =>
                                            history.push(
                                                `${stripslash(match.url)}`,
                                            )
                                        }
                                    />
                                </Route>
                            </TabbedHeader>

                            <Calendar />
                        </Fragment>
                    )) || <NotFound translate={translate} />}
                </Route>

                <Route path={`${match.path}list/page/:page/`}>
                    <Fragment>
                        <TabbedHeader
                            id="detail-header"
                            title={translate("events & shows")}
                            tabs={TABS(translate, acl).map(tab => tab[1])}
                            active={selected}
                            onChange={this.handleTabChange.bind(this)}
                        />
                        <List
                            onSelect={(id, zone = "") =>
                                history.push(
                                    `${stripslash(match.url)}/detail/${id}/${
                                        zone && zone.length ? `${zone}/` : ""
                                    }`,
                                )
                            }
                            onPageChange={page =>
                                history.push(
                                    `${stripslash(
                                        match.url,
                                    )}/list/page/${page}/`,
                                )
                            }
                        />
                    </Fragment>
                </Route>

                <Route
                    path={`${match.path}facility-view/:venueUuid?/:lineTypeUuid?/`}
                >
                    {(acl("facilityView.read") && (
                        <Fragment>
                            <TabbedHeader
                                id="detail-header"
                                title={translate("events & shows")}
                                tabs={TABS(translate, acl).map(tab => tab[1])}
                                active={selected}
                                onChange={this.handleTabChange.bind(this)}
                            />
                            <FacilityView />
                        </Fragment>
                    )) || <NotFound translate={translate} />}
                </Route>
                <Route path={`${match.path}detail/:id/`}>
                    <div>
                        <Detail />
                    </div>
                </Route>
            </Switch>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(({ shows }) => ({
                page: getIn(shows, ["page"]),
            }))(Events),
        ),
    ),
);
