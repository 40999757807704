// @flow

import React, { Component } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";

import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const snackbarStyling = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
});

type Props = {
    translate: *,
    notifications: *,
    add: (message: string, type?: string) => void,
    remove: (uuid: string) => void,
};

type StateType = {
    open: boolean,
    notification: *,
};
// $FlowFixMe
class MySnackbarContent extends Component {
    render() {
        const { classes, className, message, onClose, variant, ...other } =
            this.props;
        const Icon = variantIcon[variant];
        return (
            <SnackbarContent
                className={classNames(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={classNames(
                                classes.icon,
                                classes.iconVariant,
                            )}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(["success", "warning", "error", "info"])
        .isRequired,
};

const MySnackbarContentWrapper = withStyles(snackbarStyling)(MySnackbarContent);

export default class NotificationManager extends Component<Props, StateType> {
    state = {
        open: false,
        notification: null,
    };
    /**
     * Trigger load on update
     */
    componentDidUpdate() {
        const { open } = this.state;
        const { notifications } = this.props;
        if (!open && notifications.data.length) {
            this.setState({
                open: true,
                notification: notifications.data[0],
            });
        }
    }

    handleClose = () => {
        const { notification } = this.state;
        const { remove } = this.props;
        if (notification) {
            remove(notification.uuid);
            this.setState({ open: false, notification: null });
        }
    };

    /**
     * Render
     */
    render() {
        const { notification, open } = this.state;
        return (
            <div>
                {open && notification && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={notification.type}
                            message={notification.message}
                        />
                    </Snackbar>
                )}
            </div>
        );
    }
}
