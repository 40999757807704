// @flow

import FormError from "./FormError";

export default (error: any) => {
    if (error.response && error.response.data) {
        const { data } = error.response;

        if (data.fieldErrors && data.fieldErrors.length) {
            throw new FormError(data.fieldErrors);
        }

        if (data.message) {
            throw new Error(data.message);
        }

        if (data.code) {
            throw new Error(data.code);
        }
    }

    throw error;
};
