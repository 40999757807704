// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "facilitiesLineTypes",
    () => "ays-venue-facility-management/linetypes",
);

export const ERROR = "Couldn't fetch facilities lineTypes!";

export const load = actions.load;
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
