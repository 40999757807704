// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FRP7c99Qxig3Hou7ylNWJQ\\=\\={padding:2em;text-align:center}.PCrmd-ahfD4ZYyE8UY2VaQ\\=\\={background-color:gray;padding:1em;margin-left:0.5em;display:flex;justify-content:space-between}\n", "",{"version":3,"sources":["webpack://./../Staffing.scss"],"names":[],"mappings":"AAEA,4BACI,WAAY,CACZ,iBAAkB,CACrB,4BAGG,qBAAsB,CACtB,WAAY,CACZ,iBAAkB,CAClB,YAAa,CACb,6BAA8B","sourcesContent":["// Staffing style\n\n.progress {\n    padding: 2em;\n    text-align: center;\n}\n\n.planable {\n    background-color: gray;\n    padding: 1em;\n    margin-left: 0.5em;\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "FRP7c99Qxig3Hou7ylNWJQ==",
	"planable": "PCrmd-ahfD4ZYyE8UY2VaQ=="
};
export default ___CSS_LOADER_EXPORT___;
