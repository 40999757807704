// @flow

import invariant from "invariant";
import qs from "qs";

import { type Auth } from "../../../redux/modules/user.d";

import normalizeError from "../../error-map";
import mixin from "../../mixin";

export const Client = {
    /**
     * Login: Fetch the status
     * - Returns if registered
     */
    login(id: string): Promise<boolean> {
        return this.instance
            .post(
                "/ays-authorization/authentication/login/web",
                {
                    phoneNumber: id,
                },
                {
                    headers: {
                        Authorization: this.appAuth,
                    },
                },
            )
            .then(({ data: { userRegistrationStatus } }) => {
                invariant(userRegistrationStatus, "Wrong response format");

                return userRegistrationStatus === "WEB_LOGIN_REQUIRED";
            })
            .catch(normalizeError);
    },

    /**
     * Get a token with username and password
     */
    getTokenWithPassword(username: string, password: string): Promise<Auth> {
        return this.instance
            .post(
                "/ays-authorization/oauth/token",
                qs.stringify({
                    grant_type: "password",
                    username,
                    password,
                }),
                {
                    headers: {
                        Authorization: this.appAuth,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                },
            )
            .then(({ data }) => ({
                token: data["access_token"],
                refreshToken: data["refresh_token"],
            }));
    },

    /**
     * Refresh the current token
     */
    refreshToken(): Promise<Auth> {
        const refreshToken = this.store
            .getState()
            .user.getIn(["data", "auth", "refreshToken"]);
        invariant(refreshToken, "Refresh token required!");

        return this.instance
            .post(
                "/ays-authorization/oauth/token",
                qs.stringify({
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                }),
                {
                    _retry: true, // Mark also as retry
                    headers: {
                        Authorization: this.appAuth,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                },
            )
            .then(({ data }) => ({
                token: data["access_token"],
                refreshToken: data["refresh_token"],
            }));
    },

    /**
     * Register a user for web
     */
    register(
        phoneNumber: string,
        verificationToken: string,
        password: string,
    ): Promise<void> {
        return this.instance
            .post(
                "/ays-user-management/users/registration/web",
                {
                    phoneNumber,
                    verificationToken,
                    password,
                },
                {
                    headers: {
                        Authorization: this.appAuth,
                    },
                },
            )
            .then(() => undefined)
            .catch(normalizeError);
    },

    /**
     * Resend verification
     */
    resendVerification(phoneNumber: string): Promise<void> {
        return this.instance.post(
            "/ays-authorization/authentication/login/web/phone-token",
            {
                phoneNumber,
            },
            {
                headers: {
                    Authorization: this.appAuth,
                },
            },
        );
    },
};

/**
 * User mixin
 */
export default mixin(Client);
