// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pd-U8Sl\\+118u15Keu699Hg\\=\\= ul{min-width:70em;max-width:70vw;right:-6.7em;position:absolute;z-index:1111;background-color:#fff;max-height:30em;overflow-x:scroll;border:0.08em #ccc solid}.pd-U8Sl\\+118u15Keu699Hg\\=\\= ul li{font-size:0.875em}.pd-U8Sl\\+118u15Keu699Hg\\=\\= .o6n4r5GnEnZjzdLPYpXGXg\\=\\={color:#888;margin-left:0.5em}\n", "",{"version":3,"sources":["webpack://./../AsyncFiltered.scss","webpack://./../../styles/_variables.scss"],"names":[],"mappings":"AAEA,gCAEQ,cAAe,CACf,cAAe,CACf,YAAa,CACb,iBAAkB,CAClB,YAAa,CACb,qBCLI,CDMJ,eAAgB,CAChB,iBAAkB,CAClB,wBAAgC,CAVxC,mCAaY,iBAAkB,CAb9B,yDAiBQ,UCXW,CDYX,iBAAkB","sourcesContent":["@import \"../../src/styles/variables\";\n\n.element {\n    ul {\n        min-width: 70em;\n        max-width: 70vw;\n        right: -6.7em;\n        position: absolute;\n        z-index: 1111;\n        background-color: $white;\n        max-height: 30em;\n        overflow-x: scroll;\n        border: 0.08em $light-gray solid;\n\n        li {\n            font-size: 0.875em;\n        }\n    }\n    .searchIcon {\n        color: $neutral-grey;\n        margin-left: 0.5em;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "pd-U8Sl+118u15Keu699Hg==",
	"searchIcon": "o6n4r5GnEnZjzdLPYpXGXg=="
};
export default ___CSS_LOADER_EXPORT___;
