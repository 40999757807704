// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-delete.d";

const { reducer, actions } = createApiModule(
    "eventDelete",

    ({ uuid }: PathParams) => `/ays-series-event-management/events/${uuid}`,
    { method: "DELETE" },
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid } }));
};

export const refetch = actions.refetch;

export const clear = actions.clear;

export default reducer;
