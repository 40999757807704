// @flow

import createApiModule from "../create-api-module";
import qs from "qs";

const { reducer, actions } = createApiModule(
    "venueStaffingTemplates",
    ({ venue, lineTypes }) =>
        `/ays-venue-facility-management/staffing-templates?${qs.stringify({
            venue,
            lineTypes,
        })}`,
);

export const ERROR = "Could not get staffing templates";
export const load =
    (venueUuid: string, lineTypes: string[]) => (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { venue: venueUuid, lineTypes: lineTypes.join(",") },
            }),
        );
    };
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
