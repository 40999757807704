//@flow

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";
import Dashboard from "@material-ui/icons/Dashboard";
import React from "react";

import FabContainer from "../../../FabContainer/FabContainer";

type Props = {
    translate: *,
    acl: *,
    onOpenLegend: *,
    onAddEvent: *,
    onAddOption: *,
};

const CalendarViewActions = ({
    acl,
    translate,
    onAddEvent,
    onOpenLegend,
    onAddOption,
}: Props) => (
    <FabContainer>
        <Fab
            id="show-legend"
            variant="extended"
            color="primary"
            onClick={onOpenLegend}
            style={{ marginRight: "auto" }}
        >
            <Dashboard />
            {translate("LEGEND")}
        </Fab>
        {acl("calendarOverview.update") && (
            <>
                <Fab
                    id="add-option"
                    variant="extended"
                    onClick={onAddOption}
                    color="secondary"
                >
                    <AddIcon />
                    {translate("Add Option")}
                </Fab>
                <Fab
                    id="add-event"
                    variant="extended"
                    color="primary"
                    onClick={onAddEvent}
                >
                    <AddIcon />
                    {translate("Add event")}
                </Fab>
            </>
        )}
    </FabContainer>
);

export default CalendarViewActions;
