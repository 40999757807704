// @flow

/**
 * Module: Add option
 */

import createApiModule from "../create-api-module";

import { type PathParams } from "./add-option.d";

const { reducer, actions } = createApiModule(
    "addOption",
    ({ serieUuid }: PathParams) =>
        `/ays-series-event-management/series/${serieUuid}/options`,
    {
        method: "POST",
    },
);

export const load =
    (serieUuid: string, dates: *, promoterUuid: *) => (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { serieUuid },
                data: {
                    dates,
                    promoterUuid,
                },
            }),
        );
    };

export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not add option!";
/** REDUCER **/
export default reducer;
