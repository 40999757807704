// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WkIZfUCtlIDE8rlVxGDAlg\\=\\={margin:1em;text-align:center}\n", "",{"version":3,"sources":["webpack://./../AddRecruit.scss"],"names":[],"mappings":"AAEA,4BACI,UAAW,CACX,iBAAkB","sourcesContent":["// Add recruit style\n\n.loading {\n    margin: 1em;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "WkIZfUCtlIDE8rlVxGDAlg=="
};
export default ___CSS_LOADER_EXPORT___;
