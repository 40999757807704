// @flow

import axios, { type Axios, type AxiosXHRConfig } from "axios";
import { type Store } from "redux";

import { refreshToken, clear } from "../../redux/modules/user";

import device from "../device";
import tokenValid from "./validate";

declare var __PLATFORM__: string;
declare var __VERSION__: string;

type StoreGetter = () => Store<*, *>;

/**
 * Base api class
 */
export default class Base {
    _storeGetter: StoreGetter;
    _isRefreshing = false;
    _failedQueue = [];

    instance: Axios;
    appAuth: string;

    /**
     * Constructor
     */
    constructor(baseURL: string, storeGetter: StoreGetter, appAuth: string) {
        this._storeGetter = storeGetter;
        this.appAuth = appAuth;

        const instance = axios.create({
            baseURL,
        });

        // Set default headers
        instance.defaults.headers.common["Accept"] =
            "application/json; charset=utf-8";
        instance.defaults.headers.common["Platform"] = __PLATFORM__;
        instance.defaults.headers.common["Version"] = __VERSION__;

        // Add middleware
        instance.interceptors.request.use(
            this.languageHeaderInterceptor.bind(this),
        );
        instance.interceptors.request.use(
            this.deviceInfoInterceptor.bind(this),
        );
        instance.interceptors.request.use(this.authInterceptor.bind(this));

        this.instance = instance;
    }

    /**
     * Get the store
     */
    get store(): Store<*, *> {
        return this._storeGetter();
    }

    /**
     * Interceptor: Request: Add language header
     */
    languageHeaderInterceptor(request: any): AxiosXHRConfig<*> {
        return {
            ...request,
            headers: {
                ...request.headers,
                common: {
                    ...request.headers.common,
                    "Accept-Language": this.store.getState().language,
                },
            },
        };
    }

    /**
     * Interceptor: Request: Inject device info
     */
    deviceInfoInterceptor(request: any): AxiosXHRConfig<*> {
        return ({
            ...request,
            headers: {
                ...request.headers,
                common: {
                    ...request.headers.common,
                    "Device-Id": btoa(JSON.stringify(device())),
                },
            },
        }: any);
    }

    processQueue(error: *, token: * = null) {
        this._failedQueue.forEach(prom => {
            if (error) {
                this.store.dispatch(clear());
                prom.reject(error);
            } else {
                //$FlowFixMe[incompatible-call]
                prom.resolve(token);
            }
        });

        this._failedQueue = [];
    }

    /**
     * Add authentication to next auth request
     */
    async authInterceptor(request: any): any {
        // There is already a header present. Skip
        if (
            request.headers.Authorization ||
            ["authentication", "registration"].some(el =>
                request.url.includes(el),
            )
        ) {
            return request;
        }

        if (!this.store.getState().user.get("data")) {
            return;
        }

        const token = this.store
            .getState()
            .user.getIn(["data", "auth", "token"]);
        const refresh = this.store
            .getState()
            .user.getIn(["data", "auth", "refreshToken"]);

        if (token) {
            if (!token || !tokenValid(token)) {
                if (tokenValid(refresh)) {
                    if (this._isRefreshing) {
                        //$FlowFixMe[missing-annot]
                        return new Promise((resolve, reject) => {
                            this._failedQueue.push({ resolve, reject });
                        })
                            .then(token => {
                                request.headers.common["Authorization"] =
                                    "Bearer " + token;
                                return request;
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            });
                    }

                    this._isRefreshing = true;

                    return new Promise((resolve, reject) => {
                        this.store
                            //$FlowFixMe[prop-missing]
                            .dispatch(refreshToken())
                            //$FlowFixMe[prop-missing]
                            .then(response => {
                                this.processQueue(null, response.token);
                                request.headers.common["Authorization"] =
                                    "Bearer " + response.token;
                                resolve(request);
                            })
                            .catch(error => {
                                this.processQueue(error, null);
                                reject(error);
                            })
                            .finally(() => {
                                this._isRefreshing = false;
                            });
                    });
                } else {
                    // Can never refresh, logout
                    this.store.dispatch(clear());
                    // window.location.href = "/";
                    throw new Error("Logging out.");
                }
            }
        }

        request.headers.common["Authorization"] = "Bearer " + token;
        return request;
    }
}
