// @flow

//import styles
import styles from "./ConfirmOptions.scss";

//import libs
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

//import Components
import Options from "./Options/Options";
import SelectList from "./Options/SelectList";

type Props = {
    translate: *,
    /** Trigger modal close */
    onClose: () => void,
    /** Trigger */
    onSubmit: (option: *) => void,
    options?: *,
    loading: boolean,
    confirmOptionsState: *,
};

type State = {
    options: *,
    globalStatus: string,
    eventName: string,
    showPast: boolean,
    optionCode: string,
};

/**
 * confirm options dialog
 */
export default class ConfirmOptions extends Component<Props, State> {
    static getDerivedStateFromProps(props: Props) {
        return {
            eventName: props.options && props.options.seriesName,
            optionCode: props.options && props.options.seriesOptionCode,
            options: props.options && props.options.options,
        };
    }

    state = {
        options: [],
        globalStatus: "",
        eventName: "",
        showPast: false,
        optionCode: "",
    };

    /** Ref to fire onChangeAll function in options component */
    optionsRef: * = React.createRef();

    /**
     * Disabled getter
     */
    get disabled() {
        const { confirmOptionsState } = this.props;
        return confirmOptionsState.loading;
    }
    /**
     * Set Status for all options
     */
    onChangeAll = (value: string) => {
        this.optionsRef.current && this.optionsRef.current.onChangeAll(value);
        this.setState({
            ...this.state,
            globalStatus: value,
        });
    };

    /**
     * Render
     */
    render() {
        const { translate, onClose, loading, confirmOptionsState, onSubmit } =
            this.props;
        const { options, globalStatus, eventName, showPast, optionCode } =
            this.state;

        return (
            <Dialog open maxWidth="md" className={styles.element}>
                <DialogTitle disableTypography className={styles.title}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant="h2" color="inherit">
                                {translate("Confirm options")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                color="inherit"
                                style={{ opacity: "0.5" }}
                            >
                                {translate(
                                    "Choose which options you would like to confirm for the series:",
                                )}
                            </Typography>
                            <FormControlLabel
                                value="showPast"
                                label={translate("Show past options")}
                                color="inherit"
                                style={{ opacity: "0.5" }}
                                control={
                                    <Checkbox
                                        onChange={e => {
                                            this.setState({
                                                ...this.state,
                                                showPast: e.target.checked,
                                            });
                                        }}
                                        style={{ color: "#fff" }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={styles.disabledInput}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="confirmoptions-seriesname"
                                label="Event series"
                                value={`${eventName || ""}${
                                    eventName && optionCode ? " - " : ""
                                }${optionCode || ""}`}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid
                            color="inherit"
                            style={{
                                opacity: "0.5",
                                display: "flex",
                                alignItems: "center",
                            }}
                            item
                            xs={6}
                            offset={6}
                        >
                            <Typography
                                color="inherit"
                                style={{ flexShrink: 0, marginRight: "1em" }}
                            >
                                {translate("Change all to: ")}
                            </Typography>
                            <SelectList
                                id="confirmoptions-setStatusToAll"
                                onSelect={value => this.onChangeAll(value)}
                                venueCode="SEA-BLUE"
                                optionStatus={globalStatus}
                                disabled={this.disabled}
                                color="light"
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {loading && (
                        <Grid container style={{ alignItems: "flex-center" }}>
                            <CircularProgress className={styles.spinner} />
                        </Grid>
                    )}

                    {!loading && options && (
                        <Options
                            options={options}
                            translate={translate}
                            seriesName={eventName}
                            optionCode={optionCode}
                            onSubmit={onSubmit}
                            ref={this.optionsRef}
                            confirmOptionsState={confirmOptionsState}
                            disabled={this.disabled}
                            showPast={showPast}
                        />
                    )}
                </DialogContent>

                <DialogActions>
                    {confirmOptionsState && confirmOptionsState.error && (
                        <FormHelperText error>
                            {confirmOptionsState.error}
                        </FormHelperText>
                    )}
                    <Grid item xs="auto">
                        <Button id="editoption-cancel" onClick={onClose}>
                            {translate("Cancel")}
                        </Button>
                    </Grid>

                    <Grid item xs="auto">
                        <Button
                            id="editoption-submit"
                            form="confirmOptions"
                            type="submit"
                            color="primary"
                            disabled={this.disabled}
                        >
                            {translate("Confirm")}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}
