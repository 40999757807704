// @flow

import createApiModule from "../create-api-module";
import { type NewUser } from "./admin-user-add.d";

/**
 * Module: Add Show
 */

const { reducer, actions } = createApiModule(
    "adminUserAdd",
    () => `/ays-user-management/users`,
    {
        method: "POST",
    },
);

export const load = (data: NewUser) => (dispatch: *) => {
    return dispatch(
        actions.load({
            data,
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not add user!P";
/** REDUCER **/
export default reducer;
