// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { unpack } from "../utils/immutable-tools";

import {
    addNotification,
    removeNotification,
} from "../redux/modules/notifications";
import NotificationManager from "../components/NotificationManager/NotificationManager";

import { withTranslate } from "./Translate";

export default withTranslate(
    connect(
        // Map state to props
        ({ notifications }) => unpack({ notifications }),
        // Map dispatch to props
        (dispatch: *) =>
            bindActionCreators(
                {
                    add: (message, type) => addNotification(message, type),
                    remove: uuid => removeNotification(uuid),
                },
                dispatch,
            ),
    )(NotificationManager),
);
