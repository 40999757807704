// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "facilityViewEvents",
    () => "ays-searches/searches/facilityview/events",
    {
        method: "POST",
    },
);

export const load =
    (
        venueUuid: string,
        lineTypeUuid: string,
        startDate: string,
        endDate: string,
    ) =>
    (dispatch: *) => {
        localStorage.setItem("ays.facility-view.startDate", startDate);
        localStorage.setItem("ays.facility-view.endDate", endDate);
        return dispatch(
            actions.load({
                data: {
                    venueUuid,
                    lineTypeUuid,
                    startDate,
                    endDate,
                },
            }),
        );
    };

export const ERROR = "Could not fetch events";
export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
