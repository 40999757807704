// @flow

import { parseNumber, formatNumber } from "libphonenumber-js";

export const format = (
    input: string,
    defaultCountry: string = "BE",
    format: string = "E.164",
): string => {
    try {
        return formatNumber(parseNumber(input, defaultCountry), format);
    } catch (_) {
        return input;
    }
};
