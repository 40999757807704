//@flow
import styles from "./PresenceOverview.scss";

import React, { Component } from "react";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DescriptionIcon from "@material-ui/icons/Description";

import { type State as eventCSVDataState } from "../../../redux/modules/event-csvdata.d";
import { type State as TotalsState } from "../../../redux/modules/attendees-total.d";

type Props = {
    acl: *,
    uuid: string,
    translate: *,
    loadCSV: (uuid: string, force: boolean) => *,
    eventCSVData: eventCSVDataState,
    totals: TotalsState,
};

export default class PresenceOverview extends Component<Props> {
    render() {
        const { translate, acl, eventCSVData, loadCSV, uuid, totals } =
            this.props;

        return (
            <div className={styles.main}>
                <Paper className={styles.wrapper} elevation={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={styles.title}>
                                {translate("Overview")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid item xs={12}>
                                <Typography className={styles.subtitle}>
                                    {translate("Expected")}
                                </Typography>
                                <span className={styles.expected} />
                            </Grid>
                            <Grid item xs={12}>
                                {totals.loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Typography variant="h2">
                                        {totals.data &&
                                            totals.data.totalExpected}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2} className={styles.border}>
                            <Grid item xs={12}>
                                <Typography className={styles.subtitle}>
                                    {translate("Checked in")}
                                </Typography>
                                <span className={styles.checkedin} />
                            </Grid>
                            <Grid item xs={12}>
                                {totals.loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Typography
                                        variant="h2"
                                        style={{ color: "#000" }}
                                    >
                                        {totals.data &&
                                            totals.data.totalPresent}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {totals.loading ? (
                                <CircularProgress />
                            ) : (
                                <div className={styles.misc}>
                                    <Grid item xs={12}>
                                        <Typography className={styles.subtitle}>
                                            {translate("Checked out")}:{" "}
                                            {totals.data &&
                                                totals.data.totalCheckedOut}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={styles.subtitle}>
                                            {translate("Not present")}:{" "}
                                            {totals.data &&
                                                totals.data.totalNotPresent}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={styles.subtitle}>
                                            {translate("Guests")}:{" "}
                                            {totals.data &&
                                                totals.data.totalGuests}
                                        </Typography>
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {acl("events.csv.read") && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    aria-label="Add"
                                    id="download-csv-data"
                                    className={styles.button}
                                    disabled={
                                        eventCSVData.loading ||
                                        !acl("events.csv.read")
                                    }
                                    onClick={() => loadCSV(uuid, true)}
                                >
                                    <div className={styles.buttoninner}>
                                        <DescriptionIcon />
                                        <Typography
                                            variant={"body2"}
                                            color="inherit"
                                            className={styles.buttontext}
                                        >
                                            {translate("Export data")}
                                        </Typography>
                                    </div>
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}
