// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x5AgNUQ-pBrkN9w6F6twZA\\=\\={overflow:hidden;margin:5em;padding:1.5em 3em;border-radius:0.25em}._5tnIc-8n04IbkBcJO6ag7Q\\=\\={clear:both;overflow:hidden}.Vz8B8YM22LLL\\+jrvEFIgqQ\\=\\={float:right;padding-top:1em;overflow:hidden}._510Rw1GuQ7rDhvnThtibbg\\=\\={margin:1em;display:block;height:1em}.XJtiyuJWWS0P381y4S-81A\\=\\={display:block;height:0.5em}.HsY0Zk-YaMOM3uX-Elm0aQ\\=\\={text-align:center;margin:auto}\n", "",{"version":3,"sources":["webpack://./../EventDetails.scss"],"names":[],"mappings":"AAMA,4BACI,eAAgB,CAChB,UAAW,CACX,iBAAkB,CAClB,oBAAqB,CACxB,6BAGG,UAAW,CACX,eAAgB,CACnB,6BAEG,WAAY,CACZ,eAAgB,CAChB,eAAgB,CACnB,6BAGG,UAAW,CACX,aAAc,CACd,UAAW,CACd,4BAGG,aAAc,CACd,YAAa,CAChB,4BAGG,iBAAkB,CAClB,WAAY","sourcesContent":["/**\n * Detailsform styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    overflow: hidden;\n    margin: 5em;\n    padding: 1.5em 3em;\n    border-radius: 0.25em;\n}\n\n.form {\n    clear: both;\n    overflow: hidden;\n}\n.actionButtons {\n    float: right;\n    padding-top: 1em;\n    overflow: hidden;\n}\n\n.divider {\n    margin: 1em;\n    display: block;\n    height: 1em;\n}\n\n.titleDivider {\n    display: block;\n    height: 0.5em;\n}\n\n.spinner {\n    text-align: center;\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "x5AgNUQ-pBrkN9w6F6twZA==",
	"form": "_5tnIc-8n04IbkBcJO6ag7Q==",
	"actionButtons": "Vz8B8YM22LLL+jrvEFIgqQ==",
	"divider": "_510Rw1GuQ7rDhvnThtibbg==",
	"titleDivider": "XJtiyuJWWS0P381y4S-81A==",
	"spinner": "HsY0Zk-YaMOM3uX-Elm0aQ=="
};
export default ___CSS_LOADER_EXPORT___;
