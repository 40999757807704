// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7M1GE3pWRHZAf\\+vRPc9dEg\\=\\={margin-top:0.5em}.lNHP46E65OwCEKmKI6Ackw\\=\\={margin-right:auto !important}\n", "",{"version":3,"sources":["webpack://./../Dialog.module.scss"],"names":[],"mappings":"AAAA,8BACI,gBAAiB,CACpB,4BAGG,4BAA6B","sourcesContent":[".headerExtra {\n    margin-top: 0.5em;\n}\n\n.backBtn {\n    margin-right: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerExtra": "_7M1GE3pWRHZAf+vRPc9dEg==",
	"backBtn": "lNHP46E65OwCEKmKI6Ackw=="
};
export default ___CSS_LOADER_EXPORT___;
