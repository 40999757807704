// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4KpYIAEZHeLBoJd3Auyn7g\\=\\={max-height:28em;overflow-y:auto}._6voHb2haNt4iaYhFAkQWvA\\=\\= span,.GpDLajgmUIw5rTKhR4JapQ\\=\\= span{color:#651f82}.HdEs-c6mDPIfWZRXycZdng\\=\\= span{color:#888}\n", "",{"version":3,"sources":["webpack://./../LineSelector.scss","webpack://./../../../styles/_variables.scss"],"names":[],"mappings":"AAIA,6BACI,eAAgB,CAChB,eAAgB,CACnB,mEAGG,aCDY,CDEf,iCAEG,UCLe","sourcesContent":["// LineSelector styling\n\n@import \"../../styles/variables\";\n\n.scroll {\n    max-height: 28em;\n    overflow-y: auto;\n}\n.add span,\n.selected span {\n    color: $purple;\n}\n.cancel span {\n    color: $neutral-grey;\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "_4KpYIAEZHeLBoJd3Auyn7g==",
	"add": "_6voHb2haNt4iaYhFAkQWvA==",
	"selected": "GpDLajgmUIw5rTKhR4JapQ==",
	"cancel": "HdEs-c6mDPIfWZRXycZdng=="
};
export default ___CSS_LOADER_EXPORT___;
