// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tKopGYD5l-effd2wD001BA\\=\\={margin:2em}.wgyB4Y5sTUu9NgAQIPKliA\\=\\={overflow:hidden}\n", "",{"version":3,"sources":["webpack://./../Team.scss"],"names":[],"mappings":"AAEA,4BACI,UAAW,CACd,4BAGG,eAAgB","sourcesContent":["// Team styling\n\n.wrapper {\n    margin: 2em;\n}\n\n.inner {\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "tKopGYD5l-effd2wD001BA==",
	"inner": "wgyB4Y5sTUu9NgAQIPKliA=="
};
export default ___CSS_LOADER_EXPORT___;
