// @flow

import styles from "./FilterHeader.scss";

import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, type RouterHistory } from "react-router";
import moment from "moment";

import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { withTranslate } from "../Translate";

import { type State as VenuesState } from "../../redux/modules/facility-view-venues.d";
import { type Profile } from "../../redux/modules/user.d";
import { clear, load, ERROR } from "../../redux/modules/facility-view-venues";
import DateField from "./DateField";
import VenueBox from "../../components/VenueBox/VenueBox";
import { mapState } from "../../redux/utils";
import { ROLE_FACILITY_MANAGER } from "../../permissions";

type Props = {
    translate: *,
    history: RouterHistory,
    venues: VenuesState,
    user: Profile,
    selectedVenue: string,
    selectedLineType: string,
    startDate: string,
    endDate: string,
    loadVenues: () => void,
    clearVenues: () => void,
    onChange: (field: string, value: string) => void,
};

type StateType = {
    filterVenues: [],
};

class FilterHeader extends Component<Props, StateType> {
    state = {
        filterVenues: this.props.venues.data,
    };

    componentDidMount() {
        const { loadVenues } = this.props;
        loadVenues();
    }

    get venues() {
        const { venues, user } = this.props;
        const roles = user.authorities;
        let filter = venues?.data;
        if (roles.includes(ROLE_FACILITY_MANAGER)) {
            filter =
                user["facility_manager_venues"] &&
                venues.data &&
                venues.data.filter(venue =>
                    user["facility_manager_venues"].includes(venue.uuid),
                );
        }
        return filter;
    }

    get lineTypes() {
        const { venues, user } = this.props;
        const roles = user.authorities;

        if (this.props.selectedVenue === "" || !venues.data) {
            return [];
        }

        const selectedVenue =
            venues.data &&
            venues.data.find(venue => venue.uuid === this.props.selectedVenue);

        const lines = roles.includes(ROLE_FACILITY_MANAGER)
            ? selectedVenue?.lineTypes.filter(lineType =>
                  user["facility_manager_line_types"].includes(lineType.uuid),
              )
            : selectedVenue?.lineTypes;

        return lines ? this.sortedLineTypes(lines.slice()) : [];
    }

    sortedLineTypes(lineTypes: *) {
        return (
            lineTypes &&
            lineTypes.sort((a, b) => {
                let fa = a.name,
                    fb = b.name;

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        );
    }

    getVenueCode(venueName: string) {
        const a = venueName.split(" ");
        const b = a[0].charAt(0) + a[1].charAt(0);
        return b;
    }

    render() {
        const {
            venues,
            translate,
            history,
            selectedVenue,
            selectedLineType,
            startDate,
            endDate,
            onChange,
        } = this.props;

        return (
            <Fragment>
                <div className={styles.fields}>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.subFields}>
                            <FormControl
                                className={styles.field}
                                disabled={venues.loading || !venues.data}
                            >
                                <Select
                                    disableUnderline
                                    className={styles.venueSelect}
                                    id="venue"
                                    value={selectedVenue}
                                    name="selectedVenue"
                                    onChange={event => {
                                        history.push(
                                            `/events/facility-view/${event.target.value}/`,
                                        );
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        {translate("Select venue")}
                                    </MenuItem>
                                    {this.venues?.map(venue => (
                                        <MenuItem
                                            key={venue.uuid}
                                            value={venue.uuid}
                                        >
                                            <ListItemIcon>
                                                <div className={styles.item}>
                                                    <VenueBox
                                                        venue={{
                                                            uuid: venue.uuid,
                                                            name: venue.name,
                                                            code: this.getVenueCode(
                                                                venue.name,
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </ListItemIcon>
                                            {venue.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                className={styles.field}
                                disabled={!selectedVenue}
                            >
                                <Select
                                    disableUnderline
                                    className={styles.facilitySelect}
                                    id="line-type"
                                    value={selectedLineType}
                                    name="selectedLineType"
                                    onChange={event => {
                                        history.push(
                                            `/events/facility-view/${selectedVenue}/${event.target.value}/`,
                                        );
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        {translate("Select line type")}
                                    </MenuItem>
                                    {this.lineTypes?.map(lineType => (
                                        <MenuItem
                                            key={lineType.uuid}
                                            value={lineType.uuid}
                                        >
                                            {lineType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={styles.subFields}>
                            <span>{translate("From")}:</span>
                            <DateField
                                className={styles.datefield}
                                value={startDate}
                                allowPast
                                onChange={value => {
                                    if (
                                        moment(value)
                                            .add(6, "months")
                                            .isBefore(endDate)
                                    ) {
                                        onChange(
                                            "endDate",
                                            moment(value)
                                                .add(6, "months")
                                                .toISOString(),
                                        );
                                    }
                                    onChange("startDate", value);
                                }}
                                fullWidth
                            />
                            <ArrowDropDown className={styles.firstArrow} />
                            <span>{translate("Until")}:</span>
                            <DateField
                                className={styles.datefield}
                                value={endDate}
                                allowPast
                                onChange={value => {
                                    if (
                                        moment(value)
                                            .subtract(6, "months")
                                            .isAfter(startDate)
                                    ) {
                                        onChange(
                                            "startDate",
                                            moment(value)
                                                .subtract(6, "months")
                                                .toISOString(),
                                        );
                                    }
                                    onChange("endDate", value);
                                }}
                                fullWidth
                            />
                            <ArrowDropDown className={styles.secondArrow} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            ({ facilityViewVenues }) => ({
                venues: mapState(facilityViewVenues, ERROR, "venues"),
            }),
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadVenues: load,
                        clearVenues: clear,
                    },
                    dispatch,
                ),
        )(FilterHeader),
    ),
);
