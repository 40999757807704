// @flow

import React from "react";
import { Text, View } from "@react-pdf/renderer";

import Status from "../Status";

type Props = {
    event: any,
    venueCode: string,
};

const SOFT_HYPHEN = "\xAD";

/**
 * Add extra hyphens
 */
const addHyphenation = input => {
    const words = input.split(" ").map(word => {
        if (word.length > 10) {
            const chuncks = word.match(/.{1,10}/g);

            if (chuncks) {
                return chuncks.join(SOFT_HYPHEN);
            }
        }

        return word;
    });

    return words.join(" ");
};

/**
 * Fetch the text. Should trunkate to 35 in future
 */
const getEventText = event => {
    let result = "";

    if (event.name) {
        result += `${event.name} - `;
    }

    result += event.seriesName || event.seriesOptionCode;

    if (event.internal) {
        result += ` - (${event.internal})`;
    }

    return addHyphenation(result);
};

const Named = ({ event, venueCode }: Props) => (
    <View wrap={false} style={{ position: "relative", marginBottom: 5 }}>
        <View style={{ position: "absolute", left: "0", top: 0 }}>
            <Status venueCode={venueCode} status={event.status} />
        </View>
        <Text style={{ paddingLeft: 15, width: "100%" }}>
            {getEventText(event)}
        </Text>
    </View>
);

export default Named;
