// @flow

import UAParser from "ua-parser-js";

type UAInfo = {
    ua: ?string,
    browser: {
        name: ?string,
        version: ?string,
    },
    engine: {
        name: ?string,
        version: ?string,
    },
    os: {
        name: ?string,
        version: ?string,
    },
    device: {
        model: ?string,
        type: ?string,
        vendor: ?string,
    },
    cpu: {
        architecture: ?string,
    },
};

type ScreenInfo = {
    screen: {
        width: ?string,
        height: ?string,
    },
};

type DeviceInfo = UAInfo & ScreenInfo;

const UA_INFO: UAInfo = (new UAParser().getResult(): any);

/**
 * Device info getter
 */
export default (): DeviceInfo => ({
    ...UA_INFO,
    screen: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
});
