//@flow
import styles from "./Day.scss";

import classnames from "classnames";
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import DayItem from "./DayItem";

import MOMENT from "moment";
import { extendMoment } from "moment-range";

import { type State as VenueState } from "../../../../../redux/modules/venues.d";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    translate: *,
    day: *,
    calendarDay: *,
    venues: VenueState,
    collapsedVenues: string[],
    activeEvent: ?{
        event: string,
        series: string,
    },
    clearActiveEvent: () => void,
    activateEvent: (eventUuid: string, seriesUuid: string) => void,
    toggleActiveEvent: (eventUuid: string, seriesUuid: string) => void,
    onConfirmOptions: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: stirng) => void,
    isShowCanceled: boolean,
    acl: *,
    user: Profile,
};

const moment = extendMoment(MOMENT);

export default class Day extends Component<Props> {
    /**
     * Render
     */
    render() {
        const {
            translate,
            day,
            calendarDay,
            venues,
            collapsedVenues,
            activateEvent,
            activeEvent,
            clearActiveEvent,
            toggleActiveEvent,
            onConfirmOptions,
            onEditOption,
            onCancelOption,
            onEditSeries,
            onEditEvent,
            onMoveEvent,
            onEditConfirmedOption,
            onDefineEvents,
            onCancelConfirmedOption,
            isShowCanceled,
            onCancelEvent,
            onAddOptionToSeries,
            onAddEventToSeries,
            onShowHistory,
            onDoubleClickEvent,
            acl,
            user,
        } = this.props;

        return (
            <Grid
                key={moment(day).format("YYYY/MM/DD")}
                id={moment(day).format("YYYY/MM/DD")}
                container
                spacing={0}
                wrap={"nowrap"}
                className={classnames(
                    styles.container,
                    {
                        weekend:
                            moment(day).isoWeekday() === 6 ||
                            moment(day).isoWeekday() === 7,
                    },
                    {
                        today:
                            moment(day).format("YYYY/MM/DD") ===
                            moment(new Date()).format("YYYY/MM/DD"),
                    },
                )}
            >
                <Grid item xs={3} className={styles.spacing}>
                    <Grid container spacing={0} direction="row">
                        <Grid item xs={6}>
                            <Typography
                                style={{
                                    textAlign: "right",
                                    opacity: "0.5",
                                }}
                            >
                                {moment(day).format("ddd")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                style={{
                                    textAlign: "left",
                                    paddingLeft: "1em",
                                    fontWeight: "bold",
                                }}
                            >
                                {moment(day).format("DD/MM/YY")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {(venues.data || []).map(venue => (
                    <DayItem
                        acl={acl}
                        user={user}
                        translate={translate}
                        onCancelEvent={onCancelEvent}
                        isShowCanceled={isShowCanceled}
                        onCancelConfirmedOption={onCancelConfirmedOption}
                        key={`${moment(day).format("YYYY-MM-DD")}-${
                            venue.code
                        }`}
                        collapsedVenues={collapsedVenues}
                        venue={venue}
                        id={`${moment(day).format("YYYY-MM-DD")}-${venue.code}`}
                        day={day.format("DD/MM/YYYY")}
                        calendarDay={calendarDay}
                        activeEvent={activeEvent}
                        activateEvent={activateEvent}
                        clearActiveEvent={clearActiveEvent}
                        toggleActiveEvent={toggleActiveEvent}
                        onConfirmOptions={onConfirmOptions}
                        onEditOption={onEditOption}
                        onCancelOption={onCancelOption}
                        onAddOptionToSeries={onAddOptionToSeries}
                        onAddEventToSeries={onAddEventToSeries}
                        onEditSeries={onEditSeries}
                        onEditEvent={onEditEvent}
                        onMoveEvent={onMoveEvent}
                        onEditConfirmedOption={onEditConfirmedOption}
                        onDefineEvents={onDefineEvents}
                        onShowHistory={onShowHistory}
                        onDoubleClickEvent={onDoubleClickEvent}
                    />
                ))}
            </Grid>
        );
    }
}
