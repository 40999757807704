//@flow

import React, { useState } from "react";
import StyledFormControlLabel from "../StyledFormControlLabel";
import {
    Checkbox,
    Divider,
    FormGroup,
    FormHelperText,
} from "@material-ui/core";

type Props = {
    translate: *,
    data: { label: string, id: string }[],
    selected?: string[],
    onChange: (selected: string[]) => void,
    emptyLabel: string,
    busy?: boolean,
};

const CheckboxGroup = ({
    translate,
    data,
    selected: selectedProp = [],
    onChange,
    emptyLabel,
    busy,
}: Props) => {
    const [selected, setSelected] = useState(selectedProp);

    return data.length !== 0 ? (
        <FormGroup>
            <StyledFormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={data.length === selected.length}
                        onChange={({ target: { checked } }) => {
                            const _selected = checked
                                ? data.map(item => item.id)
                                : [];

                            onChange(_selected);
                            setSelected(_selected);
                        }}
                        disabled={busy}
                    />
                }
                label={translate("Select all")}
            />
            <Divider />
            {data.map(el => (
                <StyledFormControlLabel
                    key={el.id}
                    control={
                        <Checkbox
                            color="primary"
                            name={el.label}
                            checked={selected.includes(el.id)}
                            onChange={({ target: { checked } }) => {
                                const _selected = [...selected];

                                if (!checked) {
                                    _selected.splice(
                                        _selected.indexOf(el.id),
                                        1,
                                    );
                                } else {
                                    _selected.push(el.id);
                                }

                                onChange(_selected);
                                setSelected(_selected);
                            }}
                            disabled={busy}
                        />
                    }
                    label={el.label}
                />
            ))}
        </FormGroup>
    ) : (
        <FormHelperText>{emptyLabel}</FormHelperText>
    );
};

export default CheckboxGroup;
