// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

export const Client = {
    /**
     * Add show
     */
    addAllRecruits(uuid: string): Promise<void> {
        return this.instance
            .post(
                `/ays-series-event-management/recruitment-pools/${uuid}/_add-all`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
