//@flow

import createApiModule from "../create-api-module";
import qs from "qs";

import type { PathParams } from "./attendees.d";

const { reducer, actions } = createApiModule(
    "attendees",
    ({ eventUuid, page, sort }: PathParams) =>
        `ays-searches/searches/events/${eventUuid}/present-members?${qs.stringify(
            { page, sort },
            { arrayFormat: "repeat" },
        )}`,
    {
        method: "POST",
    },
);

export const load =
    (
        eventUuid: string,
        page: number,
        filter: string,
        sorting: string[],
        presenceFilter: string,
        force: boolean,
    ) =>
    (dispatch: *) => {
        return dispatch(
            actions.load(
                {
                    path: { eventUuid, page, sort: sorting },
                    data: { filter, presenceFilter },
                },
                force,
            ),
        );
    };
export const ERROR = "No attendees found!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
