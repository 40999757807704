// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { typeof Client } from "../../utils/api-client/modules/event-recruitment";
import {
    type ImmutableState,
    type Action,
    type FullState,
} from "./event-recruitment.d";

const ERROR = "Could not fetch recuitment pools";

const ZONE = "event-recruitment";
const CLEAR = `${ZONE}/clear`;
const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    data: null,
    uuid: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        invariant(typeof payload === "string", `Wrong payload for ${PENDING}`);

        return state.merge({
            loading: true,
            error: null,
            uuid: payload,
        });
    }

    if (type === SUCCESS) {
        invariant(payload, `Wrong payload for ${SUCCESS}`);

        return state.merge({
            loading: false,
            error: null,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        invariant(typeof payload === "string", `Wrong payload for ${FAILURE}`);

        return state.merge({
            loading: false,
            error: payload,
            data: null,
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load
 */
export const load =
    (uuid: string, force: boolean = false) =>
    (
        dispatch: (action: Action) => void,
        getState: () => FullState,
        client: Client,
    ): Promise<void> => {
        const { eventRecruitment: state } = getState();

        if (state.get("loading") || (!force && state.get("uuid") === uuid)) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: uuid,
        });

        return client
            .getEventRecruitmentPools(uuid)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };

/**
 * ActionCreator: reload
 */
export const reload = () => (dispatch: *, getState: *) =>
    dispatch(load(getState().eventRecruitment.get("uuid"), true));
