// @flow

import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

type Props = {
    venueCode: string,
};

const DEFAULT_COLOR = "#7343B9";

const COLORS = {
    AS: "#08ADFF",
    LA: "#EE3124",
    SA: "#8A6CD3",
    CG: "#17382D",
    VN: "#FFA75E",
    FN: "#FFA75E",
    TT: "#8CC63F",
    TA: "#8CC63F",
    EH: "#8CC63F",
    PA: "#5C2D91",
};

/**
 * Get Color props
 */
const getColorProps = venueCode => COLORS[venueCode] || DEFAULT_COLOR;

const getStyles = venueCode =>
    StyleSheet.create({
        venueLogo: {
            fontSize: 7,
            color: "white",
            fontWeight: 700,
            backgroundColor: getColorProps(venueCode),
            width: 15,
            height: 15,
            borderRadius: 4,
            textAlign: "center",
            paddingTop: 3.5,
            marginBottom: 2,
            letterSpacing: -0.8,
        },
    });

/**
 * Renders the VenueCode with colored background
 */
const VenueLogo = ({ venueCode }: Props) => {
    const styles = getStyles(venueCode);
    return (
        <View style={styles.venueLogo}>
            <Text>{venueCode}</Text>
        </View>
    );
};

export default VenueLogo;
