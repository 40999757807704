// @flow
import createApiModule from "../create-api-module";

import { type PathParams } from "./admin-user-delete.d";

/**
 * adminUserMemberships
 */
const { reducer, actions } = createApiModule(
    "adminUserMemberships",
    ({ userUuid }: PathParams) =>
        `/ays-organisation-management/memberships/_search/${userUuid}`,
);

export const load = (userUuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { userUuid },
        }),
    );
};

export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not find member organisations!";
/** REDUCER **/
export default reducer;
