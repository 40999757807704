// @flow

import createApiModule from "../create-api-module";

import { type PathParams } from "./edit-series.d";

export const ERROR = "Could not edit event series!";

const { reducer, actions } = createApiModule(
    "editSeries",
    ({ uuid }: PathParams) => `/ays-series-event-management/series/${uuid}`,
    {
        method: "PUT",
    },
);

export const load =
    (
        event: {
            internal: string,
            seriesName: string,
            promoterUuid: string,
            optionCode: string,
        },
        uuid: string,
    ) =>
    (dispatch: *) =>
        dispatch(
            actions.load({
                path: {
                    uuid,
                },
                data: {
                    internal: event.internal,
                    name: event.seriesName,
                    promoterUuid: event.promoterUuid,
                    optionCode: event.optionCode,
                },
            }),
        );
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
