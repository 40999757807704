// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import {
    type FullState,
    type ImmutableState,
    type State,
    type Action,
} from "./event-recruits-add-all.d";
import { typeof Client } from "../../utils/api-client/modules/event-recruits-add-all";

const ERROR = "Could not add all!";

const ZONE = "event-recruits-add-all";
const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;
const CLEAR = `${ZONE}/clear`;

const initialState: ImmutableState = (fromJS(
    ({
        loading: false,
        error: null,
    }: State),
): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === PENDING) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === SUCCESS) {
        return state.merge({
            loading: false,
            error: null,
        });
    }

    if (type === FAILURE) {
        invariant(payload, `Wrong payload for ${FAILURE}`);

        return state.merge({
            loading: false,
            error: payload.error || null,
        });
    }

    if (type === CLEAR) {
        return initialState;
    }

    return state;
};

/**
 * Actioncreator
 */
export const addAll =
    (uuid: string) =>
    (
        dispatch: (action: Action) => *,
        getState: () => FullState,
        client: Client,
    ): Promise<void> => {
        const { eventRecruitmentAddAll: state } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
        });

        return client
            .addAllRecruits(uuid)
            .then(() => dispatch({ type: SUCCESS }))
            .catch(error => {
                const payload = {};

                if (error.message) {
                    payload.error = error.message;
                } else {
                    payload.error = ERROR;
                }

                dispatch({
                    type: FAILURE,
                    payload,
                });

                throw error;
            });
    };

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});
