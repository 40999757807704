// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./update-staffing-template-name.d";

const { reducer, actions } = createApiModule(
    "updateStaffingTemplateName",
    ({ uuid }: PathParams) =>
        `/ays-venue-facility-management/staffing-templates/${uuid}/name`,
    { method: "PUT" },
);

export const ERROR = "Couldn't update staffing template name";

export const load = (uuid: string, name: string) => (dispatch: *) =>
    dispatch(
        actions.load({
            path: { uuid },
            data: { name },
        }),
    );

export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
