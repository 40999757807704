// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-line-default-organisation.d";

const { reducer, actions } = createApiModule(
    "eventLineDefaultOrganisation",
    ({ lineUUID }: PathParams) =>
        `/ays-venue-facility-management/lines/${lineUUID}/default-organisation`,
);

export const load =
    (lineUUID: string, organisationUUID: string) => (dispatch: *) => {
        return dispatch(
            actions.load(
                {
                    path: {
                        lineUUID,
                    },
                    data: {
                        defaultOrganisationUuid: organisationUUID,
                    },
                },
                true,
            ),
        ).then(({ payload }) => {
            return payload;
        });
    };
export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
