// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3kBaUmO7sV7dZgEPsOelDw\\=\\={margin-top:2em}.U4N\\+bwgyUZVahb1RVnSFWg\\=\\={color:rgba(0,0,0,0.54);font-size:0.637em;margin-bottom:1em}input[type=\"file\"]{position:absolute;z-index:0;top:0.6em;left:3.5em;color:rgba(0,0,0,0.54);font-size:0.85em}.uQ8Xz0V4TlvO\\+SgXjGMERQ\\=\\={position:relative}.PnwKBpjNMln-\\+TYNb7zpkw\\=\\={position:relative;z-index:1;background-color:#651f82;color:#fff;display:inline-block;padding:0.5em 1em;cursor:pointer;border-radius:0.2em;text-transform:uppercase;font-weight:700}.PnwKBpjNMln-\\+TYNb7zpkw\\=\\=:hover{background-color:#46155b}.XE3nf3FUAwJ8MVbqeuyAIQ\\=\\={border-radius:50%;margin-bottom:1em}\n", "",{"version":3,"sources":["webpack://./../UploadPhoto.scss"],"names":[],"mappings":"AAAA,6BACI,cAAe,CAClB,6BAGG,sBAA0B,CAC1B,iBAAkB,CAClB,iBAAkB,CACrB,mBAGG,iBAAkB,CAClB,SAAU,CACV,SAAU,CACV,UAAW,CACX,sBAA0B,CAC1B,gBAAiB,CACpB,6BAGG,iBAAkB,CACrB,6BAGG,iBAAkB,CAClB,SAAU,CACV,wBAAyB,CACzB,UAAW,CACX,oBAAqB,CACrB,iBAAkB,CAClB,cAAe,CACf,mBAAoB,CACpB,wBAAyB,CACzB,eAAgB,CACnB,mCAGG,wBAAiC,CACpC,4BAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    margin-top: 2em;\n}\n\n.title {\n    color: rgba(0, 0, 0, 0.54);\n    font-size: 0.637em;\n    margin-bottom: 1em;\n}\n\ninput[type=\"file\"] {\n    position: absolute;\n    z-index: 0;\n    top: 0.6em;\n    left: 3.5em;\n    color: rgba(0, 0, 0, 0.54);\n    font-size: 0.85em;\n}\n\n.buttonWrapper {\n    position: relative;\n}\n\n.button {\n    position: relative;\n    z-index: 1;\n    background-color: #651f82;\n    color: #fff;\n    display: inline-block;\n    padding: 0.5em 1em;\n    cursor: pointer;\n    border-radius: 0.2em;\n    text-transform: uppercase;\n    font-weight: 700;\n}\n\n.button:hover {\n    background-color: rgb(70, 21, 91);\n}\n\n.preview {\n    border-radius: 50%;\n    margin-bottom: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3kBaUmO7sV7dZgEPsOelDw==",
	"title": "U4N+bwgyUZVahb1RVnSFWg==",
	"buttonWrapper": "uQ8Xz0V4TlvO+SgXjGMERQ==",
	"button": "PnwKBpjNMln-+TYNb7zpkw==",
	"preview": "XE3nf3FUAwJ8MVbqeuyAIQ=="
};
export default ___CSS_LOADER_EXPORT___;
