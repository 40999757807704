// @flow

import styles from "./style.module.scss";

import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";

type Props = {
    translate: *,
    onClose: () => void,
    onDelete: (uuid: string) => void,
    name: string,
    deleteStaffingTemplateState: *,
};

const DeleteStaffingTemplate = ({
    onClose,
    translate,
    onDelete,
    name,
    deleteStaffingTemplateState,
}: Props) => {
    return (
        <Dialog onClose={onClose} open>
            <DialogTitle disableTypography>
                <Typography variant="h2">
                    {translate("Delete Staffing Template")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {deleteStaffingTemplateState.error && (
                    <DialogContentText color="error">
                        {deleteStaffingTemplateState.error}
                    </DialogContentText>
                )}
                <DialogContentText className={styles.warning}>
                    Are you sure you want to delete
                    <span> {name} </span>
                    from At Your Service?
                </DialogContentText>
                <DialogContentText>
                    {translate("(This action cannot be undone.)")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    id="modal-staffingtemplate-cancel"
                    onClick={onClose}
                    disabled={deleteStaffingTemplateState.loading}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    id="modal-staffingtemplate-confirm"
                    onClick={() => onDelete()}
                    color="secondary"
                    disabled={deleteStaffingTemplateState.loading}
                >
                    {translate("Delete staffing template")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteStaffingTemplate;
