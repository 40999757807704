// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H63EnT\\+NQGVFH2Go4Sj8OQ\\=\\={max-height:28em;overflow-y:auto}\n", "",{"version":3,"sources":["webpack://./../OrganisationSelector.scss"],"names":[],"mappings":"AAMA,6BACI,eAAgB,CAChB,eAAgB","sourcesContent":["/**\n * Modals styling\n */\n\n@import \"../../../styles/variables\";\n\n.scroll {\n    max-height: 28em;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "H63EnT+NQGVFH2Go4Sj8OQ=="
};
export default ___CSS_LOADER_EXPORT___;
