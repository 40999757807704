// @flow

import createApiModule from "../create-api-module";
import qs from "qs";
import moment from "moment";

import { type PathParams } from "./calendar.d";

// Very dirty hack
let lastStartDate = moment().startOf("month").format("YYYY-MM-DD");
let lastEndDate = moment().endOf("month").format("YYYY-MM-DD");

const { reducer, actions } = createApiModule(
    "calendar",
    ({ showCanceled }: PathParams) =>
        `ays-searches/searches/options-events?${qs.stringify(
            // Converts the value to url params
            { showCanceled },
            { arrayFormat: "repeat" },
        )}`,
    {
        method: "POST",
    },
);

export const load =
    (
        startDate: string = lastStartDate,
        endDate: string = lastEndDate,
        promoterUuid?: *,
        showCanceled?: boolean,
    ) =>
    (dispatch: *) => {
        // Very dirty cache
        lastStartDate = startDate;
        lastEndDate = endDate;
        return dispatch(
            actions.load({
                path: {
                    showCanceled,
                },
                data: {
                    startDate,
                    endDate,
                    promoterUuid,
                },
            }),
        );
    };
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
