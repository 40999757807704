// @flow

import createApiModule from "../create-api-module";

import { addNotification } from "./notifications";
import { normalizeError } from "../utils";
import { type CustomEventLine, type PathParams } from "./event-line-custom.d";

export const ERROR = "Could not create custom eventline";
const { reducer, actions } = createApiModule(
    "customEventLine",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/events/${uuid}/event-lines/custom`,
    {
        method: "POST",
    },
);
export const load =
    (uuid: string, customShowLineObj: CustomEventLine) => (dispatch: *) => {
        return dispatch(
            actions.load({
                path: {
                    uuid,
                },
                data: customShowLineObj,
            }),
        )
            .then(() =>
                dispatch(
                    addNotification(
                        "Successfully created custom eventline!",
                        "success",
                    ),
                ),
            )
            .catch(e => {
                const error = normalizeError(e);
                if (error.messages) {
                    dispatch(addNotification("Validation error!", "error"));
                } else if (error.message) {
                    dispatch(addNotification(e.message, "error"));
                } else {
                    dispatch(addNotification(ERROR, "error"));
                }
                throw e;
            });
    };
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
