// @flow

import createApiModule from "../create-api-module";
import type { PathParams } from "./event-line-openclose.d";

const { reducer, actions } = createApiModule(
    "eventLineOpenClose",
    ({ uuid, status }: PathParams) =>
        `/ays-series-event-management/event-lines/${uuid}/_${status}`,
    { method: "POST" },
);

export const load = (uuid: string, status: string) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid, status } }));
};
export const ERROR = "Could not open/close eventline!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
