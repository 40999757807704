//@flow
import styles from "./OrganisationList.scss";

import React, { Component } from "react";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "../../Table/Table";

import { type Organisations } from "../../../redux/modules/organisations.d";
import VenueBox from "../../VenueBox/VenueBox";
import FabContainer from "../../FabContainer/FabContainer";

type Props = {
    acl: *,
    translate: *,
    loading: boolean,
    error: ?string,
    data: ?Organisations,
    page: number,
    onPageChange: (n: number) => void,
    onSortChange: (sortString: string[]) => void,
    onAdd: () => void,
    onClick: (uuid: string) => void,
    // onDelete: (id: string) => void,
    onDelete: (uuid: string) => void,
    filter: string,
};

export default class OrganisationList extends Component<Props> {
    handleDelete(uuid: string) {
        const { onDelete } = this.props;
        onDelete(uuid);
    }

    handleClick(uuid: ?string) {
        const { onClick } = this.props;
        if (uuid) {
            onClick(uuid);
        }
    }

    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }

    get onlyError(): boolean {
        const { data, error } = this.props;

        return !!(!data && error);
    }

    /**
     * Render
     */
    render() {
        const {
            onAdd,
            page,
            data,
            error,
            loading,
            translate,
            acl,
            onDelete,
            onSortChange,
            onPageChange,
            filter,
        } = this.props;

        const config = {
            id: "organisation-list",
            uuidKey: "organisationUuid",
            hasMenu: true,
            onClick: item => this.handleClick(item.organisationUuid),
            configItems: [
                {
                    key: "organisationName",
                    displayName: translate("Name"),
                    sortable: true,
                    isNumeric: false,
                    defaultSort: "asc",
                    permission: true,
                },
                {
                    key: "organisationMembersCount",
                    displayName: translate("Members"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "venues",
                    customRender: (item: Object, index: number) => {
                        return item.venues.map(venue => {
                            return (
                                <VenueBox
                                    key={`${venue.uuid}-${index}`}
                                    venue={venue}
                                    color={"#298fc2"}
                                />
                            );
                        });
                    },
                    displayName: translate("Venue"),
                    sortable: false,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "preferredLinesCount",
                    displayName: translate("Preferred lines"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
            ],
            menuOptions: [
                {
                    id: "delete",
                    label: () => translate("Delete organisation"),
                    // onClick: item => this.handleDelete(item.organisationUuid),
                    onClick: item => onDelete(item.organisationUuid),
                    permission: () => acl("organisations.delete"),
                },
            ],
        };

        return (
            <div className={styles.main}>
                <Table
                    config={config}
                    loading={loading}
                    data={data}
                    page={page}
                    error={error}
                    translate={translate}
                    getSortString={string => onSortChange(string)}
                    onPageChange={p => onPageChange(p + 1)}
                    filter={filter}
                />
                {acl("organisations.create") && (
                    <FabContainer>
                        <Fab
                            id="organisation-list-add"
                            variant="extended"
                            color="primary"
                            onClick={onAdd}
                        >
                            <AddIcon />
                            {translate("Add organisation")}
                        </Fab>
                    </FabContainer>
                )}
                {loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
                {this.onlyError && (
                    <Paper elevation={2}>
                        <Typography color="error">{error}</Typography>
                    </Paper>
                )}
            </div>
        );
    }
}
