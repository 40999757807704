// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import stripslash from "../../utils/stripslash";
import debounce from "lodash.debounce";

import { type State } from "../../redux/modules/shows.d";
import { type State as DeleteState } from "../../redux/modules/event-delete.d";

import { load as remove } from "../../redux/modules/event-delete";
import { load, clear } from "../../redux/modules/shows";
import EventList from "../../components/Events/EventList/EventList";
import FilterField from "../../components/Authenticated/FilterField";
import NotFound from "../../components/NotFound";

import { withAcl } from "../Acl";
import { withTranslate } from "../Translate";
import isEqual from "lodash.isequal";
import DeleteEvent from "./DeleteEvent";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    events: State,
    eventDelete: DeleteState,
    load: (
        page: number,
        filter: string,
        sorting: string[],
        tab: number,
        force: boolean,
    ) => void,
    clear: () => void,
    remove: (uuid: string) => *,
    history: RouterHistory,
    onPageChange: (page: number) => void,
    onSelect: (id: string, zone?: string) => void,
};

type StateType = {
    filter: string,
    tab: number,
    sorting: string[],
};

const DEBOUNCE_WAIT = 250;
const DEBOUNCE_MAX_WAIT = 500;

class List extends Component<Props, StateType> {
    // Initial state
    state: StateType = {
        filter: this.props.events?.filter || "",
        tab: this.props.events?.tab || 0,
        sorting: this.props.events?.sorting || ["eventStart", "ASC"],
    };

    componentDidMount() {
        this.reload(true);
    }

    /**
     * Trigger load on update
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        if (
            prevProps.match.params.page !== match.params.page ||
            !isEqual(prevState, this.state)
        ) {
            this.reload();
        }
    }

    handleRemove(uuid: string) {
        const { remove } = this.props;
        remove(uuid).then(() => this.reload(true));
    }

    handleSortChange(sortString: string[]) {
        this.setState({
            sorting: sortString,
        });
    }

    /**
     * Handle modal close request
     */
    handleModalClose() {
        const { history, match } = this.props;
        history.push(`${stripslash(match.url)}`);
    }

    /**
     * Test page number integrity
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    /**
     * Trigger update
     */
    reload = debounce(
        (force: boolean = false) => {
            const { load, match } = this.props;
            const { filter, sorting, tab } = this.state;

            if (this.pageParamIsNumber) {
                load(parseInt(match.params.page), filter, sorting, tab, force);
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    /**
     * Render
     */
    render() {
        const {
            acl,
            onPageChange,
            match,
            events,
            onSelect,
            translate,
            eventDelete,
            history,
        } = this.props;
        const { filter, tab } = this.state;

        if (!this.pageParamIsNumber) {
            return <NotFound translate={translate} />;
        }

        const canRead = acl("events.read");
        const canQR = acl("events.qrcode.read");
        const canDelete = acl("events.delete");

        const onChange = filter => {
            this.setState({ filter });
        };

        return (
            <div>
                <span style={{ marginTop: "1em", display: "block" }} />
                <FilterField
                    onChange={onChange}
                    id="shows-filter-input"
                    value={filter}
                    placeholder={translate("Filter by name or venues")}
                />
                <EventList
                    acl={acl}
                    onSortChange={this.handleSortChange.bind(this)}
                    canRead={canRead}
                    canDelete={canDelete}
                    canQR={canQR}
                    translate={translate}
                    tab={tab}
                    onTabChange={tab =>
                        this.setState({
                            tab,
                            sorting: [
                                `eventStart,${tab === 1 ? "DESC" : "ASC"}`,
                            ],
                        })
                    }
                    onDelete={id =>
                        history.push(`${stripslash(match.url)}/${id}/delete/`)
                    }
                    onAddEvent={() =>
                        history.push(`${stripslash(match.url)}/add/event/`)
                    }
                    onAddToEvent={id =>
                        history.push(`${stripslash(match.url)}/add/show/${id}/`)
                    }
                    onSelect={onSelect}
                    events={events}
                    eventDelete={eventDelete}
                    onPageChange={onPageChange}
                    filter={filter}
                    reload={this.reload}
                />
                <Switch>
                    <Route path={`${match.path}:id/delete/`}>
                        {(acl("events.delete") && (
                            <DeleteEvent
                                onBack={() =>
                                    history.push(`${stripslash(match.url)}/`)
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ shows, eventDelete }) => ({
                    events: shows.toJS(),
                    eventDelete,
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load,
                            clear,
                            remove: uuid => remove(uuid),
                        },
                        dispatch,
                    ),
            )(List),
        ),
    ),
);
