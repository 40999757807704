// @flow

declare var __DEVELOPMENT__: boolean;

import { type Store } from "redux";
import { createStore as _createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import type ApiClient from "../utils/api-client";
import appReducer from "./modules/index.js";

/**
 * Create the store
 */
export const createStore = (apiClient: ApiClient): Store<*, *> => {
    const storeFactory = applyMiddleware(thunk.withExtraArgument(apiClient))(
        _createStore,
    );

    const rootReducer = (state: any, action: any) => {
        if (action.type === "user/clear") {
            state = undefined;
            localStorage.clear();
        }

        return appReducer(state, action);
    };

    if (__DEVELOPMENT__) {
        return storeFactory(
            rootReducer,
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
                window.__REDUX_DEVTOOLS_EXTENSION__(),
        );
    }

    return storeFactory(rootReducer);
};
