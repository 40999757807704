// @flow
import styles from "./Edit.scss";

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AsyncFilteredSelect from "../../../AsyncFilteredSelect";
import { type State as VenuesState } from "../../../../redux/modules/venues.d";
import { type Option } from "../../../../redux/modules/option.d";
import { type FieldErrors } from "../../../../utils/FormError";

import { type State as EditConfirmedOptionState } from "../../../../redux/modules/edit-confirmed-option.d";

import moment from "moment";
import isEqual from "lodash.isequal";

type Props = {
    translate: *,
    /** Trigger modal close */
    onClose: () => void,
    /** Trigger */
    onSubmit: (uuid: string, option: *) => Promise<*>,
    /** Disables the form */
    disabled?: boolean,
    /** Venue state */
    venues: VenuesState,
    /** Load venues */
    loadVenues: () => *,
    errors: ?FieldErrors,
    optionData: Option,
    /**promoters */
    promoters: *,
    addPromoter: *,
    addPromoterState: *,
    editConfirmedOptionState: EditConfirmedOptionState,
    id: string,
    loadOrganisers: *,
    refetchOrganisers: () => Promise<*>,
};

type State = {
    name: *,
    date: string,
    venueUuid: string,
    venueName: string,
    uuid: string,
    ranking: number | string,
    venueCode: ?string,
    promoterUuid: ?string,
    promoterName: ?string,
    seriesName: string,
    seriesInternal: string,
};

/**
 * Add dialog
 */
export default class EditConfirmedOption extends Component<Props, State> {
    // default props

    state = {
        name: "",
        date: "",
        venueUuid: "",
        venueName: "",
        uuid: "",
        ranking: 0,
        venueCode: "",
        promoterUuid: null,
        promoterName: "",
        seriesName: "",
        seriesInternal: "",
    };

    /**
     * Update state on props load
     */
    componentDidUpdate(prevProps: Props) {
        const { optionData } = this.props;

        if (!isEqual(prevProps.optionData, optionData)) {
            this.setState({
                ...this.state,
                ...optionData,
            });
        }
    }

    get disabled() {
        const { editConfirmedOptionState, disabled } = this.props;

        return editConfirmedOptionState.loading || disabled;
    }

    /** Get current promoter */
    get currentPromoter() {
        const { promoterUuid } = this.state;
        const { promoters } = this.props;

        return (
            promoters.data &&
            promoterUuid &&
            promoters.data.find(p => p.uuid === promoterUuid)
        );
    }

    /**
     * onSubmit
     */
    handleSubmit = (event: *) => {
        const { onSubmit } = this.props;
        const { uuid, promoterUuid, name } = this.state;

        event.preventDefault();

        onSubmit(uuid, { name, promoterUuid });
    };

    /**
     * Render method
     */
    render() {
        const { translate, onClose, errors, promoters, addPromoterState, id } =
            this.props;

        const { seriesInternal, seriesName, venueName, date, name } =
            this.state;

        return (
            <Dialog onClose={onClose} open>
                <form
                    onSubmit={event => this.handleSubmit(event)}
                    className={styles.element}
                >
                    <DialogTitle disableTypography className={styles.title}>
                        <Typography variant="h2" color="inherit">
                            {translate("Edit Confirmed Option")}
                        </Typography>
                        <Typography className={styles.label}>
                            {translate("Edit this option on the calendar:")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={styles.dialogContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id="editoption-show-event-series"
                                    label={translate("Event series")}
                                    value={seriesName || ""}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: styles.label,
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: styles.input,
                                            underline: styles.underline,
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="editoption-show-event-series"
                                    label={translate("Memo")}
                                    value={seriesInternal || ""}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: styles.label,
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: styles.input,
                                            underline: styles.underline,
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AsyncFilteredSelect
                                    variant="white"
                                    id={`${id}-promoter`}
                                    disabled={this.disabled}
                                    error={!!(addPromoterState.error || "")}
                                    helperText={addPromoterState.error || ""}
                                    currentItem={this.currentPromoter || ""}
                                    label={translate("Organiser")}
                                    asyncState={promoters}
                                    onSelect={promoter =>
                                        this.setState({
                                            promoterUuid:
                                                (promoter: any).uuid || null,
                                            promoterName: (promoter: any).uuid
                                                ? null
                                                : (promoter: any).name,
                                        })
                                    }
                                    itemToString={item =>
                                        (item && item.name) || ""
                                    }
                                    itemToLabel={item =>
                                        (item && (item.label || item.name)) ||
                                        ""
                                    }
                                    filter={(value, items) =>
                                        items
                                            .filter(item =>
                                                (item: any).name
                                                    .toLowerCase()
                                                    .includes(
                                                        value.toLowerCase(),
                                                    ),
                                            )
                                            .splice(0, 5)
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: styles.label,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id={`${id}-option-name`}
                                    label={translate("Confirmed Option Name")}
                                    value={name}
                                    helperText={errors && errors.name}
                                    disabled={this.disabled}
                                    onChange={({ target: { value } }) =>
                                        this.setState({ name: value })
                                    }
                                    InputLabelProps={
                                        name
                                            ? {
                                                  shrink: true,
                                              }
                                            : undefined
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id={`${id}-venue-name`}
                                    label={translate("Venue")}
                                    value={venueName}
                                    helperText={errors && errors.venueName}
                                    disabled={true}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id={`${id}-start-date`}
                                    label={translate("Start")}
                                    value={moment(date).format("DD/MM/YYYY")}
                                    helperText={errors && errors.date}
                                    disabled={true}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button id={`${id}-cancel`} onClick={onClose}>
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id={`${id}-submit`}
                            type="submit"
                            disabled={this.disabled}
                            color="primary"
                        >
                            {translate("Save changes")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
