// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";
import { refetch as refetchCalendar } from "../../redux/modules/calendar";
import {
    load as findEvents,
    ERROR as EVENTS_ERROR,
} from "../../redux/modules/events";

import {
    load as loadEventDetails,
    clear as clearEventDetails,
} from "../../redux/modules/event-series";
import {
    load as addOption,
    clear as clearAddOption,
    ERROR as ADD_OPTION_ERROR,
} from "../../redux/modules/add-option";
import {
    load as addSerie,
    clear as clearAddSerie,
    ERROR as SERIE_ERROR,
} from "../../redux/modules/add-serie";
import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";

import { mapState } from "../../redux/utils";

import { withTranslate } from "../Translate";
import { withRouter } from "react-router";
import AddOption from "../../components/Events/Option/AddOption";

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({
                venues,
                organisers,
                events,
                addOption,
                addSerie,
                addPromoter,
                eventSeries,
            }) => ({
                venues: mapState(venues, VENUES_ERROR, "venues"),
                promoters: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                series: mapState(events, EVENTS_ERROR, "content"),
                state: {
                    add: mapState(addOption, ADD_OPTION_ERROR),
                    create: mapState(addSerie, SERIE_ERROR),
                    addPromoter: mapState(
                        addPromoter,
                        ADD_PROMOTER_ERROR,
                        "uuid",
                    ),
                },
                id: "add-options-dialog",
                eventDetails: eventSeries,
            }),
            // Map dispatch to props
            (dispatch: *) => ({
                ...bindActionCreators(
                    {
                        loadVenues,
                        loadOrganisers,
                        findEvents,
                        addPromoter,
                        clearAddPromoter,
                        clearAddSerie,
                        clearAddOption,
                        loadEventDetails,
                        clearEventDetails,
                        refetchOrganisers,
                    },
                    dispatch,
                ),
                onSubmit: ({
                    seriesName,
                    serieUuid,
                    internal,
                    promoterUuid,
                    options,
                }) => {
                    if (serieUuid) {
                        dispatch(clearAddSerie());
                        return dispatch(
                            addOption(serieUuid, options, promoterUuid),
                        ).then(() => dispatch(refetchCalendar()));
                    }
                    dispatch(clearAddOption());
                    return dispatch(
                        addSerie(options, seriesName, promoterUuid, internal),
                    ).then(() => dispatch(refetchCalendar()));
                },
            }),
        )(AddOption),
    ),
);
