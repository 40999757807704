// @flow
import styles from "./SelectStatus.scss";

import React from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CalenderIcon from "../Events/Calendar/Icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import { getColor } from "../../utils/VenueColors";

type Props = {
    onSelect: (status: *, index?: number) => void,
    index?: number,
    venueCode: string,
    optionsToSelect: Array<*>,
    value?: string,
    disabled?: ?boolean,
    fullWidth?: ?boolean,
    id: string,
    label: string,
    error?: ?string,
};

type State = {
    status: string,
};

export default class SelectStatus extends React.Component<Props, State> {
    //initial state
    state = {
        status: "",
    };

    /**
     * Update state on props load
     */
    static getDerivedStateFromProps(props: Props, state: State) {
        return {
            ...state,
            status: props.value,
        };
    }

    handleChange = (value: *) => {
        const { onSelect, index } = this.props;
        this.setState({ [value.name]: value.value });
        onSelect(value.value, index);
    };

    render() {
        const { status } = this.state;
        const {
            venueCode,
            optionsToSelect,
            disabled,
            fullWidth,
            error,
            label,
            id,
        } = this.props;

        return (
            <FormControl
                disabled={!!disabled}
                fullWidth={!!fullWidth}
                error={!!error}
            >
                <InputLabel htmlFor={`${id}-input`}>{label}</InputLabel>
                <Select
                    label="Status"
                    value={status}
                    onChange={event => this.handleChange(event.target)}
                    name="status"
                    displayEmpty
                    className={styles.element}
                    fullWidth
                >
                    {optionsToSelect &&
                        optionsToSelect.map(option => {
                            return (
                                <MenuItem value={option} key={option}>
                                    <CalenderIcon
                                        status={option}
                                        color={getColor(venueCode)}
                                    />
                                    <Typography align="center">
                                        {option}
                                    </Typography>
                                </MenuItem>
                            );
                        })}
                </Select>
                {error && (
                    <FormHelperText id={`${id}-helper-text`}>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        );
    }
}
