// @flow

import React from "react";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";

export default createSvgIcon(
    <g>
        <path d="M16.0675862,18.7125517 C16.2063448,17.3691034 16.9310345,16.2877241 17.8736552,16.0182069 L17.8736552,18.7125517 L16.0675862,18.7125517 Z M13.2877241,4.91944828 L15.1205517,4.91944828 C15.1591724,8.65462069 15.6675862,12.3484138 16.622069,14.3724138 C14.7608276,12.9194483 13.3806897,9.20827586 13.2877241,4.91944828 Z M7.17103448,14.3724138 C8.12551724,12.3484138 8.63393103,8.65462069 8.67255172,4.91944828 L10.5053793,4.91944828 C10.4124138,9.20827586 9.03227586,12.9194483 7.17103448,14.3724138 Z M7.73462069,18.7125517 L5.91944828,18.7125517 L5.91944828,16.0182069 C6.86206897,16.2877241 7.59668966,17.3671724 7.73462069,18.7125517 Z M6.37931034,4.91944828 L7.75310345,4.91944828 C7.69875862,10.1241379 6.76193103,13.7213793 5.91944828,14.7337931 L5.91944828,5.37931034 C5.91944828,5.12551724 6.12551724,4.91944828 6.37931034,4.91944828 Z M17.8736552,5.37931034 L17.8736552,14.7337931 C17.0311724,13.7213793 16.0932414,10.1241379 16.04,4.91944828 L17.4137931,4.91944828 C17.6675862,4.91944828 17.8736552,5.12551724 17.8736552,5.37931034 Z M17.4137931,4 L6.37931034,4 C5.61875862,4 5,4.61875862 5,5.37931034 L5,19.1724138 C5,19.4262069 5.20496552,19.632 5.45986207,19.632 L8.21848276,19.632 C8.47227586,19.632 8.67806897,19.4262069 8.67806897,19.1724138 C8.67806897,17.6209655 8.00882759,16.2664828 7.02289655,15.5575172 C9.51668966,14.3034483 11.3227586,10.1075862 11.4248276,4.91944828 L12.3682759,4.91944828 C12.4703448,10.1186207 14.2835862,14.3216552 16.7867586,15.5657931 C15.7953103,16.272 15.1150345,17.6154483 15.1150345,19.1724138 C15.1150345,19.4262069 15.3208276,19.632 15.5746207,19.632 L18.3332414,19.632 C18.5870345,19.632 18.7931034,19.4262069 18.7931034,19.1724138 L18.7931034,5.37931034 C18.7931034,4.61875862 18.1743448,4 17.4137931,4 Z" />
    </g>,
    "Show",
);
