// @flow

import styles from "./NavBarItem.scss";

import classnames from "classnames";
import React, { Component, type Node } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DefaultIcon from "@material-ui/icons/Link";

export type ItemType = {
    /* Value to be used in the callback */
    value: string,
    label: string,
    icon?: () => Node,
    onClick?: () => void,
    afterClick?: () => void,
};

//Define props
type Props = {
    /** navigate to correct route */
    onClick?: () => void,
    /** text */
    item: ItemType,
    /** is the menu item active? */
    active?: boolean,
    collapsed: boolean,
};

const STRIP_SLASH = /\//g;

/**
 * NavBarItem component
 */
export default class NavBarItem extends Component<Props> {
    /**
     * Render
     */
    render() {
        const { item, active, onClick, collapsed } = this.props;

        return (
            <ListItem
                id={`navbar-${item.value.replace(STRIP_SLASH, "")}`}
                className={classnames(styles.override, styles.item, {
                    [styles.active]: active,
                })}
                button
                onClick={
                    item.onClick ||
                    (onClick &&
                        ((item.afterClick &&
                            (() => {
                                onClick();
                                (item: any).afterClick();
                            })) ||
                            onClick))
                }
            >
                <ListItemIcon className={styles.icon}>
                    {item.icon ? <item.icon /> : <DefaultIcon />}
                </ListItemIcon>

                {!collapsed && <ListItemText primary={item.label} />}
            </ListItem>
        );
    }
}
