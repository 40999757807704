// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { load as loadLines } from "./event-lines";

import { typeof Client } from "../../utils/api-client/modules/event-line-team";
import {
    type ImmutableState,
    type Action,
    type FullState,
} from "./event-line-team.d";

const ERROR = "Could not fetch event line team";
const ERROR_DELETE = "Could not delete the member";
const ERROR_PROMOTE = "Could not promote member to leader";
const ERROR_DEMOTE = "Could not demote leader to member";
const ERROR_ADD = "Could not add member to team";
const ERROR_ADD_RECRUIT = "Could not add recruit to team";
const ERROR_ASSIGN = "Could not assign parking";
const ERROR_UNASSIGN = "Could not unassign parking";

const ZONE = "event-line-team";
const CLEAR = `${ZONE}/clear`;

const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const ADD_RECRUIT_SUCCESS = `${ZONE}/add/recruit/success`;
const ADD_RECRUIT_PENDING = `${ZONE}/add/recruit/pending`;
const ADD_RECRUIT_FAILED = `${ZONE}/add/recruit/failure`;

const ADD_SUCCESS = `${ZONE}/add/success`;
const ADD_PENDING = `${ZONE}/add/pending`;
const ADD_FAILED = `${ZONE}/add/failure`;

const DELETE_SUCCESS = `${ZONE}/delete/success`;
const DELETE_PENDING = `${ZONE}/delete/pending`;
const DELETE_FAILED = `${ZONE}/delete/failure`;

const PROMOTE_SUCCESS = `${ZONE}/promote/success`;
const PROMOTE_PENDING = `${ZONE}/promote/pending`;
const PROMOTE_FAILED = `${ZONE}/promote/failure`;

const DEMOTE_SUCCESS = `${ZONE}/demote/success`;
const DEMOTE_PENDING = `${ZONE}/demote/pending`;
const DEMOTE_FAILED = `${ZONE}/demote/failure`;

const ASSIGN_SUCCESS = `${ZONE}/assign/success`;
const ASSIGN_PENDING = `${ZONE}/assign/pending`;
const ASSIGN_FAILED = `${ZONE}/assign/failure`;

const UNASSIGN_SUCCESS = `${ZONE}/unassign/success`;
const UNASSIGN_PENDING = `${ZONE}/unassign/pending`;
const UNASSIGN_FAILED = `${ZONE}/unassign/failure`;

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    data: null,
    uuid: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        invariant(typeof payload === "string", `Wrong payload for ${PENDING}`);

        return state.merge({
            loading: true,
            error: null,
            uuid: payload,
        });
    }

    if (type === SUCCESS) {
        invariant(payload, `Wrong payload for ${SUCCESS}`);

        return state.merge({
            loading: false,
            error: null,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        invariant(typeof payload === "string", `Wrong payload for ${FAILURE}`);

        return state.merge({
            loading: false,
            error: payload,
            data: null,
        });
    }

    if (type === PROMOTE_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${PROMOTE_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }

    if (type === DEMOTE_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${PROMOTE_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }

    if (type === UNASSIGN_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${UNASSIGN_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }
    if (type === ASSIGN_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${ASSIGN_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }

    if (type === DELETE_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${PROMOTE_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }

    if (type === ADD_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${PROMOTE_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }

    if (type === ADD_RECRUIT_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${PROMOTE_FAILED}`,
        );

        return state.merge({
            loading: false,
            error: payload,
            //data: null,
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load
 */
export const load =
    (uuid: string, force: boolean = false) =>
    (
        dispatch: (action: Action) => void,
        getState: () => FullState,
        client: Client,
    ): Promise<void> => {
        const { eventLineTeam: state } = getState();

        if (!force && (state.get("loading") || state.get("uuid") === uuid)) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: uuid,
        });

        return client
            .getShowLineTeam(uuid)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };

/**
 * Actioncreator: delete member from team
 */
export const remove =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Remove from list and set to loading
        dispatch({
            type: DELETE_PENDING,
        });

        return client
            .deleteTeamMember(uuid)
            .then(() => {
                dispatch({
                    type: DELETE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: DELETE_FAILED,
                    payload: (error && error.message) || ERROR_DELETE,
                });

                return false;
            });
    };

/**
 * Actioncreator: make teammember => teamleader
 */
export const promote =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: PROMOTE_PENDING,
        });

        return client
            .promoteTeamMember(uuid)
            .then(() => {
                dispatch({
                    type: PROMOTE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: PROMOTE_FAILED,
                    payload: (error && error.message) || ERROR_PROMOTE,
                });

                return false;
            });
    };

/**
 * Actioncreator: make teamleader => teammember
 */
export const demote =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: DEMOTE_PENDING,
        });

        return client
            .demoteTeamLeader(uuid)
            .then(() => {
                dispatch({
                    type: DEMOTE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: DEMOTE_FAILED,
                    payload: (error && error.message) || ERROR_DEMOTE,
                });

                return false;
            });
    };
/**
 * Actioncreator: add teammember
 */
export const add =
    (uuids: string[]) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: ADD_PENDING,
        });

        return client
            .addTeamMember(state.get("data").get("uuid"), uuids)
            .then(() => {
                dispatch({
                    type: ADD_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: ADD_FAILED,
                    payload: (error && error.message) || ERROR_ADD,
                });

                return false;
            });
    };
/**
 * Actioncreator: add recruit
 */
export const addRecruit =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: ADD_RECRUIT_PENDING,
        });

        return client
            .addFromRecruits(state.get("data").get("uuid"), uuid)
            .then(() => {
                dispatch({
                    type: ADD_RECRUIT_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: ADD_RECRUIT_FAILED,
                    payload: (error && error.message) || ERROR_ADD_RECRUIT,
                });

                return false;
            });
    };
/**
 * Actioncreator: assign parking
 */
export const assign =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: ASSIGN_PENDING,
        });

        return client
            .assignParking(uuid)
            .then(() => {
                dispatch({
                    type: ASSIGN_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: ASSIGN_FAILED,
                    payload: (error && error.message) || ERROR_ASSIGN,
                });

                return false;
            });
    };
/**
 * Actioncreator: unassign parking
 */
export const unassign =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLineTeam: state } = getState();
        const {
            eventLines: {
                params: {
                    path: { uuid: linesUuid },
                },
            },
        } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: UNASSIGN_PENDING,
        });

        return client
            .unassignParking(uuid)
            .then(() => {
                dispatch({
                    type: UNASSIGN_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(linesUuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: UNASSIGN_FAILED,
                    payload: (error && error.message) || ERROR_UNASSIGN,
                });

                return false;
            });
    };
