// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { type State as ConfirmedOptionsState } from "../../redux/modules/confirmed-options.d";
import {
    load as loadConfirmedOptions,
    clear as clearConfirmedOptions,
} from "../../redux/modules/confirmed-options";
import { type State as AllTemplatesState } from "../../redux/modules/all-templates.d.";
import {
    load as loadCalendar,
    refetch as refetchCalendar,
} from "../../redux/modules/calendar";
import { withTranslate } from "../Translate";
import DefineEvents from "../../components/Events/DefineEvents/DefineEvents";
import {
    edit as defineEvents,
    clear as clearDefineEvents,
    ERROR as DEFINE_EVENTS_ERROR,
} from "../../redux/modules/define-events";
import { type State as DefineEventsState } from "../../redux/modules/define-events.d";
import { mapState } from "../../redux/utils";

type Props = {
    onClose: () => void,
    translate: *,
    /** confirmed option */
    confirmedOptions: ConfirmedOptionsState,
    loadConfirmedOptions: (uuid: string) => *,
    /** templates */
    allTemplatesState: AllTemplatesState,
    allTemplates: *,
    defineEvetsState: DefineEventsState,
    defineEvents: (
        uuid: string,
        confirmedOptions: Array<*>,
        events: Array<*>,
        seriesName: string,
    ) => *,
    loadTemplates: () => *,
    /** calendar */
    loadCalendar: *,
    clearDefineEvents: *,
    /** match */
    match: Match,
    refetchCalendar: () => Promise<*>,
    clearConfirmedOptions: () => Promise<*>,
};

class DefineEventsContainer extends Component<Props> {
    componentDidMount() {
        const { loadConfirmedOptions } = this.props;
        loadConfirmedOptions(this.uuid);
    }

    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            translate,
            confirmedOptions,
            onClose,
            defineEvents,
            defineEvetsState,
            refetchCalendar,
            clearDefineEvents,
            clearConfirmedOptions,
        } = this.props;
        return (
            <DefineEvents
                onClose={() => {
                    onClose();
                    clearDefineEvents();
                }}
                translate={translate}
                defineEvetsState={defineEvetsState}
                onSubmit={(confirmedOption, events, seriesName, optionCode) =>
                    defineEvents(
                        this.uuid,
                        confirmedOption,
                        events,
                        seriesName,
                        optionCode,
                    ).then(() => {
                        onClose();
                        refetchCalendar();
                        clearDefineEvents();
                        clearConfirmedOptions();
                    })
                }
                options={confirmedOptions}
                loading={confirmedOptions.loading}
                id="defineEvents"
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ confirmedOptions, defineEvents }) => ({
                confirmedOptions,
                defineEvetsState: mapState(defineEvents, DEFINE_EVENTS_ERROR),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadConfirmedOptions: uuid =>
                            loadConfirmedOptions(uuid),
                        loadCalendar: () => loadCalendar(),
                        clearDefineEvents,
                        defineEvents,
                        refetchCalendar,
                        clearConfirmedOptions,
                    },
                    dispatch,
                ),
        )(DefineEventsContainer),
    ),
);
