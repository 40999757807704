// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Redirect,
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import debounce from "lodash.debounce";

import { type State } from "../../redux/modules/organisations.d";
import {
    type NewOrganisation,
    type State as OrganisationAddState,
} from "../../redux/modules/organisation-add.d";
import { type State as LinesState } from "../../redux/modules/lines.d";

import {
    load,
    clear,
    ERROR as ORGANISATIONS_ERROR,
} from "../../redux/modules/organisations";
import {
    load as add,
    ERROR as ORGANISATION_ADD_ERROR,
} from "../../redux/modules/organisation-add";

import {
    load as loadLines,
    ERROR as LINES_ERROR,
} from "../../redux/modules/lines";
import { load as remove } from "../../redux/modules/organisation-delete";

import stripslash from "../../utils/stripslash";
import { FilterHeader } from "../../components/Authenticated";
import OrganisationList from "../../components/Organisations/OrganisationList/OrganisationList";
import { Add } from "../../components/Organisations";

import { withTranslate } from "../Translate";
import { withAcl, type Acl } from "../Acl";
import { mapState } from "../../redux/utils";
import isEqual from "lodash.isequal";
import DeleteOrganisation from "./DeleteOrganisation";
import NotFound from "../../components/NotFound";

type Props = {
    acl: Acl,
    translate: *,
    match: Match,
    organisations: State,
    lines: LinesState,
    clear: () => void,
    load: (
        page: number,
        filter: string,
        sorting: string[],
        force: boolean,
    ) => void,
    add: (organisation: NewOrganisation) => *,
    loadLines: () => void,
    remove: (uuid: string) => *,
    history: RouterHistory,
    onSelect: (uuid: string) => void,
    onPageChange: (page: number) => void,
    organisationAdd: OrganisationAddState,
};

type StateType = {
    filter: string,
    sorting: string[],
};

const DEBOUNCE_WAIT = 2500;
const DEBOUNCE_MAX_WAIT = 5000;

class List extends Component<Props, StateType> {
    // Initial state
    state: StateType = {
        filter: "",
        sorting: [],
    };

    /**
     * Initial load
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
    }

    /**
     * Trigger load on update
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;

        if (
            prevProps.match.params.page !== match.params.page ||
            !isEqual(prevState, this.state)
        ) {
            this.reload();
        }
    }

    /**
     *
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    handleSortChange(sortString: string[]) {
        this.setState({
            sorting: sortString,
        });
    }

    /**
     * Trigger update
     */
    reload = debounce(
        (force: boolean = false) => {
            const { load, match } = this.props;
            const { filter, sorting } = this.state;
            if (this.pageParamIsNumber) {
                load(parseInt(match.params.page), filter, sorting, force);
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    handleDelete(uuid: string) {
        const { remove } = this.props;
        remove(uuid).then(() => this.reload(true));
    }

    /**
     * Submit handler
     */
    handleSubmit(organisation: NewOrganisation) {
        const { add, onSelect } = this.props;
        add(organisation).then(uuid => {
            if (uuid) {
                onSelect(uuid);
                this.reload(true);
            }
        });
    }

    /**
     * Render
     */
    render() {
        const {
            acl,
            translate,
            onSelect,
            onPageChange,
            match,
            organisations,
            history,
            lines,
            loadLines,
            organisationAdd,
        } = this.props;

        const { filter } = this.state;

        if (!this.pageParamIsNumber) {
            return <h1>404: Not Found</h1>;
        }

        return (
            <div>
                <FilterHeader
                    title={translate("Organisations")}
                    onChange={filter => this.setState({ filter })}
                    id="organisations-list"
                    value={filter}
                    placeholder={translate("Filter by name or venues")}
                />
                <OrganisationList
                    acl={acl}
                    translate={translate}
                    loading={organisations.loading}
                    page={organisations.page}
                    data={organisations.data}
                    error={organisations.error}
                    onClick={onSelect}
                    onPageChange={onPageChange}
                    onSortChange={this.handleSortChange.bind(this)}
                    // onDelete={uuid => this.handleDelete(uuid)}
                    onDelete={id =>
                        history.push(`${stripslash(match.url)}/${id}/delete/`)
                    }
                    onAdd={() => history.push(`${stripslash(match.url)}/add/`)}
                    filter={filter}
                />

                <Switch>
                    <Route path={`${match.path}add/`}>
                        {(acl("organisations.create") && (
                            <Add
                                translate={translate}
                                busy={organisationAdd.loading}
                                errors={organisationAdd.fieldErrors}
                                onSubmit={this.handleSubmit.bind(this)}
                                onClose={() =>
                                    history.push(`${stripslash(match.url)}/`)
                                }
                                lines={lines}
                                loadLines={loadLines}
                            />
                        )) || <Redirect to={`${stripslash(match.url)}/`} />}
                    </Route>
                    <Route path={`${match.path}:id/delete/`}>
                        {(acl("organisations.delete") && (
                            <DeleteOrganisation
                                onBack={() =>
                                    history.push(`${stripslash(match.url)}/`)
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ organisations, lines, organisationAdd }) => {
                    return {
                        organisations: {
                            ...mapState(organisations, ORGANISATIONS_ERROR),
                            page:
                                organisations.params &&
                                organisations.params.path &&
                                organisations.params.path.page + 1,
                        },
                        organisationAdd: mapState(
                            organisationAdd,
                            ORGANISATION_ADD_ERROR,
                        ),
                        lines: mapState(lines, LINES_ERROR, "lineTypes"),
                    };
                },
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load,
                            clear,
                            add,
                            loadLines,
                            remove: uuid => remove(uuid),
                        },
                        dispatch,
                    ),
            )(List),
        ),
    ),
);
