// @flow
import styles from "./Edit.scss";

import React, { Component } from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import AsyncFilteredSelect from "../../../AsyncFilteredSelect";
import { type State as VenuesState } from "../../../../redux/modules/venues.d";
import { type Option } from "../../../../redux/modules/option.d";
import { type FieldErrors } from "../../../../utils/FormError";

import { type State as EditOptionState } from "../../../../redux/modules/edit-option.d";
import isEqual from "lodash.isequal";

type Props = {
    translate: *,
    /** Trigger modal close */
    onClose: () => void,
    /** Trigger */
    onSubmit: (uuid: string, promoteruuid: ?string) => Promise<*>,
    /** Venue state */
    venues: VenuesState,
    /** Load venues */
    loadVenues: () => *,
    errors: ?FieldErrors,
    /**option data */
    optionData: Option,
    /**option loading status */
    loading: boolean,
    /**promoters */
    promoters: *,
    addPromoter: *,
    addPromoterState: *,
    editOptionState: EditOptionState,
    id: string,
    loadOrganisers: *,
    refetchOrganisers: () => Promise<*>,
};

type State = {
    name: string,
    date: string,
    venueUuid: string,
    venueName: string,
    uuid: string,
    ranking: number | string,
    venueCode: ?string,
    promoterUuid: ?string,
    promoterName: ?string,
    seriesName: string,
    seriesInternal: string,
    createdBy: string,
    seriesOptionCode: string,
};

/**
 * Add dialog
 */
export default class EditOption extends Component<Props, State> {
    // default props

    state = {
        name: "",
        date: "",
        venueUuid: "",
        venueName: "",
        uuid: "",
        ranking: 0,
        venueCode: "",
        promoterUuid: null,
        promoterName: "",
        seriesName: "",
        seriesInternal: "",
        createdBy: "",
        seriesOptionCode: "",
    };

    componentDidUpdate(prevProps: Props) {
        const { optionData } = this.props;

        if (!isEqual(prevProps.optionData, optionData)) {
            this.setState({
                ...this.state,
                ...optionData,
            });
        }
    }

    get disabled() {
        const { editOptionState, loading } = this.props;

        return editOptionState.loading || loading;
    }

    /** Get current promoter */
    get currentPromoter() {
        const { promoterUuid } = this.state;
        const { promoters } = this.props;

        return (
            promoters.data &&
            promoterUuid &&
            promoters.data.find(p => p.uuid === promoterUuid)
        );
    }

    /**
     * onSubmit
     */
    handleSubmit = (event: *) => {
        const { onSubmit } = this.props;
        const { uuid, promoterUuid } = this.state;

        event.preventDefault();

        onSubmit(uuid, promoterUuid);
    };

    /**
     * The render method
     */
    render() {
        const {
            translate,
            onClose,
            errors,
            promoters,
            addPromoter,
            addPromoterState,
            loading,
            id,
            refetchOrganisers,
        } = this.props;
        const {
            seriesInternal,
            seriesName,
            venueName,
            date,
            createdBy,
            seriesOptionCode,
        } = this.state;

        return (
            <Dialog open>
                <form
                    onSubmit={event => this.handleSubmit(event)}
                    className={styles.element}
                >
                    <DialogTitle disableTypography className={styles.title}>
                        <Typography variant="h2" color="inherit">
                            {translate("Edit option")}
                        </Typography>
                        <Typography className={styles.label}>
                            {translate("Edit this option on the calendar:")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={styles.dialogContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id="editoption-show-event-series"
                                    label={translate("Event series")}
                                    value={`${seriesName || ""}${
                                        seriesName && seriesOptionCode
                                            ? " - "
                                            : ""
                                    }${seriesOptionCode}`}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: styles.label,
                                        },
                                    }}
                                    fullWidth
                                    InputProps={{
                                        classes: {
                                            root: styles.input,
                                            underline: styles.underline,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="editoption-show-event-memo"
                                    label={translate("Memo")}
                                    value={seriesInternal || ""}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: styles.label,
                                        },
                                    }}
                                    fullWidth
                                    InputProps={{
                                        classes: {
                                            root: styles.input,
                                            underline: styles.underline,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AsyncFilteredSelect
                                    variant={"white"}
                                    id={`${id}-promoter`}
                                    disabled={this.disabled}
                                    error={!!(addPromoterState.error || "")}
                                    helperText={addPromoterState.error || ""}
                                    currentItem={this.currentPromoter || ""}
                                    label={translate("Organiser")}
                                    asyncState={promoters}
                                    onSelect={promoter =>
                                        this.setState(
                                            {
                                                promoterUuid:
                                                    (promoter: any).uuid ||
                                                    null,
                                                promoterName: (promoter: any)
                                                    .uuid
                                                    ? null
                                                    : (promoter: any).name,
                                            },
                                            () =>
                                                !promoter.uuid &&
                                                addPromoter(
                                                    (promoter: any).name,
                                                ).then(async ({ payload }) => {
                                                    await refetchOrganisers();
                                                    this.setState({
                                                        promoterName: null,
                                                        promoterUuid:
                                                            payload.uuid,
                                                    });
                                                }),
                                        )
                                    }
                                    itemToString={item =>
                                        (item && item.name) || ""
                                    }
                                    itemToLabel={item =>
                                        (item && (item.label || item.name)) ||
                                        ""
                                    }
                                    filter={(value, items) =>
                                        items
                                            .filter(item =>
                                                (item: any).name
                                                    .toLowerCase()
                                                    .includes(
                                                        value.toLowerCase(),
                                                    ),
                                            )
                                            .splice(0, 5)
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: styles.label,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    {loading ? (
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={styles.spinner}
                        >
                            <CircularProgress size={25} />
                        </Grid>
                    ) : (
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="editoption-start-date"
                                        label={translate("Start")}
                                        value={moment(date).format(
                                            "DD/MM/YYYY",
                                        )}
                                        helperText={errors && errors.date}
                                        disabled={true}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="editoption-venue-name"
                                        label={translate("Venue")}
                                        value={venueName}
                                        helperText={errors && errors.venueName}
                                        disabled={true}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="editoption-created-by"
                                        label={translate("Created by")}
                                        value={createdBy}
                                        helperText={errors && errors.createdBy}
                                        disabled={true}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button id="editoption-cancel" onClick={onClose}>
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="editoption-submit"
                            type="submit"
                            disabled={this.disabled}
                            color="primary"
                        >
                            {translate("Save changes")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
