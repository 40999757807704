// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tBTrDyflQwhFBSRijeIuXw\\=\\={background-color:white;position:relative}.yazE4xwbia7oOnXfrQlstA\\=\\={padding-top:11em;padding-bottom:26em;text-align:center}\n", "",{"version":3,"sources":["webpack://./../CalendarView.scss"],"names":[],"mappings":"AAAA,4BACI,sBAAuB,CACvB,iBAAkB,CACrB,4BAGG,gBAAiB,CACjB,mBAAoB,CACpB,iBAAkB","sourcesContent":[".element {\n    background-color: white;\n    position: relative;\n}\n\n.spinner {\n    padding-top: 11em;\n    padding-bottom: 26em;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "tBTrDyflQwhFBSRijeIuXw==",
	"spinner": "yazE4xwbia7oOnXfrQlstA=="
};
export default ___CSS_LOADER_EXPORT___;
