// @flow

import createApiModule from "../create-api-module";

const { actions, reducer } = createApiModule(
    "staffingTemplateDetails",
    ({ uuid }) => `/ays-venue-facility-management/staffing-templates/${uuid}`,
);

export const load = (uuid: string) => (dispatch: *) =>
    dispatch(actions.load({ path: { uuid } }));

export const ERROR = "Couldn't get staffing template details";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
