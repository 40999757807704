// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hgKrh3M4S5reFG\\+qYSWpaQ\\=\\={background-color:#651f82;color:white;margin-bottom:1em}.hgKrh3M4S5reFG\\+qYSWpaQ\\=\\= .TgaI8gz9M-n\\+TVjqQr5sxA\\=\\={background-color:white;height:0.2em}\n", "",{"version":3,"sources":["webpack://./../Wrapper.scss"],"names":[],"mappings":"AAAA,6BACI,wBAAyB,CACzB,WAAY,CACZ,iBAAkB,CAHtB,0DAKQ,sBAAuB,CACvB,YAAa","sourcesContent":[".title {\n    background-color: #651f82;\n    color: white;\n    margin-bottom: 1em;\n    .indicator {\n        background-color: white;\n        height: 0.2em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "hgKrh3M4S5reFG+qYSWpaQ==",
	"indicator": "TgaI8gz9M-n+TVjqQr5sxA=="
};
export default ___CSS_LOADER_EXPORT___;
