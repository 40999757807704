// @flow

import React from "react";

import { View, Text, StyleSheet } from "@react-pdf/renderer";

import Status from "../Table/DateRow/VenueCell/Status";

type Props = {
    translate: *,
};

const legendOptions = [
    { status: "OPTION_CREATED", label: "Option" },
    { status: "OPTION_CONFIRMED", label: "Confirmed Option" },
    { status: "LIMITED_AVAILABILITY", label: "Confirmed Option (Limited)" },
    { status: "PUBLIC", label: "Public event" },
    { status: "PRIVATE", label: "Private event" },
];
/**
 * Styling
 */
const styles = StyleSheet.create({
    legend: {
        position: "absolute",
        bottom: 10,
        left: 20,
        flexDirection: "row",
        justifyContent: "space-between",
        width: "95%",
    },
});
const Footer = ({ translate }: Props) => (
    <View fixed style={styles.legend}>
        <View
            style={{
                flexDirection: "row",
            }}
        >
            {legendOptions.map((option, index) => (
                <View
                    key={index}
                    style={{
                        flexDirection: "row",
                        marginLeft: 5,
                        marginRight: 5,
                    }}
                >
                    <Status venueCode="LEGEND" status={option.status} />
                    <Text>{translate(option.label)}</Text>
                </View>
            ))}
        </View>
        <Text
            render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
            }
            fixed
        />
    </View>
);

export default Footer;
