// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "venues",
    () => "/ays-venue-facility-management/venues",
);

export const ERROR = "Could not fetch venues";
export const load = actions.load;
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
