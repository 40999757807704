// @flow

import styles from "./index.scss";

import React, { type Node } from "react";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

import { type EventLine } from "../../../redux/modules/event-lines.d";

import { type State as ShowLineOpenCloseState } from "../../../redux/modules/event-line-openclose.d";

import Title from "./Title";

type Props = {
    id: string,
    eventLineOpenClose: ShowLineOpenCloseState,
    translate: *,
    eventLine: EventLine,
    onToggle: () => *,
    active: boolean,
    children: Node | Node[],
    canToggle: boolean,
    withConfirmation: boolean,
};

const LineStaffing = ({
    eventLine,
    translate,
    children,
    id,
    onToggle,
    canToggle,
    active,
    eventLineOpenClose,
    withConfirmation,
}: Props) => (
    <div className={styles.wrapper}>
        <Paper elevation={2}>
            <div className={styles.inner}>
                <Title
                    id={`${id}-title`}
                    active={active}
                    eventLine={eventLine}
                    onToggle={onToggle}
                    canToggle={canToggle}
                    translate={translate}
                    disabled={eventLineOpenClose.loading}
                    eventLineOpenClose={eventLineOpenClose}
                    withConfirmation={withConfirmation}
                />
                <Divider />
                {children}
            </div>
        </Paper>
    </div>
);

export default LineStaffing;
