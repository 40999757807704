//@flow
import styles from "./FacilityViewList.scss";

import React, { Component } from "react";
import moment from "moment";

import classnames from "classnames";

import Table from "../../Table/Table";
import DoneIcon from "@material-ui/icons/Done";
import HelpIcon from "@material-ui/icons/Help";

import type { Events } from "../../../redux/modules/facility-view-events.d";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    acl: *,
    translate: *,
    loading: boolean,
    error: ?string,
    data: ?Events,
    user: Profile,
    onClick: (uuid: string) => void,
    onDetail: (eventUuid: string, lineUuid: string) => void,
};

class FacilityViewList extends Component<Props> {
    canVisitEvent = (data: Object, acl: *) => {
        const { user } = this.props;
        const organisations = user.organisation_leader_organisations
            ? user.organisation_leader_organisations
            : "";

        let canVisit = false;

        if (data) {
            data.eventLines.forEach(line => {
                if (line.primaryOrganisations.length > 0) {
                    if (
                        line.primaryOrganisations.find(org =>
                            organisations.includes(org.uuid),
                        )
                    ) {
                        canVisit = true;
                    }
                }
            });

            data.customEventLines.forEach(line => {
                if (line.primaryOrganisations.length > 0) {
                    if (
                        line.primaryOrganisations.find(org =>
                            organisations.includes(org.uuid),
                        )
                    ) {
                        canVisit = true;
                    }
                }
            });
        }

        if (acl("facilityView.seeOpen")) {
            return true;
        }

        return canVisit;
    };

    getLineStatus = (line: ?Object) => {
        const { acl } = this.props;
        if (!line) {
            return "-";
        }
        if (
            !line.primaryOrganisations ||
            line.primaryOrganisations.length === 0
        ) {
            if (line.status === "OPEN" && !acl("facilityView.seeOpen")) {
                return "OPEN";
            }
            if (line.status === "OPEN") {
                return "UNASSIGNED";
            }
            if (line.status === "CLOSED") {
                return "CLOSED";
            }
            return line.status;
        }

        let value = "";
        line.primaryOrganisations.forEach((org, index) => {
            if (index !== 0) {
                value += ", ";
            }
            value += org.name;
        });
        return value;
    };
    getLineValue = (line: ?Object) => {
        const { acl, translate } = this.props;
        if (!line) {
            return (
                <div
                    className={classnames({
                        [styles.border]: true,
                        [styles.clickable]: true,
                    })}
                >
                    -
                </div>
            );
        }

        if (
            !line.primaryOrganisations ||
            line.primaryOrganisations.length === 0
        ) {
            if (line.status === "OPEN" && !acl("facilityView.seeOpen")) {
                return (
                    <div
                        className={classnames({
                            [styles.border]: true,
                            [styles.clickable]: true,
                        })}
                    >
                        <div className={styles.icon_wrapper}>
                            <DoneIcon className={styles.icon} />
                        </div>
                        {translate("Open")}
                    </div>
                );
            }
            if (line.status === "OPEN") {
                return (
                    <div className={styles.border}>
                        <div className={styles.icon_wrapper_help}>
                            <HelpIcon className={styles.icon_help} />
                        </div>
                        {translate("Unassigned")}
                    </div>
                );
            }
            if (line.status === "CLOSED") {
                return (
                    <div
                        className={classnames({
                            [styles.border]: true,
                            [styles.clickable]: true,
                        })}
                    >
                        {translate("Closed")}
                    </div>
                );
            }
            return line.status;
        }

        let value = "";
        line.primaryOrganisations.forEach((org, index) => {
            if (index !== 0) {
                value += ", ";
            }
            value += org.name;
        });
        return (
            <div
                className={classnames({
                    [styles.border]: true,
                    [styles.orgname]: true,
                })}
            >
                <div className={styles.icon_wrapper}>
                    <DoneIcon className={styles.icon} />
                </div>
                {value}
            </div>
        );
    };

    renderLineType = (
        item: Object,
        index: number,
        lines: Object[],
        lineName: string,
    ) => {
        const { onDetail, acl } = this.props;
        const line = lines.find(line => line.lineName === lineName);
        const eventLineUuid = line ? line.eventLineUuid : "";
        return (
            <div
                key={`${item.eventUuid}-${index}`}
                onClick={() => {
                    if (
                        this.getLineStatus(line) !== "CLOSED" &&
                        this.getLineStatus(line) !== "OPEN" &&
                        this.canVisitEvent(item, acl)
                    ) {
                        onDetail(item.eventUuid, eventLineUuid);
                    }
                }}
                className={
                    (line && line.status === "CLOSED") ||
                    this.getLineValue(line) === "-"
                        ? styles.closed
                        : null
                }
            >
                {this.getLineValue(line)}
            </div>
        );
    };

    get configItems() {
        const { data, translate, onClick, acl } = this.props;

        const items = [
            {
                key: "showName",
                displayName: translate("Show"),
                sortable: false,
                isNumeric: false,
                permission: true,
                customRender: (item: Object) => {
                    if (
                        this.canVisitEvent(item, acl) ||
                        acl("facilityView.seeOpen")
                    ) {
                        return (
                            <div
                                className={styles.fat}
                                onClick={() => onClick(item.eventUuid)}
                            >
                                {item.eventName}
                            </div>
                        );
                    }
                    return <div className={styles.fat}>{item.eventName}</div>;
                },
            },
            {
                key: "eventStart",
                displayName: translate("Date"),
                sortable: false,
                isNumeric: false,
                permission: true,
                customRender: (item: Object) => {
                    if (
                        this.canVisitEvent(item, acl) ||
                        acl("facilityView.seeOpen")
                    ) {
                        return (
                            <div
                                className={styles.date}
                                onClick={() => onClick(item.eventUuid)}
                            >
                                {(moment(item.eventStart): any).format(
                                    "ddd D MMM YYYY",
                                )}
                            </div>
                        );
                    }
                    return (
                        <div className={styles.date}>
                            {(moment(item.eventStart): any).format(
                                "ddd D MMM YYYY",
                            )}
                        </div>
                    );
                },
            },
        ];

        if (data) {
            data.content.forEach(event => {
                event.eventLines.forEach(line => {
                    if (!items.find(i => i.key === line.lineName)) {
                        items.push({
                            key: line.lineName,
                            displayName: line.lineCode || line.lineName,
                            customRender: (item: Object, index: number) =>
                                this.renderLineType(
                                    item,
                                    index,
                                    item.eventLines,
                                    line.lineName,
                                ),
                            sortable: false,
                            isNumeric: false,
                            permission: true,
                        });
                    }
                });

                event.customEventLines.forEach(line => {
                    if (!items.find(i => i.key === line.lineName)) {
                        items.push({
                            key: line.lineName,
                            displayName: line.lineCode || line.lineName,
                            customRender: (item: Object, index: number) =>
                                this.renderLineType(
                                    item,
                                    index,
                                    item.customEventLines,
                                    line.lineName,
                                ),
                            sortable: false,
                            isNumeric: false,
                            permission: true,
                        });
                    }
                });
            });
        }

        return items;
    }

    /**
     * Render
     */
    render() {
        const { translate, loading, error, data } = this.props;

        const config = {
            id: "facilityView-list",
            uuidKey: "eventUuid",
            hasMenu: false,
            onClick: null,
            configItems: this.configItems,
            menuOptions: [],
        };

        return (
            <Table
                config={config}
                loading={loading}
                data={data}
                error={error}
                translate={translate}
                hasPagination={false}
                page={0}
                getSortString={() => undefined}
                onPageChange={() => undefined}
                bordered
                extraPadding
                filter=""
            />
        );
    }
}

export default FacilityViewList;
