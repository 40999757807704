// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GVoIuN0h5R7GyJtYPCzYvQ\\=\\={width:100%;max-width:24em}._3nY6x2rcQsgKEqHWLuwGwg\\=\\={margin:2em 0}._3nY6x2rcQsgKEqHWLuwGwg\\=\\= a{cursor:pointer;text-decoration:underline}._3nY6x2rcQsgKEqHWLuwGwg\\=\\= a:hover{text-decoration:none}.fZlbij0ENmwUAplTKytCcw\\=\\={white-space:nowrap}\n", "",{"version":3,"sources":["webpack://./../Login.scss"],"names":[],"mappings":"AAEA,4BACI,UAAW,CACX,cAAe,CAClB,6BAGG,YAAa,CADjB,+BAIQ,cAAe,CACf,yBAA0B,CALlC,qCAQY,oBAAqB,CACxB,4BAKL,kBAAmB","sourcesContent":["// General style\n\n.form {\n    width: 100%;\n    max-width: 24em;\n}\n\n.cell {\n    margin: 2em 0;\n\n    a {\n        cursor: pointer;\n        text-decoration: underline;\n\n        &:hover {\n            text-decoration: none;\n        }\n    }\n}\n\n.phone-number {\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "GVoIuN0h5R7GyJtYPCzYvQ==",
	"cell": "_3nY6x2rcQsgKEqHWLuwGwg==",
	"phone-number": "fZlbij0ENmwUAplTKytCcw=="
};
export default ___CSS_LOADER_EXPORT___;
