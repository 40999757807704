// @flow

import createApiModule from "../create-api-module";
import qs from "qs";
import { type PathParams } from "./event-line-member-candidates.d";

const { reducer, actions } = createApiModule(
    "eventLineMemberCandidates",
    ({ eventLineUuid, page }: PathParams) =>
        `/ays-searches/searches/memberships/event-lines/${eventLineUuid}?${qs.stringify(
            { page: page - 1 },
        )}`,
    {
        method: "POST",
    },
);

export const load =
    (page: number, filter: * = "", eventLineUuid: string) =>
    (dispatch: *) => {
        return dispatch(
            actions.load(
                {
                    path: { eventLineUuid, page },
                    data: {
                        filter,
                    },
                },
                true,
            ),
        );
    };

export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "No candidates found!";
export default reducer;
