// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UwflIpgdHzaPPVBH0ASohQ\\=\\={width:60em}.UwflIpgdHzaPPVBH0ASohQ\\=\\= td{font-family:\"Roboto\", \"Helvetica\", \"Arial\", sans-serif;font-weight:400}\n", "",{"version":3,"sources":["webpack://./../History.scss"],"names":[],"mappings":"AAAA,4BACI,UAAW,CADf,+BAGQ,sDAAuD,CACvD,eAAgB","sourcesContent":[".tableWrapper {\n    width: 60em;\n    td {\n        font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n        font-weight: 400;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": "UwflIpgdHzaPPVBH0ASohQ=="
};
export default ___CSS_LOADER_EXPORT___;
