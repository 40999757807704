// @flow

/**
 * Module: Add Show
 */

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "addSerie",
    () => `/ays-series-event-management/series/options`,
    {
        method: "POST",
    },
);

export const load =
    (dates: string, name: string, promoterUuid: string, internal: string) =>
    (dispatch: *) => {
        return dispatch(
            actions.load({
                data: {
                    createOptionRequest: {
                        dates,
                        promoterUuid,
                    },
                    internal,
                    optionCode: name,
                },
            }),
        );
    };
export const ERROR = "Could not create event lines!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
