// @flow

import styles from "./index.style.scss";

import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import AsyncSelect from "../../../AsyncSelect";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";

type Props = {
    translate: *,
    state: {
        hourType: "OFFSET_DOORS" | "ABSOLUTE_HOURS",
        absoluteStart: ?string,
        absoluteEnd: ?string,
        offsetEnd: ?number,
        offsetStart: ?number,
    },
    onChange: (obj: {
        hourType: string,
        absoluteStart?: ?string,
        absoluteEnd?: ?string,
        offsetEnd?: ?number,
        offsetStart?: ?number,
    }) => void,
    disabled: boolean,
    lite: boolean,
};
const Hours = ({ translate, state, onChange, disabled, lite }: Props) => {
    const HOUR_TYPES = ["OFFSET_DOORS", "ABSOLUTE_HOURS"];
    const isOffset = state.hourType === "OFFSET_DOORS";

    const changeHandler = value => onChange({ ...state, ...value });

    const splitStart = state.absoluteStart?.split(":");
    const splitEnd = state.absoluteEnd?.split(":");

    translate("OFFSET_DOORS");
    translate("ABSOLUTE_HOURS");

    return (
        <Grid container spacing={3} className={!lite ? styles.wrapper : ""}>
            {!lite && (
                <Grid item sm={3}>
                    <Typography>{translate("Hours")}</Typography>
                </Grid>
            )}
            <Grid item sm={lite ? 12 : 9}>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <AsyncSelect
                            disabled={disabled}
                            id="details-staffingtemplate-hours-type"
                            fullWidth
                            options={HOUR_TYPES.map((type, i) => [
                                type,
                                <Typography key={i}>
                                    {translate(type)}
                                </Typography>,
                            ])}
                            value={state.hourType}
                            onChange={hourType => changeHandler({ hourType })}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        {isOffset ? (
                            <TextField
                                fullWidth
                                type={"number"}
                                disabled={disabled}
                                value={state.offsetStart}
                                label={translate(`Start`)}
                                onChange={({ target: { value } }) =>
                                    changeHandler({
                                        offsetStart: value,
                                    })
                                }
                            />
                        ) : (
                            <KeyboardTimePicker
                                ampm={false}
                                fullWidth
                                label={translate(`Start`)}
                                mask="__:__"
                                autoOk
                                disabled={disabled}
                                value={
                                    state.absoluteStart
                                        ? moment().set({
                                              hour: splitStart[0],
                                              minute: splitStart[1],
                                          })
                                        : null
                                }
                                onChange={time => {
                                    time &&
                                        time.isValid() &&
                                        changeHandler({
                                            absoluteStart: time.format("HH:mm"),
                                        });
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item sm={6}>
                        {isOffset ? (
                            <TextField
                                disabled={disabled}
                                fullWidth
                                type={"number"}
                                value={state.offsetEnd}
                                label={translate(`End`)}
                                onChange={({ target: { value } }) =>
                                    changeHandler({
                                        offsetEnd: value,
                                    })
                                }
                            />
                        ) : (
                            <KeyboardTimePicker
                                ampm={false}
                                fullWidth
                                autoOk
                                disabled={disabled}
                                label={translate(`End`)}
                                mask="__:__"
                                value={
                                    state.absoluteEnd
                                        ? moment().set({
                                              hour: splitEnd[0],
                                              minute: splitEnd[1],
                                          })
                                        : null
                                }
                                onChange={time => {
                                    time &&
                                        time.isValid() &&
                                        changeHandler({
                                            absoluteEnd: time.format("HH:mm"),
                                        });
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Hours;
