// @flow

import styles from "./index.scss";

import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { type State as TeamState } from "../../../../redux/modules/event-line-team.d";
import { type State as DirectState } from "../../../../redux/modules/event-line-member-candidates.d";
import { type State as InterestedState } from "../../../../redux/modules/event-recruits-interested.d";
import { type State as PoolState } from "../../../../redux/modules/event-recruitment.d";
import { type State as PhoneNumberState } from "../../../../redux/modules/event-line-member-phonenumbers.d";

import Wrapper from "./Wrapper";
import Pools from "./Pools";
import PhoneNumberList from "./PhoneNumberList";
import TransferList from "./TransferList";
import TransferPool from "./TransferPool";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

type Props = {
    id: string,
    disabled: boolean,
    team: TeamState,
    translate: *,
    onSubmit: (selected: string[]) => Promise<void>,
    onRecruitSubmit: (selected: string) => Promise<void>,
    onPhoneNumbersVerify: (
        teamUuid: string,
        phoneNumbers: string,
    ) => Promise<void>,
    clearVerifyPhoneNumbers: () => *,
    onClose: () => void,
    interested: InterestedState,
    loadInterested: (
        poolUuid: string,
        filter: string,
        recruitmentStatus: string[],
    ) => *,
    clearInterested: () => *,
    direct: DirectState,
    loadDirect: (filter: string) => *,
    clearDirect: () => *,
    pools: PoolState,
    loadPools: () => *,
    clearPools: () => *,
    /** The currently staffed members */
    members: string[],
    teamUuid: string,
    phoneNumbers: PhoneNumberState,
};

type State = {
    activeTab: string,
    selectedPool: ?string,
    phoneNumberField: ?string,
    selectedUuids: string[],
    isAddLoading: boolean,
    isAddPoolLoading: Boolean,
    isAddBulkLoading: boolean,
    staffingStatus: string,
    recruitmentStatus: string,
};

/**
 * Add to team
 */
export default class Add extends Component<Props, State> {
    // Default props
    static defaultProps = {
        disabled: false,
    };

    // Initial state
    state = {
        activeTab: this.tabs[0][0],
        selectedPool: null,
        phoneNumberField: null,
        selectedUuids: [],
        isAddLoading: false,
        isAddPoolLoading: false,
        isAddBulkLoading: false,
        staffingStatus: "",
        recruitmentStatus: "INTERESTED",
    };

    /**
     * Tabs getter
     */
    get tabs() {
        const { translate } = this.props;

        return [
            ["direct", translate("Direct")],
            ["pool", translate("From recruitment")],
            ["bulk", translate("Bulk")],
        ];
    }

    /**
     * Get selected pool name
     */
    get selectedPoolName() {
        const { pools } = this.props;
        const { selectedPool } = this.state;

        if (pools.data) {
            const pool = pools.data.find(pool => pool.uuid === selectedPool);

            if (pool) {
                return pool.name;
            }
        }

        return "...";
    }

    /**
     * HACK: Get the phoneNumbers from already staffed team members
     */
    get staffedPhoneNumbers(): * {
        const { team } = this.props;

        if (team.data) {
            return team.data.teamMembers.map(member => member.phoneNumber);
        }

        return [];
    }

    /**
     * Submit handler
     */
    handleSubmit() {
        const { onSubmit, onClose, clearDirect } = this.props;
        const { selectedUuids } = this.state;
        this.setState({
            isAddLoading: true,
        });
        onSubmit(selectedUuids).then(() => {
            onClose();
            clearDirect();
            this.setState({
                isAddLoading: false,
            });
        });
    }

    /**
     * Submit phonenumbers handler
     */
    handleSubmitPhoneNumbers() {
        //TODO bulk add found phone numbers
        const { phoneNumbers, onSubmit, onClose } = this.props;
        const staffedPhoneNumbers = this.staffedPhoneNumbers;

        if (phoneNumbers.data) {
            this.setState({
                isAddBulkLoading: true,
            });
            const toAdd = phoneNumbers.data.phoneNumbersFound
                .filter(
                    member => !staffedPhoneNumbers.includes(member.phoneNumber),
                )
                .map(member => member.membershipUuid);

            // Filter on phoneNumbers in team if team, else try

            if (toAdd.length) {
                onSubmit(toAdd).then(() => {
                    this.setState({
                        isAddBulkLoading: false,
                    });
                    onClose();
                });
            }
        }
    }

    /**
     * Clear verified on mount
     */
    componentDidMount() {
        const { clearVerifyPhoneNumbers } = this.props;
        clearVerifyPhoneNumbers();
    }

    /**
     * Recruit Submit handler
     */
    handleRecruitSubmit() {
        const { onRecruitSubmit, onClose, clearInterested } = this.props;
        const { selectedUuids } = this.state;
        this.setState({ isAddPoolLoading: true });
        onRecruitSubmit(selectedUuids).then(() => {
            this.setState({ isAddPoolLoading: false });
            onClose();
            clearInterested();
        });
    }

    /**
     * Check phonenumbers handler
     */
    handlePhoneNumbersCheck() {
        const { teamUuid, onPhoneNumbersVerify } = this.props;
        const { phoneNumberField } = this.state;
        if (phoneNumberField) {
            onPhoneNumbersVerify(teamUuid, phoneNumberField);
        }
    }

    /**
     * Render
     */
    render() {
        const {
            id,
            disabled,
            translate,
            onClose,
            direct,
            loadDirect,
            clearDirect,
            interested,
            loadInterested,
            pools,
            loadPools,
            clearPools,
            phoneNumbers,
        } = this.props;

        const {
            activeTab,
            selectedPool,
            selectedUuids,
            isAddLoading,
            isAddPoolLoading,
            isAddBulkLoading,
            staffingStatus,
            recruitmentStatus,
        } = this.state;

        const staffed = this.staffedPhoneNumbers;

        const staffing = [
            "PENDING",
            "PLANNED",
            "RESERVE",
            "NOT_PLANNED",
            "DECLINED_RESERVE",
            "DECLINED",
        ];

        const recruit = ["PENDING", "INTERESTED", "NOT_INTERESTED"];

        return (
            <Wrapper
                id={id}
                disabled={disabled}
                isAddLoading={isAddLoading}
                isAddPoolLoading={isAddPoolLoading}
                isAddBulkLoading={isAddBulkLoading}
                translate={translate}
                onClose={() => {
                    onClose();
                    clearDirect();
                }}
                tabs={this.tabs}
                active={activeTab}
                onTab={activeTab =>
                    this.setState({ activeTab, selectedUuids: [] })
                }
                onAdd={
                    (activeTab === "direct" &&
                        selectedUuids.length &&
                        this.handleSubmit.bind(this)) ||
                    undefined
                }
                onPoolAdd={
                    (activeTab === "pool" &&
                        selectedUuids.length &&
                        this.handleRecruitSubmit.bind(this)) ||
                    undefined
                }
                onBulkAdd={
                    (activeTab === "bulk" &&
                        !phoneNumbers.loading &&
                        phoneNumbers.data &&
                        phoneNumbers.data.phoneNumbersFound.filter(
                            member => !staffed.includes(member.phoneNumber),
                        ).length &&
                        this.handleSubmitPhoneNumbers.bind(this)) ||
                    undefined
                }
            >
                {activeTab === "direct" && (
                    <TransferList
                        translate={translate}
                        disabled={disabled}
                        state={direct}
                        load={loadDirect}
                        clear={clearDirect}
                        selected={selectedUuids}
                        onChange={selectedUuids =>
                            this.setState({ selectedUuids })
                        }
                    />
                )}
                {activeTab === "pool" && selectedPool && (
                    <Fragment>
                        <div className={styles.headerPool}>
                            <Typography variant="h5">
                                <Button
                                    onClick={() =>
                                        this.setState({ selectedPool: null })
                                    }
                                >
                                    <ArrowBackIcon />
                                </Button>
                                {this.selectedPoolName}{" "}
                            </Typography>
                            <div className={styles.dropdown}>
                                <FormControl
                                    className={styles.dropdownRecruit}
                                    fullWidth
                                >
                                    <InputLabel htmlFor={`${id}-input`}>
                                        Staffing status
                                    </InputLabel>
                                    <Select
                                        label="Staffing"
                                        value={staffingStatus}
                                        onChange={event => {
                                            this.setState({
                                                ...this.state,
                                                staffingStatus:
                                                    event.target.value,
                                            });
                                        }}
                                        name="staffing"
                                        displayEmpty
                                        className={styles.element}
                                    >
                                        <MenuItem value="">
                                            Staffing status
                                        </MenuItem>
                                        {staffing &&
                                            staffing.map(option => {
                                                return (
                                                    <MenuItem
                                                        value={option}
                                                        key={option}
                                                    >
                                                        <Typography align="center">
                                                            {option}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor={`${id}-input`}>
                                        User status
                                    </InputLabel>
                                    <Select
                                        label="User"
                                        value={recruitmentStatus}
                                        onChange={e => {
                                            this.setState({
                                                recruitmentStatus:
                                                    e.target.value,
                                            });
                                        }}
                                        name="user"
                                        displayEmpty
                                        className={styles.element}
                                    >
                                        <MenuItem value="">
                                            User status
                                        </MenuItem>
                                        {recruit &&
                                            recruit.map(option => {
                                                return (
                                                    <MenuItem
                                                        value={option}
                                                        key={option}
                                                    >
                                                        <Typography align="center">
                                                            {option}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <TransferPool
                            id={`${id}-pool`}
                            disabled={disabled}
                            translate={translate}
                            state={interested}
                            recruitmentStatuses={recruitmentStatus}
                            staffingStatus={staffingStatus}
                            load={filter => {
                                loadInterested(
                                    selectedPool,
                                    filter,
                                    recruitmentStatus
                                        ? [recruitmentStatus]
                                        : [],
                                );
                            }}
                            onChange={selectedUuids =>
                                this.setState({ selectedUuids })
                            }
                        />
                    </Fragment>
                )}
                {activeTab === "pool" && !selectedPool && (
                    <Pools
                        id={`${id}-pool-select`}
                        state={pools}
                        load={loadPools}
                        clear={clearPools}
                        onSelect={selectedPool =>
                            this.setState({
                                selectedPool,
                            })
                        }
                        translate={translate}
                    />
                )}

                {activeTab === "bulk" && (
                    <Fragment>
                        <TextField
                            multiline={true}
                            id={`${id}-bulk`}
                            label={translate("Phonenumbers")}
                            onChange={value =>
                                this.setState({
                                    phoneNumberField: value.target.value,
                                })
                            }
                            fullWidth
                        />
                        <Button
                            id={`${id}-checkPhonenumbers`}
                            onClick={this.handlePhoneNumbersCheck.bind(this)}
                        >
                            Check phonenumbers
                        </Button>

                        {!phoneNumbers.loading && phoneNumbers.data && (
                            <PhoneNumberList
                                id={`${id}-phoneNumberList`}
                                phoneNumbers={{
                                    ...phoneNumbers.data,
                                    phoneNumbersFound:
                                        phoneNumbers.data.phoneNumbersFound.filter(
                                            member =>
                                                !staffed.includes(
                                                    member.phoneNumber,
                                                ),
                                        ),
                                }}
                                translate={translate}
                            />
                        )}
                    </Fragment>
                )}
            </Wrapper>
        );
    }
}
