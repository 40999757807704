// @flow

import createApiModule from "../create-api-module";
import { load as loadMembers } from "./members";

const { reducer, actions } = createApiModule(
    "detailsMemberAdd",
    ({ uuid }) =>
        `/ays-organisation-management/organisations/${uuid}/memberships`,
    {
        method: "POST",
    },
);

export const load =
    (
        phoneNumber: string,
        description: string,
        noSmartphone: boolean,
        firstName: string,
        lastName: string,
    ) =>
    (dispatch: *, getState: *) => {
        const members = getState().members;
        const {
            data: { uuid },
        } = getState().details;
        return dispatch(
            actions.load({
                path: {
                    uuid,
                },
                data: {
                    phoneNumber,
                    description,
                    noSmartphone,
                    firstName,
                    lastName,
                },
            }),
        ).then(() =>
            dispatch(
                loadMembers(
                    members.get("organisationUUID"),
                    members.get("page"),
                    members.get("filter"),
                    members.get("sorting"),
                    true,
                ),
            ),
        );
    };
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not add errors";
export default reducer;
