// @flow

import styles from "./Team.scss";

import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { type State as TeamState } from "../../../redux/modules/event-line-team.d";
import { type State as DirectState } from "../../../redux/modules/event-line-member-candidates.d";
import { type State as showMemberQRCodeState } from "../../../redux/modules/event-line-member-qrcode.d";
import { type State as InterestedState } from "../../../redux/modules/event-recruits-interested.d";
import { type State as PoolState } from "../../../redux/modules/event-recruitment.d";
import { type User as UserState } from "../../../redux/modules/user.d";
import { type State as PhoneNumberState } from "../../../redux/modules/event-line-member-phonenumbers.d";

import TeamList from "./TeamList";
import Add from "./Add";
import MemberPDFModal from "../../Modals/MemberPDFModal/MemberPDFModal.js";
import { Box } from "@material-ui/core";

type Props = {
    id: string,
    translate: *,
    active: boolean,
    acl: *,
    load: () => *,
    disabled: boolean,
    loadDirect: (filter: string) => *,
    clearDirect: () => *,
    loadQRCode: (uuid: string) => *,
    clear: () => *,
    user: UserState,
    onClick: (uuid: string) => void,
    remove: (uuid: string) => *,
    demote: (uuid: string) => *,
    assign: (uuid: string) => *,
    unassign: (uuid: string) => *,
    promote: (uuid: string) => *,
    add: (uuid: string[]) => *,
    addRecruit: (uuid: string) => *,
    verifyPhoneNumbers: (teamUuid: string, phoneNumbers: string) => *,
    clearVerifyPhoneNumbers: () => *,
    team: TeamState,
    eventMemberQRCode: showMemberQRCodeState,
    direct: DirectState,
    interested: InterestedState,
    phoneNumbers: PhoneNumberState,
    loadInterested: (
        poolUuid: string,
        filter: string,
        recruitmentStatuses: string[],
    ) => *,
    clearInterested: () => *,
    pools: PoolState,
    loadPools: () => *,
    clearPools: () => *,
    planable: boolean,
};

type State = {
    open: boolean,
    qrOpen: boolean,
};

export default class Team extends Component<Props, State> {
    // Initial state
    state = {
        open: false,
        qrOpen: false,
    };
    /**
     * Load
     */
    load() {
        const { load } = this.props;
        load();
    }
    /**
     *   QRCode
     */
    handleQRCode(uuid: string) {
        const { loadQRCode } = this.props;
        loadQRCode(uuid);
        this.toggleQROpen();
    }
    /**
     *   Remove
     */
    remove(uuid: string) {
        const { remove } = this.props;
        remove(uuid);
    }
    /**
     *   Promote
     */
    promote(uuid: string) {
        const { promote } = this.props;
        promote(uuid);
    }
    /**
     *   Demote
     */
    demote(uuid: string) {
        const { demote } = this.props;
        demote(uuid);
    }
    /**
     *   Assign
     */
    assign(uuid: string) {
        const { assign } = this.props;
        assign(uuid);
    }
    /**
     *   Unassign
     */
    unassign(uuid: string) {
        const { unassign } = this.props;
        unassign(uuid);
    }
    /**
     *   Add
     */
    add(uuid: string[]) {
        const { add } = this.props;
        add(uuid);
    }
    /**
     *   Add Recruit
     */
    addRecruit(uuid: string[]) {
        const { addRecruit } = this.props;
        addRecruit(uuid);
    }
    /**
     * Dialog switch
     */
    toggleQROpen() {
        this.setState({
            qrOpen: !this.state.open,
        });
    }
    /**
     * Dialog switch
     */
    toggleOpen() {
        this.setState({
            open: !this.state.open,
        });
    }
    /**
     * On component mount
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
        this.load();
    }

    /**
     * Component updated
     */
    componentDidUpdate() {
        this.load();
    }

    /**
     * Render
     * TODO: Needs membershipUuid on team member for exclusion to work
     */
    render() {
        const {
            translate,
            active,
            team,
            direct,
            loadDirect,
            clearDirect,
            interested,
            loadInterested,
            clearInterested,
            pools,
            loadPools,
            clearPools,
            user,
            eventMemberQRCode,
            id,
            add,
            addRecruit,
            verifyPhoneNumbers,
            clearVerifyPhoneNumbers,
            acl,
            disabled,
            phoneNumbers,
            onClick,
            planable,
        } = this.props;
        const { open, qrOpen } = this.state;

        return (
            <div className={styles.wrapper}>
                <Typography>
                    <Box fontWeight="600">{translate("Team")}</Box>
                </Typography>
                {team.error && (
                    <Typography variant="subtitle1" color="error">
                        {team.error}
                    </Typography>
                )}
                {eventMemberQRCode.error && (
                    <Typography variant="subtitle1" color="error">
                        {eventMemberQRCode.error}
                    </Typography>
                )}
                {eventMemberQRCode.loading && <CircularProgress />}
                {team.data && (
                    <TeamList
                        translate={translate}
                        id={id}
                        team={team}
                        active={active}
                        onClick={onClick}
                        user={user}
                        toggle={this.toggleOpen.bind(this)}
                        demote={this.demote.bind(this)}
                        promote={this.promote.bind(this)}
                        assign={this.assign.bind(this)}
                        unassign={this.unassign.bind(this)}
                        remove={this.remove.bind(this)}
                        generateQRCode={this.handleQRCode.bind(this)}
                        canAdd={!disabled && acl("events.team.create")}
                        canEdit={!disabled && acl("events.team.update")}
                        canGenerate={!disabled && acl("events.team.qrcode")}
                        canDelete={!disabled && acl("events.team.delete")}
                        planable={planable}
                    />
                )}
                {team.loading && <CircularProgress />}
                {open && (
                    <Add
                        id={`${id}-add`}
                        team={team}
                        disabled={team.loading}
                        onSubmit={add}
                        onRecruitSubmit={addRecruit}
                        onPhoneNumbersVerify={verifyPhoneNumbers}
                        onClose={() =>
                            this.setState({
                                open: false,
                            })
                        }
                        translate={translate}
                        direct={direct}
                        loadDirect={loadDirect}
                        clearDirect={clearDirect}
                        interested={interested}
                        loadInterested={loadInterested}
                        clearInterested={clearInterested}
                        pools={pools}
                        loadPools={loadPools}
                        clearPools={clearPools}
                        members={[]}
                        teamUuid={team.data ? team.data.uuid : ""}
                        phoneNumbers={phoneNumbers}
                        clearVerifyPhoneNumbers={clearVerifyPhoneNumbers}
                    />
                )}
                {qrOpen && (
                    <MemberPDFModal
                        translate={translate}
                        disabled={disabled}
                        member={eventMemberQRCode}
                        onClose={() =>
                            this.setState({
                                qrOpen: false,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
