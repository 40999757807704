// @flow

/**
 * Module: Add Show
 */
import createApiModule from "../create-api-module";
import { type PathParams } from "./edit-option.d";

const { reducer, actions } = createApiModule(
    "editOption",
    ({ optionUuid }: PathParams) =>
        `/ays-series-event-management/options/${optionUuid}`,
    {
        method: "PUT",
    },
);

export const load =
    (optionUuid: string, promoterUuid: string) => (dispatch: *) =>
        dispatch(
            actions.load({
                path: {
                    optionUuid,
                },
                data: {
                    promoterUuid,
                },
            }),
        );

export const ERROR = "Could not create edit option!";
export const clear = actions.clear;
export const refetch = actions.refetch;
/** REDUCER **/
export default reducer;
