// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._19SVo4kZHDdnj734uL1VXQ\\=\\={margin:2em 6em;border-radius:0.25em}.YKgu4UCqHPwvutY8fVjjYw\\=\\={padding:2em;clear:both;overflow:hidden}.MU\\+Q8JVghyqrRmNeJWrDNg\\=\\={float:right;padding-top:1em;overflow:hidden}.dw2b7TF4WcLSBYVj89GfJg\\=\\={text-align:center}\n", "",{"version":3,"sources":["webpack://./../DetailsForm.scss"],"names":[],"mappings":"AAMA,6BACI,cAAe,CACf,oBAAqB,CACxB,4BAGG,WAAY,CACZ,UAAW,CACX,eAAgB,CACnB,6BAEG,WAAY,CACZ,eAAgB,CAChB,eAAgB,CACnB,4BAGG,iBAAkB","sourcesContent":["/**\n * Detailsform styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    margin: 2em 6em;\n    border-radius: 0.25em;\n}\n\n.form {\n    padding: 2em;\n    clear: both;\n    overflow: hidden;\n}\n.actionButtons {\n    float: right;\n    padding-top: 1em;\n    overflow: hidden;\n}\n\n.spinner {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "_19SVo4kZHDdnj734uL1VXQ==",
	"form": "YKgu4UCqHPwvutY8fVjjYw==",
	"actionButtons": "MU+Q8JVghyqrRmNeJWrDNg==",
	"spinner": "dw2b7TF4WcLSBYVj89GfJg=="
};
export default ___CSS_LOADER_EXPORT___;
