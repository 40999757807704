// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { typeof Client } from "../../utils/api-client/modules/templates";
import { type ImmutableState, type Action } from "./templates.d";

const ERROR = "Could not fetch templates";

const ZONE = "templates";
const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    data: null,
    venueUuid: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === PENDING) {
        invariant(typeof payload === "string", "Wrong payload!");

        return state.merge({
            loading: true,
            error: null,
            venueUuid: payload,
            data: null,
        });
    }

    if (type === SUCCESS) {
        invariant(
            payload && typeof payload.length === "number",
            "Wrong payload!",
        );

        return state.merge({
            loading: false,
            error: null,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        invariant(typeof payload === "string", "Wrong payload!");

        return state.merge({
            loading: false,
            error: payload,
            data: null,
        });
    }

    return state;
};

/**
 * ActionCreator: load
 */
export const load =
    (venueUuid: string) =>
    (
        dispatch: (action: Action) => void,
        getState: () => {
            templates: ImmutableState,
        },
        client: Client,
    ): Promise<void> => {
        const state: ImmutableState = getState().templates;

        if (state.get("loading") || state.get("venueUuid") === venueUuid) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: venueUuid,
        });

        return client
            .getTemplatesForVenue(venueUuid)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };
