// @flow

import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

import Named from "./Named";
import Status from "./Status";

type Props = {
    venueCode: string,
    events: any[],
};
const styles = StyleSheet.create({
    venueCell: {
        padding: 6,
        paddingBottom: 0,
    },
    anonymizedRow: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
});

const VenueCell = ({ events, venueCode }: Props) => {
    const joinedConsecutiveEvents = events.reduce((acc, event) => {
        if (acc.length && !acc[acc.length - 1][0].uuid && !event.uuid) {
            acc[acc.length - 1].push(event);
        } else {
            acc.push([event]);
        }

        return acc;
    }, []);
    return (
        <View style={styles.venueCell}>
            {joinedConsecutiveEvents.map((eventArray, index) => {
                return eventArray[0].uuid ? (
                    <View key={index}>
                        <Named event={eventArray[0]} venueCode={venueCode} />
                    </View>
                ) : (
                    <View style={styles.anonymizedRow} key={index}>
                        {eventArray.map((event, index) => (
                            <Status
                                key={index}
                                status={event.status}
                                venueCode={venueCode}
                            />
                        ))}
                    </View>
                );
            })}
        </View>
    );
};

export default VenueCell;
