// @flow

import logo from "../../logo.svg";

import styles from "./NavBar.scss";

import React, { PureComponent } from "react";
import List from "@material-ui/core/List";
import LanguageSelector from "./LanguageSelector";
import NavBarItem, { type ItemType } from "./NavBarItem";
import CollapseIcon from "../icons/Collapse";
import ExpandIcon from "../icons/Expand";

// Define props
type PropsType = {
    language: string,
    setLanguage: (language: string) => void,
    /** nav bar items */
    main: ItemType[],
    /** nav bar items */
    bottom: ItemType[],
    /** onClick event handler */
    onClick: (itemValue: string) => void,
    /** the active item */
    activeItem?: ?string,
    onCollapse: () => void,
    collapsed: boolean,
    translate: *,
};

/**
 * NavBar component
 */
class NavBar extends PureComponent<PropsType> {
    // default props
    static defaultProps = {
        items: [],
    };

    /**
     * Render
     */
    render() {
        const {
            main,
            bottom,
            onClick,
            activeItem,
            language,
            setLanguage,
            onCollapse,
            collapsed,
            translate,
        } = this.props;

        return (
            <div className={styles.navbar}>
                <div className={styles.header}>
                    <img
                        src={logo}
                        style={collapsed ? { maxWidth: 4 + "em" } : null}
                    />
                </div>
                <div className={styles.main}>
                    <List>
                        {main.map((item, index) => (
                            <NavBarItem
                                collapsed={collapsed}
                                key={`${item.value}-${index}`}
                                item={item}
                                onClick={() => onClick(item.value)}
                                active={activeItem === item.value}
                            />
                        ))}
                    </List>
                </div>
                <div className={styles.footer}>
                    {!collapsed && (
                        <LanguageSelector
                            active={language}
                            onClick={(language: string) => {
                                setLanguage(language);
                            }}
                        />
                    )}
                    <List>
                        {bottom.map((item, index) => {
                            return (
                                <NavBarItem
                                    collapsed={collapsed}
                                    key={`${item.value}-${index}`}
                                    item={item}
                                    onClick={() => onClick(item.value)}
                                    active={activeItem === item.value}
                                />
                            );
                        })}
                    </List>
                    <List>
                        <NavBarItem
                            item={{
                                value: "Collapse menu",
                                label: translate("Collapse menu"),
                                icon: collapsed ? ExpandIcon : CollapseIcon,
                            }}
                            collapsed={collapsed}
                            onClick={() => onCollapse()}
                            active={false}
                        />
                    </List>
                </div>
            </div>
        );
    }
}

export default NavBar;
