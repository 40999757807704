// @flow

export const getColor = (venueCode: string) => {
    switch (venueCode) {
        case "AS":
            return "#08ADFF";
        case "LA":
            return "#EE3124";
        case "SA":
            return "#8A6CD3";
        case "CG":
            return "#17382D";
        case "VN":
            return "#FFA75E";
        case "FN":
            return "#FFA75E";
        case "TA":
            return "#8CC63F";
        case "TT":
            return "#8CC63F";
        case "EH":
            return "#8CC63F";
        case "PA":
            return "#5C2D91";
        case "":
            return "transparent";
        default:
            return "#7343B9";
    }
};

export const getFontColor = (venueCode: string) => {
    switch (venueCode) {
        case "AS":
            return "#EDFAFF";
        case "LA":
            return "#FFD2BE";
        case "SA":
            return "#C2FFC2";
        case "CG":
            return "#FAE682";
        case "VN":
            return "#F5E9D0";
        case "FN":
            return "#F5E9D0";
        case "TA":
            return "#DEFFD9";
        case "TT":
            return "#DEFFD9";
        case "EH":
            return "#DEFFD9";
        case "PA":
            return "#E5D8F0";
        case "":
            return "transparent";
        default:
            return "#FDF9E2";
    }
};
