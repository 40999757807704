// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N9lQuNj9Y3Lhw4rBUPcvIA\\=\\={padding-bottom:1.2em}.N9lQuNj9Y3Lhw4rBUPcvIA\\=\\= span{color:#181c20}\n", "",{"version":3,"sources":["webpack://./../style.module.scss","webpack://./../../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,4BACI,oBAAqB,CADzB,iCAGQ,aCCM","sourcesContent":["@import \"../../../styles/_variables.scss\";\n\n.warning {\n    padding-bottom: 1.2em;\n    span {\n        color: $onyx;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "N9lQuNj9Y3Lhw4rBUPcvIA=="
};
export default ___CSS_LOADER_EXPORT___;
