// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import debounce from "lodash.debounce";

import { type State } from "../../redux/modules/admin.d";

import {
    load,
    clear,
    ERROR as ADMIN_LIST_ERROR,
} from "../../redux/modules/admin";
import { TabbedHeader } from "../../components/Authenticated";
import FilterField from "../../components/Authenticated/FilterField";

import { withTranslate } from "../Translate";
import stripslash from "../../utils/stripslash";
import UserList from "../../components/Admin/Userlist/UserList";

import Add from "./Add";
import Edit from "./Edit";
import DeleteUser from "./DeleteUser";
import { mapState } from "../../redux/utils";
import isEqual from "lodash.isequal";

type Props = {
    translate: *,
    match: Match,
    admin: State,
    load: (
        page: number,
        filter: string,
        sorting: string[],
        force: boolean,
    ) => void,
    clear: () => void,
    history: RouterHistory,
    onPageChange: (page: number) => void,
    onSelect: (id: string) => void,
};

type StateType = {
    filter: string,
    sorting: string[],
};
const DEBOUNCE_WAIT = 250;
const DEBOUNCE_MAX_WAIT = 500;

class AdminList extends Component<Props, StateType> {
    // Initial state
    state: StateType = {
        filter: "",
        sorting: [],
    };

    /**
     * Initial load
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
    }

    /**
     * Trigger load on update
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;

        if (
            prevProps.match.params.page !== match.params.page ||
            !isEqual(prevState, this.state)
        ) {
            this.reload();
        }
    }

    handleSortChange(sortString: string[]) {
        this.setState({
            sorting: sortString,
        });
    }

    /**
     *
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    /**
     * Trigger update
     */
    reload = debounce(
        (force: boolean = false) => {
            const { load, match } = this.props;
            const { filter, sorting } = this.state;
            if (this.pageParamIsNumber) {
                load(parseInt(match.params.page), filter, sorting, force);
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    /**
     * Render
     */
    render() {
        const { onPageChange, match, admin, onSelect, translate, history } =
            this.props;
        const { filter } = this.state;

        if (!this.pageParamIsNumber) {
            return <h1>404: Not Found</h1>;
        }

        return (
            <div>
                <TabbedHeader
                    id="admin-header"
                    title="Admin"
                    tabs={[translate("Users")]}
                    active={0}
                    onChange={() => {}}
                />
                <span style={{ marginTop: "1em", display: "block" }} />
                <FilterField
                    onChange={filter => this.setState({ filter })}
                    placeholder={translate(
                        "Filter by name, description or phone number",
                    )}
                    id="admin-userlist-filter"
                    value={filter}
                />
                <UserList
                    translate={translate}
                    onAdd={() => history.push(`${stripslash(match.url)}/add/`)}
                    onDelete={id =>
                        history.push(`${stripslash(match.url)}/${id}/delete/`)
                    }
                    onEdit={id =>
                        history.push(`${stripslash(match.url)}/${id}/edit/`)
                    }
                    onSelect={onSelect}
                    admin={admin}
                    onPageChange={onPageChange}
                    onSortChange={this.handleSortChange.bind(this)}
                    filter={filter}
                />
                <Switch>
                    <Route path={`${match.path}add/`}>
                        <Add
                            id="admin-user-add"
                            disabled={admin.loading}
                            onClose={() =>
                                history.push(`${stripslash(match.url)}/`)
                            }
                        />
                    </Route>
                    <Route path={`${match.path}:id/delete/`}>
                        <DeleteUser
                            onBack={() =>
                                history.push(`${stripslash(match.url)}/`)
                            }
                        />
                    </Route>
                    <Route path={`${match.path}:id/edit/`}>
                        <Edit
                            id="admin-user-edit"
                            disabled={admin.loading}
                            onClose={() =>
                                history.push(`${stripslash(match.url)}/`)
                            }
                        />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            ({ admin }) => ({
                admin: {
                    ...mapState(admin, ADMIN_LIST_ERROR),
                    page:
                        admin.params &&
                        admin.params.path &&
                        admin.params.path.page + 1,
                },
            }),
            (dispatch: *) => bindActionCreators({ load, clear }, dispatch),
        )(AdminList),
    ),
);
