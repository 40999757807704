import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StyledFormControlLabel = withStyles({
    root: {
        marginLeft: 0,
        marginRight: 0,
        paddingRight: "10px",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
    },
    label: {
        textTransform: "uppercase",
        color: "#888888",
    },
})(FormControlLabel);

export default StyledFormControlLabel;
