// @flow

import styles from "./Login.scss";

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { parsePhoneNumber } from "libphonenumber-js";

// Define props
type Props = {
    /** Translate interface */
    translate: *,
    /** on submit handler */
    onSubmit: () => *,
    /** phone number of the user */
    phone: string,
};

/**
 * ForgotPassword component
 */
export default class ForgotPassword extends Component<Props> {
    /**
     * Get formatted phone number
     */
    get formattedPhone(): string {
        const { phone } = this.props;
        return parsePhoneNumber(phone).formatInternational();
    }

    /**
     * Render
     */
    render() {
        const { onSubmit, translate } = this.props;

        return (
            <form
                className={styles.form}
                onSubmit={event => {
                    event.preventDefault();
                    onSubmit();
                }}
            >
                <Typography variant="h2">
                    {translate("Forgot your password?")}
                </Typography>
                <Typography>
                    {translate(
                        "Don’t worry, it happens to the best of us. Click the button below to request a reset SMS-code to be send to %{phone}.",
                        { phone: this.formattedPhone },
                    )}
                </Typography>
                <div className={styles.cell}>
                    <Button
                        id="login-forgotpassword-submit"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        {translate("Request SMS-code")}
                    </Button>
                </div>
            </form>
        );
    }
}
