//@flow

import React, { Component, Fragment } from "react";
import _Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import classnames from "classnames";

import Header from "./Header";
import Item from "./Item";

import styles from "./Table.scss";

// Import types
import type { Node } from "react";
import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

type Props = {
    translate: *,
    error: ?string,
    data: ?Object,
    page: number,
    config: Config,
    tab?: number,
    onPageChange: (n: number) => void,
    onTabChange?: (t: number) => void,
    getSortString: (sortstring: string[]) => void,
    hasPagination: boolean,
    bordered?: boolean,
    extraPadding?: boolean,
    filter: string,
    sorting?: string[],
};

type StateType = {
    sorting: SortItem[],
    didSort: boolean,
};

export type SortItem = {
    name: string,
    active: boolean,
    order: "asc" | "desc",
};

export type TabOption = {
    name: string,
    amount?: number,
    notificationAmount?: number,
};

export type Config = {
    id: string,
    uuidKey: string,
    hasMenu: boolean,
    hasTabs?: boolean,
    onClick: ?(obj: Object) => void,
    configItems: ConfigItem[],
    menuOptions: Object[],
    tabOptions?: TabOption[],
};

export type ConfigItem = {
    key: string,
    name?: string,
    customRender?: (item: Object, index: number) => Node,
    displayName: string,
    sortable: boolean,
    defaultSort?: "asc" | "desc",
    isNumeric: boolean,
    permission: boolean,
};

export default class Table extends Component<Props, StateType> {
    static defaultProps = {
        hasPagination: true,
    };

    state = {
        sorting: this.props.sorting?.length
            ? this.props.sorting.map(s => {
                  const splitted = s.split(",");
                  return {
                      name: splitted[0],
                      active: true,
                      order: splitted[1].toLowerCase(),
                  };
              })
            : [],
        activeTab: 0,
        didSort: false,
    };
    /**
     * Handle page change request
     */
    componentWillMount() {
        const { config } = this.props;
        const { sorting, didSort } = this.state;
        config.configItems.map(item => {
            if (item.defaultSort && sorting.length === 0 && !didSort) {
                sorting.push({
                    name: item.key,
                    active: true,
                    order: item.defaultSort,
                });

                this.setState({
                    sorting,
                });

                this.generateSorting();
            }
        });
    }

    componentDidUpdate(prevProps: Props) {
        const { onPageChange, filter } = this.props;
        if (prevProps.filter !== filter) {
            onPageChange(0);
        }
    }

    generateSorting() {
        const { getSortString } = this.props;
        const { sorting } = this.state;

        let sort = [];

        sorting.map((sortItem: Object) => {
            if (sortItem.active) {
                sort.push(`${sortItem.name},${sortItem.order.toUpperCase()}`);
            }
        });
        getSortString(sort);
    }

    handleChange(_: *, tab: number) {
        const { onTabChange } = this.props;
        if (onTabChange) {
            onTabChange(tab);
        }
    }

    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page);
    }

    handleSort(
        displayName: string,
        defaultSortingMethod: "asc" | "desc" = "desc",
    ) {
        const { sorting } = this.state;
        const sortItem = sorting.findIndex(i => i.name === displayName);
        //Check if there is already been a sorting action for said coluwn
        if (sortItem >= 0) {
            //Check if the clicked item is active
            if (sorting[sortItem].active) {
                //Check if its the asc or desc
                switch (sorting[sortItem].order) {
                    case "desc":
                        sorting[sortItem].order = "asc";
                        break;
                    case "asc":
                        sorting[sortItem].active = false;
                }
            } else {
                //Set it to true and set the default sorting method
                sorting[sortItem].active = true;
                sorting[sortItem].order = defaultSortingMethod;
            }
        } else {
            sorting.length = 0;
            //Add the item to the array if it didnt exist yet.
            sorting.push({
                name: displayName,
                active: true,
                order: defaultSortingMethod,
            });
        }

        this.setState({
            sorting,
        });

        this.generateSorting();
        this.props.onPageChange(0);
    }
    getTabLabel = (tab: TabOption) => {
        return (
            <Fragment>
                <span>
                    {tab.name}
                    {tab.amount !== null && tab.amount !== undefined
                        ? ` (${tab.amount})`
                        : ""}
                </span>
                {tab.notificationAmount && tab.notificationAmount > 0 ? (
                    <span className={styles.notification}>
                        {tab.notificationAmount}
                    </span>
                ) : null}
            </Fragment>
        );
    };

    TablePaginationActions = () => {
        const { page, data, onPageChange } = this.props;
        const rowsPerPage = (data && data.size) || 0;
        const count = data ? data.totalElements : 0;

        const handleFirstPageButtonClick = () => {
            onPageChange(0);
        };

        const handleBackButtonClick = () => {
            onPageChange(page - 2);
        };

        const handleNextButtonClick = () => {
            onPageChange(page);
        };

        const handleLastPageButtonClick = () => {
            onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 1}
                    aria-label="first page"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 1}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage)}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage)}
                    aria-label="last page"
                >
                    <LastPageIcon />
                </IconButton>
            </div>
        );
    };

    render() {
        const { sorting } = this.state;
        const {
            page,
            config,
            data,
            error,
            translate,
            tab,
            hasPagination,
            bordered,
            extraPadding,
        } = this.props;

        return (
            <div>
                {data && (
                    <Paper className={styles.paper} elevation={2}>
                        {config.hasTabs && (
                            <div className={styles.wrapper}>
                                <Tabs
                                    value={tab}
                                    onChange={this.handleChange.bind(this)}
                                    TabIndicatorProps={{
                                        className: styles.indicator,
                                    }}
                                >
                                    {config.tabOptions &&
                                        config.tabOptions.map((tab, index) => {
                                            return (
                                                <Tab
                                                    label={this.getTabLabel(
                                                        tab,
                                                    )}
                                                    key={index}
                                                    id={`${config.id}-tab-${index}`}
                                                />
                                            );
                                        })}
                                </Tabs>
                            </div>
                        )}

                        <div className={styles.tableWrapper}>
                            {error && <h1>{error}</h1>}
                            <_Table
                                className={classnames(
                                    bordered && styles.bordered,
                                    extraPadding && styles.extraPadding,
                                )}
                                stickyHeader
                            >
                                <Header
                                    config={config}
                                    sorting={sorting}
                                    sortHandler={displayName =>
                                        this.handleSort(displayName)
                                    }
                                />
                                <TableBody>
                                    {data &&
                                        data.content.map(
                                            (contentItem, index) => (
                                                <Item
                                                    key={
                                                        contentItem[
                                                            config.uuidKey
                                                        ]
                                                    }
                                                    index={index}
                                                    translate={translate}
                                                    config={config}
                                                    contentItem={contentItem}
                                                />
                                            ),
                                        )}
                                </TableBody>

                                <TableFooter className={styles.tableFooter}>
                                    <TableRow>
                                        {hasPagination && (
                                            <TablePagination
                                                ActionsComponent={
                                                    this.TablePaginationActions
                                                }
                                                // backIconButtonProps={{
                                                //     id: `${config.id}-list-page-back`,
                                                // }}
                                                // nextIconButtonProps={{
                                                //     id: `${config.id}-list-page-next`,
                                                // }}
                                                colSpan={
                                                    config.hasMenu
                                                        ? config.configItems
                                                              .length + 1
                                                        : config.configItems
                                                              .length
                                                }
                                                count={
                                                    data
                                                        ? data.totalElements
                                                        : 0
                                                }
                                                rowsPerPage={
                                                    (data && data.size) || 0
                                                }
                                                rowsPerPageOptions={[10]}
                                                page={page - 1}
                                                onChangePage={this.handleChangePage.bind(
                                                    this,
                                                )}
                                                onChangeRowsPerPage={
                                                    (null: any)
                                                }
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>
                            </_Table>
                        </div>
                    </Paper>
                )}
            </div>
        );
    }
}
