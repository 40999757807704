// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./update-event-briefing.d";

import { addNotification } from "./notifications";

const { reducer, actions } = createApiModule(
    "updateEventBriefing",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/events/${eventUuid}/briefing`,
    {
        method: "POST",
    },
);

export const load =
    (
        eventUuid: string,
        briefing: string,
        preBriefing: string,
        preBriefingNotification: boolean,
        briefingNotification: boolean,
        applySeries: { applyForSeries: boolean, eventUuids: string[] },
    ) =>
    (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { eventUuid },
                data: {
                    briefing,
                    preBriefing,
                    preBriefingNotification,
                    briefingNotification,
                    ...applySeries,
                },
            }),
        )
            .then(() =>
                dispatch(
                    addNotification(
                        "Briefing updated successfully!",
                        "success",
                    ),
                ),
            )
            .catch(error => {
                dispatch(addNotification(error && error.message, "error"));
                throw error;
            });
    };

export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
