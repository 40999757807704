// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { withTranslate } from "../Translate";

import {
    load as cancelEvent,
    clear as clearCancelEvent,
    ERROR as CANCEL_EVENT_ERROR,
} from "../../redux/modules/cancel-event";
import { refetch as refetchCalendar } from "../../redux/modules/calendar";
import { type State as CancelEventState } from "../../redux/modules/cancel-event.d";
import Cancel from "../../components/Events/Cancel";
import { type State as eventDetails } from "../../redux/modules/event-details.d";
import {
    load as loadEventDetails,
    clear as clearShowDetails,
} from "../../redux/modules/event-details";
import { mapState } from "../../redux/utils";

type Props = {
    onClose: () => void,
    translate: *,
    match: Match,
    cancelEvent: (uuid: *) => Promise<*>,
    cancelEventState: CancelEventState,
    clearCancelEvent: *,
    eventDetails: eventDetails,
    loadEventDetails: *,
    clearShowDetails: *,
    refetchCalendar: () => Promise<*>,
};

class CancelEventContainer extends Component<Props> {
    /**
     * Get uuid
     */
    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }
    componentDidMount() {
        const { loadEventDetails } = this.props;
        loadEventDetails(this.uuid);
    }

    /**
     * Render
     */
    render() {
        const {
            translate,
            onClose,
            cancelEvent,
            cancelEventState,
            eventDetails,
            refetchCalendar,
        } = this.props;

        return (
            <Cancel
                type="EVENT"
                translate={translate}
                onClose={onClose}
                error={cancelEventState.error}
                loading={cancelEventState.loading}
                event={eventDetails}
                uuid={this.uuid}
                dataLoading={eventDetails.loading}
                onSubmit={uuid => {
                    cancelEvent(uuid).then(() => {
                        refetchCalendar();
                        onClose();
                    });
                }}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ cancelEvent, eventDetails }) => ({
                cancelEventState: mapState(cancelEvent, CANCEL_EVENT_ERROR),
                eventDetails,
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        clearCancelEvent,
                        clearShowDetails,
                        loadEventDetails,
                        cancelEvent: uuid => cancelEvent(uuid),
                        refetchCalendar: () => refetchCalendar(),
                    },
                    dispatch,
                ),
        )(CancelEventContainer),
    ),
);
