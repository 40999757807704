// @flow

/**
 * ExportData module
 */

import createApiModule from "../create-api-module";
import qs from "qs";
import { type PathParams } from "./export-data.d";

const { reducer, actions } = createApiModule(
    "exportData",
    ({ exportFor, showCanceled }: PathParams) =>
        `ays-searches/searches/options-events?${qs.stringify({
            exportFor,
            showCanceled,
        })}`,
    {
        method: "POST",
    },
);

export const load =
    (data: *, exportFor: string = "me", showCanceled: boolean = false) =>
    (dispatch: *) => {
        const { startDate, endDate, promoterUuids, venueUuids } = data;

        return dispatch(
            actions.load({
                path: {
                    showCanceled,
                    exportFor,
                },
                data: {
                    startDate,
                    endDate,
                    promoterUuids,
                    venueUuids,
                },
            }),
        );
    };
export const clear = actions.clear;
export const refetch = actions.refetch;
/** REDUCER **/
export default reducer;
