// @flow

import createApiModule from "../create-api-module";
import { type Event } from "./add-event.d";
const { reducer, actions } = createApiModule(
    "addEvent",
    () => "/ays-series-event-management/series/events",
    {
        method: "POST",
    },
);

export const load =
    (event: Event, internal: string, seriesName: string, optionCode: string) =>
    (dispatch: *) => {
        const {
            assignParking,
            end,
            name,
            promoterUuid,
            start,
            status,
            templateUuids,
            venueUuid,
        } = event;

        return dispatch(
            actions.load({
                data: {
                    createEventRequests: [
                        {
                            assignParking: assignParking,
                            end: end,
                            name: name,
                            promoterUuid: promoterUuid,
                            start: start,
                            status: status,
                            templateUuids: templateUuids,
                            venueUuid: venueUuid,
                        },
                    ],
                    internal: internal,
                    name: seriesName,
                    optionCode,
                },
            }),
        );
    };
export const ERROR = "Could not add event!";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
