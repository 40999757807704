// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O3XIoo7kK8v5VBTIrcBJ1Q\\=\\={text-align:center}._0wrK6Iwl\\+9Jsdn\\+SL\\+7T8A\\=\\={display:flex;align-items:center;justify-content:space-between;width:100%}._0wrK6Iwl\\+9Jsdn\\+SL\\+7T8A\\=\\= h2{overflow:hidden;text-overflow:ellipsis}._0wrK6Iwl\\+9Jsdn\\+SL\\+7T8A\\=\\= span img{width:10em;border-radius:50%}\n", "",{"version":3,"sources":["webpack://./../EditMember.scss"],"names":[],"mappings":"AAAA,4BACI,iBAAkB,CACrB,gCAGG,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,UAAW,CAJf,mCAOQ,eAAgB,CAChB,sBAAuB,CAR/B,yCAaY,UAAW,CACX,iBAAkB","sourcesContent":[".loading {\n    text-align: center;\n}\n\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n\n    h2 {\n        overflow: hidden;\n        text-overflow: ellipsis;\n    }\n\n    span {\n        img {\n            width: 10em;\n            border-radius: 50%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "O3XIoo7kK8v5VBTIrcBJ1Q==",
	"header": "_0wrK6Iwl+9Jsdn+SL+7T8A=="
};
export default ___CSS_LOADER_EXPORT___;
