// @flow

import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

/**
 * Test if an event is in anon mode or not
 */
const sortEvents = events => {
    const copyEvents = [...events];
    return copyEvents.sort((a, b) => {
        return a.ranking - b.ranking;
    });
};

/**
 * Get all events for all venues for a certain day
 */
const getVenuesForDay = (day, venues, dates) => {
    const relevantDates = dates.filter(date => date.date.isSame(day, "day"));

    return venues.reduce((venues, venue) => {
        venues[venue.code] = [];

        for (const date of relevantDates) {
            const sortedEvents = sortEvents(
                date.venues.find(
                    venueEntry => venueEntry.venueUuid === venue.uuid,
                )?.events || [],
            );

            venues[venue.code].push(...sortedEvents);
        }

        return venues;
    }, {});
};

/**
 * Convert all dates to moment objects
 */
const convertDates = dates =>
    dates.map(date => ({
        ...date,
        date: moment(date.date, "YYYY-MM-DD"),
    }));

/**
 * Processes the data structure for easy consumption
 */
const generate = (start, end, dates, venues) =>
    Array.from(moment.range(start, end).by("day")).map<any>(day => ({
        day,
        venues: getVenuesForDay(day, venues, dates),
    }));

/**
 * External process
 */
export default (start: any, end: any, dates: any[], venues: any[]) =>
    generate(start, end, convertDates(dates), venues);
