// @flow

import createApiModule from "../create-api-module";
import { refetch as reload } from "./details";

const { reducer, actions } = createApiModule(
    "detailsUpdate",
    ({ uuid }) => `/ays-organisation-management/organisations/${uuid}`,
    {
        method: "PUT",
        headers: {
            "Content-type": "multipart/form-data",
        },
    },
);

export const load =
    (
        uuid: string,
        name: string,
        maxLeaders: number,
        description: string,
        image: ImageData,
    ) =>
    (dispatch: *) => {
        let data = new FormData();
        if (image) {
            data.append("image", image);
        }
        let request = {
            name: name,
            maxLeaders: maxLeaders,
            description: description,
        };
        data.append("request", JSON.stringify(request));
        dispatch(
            actions.load({
                path: {
                    uuid,
                },
                data: data,
            }),
        ).then(() => dispatch(reload()));
    };
export const refetch = actions.refetch;
export const clear = actions.clear;
export const ERROR = "No update!";
export default reducer;
