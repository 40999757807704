// @flow

import { connect } from "react-redux";

import {
    load,
    clear,
    remove,
    promote,
    demote,
    assign,
    unassign,
    add,
    addRecruit,
} from "../../../redux/modules/event-line-team";

import {
    load as verifyPhoneNumbers,
    clear as clearVerifyPhoneNumbers,
    ERROR as SHOW_LINE_MEMBER_ERROR,
} from "../../../redux/modules/event-line-member-phonenumbers";

import {
    load as loadQRCode,
    ERROR as SHOW_MEMBER_QRCODE_ERROR,
} from "../../../redux/modules/event-line-member-qrcode";

import {
    load as loadCandidates,
    clear as clearDirect,
    ERROR as SHOW_LINE_MEMBER_CANDIDATES,
} from "../../../redux/modules/event-line-member-candidates";

import {
    load as loadInterested,
    clear as clearInterested,
} from "../../../redux/modules/event-recruits-interested";

import {
    load as loadPools,
    clear as clearPools,
} from "../../../redux/modules/event-recruitment";

import Team from "../../../components/Events/LineStaffing/Team";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
import { mapState } from "../../../redux/utils";
import { load as loadLines } from "../../../redux/modules/event-lines";
export default withTranslate(
    withAcl(
        connect(
            // Map state to props
            ({
                eventLineTeam,
                eventLineMemberCandidates,
                showRecruitsInterested,
                eventRecruitment,
                eventMemberQRCode,
                showLineMemberPhoneNumbers,
                eventLineOrganisations,
                user,
            }) => ({
                team: eventLineTeam.toJS(),
                user,
                direct: mapState(
                    eventLineMemberCandidates,
                    SHOW_LINE_MEMBER_CANDIDATES,
                ),
                interested: showRecruitsInterested.toJS(),
                eventMemberQRCode: mapState(
                    eventMemberQRCode,
                    SHOW_MEMBER_QRCODE_ERROR,
                ),
                pools: eventRecruitment.toJS(),
                phoneNumbers: mapState(
                    showLineMemberPhoneNumbers,
                    SHOW_LINE_MEMBER_ERROR,
                ),
                primaryOrganisation: eventLineOrganisations.getIn([
                    "data",
                    0,
                    "organisationUuid",
                ]),
            }),
            // Map dispatch to props
            (dispatch: *, { eventLineUuid, eventUuid }) => ({
                load: () => dispatch(load(eventLineUuid)),
                loadQRCode: uuid => dispatch(loadQRCode(uuid, true)),
                loadDirect: (filter, page) =>
                    dispatch(loadCandidates(page, filter, eventLineUuid)),
                clearDirect: () => dispatch(clearDirect()),
                remove: memberUuid =>
                    dispatch(remove(memberUuid)).then(() =>
                        dispatch(loadLines(eventUuid, true)),
                    ),
                demote: memberUuid => dispatch(demote(memberUuid)),
                promote: memberUuid => dispatch(promote(memberUuid)),
                assign: memberUuid => dispatch(assign(memberUuid)),
                unassign: memberUuid => dispatch(unassign(memberUuid)),
                add: memberUuid =>
                    dispatch(add(memberUuid)).then(() =>
                        dispatch(loadLines(eventUuid, true)),
                    ),
                addRecruit: memberUuid =>
                    dispatch(addRecruit(memberUuid)).then(() =>
                        dispatch(loadLines(eventUuid, true)),
                    ),
                clearVerifyPhoneNumbers: () =>
                    dispatch(clearVerifyPhoneNumbers()),
                verifyPhoneNumbers: (
                    teamUuid,
                    organisationUuid,
                    phoneNumbers,
                ) =>
                    dispatch(
                        verifyPhoneNumbers(
                            teamUuid,
                            organisationUuid,
                            phoneNumbers,
                        ),
                    ),
                clear: () => dispatch(clear()),
                loadPools: () => dispatch(loadPools(eventUuid)),
                clearPools: () => dispatch(clearPools()),
                loadInterested: (pool, filter, recruitmentStatuses) =>
                    dispatch(
                        loadInterested(pool, 1, filter, recruitmentStatuses),
                    ),
                clearInterested: () => dispatch(clearInterested()),
            }),
            // Mergeprops
            (stateProps: *, dispatchProps: *, ownProps: *) => ({
                ...stateProps,
                ...dispatchProps,
                ...ownProps,
                verifyPhoneNumbers: (teamUuid, phoneNumbers) =>
                    dispatchProps.verifyPhoneNumbers(
                        teamUuid,
                        (stateProps: any).primaryOrganisation,
                        phoneNumbers,
                    ),
            }),
        )(Team),
    ),
);
