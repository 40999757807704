// @flow
import FormError from "./FormError";
import get from "lodash.get";

/**
 * @param {Array<string>} stages
 * @param {string} prefix
 * @param {string} namespace
 */
export const createConstants = (stages: *, prefix: *, namespace: *) =>
    (stages.reduce(
        (accumulator, current) => ({
            ...accumulator,
            [current.toUpperCase()]: `${prefix}/${namespace}/${current}`,
        }),
        {},
    ): any);

/**
 *
 * @param {any} error
 */
export const normalizeError = (error: any): any => {
    if (error.response && error.response.data) {
        const { data } = error.response;

        if (data.fieldErrors && data.fieldErrors.length) {
            return new FormError(data.fieldErrors);
        }

        if (data.message) {
            return new Error(data.message);
        }

        if (data.code) {
            return new Error(data.code);
        }
    }
    return error;
};

export const mapState = (
    state: *,
    defaultError: string,
    targetProp?: string,
) => ({
    ...state,
    data: get(state, ["data", targetProp], state.data),
    fieldErrors:
        state.error && state.error.messages ? state.error.messages : null,
    error:
        state.error && !state.error.messages
            ? state.error && state.error.message
                ? state.error.message
                : defaultError
            : null,
});
