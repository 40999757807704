// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-history.d";

const { reducer, actions } = createApiModule(
    "eventHistory",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/events/${uuid}/access-logs`,
);

export const ERROR = "Not able to get this event history!";

export const load = (uuid: string) => (dispatch: *) =>
    dispatch(actions.load({ path: { uuid } }));

export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
