// @flow

import styles from "./OrganisationsList.scss";

import React from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";

import { type EventLineOrganisation } from "../../../redux/modules/event-line-organisations.d";
import { type User as UserState } from "../../../redux/modules/user.d";
import Item from "./ListItem";

type Props = {
    id: string,
    translate: *,
    active: boolean,
    user: UserState,
    organisations: EventLineOrganisation[],
    onDelete: (uuid: string) => void,
    onActivate: (uuid: string) => void,
    onDeactivate: (ueuid: string) => void,
    onToggle: (type: string) => void,
    canAdd: boolean,
    canEdit: boolean,
    canDelete: boolean,
};

/**
 * Helper: Has primary
 */
const hasPrimary = (items: EventLineOrganisation[]) =>
    items.reduce(
        (accumulator, current) =>
            accumulator || current.organisationType === "PRIMARY",
        false,
    );

/**
 * Organisation list component
 */
const OrganisationsList = ({
    id,
    active,
    organisations,
    onDelete,
    onActivate,
    onDeactivate,
    onToggle,
    translate,
    canAdd,
    canEdit,
    user,
    canDelete,
}: Props) => (
    <Paper elevation={2}>
        {canAdd && active && !hasPrimary(organisations) && (
            <List>
                <ListItem
                    button
                    id="add-primary-organisation"
                    onClick={() => onToggle("main")}
                >
                    <ListItemText
                        className={styles.add}
                        color={"inherit"}
                        disableTypography
                        primary={translate("Add primary organisation")}
                    />
                </ListItem>
            </List>
        )}
        <Table>
            <TableBody>
                {organisations
                    .sort(a => a.organisationType === "PRIMARY" && -1)
                    .map((organisation, index) => (
                        <Item
                            key={organisation.uuid}
                            active={active}
                            id={id}
                            index={index}
                            organisation={organisation}
                            translate={translate}
                            onDelete={() => onDelete(organisation.uuid)}
                            onDeactivate={() => onDeactivate(organisation.uuid)}
                            onActivate={() => onActivate(organisation.uuid)}
                            canEdit={canEdit}
                            isMyMember={
                                user &&
                                user.data &&
                                user.data.profile &&
                                user.data.profile
                                    .organisation_leader_organisations
                                    ? (user.data.profile
                                          .organisation_leader_organisations: string).includes(
                                          organisation.organisationUuid,
                                      )
                                        ? true
                                        : false
                                    : false
                            }
                            canDelete={canDelete}
                        />
                    ))}
            </TableBody>
        </Table>
        {canAdd && active && (
            <List>
                <ListItem
                    button
                    id="add-backup-organisation"
                    onClick={() => onToggle("backup")}
                >
                    <ListItemText
                        className={styles.add}
                        color={"inherit"}
                        disableTypography
                        primary={translate("Add backup organisation")}
                    />
                </ListItem>
            </List>
        )}
    </Paper>
);

export default OrganisationsList;
