//@flow
import styles from "./Header.scss";

import React, { Component } from "react";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import VenueBox from "../../../../VenueBox/VenueBox";

import { type State as VenueState } from "../../../../../redux/modules/venues.d";

type Props = {
    venues: VenueState,
    collapsedVenues: string[],
    onHeadingClick: (venueCode: string) => void,
};

export default class Header extends Component<Props> {
    /**
     * Render
     */
    render() {
        const { venues, onHeadingClick, collapsedVenues } = this.props;

        return (
            <Grid
                container
                spacing={0}
                wrap={"nowrap"}
                alignItems="center"
                className={styles.container}
            >
                <Grid item xs={3} className={styles.spacing} />
                {(venues.data || []).map(venue => (
                    <Grid
                        item
                        xs
                        className={classnames(styles.test, {
                            collapsed: collapsedVenues.find(
                                venueItem => venueItem === venue.code,
                            ),
                        })}
                        key={venue.uuid}
                        onClick={() => onHeadingClick(venue.code)}
                    >
                        <VenueBox venue={venue} />
                        {!collapsedVenues.find(
                            venueItem => venueItem === venue.code,
                        ) && (
                            <Typography style={{ marginLeft: "0.25em" }}>
                                {venue.name}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        );
    }
}
