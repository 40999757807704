// @flow

import React from "react";
import { connect } from "react-redux";
import { withRouter, type Match } from "react-router";

import { load as remove } from "../../redux/modules/event-delete";
import { type State } from "../../redux/modules/shows.d";
import { reload } from "../../redux/modules/shows";
import { type State as DeleteState } from "../../redux/modules/event-delete.d";

import { withTranslate } from "../Translate";
import { bindActionCreators } from "redux";
import { withAcl } from "../Acl";
import { DeleteModal } from "../../components/Modals/DeleteModal/DeleteModal";

type Props = {
    translate: *,
    match: Match,
    eventDelete: DeleteState,
    events: State,
    reload: () => void,
    remove: () => Promise<void>,
    onBack: () => void,
};

const DeleteEvent = ({
    events,
    onBack,
    translate,
    remove,
    reload,
    match,
}: Props) => {
    return (
        <DeleteModal
            translate={translate}
            data={events.data?.content.find(
                evn => evn.eventUuid === match.params.id,
            )}
            name={
                events.data?.content.find(
                    evn => evn.eventUuid === match.params.id,
                ).eventName
            }
            loading={events.loading}
            error={events.error}
            onSubmit={() =>
                remove(match.params.id).then(() => {
                    onBack();
                    reload();
                })
            }
            onClose={onBack}
            title={translate("Cancel Event")}
            cancelText={translate("No")}
            confirmText={translate("Yes, cancel")}
        />
    );
};

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ shows, eventDelete }) => ({
                    events: shows.toJS(),
                    eventDelete,
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            reload,
                            remove,
                        },
                        dispatch,
                    ),
            )(DeleteEvent),
        ),
    ),
);
