// @flow
/**
 * Events module
 */
import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "events",
    () => `/ays-series-event-management/series/_search?page=0`,
    {
        method: "POST",
    },
);

export const load = (filter: string) => (dispatch: *) => {
    if (!filter.trim().length) {
        return Promise.resolve([]);
    }
    return dispatch(
        actions.load({
            data: { filter },
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not find any events!";
/** REDUCER **/
export default reducer;
