// @flow

import React from "react";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";

export default createSvgIcon(
    <g>
        <path d="M5.04564706,9.75152941 C5.04564706,7.15701961 7.15701961,5.04564706 9.75152941,5.04564706 C12.3460392,5.04564706 14.4574118,7.15701961 14.4574118,9.75152941 C14.4574118,12.3460392 12.3460392,14.4574118 9.75152941,14.4574118 C7.15701961,14.4574118 5.04564706,12.3460392 5.04564706,9.75152941 M19.5334902,18.793098 L14.1687843,13.4296471 C15.0318463,12.3984154 15.5043257,11.096265 15.5033725,9.75152941 C15.5033725,6.58101961 12.9232941,4 9.75152941,4 C6.57976471,4 4,6.58101961 4,9.75152941 C4,12.9223529 6.57976471,15.5033725 9.75152941,15.5033725 C11.1498039,15.5033725 12.432,15.0014118 13.4296471,14.1687843 L18.7943529,19.5334902 C18.9988909,19.7365544 19.3289522,19.7365544 19.5334902,19.5334902 C19.7374118,19.3286275 19.7374118,18.9982745 19.5334902,18.793098" />
    </g>,
    "Search",
);
