// @flow

/**
 * Module: Edit confirmed option
 */

import createApiModule from "../create-api-module";

import { type PathParams } from "./edit-confirmed-option.d";

const { reducer, actions } = createApiModule(
    "editConfirmedOption",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/options/${uuid}/confirmed`,
    { method: "PUT" },
);

export const load = (uuid: string, option: *) => (dispatch: *) => {
    const { name, promoterUuid } = option;
    return dispatch(
        actions.load({
            path: { uuid },
            data: { name, promoterUuid },
        }),
    );
};

export const ERROR = "Could not create edit confirmed option!";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
