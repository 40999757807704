// @flow

import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

import VenueCell from "./VenueCell";

type Props = {
    date: *,
    venues: *[],
};

/**
 * Get component specific styles
 */
const getStyles = venues =>
    StyleSheet.create({
        row: {
            margin: "auto",
            flexDirection: "row",
            borderBottom: 1,
            borderRight: 1,
            borderColor: "#d6d6d6",
        },
        dateColumn: {
            width: "10%",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 6,
            borderLeft: 1,
            borderColor: "#d6d6d6",
        },
        column: {
            width: `${90 / venues.length}%`,
            borderLeft: 1,
            borderColor: "#d6d6d6",
        },
        textDate: {
            textTransform: "uppercase",
            color: "#d6d6d6",
            textAlign: "left",
        },
    });

/**
 * Renders a date with the selected venues
 */
const DateRow = ({ date, venues }: Props) => {
    const styles = getStyles(venues);

    return (
        <View wrap={false} style={styles.row}>
            <View style={styles.dateColumn}>
                <Text style={styles.textDate}>{date.day.format("ddd")}</Text>
                <Text>{date.day.format("DD/MM/YYYY")}</Text>
            </View>
            {venues.map((venue, index) => (
                <View key={`${index}${venue.uuid}`} style={styles.column}>
                    <VenueCell
                        events={date.venues[venue.code]}
                        venueCode={venue.code}
                    />
                </View>
            ))}
        </View>
    );
};

export default DateRow;
