//@flow
import styles from "./EventList.scss";

import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import moment from "moment";

import VenueBox from "../../VenueBox/VenueBox";
import Tasks from "../../Tasks/Tasks";
import StaffingIndicator from "../../StaffingIndicator/StaffingIndicator";
import Table from "../../Table/Table";
import { type State as EventsState } from "../../../redux/modules/shows.d";

type Props = {
    acl: *,
    canRead: boolean,
    canDelete: boolean,
    canQR: boolean,
    translate: *,
    tab: number,
    onAddEvent: () => void,
    onTabChange: (t: number) => void,
    events: EventsState,
    onSelect: (id: string, zone?: string) => void,
    onPageChange: (n: number) => void,
    onDelete: (uuid: string) => void,
    onSortChange: (sortString: string[]) => void,
    filter: string,
};

export default class EventList extends Component<Props> {
    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }
    render() {
        const {
            canRead,
            canDelete,
            canQR,
            events,
            translate,
            onSortChange,
            onDelete,
            onSelect,
            onPageChange,
            onTabChange,
            tab,
            acl,
            filter,
        } = this.props;

        const config = {
            id: "show-list",
            uuidKey: "eventUuid",
            hasMenu: true,
            hasTabs: true,
            tabOptions: [
                {
                    name: translate("Upcoming shows"),
                },
                {
                    name: translate("Past shows"),
                },
            ],
            onClick: item => {
                onSelect(item.eventUuid);
            },
            configItems: [
                {
                    key: "eventName",
                    customRender: (item: Object) => {
                        return (
                            <span className={styles.showName}>
                                {item.eventName}
                            </span>
                        );
                    },
                    displayName: translate("Show"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "venueCode",
                    customRender: (item: Object) => {
                        return (
                            <VenueBox
                                venue={{
                                    uuid: item.venueUuid,
                                    code: item.venueCode,
                                    name: item.venueName,
                                }}
                                color={"#298fc2"}
                            />
                        );
                    },
                    displayName: translate("Venue"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "eventStart",
                    customRender: (item: Object) => {
                        return (
                            <span>
                                {moment(item.eventStart).format(
                                    "ddd D MMM YYYY",
                                )}
                            </span>
                        );
                    },
                    displayName: translate("Date"),
                    sortable: true,
                    isNumeric: false,
                    defaultSort: tab === 1 ? "desc" : "asc",
                    permission: true,
                },
                {
                    key: "eventStart_",
                    customRender: (item: Object) => {
                        return (
                            <span>
                                {moment(item.eventStart).format("HH:mm")}
                            </span>
                        );
                    },
                    displayName: translate("Start"),
                    sortable: false,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "teamMemberCount",
                    customRender: (item: Object) => {
                        return (
                            <StaffingIndicator
                                fullyStaffed={item.fullyStaffed}
                                currentOccupation={item.teamMemberCount}
                                minOccupation={item.minOccupation}
                                maxOccupation={item.maxOccupation}
                                translate={translate}
                            />
                        );
                    },
                    displayName: translate("Staffing"),
                    sortable: false,
                    isNumeric: false,
                    permission: acl("events.lines.read"),
                },
                {
                    key: "tasksCount",
                    customRender: (item: Object) => {
                        return <Tasks amount={item.tasksCount} />;
                    },
                    displayName: translate("Tasks"),
                    sortable: false,
                    isNumeric: false,
                    permission: acl("events.lines.read"),
                },
            ],
            menuOptions: [
                {
                    id: "delete",
                    label: () => translate("Cancel Event"),
                    onClick: item => onDelete(item.eventUuid),
                    permission: () => canDelete,
                },
                {
                    id: "qr",
                    label: () => "Print event QR-Code",
                    onClick: item => onSelect(item.eventUuid, "qr"),
                    permission: () => canQR,
                },
                {
                    id: "export",
                    label: () => translate("Export data"),
                    onClick: item => onSelect(item.eventUuid, "attendees"),
                    permission: () => canRead,
                },
            ],
        };

        return (
            <div className={styles.main}>
                <Table
                    key={tab}
                    config={config}
                    loading={events.loading}
                    data={events.data}
                    page={events.page}
                    error={events.error}
                    translate={translate}
                    tab={tab}
                    onTabChange={t => onTabChange(t)}
                    getSortString={string => onSortChange(string)}
                    onPageChange={p => onPageChange(p + 1)}
                    filter={filter}
                    sorting={events.sorting}
                />
                {events.loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}
