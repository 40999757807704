// @flow

export const unpack = (input: Object) =>
    Object.keys(input).reduce((accumulator, current) => {
        if (input[current] && typeof input[current].toJS === "function") {
            accumulator[current] = input[current].toJS();
        } else {
            accumulator[current] = input[current];
        }

        return accumulator;
    }, {});
