// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+RfKJHLwHz\\+\\+Exm7rmGywA\\=\\={width:100%}.\\+RfKJHLwHz\\+\\+Exm7rmGywA\\=\\=>:first-child{margin:2em 6em}\n", "",{"version":3,"sources":["webpack://./../style.module.scss"],"names":[],"mappings":"AAAA,+BACI,UAAW,CADf,4CAGQ,cAAe","sourcesContent":[".wrapper {\n    width: 100%;\n    > :first-child {\n        margin: 2em 6em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "+RfKJHLwHz++Exm7rmGywA=="
};
export default ___CSS_LOADER_EXPORT___;
