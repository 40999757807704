//@flow
import styles from "./UserList.scss";

import React, { Component } from "react";

import AddIcon from "@material-ui/icons/AddCircle";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "../../Table/Table";
import { type State as AdminState } from "../../../redux/modules/admin.d";
import FabContainer from "../../FabContainer/FabContainer";

type Props = {
    translate: *,
    onAdd: () => void,
    onEdit: (id: string) => void,
    onDelete: (id: string) => void,
    admin: AdminState,
    onSelect: (id: string) => void,
    onPageChange: (n: number) => void,
    onSortChange: (sortString: string[]) => void,
    filter: string,
};

export default class UserList extends Component<Props> {
    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }

    /**
     * Render
     */
    render() {
        const {
            translate,
            onPageChange,
            onSortChange,
            admin,
            onEdit,
            onDelete,
            onAdd,
            filter,
        } = this.props;

        const config = {
            id: "admin-user-list",
            uuidKey: "uuid",
            hasMenu: true,
            onClick: item => onEdit(item.uuid),
            configItems: [
                {
                    key: "lastName",
                    customRender: (item: Object) => {
                        return (
                            <span>
                                {item.firstName ? (
                                    <span className={styles.user}>
                                        <img
                                            alt={`${item.firstName} ${item.lastName}`}
                                            src={`${item.profilePicturePath}/64x64.png`}
                                            className={styles.avatar}
                                        />
                                        <span>{`${item.firstName} ${item.lastName}`}</span>
                                    </span>
                                ) : (
                                    <span>{translate("(Unknown)")}</span>
                                )}
                            </span>
                        );
                    },
                    displayName: translate("Name"),
                    sortable: true,
                    defaultSort: "asc",
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "emailAddress",
                    customRender: (item: Object) => {
                        return (
                            <span>
                                {item.emailAddress ? (
                                    <span>{`${item.emailAddress}`}</span>
                                ) : (
                                    <span>{translate("(Unknown)")}</span>
                                )}
                            </span>
                        );
                    },
                    displayName: translate("E-mail"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "phoneNumber",
                    customRender: (item: Object) => {
                        return (
                            <span>
                                {item.phoneNumber ? (
                                    <span>{`${item.phoneNumber}`}</span>
                                ) : (
                                    <span>{translate("(Unknown)")}</span>
                                )}
                            </span>
                        );
                    },
                    displayName: translate("Phone number"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "personalDataVerifiedByUser",
                    customRender: (item: Object) => {
                        return (
                            <span>
                                {item.personalDataVerifiedByUser ? (
                                    <span className={styles.status}>
                                        <span className={styles.verified} />
                                        {translate("Verified")}
                                    </span>
                                ) : (
                                    <span className={styles.status}>
                                        <span className={styles.pending} />
                                        {translate("Pending")}
                                    </span>
                                )}
                            </span>
                        );
                    },
                    displayName: translate("Status"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "noSmartphone",
                    customRender: (item: Object, index: number) => {
                        return (
                            <Checkbox
                                id={`admin-user-list-noSmartphone-${index}`}
                                checked={item.noSmartphone}
                                disabled={true}
                            />
                        );
                    },
                    displayName: translate("No Smartphone"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
            ],
            menuOptions: [
                {
                    id: "delete",
                    label: () => translate("Delete member"),
                    onClick: item => onDelete(item.uuid),
                    permission: () => true,
                },
            ],
        };

        return (
            <div className={styles.main}>
                <Table
                    config={config}
                    loading={admin.loading}
                    data={admin.data}
                    page={admin.page}
                    error={admin.error}
                    translate={translate}
                    getSortString={string => onSortChange(string)}
                    onPageChange={p => onPageChange(p + 1)}
                    filter={filter}
                />
                <FabContainer>
                    <Fab
                        id="admin-user-add"
                        variant="extended"
                        color="primary"
                        onClick={onAdd}
                    >
                        <AddIcon />
                        Add user
                    </Fab>
                </FabContainer>
                {admin.loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}
