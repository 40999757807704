// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "confirmOptions",
    () => "/ays-series-event-management/series/options",
    {
        method: "PUT",
    },
);

export const load =
    (options: { name: string, status: string, uuid: string }) =>
    (dispatch: *) =>
        dispatch(
            actions.load({
                data: {
                    options,
                },
            }),
        );
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not confirm options!";
export default reducer;
