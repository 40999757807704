// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z-3QOR\\+CPEmWRPsQ1Um49g\\=\\={background-color:#651f82;color:#fff}.fqFQEPU-Z0q2FKBfrjEHMw\\=\\={opacity:0.5}\n", "",{"version":3,"sources":["webpack://./../style.module.scss","webpack://./../../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,6BACI,wBCMY,CDLZ,UCAQ,CDCX,4BAGG,WAAY","sourcesContent":["@import \"../../../styles/variables\";\n\n.title {\n    background-color: $purple;\n    color: $white;\n}\n\n.subtitle {\n    opacity: 0.5;\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Z-3QOR+CPEmWRPsQ1Um49g==",
	"subtitle": "fqFQEPU-Z0q2FKBfrjEHMw=="
};
export default ___CSS_LOADER_EXPORT___;
