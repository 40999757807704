// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NJ9Ai3H4JyajVEn7xxRn8A\\=\\={box-sizing:border-box;background-color:#000;height:9.5em;position:relative}.NJ9Ai3H4JyajVEn7xxRn8A\\=\\= .vZPptS5yjYGD0xmcaWaNMQ\\=\\=,.NJ9Ai3H4JyajVEn7xxRn8A\\=\\= .O\\+SyZTWVH0Om9E8oYs2ffw\\=\\={color:#888;font-size:0.875rem;font-weight:700;text-transform:uppercase;position:absolute;left:0;padding:0 8em}.NJ9Ai3H4JyajVEn7xxRn8A\\=\\= .vZPptS5yjYGD0xmcaWaNMQ\\=\\={white-space:nowrap;top:5em}.NJ9Ai3H4JyajVEn7xxRn8A\\=\\= .vZPptS5yjYGD0xmcaWaNMQ\\=\\=>*{color:#fff}.NJ9Ai3H4JyajVEn7xxRn8A\\=\\= .O\\+SyZTWVH0Om9E8oYs2ffw\\=\\={top:4em}\n", "",{"version":3,"sources":["webpack://./../Header.scss","webpack://./../../../styles/_variables.scss"],"names":[],"mappings":"AAIA,4BACI,qBAAsB,CACtB,qBCDQ,CDER,YAAa,CACb,iBAAkB,CAJtB,iHAQQ,UAAW,CACX,kBAAmB,CACnB,eAAgB,CAChB,wBAAyB,CACzB,iBAAkB,CAClB,MAAO,CACP,aAAc,CAdtB,wDAsBQ,kBAAmB,CACnB,OAAQ,CAvBhB,0DAmBY,UCnBA,CDAZ,yDA2BQ,OAAQ","sourcesContent":["// Header styling\n\n@import \"../../styles/variables\";\n\n.wrapper {\n    box-sizing: border-box;\n    background-color: $black;\n    height: 9.5em;\n    position: relative;\n\n    .title,\n    .subtitle {\n        color: #888;\n        font-size: 0.875rem;\n        font-weight: 700;\n        text-transform: uppercase;\n        position: absolute;\n        left: 0;\n        padding: 0 8em;\n    }\n\n    .title {\n        & > * {\n            color: $white;\n        }\n\n        white-space: nowrap;\n        top: 5em;\n    }\n\n    .subtitle {\n        top: 4em;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NJ9Ai3H4JyajVEn7xxRn8A==",
	"title": "vZPptS5yjYGD0xmcaWaNMQ==",
	"subtitle": "O+SyZTWVH0Om9E8oYs2ffw=="
};
export default ___CSS_LOADER_EXPORT___;
