// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+3cSufUCuASvIe4H5--NuA\\=\\=.FjjHNnnzJ7mVTkVYbMeBRw\\=\\=,.\\+3cSufUCuASvIe4H5--NuA\\=\\=.J8PiGbBo7\\+6J\\+rd9BmJjGg\\=\\={font-size:1rem;width:1.5em;height:1.5em;margin-right:1em}\n", "",{"version":3,"sources":["webpack://./../ProfilePic.scss"],"names":[],"mappings":"AAEA,kHAEI,cAAe,CACf,WAAY,CACZ,YAAa,CACb,gBAAiB","sourcesContent":["// Styling\n\n.override.avatar,\n.override.person {\n    font-size: 1rem;\n    width: 1.5em;\n    height: 1.5em;\n    margin-right: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"override": "+3cSufUCuASvIe4H5--NuA==",
	"avatar": "FjjHNnnzJ7mVTkVYbMeBRw==",
	"person": "J8PiGbBo7+6J+rd9BmJjGg=="
};
export default ___CSS_LOADER_EXPORT___;
