// @flow

import styles from "./Group.scss";

import classnames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { type EventLine } from "../../redux/modules/event-lines.d";
import StaffingIndicator from "../StaffingIndicator/StaffingIndicator";
import Tasks from "../Tasks/Tasks";
import IconMenu from "../IconMenu";
import { type LineTypeBulkEditProps } from "./LineTypeBulkEdit/LineTypeBulkEdit";

type Props = {
    translate: *,
    id: string,
    index: number,
    group: {
        name: string,
        uuid: string,
        lines: EventLine[],
    },
    selected: ?string,
    onSelect: (eventLineUuid: string) => void,
    onLineTypeAction: LineTypeBulkEditProps => void | undefined,
};
class Group extends Component<Props> {
    myRef: { current: null | * };

    constructor(props: Props) {
        super(props);
        this.myRef = React.createRef(); // create a ref object
    }

    scrollToMyRef = () => {
        // run this method to execute scrolling.
        if (this.myRef) {
            if (this.myRef.current === null) {
                return;
            }

            let myElement = document.getElementById(this.myRef.current.id);
            myElement && myElement.scrollIntoView();
        }
    };

    componentDidMount() {
        this.scrollToMyRef();
    }

    render() {
        const {
            group,
            selected,
            onSelect,
            onLineTypeAction,
            id,
            index,
            translate,
        } = this.props;

        return (
            <List id={id} className={styles.list}>
                <ListItem id={`${id}-facility`}>
                    <ListItemText
                        primary={group.name}
                        className={styles.title}
                        disableTypography
                    />
                    {onLineTypeAction && (
                        <ListItemSecondaryAction>
                            <IconMenu
                                options={[
                                    {
                                        id: "linetype-update-hours",
                                        label: translate("Update hours"),
                                        onClick: () =>
                                            onLineTypeAction({
                                                lineType: {
                                                    name: group.name,
                                                    uuid: group.uuid,
                                                },
                                                action: "updateHours",
                                            }),
                                    },
                                    {
                                        id: "linetype-update-occupation",
                                        label: translate("Update occupation"),
                                        onClick: () =>
                                            onLineTypeAction({
                                                lineType: {
                                                    name: group.name,
                                                    uuid: group.uuid,
                                                },
                                                action: "updateOccupation",
                                            }),
                                    },
                                    {
                                        id: "linetype-update-briefings",
                                        label: translate("Update briefings"),
                                        onClick: () =>
                                            onLineTypeAction({
                                                lineType: {
                                                    name: group.name,
                                                    uuid: group.uuid,
                                                },
                                                action: "updateBriefings",
                                            }),
                                    },
                                ]}
                                id={group.name}
                            />
                        </ListItemSecondaryAction>
                    )}
                </ListItem>

                {group.lines
                    .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
                    .map((line, lineIndex) => (
                        <ListItem
                            id={`${id}-item-${index}-${lineIndex}`}
                            key={line.uuid}
                            className={classnames({
                                [styles.selected]: selected === line.uuid,
                                [styles.closed]:
                                    line.eventLineStatus === "CLOSED",
                            })}
                            ref={selected === line.uuid ? this.myRef : null}
                            onClick={() => onSelect(line.uuid)}
                            button
                        >
                            <Grid container spacing={3} className={styles.grid}>
                                <Grid item xs={4}>
                                    <ListItemText secondary={line.name} />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText
                                        secondary={moment(line.start).format(
                                            "HH:mm",
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {line.eventLineStatus === "OPEN" && (
                                        <StaffingIndicator
                                            translate={translate}
                                            currentOccupation={
                                                line.teamMemberCount
                                            }
                                            minOccupation={line.minOccupation}
                                            maxOccupation={line.maxOccupation}
                                        />
                                    )}
                                    {line.eventLineStatus === "CLOSED" && (
                                        <ListItemText
                                            secondary={translate("Closed")}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={1}>
                                    {line.tasksCount > 0 && (
                                        <Tasks amount={line.tasksCount} />
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {line.primaryOrganisation ? (
                                        <ListItemText
                                            secondary={line.primaryOrganisation}
                                        />
                                    ) : (
                                        <ListItemText
                                            secondary={translate("Unassigned")}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <ListItemSecondaryAction
                                className={classnames({
                                    [styles["selected-secondary"]]:
                                        selected === line.uuid,
                                })}
                            />
                        </ListItem>
                    ))}
            </List>
        );
    }
}

export default Group;
