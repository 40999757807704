// @flow

import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import { type State as PoolState } from "../../../../redux/modules/event-recruitment.d";

type Props = {
    id: string,
    state: PoolState,
    load: () => *,
    clear: () => *,
    onSelect: (uuid: string) => void,
    translate: *,
};

/**
 * Pool selector
 */
export default class Pools extends Component<Props> {
    /**
     * On Mount
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
        this.load();
    }

    /**
     * On Update
     */
    componentDidUpdate() {
        this.load();
    }

    /**
     * Load
     */
    load() {
        const { load } = this.props;
        load();
    }

    /**
     * Render
     */
    render() {
        const { state, id, onSelect, translate } = this.props;

        return (
            <div>
                <Typography>
                    {translate("First choose a recruitment group to continue")}
                </Typography>
                <List>
                    {state.data &&
                        state.data.map((pool, index) => (
                            <ListItem
                                key={pool.uuid}
                                id={`${id}-item-${index}`}
                                onClick={() => onSelect(pool.uuid)}
                                button
                            >
                                <ListItemText
                                    id={`${id}-item-inner-${index}`}
                                    primary={pool.name}
                                />
                            </ListItem>
                        ))}
                    {!state.data && state.loading && (
                        <ListItem>
                            <CircularProgress />
                        </ListItem>
                    )}
                </List>
            </div>
        );
    }
}
