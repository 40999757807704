import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#651F82",
        },
        secondary: {
            main: "#298fc2",
        },
    },
    overrides: {
        MuiButton: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                fontWeight: "700",
            },
            text: {
                color: "#888",
            },
            textSecondary: {
                color: "#FF0000",
            },
        },
        MuiInput: {
            underline: {
                "&$disabled": {
                    "&:before": {
                        borderBottomStyle: "solid",
                    },
                },
            },
        },
        MuiSelect: {
            select: {
                display: "flex",
                "align-items": "center",
            },
        },
        MuiFormLabel: {
            root: {
                marginBottom: "0.5em",
            },
        },
        MuiDialogContent: {
            root: {
                "overflow-y": "inherit",
                padding: "12px 24px",
            },
        },
        MuiFab: {
            root: {
                fontWeight: "700",
            },
        },
    },
    typography: {
        fontFamily: "Space Grotesk, Arial, sans-serif",
        h1: {
            color: "#888888",
            fontSize: "0.65rem",
            fontWeight: "700",
            lineHeight: "1.71429em",
            textTransform: "uppercase",
        },
        h2: {
            fontWeight: "700",
            textTransform: "uppercase",
            "font-size": "3.55rem",
            color: "#181C20",
        },
        h3: {
            fontWeight: "700",
            fontSize: "2.85rem",
            textTransform: "uppercase",
        },
        h4: {
            fontWeight: "700",
        },
        body1: {
            "font-size": "0.85em",
        },
        body2: {
            textTransform: "uppercase",
            color: "#888888",
        },
    },
});

export default theme;
