// @flow

import styles from "./Persons.scss";

import React, { Component, Fragment } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import PhoneIcon from "@material-ui/icons/Phone";

import PhoneNumberField from "../../PhoneNumberField";

type ItemProps = {
    id: string,
    placeholder: string,
    value: string,
    onChange: (value: string) => void,
    error: ?string,
    disabled?: ?boolean,
    index?: number,
};

type Props = {
    placeholder: string,
    value: string[],
    max: number,
    onChange: (value: string[]) => void,
    errors: (?string)[],
    disabled?: ?boolean,
};

/**
 * Item component
 */
const Item = ({
    id,
    placeholder,
    value,
    onChange,
    error,
    disabled,
    index,
}: ItemProps) => (
    <ListItem>
        <div className={styles.circle}>{index ? index + 1 : 1}</div>
        <PhoneNumberField
            id={`${id}-input`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            fullWidth
            error={!!error}
            helperText={error}
            disabled={disabled}
        />
    </ListItem>
);

/**
 * Persons component
 */
export default class Persons extends Component<Props> {
    /**
     * Change handler
     */
    handleChange(itemValue: string, index: number) {
        const { value, onChange } = this.props;
        onChange(value.map((item, i) => (index === i ? itemValue : item)));
    }

    /**
     * Render
     */
    render() {
        const { value, errors, disabled, placeholder } = this.props;

        return (
            <Fragment>
                <List className={styles.list}>
                    {value.map((number, index) => (
                        <Item
                            id={`add-organisation-leaders-${index}`}
                            key={index}
                            value={number}
                            index={index}
                            onChange={value => this.handleChange(value, index)}
                            error={errors[index]}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                    ))}
                </List>
            </Fragment>
        );
    }
}
