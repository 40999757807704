//@flow
import styles from "./Legend.scss";

import React, { Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import CalenderIcon from "../Icons";

/**
 * propsType
 */
type Props = {
    translate: *,
    variant: "row" | "modal" | "icons",
    icon?:
        | "OPTION_CREATED"
        | "OPTION_CONFIRMED"
        | "OPTION_CANCELED"
        | "LIMITED_AVAILABILITY"
        | "PRIVATE"
        | "PUBLIC"
        | "CANCELED",
    onClose: *,
};

const Legend = ({ variant, onClose, icon, translate }: Props) => (
    <Fragment>
        {variant === "row" && (
            <Paper className={styles.element} elevation={2}>
                <CalenderIcon status="OPTION_CREATED" />
                <Typography align="center">{translate("Option")}</Typography>
                <CalenderIcon status="OPTION_CONFIRMED" />
                <Typography align="center">
                    {translate("Confirmed Option")}
                </Typography>
                <CalenderIcon status="LIMITED_AVAILABILITY" />
                <Typography align="center">
                    {translate("Confirmed Option (Limited)")}
                </Typography>
                <CalenderIcon status="PUBLIC" />
                <Typography align="center">
                    {translate("Public event")}
                </Typography>
                <CalenderIcon status="PRIVATE" />
                <Typography align="center">
                    {translate("Private event")}
                </Typography>
            </Paper>
        )}

        {variant === "modal" && (
            <Dialog
                open
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h2">{translate("LEGEND")}</Typography>
                </DialogTitle>
                <DialogContent className={styles.modal}>
                    <div>
                        <CalenderIcon status="OPTION_CREATED" />
                        <Typography align="center">
                            {translate("Option")}
                        </Typography>
                    </div>
                    <div>
                        <CalenderIcon status="PUBLIC" />
                        <Typography align="center">
                            {translate("Public event")}
                        </Typography>
                    </div>
                    <div>
                        <CalenderIcon status="OPTION_CONFIRMED" />
                        <Typography align="center">
                            {translate("Confirmed Option")}
                        </Typography>
                    </div>
                    <div>
                        <CalenderIcon status="PRIVATE" />
                        <Typography align="center">
                            {translate("Private event")}
                        </Typography>
                    </div>
                    <div>
                        <CalenderIcon status="LIMITED_AVAILABILITY" />
                        <Typography align="center">
                            {translate("Confirmed Option (Limited)")}
                        </Typography>
                    </div>
                    <div className={styles.cancel}>
                        <CalenderIcon status="CANCELED" />
                        <CalenderIcon status="OPTION_CANCELED" />
                        <Typography align="center">
                            {translate("Canceled")}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{translate("Cancel")}</Button>
                    <Button onClick={onClose} color="primary" autoFocus>
                        {translate("OK")}
                    </Button>
                </DialogActions>
            </Dialog>
        )}
        {variant === "icons" && (
            <span>
                {icon === "OPTION_CREATED" && (
                    <CalenderIcon status="OPTION_CREATED" />
                )}

                {icon === "OPTION_CONFIRMED" && (
                    <CalenderIcon status="OPTION_CONFIRMED" />
                )}
                {icon === "LIMITED_AVAILABILITY" && (
                    <CalenderIcon status="LIMITED_AVAILABILITY" />
                )}
                {icon === "PUBLIC" && <CalenderIcon status="PUBLIC" />}
                {icon === "PRIVATE" && <CalenderIcon status="PRIVATE" />}
            </span>
        )}
    </Fragment>
);

export default Legend;
