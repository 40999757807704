// @flow

import styles from "./BriefingEditor.scss";

import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveForSeries from "../../SaveForSeriesDialog";

import { type State as SeriesEvent } from "../../../redux/modules/series-events.d";

// $FlowFixMe
import RichTextEditor, { createValueFromString } from "react-rte";
import ConfirmNotification from "../../Modals/ConfirmNotification/ConfirmNotification";

type Props = {
    translate: *,
    acl: *,
    title: string,
    briefing: ?string,
    prebriefing: ?string,
    canEdit?: boolean,
    onSubmit: (briefing: string, prebriefing: string) => void,
    updating: boolean,
    loadSeriesEvents: () => void,
    seriesEvents: SeriesEvent,
    clearSeriesEvents: () => void,
};

type StateType = {
    prebriefing: any,
    briefing: any,
    markdownBriefing: string,
    markdownPrebriefing: string,
    isEditing: boolean,
    saveForAllDialogOpen: boolean,
    saveDialogOpen: boolean,
    originalBriefing: string,
    originalPreBriefing: string,
    isBriefingChanged: boolean,
    isPreBriefingChanged: boolean,
};
export default class BriefingEditor extends Component<Props, StateType> {
    //Initial state
    state = {
        prebriefing: this.props.prebriefing
            ? createValueFromString(this.props.prebriefing, "markdown")
            : RichTextEditor.createEmptyValue(),
        briefing: this.props.briefing
            ? createValueFromString(this.props.briefing, "markdown")
            : RichTextEditor.createEmptyValue(),
        markdownBriefing: this.props.briefing || "",
        markdownPrebriefing: this.props.prebriefing || "",
        isEditing: false,
        saveForAllDialogOpen: false,
        saveDialogOpen: false,
        briefingNotification: false,
        preBriefingNotification: false,
        originalBriefing: this.props.briefing || "",
        originalPreBriefing: this.props.prebriefing || "",
        isBriefingChanged: false,
        isPreBriefingChanged: false,
    };

    static defaultProps = {
        canEdit: false,
    };

    reset() {
        const { briefing, prebriefing } = this.props;
        this.setState({
            briefing: briefing
                ? createValueFromString(briefing, "markdown")
                : RichTextEditor.createEmptyValue(),
            prebriefing: prebriefing
                ? createValueFromString(prebriefing, "markdown")
                : RichTextEditor.createEmptyValue(),
            markdownBriefing: briefing || "",
            markdownPrebriefing: prebriefing || "",
            isEditing: false,
        });
    }

    toggleEdit() {
        this.setState({
            isEditing: true,
        });
    }

    onChangeBriefing(briefing: any) {
        this.setState({
            briefing,
            markdownBriefing: briefing.toString("markdown"),
        });
    }

    onChangePrebriefing(prebriefing: any) {
        this.setState({
            prebriefing,
            markdownPrebriefing: prebriefing.toString("markdown"),
        });
    }

    checkHasText(briefing: any) {
        return briefing.getEditorState().getCurrentContent().hasText();
    }

    clearNotifications = () => {
        this.setState({
            preBriefingNotification: false,
            briefingNotification: false,
        });
    };

    handleSave(e, _preBriefingNotification, _briefingNotification) {
        this.setState(
            {
                preBriefingNotification: _preBriefingNotification,
                briefingNotification: _briefingNotification,
            },
            () => {
                this.handleSubmit(null);
            },
        );
    }

    handleSaveClick() {
        const {
            originalBriefing,
            originalPreBriefing,
            markdownBriefing,
            markdownPrebriefing,
        } = this.state;
        this.setState({
            isBriefingChanged: markdownBriefing !== originalBriefing,
            isPreBriefingChanged: markdownPrebriefing !== originalPreBriefing,
        });
        if (
            markdownBriefing !== originalBriefing ||
            markdownPrebriefing !== originalPreBriefing
        ) {
            this.setState({
                saveDialogOpen: true,
            });
        } else {
            this.handleSubmit(null);
        }
    }

    handleSubmit(selected: string[]) {
        const { onSubmit, clearSeriesEvents } = this.props;
        const { markdownBriefing, markdownPrebriefing, briefing, prebriefing } =
            this.state;
        this.setState({
            isEditing: false,
            originalBriefing: briefing,
            originalPreBriefing: prebriefing,
        });
        onSubmit(
            this.checkHasText(briefing) ? markdownBriefing : "",
            this.checkHasText(prebriefing) ? markdownPrebriefing : "",
            this.state.preBriefingNotification,
            this.state.briefingNotification,
            selected
                ? { applyForSeries: true, eventUuids: selected }
                : { applyForSeries: false },
        );
        this.setState({ saveForAllDialogOpen: false, saveDialogOpen: false });
        clearSeriesEvents();
        this.clearNotifications();
    }

    render() {
        const {
            translate,
            title,
            canEdit,
            updating,
            loadSeriesEvents,
            seriesEvents,
            clearSeriesEvents,
        } = this.props;
        const {
            briefing,
            prebriefing,
            isEditing,
            saveForAllDialogOpen,
            saveDialogOpen,
            isPreBriefingChanged,
            isBriefingChanged,
        } = this.state;
        return (
            <>
                {saveForAllDialogOpen && (
                    <SaveForSeries
                        translate={translate}
                        onClose={() => {
                            this.setState({ saveForAllDialogOpen: false });
                            clearSeriesEvents();
                        }}
                        loadSeriesEvents={loadSeriesEvents}
                        seriesEvents={seriesEvents}
                        onSubmit={(_, selected) => this.handleSubmit(selected)}
                        loading={false}
                    />
                )}
                {saveDialogOpen && (
                    <ConfirmNotification
                        translate={translate}
                        onClose={() => {
                            this.setState({ saveDialogOpen: false });
                            clearSeriesEvents();
                        }}
                        onSubmit={(
                            e,
                            preBriefingNotification,
                            briefingNotification,
                        ) =>
                            this.handleSave(
                                e,
                                preBriefingNotification,
                                briefingNotification,
                            )
                        }
                        isBriefingChanged={isBriefingChanged}
                        isPrebriefingChanged={isPreBriefingChanged}
                    />
                )}
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography
                            variant="h2"
                            color="inherit"
                            className={styles.title}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {canEdit && !isEditing && (
                            <div className={styles.actionButtons}>
                                <Button
                                    id="show-briefing-edit"
                                    onClick={this.toggleEdit.bind(this)}
                                    color="primary"
                                >
                                    {translate("Edit")}
                                </Button>
                            </div>
                        )}
                        {updating && (
                            <CircularProgress
                                className={styles.actionButtons}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.headerContainer}>
                            <div className={styles.labelContainer}>
                                <Typography variant="body1">
                                    {translate("Prebriefing")}
                                </Typography>

                                <Typography variant="h1">
                                    {translate(
                                        "This information will be visible in the job description",
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <RichTextEditor
                            id="editor-prebriefing"
                            editorClassName={styles.editor}
                            readOnly={!isEditing}
                            value={prebriefing}
                            onChange={this.onChangePrebriefing.bind(this)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.headerContainer}>
                            <div className={styles.labelContainer}>
                                <Typography variant="body1">
                                    {translate("Briefing")}
                                </Typography>
                                <Typography variant="h1">
                                    {translate(
                                        "This is additional information which will be visible after they’ve checked in on location",
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <RichTextEditor
                            id="editor-briefing"
                            editorClassName={styles.editor}
                            readOnly={!isEditing}
                            value={briefing}
                            onChange={this.onChangeBriefing.bind(this)}
                        />
                    </Grid>
                    {canEdit && isEditing && (
                        <Grid item xs={12}>
                            <div className={styles.actionButtons}>
                                <Button
                                    id="show-briefing-cancel"
                                    onClick={this.reset.bind(this)}
                                >
                                    {translate("Cancel")}
                                </Button>
                                <Button
                                    id="show-briefing-confirm-all-series"
                                    onClick={() =>
                                        this.setState({
                                            saveForAllDialogOpen: true,
                                        })
                                    }
                                    color="primary"
                                >
                                    {translate("Save for all series")}
                                </Button>
                                <Button
                                    id="show-briefing-confirm"
                                    onClick={() => {
                                        this.handleSaveClick();
                                    }}
                                    color="primary"
                                >
                                    {translate("Save changes")}
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    }
}
