//@flow

import React from "react";
import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    Typography,
    DialogActions,
    Button,
    FormHelperText,
} from "@material-ui/core";

import style from "./Dialog.module.scss";

type Props = {
    id: string,
    translate: *,
    title: string,
    description?: string,
    children: *,
    onClose: () => void,
    onSubmit?: () => void,
    onBack?: () => void,
    busy?: boolean,
    submitLabel: string,
    submitDisabled?: boolean,
    headerExtra?: Node,
    secondaryActions?: Node,
    error?: boolean,
    maxWidth?: string,
};

const Dialog = ({
    id,
    translate,
    title,
    description,
    children,
    onClose,
    onSubmit,
    onBack,
    busy,
    submitLabel,
    submitDisabled,
    headerExtra,
    secondaryActions,
    error,
    maxWidth = "sm",
}: Props) => (
    <MuiDialog onClose={() => !busy && onClose()} maxWidth={maxWidth} open>
        <DialogTitle disableTypography>
            <Typography variant="h2" color="inherit">
                {title}
            </Typography>
            {description && (
                <Typography color="inherit">{description}</Typography>
            )}
            {headerExtra && (
                <div className={style.headerExtra}>{headerExtra}</div>
            )}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            {error && <FormHelperText error>{error}</FormHelperText>}
            {onBack && (
                <Button
                    id={`${id}-back`}
                    onClick={onBack}
                    className={style.backBtn}
                    disabled={busy}
                >
                    {translate("Back")}
                </Button>
            )}
            <Button id={`${id}-cancel`} onClick={onClose} disabled={busy}>
                {translate("Cancel")}
            </Button>
            {secondaryActions}
            {onSubmit && (
                <Button
                    id={`${id}-submit`}
                    color="primary"
                    disabled={busy || submitDisabled}
                    onClick={onSubmit}
                >
                    {translate(submitLabel)}
                </Button>
            )}
        </DialogActions>
    </MuiDialog>
);

export default Dialog;
