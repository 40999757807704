// @flow

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    load,
    clear,
    refetch,
    ERROR,
} from "../../redux/modules/staffing-templates";
import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";

import {
    load as loadLineTypes,
    ERROR as LINE_TYPES_ERROR,
} from "../../redux/modules/facilities-linetypes";

import {
    load as add,
    ERROR as ADD_ERROR,
} from "../../redux/modules/add-staffing-template";

import {
    load as deleteTemplate,
    ERROR as DELETE_ERROR,
} from "../../redux/modules/staffing-template-delete";

import { clear as clearTemplateDetails } from "../../redux/modules/staffing-template-details";

import { withAcl, type Acl } from "../Acl";
import { withTranslate } from "../Translate";
import { FilterHeader } from "../../components/Authenticated";
import Add from "../../components/StaffingTemplates/Add";
import DeleteStaffingTemplate from "../../components/StaffingTemplates/Delete";
import _StaffingTemplatesList from "../../components/StaffingTemplates/List";

import stripslash from "../../utils/stripslash";

import {
    Redirect,
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";
import { mapState } from "../../redux/utils";

type Props = {
    translate: *,
    history: RouterHistory,
    match: Match,
    onClick: (uuid: string) => void,
    load: (page: number, filter: string, force: boolean) => void,
    staffingTemplates: *,
    remove: (uuid: string) => void,
    refetch: () => void,
    loadVenues: () => void,
    loadLineTypes: () => void,
    add: (name: string, venue: string, lineType: string) => void,
    user: *,
    venues: *,
    lineTypes: *,
    addStaffingTemplateState: *,
    onPageChange: (page: number) => void,
    staffingTemplateDelete: *,
    acl: Acl,
    clearTemplateDetails: () => void,
};

const StaffingTemplatesList = ({
    translate,
    match,
    history,
    onClick,
    load,
    loadVenues,
    loadLineTypes,
    staffingTemplates,
    remove,
    refetch,
    user,
    venues,
    lineTypes,
    addStaffingTemplateState,
    add,
    onPageChange,
    staffingTemplateDelete,
    acl,
    clearTemplateDetails,
}: Props) => {
    const [filter, setFilter] = useState("");
    const [currentItem, setCurrentItem] = useState({});

    const onClose = () => history.push(`${stripslash(match.url)}/`);

    useEffect(() => {
        load(parseInt(match.params.page), filter);
    }, [filter, onPageChange]);

    useEffect(() => {
        loadVenues();
        loadLineTypes();
        clearTemplateDetails();
    }, []);

    return (
        <>
            <FilterHeader
                onChange={filter => setFilter(filter)}
                title={translate("Staffing Templates")}
                value={filter}
                placeholder={translate("Filter by name, facility or venue")}
            />

            <_StaffingTemplatesList
                acl={acl}
                translate={translate}
                filter={filter}
                onClick={onClick}
                onAdd={() => history.push(`${stripslash(match.url)}/add/`)}
                onPageChange={onPageChange}
                onDelete={item => {
                    history.push(
                        `${stripslash(match.url)}/${item.uuid}/delete`,
                    );
                    setCurrentItem(item);
                }}
                staffingTemplates={staffingTemplates}
            />
            <Switch>
                <Route path={`${match.path}add/`}>
                    {(acl("staffingTemplates.create") && (
                        <Add
                            onClose={onClose}
                            onSubmit={(name, venue, lineType) =>
                                add(name, venue, lineType).then(res => {
                                    refetch();
                                    onClose();
                                    onClick(res.payload.uuid);
                                })
                            }
                            addState={addStaffingTemplateState}
                            translate={translate}
                            user={user}
                            venues={venues.data}
                            lineTypes={lineTypes.data}
                        />
                    )) || <Redirect to={`${stripslash(match.url)}/`} />}
                </Route>
                <Route path={`${match.path}:id/delete/`}>
                    {(acl("staffingTemplates.delete") && (
                        <DeleteStaffingTemplate
                            translate={translate}
                            match={match}
                            onClose={onClose}
                            name={currentItem.name}
                            onDelete={() =>
                                remove(currentItem.uuid).then(() => {
                                    refetch();
                                    onClose();
                                })
                            }
                            deleteStaffingTemplateState={staffingTemplateDelete}
                        />
                    )) || <Redirect to={`${stripslash(match.url)}/`} />}
                </Route>
            </Switch>
        </>
    );
};

export default withAcl(
    withTranslate(
        withRouter(
            connect(
                ({
                    staffingTemplates,
                    user,
                    venues,
                    facilitiesLineTypes,
                    addStaffingTemplate,
                    staffingTemplateDelete,
                }) => ({
                    staffingTemplates: {
                        ...mapState(staffingTemplates, ERROR),
                        page: staffingTemplates?.params?.path?.page,
                    },
                    venues: mapState(venues, VENUES_ERROR),
                    lineTypes: mapState(facilitiesLineTypes, LINE_TYPES_ERROR),
                    user:
                        user &&
                        user.get("data") &&
                        user.getIn(["data", "profile"]).toJS(),
                    addStaffingTemplateState: mapState(
                        addStaffingTemplate,
                        ADD_ERROR,
                    ),
                    staffingTemplateDelete: mapState(
                        staffingTemplateDelete,
                        DELETE_ERROR,
                    ),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            add,
                            load,
                            loadVenues,
                            loadLineTypes,
                            clear,
                            refetch,
                            remove: uuid => deleteTemplate(uuid),
                            clearTemplateDetails,
                        },
                        dispatch,
                    ),
            )(StaffingTemplatesList),
        ),
    ),
);
