// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KZahUvcme5mDDUTDZL62AA\\=\\= .zB0mUeD5znnRX1kOg1zPvQ\\=\\={color:white;margin-bottom:1em;background-color:#298fc2}.KZahUvcme5mDDUTDZL62AA\\=\\= .Hbr3lkHVS8yMF\\+5B\\+PdUQg\\=\\={display:flex;margin-top:3em;padding-bottom:1em;border-bottom:0.01em solid #888}.KZahUvcme5mDDUTDZL62AA\\=\\= .Hbr3lkHVS8yMF\\+5B\\+PdUQg\\=\\= label{font-size:0.9em}.KZahUvcme5mDDUTDZL62AA\\=\\= .bs0ByoD29hWmOZYj0C9t\\+Q\\=\\={margin:auto;margin-top:2em}\n", "",{"version":3,"sources":["webpack://./../AddOption.scss","webpack://./../../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,wDAEQ,WAAY,CACZ,iBAAkB,CAClB,wBCKW,CDTnB,0DAOQ,YAAa,CACb,cAAe,CACf,kBAAmB,CACnB,+BCJW,CDNnB,gEAYY,eAAgB,CAZ5B,yDAgBQ,WAAY,CACZ,cAAe","sourcesContent":["@import \"../../../styles//variables\";\n\n.element {\n    .title {\n        color: white;\n        margin-bottom: 1em;\n        background-color: $dark-blue;\n    }\n    .labels {\n        display: flex;\n        margin-top: 3em;\n        padding-bottom: 1em;\n        border-bottom: 0.01em solid $neutral-grey;\n        label {\n            font-size: 0.9em;\n        }\n    }\n    .spinner {\n        margin: auto;\n        margin-top: 2em;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "KZahUvcme5mDDUTDZL62AA==",
	"title": "zB0mUeD5znnRX1kOg1zPvQ==",
	"labels": "Hbr3lkHVS8yMF+5B+PdUQg==",
	"spinner": "bs0ByoD29hWmOZYj0C9t+Q=="
};
export default ___CSS_LOADER_EXPORT___;
