// @flow

import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

type FoundMember = {
    membershipUuid: string,
    phoneNumber: string,
};

type PhoneNumbersType = {
    phoneNumbersFound: FoundMember[],
    phoneNumbersNotAccepted: string[],
    phoneNumbersNotFound: string[],
    phoneNumbersNotValid: string[],
};

type PropsType = {
    id: string,
    phoneNumbers: PhoneNumbersType,
    /** A translator function */
    translate: *,
};

const PhoneNumberList = ({ id, phoneNumbers, translate }: PropsType) => {
    const {
        phoneNumbersFound,
        phoneNumbersNotAccepted,
        phoneNumbersNotFound,
        phoneNumbersNotValid,
    } = phoneNumbers;

    return (
        <Table id={id}>
            <TableHead>
                <TableRow>
                    <TableCell>{translate("Found")}</TableCell>
                    <TableCell>
                        {translate("Membership not yet accepted")}
                    </TableCell>
                    <TableCell>
                        {translate("Not part of your organisation")}
                    </TableCell>
                    <TableCell>{translate("Non valid phonenumbers")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        {phoneNumbersFound.map((member, index) => (
                            <p
                                id={`${id}-found-${index}`}
                                key={member.phoneNumber}
                            >
                                {member.phoneNumber}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell>
                        {phoneNumbersNotAccepted.map((number, index) => (
                            <p id={`${id}-notaccepted-${index}`} key={number}>
                                {number}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell>
                        {phoneNumbersNotFound.map((number, index) => (
                            <p id={`${id}-notfound-${index}`} key={number}>
                                {number}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell>
                        {phoneNumbersNotValid.map((number, index) => (
                            <p id={`${id}-notvalid-${index}`} key={number}>
                                {number}
                            </p>
                        ))}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default PhoneNumberList;
