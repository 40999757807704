// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-details.d";

const { reducer, actions } = createApiModule(
    "eventDetails",
    ({ uuid }: PathParams) => `/ays-series-event-management/events/${uuid}`,
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
