// @flow

import styles from "./style.module.scss";

import React, { useEffect, useState } from "react";
import { withAcl } from "../../Acl";
import {
    withRouter,
    type Match,
    type RouterHistory,
    Switch,
    Route,
} from "react-router";
import {
    load,
    refetch,
    ERROR,
} from "../../../redux/modules/staffing-template-details";
import {
    load as updateName,
    ERROR as UPDATE_NAME_ERROR,
} from "../../../redux/modules/update-staffing-template-name";
import { type State as UpdateNameState } from "../../../redux/modules/update-staffing-template-name.d";
import { type State as DetailsState } from "../../../redux/modules/staffing-template-details.d";
import {
    load as addLines,
    clear as clearLines,
    ERROR as ADD_LINES_ERROR,
} from "../../../redux/modules/add-staffing-template-lines";
import {
    load as loadOrganisations,
    ERROR as ORGANISATIONS_ERROR,
} from "../../../redux/modules/organisations";

import {
    load as updateLine,
    clear as clearLine,
    ERROR as UPDATE_LINE_ERROR,
} from "../../../redux/modules/update-line-staffing-template";
import {
    load as deleteLine,
    ERROR as DELETE_LINE_ERROR,
} from "../../../redux/modules/staffing-template-line-delete";
import { type State as DeleteLineState } from "../../../redux/modules/staffing-template-line-delete.d";

import { connect } from "react-redux";
import { mapState } from "../../../redux/utils";

import Grid from "@material-ui/core/Grid";
import Header from "../../../components/Authenticated/Header";
import { withTranslate } from "../../Translate";
import { bindActionCreators } from "redux";
import Lines from "../../../components/StaffingTemplates/Lines";
import stripslash from "../../../utils/stripslash";
import LineSelector from "../../../components/StaffingTemplates/Lines/LineSelector";
import LineDetails from "../../../components/StaffingTemplates/Lines/LineDetails";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    user: *,
    load: () => void,
    staffingTemplateDetails: DetailsState,
    history: RouterHistory,
    clearLines: () => void,
    addLines: (uuid: string, lineUuids: string[]) => void,
    refetch: () => void,
    addStaffingTemplateLines: *,
    organisations: *,
    loadOrganisations: (filter: string) => void,
    updateLine: (uuid: string, obj: *) => void,
    lineUpdateState: *,
    updateNameState: UpdateNameState,
    updateName: (uuid: string, name: string) => void,
    deleteLineState: DeleteLineState,
    deleteLine: (uuid: string) => void,
    clearLine: () => void,
};

const Details = ({
    acl,
    translate,
    load,
    staffingTemplateDetails: { data, loading },
    match,
    history,
    clearLines,
    addLines,
    refetch,
    addStaffingTemplateLines,
    loadOrganisations,
    organisations,
    updateLine,
    user,
    updateName,
    updateNameState,
    deleteLine,
    deleteLineState,
    lineUpdateState,
    clearLine,
}: Props) => {
    const [selected, setSelected] = useState(null);
    const getTitles = type => {
        if (type === "title") return data?.name || "...";
        if (type === "subtitle")
            return `${data?.venue.name} | ${data?.lineType.name}`;
    };
    useEffect(() => {
        load(match.params.id);
        loadOrganisations("");
    }, []);

    useEffect(() => {
        if (data?.staffingTemplateLines.length > 0 && !selected)
            setSelected(data.staffingTemplateLines[0]);
        if (data?.staffingTemplateLines.length > 0 && selected) {
            setSelected(
                data.staffingTemplateLines.find(
                    line =>
                        line.staffingTemplateLineUuid ===
                        selected.staffingTemplateLineUuid,
                ) || data.staffingTemplateLines[0],
            );
        }

        if (data?.staffingTemplateLines.length === 0) setSelected(null);

        return;
    }, [data, match.params.id]);

    const onClose = () => history.push(`${stripslash(match.url)}/`);
    return (
        <>
            <div className={styles.headerWrapper}>
                <Header
                    title={getTitles("title")}
                    subtitle={getTitles("subtitle")}
                />
            </div>
            <Grid container>
                <Grid item sm={4}>
                    <Lines
                        data={data}
                        translate={translate}
                        loading={loading}
                        onAdd={() =>
                            history.push(`${stripslash(match.url)}/add/`)
                        }
                        onClick={setSelected}
                        selected={selected}
                        updateName={updateName}
                        updateNameState={updateNameState}
                        refetchTemplate={refetch}
                    />
                </Grid>
                <Grid item sm={8}>
                    {selected && (
                        <LineDetails
                            key={selected.staffingTemplateLineUuid}
                            acl={acl}
                            translate={translate}
                            user={user}
                            line={selected}
                            organisations={organisations}
                            loadOrganisations={loadOrganisations}
                            updateLine={updateLine}
                            updateLoading={lineUpdateState.loading}
                            updateFieldErrors={lineUpdateState?.fieldErrors}
                            deleteLine={deleteLine}
                            deleteLoading={deleteLineState.loading}
                            refetchTemplate={refetch}
                            clearLine={clearLine}
                            error={lineUpdateState.error}
                        />
                    )}
                </Grid>
            </Grid>
            <Switch>
                <Route path={`${match.path}:id/add/`}>
                    <LineSelector
                        translate={translate}
                        onClose={onClose}
                        onSubmit={lineUuids => {
                            addLines(match.params.id, lineUuids).then(() => {
                                clearLines();
                                refetch();
                                onClose();
                            });
                        }}
                        lines={data?.lines}
                        addState={addStaffingTemplateLines}
                    />
                </Route>
            </Switch>
        </>
    );
};

export default withAcl(
    withTranslate(
        withRouter(
            connect(
                ({
                    staffingTemplateDetails,
                    addStaffingTemplateLines,
                    organisations,
                    user,
                    updateLineStaffingTemplate,
                    updateStaffingTemplateName,
                    staffingTemplateLineDelete,
                }) => ({
                    user: user.toJS(),
                    staffingTemplateDetails: mapState(
                        staffingTemplateDetails,
                        ERROR,
                    ),
                    addStaffingTemplateLines: mapState(
                        addStaffingTemplateLines,
                        ADD_LINES_ERROR,
                    ),
                    organisations: mapState(organisations, ORGANISATIONS_ERROR),
                    lineUpdateState: mapState(
                        updateLineStaffingTemplate,
                        UPDATE_LINE_ERROR,
                    ),
                    updateNameState: mapState(
                        updateStaffingTemplateName,
                        UPDATE_NAME_ERROR,
                    ),
                    deleteLineState: mapState(
                        staffingTemplateLineDelete,
                        DELETE_LINE_ERROR,
                    ),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load,
                            addLines,
                            clearLines,
                            refetch,
                            updateLine,
                            clearLine,
                            updateName,
                            deleteLine,
                            loadOrganisations: filter =>
                                loadOrganisations(1, filter, []),
                        },
                        dispatch,
                    ),
            )(Details),
        ),
    ),
);
