// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

import { type EventLineOrganisation } from "../../../redux/modules/event-line-organisations.d";

export const Client = {
    /**
     * Fetch all showlineOrganisations
     */
    getShowLineOrganisations(uuid: string): Promise<EventLineOrganisation[]> {
        if (!uuid.trim().length) {
            return Promise.resolve([]);
        }

        return this.instance
            .get(
                `/ays-series-event-management/event-lines/${uuid}/organisations`,
            )
            .then(
                ({ data: { eventLineOrganisations } }) =>
                    eventLineOrganisations,
            )
            .catch(normalizeError);
    },

    /**
     * Delete showlineOrganisation
     */
    deleteShowLineOrganisation(showlineuuid: string, organisationuuid: string) {
        return this.instance
            .delete(
                `/ays-series-event-management/event-lines/${showlineuuid}/organisations/${organisationuuid}
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * Activate showlineOrganisation
     */
    activateShowLineOrganisation(organisationuuid: string) {
        return this.instance
            .post(
                `/ays-series-event-management/event-line-organisations/${organisationuuid}/make-active
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * Deactivate showlineOrganisation
     */
    deactivateShowLineOrganisation(organisationuuid: string) {
        return this.instance
            .post(
                `/ays-series-event-management/event-line-organisations/${organisationuuid}/make-inactive
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * Add showlineOrganisation
     */
    addShowLineOrganisation(
        showlineuuid: string,
        organisationuuid: string,
        type: string,
    ) {
        return this.instance
            .post(
                `/ays-series-event-management/event-lines/${showlineuuid}/organisations/${type}
`,
                {
                    organisationUuid: organisationuuid,
                },
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
