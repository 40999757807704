// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JQnDoXDPLhUMrbz1RfWQlg\\=\\={margin:2em}.JQnDoXDPLhUMrbz1RfWQlg\\=\\=>div>table thead th{padding-left:2em;padding-right:1em;text-transform:none;font-size:0.8125rem;font-family:inherit}.JQnDoXDPLhUMrbz1RfWQlg\\=\\=>div>table tbody td{padding:0 1em 0 2em;text-transform:none;font-size:0.8125rem;font-family:inherit}.JQnDoXDPLhUMrbz1RfWQlg\\=\\=>div>table tfoot td{font-family:inherit}.JQnDoXDPLhUMrbz1RfWQlg\\=\\=>div>table.A2BauQX1U2abiOthSK8jwQ\\=\\= tbody td{padding:1em 1em}.Wo1\\+U32FkuvX-YBQYlwJqg\\=\\={margin:1em 0}.Lx4nmH-EXCeELEFSlW7MxA\\=\\={margin:1em;text-align:center}\n", "",{"version":3,"sources":["webpack://./../PoolRecruits.scss"],"names":[],"mappings":"AAEA,4BACI,UAAW,CADf,+CAIY,gBAAiB,CACjB,iBAAkB,CAClB,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CARhC,+CAWY,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CAdhC,+CAiBY,mBAAoB,CAjBhC,0EAuBY,eAAgB,CACnB,6BAKL,YAAa,CAChB,4BAGG,UAAW,CACX,iBAAkB","sourcesContent":["// Pool Recruits styling\n\n.wrapper {\n    margin: 2em;\n    & > div > table {\n        thead th {\n            padding-left: 2em;\n            padding-right: 1em;\n            text-transform: none;\n            font-size: 0.8125rem;\n            font-family: inherit;\n        }\n        tbody td {\n            padding: 0 1em 0 2em;\n            text-transform: none;\n            font-size: 0.8125rem;\n            font-family: inherit;\n        }\n        tfoot td {\n            font-family: inherit;\n        }\n    }\n\n    & > div > table.extraPadding {\n        tbody td {\n            padding: 1em 1em;\n        }\n    }\n}\n\n.filter {\n    margin: 1em 0;\n}\n\n.spinner {\n    margin: 1em;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "JQnDoXDPLhUMrbz1RfWQlg==",
	"extraPadding": "A2BauQX1U2abiOthSK8jwQ==",
	"filter": "Wo1+U32FkuvX-YBQYlwJqg==",
	"spinner": "Lx4nmH-EXCeELEFSlW7MxA=="
};
export default ___CSS_LOADER_EXPORT___;
