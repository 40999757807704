// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TJgTeP4EUVzk1UdadAJgNg\\=\\={width:2em;height:2em;border-radius:50%;margin-right:0.5em}.rulsWzUsyuNIDQRkLrm0Zg\\=\\={display:flex;align-items:center}\n", "",{"version":3,"sources":["webpack://./../PoolRecruit.scss"],"names":[],"mappings":"AAAA,4BACI,SAAU,CACV,UAAW,CACX,iBAAkB,CAClB,kBAAmB,CACtB,4BAEG,YAAa,CACb,kBAAmB","sourcesContent":[".avatar {\n    width: 2em;\n    height: 2em;\n    border-radius: 50%;\n    margin-right: 0.5em;\n}\n.user {\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "TJgTeP4EUVzk1UdadAJgNg==",
	"user": "rulsWzUsyuNIDQRkLrm0Zg=="
};
export default ___CSS_LOADER_EXPORT___;
