//@flow
import styles from "./EventLineDetails.scss";
import React, { Component, Fragment } from "react";
import isEqual from "lodash.isequal";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import {
    type State as DetailsState,
    type EventLineDetails,
} from "../../../redux/modules/event-line-details.d";

import { DateTimeField } from "../../DatePickers";
import SaveForSeries from "../../SaveForSeriesDialog";

import { type EventLine } from "../../../redux/modules/event-lines.d";
import { type State as SeriesEvent } from "../../../redux/modules/series-events.d";
import { Box } from "@material-ui/core";
import ConfirmNotification from "../../Modals/ConfirmNotification/ConfirmNotification";

type Props = {
    active: boolean,
    disabled: boolean,
    translate: *,
    acl: *,
    details: DetailsState,
    eventLine: EventLine,
    update: (uuid: string, eventline: EventLineDetails) => void,
    canEditShowLine: boolean,
    loadSeriesEvents: () => void,
    seriesEvents: SeriesEvent,
    clearSeriesEvents: () => void,
};

type State = {
    maxOccupation: number,
    minOccupation: number,
    briefing: string,
    preBriefing: string,
    end: string,
    start: string,
    saveForAllDialogOpen: boolean,
    saveDialogOpen: boolean,
    originalBriefing: string,
    originalPreBriefing: string,
    isBriefingChanged: boolean,
    isPreBriefingChanged: boolean,
};

/**
 * Loader component
 */
const Loading = () => (
    <div className={styles.spinner}>
        <CircularProgress />
    </div>
);

/**
 * Form component
 */
class Form extends Component<Props, State> {
    // Initial state
    state = {
        briefing: this.props.eventLine.briefing || "",
        preBriefing: this.props.eventLine.preBriefing || "",
        start: ((this.props.eventLine: any): EventLine).start,
        end: ((this.props.eventLine: any): EventLine).end,
        minOccupation: ((this.props.eventLine: any): EventLine).minOccupation,
        maxOccupation: ((this.props.eventLine: any): EventLine).maxOccupation,
        saveForAllDialogOpen: false,
        saveDialogOpen: false,
        preBriefingNotification: false,
        briefingNotification: false,
        originalBriefing: this.props.eventLine.briefing || "",
        originalPreBriefing: this.props.eventLine.preBriefing || "",
        isBriefingChanged: false,
        isPreBriefingChanged: false,
    };

    componentDidUpdate(prevProps: any) {
        const { eventLine } = this.props;

        if (!isEqual(prevProps.eventLine, eventLine)) {
            this.reset();
        }
    }

    /**
     * Get the disabled state of this form
     */
    get disabled(): boolean {
        const { details, disabled, active, acl } = this.props;

        if (details) {
            if (acl("events.lines.updateoverride")) {
                return false;
            }

            return (
                disabled ||
                !acl("events.lines.update") ||
                details.loading ||
                !active
            );
        }
        return false;
    }

    /**
     * Reset the form into the original state
     */
    reset() {
        const { eventLine } = this.props;
        this.setState({
            briefing: eventLine.briefing || "",
            preBriefing: eventLine.preBriefing || "",
            start: eventLine.start,
            end: eventLine.end,
            minOccupation: eventLine.minOccupation,
            maxOccupation: eventLine.maxOccupation,
        });
        this.clearNotifications();
    }

    /**
     * Retrieve the field error if present
     */
    getFieldError(field: string): ?string {
        const {
            details: { fieldErrors },
        } = this.props;
        return fieldErrors && fieldErrors[field];
    }

    /**
     * Get the change handler
     */
    getChangeHandler(
        field: string,
        postProcess?: (value: string) => string | number,
    ) {
        return ({ target: { value } }: *) =>
            this.setState({
                [field]: postProcess ? postProcess(value) : value,
            });
    }

    closeSaveForAllDialog() {
        this.setState({ saveForAllDialogOpen: false });
        this.props.clearSeriesEvents();
    }

    closeSaveDialog() {
        this.setState({ saveDialogOpen: false });
        this.props.clearSeriesEvents();
    }

    clearNotifications = () => {
        this.setState({
            preBriefingNotification: false,
            briefingNotification: false,
        });
    };

    handleSave(e, _preBriefingNotification, _briefingNotification) {
        this.setState(
            {
                preBriefingNotification: _preBriefingNotification,
                briefingNotification: _briefingNotification,
            },
            () => {
                this.handleSubmit(e, false, []);
            },
        );
    }

    handleSaveClick(e) {
        const { briefing, originalBriefing, preBriefing, originalPreBriefing } =
            this.state;
        this.setState({
            isBriefingChanged: briefing !== originalBriefing,
            isPreBriefingChanged: preBriefing !== originalPreBriefing,
        });
        if (
            briefing !== originalBriefing ||
            preBriefing !== originalPreBriefing
        ) {
            this.setState({
                saveDialogOpen: true,
            });
        } else {
            this.handleSubmit(e, false, []);
        }
    }

    /**
     * Submit handler
     */
    handleSubmit(
        event: *,
        applyForSeries: boolean = false,
        eventUuids: string[],
    ) {
        event.preventDefault();

        const {
            minOccupation,
            maxOccupation,
            preBriefing,
            briefing,
            briefingNotification,
            preBriefingNotification,
            start,
            end,
        } = this.state;

        const { update, eventLine, acl } = this.props;

        this.setState({
            originalBriefing: briefing,
            originalPreBriefing: preBriefing,
        });

        const parsedMax = parseInt(maxOccupation);
        const parsedMin = parseInt(minOccupation);

        const paramsCompact = {
            preBriefing,
            briefing,
            briefingNotification,
            preBriefingNotification,
            start: start || eventLine.start,
            end: end || eventLine.end,
            maxOccupation: isNaN(parsedMax) ? parsedMax : maxOccupation,
            minOccupation: isNaN(parsedMin) ? parsedMin : minOccupation,
        };

        const allParams = {
            lineTypeUuid: eventLine.lineTypeUuid,
            name: eventLine.name,
            eventLineStatus: eventLine.eventLineStatus,
            ...paramsCompact,
            ...(eventUuids && { applyForSeries, eventUuids }),
        };

        const updateParams = acl("events.lines.update")
            ? allParams
            : paramsCompact;

        update(eventLine.uuid, updateParams).then(() => {
            this.closeSaveForAllDialog();
            this.closeSaveDialog();
        });

        this.clearNotifications();
    }

    /**
     * Render
     */
    render() {
        const {
            translate,
            details,
            disabled,
            eventLine,
            canEditShowLine,
            loadSeriesEvents,
            seriesEvents,
        } = this.props;
        const {
            briefing,
            preBriefing,
            minOccupation,
            maxOccupation,
            start,
            end,
            saveForAllDialogOpen,
            saveDialogOpen,
            isBriefingChanged,
            isPreBriefingChanged,
        } = this.state;

        return (
            <>
                {saveForAllDialogOpen && (
                    <SaveForSeries
                        translate={translate}
                        onClose={() => this.closeSaveForAllDialog()}
                        loadSeriesEvents={loadSeriesEvents}
                        seriesEvents={seriesEvents}
                        onSubmit={(e, selected) => {
                            this.handleSubmit(e, true, selected).bind(this);
                        }}
                        loading={details.loading}
                    />
                )}
                {saveDialogOpen && (
                    <ConfirmNotification
                        translate={translate}
                        onClose={() => this.closeSaveDialog()}
                        onSubmit={(
                            e,
                            preBriefingNotification,
                            briefingNotification,
                        ) =>
                            this.handleSave(
                                e,
                                preBriefingNotification,
                                briefingNotification,
                            )
                        }
                        isBriefingChanged={isBriefingChanged}
                        isPrebriefingChanged={isPreBriefingChanged}
                    />
                )}
                <form className={styles.form}>
                    <Grid container spacing={3}>
                        <Grid item sm={3}>
                            <Typography>
                                <Box fontWeight="600">
                                    {translate("Settings")}
                                </Box>
                            </Typography>
                            {details && details.error && (
                                <Typography color="error">
                                    {details.error}
                                </Typography>
                            )}
                        </Grid>
                        <Grid container item sm={9} spacing={2}>
                            <Grid item xs={6}>
                                <DateTimeField
                                    id="detailsform-start"
                                    disabled={
                                        disabled ||
                                        eventLine.eventLineStatus ===
                                            "CLOSED" ||
                                        !canEditShowLine
                                    }
                                    value={start}
                                    onChange={value =>
                                        this.setState({
                                            start: value,
                                        })
                                    }
                                    label={translate("Start")}
                                    error={!!this.getFieldError("start")}
                                    helperText={this.getFieldError("start")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimeField
                                    id="detailsform-end"
                                    disabled={
                                        disabled ||
                                        eventLine.eventLineStatus ===
                                            "CLOSED" ||
                                        !canEditShowLine
                                    }
                                    value={end ? end : start}
                                    onChange={value =>
                                        this.setState({
                                            end: value,
                                        })
                                    }
                                    label={translate("End")}
                                    error={!!this.getFieldError("end")}
                                    helperText={this.getFieldError("end")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="detailsform-min"
                                    label={translate("Min. staff")}
                                    onChange={this.getChangeHandler(
                                        "minOccupation",
                                    )}
                                    value={minOccupation}
                                    type="number"
                                    inputProps={{
                                        min: "1",
                                        max: maxOccupation - 1,
                                    }}
                                    error={
                                        !!this.getFieldError("minOccupation")
                                    }
                                    helperText={this.getFieldError(
                                        "minOccupation",
                                    )}
                                    disabled={
                                        this.disabled ||
                                        eventLine.eventLineStatus ===
                                            "CLOSED" ||
                                        !canEditShowLine
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="detailsform-max"
                                    label={translate("Max. staff")}
                                    onChange={this.getChangeHandler(
                                        "maxOccupation",
                                    )}
                                    value={maxOccupation}
                                    type="number"
                                    inputProps={{ min: "1" }}
                                    error={
                                        !!this.getFieldError("maxOccupation")
                                    }
                                    helperText={this.getFieldError(
                                        "maxOccupation",
                                    )}
                                    disabled={
                                        this.disabled ||
                                        eventLine.eventLineStatus ===
                                            "CLOSED" ||
                                        !canEditShowLine
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid
                                item
                                xs={3}
                                container
                                style={{ padding: "20px 12px 0 12px" }}
                            >
                                <Grid item xs={12}>
                                    <Typography>
                                        <Box fontWeight="600">Briefings</Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {translate(
                                            "Changes made to these briefings only apply to this specific line (and shift).",
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={9}>
                                <Grid
                                    item
                                    xs={12}
                                    className={styles.briefingContainer}
                                >
                                    <TextField
                                        id="detailsform-prebriefing"
                                        label={translate("Prebriefing")}
                                        onChange={this.getChangeHandler(
                                            "preBriefing",
                                        )}
                                        value={preBriefing}
                                        error={
                                            !!this.getFieldError("preBriefing")
                                        }
                                        helperText={this.getFieldError(
                                            "preBriefing",
                                        )}
                                        disabled={
                                            disabled ||
                                            eventLine.eventLineStatus ===
                                                "CLOSED" ||
                                            !canEditShowLine
                                        }
                                        rows={4}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={styles.briefingContainer}
                                >
                                    <TextField
                                        id="detailsform-briefing"
                                        label={translate("Briefing")}
                                        onChange={this.getChangeHandler(
                                            "briefing",
                                        )}
                                        value={briefing}
                                        error={!!this.getFieldError("briefing")}
                                        helperText={this.getFieldError(
                                            "briefing",
                                        )}
                                        disabled={
                                            disabled ||
                                            eventLine.eventLineStatus ===
                                                "CLOSED" ||
                                            !canEditShowLine
                                        }
                                        rows={4}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={styles.actionButtons}>
                                    <Button
                                        id="detailsform-cancel"
                                        disabled={disabled}
                                        onClick={this.reset.bind(this)}
                                    >
                                        {translate("Cancel")}
                                    </Button>
                                    <Button
                                        id="detailsform-confirm-all-series"
                                        disabled={
                                            disabled || !eventLine.lineUuid
                                        }
                                        onClick={() =>
                                            this.setState({
                                                saveForAllDialogOpen: true,
                                            })
                                        }
                                        color="primary"
                                    >
                                        {translate("Save for all series")}
                                    </Button>
                                    <Button
                                        id="detailsform-confirm"
                                        disabled={disabled}
                                        color="primary"
                                        onClick={e => {
                                            this.handleSaveClick(e);
                                        }}
                                    >
                                        {translate("Save changes")}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }
}

const Details = ({
    details,
    eventLine,
    translate,
    disabled,
    update,
    active,
    acl,
    canEditShowLine,
    loadSeriesEvents,
    seriesEvents,
    clearSeriesEvents,
}: Props) => (
    <div className={styles.main}>
        <Fragment>
            {details.error && (
                <Typography color="error">{details.error}</Typography>
            )}
            {details.loading && <Loading />}
            <Form
                active={active}
                disabled={disabled}
                details={details}
                eventLine={eventLine}
                translate={translate}
                acl={acl}
                update={update}
                key={eventLine.uuid}
                canEditShowLine={canEditShowLine}
                seriesEvents={seriesEvents}
                loadSeriesEvents={loadSeriesEvents}
                clearSeriesEvents={clearSeriesEvents}
            />
        </Fragment>
    </div>
);

Details.defaultProps = {
    disabled: false,
};

export default Details;
