// @flow
import React, { useState, useMemo } from "react";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    CircularProgress,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import type { VenueStaffingTemplate } from "../../../../redux/modules/venue-staffing-templates.d";
import StyledFormControlLabel from "../../../StyledFormControlLabel";

export type SelectedTemplatesType = {
    [key: string]: { uuid: string, name: string },
};

type Props = {
    translate: *,
    onClose: () => void,
    lineTypes: {
        name: string,
        uuid: string,
    }[],
    staffingTemplates: ?(VenueStaffingTemplate[]),
    onNext: (selected: SelectedTemplatesType) => void,
    onApplySeries: (selected: SelectedTemplatesType) => void,
    selected: SelectedTemplatesType,
};

const SelectTemplates = ({
    translate,
    onClose,
    lineTypes,
    staffingTemplates,
    onNext,
    onApplySeries,
    selected: selectedProp,
}: Props) => {
    const [selected, setSelected] = useState(selectedProp);
    const [filter, setFilter] = useState(null);

    const groupedTemplates = useMemo(() => {
        if (staffingTemplates) {
            return lineTypes
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(lineType => ({
                    lineType,
                    templates: staffingTemplates
                        ?.filter(t => t.lineType.uuid === lineType.uuid)
                        .sort((a, b) => a.name.localeCompare(b.name)),
                }))
                .filter(group => group.templates.length);
        }
    }, [staffingTemplates, lineTypes, filter]);

    return (
        <>
            <DialogTitle disableTypography>
                <Typography variant="h2" color="inherit">
                    {translate("Apply templates")}
                </Typography>
                <Typography color="inherit">
                    {translate(
                        "Choose one or more templates (one per facility) to apply for this event or series of events.",
                    )}
                </Typography>
                <TextField
                    id="applytemplates-select-filter"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    onChange={({ target: { value } }) => {
                        setFilter(value.toLowerCase());
                    }}
                    placeholder={translate("Filter by name")}
                    disabled={!staffingTemplates}
                    fullWidth
                    style={{ marginTop: "1em" }}
                />
            </DialogTitle>
            <DialogContent>
                {!groupedTemplates ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    groupedTemplates.map(group => {
                        const filteredTemplates = [...group.templates].filter(
                            template => {
                                if (!filter) return true;

                                return template.name
                                    .toLowerCase()
                                    .includes(filter);
                            },
                        );

                        if (!filteredTemplates.length) return null;

                        return (
                            <FormControl
                                margin="normal"
                                fullWidth
                                key={group.lineType.uuid}
                            >
                                <FormLabel>{group.lineType.name}</FormLabel>
                                <RadioGroup
                                    key={group.lineType.uuid}
                                    name={group.lineType.name}
                                    onChange={e =>
                                        setSelected({
                                            ...selected,
                                            [group.lineType.name]: {
                                                uuid: e.target.value,
                                                name: e.target.name,
                                            },
                                        })
                                    }
                                    value={
                                        selected[group.lineType.name]?.uuid ||
                                        null
                                    }
                                >
                                    {filteredTemplates.map(template => (
                                        <StyledFormControlLabel
                                            key={template.uuid}
                                            value={template.uuid}
                                            name={template.name}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    onClick={e => {
                                                        if (
                                                            selected[
                                                                group.lineType
                                                                    .name
                                                            ]?.uuid ===
                                                            e.target.value
                                                        ) {
                                                            const selectedCopy =
                                                                {
                                                                    ...selected,
                                                                };
                                                            delete selectedCopy[
                                                                group.lineType
                                                                    .name
                                                            ];
                                                            setSelected(
                                                                selectedCopy,
                                                            );
                                                        }
                                                    }}
                                                />
                                            }
                                            label={template.name}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        );
                    })
                )}
            </DialogContent>
            <DialogActions>
                <Button id="applytemplates-select-cancel" onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button
                    id="applytemplates-select-applyseries"
                    color="primary"
                    disabled={Object.keys(selected).length === 0}
                    onClick={() => onApplySeries(selected)}
                >
                    {translate("Apply to series")}
                </Button>
                <Button
                    id="applytemplates-select-next"
                    color="primary"
                    disabled={Object.keys(selected).length === 0}
                    onClick={() => onNext(selected)}
                >
                    {translate("Next")}
                </Button>
            </DialogActions>
        </>
    );
};
export default SelectTemplates;
