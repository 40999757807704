// @flow

import moment from "moment";

/**
 * Validate the JWT token
 * token: The token to be validated
 * offset: The offset in seconds
 */
export default (token: string, offset: number = 5) => {
    try {
        const now = moment();
        const exp = moment.unix(JSON.parse(atob(token.split(".")[1])).exp);

        return !now.isAfter(exp.subtract(offset, "seconds"));
    } catch (_) {
        return false;
    }
};
