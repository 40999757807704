// @flow

/* eslint react/display-name: 0 */

import { fromJS } from "immutable";
import React, { type Node } from "react";
import { connect } from "react-redux";
import warning from "warning";

import PERMISSIONS, { ROLE_ADMINISTRATOR } from "../permissions";

export type Acl = (permission: string) => boolean;

const Context = React.createContext<any>();

/**
 * Fetch a roles array for permissions
 */
const getRoles = (permission: string): ?(string[]) =>
    (permission
        .split(".")
        .reduce(
            (accumulator, current) => accumulator && accumulator[current],
            PERMISSIONS,
        ): any);

/**
 * Test if a role has a required permission
 */
const getAcl = roles => permission => {
    const permissionRoles = getRoles(permission);

    // Print error
    warning(!!permissionRoles, `Permission ${permission} is not defined!`);

    // Short circuit if the role is admin
    if (roles.includes(ROLE_ADMINISTRATOR)) {
        return true;
    }

    // Undefined permission
    if (!permissionRoles) {
        return false;
    }

    for (const role of permissionRoles) {
        if (roles.includes(role)) {
            return true;
        }
    }

    return false;
};

/**
 * Permission provider
 */
const PermissionProvider = ({
    acl,
    children,
}: {
    acl: Acl,
    children: Node,
}) => <Context.Provider value={acl}>{children}</Context.Provider>;

export const Provider = connect(({ user }) => ({
    acl: getAcl(
        user.getIn(["data", "profile", "authorities"], fromJS([])).toJS(),
    ),
}))(PermissionProvider);

/**
 * Acl consumer
 */
export const withAcl = (Component: *) => (props: *) =>
    (
        <Context.Consumer>
            {acl => <Component {...props} acl={acl} />}
        </Context.Consumer>
    );
