// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { type State as OptionState } from "../../redux/modules/option.d";
import {
    load as loadOption,
    clear as clearOption,
} from "../../redux/modules/option";
import { type State as VenuesState } from "../../redux/modules/venues.d";
import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";
import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";
import { clear as clearEditConfirmedOption } from "../../redux/modules/edit-confirmed-option";

import {
    load as editConfirmedOption,
    ERROR as CONFIRMED_OPTION_ERROR,
} from "../../redux/modules/edit-confirmed-option";
import { type State as EditConfirmedOptionState } from "../../redux/modules/edit-confirmed-option.d";
import { withTranslate } from "../Translate";
import { refetch as refetchCalendar } from "../../redux/modules/calendar";
import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";
import { type State as AddPromoterState } from "../../redux/modules/add-promoter.d";
import { mapState } from "../../redux/utils";
import { EditConfirmedOption } from "../../components/Events/Option/EditOption";

type Props = {
    onClose: () => void,
    translate: *,
    /** get option data*/
    option: OptionState,
    loadOption: (uuid: string) => *,
    /**generate new promoter */
    addPromoter: *,
    addPromoterState: AddPromoterState,
    /** get promoters */
    organisers: OrganisersState,
    loadOrganisers: () => *,
    venues: VenuesState,
    match: Match,
    /**edit confirmed option */
    editConfirmedOption: (uuid: string, option: Object[]) => *,
    editConfirmedOptionState: EditConfirmedOptionState,
    /** clear */
    clearEditConfirmedOption: () => *,
    clearOption: () => *,
    /** Calendar */
    refetchCalendar: *,
    clearAddPromoter: *,
    refetchOrganisers: () => Promise<*>,
};

class EditConfirmedOptionContainer extends Component<Props> {
    componentDidMount() {
        const {
            loadOption,
            loadOrganisers,
            clearEditConfirmedOption,
            clearOption,
        } = this.props;
        clearOption();
        clearEditConfirmedOption();
        clearAddPromoter();
        loadOrganisers();
        loadOption(this.uuid);
    }

    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            translate,
            option,
            venues,
            organisers,
            onClose,
            editConfirmedOption,
            editConfirmedOptionState,
            addPromoter,
            addPromoterState,
            loadOrganisers,
            refetchCalendar,
            clearOption,
            clearEditConfirmedOption,
            clearAddPromoter,
            refetchOrganisers,
        } = this.props;

        return (
            <EditConfirmedOption
                onClose={() => {
                    clearOption();
                    clearEditConfirmedOption();
                    clearAddPromoter();
                    onClose();
                }}
                translate={translate}
                loadVenues={loadVenues}
                venues={venues}
                addPromoter={addPromoter}
                addPromoterState={addPromoterState}
                promoters={organisers}
                editConfirmedOptionState={editConfirmedOptionState}
                onSubmit={(uuid, option) =>
                    editConfirmedOption(uuid, option).then(() => {
                        refetchCalendar();
                        onClose();
                        clearEditConfirmedOption();
                        clearAddPromoter();
                        clearOption();
                    })
                }
                optionData={option.data}
                loadOrganisers={loadOrganisers}
                id="edit-confirmed-option"
                refetchOrganisers={refetchOrganisers}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({
                option,
                organisers,
                venues,
                editConfirmedOption,
                addPromoter,
            }) => ({
                option,
                editConfirmedOptionState: mapState(
                    editConfirmedOption,
                    CONFIRMED_OPTION_ERROR,
                ),
                venues: mapState(venues, VENUES_ERROR, "venues"),
                organisers: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                addPromoterState: mapState(
                    addPromoter,
                    ADD_PROMOTER_ERROR,
                    "uuid",
                ),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadVenues,
                        loadOrganisers,
                        addPromoter,
                        clearAddPromoter,
                        clearEditConfirmedOption,
                        clearOption,
                        loadOption: uuid => loadOption(uuid),
                        refetchCalendar,
                        editConfirmedOption: (uuid, option) =>
                            editConfirmedOption(uuid, option),
                        refetchOrganisers,
                    },
                    dispatch,
                ),
        )(EditConfirmedOptionContainer),
    ),
);
