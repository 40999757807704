// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X7a0H20e0pX8XTWDj3K1nQ\\=\\={min-width:100%;width:26em}.X7a0H20e0pX8XTWDj3K1nQ\\=\\= .yRAMbp\\+67LPZG3HvMLIrzw\\=\\={height:28em;overflow:auto}.X7a0H20e0pX8XTWDj3K1nQ\\=\\= .drLqCPRN1FNPMMFgt\\+M0Og\\=\\={height:3em;text-align:center}.OGij9ZwEMQv\\+LQ9v3Mjh3A\\=\\=>ul{justify-content:center}.OGij9ZwEMQv\\+LQ9v3Mjh3A\\=\\=>ul ._2a6QioiCRopkEgGNzOh0FA\\=\\={background-color:cyan}.Q0DKbhvT2jZnhAnbf4\\+BZQ\\=\\={width:100% !important;display:flex;justify-content:center}.nqPP2CpR21\\+K7g-9fq8Pkg\\=\\= span{font-weight:700 !important}.tb92aKdqXPn3974-k8FrYg\\=\\={-webkit-text-decoration:line-through #888 !important;text-decoration:line-through #888 !important}\n", "",{"version":3,"sources":["webpack://./../TransferList.scss"],"names":[],"mappings":"AAAA,4BACI,cAAe,CACf,UAAW,CAFf,yDAKQ,WAAY,CACZ,aAAc,CANtB,yDAUQ,UAAW,CACX,iBAAkB,CACrB,gCAKG,sBAAuB,CAF/B,6DAKY,qBAAsB,CACzB,6BAKL,qBAAsB,CACtB,YAAa,CACb,sBAAuB,CAC1B,kCAGG,0BAA2B,CAC9B,4BAGG,oDAAiB,CAAjB,4CAA6C","sourcesContent":[".card {\n    min-width: 100%;\n    width: 26em;\n\n    .list {\n        height: 28em;\n        overflow: auto;\n    }\n\n    .loader {\n        height: 3em;\n        text-align: center;\n    }\n}\n\n.pagination {\n    > ul {\n        justify-content: center;\n\n        .Mui-selected {\n            background-color: cyan;\n        }\n    }\n}\n\n.listWrapper {\n    width: 100% !important;\n    display: flex;\n    justify-content: center;\n}\n\n.interested span {\n    font-weight: 700 !important;\n}\n\n.notInterested {\n    text-decoration: line-through #888 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "X7a0H20e0pX8XTWDj3K1nQ==",
	"list": "yRAMbp+67LPZG3HvMLIrzw==",
	"loader": "drLqCPRN1FNPMMFgt+M0Og==",
	"pagination": "OGij9ZwEMQv+LQ9v3Mjh3A==",
	"Mui-selected": "_2a6QioiCRopkEgGNzOh0FA==",
	"listWrapper": "Q0DKbhvT2jZnhAnbf4+BZQ==",
	"interested": "nqPP2CpR21+K7g-9fq8Pkg==",
	"notInterested": "tb92aKdqXPn3974-k8FrYg=="
};
export default ___CSS_LOADER_EXPORT___;
