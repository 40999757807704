// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./series-events.d";

const { reducer, actions } = createApiModule(
    "seriesEvents",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/series/${uuid}/events?retrievePassed=false`,
);

export const ERROR = "Could not fetch events for this series";
export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid } }));
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
