// @flow
import styles from "./Move.scss";

import React, { useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import AsyncFilteredSelect from "../../AsyncFilteredSelect";
import {
    format,
    formatWithoutTime,
    mapToLocalTimezone,
} from "../../../utils/date";

type Props = {
    translate: *,
    events: *,
    series: *,
    loadEvents: (page: number) => void,
    handleSelectAll: () => void,
    numberOfSelectedEvents: number,
    isEventSelected: (event: *) => boolean,
    onEventChange: (event: *) => void,
    onSerieChange: (serie: *) => void,
    findSeries: (filter: string) => *,
    selectedSerie: *,
    seriesUuid: string,
};

const createDisplayedName = (translate, item) => {
    let displayName = "";
    if (item.optionDate)
        displayName += `${formatWithoutTime(
            mapToLocalTimezone(item.optionDate),
        )} `;
    else if (item.start)
        displayName += `${format(mapToLocalTimezone(item.start))} `;
    if (item.status) {
        let suffix = "";
        switch (item.status) {
            case "OPTION_CREATED":
                suffix = translate("Option");
                break;
            case "OPTION_CONFIRMED":
                suffix = translate("Confirmed Option");
                break;
            case "LIMITED_AVAILABILITY":
                suffix = translate("Confirmed Option (Limited)");
                break;
            case "PUBLIC":
                suffix = translate("Public event");
                break;
            case "PRIVATE":
                suffix = translate("Private event");
                break;
            default:
                suffix = translate("Option");
                break;
        }

        displayName += `${suffix} `;
    }
    if (item.name) displayName += `${item.name} `;
    return displayName;
};

const mapItems = (translate, items, onChange, isItemSelected) =>
    items.map(item => {
        const labelId = `member-${item.uuid}-label`;
        const handleOnChange = () => {
            onChange(item);
        };
        return (
            <ListItem
                key={item.uuid}
                role="listitem"
                button
                onClick={handleOnChange}
            >
                <ListItemIcon>
                    <Checkbox
                        color="primary"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                        checked={isItemSelected(item)}
                    />
                </ListItemIcon>
                <ListItemText
                    id={labelId}
                    primary={createDisplayedName(translate, item)}
                />
            </ListItem>
        );
    });

const customList = (
    translate,
    title,
    items,
    onChange,
    hasSelectAll,
    handleSelectAll,
    hasMore,
    handleFetch,
    pagingEvents,
    numberOfSelected,
    isItemSelected,
) => {
    return (
        <Card className={styles.card}>
            <CardHeader
                avatar={
                    hasSelectAll && (
                        <Checkbox
                            color="primary"
                            onClick={handleSelectAll}
                            checked={
                                numberOfSelected === items.length &&
                                items.length !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{ "aria-label": "all items selected" }}
                        />
                    )
                }
                title={title}
            />
            <Divider />
            <List dense component="div" role="list">
                <InfiniteScroll
                    dataLength={items.data?.content?.length || 0}
                    hasMore={hasMore}
                    next={handleFetch}
                    height={450}
                >
                    {items.data &&
                        mapItems(
                            translate,
                            items.data.content,
                            onChange,
                            isItemSelected,
                        )}
                    {(hasMore || items.loading) &&
                        pagingEvents.totalPages !== 0 && (
                            <div className={styles.loader}>
                                <CircularProgress size={35} />
                            </div>
                        )}
                </InfiniteScroll>
            </List>
        </Card>
    );
};

const Move = ({
    translate,
    events = [],
    series,
    loadEvents,
    handleSelectAll,
    numberOfSelectedEvents,
    onEventChange,
    onSerieChange,
    isEventSelected,
    selectedSerie,
    findSeries,
    seriesUuid,
}: Props) => {
    const [pagingEvents, setPagingEvents] = useState({
        current: 0,
        totalPages: null,
    });

    const hasMoreEvents = pagingEvents.current + 1 < pagingEvents.totalPages;
    const [localEvents, setLocalEvents] = useState([]);

    const isDuplicate = (data, obj) =>
        data.some(el =>
            Object.entries(obj).every(([key, value]) => value === el[key]),
        );

    useEffect(() => {
        if (events.data) {
            events.data?.content.forEach(element => {
                if (!isDuplicate(localEvents, element)) {
                    setLocalEvents(current => current.concat(element));
                }
            });
        }
    }, [events]);

    useEffect(() => {
        if (events.data) {
            setPagingEvents({
                ...pagingEvents,
                totalPages: 1,
            });
        }
    }, [events]);

    const handleFetchEvents = () => {
        if (hasMoreEvents) {
            loadEvents(seriesUuid, pagingEvents.current + 1);
            setPagingEvents({
                ...pagingEvents,
                current: pagingEvents.current + 1,
            });
        }
    };

    const itemToLabel = item => {
        if (item && item.uuid) {
            if (item.name) return item.name;
            if (item.optionCode) return item.optionCode;
        }
        return "";
    };

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item sm={6} className={styles.listWrapper}>
                {customList(
                    translate,
                    translate("Select events/options"),
                    events || [],
                    onEventChange,
                    true,
                    handleSelectAll,
                    hasMoreEvents,
                    handleFetchEvents,
                    pagingEvents,
                    numberOfSelectedEvents,
                    isEventSelected,
                )}
            </Grid>
            <Grid item sm={6} className={styles.listWrapper}>
                <Card className={styles.select}>
                    <AsyncFilteredSelect
                        id={`search-serie`}
                        helperText={translate("Select serie")}
                        label={translate("Series")}
                        currentItem={selectedSerie || ""}
                        asyncState={series}
                        lookup={findSeries}
                        onSelect={onSerieChange}
                        itemToString={itemToLabel}
                        itemToLabel={itemToLabel}
                        fullWidth
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: styles.label,
                            },
                        }}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
export default Move;
