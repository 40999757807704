// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YECVmcpVUvAgRIh\\+gDOHiA\\=\\={display:flex;align-items:center;justify-content:space-between;width:60em;height:3.5em;margin:2em;padding:0 1em}.YECVmcpVUvAgRIh\\+gDOHiA\\=\\= p{margin-left:0.2em;margin-right:1em}.e8M8e8RgfuD1AhfDKVFAEg\\=\\= span:first-child{margin-right:0}.jhoEYWwbKy1QSmjv3gnkQw\\=\\={display:flex;flex-wrap:wrap}.jhoEYWwbKy1QSmjv3gnkQw\\=\\=>:first-child{font-style:italic;opacity:0.5}.jhoEYWwbKy1QSmjv3gnkQw\\=\\=>:last-child>:last-child{font-style:italic;text-decoration:line-through;opacity:0.5}.jhoEYWwbKy1QSmjv3gnkQw\\=\\=>:last-child span{background-color:#000}.jhoEYWwbKy1QSmjv3gnkQw\\=\\=>div{width:50%;display:flex;align-items:center;margin:0.5em 0}\n", "",{"version":3,"sources":["webpack://./../Legend.scss"],"names":[],"mappings":"AAAA,6BACI,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,UAAW,CACX,YAAa,CACb,UAAW,CACX,aAAc,CAPlB,+BAUQ,iBAAkB,CAClB,gBAAiB,CACpB,6CAKG,cAAe,CAClB,4BAID,YAAa,CACb,cAAe,CAFnB,yCAIQ,iBAAkB,CAClB,WAAY,CALpB,oDAUY,iBAAkB,CAClB,4BAA6B,CAC7B,WAAY,CAZxB,6CAeY,qBAAsB,CAflC,gCAoBQ,SAAU,CACV,YAAa,CACb,kBAAmB,CACnB,cAAe","sourcesContent":[".element {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 60em;\n    height: 3.5em;\n    margin: 2em;\n    padding: 0 1em;\n\n    p {\n        margin-left: 0.2em;\n        margin-right: 1em;\n    }\n}\n\n.cancel {\n    span:first-child {\n        margin-right: 0;\n    }\n}\n\n.modal {\n    display: flex;\n    flex-wrap: wrap;\n    & > :first-child {\n        font-style: italic;\n        opacity: 0.5;\n    }\n\n    & > :last-child {\n        & > :last-child {\n            font-style: italic;\n            text-decoration: line-through;\n            opacity: 0.5;\n        }\n        span {\n            background-color: #000;\n        }\n    }\n\n    > div {\n        width: 50%;\n        display: flex;\n        align-items: center;\n        margin: 0.5em 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "YECVmcpVUvAgRIh+gDOHiA==",
	"cancel": "e8M8e8RgfuD1AhfDKVFAEg==",
	"modal": "jhoEYWwbKy1QSmjv3gnkQw=="
};
export default ___CSS_LOADER_EXPORT___;
