// @flow

import createApiModule from "../create-api-module";
import type {
    PathParams,
    LineTypeOccupationBulkUpdateData,
    LineTypeHoursBulkUpdateData,
    LineTypeBriefingsBulkUpdateData,
} from "./event-linetype-bulk-update.d";

const { reducer, actions } = createApiModule(
    "eventLineTypeBulkUpdate",
    ({ uuid, lineTypeUuid, entity }: PathParams) =>
        `/ays-series-event-management/events/${uuid}/line-types/${lineTypeUuid}/${entity}`,
    {
        method: "POST",
    },
);

export const load =
    (
        uuid: string,
        lineTypeUuid: string,
        data:
            | LineTypeOccupationBulkUpdateData
            | LineTypeHoursBulkUpdateData
            | LineTypeBriefingsBulkUpdateData,
        entity: PathParams.entity,
    ) =>
    (dispatch: *) => {
        return dispatch(
            actions.load({ path: { uuid, lineTypeUuid, entity }, data }),
        );
    };
export const ERROR = "Could not execute bulk update!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
