// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{font-family:\"Space Grotesk\", \"Arial\", sans-serif;overflow-x:hidden;background-color:#eee;margin:0}input{font-family:\"Space Grotesk\", Arial, sans-serif}.MuiFab-label svg{margin-right:0.5em}\n", "",{"version":3,"sources":["webpack://./../global.scss"],"names":[],"mappings":"AAQA,KACI,gDAAiD,CACjD,iBAAkB,CAClB,qBAAsB,CACtB,QAAS,CACZ,MAGG,8CAA+C,CAClD,kBAIO,kBAAmB","sourcesContent":["/**\n * Global styling\n */\n\n//import variables\n@import \"variables\";\n@import \"fonts\";\n\nbody {\n    font-family: \"Space Grotesk\", \"Arial\", sans-serif;\n    overflow-x: hidden;\n    background-color: #eee;\n    margin: 0;\n}\n\ninput {\n    font-family: \"Space Grotesk\", Arial, sans-serif;\n}\n\n:global .MuiFab-label {\n    svg {\n        margin-right: 0.5em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
