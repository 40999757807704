//@flow
import styles from "./Issues.scss";

import React, { Component } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";

import { type Issues, type Issue } from "../../../redux/modules/event-issues.d";

import IssuesDetail from "./IssuesDetail";

type Props = {
    acl: *,
    translate: *,
    loading: boolean,
    error: ?string,
    data: ?Issues,
    page: number,
    onPageChange: (n: number) => void,
};

type StateType = {
    selected: ?Issue,
    open: boolean,
};

type HeadProps = {
    translate: *,
};

type ItemProps = {
    index: number,
    issue: Issue,
    onClick: (issue: Issue) => void,
};

const Head = ({ translate }: HeadProps) => (
    <TableHead>
        <TableRow>
            <TableCell>{translate("Showline")}</TableCell>
            <TableCell>{translate("Description")}</TableCell>
            <TableCell>{translate("Creation time")}</TableCell>
            <TableCell>{translate("User")}</TableCell>
        </TableRow>
    </TableHead>
);

const Item = ({ index, issue, onClick }: ItemProps) => (
    <TableRow
        id={`organisation-list-item-${index}`}
        className={styles.item}
        onClick={() => onClick(issue)}
    >
        <TableCell component="th" scope="row">
            {issue.eventLineName}
        </TableCell>
        <TableCell>{issue.description}</TableCell>
        <TableCell>
            {moment(issue.creationTime).format("ddd D MMM YYYY, HH:mm")}
        </TableCell>
        <TableCell>{`${issue.firstName} ${issue.lastName}`}</TableCell>
    </TableRow>
);

export default class OrganisationList extends Component<Props, StateType> {
    state = {
        open: false,
        selected: null,
    };

    handleClick(issue: Issue) {
        const { open } = this.state;
        if (open) {
            this.setState({
                open: !open,
                selected: null,
            });
        } else {
            this.setState({
                open: !open,
                selected: issue,
            });
        }
    }

    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }

    get onlyError(): boolean {
        const { data, error } = this.props;

        return !!(!data && error);
    }

    render() {
        const { page, data, error, loading, translate, acl } = this.props;
        const { open, selected } = this.state;

        return (
            <div className={styles.main}>
                {data && (
                    <div>
                        <Paper elevation={2}>
                            {acl("events.issues.read") && (
                                <div>
                                    {error && <h1>{error}</h1>}
                                    <Table>
                                        <Head translate={translate} />
                                        <TableBody>
                                            {data &&
                                                data.content.map(
                                                    (issue, index) => (
                                                        <Item
                                                            key={
                                                                issue.issueUuid
                                                            }
                                                            onClick={this.handleClick.bind(
                                                                this,
                                                            )}
                                                            index={index}
                                                            issue={issue}
                                                        />
                                                    ),
                                                )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    backIconButtonProps={{
                                                        id: "issues-list-page-back",
                                                    }}
                                                    nextIconButtonProps={{
                                                        id: "issues-list-page-next",
                                                    }}
                                                    colSpan={4}
                                                    count={
                                                        data
                                                            ? data.totalElements
                                                            : 0
                                                    }
                                                    rowsPerPage={
                                                        (data && data.size) || 0
                                                    }
                                                    rowsPerPageOptions={[10]}
                                                    page={page - 1}
                                                    onChangePage={this.handleChangePage.bind(
                                                        this,
                                                    )}
                                                    onChangeRowsPerPage={
                                                        (null: any)
                                                    }
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>
                            )}
                        </Paper>
                    </div>
                )}
                {open && selected && (
                    <IssuesDetail
                        issue={selected}
                        onClose={this.handleClick.bind(this, selected)}
                        translate={translate}
                    />
                )}
                {loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
                {this.onlyError && (
                    <Paper elevation={2}>
                        <Typography color="error">{error}</Typography>
                    </Paper>
                )}
            </div>
        );
    }
}
