// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, type Match } from "react-router";

import { load, remove } from "../../../redux/modules/member";

import { type State as MemberShipState } from "../../../redux/modules/member.d";

import _DeleteMember from "../../../components/Modals/DeleteMember/DeleteMember";

import { withTranslate } from "../../Translate";

type Props = {
    translate: *,
    match: Match,
    load: (uuid: string) => void,
    remove: () => Promise<void>,
    member: MemberShipState,
    onBack: () => void,
};

class DeleteMember extends Component<Props> {
    /**
     * Initial load
     */
    componentDidMount() {
        this.reload();
    }

    /**
     * Reload on props change
     */
    componentDidUpdate() {
        this.reload();
    }

    /**
     * Reload
     */
    reload() {
        const {
            match: {
                params: { id },
            },
            load,
        } = this.props;

        if (id) {
            load(id);
        }
    }

    /**
     * Render
     */
    render() {
        const { member, onBack, translate, remove } = this.props;

        return (
            <_DeleteMember
                translate={translate}
                member={member}
                onSubmit={() => remove().then(() => onBack())}
                onClose={onBack}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            ({ member }) => ({
                member: member.toJS(),
            }),
            (dispatch: *) => bindActionCreators({ load, remove }, dispatch),
        )(DeleteMember),
    ),
);
