// @flow

// Import styles
import styles from "./StaffingIndicator.scss";

// Import libs
import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Define props
type PropsType = {
    currentOccupation: number,
    minOccupation: number,
    maxOccupation: number,
    translate: *,
    fullyStaffed?: boolean,
};
/**
 * A dummy component
 */
export default class StaffingIndicator extends Component<PropsType> {
    static defaultProps = {
        color: "#298fc2",
    };

    getColor() {
        const { currentOccupation, minOccupation, fullyStaffed } = this.props;
        if (fullyStaffed) {
            return "#56c271";
        }
        if (currentOccupation === 0) {
            return "#eeeeee";
        }
        if (currentOccupation < minOccupation) {
            return "#298fc2";
        }

        if (currentOccupation >= minOccupation) {
            if (typeof fullyStaffed !== "undefined") {
                if (fullyStaffed) {
                    return "#56c271";
                } else {
                    return "#298fc2";
                }
            } else {
                return "#56c271";
            }
        }

        return "#eeeeee";
    }
    /**
     * Render
     */
    render() {
        const {
            translate,
            currentOccupation,
            maxOccupation,
            minOccupation,
            fullyStaffed,
        } = this.props;

        return (
            <Tooltip
                title={translate(
                    "Current: %{currentOccupation}, Mininum: %{minOccupation}, Maximum: %{maxOccupation}.",
                    {
                        currentOccupation: currentOccupation,
                        maxOccupation: maxOccupation,
                        minOccupation: minOccupation,
                    },
                )}
            >
                <div className={styles.wrapper}>
                    <span
                        className={styles.indicator}
                        style={{ backgroundColor: this.getColor() }}
                    />
                    {currentOccupation < minOccupation ||
                    (typeof fullyStaffed !== "undefined" && !fullyStaffed) ? (
                        <Typography
                            style={{
                                color:
                                    currentOccupation === 0
                                        ? "#888888"
                                        : "inherit",
                            }}
                        >{`${currentOccupation}/${minOccupation}`}</Typography>
                    ) : (
                        <Typography>{currentOccupation}</Typography>
                    )}
                </div>
            </Tooltip>
        );
    }
}
