// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4Mxx9ALt\\+beE01EgoC8\\+GA\\=\\={float:right;overflow:hidden}.Gt\\+uhQDlPgJFShB0z5xuRw\\=\\={display:flex;flex-direction:row}.Gt\\+uhQDlPgJFShB0z5xuRw\\=\\= .lqaru0SruJUVWCeqpIbwDA\\=\\={display:flex;flex-direction:column}.Gt\\+uhQDlPgJFShB0z5xuRw\\=\\= .Guru-4Y-8Lu00NW\\+7Vs9aQ\\=\\={margin-left:auto;overflow:hidden}._2elLdsB1mQAE6y4phvlpGA\\=\\={text-transform:uppercase;color:black}\n", "",{"version":3,"sources":["webpack://./../BriefingEditor.scss"],"names":[],"mappings":"AAAA,+BACI,WAAY,CACZ,eAAgB,CACnB,6BAGG,YAAa,CACb,kBAAmB,CAFvB,yDAKQ,YAAa,CACb,qBAAsB,CAN9B,0DAUQ,gBAAiB,CACjB,eAAgB,CACnB,6BAID,wBAAyB,CACzB,WAAY","sourcesContent":[".actionButtons {\n    float: right;\n    overflow: hidden;\n}\n\n.headerContainer {\n    display: flex;\n    flex-direction: row;\n\n    .labelContainer {\n        display: flex;\n        flex-direction: column;\n    }\n\n    .notificationCheckbox {\n        margin-left: auto;\n        overflow: hidden;\n    }\n}\n\n.title {\n    text-transform: uppercase;\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": "_4Mxx9ALt+beE01EgoC8+GA==",
	"headerContainer": "Gt+uhQDlPgJFShB0z5xuRw==",
	"labelContainer": "lqaru0SruJUVWCeqpIbwDA==",
	"notificationCheckbox": "Guru-4Y-8Lu00NW+7Vs9aQ==",
	"title": "_2elLdsB1mQAE6y4phvlpGA=="
};
export default ___CSS_LOADER_EXPORT___;
