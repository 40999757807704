// @flow

import styles from "./Lines.scss";

import React from "react";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircle";
import Fab from "@material-ui/core/Fab";
import { type EventLine } from "../../redux/modules/event-lines.d";
import { type LineTypeBulkEditProps } from "./LineTypeBulkEdit/LineTypeBulkEdit";

import Group from "./Group";
import FabContainer from "../FabContainer/FabContainer";

type Props = {
    /** identifier */
    id: string,
    /** can add lines */
    canAdd: boolean,
    /** Translate */
    translate: *,
    /** Data */
    eventLines: EventLine[],
    /** disabled */
    disabled: boolean,
    /** Uuid of the selected eventline */
    selected: ?string,
    /** Triggers when a line is selected */
    onSelect: (eventLineUuid: string) => void,
    /** Selects the first line*/
    selectFirst: (uuid: string) => void,
    /** Triggers when the add button is fired */
    onAdd: () => void,
    /** Triggers when the add custom button is fired */
    onAddCustom: () => void,
    /** Trigger when an action is fired */
    onLineTypeAction: LineTypeBulkEditProps => void,
    onApplyTemplates: () => void,
};

/**
 * Sort by facilities
 */
const sortLines = (eventLines: EventLine[], defaultGroupName: string) => {
    const grouped = eventLines.reduce((accumulator, current) => {
        const group = current.lineTypeName || defaultGroupName;

        if (!accumulator[group]) {
            accumulator[group] = [];
        }

        accumulator[group].push(current);

        return accumulator;
    }, {});

    return Object.keys(grouped)
        .sort()
        .map(key => ({
            name: key,
            uuid: grouped[key][0].lineTypeUuid,
            lines: grouped[key],
        }));
};

/**
 * Lines component
 */
const Lines = ({
    canAdd,
    eventLines,
    translate,
    onSelect,
    selected,
    selectFirst,
    id,
    onLineTypeAction,
    onAdd,
    disabled,
    onAddCustom,
    onApplyTemplates,
}: Props) => (
    <div className={styles.list} id="test-scrollto">
        {!eventLines.length && (
            <Typography variant="h5" align="center">
                {translate("No lines here yet! Let's add some...")}
            </Typography>
        )}
        {sortLines(eventLines, translate("Default")).map((group, index) => {
            index === 0 && selectFirst(group.lines[0].uuid);
            return (
                <Group
                    translate={translate}
                    id={id}
                    index={index}
                    key={group.name}
                    group={group}
                    onSelect={onSelect}
                    selected={selected}
                    onLineTypeAction={canAdd ? onLineTypeAction : undefined}
                />
            );
        })}
        {!disabled && canAdd && (
            <FabContainer>
                <Fab
                    id="eventline-apply-templates"
                    variant="extended"
                    color="primary"
                    onClick={onApplyTemplates}
                >
                    <AddIcon />
                    {translate("Apply templates")}
                </Fab>
                <Fab
                    id="eventline-add-custom"
                    variant="extended"
                    color="primary"
                    onClick={onAddCustom}
                >
                    <AddIcon />
                    {translate("Add custom eventline")}
                </Fab>
                <Fab
                    id="eventline-add"
                    color="secondary"
                    variant="extended"
                    onClick={onAdd}
                >
                    <AddIcon />
                    {translate("Add eventline")}
                </Fab>
            </FabContainer>
        )}
    </div>
);

export default Lines;
