// @flow

import styles from "./index.style.scss";

import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { type FieldErrors } from "../../../../utils/FormError";

type Props = {
    translate: *,
    min: number,
    max: number,
    onChange: (min: number, max: number) => void,
    disabled: boolean,
    lite: boolean,
    fieldErrors: ?FieldErrors,
};

const Occupancy = ({
    translate,
    min,
    max,
    onChange,
    disabled,
    lite,
    fieldErrors,
}: Props) => (
    <Grid container spacing={3} className={!lite ? styles.wrapper : ""}>
        {!lite && (
            <Grid item sm={3}>
                <Typography>{translate("Staffing")}</Typography>
            </Grid>
        )}
        <Grid item sm={lite ? 12 : 9}>
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <TextField
                        disabled={disabled}
                        fullWidth
                        value={min}
                        type={"number"}
                        inputProps={{ min: 1, max: max - 1 }}
                        label={translate("Min. staff")}
                        onChange={({ target: { value } }) =>
                            onChange(value, max)
                        }
                        error={
                            fieldErrors?.minOccupation ||
                            fieldErrors?.minOccupancy
                        }
                        helperText={
                            fieldErrors?.minOccupation ||
                            fieldErrors?.minOccupancy
                        }
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        disabled={disabled}
                        value={max}
                        fullWidth
                        inputProps={{ min: min }}
                        type={"number"}
                        label={translate("Max. staff")}
                        onChange={({ target: { value } }) =>
                            onChange(min, value)
                        }
                        error={
                            fieldErrors?.maxOccupation ||
                            fieldErrors?.maxOccupancy
                        }
                        helperText={
                            fieldErrors?.maxOccupation ||
                            fieldErrors?.maxOccupancy
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default Occupancy;
