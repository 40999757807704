// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { typeof Client } from "../../utils/api-client/modules/venue-parking";
import {
    type ImmutableState,
    type Action,
    type FullState,
} from "./venue-parking.d";

const ERROR = "Could not fetch parking details";

const ZONE = "venue-parking";
const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    data: null,
    uuid: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === PENDING) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === SUCCESS) {
        invariant(payload, "Wrong payload!");

        return state.merge({
            loading: false,
            error: null,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        invariant(typeof payload === "string", "Wrong payload!");

        return state.merge({
            loading: false,
            error: payload,
            data: null,
        });
    }

    return state;
};

/**
 * ActionCreator: load
 */
export const load =
    (uuid: string) =>
    (
        dispatch: (action: Action) => void,
        getState: () => FullState,
        client: Client,
    ): Promise<void> => {
        const { venueParking: state } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
        });

        return client
            .getVenueParking(uuid)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };
