//@flow

import React from "react";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    List,
    ListItem,
} from "@material-ui/core";

import { ruleMapper } from "../BusinessRules/BusinessRules";

import type { ApplyStaffingTemplatesData } from "../../../../redux/modules/apply-staffing-templates.d";
import type { SelectedTemplatesType } from "../SelectTemplates/SelectTemplates";

type Props = {
    translate: *,
    data: ApplyStaffingTemplatesData & {
        selectedTemplates: SelectedTemplatesType,
        eventNames: string[],
    },
    onBack: () => void,
    onApply: () => void,
    onClose: () => void,
    busy: boolean,
    currentEventDisplayName: string,
};

const Summary = ({
    translate,
    data,
    onBack,
    onApply,
    onClose,
    busy,
    currentEventDisplayName,
}: Props) => {
    return (
        <>
            <DialogTitle disableTypography>
                <Typography variant="h2" color="inherit">
                    {data.applyForSeries
                        ? translate("Apply templates to series")
                        : translate("Apply templates")}
                </Typography>
                <Typography color="inherit">{translate("Summary")}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {translate(
                        "Are you sure to apply the following templates:",
                    )}
                </Typography>
                <List>
                    {Object.keys(data.selectedTemplates).reduce(
                        (nodes, t, i) => {
                            nodes.push(
                                <ListItem key={i}>
                                    <Typography variant="body2">{`${data.selectedTemplates[t].name} (${t})`}</Typography>
                                </ListItem>,
                            );
                            return nodes;
                        },
                        [],
                    )}
                </List>
                {data.applyForSeries && (
                    <>
                        <Typography>
                            {translate("to the following events:")}
                        </Typography>
                        <List>
                            {
                                <ListItem>
                                    <Typography variant="body2">
                                        {currentEventDisplayName}
                                    </Typography>
                                </ListItem>
                            }
                            {data.eventNames.map((name, index) => (
                                <ListItem key={index}>
                                    <Typography variant="body2">
                                        {name}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {data.keepExistingTimingsAndOccupation ||
                data.keepExistingStaffing ||
                data.keepExistingBriefings ? (
                    <>
                        <Typography>
                            {translate("with these rules:")}
                        </Typography>
                        <List>
                            {data.keepExistingTimingsAndOccupation && (
                                <ListItem>
                                    <Typography variant="body2">
                                        {
                                            ruleMapper(translate)
                                                .keepExistingTimingsAndOccupation
                                        }
                                    </Typography>
                                </ListItem>
                            )}
                            {data.keepExistingStaffing && (
                                <ListItem>
                                    <Typography variant="body2">
                                        {
                                            ruleMapper(translate)
                                                .keepExistingStaffing
                                        }
                                    </Typography>
                                </ListItem>
                            )}
                            {data.keepExistingBriefings && (
                                <ListItem>
                                    <Typography variant="body2">
                                        {
                                            ruleMapper(translate)
                                                .keepExistingBriefings
                                        }
                                    </Typography>
                                </ListItem>
                            )}
                        </List>
                    </>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button
                    id="applytemplates-summary-back"
                    onClick={onBack}
                    disabled={busy}
                    style={{ marginRight: "auto" }}
                >
                    {translate("Back")}
                </Button>
                <Button
                    id="applytemplates-summary-cancel"
                    onClick={onClose}
                    disabled={busy}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    id="applytemplates-summary-next"
                    color="primary"
                    onClick={onApply}
                    disabled={busy}
                >
                    {translate("Apply")}
                </Button>
            </DialogActions>
        </>
    );
};

export default Summary;
