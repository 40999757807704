// @flow

/**
 * Application entry point
 */

import "./styles/global.scss";

// requestAnimationFrame polyfill needed for react 16
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import App from "./containers/App/App";
import { createStore } from "./redux";

import ApiClient from "./utils/api-client";

declare var __API_URL__: string;
declare var __APP_AUTH__: string;

const client = new ApiClient(__API_URL__, () => store, __APP_AUTH__);
const store = createStore(client);

// Fetch the root element
const ROOT = document.getElementById("root");

// Render to dom, if root found
if (ROOT) {
    ReactDOM.render(<App store={store} />, ROOT);
}
