// @flow

import styles from "./Staffing.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, type Match, type RouterHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import stripslash from "../../../utils/stripslash";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";

import {
    type State as ShowLinesState,
    type Line,
    type EventLine,
} from "../../../redux/modules/event-lines.d";
import { type State as LinesState } from "../../../redux/modules/lines.d";

import {
    load as loadLines,
    clear as clearLines,
    ERROR as SHOW_LINE_ERROR,
} from "../../../redux/modules/event-lines";

import { load as reloadTeam } from "../../../redux/modules/event-line-team";

import { type State as ShowLineOpenCloseState } from "../../../redux/modules/event-line-openclose.d";
import {
    load as toggleLine,
    ERROR as SHOWLINE_OPEN_CLOSE_ERROR,
} from "../../../redux/modules/event-line-openclose";
import {
    load as addCustomShowline,
    ERROR as CUSTOM_SHOW_LINE_ERROR,
} from "../../../redux/modules/event-line-custom";
import {
    type State as CustomLineState,
    type CustomEventLine,
} from "../../../redux/modules/event-line-custom.d";

import { type State as ShowLineAddState } from "../../../redux/modules/event-line-add.d";
import {
    load as addLines,
    ERROR as SHOW_LINE_ADD_ERROR,
    clear as clearSelected,
} from "../../../redux/modules/event-line-add";
import {
    load as loadStaffingTemplates,
    clear as clearStaffingTemplates,
    ERROR as VENUE_STAFFING_TEMPLATES_ERROR,
} from "../../../redux/modules/venue-staffing-templates";

import {
    load as applyStaffingTemplates,
    clear as clearApplyStaffingTemplates,
    ERROR as APPLY_STAFFING_TEMPLATES_ERROR,
} from "../../../redux/modules/apply-staffing-templates";
import {
    type ApplyStaffingTemplatesData,
    type State as staffingTemplatesApplyState,
} from "../../../redux/modules/apply-staffing-templates.d";

import { type State as VenueStaffingTemplateState } from "../../../redux/modules/venue-staffing-templates.d";

import {
    load as loadSeriesEvents,
    clear as clearSeriesEvents,
    ERROR as SERIES_EVENTS_ERROR,
} from "../../../redux/modules/series-events";

import {
    State as seriesEventsState,
    type SeriesEvent,
} from "../../../redux/modules/series-events.d";

import { mapState } from "../../../redux/utils";

import LineSelector from "../../../components/LineSelector";
import { Lines } from "../../../components/Events";
import { type Profile } from "../../../redux/modules/user.d";

import LineStaffing from "../../../components/Events/LineStaffing";
import CustomLine from "../../../components/Events/CustomLine/CustomLine";
import ApplyTemplates from "../../../components/Events/ApplyTemplates/ApplyTemplates";
import { type LineTypeBulkEditProps } from "../../../components/Events/LineTypeBulkEdit/LineTypeBulkEdit";

import Team from "./Team";
import Organisations from "./Organisations";
import EventLineDetails from "./EventLineDetails";
import EditMember from "../../Organisations/Detail/EditMember";
import LineTypeBulkEdit from "./LineTypeBulkEdit";

type Props = {
    root: string,
    match: Match,
    history: RouterHistory,
    acl: *,
    translate: *,
    disabled: *,
    eventLines: ShowLinesState,
    uuid: string,
    user: Profile,
    eventLineOrganisations: *,
    loadLines: () => Promise<void>,
    clearLines: () => void,
    toggleLine: (uuid: string, status: string) => void,
    addLines: (lines: string[]) => Promise<void>,
    clearSelected: () => void,
    addCustomShowline: (customline: CustomEventLine) => Promise<void>,
    eventLineAdd: ShowLineAddState,
    eventLineOpenClose: ShowLineOpenCloseState,
    customEventLine: CustomLineState,
    loadStaffingTemplates: (
        venueUuid: string,
        lineTypes: string[],
    ) => Promise<void>,
    clearStaffingTemplates: () => void,
    staffingTemplates: VenueStaffingTemplateState,
    venueUuid: string,
    applyStaffingTemplates: (
        eventUuid: string,
        data: ApplyStaffingTemplatesData,
    ) => Promise<void>,
    clearApplyStaffingTemplates: () => void,
    staffingTemplatesApply: staffingTemplatesApplyState,
    seriesUuid: string,
    loadSeriesEvents: () => Promise<void>,
    seriesEvents: seriesEventsState,
    clearSeriesEvents: () => void,
    reloadTeam: (uuid: string) => void,
    currentEventInSeries: SeriesEvent,
    planable: boolean,
};

type State = {
    adding: boolean,
    addingCustom: boolean,
    selectedItem: string,
    active: boolean,
    mUuid: string,
    applyTemplates: boolean,
    lineTypeBulkEdit?: LineTypeBulkEditProps,
    isLoading: boolean,
};

const linesToSelectorFormat = (
    lines: Line[],
    eventLineAdd: ShowLineAddState,
    defaultGroupName: string,
): LinesState => {
    const grouped = lines.reduce((accumulator, current) => {
        const group = current.lineTypeName || defaultGroupName;

        if (!accumulator[group]) {
            accumulator[group] = [];
        }

        accumulator[group].push({
            ...current,
            venues: [],
        });

        return accumulator;
    }, {});

    return {
        loading: eventLineAdd.loading,
        error: eventLineAdd.error,
        data: Object.keys(grouped)
            .sort()
            .map(key => ({
                uuid: grouped[key].lineTypeUuid || key,
                name: key,
                lines: grouped[key],
            })),
    };
};

/**
 * Staffing
 */
class Staffing extends Component<Props, State> {
    // Initial state
    state = {
        adding: false,
        addingCustom: false,
        selectedItem: "",
        active: false,
        mUuid: "",
        applyTemplates: false,
        lineTypeBulkEdit: null,
        isLoading: false,
    };
    /**
     * Get selected eventline
     */
    get selectedShowLine(): ?EventLine {
        const {
            match: {
                params: { selected },
            },
            eventLines,
        } = this.props;

        if (selected && eventLines.data && eventLines.data.eventLines) {
            return eventLines.data.eventLines.find(
                line => line.uuid === selected,
            );
        }

        return null;
    }

    /**
     *   Get isShowLineOpen
     *   returns boolean
     **/
    get isShowLineOpen(): boolean {
        const selected = this.selectedShowLine;
        if (selected) {
            if (selected.eventLineStatus === "OPEN") {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    get canEditShowLine(): boolean {
        const { user, eventLineOrganisations } = this.props;
        if (
            user &&
            (user.authorities.includes("ROLE_FACILITY_MANAGER") ||
                user.authorities.includes("ROLE_ADMINISTRATOR"))
        )
            return true;
        let found = false;
        const showLineOrgUuids =
            eventLineOrganisations?.map(org => org.organisationUuid) || [];
        user &&
            showLineOrgUuids.length &&
            user.organisation_leader_organisations?.split(",").forEach(uuid => {
                if (showLineOrgUuids.includes(uuid))
                    found = showLineOrgUuids.includes(uuid);
            });
        return found;
    }

    /**
     *   Get isShowLineExpired
     *   returns boolean
     **/
    get isShowLineExpired(): boolean {
        const selected = this.selectedShowLine;
        if (selected) {
            return moment.utc().isSameOrAfter(moment.utc(selected.end));
        }

        return false;
    }

    toggleLine() {
        const selected = this.selectedShowLine;
        const { toggleLine } = this.props;
        if (selected) {
            if (this.isShowLineOpen) {
                toggleLine(selected.uuid, "close");
            } else {
                toggleLine(selected.uuid, "open");
            }
        }
    }

    selectFirstLine(uuid: string) {
        const { selectedItem } = this.state;
        const { history, root } = this.props;
        if (selectedItem === "" && uuid !== "undefined") {
            this.setState(
                {
                    selectedItem: uuid,
                },
                () => {
                    history.push(`${stripslash(root)}/${uuid}/`);
                },
            );
        }
    }

    /**
     * Load data
     */
    load() {
        const { loadLines } = this.props;
        return loadLines();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        const { clearLines } = this.props;
        clearLines();
        this.load();
        const { selected } = this.props.match.params;
        if (selected) {
            this.setState({ selectedItem: selected });
        }
        this.setState({
            isLoading: false,
        });
    }

    /**
     * After update
     */
    componentDidUpdate() {
        this.load();
        if (this.state.isLoading && !this.state.adding) {
            this.setState({
                isLoading: false,
            });
        }
    }

    componentWillUnmount() {
        const { clearLines } = this.props;
        clearLines();
    }

    /**
     * Render
     */
    render() {
        const {
            eventLines,
            translate,
            acl,
            match,
            history,
            root,
            addLines,
            clearSelected,
            addCustomShowline,
            eventLineAdd,
            eventLineOpenClose,
            customEventLine,
            uuid,
            disabled,
            loadStaffingTemplates,
            clearStaffingTemplates,
            staffingTemplates,
            venueUuid,
            applyStaffingTemplates,
            clearApplyStaffingTemplates,
            staffingTemplatesApply,
            loadSeriesEvents,
            seriesEvents,
            clearSeriesEvents,
            currentEventInSeries,
            eventLineOrganisations,
            planable,
        } = this.props;
        const {
            adding,
            addingCustom,
            active,
            mUuid,
            applyTemplates,
            lineTypeBulkEdit,
            isLoading,
        } = this.state;
        if (!eventLines.uuid || (eventLines.loading && !eventLines.data)) {
            return (
                <div className={styles.progress}>
                    <CircularProgress />
                </div>
            );
        }

        const selected = this.selectedShowLine;
        if (eventLines.data) {
            const eventLinesData = eventLines.data;

            return (
                <>
                    <Grid container>
                        <Grid item sm={5}>
                            <Lines
                                eventLines={eventLinesData.eventLines}
                                id="show-staffing-lines"
                                translate={translate}
                                canAdd={acl("events.lines.create")}
                                selected={match.params.selected}
                                selectFirst={uuid => this.selectFirstLine(uuid)}
                                disabled={disabled}
                                onSelect={uuid => {
                                    if (uuid !== selected.uuid) {
                                        history.push(
                                            `${stripslash(root)}/${uuid}/`,
                                        );
                                    }
                                }}
                                onLineTypeAction={obj =>
                                    this.setState({
                                        lineTypeBulkEdit: obj,
                                    })
                                }
                                onAdd={() =>
                                    this.setState({
                                        adding: true,
                                    })
                                }
                                onAddCustom={() =>
                                    this.setState({
                                        addingCustom: true,
                                    })
                                }
                                onApplyTemplates={() =>
                                    this.setState({ applyTemplates: true })
                                }
                            />
                        </Grid>
                        <Grid item sm={7}>
                            {selected && (
                                <LineStaffing
                                    id="show-linestaffing"
                                    eventLineOpenClose={eventLineOpenClose}
                                    key={`staffing-${selected.uuid}`}
                                    active={this.isShowLineOpen}
                                    eventLine={selected}
                                    translate={translate}
                                    onToggle={this.toggleLine.bind(this)}
                                    canToggle={
                                        !this.isShowLineExpired &&
                                        acl("events.lines.update")
                                    }
                                    withConfirmation
                                >
                                    <>
                                        {acl("events.team.read") && (
                                            <>
                                                <Team
                                                    id="linestaffing-team"
                                                    key={`team-${selected.uuid}`}
                                                    eventLineUuid={
                                                        selected.uuid
                                                    }
                                                    eventUuid={uuid}
                                                    disabled={
                                                        this
                                                            .isShowLineExpired ||
                                                        !eventLineOrganisations?.some(
                                                            organisation =>
                                                                organisation.organisationStatus ===
                                                                "ACTIVE",
                                                        )
                                                    }
                                                    onClick={uuid =>
                                                        this.setState({
                                                            active: true,
                                                            mUuid: uuid,
                                                        })
                                                    }
                                                    active={this.isShowLineOpen}
                                                    planable={planable}
                                                />
                                                <Divider />
                                            </>
                                        )}
                                        {acl("events.organisations.read") && (
                                            <>
                                                <Organisations
                                                    id="linestaffing-organisations"
                                                    key={`organisations-${selected.uuid}`}
                                                    disabled={
                                                        this.isShowLineExpired
                                                    }
                                                    eventLineUuid={
                                                        selected.uuid
                                                    }
                                                    active={this.isShowLineOpen}
                                                />
                                                <Divider />
                                            </>
                                        )}
                                        <EventLineDetails
                                            id="linestaffing-details"
                                            key={`details-${selected.uuid}`}
                                            eventLine={selected}
                                            showuuid={uuid}
                                            disabled={this.isShowLineExpired}
                                            active={this.isShowLineOpen}
                                            canEditShowLine={
                                                this.canEditShowLine
                                            }
                                            loadSeriesEvents={loadSeriesEvents}
                                            clearSeriesEvents={
                                                clearSeriesEvents
                                            }
                                            seriesEvents={seriesEvents}
                                        />
                                        <Divider />
                                    </>
                                </LineStaffing>
                            )}
                        </Grid>
                    </Grid>
                    {!disabled && addingCustom && (
                        <CustomLine
                            busy={customEventLine.loading}
                            customLineState={customEventLine}
                            translate={translate}
                            onClose={() =>
                                this.setState({
                                    addingCustom: false,
                                })
                            }
                            start={
                                eventLinesData &&
                                eventLinesData.eventLines[0] &&
                                eventLinesData.eventLines[0].start
                                    ? eventLinesData.eventLines[0].start
                                    : ""
                            }
                            end={
                                eventLinesData &&
                                eventLinesData.eventLines[0] &&
                                eventLinesData.eventLines[0].end
                                    ? eventLinesData.eventLines[0].end
                                    : ""
                            }
                            onSubmit={customLine => {
                                addCustomShowline(customLine).then(() =>
                                    this.setState({
                                        addingCustom: false,
                                    }),
                                );
                            }}
                            showLineData={
                                eventLinesData && eventLinesData.lines
                            }
                        />
                    )}
                    {!disabled && adding && (
                        <LineSelector
                            lines={linesToSelectorFormat(
                                eventLinesData.lines,
                                eventLineAdd,
                                translate("Default"),
                            )}
                            busy={eventLines.loading}
                            translate={translate}
                            onClose={() =>
                                this.setState({
                                    adding: false,
                                })
                            }
                            onSubmit={lines => {
                                if (!lines.length) {
                                    return;
                                }
                                this.setState({
                                    isLoading: true,
                                });
                                addLines(lines).then(
                                    () =>
                                        this.setState({
                                            adding: false,
                                        }),
                                    clearSelected(),
                                );
                            }}
                            load={() => undefined}
                            excluded={(eventLines.data: any).eventLines.map(
                                line => line.lineUuid,
                            )}
                            isLoading={isLoading}
                        />
                    )}
                    {active && mUuid && (
                        <EditMember
                            uuid={mUuid}
                            disabled={true}
                            onBack={() => {
                                this.setState({
                                    active: false,
                                });
                            }}
                        />
                    )}
                    {!disabled && applyTemplates && (
                        <ApplyTemplates
                            translate={translate}
                            lineTypes={eventLinesData.lines.reduce(
                                (acc, cur) => {
                                    if (
                                        !acc.find(
                                            el => el.uuid === cur.lineTypeUuid,
                                        )
                                    ) {
                                        acc.push({
                                            name: cur.lineTypeName,
                                            uuid: cur.lineTypeUuid,
                                        });
                                    }

                                    return acc;
                                },
                                [],
                            )}
                            staffingTemplates={
                                staffingTemplates.data?.staffingTemplates
                            }
                            onClose={() => {
                                this.setState({
                                    applyTemplates: false,
                                });
                                clearStaffingTemplates();
                                clearSeriesEvents();
                            }}
                            onInit={lineTypeUuids => {
                                loadStaffingTemplates(venueUuid, lineTypeUuids);
                            }}
                            onApply={data => {
                                applyStaffingTemplates(data).then(() => {
                                    clearApplyStaffingTemplates();
                                    this.setState({
                                        applyTemplates: false,
                                    });
                                });
                            }}
                            busy={
                                seriesEvents.loading ||
                                staffingTemplatesApply.loading
                            }
                            onApplySeries={() => {
                                loadSeriesEvents();
                            }}
                            events={seriesEvents.data?.events}
                            currentEventDisplayName={
                                currentEventInSeries &&
                                `${moment(currentEventInSeries.start).format(
                                    "DD/MM/YYYY - HH:mm",
                                )} - ${currentEventInSeries.venueCode} - ${
                                    currentEventInSeries.name
                                } - ${currentEventInSeries.seriesName}`
                            }
                        />
                    )}
                    {!disabled && lineTypeBulkEdit && (
                        <LineTypeBulkEdit
                            onClose={() => {
                                this.setState({
                                    lineTypeBulkEdit: null,
                                });
                                clearSeriesEvents();
                            }}
                            onInit={loadSeriesEvents}
                            events={seriesEvents.data?.events}
                            uuid={uuid}
                            {...lineTypeBulkEdit}
                        />
                    )}
                </>
            );
        }

        return (
            <Grid container spacing={3} justifyContent="center">
                <Grid item sm={6} xs={12}>
                    <Typography align="center" color="error">
                        {eventLines.error}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                // Map state to props
                (
                    {
                        eventLines,
                        eventLineAdd,
                        eventLineOpenClose,
                        customEventLine,
                        user,
                        eventLineOrganisations,
                        venueStaffingTemplates,
                        staffingTemplatesApply,
                        seriesEvents,
                    },
                    { venueUuid, uuid },
                ) => ({
                    eventLines: {
                        ...mapState(eventLines, SHOW_LINE_ERROR),
                        uuid:
                            eventLines.params &&
                            eventLines.params.path &&
                            eventLines.params.path.uuid,
                    },
                    eventLineOpenClose: mapState(
                        eventLineOpenClose,
                        SHOWLINE_OPEN_CLOSE_ERROR,
                    ),
                    eventLineAdd: {
                        ...mapState(eventLineAdd, SHOW_LINE_ADD_ERROR),
                        error: eventLineAdd.error
                            ? (eventLineAdd.error.messages &&
                                  eventLineAdd.error.messages.linesUuids) ||
                              eventLineAdd.error.message ||
                              SHOW_LINE_ADD_ERROR
                            : null,
                    },
                    customEventLine: mapState(
                        customEventLine,
                        CUSTOM_SHOW_LINE_ERROR,
                    ),
                    user:
                        user &&
                        user.get("data") &&
                        user.getIn(["data", "profile"]).toJS(),
                    eventLineOrganisations: eventLineOrganisations.toJS().data,
                    staffingTemplates: mapState(
                        venueStaffingTemplates,
                        VENUE_STAFFING_TEMPLATES_ERROR,
                    ),
                    staffingTemplatesApply: mapState(
                        staffingTemplatesApply,
                        APPLY_STAFFING_TEMPLATES_ERROR,
                    ),
                    currentEventInSeries: seriesEvents.data?.events.find(
                        event =>
                            event.venueUuid === venueUuid &&
                            event.uuid === uuid,
                    ),
                    seriesEvents: mapState(
                        {
                            ...seriesEvents,
                            ...(seriesEvents.data
                                ? {
                                      data: {
                                          events: seriesEvents.data.events
                                              .filter(
                                                  event =>
                                                      event.venueUuid ===
                                                          venueUuid &&
                                                      event.uuid !== uuid,
                                              )
                                              .map(event => ({
                                                  ...event,
                                                  fullDisplayName: `${moment(
                                                      event.start,
                                                  ).format(
                                                      "DD/MM/YYYY - HH:mm",
                                                  )} - ${event.venueCode} - ${
                                                      event.name
                                                  } - ${event.seriesName}`,
                                              })),
                                      },
                                  }
                                : {}),
                        },
                        SERIES_EVENTS_ERROR,
                    ),
                }),
                // Map dispatch to props
                (dispatch: *, { uuid, seriesUuid }) => ({
                    ...bindActionCreators(
                        {
                            clearLines,
                            clearSelected,
                            loadStaffingTemplates,
                            clearStaffingTemplates,
                            clearApplyStaffingTemplates,
                            clearSeriesEvents,
                            reloadTeam,
                        },
                        dispatch,
                    ),
                    loadLines: () => dispatch(loadLines(uuid)),
                    addCustomShowline: customline =>
                        dispatch(addCustomShowline(uuid, customline)).then(() =>
                            dispatch(loadLines(uuid, true)),
                        ),
                    toggleLine: (lineuuid, status) =>
                        dispatch(toggleLine(lineuuid, status)).then(() => {
                            dispatch(loadLines(uuid, true));
                            dispatch(reloadTeam(lineuuid, true));
                        }),

                    addLines: lines =>
                        dispatch(addLines(uuid, lines)).then(() =>
                            dispatch(loadLines(uuid, true)),
                        ),
                    applyStaffingTemplates: data =>
                        dispatch(applyStaffingTemplates(uuid, data)).then(() =>
                            dispatch(loadLines(uuid, true)),
                        ),
                    loadSeriesEvents: () =>
                        dispatch(loadSeriesEvents(seriesUuid)),
                }),
            )(Staffing),
        ),
    ),
);
