// @flow
// Import styles
import styles from "./DeleteUser.scss";

import React, { Component, Fragment } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { type State as AdminUserState } from "../../../redux/modules/admin-user.d";
import { type State as DeleteUserState } from "../../../redux/modules/admin-user-delete.d";

type Props = {
    translate: *,
    adminuser: AdminUserState,
    deleteUserState: DeleteUserState,
    onSubmit: () => *,
    onClose: () => void,
};

export default class DeleteUser extends Component<Props> {
    /**
     * Name getter
     */
    get name(): string {
        const { adminuser, translate } = this.props;

        if (!adminuser.data) {
            return "...";
        }

        if (adminuser.data.firstName || adminuser.data.lastName) {
            return `${adminuser.data.firstName || ""} ${
                adminuser.data.lastName || ""
            }`.trim();
        }

        return translate("(Unknown)");
    }

    /**
     * Render
     */
    render() {
        const { onSubmit, onClose, adminuser, translate, deleteUserState } =
            this.props;

        const msg = translate(
            "Are you sure you want to permanently delete " +
                "%{name} from At Your Service?",
            { name: this.name },
        );

        return (
            <Dialog onClose={onClose} open>
                <DialogTitle disableTypography>
                    <Typography variant="h2">
                        {translate("Delete user")}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    {deleteUserState.error && (
                        <DialogContentText color="error">
                            {deleteUserState.error}
                        </DialogContentText>
                    )}
                    {adminuser.data && (
                        <Fragment>
                            <DialogContentText
                                dangerouslySetInnerHTML={{ __html: msg }}
                                className={styles.msg}
                            ></DialogContentText>
                            <DialogContentText>
                                {translate("(This action cannot be undone.)")}
                            </DialogContentText>
                        </Fragment>
                    )}
                    {adminuser.loading && (
                        <div style={{ textAlign: "center", margin: "1em 0" }}>
                            <CircularProgress />
                        </div>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button
                        id="modal-deleteuser-cancel"
                        onClick={onClose}
                        disabled={adminuser.loading}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id="modal-deleteuser-confirm"
                        onClick={onSubmit}
                        color="secondary"
                        disabled={adminuser.loading}
                    >
                        {translate("Delete user")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
