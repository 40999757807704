// @flow
import { connect } from "react-redux";
import moment from "moment";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
import { mapState } from "../../../redux/utils";

import { load as loadLines } from "../../../redux/modules/event-lines";

import {
    load as bulkUpdateOccupation,
    clear as clearBulkUpdateOccupation,
    ERROR as OCCUPATION_BULK_UPDATE_ERROR,
} from "../../../redux/modules/event-linetype-bulk-update";

import LineTypeBulkEdit from "../../../components/Events/LineTypeBulkEdit/LineTypeBulkEdit";

const ENTITY_MAP = {
    updateOccupation: "occupation",
    updateHours: "hours",
    updateBriefings: "briefings",
};

export default withAcl(
    withTranslate(
        connect(
            // Map state to props
            ({ eventDetails, eventLineTypeBulkUpdate }) => ({
                eventHours: {
                    start: moment(eventDetails.data.start).format("hh:mm"),
                    end: moment(eventDetails.data.end).format("hh:mm"),
                },
                bulkUpdateState: mapState(
                    eventLineTypeBulkUpdate,
                    OCCUPATION_BULK_UPDATE_ERROR,
                ),
            }),
            // Map dispatch to props
            (dispatch: *, { action, uuid }) => ({
                update: (lineTypeUuid, data) =>
                    dispatch(
                        bulkUpdateOccupation(
                            uuid,
                            lineTypeUuid,
                            data,
                            ENTITY_MAP[action],
                        ),
                    ).then(() => dispatch(loadLines(uuid, true))),
                clear: () => dispatch(clearBulkUpdateOccupation()),
            }),
        )(LineTypeBulkEdit),
    ),
);
