// @flow

import styles from "./Login.scss";

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";

type Props = {
    translate: *,
    onSubmit: (value: string) => void,
    error: ?string,
    busy: boolean,
    forgottenLink: string,
};

type State = {
    value: string,
    shown: boolean,
};

export default class Password extends Component<Props, State> {
    state = {
        value: "",
        shown: false,
    };

    render() {
        const { onSubmit, error, busy, forgottenLink, translate } = this.props;
        const { value, shown } = this.state;

        return (
            <form
                className={styles.form}
                onSubmit={event => {
                    event.preventDefault();
                    onSubmit(value);
                }}
            >
                <Typography variant="h2">
                    {translate("Nice to see you again")}
                </Typography>
                <Typography>
                    {translate(
                        "Confirm your identity with your password " +
                            "below in order to continue.",
                    )}
                </Typography>
                <div className={styles.cell}>
                    <FormControl error={!!error} disabled={busy} fullWidth>
                        <InputLabel htmlFor="login-password-password">
                            {translate("Password")}
                        </InputLabel>
                        <Input
                            id="login-password-password"
                            type={shown ? "text" : "password"}
                            value={value}
                            onChange={({ target: { value } }) =>
                                this.setState({ value })
                            }
                            autoFocus
                            autocomplete="current-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        id="login-password-password-show-hide"
                                        onClick={() =>
                                            this.setState({ shown: !shown })
                                        }
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        {shown ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {error && (
                            <FormHelperText id="login-password-password-helper-text">
                                {error}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
                <div className={styles.cell}>
                    <Link to={forgottenLink} id="login-password-forgot">
                        <Typography>
                            {translate("Forgot your password?")}
                        </Typography>
                    </Link>
                </div>
                <div className={styles.cell}>
                    <Button
                        id="login-password-submit"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={busy}
                    >
                        {translate("Get started")}
                    </Button>
                </div>
            </form>
        );
    }
}
