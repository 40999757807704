// @flow

import { combineReducers } from "redux";

import members from "./members";
import member from "./member";
import organisations from "./organisations";
import organisationAdd from "./organisation-add";
import organisationlines from "./organisationlines";
import user from "./user";
import language from "./language";
import lines from "./lines";
import details from "./details";
import detailsUpdate from "./details-update";
import detailsMemberAdd from "./details-member-add";
import shows from "./shows";
import venues from "./venues";
import templates from "./templates";
import events from "./events";
import admin from "./admin";
import adminUser from "./admin-user";
import adminUserMemberships from "./admin-user-memberships";
import addShow from "./add-show";
import addEvent from "./add-event";
import seriesDetails from "./series-details";
import eventDetails from "./event-details";
import eventLines from "./event-lines";
import eventLineTeam from "./event-line-team";
import eventLineDefaultOrganisation from "./event-line-default-organisation";
import setEventLineDefaultOrganisation from "./set-event-line-default-organisation";
import eventLineDetails from "./event-line-details";
import eventLineOrganisations from "./event-line-organisations";
import eventLineMemberCandidates from "./event-line-member-candidates";
import showLineMemberPhoneNumbers from "./event-line-member-phonenumbers";
import eventLineAdd from "./event-line-add";
import eventRecruitment from "./event-recruitment";
import adminUserAdd from "./admin-user-add";
import adminUserEdit from "./admin-user-edit";
import adminUserDelete from "./admin-user-delete";
import eventQRCode from "./event-qrcode";
import eventCSVData from "./event-csvdata";
import eventDetailsUpdate from "./event-details-update";
import eventMoveUpdate from "./event-move";
import showRecruitmentAdd from "./event-recruitment-add";
import eventLineOpenClose from "./event-line-openclose";
import showRecruitmentDelete from "./event-recruitment-delete";
import showRecruitmentUpdate from "./event-recruitment-update";
import eventRecruits from "./event-recruits";
import showRecruitsAdd from "./event-recruits-add";
import eventIssues from "./event-issues";
import organisationDelete from "./organisation-delete";
import eventMemberQRCode from "./event-line-member-qrcode";
import eventRecruitsUpdate from "./event-recruits-update";
import eventRecruitsDelete from "./event-recruits-delete";
import showRecruitsInterested from "./event-recruits-interested";
import eventDelete from "./event-delete";
import venueParking from "./venue-parking";
import customEventLine from "./event-line-custom";
import eventBriefing from "./event-briefing";
import updateEventBriefing from "./update-event-briefing";
import eventLinesBriefing from "./event-linetype-briefing";
import notifications from "./notifications";
import attendees from "./attendees";
import attendeesTotals from "./attendees-total";
import attendeesQRCode from "./attendees-qrcode";
import eventRecruitmentAddAll from "./event-recruits-add-all";
import facilityViewVenues from "./facility-view-venues";
import facilityViewEvents from "./facility-view-events";
import calendar from "./calendar";
import option from "./option";
import options from "./options";
import confirmOptions from "./confirm-options";
import editOption from "./edit-option";
import organisers from "./organisers";
import addOption from "./add-option";
import addSerie from "./add-serie";
import addPromoter from "./add-promoter";
import editSeries from "./edit-series";
import editConfirmedOption from "./edit-confirmed-option";
import confirmedOptions from "./confirmed-options";
import allTemplates from "./all-templates";
import defineEvents from "./define-events";
import exportData from "./export-data";
import cancelEvent from "./cancel-event";
import series from "./series";
import staffingTemplates from "./staffing-templates";
import staffingTemplateDelete from "./staffing-template-delete";
import facilitiesLineTypes from "./facilities-linetypes";
import addStaffingTemplate from "./add-staffing-template";
import staffingTemplateDetails from "./staffing-template-details";
import staffingTemplateLines from "./staffing-template-lines";
import addStaffingTemplateLines from "./add-staffing-template-lines";
import updateLineStaffingTemplate from "./update-line-staffing-template";
import venueStaffingTemplates from "./venue-staffing-templates";
import staffingTemplatesApply from "./apply-staffing-templates";
import updateStaffingTemplateName from "./update-staffing-template-name";
import seriesEvents from "./series-events";
import seriesEventsPaged from "./series-events-paged";
import staffingTemplateLineDelete from "./staffing-template-line-delete";
import eventLineTypeBulkUpdate from "./event-linetype-bulk-update";
import eventHistory from "./event-history";
import eventSeries from "./event-series";
import eventSecurityReport from "./event-security-report";
import eventDetailsPlanable from "./event-detail-planable";

/**
 * The root reducer
 */
export default combineReducers<any, any>({
    language,
    lines,
    organisations,
    organisationAdd,
    members,
    member,
    user,
    details,
    detailsMemberAdd,
    detailsUpdate,
    organisationlines,
    shows,
    venues,
    templates,
    events,
    admin,
    adminUser,
    addShow,
    addEvent,
    seriesDetails,
    eventDetails,
    eventLines,
    eventLineTeam,
    eventLineDefaultOrganisation,
    setEventLineDefaultOrganisation,
    eventLineDetails,
    eventLineOrganisations,
    eventLineMemberCandidates,
    eventLineAdd,
    showLineMemberPhoneNumbers,
    eventRecruitment,
    adminUserAdd,
    adminUserEdit,
    adminUserDelete,
    adminUserMemberships,
    eventQRCode,
    eventCSVData,
    eventDetailsUpdate,
    eventMoveUpdate,
    showRecruitmentAdd,
    eventLineOpenClose,
    showRecruitmentDelete,
    showRecruitmentUpdate,
    eventRecruits,
    showRecruitsAdd,
    eventIssues,
    organisationDelete,
    eventMemberQRCode,
    eventRecruitsUpdate,
    eventRecruitsDelete,
    showRecruitsInterested,
    eventDelete,
    venueParking,
    customEventLine,
    eventBriefing,
    updateEventBriefing,
    eventLinesBriefing,
    notifications,
    attendees,
    attendeesTotals,
    attendeesQRCode,
    eventRecruitmentAddAll,
    facilityViewVenues,
    facilityViewEvents,
    calendar,
    option,
    options,
    confirmOptions,
    editOption,
    organisers,
    addOption,
    addSerie,
    addPromoter,
    editSeries,
    editConfirmedOption,
    confirmedOptions,
    allTemplates,
    defineEvents,
    exportData,
    cancelEvent,
    series,
    staffingTemplates,
    staffingTemplateDelete,
    facilitiesLineTypes,
    addStaffingTemplate,
    staffingTemplateDetails,
    staffingTemplateLines,
    addStaffingTemplateLines,
    updateLineStaffingTemplate,
    venueStaffingTemplates,
    staffingTemplatesApply,
    updateStaffingTemplateName,
    seriesEvents,
    seriesEventsPaged,
    staffingTemplateLineDelete,
    eventLineTypeBulkUpdate,
    eventHistory,
    eventSeries,
    eventSecurityReport,
    eventDetailsPlanable,
});
