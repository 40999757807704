// @flow
import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "facilityViewVenues",
    () => "ays-searches/searches/facilityview/venues",
);

export const load = actions.load;
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not fetch venues";
// reducer
export default reducer;
