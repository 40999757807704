// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IEmYjHtkNvW5wUSwSxBggA\\=\\={min-width:100%}.IEmYjHtkNvW5wUSwSxBggA\\=\\= .lKtiokCvpzxvybb-n6gIkQ\\=\\={height:28em;overflow:auto}.IEmYjHtkNvW5wUSwSxBggA\\=\\= .FJYqanVEvDi\\+BQdH1g5KYg\\=\\={height:3em;text-align:center}.OccjXTuOnpUXyuwjT0yN6A\\=\\={width:32em !important;display:flex;justify-content:center}.OccjXTuOnpUXyuwjT0yN6A\\=\\= .wOxOuw2bzrmnvlR6cTqGJA\\=\\={height:31.813em;width:100%;padding:1em}.eFHGW7oOyjUbLOOWOZA\\+DQ\\=\\=>ul{justify-content:center}.eFHGW7oOyjUbLOOWOZA\\+DQ\\=\\=>ul .VKBKyikNct2fIEGXhspYWw\\=\\={background-color:cyan}\n", "",{"version":3,"sources":["webpack://./../Move.scss"],"names":[],"mappings":"AAAA,4BACI,cAAe,CADnB,wDAGQ,WAAY,CACZ,aAAc,CAJtB,yDAQQ,UAAW,CACX,iBAAkB,CACrB,4BAID,qBAAsB,CACtB,YAAa,CACb,sBAAuB,CAH3B,wDAMQ,eAAgB,CAChB,UAAW,CACX,WAAY,CACf,gCAIG,sBAAuB,CAF/B,4DAIY,qBAAsB","sourcesContent":[".card {\n    min-width: 100%;\n    .list {\n        height: 28em;\n        overflow: auto;\n    }\n\n    .loader {\n        height: 3em;\n        text-align: center;\n    }\n}\n\n.listWrapper {\n    width: 32em !important;\n    display: flex;\n    justify-content: center;\n\n    .select {\n        height: 31.813em;\n        width: 100%;\n        padding: 1em;\n    }\n}\n.pagination {\n    > ul {\n        justify-content: center;\n        .Mui-selected {\n            background-color: cyan;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "IEmYjHtkNvW5wUSwSxBggA==",
	"list": "lKtiokCvpzxvybb-n6gIkQ==",
	"loader": "FJYqanVEvDi+BQdH1g5KYg==",
	"listWrapper": "OccjXTuOnpUXyuwjT0yN6A==",
	"select": "wOxOuw2bzrmnvlR6cTqGJA==",
	"pagination": "eFHGW7oOyjUbLOOWOZA+DQ==",
	"Mui-selected": "VKBKyikNct2fIEGXhspYWw=="
};
export default ___CSS_LOADER_EXPORT___;
