// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._50Vfs6njpzRjRM0y4N-elA\\=\\= :first-child>div{display:flex}._50Vfs6njpzRjRM0y4N-elA\\=\\= :first-child>div span{align-self:center}.cMS4D16Ez4xJtla7q\\+3b7w\\=\\= p{color:white}.V55W44fJ-THGJpf3V9hjGw\\=\\= p{color:black}\n", "",{"version":3,"sources":["webpack://./../SelectList.scss"],"names":[],"mappings":"AAAA,8CAGY,YAAa,CAHzB,mDAKgB,iBAAkB,CACrB,+BAML,WAAY,CACf,8BAIG,WAAY","sourcesContent":[".element {\n    & :first-child {\n        & > div {\n            display: flex;\n            span {\n                align-self: center;\n            }\n        }\n    }\n}\n.light {\n    p {\n        color: white;\n    }\n}\n.dark {\n    p {\n        color: black;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "_50Vfs6njpzRjRM0y4N-elA==",
	"light": "cMS4D16Ez4xJtla7q+3b7w==",
	"dark": "V55W44fJ-THGJpf3V9hjGw=="
};
export default ___CSS_LOADER_EXPORT___;
