// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A6h4bGAeOavpuY-6QalPvQ\\=\\={text-align:center}.A6h4bGAeOavpuY-6QalPvQ\\=\\=>div{margin:1em}.OhLs5\\+9jAMaREMelbZCNqQ\\=\\=>:last-child{display:table}.OhLs5\\+9jAMaREMelbZCNqQ\\=\\= form>:first-child{border-bottom:0.01em solid #ccc;padding-top:0}.OhLs5\\+9jAMaREMelbZCNqQ\\=\\= .W6JSMnW55DG66mKpRD86dg\\=\\= *{color:white}.OhLs5\\+9jAMaREMelbZCNqQ\\=\\= .-NUL\\+vM3jV0xDr-Nn3MVtQ\\=\\= *{color:white;opacity:0.5}.mvi14vxIX1eEwTf6jabPDA\\=\\={color:#fff;background-color:#298fc2}\n", "",{"version":3,"sources":["webpack://./../DefineEvents.scss","webpack://./../../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,4BACI,iBAAkB,CADtB,gCAGQ,UAAW,CACd,yCAKG,aAAc,CAFtB,+CAOY,+BCNK,CDOL,aAAc,CAR1B,2DAcY,WAAY,CAdxB,4DAoBY,WAAY,CACZ,WAAY,CACf,4BAKL,UChCQ,CDiCR,wBC1Be","sourcesContent":["@import \"../../../styles/variables\";\n\n.spinner {\n    text-align: center;\n    & > div {\n        margin: 1em;\n    }\n}\n\n.element {\n    > :last-child {\n        display: table;\n    }\n\n    form {\n        & > :first-child {\n            border-bottom: 0.01em solid $light-gray;\n            padding-top: 0;\n        }\n    }\n\n    .enabledInput {\n        * {\n            color: white;\n        }\n    }\n\n    .disabledInput {\n        * {\n            color: white;\n            opacity: 0.5;\n        }\n    }\n}\n\n.title {\n    color: $white;\n    background-color: $dark_blue;\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "A6h4bGAeOavpuY-6QalPvQ==",
	"element": "OhLs5+9jAMaREMelbZCNqQ==",
	"enabledInput": "W6JSMnW55DG66mKpRD86dg==",
	"disabledInput": "-NUL+vM3jV0xDr-Nn3MVtQ==",
	"title": "mvi14vxIX1eEwTf6jabPDA=="
};
export default ___CSS_LOADER_EXPORT___;
