//@flow
import styles from "./Attendees.scss";

import React, { Component } from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "../../Table/Table";

import { type Attendees } from "../../../redux/modules/attendees.d";
import { type State as TotalsState } from "../../../redux/modules/attendees-total.d";
import { type State as attendeeQRState } from "../../../redux/modules/attendees-qrcode.d";
import AttendeePDFModal from "../../Modals/AttendeePDFModal/AttendeePDFModal.js";
import AttendeeDetail from "../../Modals/AttendeeDetails/AttendeeDetail.js";

type Props = {
    acl: *,
    translate: *,
    loading: boolean,
    error: ?string,
    data: ?Attendees,
    page: number,
    onPageChange: (n: number) => void,
    onSortChange: (sortString: string[]) => void,
    onPresenceChange: (prescence: string) => void,
    loadAttendeeQR: (uuid: string, eventUuid: string) => void,
    totals: TotalsState,
    qrcode: attendeeQRState,
    tab: number,
    uuid: string,
    onTabChange: (t: number) => void,
    filter: string,
};

type State = {
    qrOpen: boolean,
    currentAttendee: *,
};

export default class AttendeesList extends Component<Props, State> {
    // Initial state
    state = {
        qrOpen: false,
        currentAttendee: null,
    };

    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }

    handleChange = (event: any) => {
        const { onPresenceChange } = this.props;
        onPresenceChange(event.target.value);
    };

    handleQRCode(uuid: string, eventUuid: string) {
        const { loadAttendeeQR } = this.props;
        loadAttendeeQR(uuid, eventUuid);
        this.toggleQROpen();
    }

    toggleQROpen() {
        this.setState({
            qrOpen: !this.state.qrOpen,
        });
    }

    get onlyError(): boolean {
        const { data, error } = this.props;

        return !!(!data && error);
    }

    render() {
        const {
            page,
            data,
            error,
            loading,
            translate,
            uuid,
            acl,
            onPageChange,
            onSortChange,
            totals,
            tab,
            onTabChange,
            qrcode,
            filter,
        } = this.props;
        const { qrOpen, currentAttendee } = this.state;

        const getBorderColor = item => {
            switch (item.presenceStatus) {
                case "CHECKED_IN":
                    return "#56c271";
                case "CHECKED_OUT":
                    return "#f00";
                case "NOT_CHECKED":
                    return "#298fc2";
                default:
                    return "#f00";
            }
        };

        const config = {
            id: "attendees-list",
            uuidKey: "userUuid",
            hasMenu: true,
            hasTabs: true,
            tabOptions: [
                {
                    name: translate("All"),
                    amount:
                        totals && totals.data ? totals.data.totalExpected : 0,
                },
                {
                    name: translate("Checked in"),
                    amount:
                        totals && totals.data ? totals.data.totalPresent : 0,
                },
                {
                    name: translate("Checked out"),
                    amount:
                        totals && totals.data ? totals.data.totalCheckedOut : 0,
                },
                {
                    name: translate("Not present"),
                    amount:
                        totals && totals.data ? totals.data.totalNotPresent : 0,
                },
            ],
            onClick: item => {
                this.setState({ currentAttendee: item });
            },
            configItems: [
                {
                    key: "lastName",
                    customRender: (item: Object) => {
                        return (
                            <span className={styles.user}>
                                <img
                                    alt={`${item.firstName} ${item.lastName}`}
                                    src={`${item.profilePicturePath}/64x64.png`}
                                    className={styles.avatar}
                                    style={{
                                        borderStyle: "solid",
                                        borderWidth: "4px",
                                        borderColor: getBorderColor(item),
                                    }}
                                />
                                <span
                                    className={classnames({
                                        [styles.guest]: item.guest === true,
                                    })}
                                >{`${item.firstName} ${item.lastName} ${
                                    item.guest ? translate("(Guest)") : ""
                                }`}</span>
                            </span>
                        );
                    },
                    displayName: translate("Name"),
                    sortable: true,
                    defaultSort: "asc",
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "phoneNumber",
                    displayName: translate("Phone Number"),
                    sortable: false,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "organisations",
                    displayName: translate("Organisation"),
                    sortable: false,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "lines",
                    displayName: translate("Line"),
                    sortable: false,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "geofencingStatus",
                    displayName: translate("Geolocation"),
                    sortable: false,
                    isNumeric: false,
                    permission: true,
                },
            ],
            menuOptions: [
                {
                    id: `qrcode-member`,
                    label: () => translate("Generate QR Code"),
                    onClick: item => {
                        this.handleQRCode(item.userUuid, uuid);
                    },
                    permission: () => acl("events.team.qrcode"),
                },
            ],
        };

        return (
            <div className={styles.main}>
                {acl("events.attendees.read") && (
                    <Table
                        config={config}
                        tab={tab}
                        onTabChange={t => {
                            onTabChange(t);
                            onPageChange(1);
                        }}
                        loading={loading}
                        data={data}
                        page={page}
                        error={error}
                        translate={translate}
                        getSortString={string => onSortChange(string)}
                        onPageChange={p => onPageChange(p + 1)}
                        filter={filter}
                    />
                )}
                {loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
                {qrOpen && (
                    <AttendeePDFModal
                        translate={translate}
                        disabled={false}
                        attendee={qrcode}
                        onClose={() =>
                            this.setState({
                                qrOpen: false,
                            })
                        }
                    />
                )}

                {currentAttendee ? (
                    <AttendeeDetail
                        item={currentAttendee}
                        onClose={() => this.setState({ currentAttendee: null })}
                        translate={translate}
                    />
                ) : undefined}
                {this.onlyError && (
                    <Paper elevation={2}>
                        <Typography color="error">{error}</Typography>
                    </Paper>
                )}
            </div>
        );
    }
}
