// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uCYHJwYfggoEdByZ\\+gjViA\\=\\={cursor:default;display:flex;align-items:center;justify-content:center;align-content:center;border-radius:45%;width:1.5em;height:1.5em}.uCYHJwYfggoEdByZ\\+gjViA\\=\\= .ibXccLdQS78BkRfMhOLu1g\\=\\={font-size:0.8em;text-align:center;display:block;color:#fff}.uCYHJwYfggoEdByZ\\+gjViA\\=\\= .-R1bSXPwtFfaHTtlhba1WA\\=\\={font-size:1.25em;text-align:center;display:block;color:#fff}\n", "",{"version":3,"sources":["webpack://./../Tasks.scss","webpack://./../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,6BACI,cAAe,CACf,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,oBAAqB,CACrB,iBAAkB,CAClB,WAAY,CACZ,YAAa,CARjB,yDAWQ,eAAgB,CAChB,iBAAkB,CAClB,aAAc,CACd,UCZI,CDFZ,yDAiBQ,gBAAiB,CACjB,iBAAkB,CAClB,aAAc,CACd,UClBI","sourcesContent":["@import \"../../styles/variables\";\n\n.task {\n    cursor: default;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    align-content: center;\n    border-radius: 45%;\n    width: 1.5em;\n    height: 1.5em;\n\n    .inner {\n        font-size: 0.8em;\n        text-align: center;\n        display: block;\n        color: $white;\n    }\n    .icon {\n        font-size: 1.25em;\n        text-align: center;\n        display: block;\n        color: $white;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"task": "uCYHJwYfggoEdByZ+gjViA==",
	"inner": "ibXccLdQS78BkRfMhOLu1g==",
	"icon": "-R1bSXPwtFfaHTtlhba1WA=="
};
export default ___CSS_LOADER_EXPORT___;
