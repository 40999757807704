// @flow

import React from "react";

import styles from "./PoolRecruit.scss";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { type Recruit } from "../../../redux/modules/event-recruits.d";

import IconMenu from "../../IconMenu";

type Props = {
    id: string,
    index: number,
    recruit: Recruit,
    canEdit: boolean,
    canDelete: boolean,
    onUpdate: (state: string) => *,
    onDelete: () => *,
    disabled: boolean,
    translate: *,
};

const STATES = [
    "PENDING",
    "PLANNED",
    "RESERVE",
    "NOT_PLANNED",
    "DECLINED_RESERVE",
    "DECLINED",
];

/**
 * Get options
 */
const getOptions = (id, translate, canEdit, canDelete, onUpdate, onDelete) => {
    const result = [];

    if (canEdit) {
        result.push(
            ...STATES.map(state => ({
                id: `${id}-${state.toLowerCase()}`,
                label: translate("Set to %{state}", { state }),
                onClick: () => onUpdate(state),
            })),
        );
    }

    if (canDelete) {
        result.push({
            id: `${id}-delete`,
            label: translate("Delete"),
            onClick: () => onDelete(),
        });
    }

    return result;
};

/**
 * A pool recruit record
 */
const PoolRecruit = ({
    id,
    index,
    translate,
    recruit,
    disabled,
    onUpdate,
    onDelete,
    canEdit,
    canDelete,
}: Props) => (
    <TableRow id={`${id}-item-${index}`}>
        <TableCell>
            {" "}
            <span className={styles.user}>
                <img
                    alt={`${recruit.firstName} ${recruit.lastName}`}
                    src={`${recruit.profilePicturePath}/64x64.png`}
                    className={styles.avatar}
                />
                <span>{`${recruit.firstName} ${recruit.lastName}`}</span>
            </span>
        </TableCell>
        <TableCell>{recruit.recruitmentStatus || "-"}</TableCell>
        <TableCell>{recruit.staffingStatus || "-"}</TableCell>
        <TableCell align="right">
            {(canEdit || canDelete) && (
                <IconMenu
                    id={`${id}-menu-${index}`}
                    disabled={disabled}
                    options={getOptions(
                        id,
                        translate,
                        canEdit,
                        canDelete,
                        onUpdate,
                        onDelete,
                    )}
                />
            )}
        </TableCell>
    </TableRow>
);

export default PoolRecruit;
