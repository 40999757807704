//@flow
import styles from "./styles.scss";
import classnames from "classnames";
import React from "react";

type Props = {
    status:
        | "OPTION_CREATED"
        | "OPTION_CONFIRMED"
        | "PRIVATE"
        | "PUBLIC"
        | "LIMITED_AVAILABILITY"
        | "OPTION_CANCELED"
        | "CANCELED",
    color?: string,
};

/**
 * convert Hex to HSL
 */

const convertHex = (hex, opacity) => {
    if (!hex) return;

    hex = hex.replace("#", "");
    return (
        "rgba(" +
        parseInt(hex.substring(0, 2), 16) +
        "," +
        parseInt(hex.substring(2, 4), 16) +
        "," +
        parseInt(hex.substring(4, 6), 16) +
        "," +
        opacity / 100 +
        ")"
    );
};

const CalenderIcon = ({ status, color }: Props) => (
    <span
        className={classnames(
            styles.element,
            status === "OPTION_CREATED" && styles.miniCircle,
            status === "OPTION_CONFIRMED" && styles.filledCircle,
            status === "LIMITED_AVAILABILITY" && styles.halfFilledCircle,
            status === "PUBLIC" && styles.square,
            status === "PRIVATE" && styles.squareWithLetter,
            status === "OPTION_CANCELED" && styles.canceled,
            status === "CANCELED" && styles.canceledSquare,
        )}
        style={
            status === "OPTION_CONFIRMED" ||
            status === "LIMITED_AVAILABILITY" ||
            status === "OPTION_CREATED"
                ? {
                      backgroundColor: convertHex(color, 70),
                      borderColor: convertHex(color, 70),
                      backgroundClip: "padding-box",
                  }
                : {
                      backgroundColor: color,
                      borderColor: color,
                  }
        }
    >
        {status === "LIMITED_AVAILABILITY" && (
            <span className={styles.halfFilled} />
        )}

        {status === "PRIVATE" && <p>P</p>}
        {status === "CANCELED" && <p>x</p>}
        {status === "OPTION_CANCELED" && <p>⨉</p>}
    </span>
);

export default CalenderIcon;
