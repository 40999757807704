// @flow

//import styles
import styles from "./Options.scss";

//import libs
import React, { Component } from "react";
import get from "lodash.get";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
//import Components
import Option from "./Option";

type Props = {
    translate: *,
    /** Trigger */
    onSubmit: (option: *) => void,
    options: *,
    confirmOptionsState?: *,
    seriesName: string,
    disabled: boolean,
    showPast: boolean,
    optionCode: string,
};

type State = {
    seriesName: string,
    options: *,
    loading: boolean,
};

/**
 * Add dialog
 */
export default class ConfirmOptions extends Component<Props, State> {
    state = {
        options: this.props.options,
        seriesName: this.props.seriesName,
        loading: false,
    };

    /**
     * change handler
     **/
    handleChange = (field: string, value: string, index: number) => {
        const { options } = this.state;
        this.setState({
            options: options.map((option, optionIndex) => {
                if (index === optionIndex) {
                    return {
                        ...option,
                        [field]: value,
                    };
                }

                return option;
            }),
        });
    };

    /**
     * ensure default status
     */
    ensureDefaultStatus() {
        const { options } = this.state;

        this.setState({
            options: options.map(option => ({
                ...option,
                status: "OPTION_CREATED",
            })),
        });
    }

    componentDidMount() {
        this.ensureDefaultStatus();
    }

    /**
     * Error handler
     */
    getError = (key: string, index: *) => {
        const { confirmOptionsState } = this.props;

        let transformedKey = key;
        if (confirmOptionsState && confirmOptionsState.fieldErrors) {
            return get(
                confirmOptionsState.fieldErrors,
                `options[${index}].${transformedKey}`,
            );
        }
    };

    /**
     * Set Status for all options
     */
    onChangeAll = (value: string) => {
        const { options } = this.state;
        const { showPast } = this.props;

        if (showPast) {
            this.setState({
                options: options.map(option => ({
                    ...option,
                    status: value ? value : option.status,
                })),
            });
        } else {
            this.setState({
                options: options.map(option =>
                    moment(option.date).diff(moment(), "days") >= 0
                        ? {
                              ...option,
                              status: value ? value : option.status,
                          }
                        : {
                              ...option,
                              status: option.status,
                          },
                ),
            });
        }
    };

    /**
     * Handle submit
     */
    handleSubmit = (event: *) => {
        const { onSubmit } = this.props;
        const { options } = this.state;
        event.preventDefault();
        this.setState({
            loading: true,
        });

        const optionsToConfirm =
            options &&
            options.map(el => {
                return {
                    uuid: el.uuid,
                    status: el.status,
                    name: el.name,
                };
            });
        onSubmit(optionsToConfirm)?.then(() => {
            this.setState({
                loading: false,
            });
        });
    };

    /**
     * Render
     */
    render() {
        const { translate, disabled, showPast, optionCode } = this.props;
        const { options, seriesName, loading } = this.state;

        return (
            <form
                onSubmit={event => this.handleSubmit(event)}
                id="confirmOptions"
            >
                <DialogContent className={styles.content}>
                    <Grid container spacing={3}>
                        {loading ? (
                            <div className={styles.loader}>
                                <CircularProgress />
                            </div>
                        ) : (
                            (options: any).map((option, index) => {
                                if (
                                    !showPast &&
                                    moment(option.date)
                                        .add(1, "days")
                                        .isBefore()
                                ) {
                                    return null;
                                }

                                return (
                                    <Option
                                        key={option.uuid}
                                        index={index}
                                        translate={translate}
                                        ranking={option.ranking}
                                        name={option.name}
                                        seriesName={seriesName || optionCode}
                                        optionStatus={option.status}
                                        onChange={value =>
                                            this.handleChange(
                                                "name",
                                                value,
                                                index,
                                            )
                                        }
                                        venue={{
                                            code: option.venueCode,
                                            name: option.venueName,
                                            uuid: option.venueUuid,
                                        }}
                                        disabled={disabled}
                                        getError={key =>
                                            this.getError(key, index)
                                        }
                                        date={option.date}
                                        onSelect={value =>
                                            this.handleChange(
                                                "status",
                                                value,
                                                index,
                                            )
                                        }
                                    />
                                );
                            })
                        )}
                    </Grid>
                    {options.length === 0 ||
                    (!showPast &&
                        options.filter(
                            o => moment(o.date).diff(moment(), "days") >= 0,
                        ).length === 0) ? (
                        <Typography
                            align="center"
                            color="inherit"
                            style={{ fontSize: "1em" }}
                        >
                            {translate("No future options")}
                        </Typography>
                    ) : null}
                </DialogContent>
            </form>
        );
    }
}
