// @flow

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

type Props = {
    venueCode: string,
    status: string,
};

const DEFAULT_COLOR = "#7343B9";
const LIGHTENED_STATES = [
    "OPTION_CONFIRMED",
    "LIMITED_AVAILABILITY",
    "OPTION_CREATED",
];

const COLORS = {
    AS: "#08ADFF",
    LA: "#EE3124",
    SA: "#8A6CD3",
    CG: "#17382D",
    VN: "#FFA75E",
    FN: "#FFA75E",
    TT: "#8CC63F",
    TA: "#8CC63F",
    EH: "#8CC63F",
    PA: "#5C2D91",
};

/**
 * Get Color props
 */
const getColorProps = (venueCode, status) => ({
    borderColor: COLORS[venueCode] || DEFAULT_COLOR,
    backgroundColor: COLORS[venueCode] || DEFAULT_COLOR,
    opacity: LIGHTENED_STATES.includes(status) ? 0.7 : 1,
});

/**
 * Get Shape props
 */
const getShapeProps = status => {
    if (status === "OPTION_CONFIRMED") {
        return {
            borderRadius: 5,
        };
    }

    if (status === "LIMITED_AVAILABILITY") {
        return {
            borderRadius: 5,
            backgroundColor: "#fff",
            borderWidth: 1,
            borderStyle: "solid",
        };
    }

    if (status === "OPTION_CREATED") {
        return {
            margin: 2,
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: "#fff",
            borderWidth: 1,
            borderStyle: "solid",
        };
    }

    return {};
};

const styles = StyleSheet.create({
    status: {
        backgroundColor: "#999",
        width: "10px",
        height: "10px",
        borderRadius: 2,
        color: "#fff",
    },
    text: {
        textAlign: "center",
    },
    limited: {
        left: 4,
        width: 4,
        height: 8,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
});

/**
 * Renders a status with a venue in context
 */
const Status = ({ venueCode, status }: Props) => {
    return (
        <View style={{ marginBottom: 5, marginRight: 5 }}>
            <View
                style={{
                    ...styles.status,
                    ...getColorProps(venueCode, status),
                    ...getShapeProps(status),
                }}
            >
                {status === "PRIVATE" && <Text style={styles.text}>P</Text>}
                {(status === "CANCELED" || status === "OPTION_CANCELED") && (
                    <Text style={styles.text}>x</Text>
                )}
                {status === "LIMITED_AVAILABILITY" && (
                    <View
                        style={{
                            ...styles.limited,
                            ...getColorProps(venueCode, status),
                        }}
                    />
                )}
            </View>
        </View>
    );
};

export default Status;
