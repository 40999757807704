// @flow

/**
 * Decode b64 encoded utf-8
 *
 * https://stackoverflow.com/a/30106551/2095051
 */
export default (str: string) =>
    decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );
