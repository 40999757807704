//@flow
import React from "react";
import { Fab } from "@material-ui/core";
import classnames from "classnames/bind";

import styles from "./FabContainer.scss";

type Props = {
    children: React.ChildrenArray<React.Element<typeof Fab>>,
    float?: boolean,
    center?: boolean,
};

const FabContainer = ({ children, float = true, center }: Props) => (
    <div
        className={classnames.bind(styles)({
            container: true,
            "no-float": !float,
            center,
        })}
    >
        {children}
    </div>
);

export default FabContainer;
