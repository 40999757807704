// @flow

//import styles
import styles from "./DeleteMember.scss";

import React, { Component, Fragment } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { type State as MemberShipState } from "../../../redux/modules/member.d";

type Props = {
    translate: *,
    member: MemberShipState,
    onSubmit: () => *,
    onClose: () => void,
};

export default class DeleteMember extends Component<Props> {
    /**
     * Name getter
     */
    get name(): string {
        const { member, translate } = this.props;

        if (!member.data) {
            return "...";
        }

        if (member.data.userFirstName || member.data.userLastName) {
            return `${member.data.userFirstName || ""} ${
                member.data.userLastName || ""
            }`.trim();
        }

        return translate("(Unknown)");
    }

    /**
     * Render
     */
    render() {
        const { onSubmit, onClose, member, translate } = this.props;

        const msg = translate(
            "Are you sure you want to permanently delete " +
                "%{name} from At Your Service?",
            { name: this.name },
        );

        return (
            <Dialog onClose={onClose} open>
                <DialogTitle disableTypography>
                    <Typography variant="h2">
                        {translate("Delete member")}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    {member.error && (
                        <DialogContentText color="error">
                            {member.error}
                        </DialogContentText>
                    )}
                    {member.data && (
                        <Fragment>
                            <DialogContentText
                                dangerouslySetInnerHTML={{ __html: msg }}
                                className={styles.warning}
                            ></DialogContentText>
                            <DialogContentText>
                                {translate("(This action cannot be undone.)")}
                            </DialogContentText>
                        </Fragment>
                    )}
                    {member.loading && (
                        <div style={{ textAlign: "center", margin: "1em 0" }}>
                            <CircularProgress />
                        </div>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button
                        id="modal-deletemember-cancel"
                        onClick={onClose}
                        disabled={member.loading}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id="modal-deletemember-confirm"
                        onClick={onSubmit}
                        color="secondary"
                        disabled={member.loading}
                    >
                        {translate("Delete member")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
