// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { typeof Client } from "../../utils/api-client/modules/events";
import { type Action, type IState } from "./shows.d";

const ERROR_EVENTS = "No events found!";

const ZONE = "events";
const CLEAR = `${ZONE}/clear`;
const PENDING: string = `${ZONE}/pending`;
const SUCCESS: string = `${ZONE}/success`;
const FAILED: string = `${ZONE}/failed`;

const initialState: IState = (fromJS({
    loading: false,
    error: null,
    fieldErrors: null,
    data: null,
    page: 0,
    filter: "",
    tab: 0,
    sorting: [],
}): any);

/**
 * Reducer
 */
export default (state: IState = initialState, { type, payload }: Action): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        const { page, filter, sorting, tab } = (payload: any);
        invariant(page, "Wrong action format!");

        return state.merge({
            loading: true,
            page: page,
            filter: filter,
            sorting: fromJS(sorting),
            tab,
            error: null,
        });
    }

    if (type === SUCCESS) {
        return state.merge({
            loading: false,
            data: fromJS(payload),
        });
    }

    if (type === FAILED) {
        return state.merge({
            loading: false,
            error: payload,
        });
    }
    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load
 */
export const load =
    (
        page: number,
        filter: string,
        sorting: string[],
        tab: number,
        force: boolean = false,
    ) =>
    (dispatch: *, getState: *, client: Client) => {
        const state = getState().shows;

        // Block when loading
        if (!force && state.get("loading")) {
            return;
        }
        if (
            !force &&
            state.get("page") === page &&
            state.get("filter") === filter &&
            state.get("sorting").equals(fromJS(sorting)) &&
            state.get("tab") === tab
        ) {
            return;
        }

        dispatch({
            type: PENDING,
            payload: {
                page,
                filter,
                sorting,
                tab,
            },
        });

        client
            .getEvents(page, filter, sorting, tab)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error =>
                dispatch({
                    type: FAILED,
                    payload: (error && error.message) || ERROR_EVENTS,
                }),
            );
    };

/**
 * ActionCreator: reload
 */
export const reload = () => (dispatch: *, getState: *) => {
    const state = getState().shows;

    return dispatch(
        load(
            state.get("page"),
            state.get("filter"),
            state.get("sorting"),
            state.get("tab"),
            true,
        ),
    );
};
