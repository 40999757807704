// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import moment from "moment";

import {
    load as loadEventBriefing,
    ERROR as EVENT_BRIEFING_ERROR,
    refetch as refetchEventBriefing,
    clear as clearEventBriefing,
} from "../../../redux/modules/event-briefing";
import {
    load as loadSeriesEvents,
    clear as clearSeriesEvents,
    ERROR as SERIES_EVENTS_ERROR,
} from "../../../redux/modules/series-events";
import { load as updateEventBriefing } from "../../../redux/modules/update-event-briefing";

import {
    load as loadEventLineTypesBriefing,
    update as updateEventLineTypesBriefing,
} from "../../../redux/modules/event-linetype-briefing";

import {
    load as loadSecurityReport,
    ERROR as SECURITY_REPORT_ERROR,
} from "../../../redux/modules/event-security-report";

import EventBriefings from "../../../components/Events/EventBriefings";

import { withTranslate } from "../../Translate";
import { withAcl } from "../../Acl";
import { mapState } from "../../../redux/utils";

export default withAcl(
    withTranslate(
        connect(
            // Map state to props
            (
                {
                    eventBriefing,
                    eventLinesBriefing,
                    seriesEvents,
                    eventSecurityReport,
                },
                { eventUuid, venueUuid },
            ) => ({
                eventBriefing: mapState(eventBriefing, EVENT_BRIEFING_ERROR),
                eventSecurityReport: mapState(
                    eventSecurityReport,
                    SECURITY_REPORT_ERROR,
                ),
                eventLinesBriefing: eventLinesBriefing.toJS(),
                seriesEvents: mapState(
                    {
                        ...seriesEvents,
                        ...(seriesEvents.data
                            ? {
                                  data: {
                                      events: seriesEvents.data.events
                                          .filter(
                                              event =>
                                                  event.venueUuid ===
                                                      venueUuid &&
                                                  event.uuid !== eventUuid,
                                          )
                                          .map(event => ({
                                              ...event,
                                              fullDisplayName: `${moment(
                                                  event.start,
                                              ).format(
                                                  "DD/MM/YYYY - HH:mm",
                                              )} - ${event.venueCode} - ${
                                                  event.name
                                              } - ${event.seriesName}`,
                                          })),
                                  },
                              }
                            : {}),
                    },
                    SERIES_EVENTS_ERROR,
                ),
            }),
            // Map dispatch to props
            (dispatch: *, { eventUuid, seriesUuid }) =>
                bindActionCreators(
                    {
                        loadEventBriefing: () => loadEventBriefing(eventUuid),
                        loadSecurityReport: () => loadSecurityReport(eventUuid),
                        updateEventBriefing: (
                            briefing,
                            preBriefing,
                            preBriefingNotification,
                            briefingNotification,
                            applySeries,
                        ) =>
                            updateEventBriefing(
                                eventUuid,
                                briefing,
                                preBriefing,
                                preBriefingNotification,
                                briefingNotification,
                                applySeries,
                            ),
                        loadEventLineTypesBriefing: () =>
                            loadEventLineTypesBriefing(eventUuid),
                        updateEventLineTypesBriefing: (
                            briefing,
                            preBriefing,
                            preBriefingNotification,
                            briefingNotification,
                            lineUuid,
                            applySeries,
                        ) =>
                            updateEventLineTypesBriefing(
                                eventUuid,
                                briefing,
                                preBriefing,
                                preBriefingNotification,
                                briefingNotification,
                                lineUuid,
                                applySeries,
                            ),
                        refetchEventBriefing: () => refetchEventBriefing(),
                        loadSeriesEvents: () => loadSeriesEvents(seriesUuid),
                        clearSeriesEvents,
                        clearEventBriefing,
                    },
                    dispatch,
                ),
        )(EventBriefings),
    ),
);
