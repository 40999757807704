// @flow

import styles from "./EventQRCode.scss";

import React, { Fragment, Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { type State as QRCodeState } from "../../../redux/modules/event-qrcode.d";
import PDFGenerator from "../../PDFGenerator";

type Props = {
    acl: *,
    translate: *,
    load: (uuid: string) => void,
    clear: () => void,
    uuid: string,
    eventQRCode: QRCodeState,
    disabled: boolean,
};

class EventQRCode extends Component<Props> {
    componentDidMount() {
        const { uuid, load } = this.props;
        load(uuid);
    }

    componentWillUnmount() {
        const { clear } = this.props;
        clear();
    }

    render() {
        const { translate, acl, eventQRCode, disabled } = this.props;
        return (
            <div className={styles.main}>
                <Grid container spacing={3}>
                    {eventQRCode.error && (
                        <Grid item xs={12}>
                            <Typography color="error">
                                {eventQRCode.error}
                            </Typography>
                        </Grid>
                    )}
                    <Fragment>
                        <Grid item xs={12}>
                            {acl("events.qrcode.read") && (
                                <PDFGenerator
                                    type="SHOW"
                                    disabled={disabled}
                                    loading={eventQRCode.loading}
                                    eventQRCode={eventQRCode.data}
                                    translate={translate}
                                />
                            )}
                        </Grid>
                    </Fragment>
                </Grid>
            </div>
        );
    }
}

export default EventQRCode;
