// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./series-events-paged.d";

const { reducer, actions } = createApiModule(
    "seriesEventsPaged",
    ({ uuid, page }: PathParams) =>
        `/ays-series-event-management/series/${uuid}/events-paged?page=${page}`,
);

export const ERROR = "Could not fetch events and options for this series";
export const load = (uuid: string, page: number) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid, page } }));
};

export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
