// @flow
import styles from "./LineSelector.scss";
import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { type Line as LineType } from "../../redux/modules/lines.d";

import Venue from "../VenueBox/VenueBox";

type Props = {
    busy?: ?boolean,
    line: LineType,
    selected: boolean,
    onToggle: () => void,
};

const FIND_SPACES = / /g;

const slugify = input => input.replace(FIND_SPACES, "-");

/**
 * Line component
 */
const Line = ({ line, selected, onToggle, busy }: Props) => (
    <ListItem
        key={line.uuid}
        role={undefined}
        dense
        button
        onClick={onToggle}
        disabled={!!busy}
    >
        <Checkbox
            checked={selected}
            tabIndex={-1}
            disableRipple
            classes={{
                checked: styles.selected,
            }}
        />
        <ListItemText
            id={`lineselector-item-${slugify(line.name)}`}
            primary={line.name}
        />
        <div>
            {line?.venues &&
                line.venues.map(venue => (
                    <Venue key={venue.uuid} venue={venue} />
                ))}
        </div>
    </ListItem>
);

export default Line;
