// @flow
import styles from "./Add.scss";

import moment from "moment";
import React, { Component, Fragment } from "react";

import get from "lodash.get";
import { DateTimeField } from "../DatePickers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import isEqual from "lodash.isequal";

import { type State as VenueState } from "../../redux/modules/venues.d";
import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import { type State as EventDetails } from "../../redux/modules/event-details.d";
import AsyncSelect from "../AsyncSelect";
import AsyncFilteredSelect from "../AsyncFilteredSelect";
import VenueBox from "../VenueBox/VenueBox";
import SelectStatus from "../SelectStatus";
import { type Match } from "react-router";

type Props = {
    match: *,
    translate: *,
    onClose: () => void,
    /** submit */
    onSubmit: (
        show: *,
        internal: ?string,
        seriesName: ?string,
        serieUuid: ?string,
    ) => *,
    /** Load event details */
    loadEventDetails: (uuid: string, isAddToSeriesEvent?: boolean) => *,
    events: *,
    /** Find events */
    findEvents: (filter: string) => *,
    /** Disables the form */
    disabled: boolean,
    /**venues */
    venues: VenueState,
    /** Load venues */
    loadVenues: () => any,
    /** Load Organisers */
    loadOrganisers: *,
    /**  Organisers state */
    organisers: OrganisersState,
    id: string,
    addPromoter: (value: *) => Promise<*>,
    addPromoterState: *,
    addSerieState: *,
    addSerie: *,
    addShowState: *,
    addEventState: *,
    /**eventDetails */
    eventDetails: EventDetails,
    refetchOrganisers: () => Promise<*>,
};

type State = {
    name: *,
    match?: Match,
    seriesName: *,
    serieUuid: *,
    promoterUuid: *,
    promoterName: *,
    assignParking: boolean,
    end: *,
    promoterUuid: *,
    start: *,
    status: *,
    venueUuid: *,
    internal: *,
    optionCode: ?string,
    showAlert: boolean,
};

/**
 * Add dialog
 */
export default class Add extends Component<Props, State> {
    // Initial state
    state: State = {
        match: undefined,
        name: null,
        seriesName: null,
        serieUuid: null,
        promoterName: null,
        promoterUuid: null,
        assignParking: false,
        start: moment().add(1, "days"),
        end: moment().add(1, "days").add(2, "hours"),
        venueUuid: "",
        status: null,
        internal: "",
        optionCode: null,
        showAlert: false,
    };

    componentDidUpdate(prevProps: any) {
        const {
            eventDetails: { data },
            venues: { data: venuesData },
        } = this.props;

        if (data && !isEqual(prevProps.eventDetails.data, data)) {
            this.setState({
                //$FlowFixMe[prop-missing]
                promoterUuid: data.firstPromoterUuid,
                //$FlowFixMe[prop-missing]
                internal: data.internal,
                serieUuid: data.uuid,
                seriesName: data.name,
                optionCode: data.optionCode,
                showAlert: !data.name,
            });
        }

        if (venuesData && !isEqual(prevProps.venues.data, venuesData)) {
            this.getVenueData();
        }
    }

    /**
     * Disabled getter
     */
    get disabled() {
        const { disabled, addShowState } = this.props;

        return !!(disabled || (addShowState && addShowState.loading));
    }

    /**
     * Get Series uuid if Add option to series has been clicked
     */
    get uuid() {
        const { match } = this.props;
        const id = match.params.uuid ? match.params.uuid.toString() : "";

        return id ? (id.length > 13 ? id : "") : "";
    }

    /**
     * Get event details
     */
    get eventDetails() {
        const { eventDetails } = this.props;
        if (!eventDetails.loading && eventDetails.data) {
            return eventDetails.data;
        }

        return null;
    }

    /**
     * Get errors
     */
    getError(key: string) {
        const { addSerieState, addShowState, addEventState, addPromoterState } =
            this.props;

        const { serieUuid } = this.state;

        let transformedKey = key;

        if (serieUuid && addShowState.fieldErrors) {
            if (key === "serie") {
                return;
            }

            if (key === "internal") {
                return;
            }

            return get(addShowState.fieldErrors, transformedKey);
        }

        if (addSerieState.fieldErrors) {
            if (key === "serie") {
                transformedKey = "name";
            }

            return (
                get(addSerieState.fieldErrors, transformedKey) ||
                get(
                    addSerieState.fieldErrors,
                    `createEventRequests[0].${transformedKey}`,
                )
            );
        }
        if (addEventState.fieldErrors) {
            if (key === "serie") {
                transformedKey = "name";
            }

            return key === "serie"
                ? get(addEventState.fieldErrors, transformedKey)
                : get(
                      addEventState.fieldErrors,
                      `createEventRequests[0].${transformedKey}`,
                  );
        }

        if (addPromoterState.fieldErrors) {
            return get(addPromoterState.fieldErrors, transformedKey);
        }
    }

    /** Get default promoter */
    get defaultPromoter() {
        const { organisers } = this.props;
        const { promoterUuid } = this.state;
        return (
            promoterUuid &&
            organisers.data &&
            organisers.data.find(p => p.uuid === promoterUuid)
        );
    }

    /**
     * Get date from params
     */
    getDate = () => {
        const { match } = this.props;
        const id = match.params.date;
        const date = id ? id.slice(0, -3).toString() : null;
        if (date) {
            this.setState({
                start: moment.utc(date),
                end: moment.utc(date).clone().add(2, "hours"),
            });
        }
    };

    /**
     * Get venue Uuid from params
     */
    getVenueData = () => {
        const { match, venues } = this.props;
        const id = match.params.date;

        const venueCode = id ? id.slice(-2) : "AS";
        const venue =
            !venues.loading &&
            venues.data &&
            venueCode &&
            venues.data.find(venue => venue.code === venueCode);

        if (venue) {
            this.setState({
                venueUuid: venue.uuid,
            });
        }
    };

    /**
     * Load all neccesary data
     */
    load() {
        const { loadVenues, loadOrganisers, loadEventDetails } = this.props;

        loadVenues();
        if (loadOrganisers) {
            loadOrganisers();
        }

        if (loadEventDetails && this.uuid) {
            loadEventDetails(this.uuid, true);
        }
        this.getDate();
        this.getVenueData();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        this.load();
    }

    /** venue code getter */
    get venueCode() {
        const { venueUuid } = this.state;
        const { venues } = this.props;
        const venueToCheck = venueUuid;
        if (venues.data && venueToCheck) {
            const venuesArr = venues.data;
            const selectedVenue = venuesArr.filter(
                element => element.uuid === venueToCheck,
            );

            return selectedVenue[0].code.toString();
        }

        return null;
    }

    itemToLabel = (item: *) => {
        if (item) {
            const { uuid, name, internal, optionCode, label } = item;
            if (uuid) {
                const displayName =
                    name && optionCode
                        ? `${name} - ${optionCode}`
                        : `${name || optionCode}`;
                return displayName + (internal ? ` ( ${internal} )` : "");
            }

            return label || name;
        }
        return "";
    };

    /**
     * Submit handler
     */
    handleSubmit(event: *) {
        const { onSubmit } = this.props;
        let { internal, seriesName, serieUuid, optionCode } = this.state;

        event.preventDefault();
        const newShow = this.state;

        const {
            assignParking,
            end,
            name,
            promoterUuid,
            start,
            status,
            venueUuid,
        } = newShow;

        onSubmit(
            {
                assignParking,
                end,
                name,
                promoterUuid,
                start,
                status,
                venueUuid,
            },
            internal,
            seriesName,
            serieUuid,
            optionCode,
        );
    }

    /**
     * Render
     */
    render() {
        const {
            onClose,
            translate,
            venues,
            organisers,
            events,
            findEvents,
            id,
            addPromoterState,
            addShowState,
            eventDetails,
        } = this.props;
        const {
            internal,
            venueUuid,
            start,
            end,
            serieUuid,
            seriesName,
            name,
            optionCode,
            showAlert,
        } = this.state;

        return (
            <Dialog open className={styles.element}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <DialogTitle disableTypography className={styles.title}>
                        <Typography variant="h2" color="inherit">
                            {translate("Add Event")}
                        </Typography>
                        <Typography color="inherit" className={styles.subTitle}>
                            {translate("Add an event to the calender:")}
                        </Typography>
                    </DialogTitle>

                    {eventDetails.loading ? (
                        <DialogContent className={styles.loading}>
                            <CircularProgress className={styles.spinner} />
                        </DialogContent>
                    ) : (
                        <Fragment>
                            <DialogContent className={styles.purpleContent}>
                                <Grid container spacing={3}>
                                    {showAlert && (
                                        <Grid item xs={12}>
                                            <Alert
                                                id="add-event-modal-series-name-alert"
                                                severity="error"
                                            >
                                                {translate("Series Name Alert")}
                                            </Alert>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <AsyncFilteredSelect
                                            variant={"white"}
                                            id={`${id}-serie`}
                                            disabled={this.disabled}
                                            error={!!this.getError("serie")}
                                            helperText={this.getError("serie")}
                                            label={translate("Event series")}
                                            asyncState={events}
                                            lookup={findEvents}
                                            currentItem={
                                                (this.uuid &&
                                                    this.eventDetails) ||
                                                ""
                                            }
                                            onSelect={serie =>
                                                serie.uuid
                                                    ? this.setState({
                                                          serieUuid:
                                                              (serie: any).uuid,
                                                          seriesName:
                                                              serie.name,
                                                          showAlert:
                                                              !serie.name,
                                                          internal:
                                                              serie.internal,
                                                          promoterUuid:
                                                              serie.firstPromoterUuid,
                                                          optionCode:
                                                              serie.optionCode,
                                                      })
                                                    : this.setState({
                                                          serieUuid: null,
                                                          seriesName:
                                                              (serie: any).name,
                                                          internal: "",
                                                          promoterUuid: "",
                                                          optionCode: "",
                                                          showAlert: false,
                                                      })
                                            }
                                            itemToString={item =>
                                                (item && item.name) || ""
                                            }
                                            itemToLabel={this.itemToLabel}
                                            createNewItem={name =>
                                                name && {
                                                    uuid: null,
                                                    name,
                                                    label: translate(
                                                        'Add "%{name}" as a new series',
                                                        { name },
                                                    ),
                                                }
                                            }
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addshow-show-name"
                                            label={translate("Event Name")}
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    name: value,
                                                })
                                            }
                                            value={name || ""}
                                            error={!!this.getError("name")}
                                            helperText={this.getError("name")}
                                            disabled={this.disabled}
                                            fullWidth
                                            className={styles.whiteField}
                                            InputProps={{
                                                classes: {
                                                    underline: styles.underline,
                                                    disabled: styles.disabled,
                                                    focused: styles.focused,
                                                    error: styles.error,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addshow-event-code"
                                            label={translate("Option Code")}
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    optionCode: value,
                                                })
                                            }
                                            value={optionCode || ""}
                                            error={
                                                !!this.getError("optionCode")
                                            }
                                            helperText={this.getError(
                                                "optionCode",
                                            )}
                                            disabled={
                                                this.disabled ||
                                                !!serieUuid ||
                                                !seriesName
                                            }
                                            fullWidth
                                            className={styles.whiteField}
                                            InputProps={{
                                                classes: {
                                                    underline: styles.underline,
                                                    disabled: styles.disabled,
                                                    focused: styles.focused,
                                                    error: styles.error,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addshow-memo-name"
                                            label={translate("Memo")}
                                            onChange={({ target: { value } }) =>
                                                this.setState({
                                                    internal: value,
                                                })
                                            }
                                            value={internal || ""}
                                            error={!!this.getError("internal")}
                                            helperText={this.getError(
                                                "internal",
                                            )}
                                            disabled={
                                                this.disabled ||
                                                !!serieUuid ||
                                                !seriesName
                                            }
                                            fullWidth
                                            className={styles.whiteField}
                                            InputProps={{
                                                classes: {
                                                    underline: styles.underline,
                                                    disabled: styles.disabled,
                                                    focused: styles.focused,
                                                    error: styles.error,
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <AsyncFilteredSelect
                                            variant={"white"}
                                            id={`${id}-promoter`}
                                            disabled={this.disabled}
                                            error={
                                                !!(
                                                    addPromoterState.error ||
                                                    (addPromoterState.fieldErrors &&
                                                        addPromoterState
                                                            .fieldErrors
                                                            .name) ||
                                                    this.getError(
                                                        "promoterUuid",
                                                    )
                                                )
                                            }
                                            helperText={
                                                (!!addPromoterState.error &&
                                                    addPromoterState.error) ||
                                                (addPromoterState.fieldErrors &&
                                                    addPromoterState.fieldErrors
                                                        .name) ||
                                                this.getError("promoterUuid")
                                            }
                                            currentItem={
                                                this.defaultPromoter || ""
                                            }
                                            label={translate("Organiser")}
                                            asyncState={organisers}
                                            onSelect={promoter =>
                                                this.setState({
                                                    promoterUuid:
                                                        (promoter: any).uuid ||
                                                        null,
                                                    promoterName:
                                                        (promoter: any).uuid
                                                            ? null
                                                            : (promoter: any)
                                                                  .name,
                                                })
                                            }
                                            itemToString={item =>
                                                (item && item.name) || ""
                                            }
                                            itemToLabel={item =>
                                                (item &&
                                                    (item.label ||
                                                        item.name)) ||
                                                ""
                                            }
                                            filter={(value, items) =>
                                                items
                                                    .filter(item =>
                                                        (item: any).name
                                                            .toLowerCase()
                                                            .includes(
                                                                value.toLowerCase(),
                                                            ),
                                                    )
                                                    .splice(0, 5)
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogContent className={styles.whiteContent}>
                                <Grid container spacing={3}>
                                    <Grid item sm={6}>
                                        <DateTimeField
                                            id="addshow-show-start"
                                            disabled={this.disabled}
                                            value={start}
                                            onChange={value =>
                                                value &&
                                                this.setState({
                                                    start: value,
                                                    end: end.isAfter(value)
                                                        ? end
                                                        : value
                                                              .clone()
                                                              .add(2, "hours"),
                                                })
                                            }
                                            label={translate("Start")}
                                            error={!!this.getError("start")}
                                            helperText={this.getError("start")}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <DateTimeField
                                            id="addshow-show-end"
                                            disabled={this.disabled}
                                            value={end}
                                            onChange={value =>
                                                value &&
                                                this.setState({
                                                    end: value,
                                                })
                                            }
                                            label={translate("End")}
                                            error={!!this.getError("end")}
                                            helperText={this.getError("end")}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AsyncSelect
                                            id="addshow-show-venue"
                                            label={translate("Venue")}
                                            disabled={this.disabled}
                                            value={venueUuid || ""}
                                            loading={venues.loading}
                                            error={this.getError("venueUuid")}
                                            options={(venues.data || []).map(
                                                venue => [
                                                    venue.uuid,

                                                    <Grid
                                                        key={venue.uuid}
                                                        container
                                                        alignItems="center"
                                                        spacing={3}
                                                    >
                                                        <Grid item xs>
                                                            <Typography>
                                                                {venue.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs
                                                            justifyContent="flex-end"
                                                            container
                                                        >
                                                            <VenueBox
                                                                venue={venue}
                                                            />
                                                        </Grid>
                                                    </Grid>,
                                                ],
                                            )}
                                            onChange={venueUuid => {
                                                this.setState({
                                                    venueUuid,
                                                });
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectStatus
                                            id="addshow-status"
                                            error={this.getError("status")}
                                            label={translate("Status")}
                                            fullWidth
                                            venueCode={
                                                this.venueCode || "#298fc2"
                                            }
                                            optionsToSelect={[
                                                "PUBLIC",
                                                "PRIVATE",
                                            ]}
                                            onSelect={value => {
                                                this.setState({
                                                    status: value.toString(),
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {addShowState.error && (
                                            <FormHelperText
                                                id={`${id}-helper-text`}
                                                error
                                            >
                                                {addShowState.error}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Fragment>
                    )}
                    <DialogActions className={styles.actions}>
                        <Button
                            id="addshow-cancel"
                            onClick={onClose}
                            disabled={this.disabled}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="addshow-submit"
                            type="submit"
                            disabled={this.disabled || showAlert}
                            color="primary"
                        >
                            {translate("Add event")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
