// @flow

import styles from "./Scrollbar.scss";

// Import types
import type { Node } from "react";

// Import libs
import classnames from "classnames";
import React, { Component } from "react";
import PerfectScrollbar from "perfect-scrollbar";

// Define props
type PropsType = {
    children: Node,
    h: string,
    w: string,
    onStartScrolling?: () => void,
    onEndScrolling?: () => void,
    onScrolling?: () => void,
    style?: *,
    className?: ?string,
};
type StateType = {
    scrollElement: *,
};

/**
 * A Scrollbar component
 */
export default class Scrollbar extends Component<PropsType, StateType> {
    element: *;
    scrolledElement: *;
    constructor() {
        super();
        this.element = React.createRef();
        this.scrolledElement;
    }

    componentDidMount() {
        this.scrolledElement = new PerfectScrollbar(this.element.current);
        if (this.element.current) {
            this.element.current.addEventListener(
                "scroll",
                this.showReachedElement,
            );
        }
    }

    componentWillUnmount() {
        const { onStartScrolling, onEndScrolling, onScrolling } = this.props;
        //remove the general scroll event

        this.element.current &&
            this.element.current.removeEventListener(
                "scroll",
                this.showReachedElement,
            );

        this.scrolledElement && this.scrolledElement.destroy();
        this.scrolledElement = null;

        //Remove start scroll event
        if (this.element.current && onStartScrolling) {
            this.element.current.removeEventListener("ps-y-reach-start", () => {
                onStartScrolling();
            });
        }
        //Remove end scroll event
        if (this.element.current && onEndScrolling) {
            this.element.current.removeEventListener("ps-y-reach-end", () => {
                onEndScrolling();
            });
        }
        //Remove scroll event
        if (this.element.current && onScrolling) {
            this.element.current.removeEventListener("ps-scroll-up", () => {
                onScrolling();
            });
        }
        //Remove scroll event
        if (this.element.current && onScrolling) {
            this.element.current.removeEventListener("ps-scroll-down", () => {
                onScrolling();
            });
        }
    }
    /**
     * It will fire onStartScrolling / onEndScrolling functions
     */
    showReachedElement = () => {
        this.onStartScrolling();
        this.onEndScrolling();
        this.onScrolling();
    };

    /**
     * It will fire when the start of the element has been reached
     */
    onStartScrolling = () => {
        const { onStartScrolling } = this.props;
        if (onStartScrolling && this.element.current) {
            this.element.current.addEventListener("ps-y-reach-start", () => {
                onStartScrolling();
            });
        }
    };

    /**
     * It will fire when user is scrolling
     */
    onScrolling = () => {
        const { onScrolling } = this.props;
        if (onScrolling && this.element.current) {
            this.element.current.addEventListener("ps-scroll-up", () => {
                onScrolling();
            });
        }
        if (onScrolling && this.element.current) {
            this.element.current.addEventListener("ps-scroll-down", () => {
                onScrolling();
            });
        }
    };

    /**
     * It will fire when the end of the element has been reached
     */
    onEndScrolling = () => {
        const { onEndScrolling } = this.props;
        if (onEndScrolling && this.element.current) {
            this.element.current.addEventListener("ps-y-reach-end", () => {
                onEndScrolling();
            });
        }
    };

    /**
     * Render
     */
    render() {
        const { children, h, w, style, className } = this.props;

        return (
            <div
                className={classnames(styles.container, className)}
                style={{ height: h, width: w, ...style }}
                ref={this.element}
            >
                {children}
            </div>
        );
    }
}
