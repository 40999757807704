// @flow

//importComponents
import SelectList from "./SelectList";
import VenueBox from "../../../VenueBox/VenueBox";

//import libs
import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

type Props = {
    translate: *,
    onSelect: (status: string) => void,
    venue: Object,
    date: string,
    onChange: (data: *, index: number) => void,
    index: number,
    name: *,
    seriesName?: string,
    ranking: number,
    optionStatus: *,
    getError: (field: string) => void,
    disabled: boolean,
};

const Option = ({
    date,
    venue,
    optionStatus,
    index,
    onChange,
    name,
    seriesName,
    ranking,
    onSelect,
    getError,
    disabled,
    translate,
}: Props) => {
    return (
        <Fragment>
            <Grid item xs={5}>
                <TextField
                    id={`confirm-event-series-${index}`}
                    value={name || ""}
                    error={getError("name")}
                    helperText={getError("name")}
                    disabled={disabled}
                    onChange={event => onChange(event.target.value, index)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                style={{
                                    width: "auto",
                                }}
                                position="start"
                            >
                                {name && seriesName
                                    ? `${seriesName}:`
                                    : seriesName}
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <Typography variant="subtitle1" color="textSecondary">
                    {moment(date).format("DD/MM/YYYY")}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <VenueBox venue={venue} />
            </Grid>
            <Grid item xs={3}>
                <SelectList
                    disabled={disabled}
                    id="confirmoptions-selectoption"
                    onSelect={onSelect}
                    venueCode={venue.code}
                    optionStatus={optionStatus && optionStatus}
                    error={getError("status")}
                />
            </Grid>
            <Grid item xs={1}>
                {ranking !== 0 && (
                    <Tooltip
                        id="confirmoptions-high-ranking-tooltip "
                        title={translate("high ranking option tooltip")}
                        placement="right"
                    >
                        <Info style={{ color: "651f82" }} />
                    </Tooltip>
                )}
            </Grid>
        </Fragment>
    );
};

export default Option;
