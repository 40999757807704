// @flow

import React, { useEffect, useRef } from "react";
import { Menu } from "@material-ui/core";

import style from "./ContextMenu.scss";

type Props = {
    contextMenuPosition: { mouseX: number, mouseY: number },
    children: *,
    onClose: () => void,
};

const ContextMenu = ({ contextMenuPosition, children, onClose }: Props) => {
    const node = useRef<any>();
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        window.addEventListener("scroll", onClose, true);
        return () => {
            document.removeEventListener("mousedown", handleClick);
            window.removeEventListener("scroll", onClose);
        };
    }, []);
    const handleClick = (e: MouseEvent) => {
        if (node.current?.contains(e.target)) return;
        onClose();
    };
    return (
        <Menu
            className={style.contextMenu}
            open
            keepMounted
            onClose={onClose}
            anchorReference={"anchorPosition"}
            anchorPosition={
                contextMenuPosition.mouseY && contextMenuPosition.mouseX
                    ? {
                          top: contextMenuPosition.mouseY - 4,
                          left: contextMenuPosition.mouseX - 2,
                      }
                    : undefined
            }
            hideBackdrop={true}
        >
            <div ref={node}>
                {React.Children.map(
                    children,
                    child =>
                        child &&
                        React.cloneElement(child, {
                            onClick: e => {
                                onClose();
                                child.props.onClick(e);
                            },
                        }),
                )}
            </div>
        </Menu>
    );
};

export default ContextMenu;
