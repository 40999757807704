// @flow

import styles from "./Option.scss";
import React, { Component, Fragment } from "react";
import moment from "moment";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import VenueBox from "../../../VenueBox/VenueBox";
import SelectList from "../SelectList/SelectList";
import { TimeField, DateTimeField } from "../../../DatePickers";

/** props */
type Props = {
    translate: *,
    onSelect: (status: string, value: *) => void,
    index: number,
    seriesName?: string,
    id: string,
    onChange: *,
    onAddEvent: (index: *) => *,
    option: *,
    getError: *,
    defineEvetsState: *,
};

export default class Option extends Component<Props, State> {
    /**
     * Clip to event.date
     */
    handleChangeStartTime(value: *) {
        const { index, onChange } = this.props;

        onChange("date", value, index);
    }

    /**
     * Ensure one hour from start
     */
    handleChangeEndTime(value: *) {
        const { index, onChange } = this.props;

        onChange("end", moment(value), index);
    }

    render() {
        const {
            option,
            id,
            index,
            seriesName,
            onSelect,
            onChange,
            onAddEvent,
            getError,
        } = this.props;

        let startCopyDate = moment(option.date).format("DD/MM/YYYY");

        return (
            <TableRow className={styles.event}>
                <TableCell>
                    <TextField
                        index={index}
                        id={`${id}-option-name`}
                        error={!!getError("name")}
                        helperText={getError("name")}
                        value={option.name ? option.name : ""}
                        onChange={e => onChange("name", e.target.value, index)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {seriesName && seriesName}
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </TableCell>
                <TableCell>
                    <VenueBox
                        venue={{
                            code: option.venueCode,
                            name: option.venueName,
                            uuid: option.venueUuid,
                        }}
                    />
                </TableCell>
                <TableCell>
                    <SelectList
                        id={`${id}-option-select-option`}
                        onSelect={value => onSelect("status", value)}
                        venueCode={option.venueCode}
                        optionStatus={option.status}
                        type="ALL"
                        color="dark"
                    />
                </TableCell>
                <TableCell>
                    <div className={styles.time}>
                        {option.status &&
                        (option.status === "PUBLIC" ||
                            option.status === "PRIVATE") ? (
                            <TextField
                                value={startCopyDate}
                                disabled={true}
                                error={!!getError("start")}
                                helperText={getError("start")}
                                InputProps={{
                                    endAdornment: (
                                        <TimeField
                                            id={`${id}-start-time`}
                                            value={moment(option.date).clone()}
                                            onChange={value => {
                                                value?.isValid() &&
                                                    this.handleChangeStartTime(
                                                        value,
                                                    );
                                            }}
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <TextField
                                value={option.date.format("DD/MM/YYYY") + ","}
                                disabled={true}
                                id={`${id}-disabled-start time`}
                            />
                        )}
                    </div>
                </TableCell>
                {(option.status === "PUBLIC" ||
                    option.status === "PRIVATE") && (
                    <Fragment>
                        <TableCell className={styles.dateset}>
                            <DateTimeField
                                id={`${id}-option-time-until`}
                                value={moment(option.end)}
                                onChange={value =>
                                    this.handleChangeEndTime(value)
                                }
                                format={"DD/MM/YYYY, HH:mm"}
                                ampm={false}
                            />
                        </TableCell>
                    </Fragment>
                )}
                <TableCell className={styles.add}>
                    <Button
                        size="small"
                        id={`${id}-option-add-event`}
                        variant="text"
                        color="primary"
                        onClick={() => onAddEvent(index)}
                    >
                        <AddCircleIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}
