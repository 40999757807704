// @flow

import styles from "./TeamMember.scss";

import React, { Component } from "react";
import classnames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CarIcon from "@material-ui/icons/DirectionsCar";

import { type Member } from "../../../redux/modules/event-line-team.d";

import IconMenu from "../../IconMenu";

type Props = {
    translate: *,
    id: string,
    index: number,
    member: Member,
    onDelete: (uuid: string) => void,
    onPromote: (uuid: string) => void,
    onDemote: (uuid: string) => void,
    onAssign: (uuid: string) => void,
    onUnassign: (uuid: string) => void,
    generateQRCode: (uuid: string) => void,
    onClick: (uuid: string) => void,
    canEdit: boolean,
    canDelete: boolean,
    isMyMember: boolean,
    canGenerate: boolean,
    active: boolean,
};

export default class TeamMember extends Component<Props> {
    /**
     * Get the label of the toggle
     */
    get toggleLabelStatus(): string {
        const { member, translate } = this.props;

        if (member.teamLeader) {
            return translate("Demote to member");
        }

        return translate("Promote to leader");
    }

    /**
     * Get the label of the toggle
     */
    get toggleLabelParking(): string {
        const { member, translate } = this.props;

        if (member.premiumParking) {
            return translate("Unassign premium parking");
        }

        return translate("Assign premium parking");
    }

    /**
     * Menu options getter
     */
    get menuOptions() {
        const {
            translate,
            member,
            onDelete,
            onPromote,
            onDemote,
            onAssign,
            onUnassign,
            canEdit,
            canDelete,
            canGenerate,
            generateQRCode,
        } = this.props;

        const options = [];

        if (canEdit) {
            options.push({
                id: `toggle-member`,
                label: this.toggleLabelStatus,
                onClick: () =>
                    member.teamLeader
                        ? onDemote(member.teamMemberUuid)
                        : onPromote(member.teamMemberUuid),
            });
        }

        if (canEdit) {
            options.push({
                id: `toggle-parking`,
                label: this.toggleLabelParking,
                onClick: () =>
                    member.premiumParking
                        ? onUnassign(member.teamMemberUuid)
                        : onAssign(member.teamMemberUuid),
            });
        }

        if (canGenerate) {
            options.push({
                id: `qrcode-member`,
                label: translate("Generate QR Code"),
                onClick: () => generateQRCode(member.teamMemberUuid),
            });
        }

        if (canDelete) {
            options.push({
                id: `delete-organisation`,
                label: translate("Remove from team"),
                onClick: () => onDelete(member.teamMemberUuid),
            });
        }

        return options;
    }

    render() {
        const {
            id,
            index,
            translate,
            member,
            canEdit,
            canDelete,
            canGenerate,
            active,
            isMyMember,
            onClick,
        } = this.props;
        return (
            <TableRow
                id={`${id}-item-${index}`}
                key={member.teamMemberUuid}
                className={styles.teammember}
            >
                <TableCell
                    onClick={() => {
                        if (isMyMember) onClick(member.membershipUuid);
                    }}
                    className={styles.membername}
                >
                    <span
                        className={classnames({
                            [styles.user]: true,
                            [styles.isMyMember]: !isMyMember,
                        })}
                    >
                        <img
                            alt={`${member.userFirstName} ${member.userLastName}`}
                            src={`${member.profilePicturePath}/64x64.png`}
                            className={styles.avatar}
                        />
                        <span>{`${member.userFirstName} ${member.userLastName}`}</span>
                    </span>
                </TableCell>

                <TableCell
                    onClick={() => {
                        if (isMyMember) onClick(member.membershipUuid);
                    }}
                >
                    {member.premiumParking && (
                        <CarIcon
                            id="super-funky-fancy-gold-car"
                            color="primary"
                            style={{ fontSize: 36 }}
                            component={svgProps => (
                                <svg {...svgProps}>
                                    <defs>
                                        <linearGradient
                                            id="gradient1"
                                            gradientTransform="rotate(65)"
                                        >
                                            <stop
                                                offset="0%"
                                                stopColor={
                                                    "rgba(213,173,109,1)"
                                                }
                                            />
                                            <stop
                                                offset="26%"
                                                stopColor={
                                                    "rgba(213,173,109,1)"
                                                }
                                            />
                                            <stop
                                                offset="35%"
                                                stopColor={
                                                    "rgba(226,186,120,1)"
                                                }
                                            />
                                            <stop
                                                offset="45%"
                                                stopColor={"rgba(163,126,67,1)"}
                                            />
                                            <stop
                                                offset="61%"
                                                stopColor={"rgba(145,112,59,1)"}
                                            />
                                            <stop
                                                offset="100%"
                                                stopColor={
                                                    "rgba(213,173,109,1)"
                                                }
                                            />
                                        </linearGradient>
                                    </defs>
                                    {React.cloneElement(svgProps.children[0], {
                                        fill: "url(#gradient1)",
                                    })}
                                </svg>
                            )}
                        />
                    )}
                </TableCell>

                <TableCell
                    onClick={() => {
                        if (isMyMember) onClick(member.membershipUuid);
                    }}
                    className={classnames({
                        [styles.isMyMember]: !isMyMember,
                    })}
                >
                    {member.teamLeader
                        ? translate("Team leader")
                        : translate("Team member")}
                </TableCell>
                <TableCell
                    onClick={() => {
                        if (isMyMember) onClick(member.membershipUuid);
                    }}
                    className={classnames({
                        [styles.isMyMember]: !isMyMember,
                    })}
                >
                    {member.organisationName
                        ? member.organisationName.length > 50
                            ? `${member.organisationName.substring(0, 50)}...`
                            : member.organisationName
                        : "GUEST"}
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                    {(canEdit || canDelete || canGenerate) && active && (
                        <IconMenu
                            id={`${id}-menu-${index}`}
                            options={this.menuOptions}
                        />
                    )}
                </TableCell>
            </TableRow>
        );
    }
}
