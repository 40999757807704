// @flow

import styles from "./TransferList.scss";

import React, { useEffect, useState } from "react";

import {
    Divider,
    Grid,
    List,
    Card,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    TextField,
    InputAdornment,
    CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InfiniteScroll from "react-infinite-scroll-component";

import { useDebounce } from "../../../../hooks";
import { type State as DirectState } from "../../../../redux/modules/event-line-member-candidates.d";

type Props = {
    translate: *,
    state: DirectState,
    load: (filter: string, page: number) => void,
    onChange: (uuids: string[]) => void,
};

const notIn = (a, b) => a.filter(value => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter(value => b.indexOf(value) !== -1);

const union = (a, b) => [...a, ...notIn(b, a)];

const TransferList = ({ load, state, translate, onChange }: Props) => {
    const [paging, setPaging] = useState({ current: 1, totalPages: null });
    const [checked, setChecked] = useState([]);
    const [unchecked, setUnchecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [filter, setFilter] = useState("");
    const [totalElements, setTotalElements] = useState(0);

    const debouncedFilter = useDebounce(filter, 100);
    const hasMore = !(paging.current === paging.totalPages);

    const createDisplayName = member =>
        `${member.firstName || ""} ${member.lastName || ""} ${
            member.phoneNumber
        } ${
            member.inviteStatus === "INVITED" ? `(${translate("Invited")})` : ""
        }`;

    const handleFetch = () => {
        if (hasMore) {
            load(debouncedFilter, paging.current + 1);
            setPaging({ ...paging, current: paging.current + 1 });
        }
        return;
    };

    useEffect(() => {
        load(debouncedFilter, 1);
        setPaging({ ...paging, current: 1 });
    }, [debouncedFilter]);

    useEffect(() => {
        if (state.data?.content) {
            const leftItems = [
                ...(paging.current > 1 ? left : []),
                ...state.data.content,
            ].filter(
                member =>
                    !right.some(
                        item => item.membershipUuid === member.membershipUuid,
                    ),
            );
            setLeft(leftItems);
            setPaging({ ...paging, totalPages: state.data.totalPages });
            if (
                state.data?.content.length &&
                leftItems.length < 10 &&
                paging.current !== state.data.totalPages
            )
                handleFetch();
        }
        if (state.data?.totalElements) {
            setTotalElements(state.data.totalElements);
        }
    }, [state.data]);

    useEffect(() => {
        onChange(right.map(member => member.membershipUuid));
    }, [right]);

    useEffect(() => {
        if (checked.length > 0) {
            setRight(right.concat(leftChecked));
            setLeft(notIn(left, leftChecked));
            if (notIn(left, leftChecked).length < 10 && hasMore) handleFetch();
        }
    }, [checked]);

    useEffect(() => {
        setLeft(left.concat(rightChecked));
        setRight(notIn(right, rightChecked));
    }, [unchecked]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(unchecked, right);

    const handleLeft = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(notIn(newChecked, leftChecked));
        if (notIn(left, leftChecked).length < 10 && hasMore) handleFetch();
    };

    const handleRight = value => () => {
        const currentIndex = unchecked.indexOf(value);
        const newChecked = [...unchecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setUnchecked(notIn(newChecked, rightChecked));
    };

    const numberOfChecked = items => intersection(checked, items).length;
    const numberOfUnchecked = items => intersection(unchecked, items).length;

    const handleToggleAllLeft = items => () => {
        if (
            numberOfChecked(items) ===
            items.filter(member => member.inviteStatus !== "INVITED").length
        )
            setChecked(notIn(checked, items));
        else
            setChecked(
                union(
                    checked,
                    items.filter(member => member.inviteStatus !== "INVITED"),
                ),
            );
    };

    const handleToggleAllRight = items => () => {
        if (
            numberOfUnchecked(items) ===
            items.filter(member => member.inviteStatus !== "INVITED").length
        )
            setUnchecked(notIn(unchecked, items));
        else
            setUnchecked(
                union(
                    unchecked,
                    items.filter(member => member.inviteStatus !== "INVITED"),
                ),
            );
    };

    const mapMembers = (members, handler) =>
        members.map(member => {
            const labelId = `member-${member.membershipUuid}-label`;
            return (
                <ListItem
                    key={member.membershipUuid}
                    role="listitem"
                    button
                    onClick={handler(member)}
                    disabled={member.inviteStatus === "INVITED"}
                >
                    <ListItemIcon>
                        <Checkbox
                            color="primary"
                            tabIndex={-1}
                            disableRipple
                            checked={right.includes(member)}
                            inputProps={{
                                "aria-labelledby": labelId,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        id={labelId}
                        primary={createDisplayName(member)}
                    />
                </ListItem>
            );
        });

    const customList = (
        title,
        items,
        totalElements,
        handler,
        numberOfSelected,
        withInfinite = false,
    ) => (
        <Card className={styles.card}>
            <CardHeader
                avatar={
                    <Checkbox
                        color="primary"
                        onClick={handler(items)}
                        checked={
                            numberOfSelected(items) === items.length &&
                            items.length !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{ "aria-label": "all items selected" }}
                    />
                }
                title={withInfinite ? title : ""}
                subheader={
                    withInfinite
                        ? `Transfer ${
                              left.filter(
                                  member => member.inviteStatus !== "INVITED",
                              ).length
                          } of ${totalElements} users`
                        : `Unselect ${totalElements} users`
                }
            />
            <Divider />
            {withInfinite ? (
                <List dense component="div" role="list">
                    <InfiniteScroll
                        dataLength={left.length}
                        hasMore={hasMore}
                        next={handleFetch}
                        height={450}
                    >
                        {mapMembers(items, handleLeft)}
                        {(hasMore || state.loading) && paging.totalPages !== 0 && (
                            <div className={styles.loader}>
                                <CircularProgress size={35} />
                            </div>
                        )}
                    </InfiniteScroll>
                </List>
            ) : (
                <List dense component="div" role="list" className={styles.list}>
                    {mapMembers(items, handleRight)}
                </List>
            )}
        </Card>
    );

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item sm={12}>
                <TextField
                    autoFocus
                    fullWidth
                    id="staffingtemplate-lineselector-search"
                    value={filter}
                    onChange={({ target: { value } }) => setFilter(value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    placeholder={translate("Filter by name")}
                />
            </Grid>
            <Grid item sm={6} className={styles.listWrapper}>
                {customList(
                    translate("Search results"),
                    left,
                    totalElements,
                    handleToggleAllLeft,
                    numberOfChecked,
                    true,
                )}
            </Grid>
            <Grid item sm={6} className={styles.listWrapper}>
                {customList(
                    translate("Selected to add"),
                    right,
                    right.length,
                    handleToggleAllRight,
                    numberOfUnchecked,
                )}
            </Grid>
        </Grid>
    );
};

export default TransferList;
