// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zhJjHebW-Ea954Fkgj26sQ\\=\\=>:first-child{height:13.1em}\n", "",{"version":3,"sources":["webpack://./../style.module.scss"],"names":[],"mappings":"AAAA,yCAEQ,aAAc","sourcesContent":[".headerWrapper {\n    > :first-child {\n        height: 13.1em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": "zhJjHebW-Ea954Fkgj26sQ=="
};
export default ___CSS_LOADER_EXPORT___;
