// @flow

// Import styles
import styles from "../PDFGenerator.scss";
import logo from "../../../logo.svg";
// Import libs
import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { type EventQRCode } from "../../../redux/modules/event-qrcode.d";
import moment from "moment";
import QRCode from "qrcode.react";

// Define props
type PropsType = {
    data: EventQRCode,
    translate: *,
};

/**
 * A component that generates a pdf
 */
export default class ShowPDF extends Component<PropsType> {
    /**
     * Render
     */
    render() {
        const { data, translate } = this.props;
        return (
            <Paper id="pdf" className={styles.pdf} elevation={2}>
                <div className={styles.header}>
                    <img src={logo} />
                    {data ? (
                        <div>
                            <Typography className={styles.title}>
                                {data.eventName}
                            </Typography>
                            <Typography variant="body2" className={styles.time}>
                                {moment(data.eventStart).format(
                                    "ddd D MMM YYYY",
                                )}
                            </Typography>
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
                <div className={styles.content}>
                    <Typography
                        variant="body1"
                        color="inherit"
                        className={styles.subtitle}
                    >
                        {translate("Event Details")}
                    </Typography>

                    {data ? (
                        <div>
                            <Typography className={styles.item}>
                                <span className={styles.key}>
                                    {translate("Venue")}:
                                </span>
                                {data.venueName}
                            </Typography>
                            <Typography className={styles.item}>
                                <span className={styles.key}>
                                    {translate("Series Name")}:
                                </span>
                                {data.seriesName}
                            </Typography>
                            <Typography className={styles.item}>
                                <span className={styles.key}>
                                    {translate("Event name")}:
                                </span>
                                {data.eventName}
                            </Typography>
                            <Typography className={styles.item}>
                                <span className={styles.key}>
                                    {translate("Event start")}:
                                </span>
                                {moment(data.eventStart).format(
                                    "ddd D MMM YYYY, H:mm",
                                )}
                            </Typography>
                            <Typography className={styles.item}>
                                <span className={styles.key}>
                                    {translate("Event end")}:
                                </span>
                                {moment(data.eventEnd).format(
                                    "ddd D MMM YYYY, H:mm",
                                )}
                            </Typography>
                            <QRCode
                                value={data.qrCodeData}
                                size={175}
                                className={styles.qrcode}
                            />
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
            </Paper>
        );
    }
}
