// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+XLflAvX9fSINhJMF7csNw\\=\\={padding:1em 2em}._9\\+Z4\\+qVo-6aQcphYbgsBCw\\=\\={display:flex;justify-content:space-between;padding:1em}p.XpurnJunWK1v3cRSVMO8Ug\\=\\={margin:1em 0 0 2em}\n", "",{"version":3,"sources":["webpack://./../index.style.scss"],"names":[],"mappings":"AAAA,6BACI,eAAgB,CACnB,+BAEG,YAAa,CACb,6BAA8B,CAC9B,WAAY,CACf,6BAEG,kBAAmB","sourcesContent":[".wrapper {\n    padding: 1em 2em;\n}\n.buttonsWrapper {\n    display: flex;\n    justify-content: space-between;\n    padding: 1em;\n}\np.helperText {\n    margin: 1em 0 0 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "+XLflAvX9fSINhJMF7csNw==",
	"buttonsWrapper": "_9+Z4+qVo-6aQcphYbgsBCw==",
	"helperText": "XpurnJunWK1v3cRSVMO8Ug=="
};
export default ___CSS_LOADER_EXPORT___;
