//@flow
import createApiModule from "../create-api-module";

import { type PathParams } from "./attendees-total.d";

const { reducer, actions } = createApiModule(
    "attendeesTotals",
    ({ eventUuid }: PathParams) =>
        `ays-searches/searches/events/${eventUuid}/present-members/totals`,
);

export const load =
    (eventUuid: string, force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(actions.load({ path: { eventUuid } }, force));
    };
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
