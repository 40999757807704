// @flow

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import debounce from "lodash.debounce";
import isEqual from "lodash.isequal";

import {
    //    Redirect,
    //    Switch,
    //    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import { type State as attendeesState } from "../../../../redux/modules/attendees.d";
import { type State as attendeesTotalState } from "../../../../redux/modules/attendees-total.d";
import { type State as eventCSVDataState } from "../../../../redux/modules/event-csvdata.d";
import { type State as attendeeQRState } from "../../../../redux/modules/attendees-qrcode.d";
import {
    load,
    clear,
    ERROR as ATTENDEES_ERROR,
} from "../../../../redux/modules/attendees";
import { load as loadCSV } from "../../../../redux/modules/event-csvdata";
import { load as loadAttendeeQR } from "../../../../redux/modules/attendees-qrcode";
import { load as loadTotals } from "../../../../redux/modules/attendees-total";
//import stripslash from "../../../../utils/stripslash";
import FilterField from "../../../../components/Authenticated/FilterField";

import { withTranslate } from "../../../Translate";
import Attendees from "../../../../components/Events/Attendees";
import PresenceOverview from "../../../../components/Events/Attendees/PresenceOverview";
import { withAcl, type Acl } from "../../../Acl";
import { mapState } from "../../../../redux/utils";

type Props = {
    acl: Acl,
    translate: *,
    match: Match,
    uuid: string,
    attendees: attendeesState,
    totals: attendeesTotalState,
    eventCSVData: eventCSVDataState,
    loadAttendeeQR: (uuid: string, eventUuid: string) => void,
    clear: () => void,
    loadCSV: (uuid: string, force: boolean) => *,
    loadTotals: (uuid: string) => *,
    attendeesQRCode: attendeeQRState,
    load: (
        uuid: string,
        page: number,
        filter: string,
        sorting: string[],
        presenceFilter: string,
        force: boolean,
    ) => void,
    history: RouterHistory,
    onPageChange: (page: number) => void,
};

const DEBOUNCE_WAIT = 250;
const DEBOUNCE_MAX_WAIT = 500;

type StateType = {
    filter: string,
    presenceFilter: string,
    tab: number,
    sorting: string[],
};

class List extends Component<Props, StateType> {
    // Initial state
    state = {
        filter: "",
        presenceFilter: "",
        tab: 0,
        sorting: [],
    };

    /**
     * Initial load
     */
    componentDidMount() {
        const { clear, match, loadTotals } = this.props;
        clear();
        loadTotals(match.params.id ? match.params.id : "");
    }

    /**
     * Trigger load on update
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;

        if (
            prevProps.match.params.page !== match.params.page ||
            !isEqual(prevState, this.state)
        ) {
            this.reload();
        }
    }

    onPresenceChange(presenceFilter: string) {
        this.setState({
            presenceFilter,
        });
    }

    handleSortChange(sortString: string[]) {
        this.setState({
            sorting: sortString,
        });
    }

    /**
     *
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    /**
     * Trigger update
     */
    reload = debounce(
        (force: boolean = false) => {
            const { load, match } = this.props;
            const { filter, presenceFilter, sorting } = this.state;
            if (this.pageParamIsNumber) {
                load(
                    match.params.id ? match.params.id : "",
                    parseInt(match.params.page) - 1,
                    filter,
                    sorting,
                    presenceFilter,
                    force,
                );
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );
    /**
     * Render
     */
    render() {
        const {
            acl,
            translate,
            onPageChange,
            attendees,
            loadAttendeeQR,
            loadCSV,
            totals,
            eventCSVData,
            attendeesQRCode,
            uuid,
        } = this.props;
        const { tab } = this.state;
        const { filter, presenceFilter } = this.state;
        if (!this.pageParamIsNumber) {
            return <h1>404: Not Found</h1>;
        }
        return (
            <div>
                <span style={{ marginTop: "1em", display: "block" }} />
                <PresenceOverview
                    loadCSV={loadCSV}
                    totals={totals}
                    eventCSVData={eventCSVData}
                    uuid={uuid}
                    acl={acl}
                    translate={translate}
                />
                <FilterField
                    onChange={filter => this.setState({ filter })}
                    placeholder={translate(
                        "Filter by name, phonenumber or organisation",
                    )}
                    id="issues-list-filter"
                    value={filter}
                />
                <Attendees
                    tab={tab}
                    onTabChange={tab => {
                        switch (tab) {
                            case 0:
                                this.onPresenceChange("");
                                break;
                            case 1:
                                this.onPresenceChange("CHECKED_IN");
                                break;
                            case 2:
                                this.onPresenceChange("CHECKED_OUT");
                                break;
                            case 3:
                                this.onPresenceChange("NOT_CHECKED");
                                break;
                            default:
                                this.onPresenceChange("");
                                break;
                        }
                        this.setState({
                            tab,
                        });
                    }}
                    acl={acl}
                    translate={translate}
                    atten
                    loading={attendees.loading}
                    page={attendees.page + 1}
                    data={attendees.data}
                    error={attendees.error}
                    onSortChange={this.handleSortChange.bind(this)}
                    onPageChange={onPageChange}
                    presenceFilter={presenceFilter}
                    onPresenceChange={this.onPresenceChange.bind(this)}
                    loadCSV={loadCSV}
                    loadAttendeeQR={loadAttendeeQR}
                    totals={totals}
                    eventCSVData={eventCSVData}
                    qrcode={attendeesQRCode}
                    uuid={uuid}
                />
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                // Map state to props
                ({
                    eventCSVData,
                    attendees,
                    attendeesTotals,
                    attendeesQRCode,
                }) => ({
                    totals: attendeesTotals,
                    eventCSVData,
                    attendees: {
                        ...mapState(attendees, ATTENDEES_ERROR),
                        page:
                            attendees.params &&
                            attendees.params.path &&
                            attendees.params.path.page,
                    },
                    attendeesQRCode,
                }),
                // Map dispatch to props
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load: (
                                uuid,
                                page,
                                filter,
                                sorting,
                                presenceFilter,
                                force,
                            ) =>
                                load(
                                    uuid,
                                    page,
                                    filter,
                                    sorting,
                                    presenceFilter,
                                    force,
                                ),
                            clear,
                            loadCSV: uuid => loadCSV(uuid, true),
                            loadTotals: uuid => loadTotals(uuid, true),
                            loadAttendeeQR: (uuid, eventUuid) =>
                                loadAttendeeQR(uuid, eventUuid),
                        },
                        dispatch,
                    ),
            )(List),
        ),
    ),
);
