// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { type State as OptionState } from "../../redux/modules/option.d";
import {
    load as loadOption,
    clear as clearOption,
} from "../../redux/modules/option";
import { type State as VenuesState } from "../../redux/modules/venues.d";
import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";
import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";
import { refetch as refetchCalendar } from "../../redux/modules/calendar";

import {
    load as editOption,
    clear as clearEditOption,
    ERROR as EDIT_OPTION_ERROR,
} from "../../redux/modules/edit-option";
import { type State as EditOptionState } from "../../redux/modules/edit-option.d";
import { withTranslate } from "../Translate";

import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";
import { type State as AddPromoterState } from "../../redux/modules/add-promoter.d";
import { mapState } from "../../redux/utils";
import { EditOption } from "../../components/Events/Option/EditOption";

type Props = {
    onClose: () => void,
    translate: *,
    option: OptionState,
    loadOption: (uuid: string) => *,
    editOption: (uuid: string, option: Object[]) => *,
    addPromoter: *,
    addPromoterState: AddPromoterState,
    loadOrganisers: () => *,
    venues: VenuesState,
    organisers: OrganisersState,
    match: Match,
    editOptionState: EditOptionState,
    clearEditOption: () => *,
    clearOption: () => *,
    loadCalendar: *,
    clearAddPromoter: *,
    refetchOrganisers: () => Promise<*>,
    refetchCalendar: () => Promise<*>,
};

class EditOptionContainer extends Component<Props> {
    componentDidMount() {
        const { loadOption, loadOrganisers, clearEditOption, clearOption } =
            this.props;
        clearOption();
        clearEditOption();
        clearAddPromoter();
        loadOrganisers();
        loadOption(this.uuid);
    }

    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            translate,
            option,
            venues,
            organisers,
            onClose,
            editOption,
            editOptionState,
            addPromoter,
            addPromoterState,
            loadOrganisers,
            refetchOrganisers,
            clearOption,
            clearEditOption,
            clearAddPromoter,
            refetchCalendar,
        } = this.props;

        return (
            <EditOption
                onClose={() => {
                    clearOption();
                    clearEditOption();
                    clearAddPromoter();
                    onClose();
                }}
                translate={translate}
                loadVenues={loadVenues}
                venues={venues}
                addPromoter={addPromoter}
                addPromoterState={addPromoterState}
                promoters={organisers}
                editOptionState={editOptionState}
                onSubmit={(optionUuid, promoterUuid) =>
                    editOption(optionUuid, promoterUuid).then(() => {
                        onClose();
                        clearEditOption();
                        clearOption();
                        clearAddPromoter();
                        refetchCalendar();
                    })
                }
                optionData={option.data}
                loadOrganisers={loadOrganisers}
                id="edit-option"
                loading={option.loading}
                refetchOrganisers={refetchOrganisers}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ option, organisers, venues, editOption, addPromoter }) => ({
                option,
                editOptionState: mapState(editOption, EDIT_OPTION_ERROR),
                venues: mapState(venues, VENUES_ERROR, "venues"),
                organisers: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                addPromoterState: mapState(
                    addPromoter,
                    ADD_PROMOTER_ERROR,
                    "uuid",
                ),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadVenues,
                        loadOrganisers,
                        addPromoter,
                        clearAddPromoter,
                        clearEditOption,
                        clearOption,
                        loadOption: uuid => loadOption(uuid),
                        editOption: (uuid, option) => editOption(uuid, option),
                        refetchOrganisers,
                        refetchCalendar,
                    },
                    dispatch,
                ),
        )(EditOptionContainer),
    ),
);
