//@flow

import React, { Component } from "react";
import { type Match, type RouterHistory, withRouter } from "react-router";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./MemberList.scss";
import { type Members } from "../../../redux/modules/members.d";
import Table from "../../Table/Table";
import stripslash from "../../../utils/stripslash";

// import SmartPhone from material
import Smartphone from "@material-ui/icons/Smartphone";
// import svg phoneOff from icons
import NoSmartPhone from "../../icons/NoSmart";
// import formatNumber
import { parsePhoneNumber } from "libphonenumber-js";
import FabContainer from "../../FabContainer/FabContainer";

type Props = {
    acl: *,
    translate: *,
    loading: boolean,
    error: ?string,
    data: ?Members,
    page: number,
    match: Match,
    history: RouterHistory,
    onPageChange: (n: number) => void,
    onSortChange: (sortString: string[]) => void,
    onToggleRole: (uuid: string, promote: boolean) => void,
    onApproveMember: (uuid: string) => void,
    onDenyMember: (uuid: string) => void,
    filter: string,
};
type State = {
    selectedTab: number,
};

class MemberList extends Component<Props, State> {
    state = {
        selectedTab: 0,
    };
    /**
     * Handle edit request
     */
    handleEdit(uuid: string) {
        const { history, match } = this.props;
        history.push(`${stripslash(match.url)}/${uuid}/edit/`);
    }

    /**
     * Handle delete request
     */
    handleDelete(uuid: string) {
        const { history, match } = this.props;
        history.push(`${stripslash(match.url)}/${uuid}/delete/`);
    }

    /**
     * Handle approve request
     */
    handleApprove(uuid: string) {
        const { onApproveMember } = this.props;
        onApproveMember(uuid);
    }

    /**
     * Handle deny request
     */
    handleDeny(uuid: string) {
        const { onDenyMember } = this.props;
        onDenyMember(uuid);
    }

    /**
     * Handle toggle request
     */
    handleToggleRole(uuid: string, role: string) {
        const { onToggleRole } = this.props;

        onToggleRole(uuid, role !== "LEADER");
    }

    /**
     * Handle page change request
     */
    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }
    /**
     * getAmount of tab
     */
    getAmount = (type: string) => {
        const { data } = this.props;
        if (data && data.content) {
            return data.content.filter(el => el.inviteStatus === type).length;
        }
        return 0;
    };
    /**
     * get notificationAmount
     */
    getNotificationAmount = () => {
        const { data } = this.props;
        if (data && data.content) {
            return data.content.filter(
                it =>
                    (!it.phoneNumber || it.noSmartphone) &&
                    it.inviteStatus === "INVITED",
            ).length;
        }
        return 0;
    };
    /**
     * get filtered data by SelectedTab
     */
    get filteredData() {
        const { data } = this.props;
        const { selectedTab } = this.state;

        if (data && selectedTab === 1) {
            const content = data.content.filter(
                el =>
                    el.inviteStatus === "MANUALLY_APPROVED" ||
                    el.inviteStatus === "ACCEPTED",
            );
            return {
                ...data,
                content,
                totalElements: content.length,
            };
        }
        if (data && selectedTab === 2) {
            const content = data.content.filter(
                el => el.inviteStatus === "INVITED",
            );
            return {
                ...data,
                content,
                totalElements: content.length,
            };
        }
        return data;
    }
    /**
     * Get formatted phone number
     */
    formattedPhone(phone): string {
        return parsePhoneNumber(phone).formatInternational();
    }
    /**
     * Render
     */
    render() {
        const {
            page,
            error,
            loading,
            translate,
            acl,
            onPageChange,
            onSortChange,
            match,
            history,
            filter,
        } = this.props;

        const { selectedTab } = this.state;

        const config = {
            id: "memberlist",
            uuidKey: "uuid",
            hasMenu: true,
            hasTabs: true,
            tabOptions: [
                {
                    name: translate("All"),
                },
                {
                    name: translate("Accepted"),
                    amount:
                        this.getAmount("ACCEPTED") +
                        this.getAmount("MANUALLY_APPROVED"),
                },
                {
                    name: translate("Pending"),
                    amount: this.getAmount("INVITED"),
                    notificationAmount: this.getNotificationAmount(),
                },
            ],
            onClick: item => this.handleEdit(item.uuid),
            configItems: [
                {
                    key: "lastName",
                    customRender: (item: Object) => {
                        return (
                            <span className={styles.user}>
                                <span>
                                    {item.firstName ? (
                                        `${item.firstName} ${item.lastName}`
                                    ) : (
                                        <span className={styles.unknown}>
                                            {translate("(Unknown)")}
                                        </span>
                                    )}
                                </span>
                            </span>
                        );
                    },
                    displayName: translate("Name"),
                    sortable: true,
                    isNumeric: false,
                    defaultSort: "asc",
                    permission: true,
                },
                {
                    key: "description",
                    displayName: translate("Description"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "role",
                    displayName: translate("Role"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                    customRender: (item: Object) => {
                        const allLower = item.role.toLowerCase();
                        return (
                            <span>
                                {allLower.replace(/^\w/, c => c.toUpperCase())}
                            </span>
                        );
                    },
                },
                {
                    key: "phoneNumber",
                    displayName: translate("Phone Number"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                    customRender: (item: Object, index: number) => {
                        if (item.phoneNumber) {
                            return (
                                <span className={styles.user}>
                                    {this.formattedPhone(item.phoneNumber)}
                                </span>
                            );
                        }
                        return (
                            <span className={styles.user}>
                                <NoSmartPhone
                                    id={`memberlist-noSmartphone-${index}`}
                                    className={styles.phoneOff}
                                />
                                -
                            </span>
                        );
                    },
                },
                {
                    key: "noSmartphone",
                    displayName: translate("No Smartphone"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                    customRender: (item: Object, index: number) => {
                        return item.noSmartphone ? (
                            <NoSmartPhone
                                id={`memberlist-noSmartphone-${index}`}
                                className={styles.phoneOff}
                            />
                        ) : (
                            <Smartphone
                                id={`memberlist-noSmartphone-${index}`}
                                className={styles.phone}
                            />
                        );
                    },
                },
                {
                    key: "inviteStatus",
                    displayName: translate("Status"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                    customRender: (item: Object) => {
                        if (
                            (item.inviteStatus &&
                                item.inviteStatus === "ACCEPTED") ||
                            item.inviteStatus === "MANUALLY_APPROVED"
                        ) {
                            return (
                                <span className={styles.status}>
                                    <span className={styles.accepted} />
                                    {translate("Accepted")}
                                </span>
                            );
                        }
                        if (
                            (item.inviteStatus &&
                                item.inviteStatus === "INVITED") ||
                            item.inviteStatus == "PENDING"
                        ) {
                            return (
                                <span className={styles.status}>
                                    <span className={styles.pending} />
                                    {translate("Pending")}
                                </span>
                            );
                        }
                        return (
                            <span className={styles.status}>
                                <span className={styles.denied} />
                                {translate("Denied")}
                            </span>
                        );
                    },
                },
                {
                    key: "",
                    displayName: "",
                    permission: true,
                    sortable: true,
                    isNumeric: false,
                    customRender: (item: Object) => {
                        return (
                            !item.phoneNumber &&
                            item.inviteStatus === "INVITED" && (
                                <div className={styles.status}>
                                    <span
                                        className={styles["button-accept"]}
                                        id={`item-accept-${item.uuid}`}
                                        onClick={() =>
                                            this.handleApprove(item.uuid)
                                        }
                                    >
                                        {translate("Accept")}
                                    </span>
                                    <span
                                        className={styles["button-deny"]}
                                        id={`item-deny-${item.uuid}`}
                                        onClick={() =>
                                            this.handleDeny(item.uuid)
                                        }
                                    >
                                        {translate("Deny")}
                                    </span>
                                </div>
                            )
                        );
                    },
                },
            ],
            menuOptions: [
                {
                    id: "toggle",
                    label: item => {
                        if (item.role === "LEADER") {
                            return translate("Make member");
                        } else {
                            return translate("Make leader");
                        }
                    },
                    onClick: item =>
                        this.handleToggleRole(item.uuid, item.role),
                    permission: () => acl("organisations.members.update"),
                },
                {
                    id: "delete",
                    label: () => translate("Delete member"),
                    onClick: item => this.handleDelete(item.uuid),
                    permission: () => acl("organisations.members.delete"),
                },
                {
                    id: "approve",
                    label: () => translate("Approve member"),
                    onClick: item => this.handleApprove(item.uuid),
                    permission: item => {
                        if (
                            acl("organisations.members.approve") &&
                            (item.inviteStatus === "INVITED" ||
                                item.inviteStatus === "MANUALLY_DENIED") &&
                            item.noSmartphone
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
                {
                    id: "deny",
                    label: () => translate("Deny member"),
                    onClick: item => this.handleDeny(item.uuid),
                    permission: item => {
                        if (
                            acl("organisations.members.approve") &&
                            (item.inviteStatus === "INVITED" ||
                                !item.inviteStatus === "MANUALLY_DENIED") &&
                            item.noSmartphone
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                },
            ],
        };

        return (
            <div className={styles.main}>
                <Table
                    tab={selectedTab}
                    config={config}
                    loading={loading}
                    data={this.filteredData}
                    page={page}
                    error={error}
                    translate={translate}
                    getSortString={string => onSortChange(string)}
                    onPageChange={p => onPageChange(p)}
                    onTabChange={t => this.setState({ selectedTab: t })}
                    filter={filter}
                />
                {acl("organisations.members.create") && (
                    <FabContainer>
                        <Fab
                            variant="extended"
                            id="memberlist-add"
                            color="primary"
                            onClick={() => {
                                history.push(`${stripslash(match.url)}/add/`);
                            }}
                        >
                            <AddIcon />
                            {translate("Add member")}
                        </Fab>
                    </FabContainer>
                )}
                {loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter<*>(MemberList);
