// @flow
// Import styles
import styles from "./ConfirmMove.scss";

import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

type Props = {
    translate: *,
    onSubmit: () => *,
    onClose: () => void,
};

const ConfirmMove = ({ onSubmit, onClose, translate }: Props) => {
    const msg = translate(
        "Are you sure you want to move these events/options?",
    );

    return (
        <Dialog onClose={onClose} open>
            <DialogTitle disableTypography>
                <Typography variant="h2">{translate("Move event")}</Typography>
            </DialogTitle>

            <DialogContent>
                <DialogContentText
                    dangerouslySetInnerHTML={{ __html: msg }}
                    className={styles.msg}
                ></DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button id="modal-move-cancel" onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button
                    id="modal-move-confirm"
                    onClick={onSubmit}
                    color="primary"
                >
                    {translate("Move event")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmMove;
