// @flow
import React from "react";
import styles from "./SelectList.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CalenderIcon from "../../Calendar/Icons";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { getColor } from "../../../../utils/VenueColors";

type Props = {
    onSelect: (status: *) => void,
    venueCode: string,
    optionStatus?: string,
    venueUuid?: string,
    type: "ALL" | "EVENT",
    color?: "dark" | "light",
};

type State = {
    status: string,
};

export default class SelectList extends React.Component<Props, State> {
    static getDerivedStateFromProps(props: Props) {
        return {
            status:
                props.type === "ALL" && props.optionStatus
                    ? props.optionStatus
                    : props.type === "EVENT" &&
                      (props.optionStatus === "PUBLIC" ||
                          props.optionStatus === "PRIVATE")
                    ? props.optionStatus
                    : "",
        };
    }

    state = {
        status: "",
    };

    handleChange = (value: *) => {
        const { onSelect } = this.props;
        this.setState({ status: value.value });
        onSelect(value.value);
    };

    render() {
        const { status } = this.state;
        const { venueCode, type, color } = this.props;

        const allOptions = [
            {
                value: "OPTION_CONFIRMED",
                title: "Confirm",
            },
            {
                value: "LIMITED_AVAILABILITY",
                title: "Confirm (Limited)",
            },
            {
                value: "PRIVATE",
                title: "Private",
            },
            {
                value: "PUBLIC",
                title: "Public",
            },
            {
                value: "CANCELED",
                title: "Cancel",
            },
        ];

        const event = [
            {
                value: "PRIVATE",
                title: "Private",
            },
            {
                value: "PUBLIC",
                title: "Public",
            },
        ];

        return (
            <Select
                value={status}
                onChange={event => this.handleChange(event.target)}
                name="status"
                displayEmpty
                className={classnames(
                    styles.element,
                    color === "dark" && styles.dark,
                    color === "light" && styles.light,
                )}
                fullWidth
            >
                {type === "ALL" &&
                    allOptions &&
                    allOptions.map(element => {
                        return (
                            <MenuItem value={element.value} key={element.value}>
                                <CalenderIcon
                                    status={element.value}
                                    color={getColor(venueCode)}
                                />
                                <Typography align="center">
                                    {element.title}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                {type === "EVENT" &&
                    event &&
                    event.map(element => {
                        return (
                            <MenuItem value={element.value} key={element.value}>
                                <CalenderIcon
                                    status={element.value}
                                    color={getColor(venueCode)}
                                />
                                <Typography align="center">
                                    {element.title}
                                </Typography>
                            </MenuItem>
                        );
                    })}
            </Select>
        );
    }
}
