//@flow

import moment from "moment";
import get from "lodash.get";
import React, { PureComponent, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import AsyncFilteredSelect from "../AsyncFilteredSelect";
import { DateField } from "../DatePickers";
import { open } from "../PDFGeneratorV2";
import CalendarPDF from "../PDFGeneratorV2/Documents/Calendar";

type Props = {
    translate: *,
    venues: *,
    onClose: () => void,
    onSubmit: (data: *, exportFor?: *) => Promise<*>,
    loadVenues: () => *,
    id: string,
    /** Organisers */
    organisers: *,
    id: string,
    /** Promoters */
    addPromoter: (value: *) => Promise<*>,
    addPromoterState: *,
    /** data to export */
    data: *,
};

type State = {
    end: *,
    start: *,
    promoterName?: *,
    promoterUuid?: *,
    exportFor?: string,
    venueUuids: Array<*>,
    filename: string,
};

export default class ExportData extends PureComponent<Props, State> {
    state = {
        start: moment().startOf("week").startOf("day").add(1, "days"),
        end: moment().endOf("week").startOf("day").add(1, "days"),
        promoterName: "",
        promoterUuid: "",
        exportFor: "me",
        venueUuids: [],
        filename: "",
    };

    /**
     * Get errors
     */
    getError(key: string) {
        const { addPromoterState } = this.props;

        let transformedKey = key;

        if (addPromoterState.fieldErrors) {
            return get(addPromoterState.fieldErrors, transformedKey);
        }
    }

    sortOrganizer(organisers: [], value: string) {
        let sortedOrganisers = organisers
            .sort((a, b) => {
                const indexA = a.name.indexOf(value.toUpperCase());
                const indexB = b.name.indexOf(value.toUpperCase());

                if (indexA === -1) return 1;
                if (indexB === -1) return -1;

                if (indexA === indexB) {
                    return a.name.localeCompare(b.name);
                }
                return indexA - indexB;
            })
            .splice(0, 10);
        return sortedOrganisers;
    }

    /**
     * Handle change
     */
    handleChange = (uuid: *) => {
        const { venueUuids } = this.state;
        const IVenues = venueUuids;

        if (IVenues) {
            const foundElement = IVenues.find(venueUuid => venueUuid === uuid);

            if (foundElement) {
                IVenues.splice(IVenues.indexOf(uuid), 1);
            } else {
                IVenues.push(uuid);
            }
        }

        this.setState({ venueUuids: IVenues });
    };

    /**
     * Get allVenues that are already checked
     */
    get filterdVenues() {
        const { venueUuids } = this.state;
        const { venues } = this.props;
        const IVenues = venues.data;

        if (IVenues && venueUuids.length < 1) return IVenues;

        if (IVenues && !(venueUuids.length < 1)) {
            const reduced =
                venues &&
                IVenues.reduce(function (filtered, venue) {
                    venueUuids.map(uuid => {
                        if (uuid === venue.uuid) {
                            filtered.push(venue);
                        }
                    });

                    return filtered;
                }, []);

            return reduced;
        }
        return IVenues;
    }

    /**
     * Handle submit
     */

    handleSubmit = (event: *, preview: boolean = false) => {
        const {
            end,
            start,
            promoterUuid,
            promoterName,
            exportFor,
            venueUuids,
            filename,
        } = this.state;
        const { onSubmit, venues, translate } = this.props;
        event.preventDefault();
        const IData = {};
        const venueUuidsSelected =
            venueUuids.length === 0
                ? venues.data.map(venue => venue.uuid)
                : venueUuids;
        IData.startDate = start;
        IData.endDate = moment(end).add(1, "day").subtract(1, "second");
        IData.promoterUuids = exportFor === "organizer" ? [promoterUuid] : null;
        IData.venueUuids = venueUuidsSelected;
        onSubmit(IData, exportFor).then(() => {
            const audience =
                exportFor === "organizer"
                    ? `-${promoterName ? promoterName : ""}`
                    : "";
            const venueCodesJoined = venues.data
                .filter(venue => venueUuidsSelected.includes(venue.uuid))
                .map(venue => venue.code)
                .join("-");
            const defaultFileName = `${start.format("DD-MM-YYYY")}-${end.format(
                "DD-MM-YYYY",
            )}-${venueCodesJoined}${audience}`;
            open(
                <CalendarPDF
                    start={start}
                    end={end}
                    translate={translate}
                    dates={this.props.data.data.dates}
                    venues={venues.data.filter(venue =>
                        venueUuidsSelected.includes(venue.uuid),
                    )}
                />,
                preview
                    ? undefined
                    : (filename.trim().length && filename) || defaultFileName,
            );
        });
    };

    render() {
        const {
            translate,
            onClose,
            id,
            addPromoterState,
            organisers,
            venues,
            data,
        } = this.props;

        const { start, end, exportFor, filename } = this.state;
        return (
            <Fragment>
                <Dialog onClose={onClose} open>
                    <form onSubmit={event => this.handleSubmit(event)}>
                        <DialogTitle disableTypography>
                            <Typography variant="h2" color="inherit">
                                {translate("EXPORT DATA")}
                            </Typography>
                            <DialogContentText>
                                {translate(
                                    "Please choose a date range of which you would like to export planned series and events:",
                                )}
                            </DialogContentText>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={translate("File name:")}
                                        id="file-name"
                                        value={filename}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    .PDF
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={({ target: { value } }) =>
                                            this.setState({
                                                filename: value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl>
                                        <FormLabel style={{ fontSize: ".8em" }}>
                                            {translate("Start")}
                                        </FormLabel>
                                        <DateField
                                            id={`${id}-select-time-start`}
                                            value={start}
                                            onChange={value => {
                                                this.setState({
                                                    start: value,
                                                    end:
                                                        value &&
                                                        value.isSameOrAfter(
                                                            end
                                                                .clone()
                                                                .subtract(
                                                                    1,
                                                                    "days",
                                                                ),
                                                        )
                                                            ? value
                                                                  .clone()
                                                                  .add(
                                                                      1,
                                                                      "days",
                                                                  )
                                                            : end,
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl>
                                        <FormLabel style={{ fontSize: ".8em" }}>
                                            {translate("End")}
                                        </FormLabel>
                                        <DateField
                                            id={`${id}-select-time-end`}
                                            value={end}
                                            onChange={value => {
                                                this.setState({
                                                    end: moment(value),
                                                    start:
                                                        value &&
                                                        value.isSameOrBefore(
                                                            start
                                                                .clone()
                                                                .add(1, "days"),
                                                        )
                                                            ? value
                                                                  .clone()
                                                                  .subtract(
                                                                      1,
                                                                      "days",
                                                                  )
                                                            : start,
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        component="fieldset"
                                        style={{ display: "flex" }}
                                    >
                                        <FormLabel
                                            focused={false}
                                            component="legend"
                                            style={{ fontSize: ".8em" }}
                                        >
                                            {translate("Export for")}
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="position"
                                            name="position"
                                            row
                                            value={exportFor}
                                            onChange={event =>
                                                this.setState({
                                                    exportFor:
                                                        event.target.value,
                                                })
                                            }
                                        >
                                            <FormControlLabel
                                                style={{ width: "50%" }}
                                                value="me"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={translate("Me")}
                                            />

                                            <FormControlLabel
                                                value="organizer"
                                                label={null}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                            />

                                            <AsyncFilteredSelect
                                                style={{
                                                    display: "inline-block",
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id={`${id}-promoter`}
                                                disabled={
                                                    !(exportFor === "organizer")
                                                }
                                                error={
                                                    !!(
                                                        addPromoterState.error ||
                                                        (addPromoterState.fieldErrors &&
                                                            addPromoterState
                                                                .fieldErrors
                                                                .name) ||
                                                        this.getError(
                                                            "promoterUuid",
                                                        )
                                                    )
                                                }
                                                helperText={
                                                    (!!addPromoterState.error &&
                                                        addPromoterState.error) ||
                                                    (addPromoterState.fieldErrors &&
                                                        addPromoterState
                                                            .fieldErrors
                                                            .name) ||
                                                    this.getError(
                                                        "promoterUuid",
                                                    )
                                                }
                                                label={translate("Organiser")}
                                                asyncState={organisers}
                                                onSelect={promoter =>
                                                    this.setState({
                                                        promoterUuid:
                                                            (promoter: any)
                                                                .uuid || null,
                                                        promoterName:
                                                            (promoter: any).uuid
                                                                ? (promoter: any)
                                                                      .name
                                                                : null,
                                                    })
                                                }
                                                itemToString={item =>
                                                    (item && item.name) || ""
                                                }
                                                itemToLabel={item =>
                                                    (item &&
                                                        (item.label ||
                                                            item.name)) ||
                                                    ""
                                                }
                                                filter={(value, items) =>
                                                    this.sortOrganizer(
                                                        items.filter(item =>
                                                            (item: any).name
                                                                .toLowerCase()
                                                                .includes(
                                                                    value.toLowerCase(),
                                                                ),
                                                        ),
                                                        value,
                                                    )
                                                }
                                                fullWidth
                                            />

                                            <FormControlLabel
                                                style={{ width: "100%" }}
                                                value="anonymous"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={translate("Anonymous")}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {venues.loading && (
                                        <div style={{ textAlign: "center" }}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel
                                            component="legend"
                                            focused={false}
                                            style={{ fontSize: ".8em" }}
                                        >
                                            {translate("Venues")}
                                        </FormLabel>
                                        <FormGroup
                                            aria-label="position"
                                            name="position"
                                            onChange={event =>
                                                this.handleChange(
                                                    event.target.value,
                                                )
                                            }
                                            row
                                        >
                                            {!venues.loading &&
                                                venues.data &&
                                                venues.data.map(
                                                    (venue, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                value={
                                                                    venue.uuid
                                                                }
                                                                key={venue.uuid}
                                                                index={index}
                                                                color="default"
                                                                control={
                                                                    <Checkbox color="primary" />
                                                                }
                                                                label={
                                                                    venue.name
                                                                }
                                                                style={{
                                                                    width: "48%",
                                                                }}
                                                            />
                                                        );
                                                    },
                                                )}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {data && data.loading ? (
                                <LinearProgress />
                            ) : undefined}
                        </DialogContent>

                        <DialogActions>
                            <Button id="editoption-cancel" onClick={onClose}>
                                {translate("Cancel")}
                            </Button>
                            <Button
                                id="preview-submit"
                                onClick={event =>
                                    this.handleSubmit(event, true)
                                }
                                color="primary"
                                disabled={data && data.loading}
                            >
                                {translate("Preview")}
                            </Button>
                            <Button
                                id="editoption-submit"
                                type="submit"
                                color="primary"
                                disabled={data && data.loading}
                            >
                                {translate("Export")}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Fragment>
        );
    }
}
