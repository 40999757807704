// @flow
import qs from "qs";

import createApiModule from "../create-api-module";
import type { PathParams } from "./event-issues.d";

/**
 * ShowIssues Module
 */
const { reducer, actions } = createApiModule(
    "eventIssues",
    ({ eventUuid, page }: PathParams) =>
        `/ays-searches/events/${eventUuid}/issues?${qs.stringify({
            page: page - 1,
        })}`,
    {
        method: "POST",
    },
);

export const load =
    (page: number, eventUuid: string, filter: string, force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { eventUuid, page },
                data: { filter },
            }),
            force,
        );
    };
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "No event issues found!";
/** reducer **/
export default reducer;
