// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VdEWOM3P6Q9T0G0h\\+uxhpw\\=\\={color:#651f82;font-size:0.8em}\n", "",{"version":3,"sources":["webpack://./../OrganisationsList.scss"],"names":[],"mappings":"AACA,6BACI,aAAc,CACd,eAAgB","sourcesContent":["@import \"../../../styles/variables\";\n.add {\n    color: #651f82;\n    font-size: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add": "VdEWOM3P6Q9T0G0h+uxhpw=="
};
export default ___CSS_LOADER_EXPORT___;
