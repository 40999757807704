// @flow

import React, { Component } from "react";
import { type Store } from "redux";
import { Provider, connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core";

import theme from "./theme";

import NotificationManager from "../NotificationManager";

import { Provider as TranslationProvider } from "../Translate";
import { Provider as AclProvider } from "../Acl";

import Authenticated from "../Authenticated";
import Login from "../Login";

type Props = {
    store: Store<*, *>,
};

type AppProps = {
    isAuthenticated: boolean,
    language: string,
};

/**
 * Actual container
 */
class App extends Component<AppProps> {
    /**
     * Render
     */
    render() {
        const { isAuthenticated, language } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                    locale={language}
                >
                    <Switch>
                        <Route path="/login/">
                            {() =>
                                isAuthenticated ? (
                                    <Redirect to="/" />
                                ) : (
                                    <Login />
                                )
                            }
                        </Route>
                        <Route>
                            {() =>
                                !isAuthenticated ? (
                                    <Redirect to="/login/" />
                                ) : (
                                    <Authenticated />
                                )
                            }
                        </Route>
                    </Switch>
                    <NotificationManager />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const AppContainer = withRouter(
    connect(({ user, language }) => ({
        isAuthenticated: !!user.get("data"),
        language,
    }))(App),
);

/**
 * Wrapper
 */
const AppWrapper = ({ store }: Props) => (
    <Provider store={store}>
        <TranslationProvider>
            <AclProvider>
                <BrowserRouter>
                    <AppContainer />
                </BrowserRouter>
            </AclProvider>
        </TranslationProvider>
    </Provider>
);

export default AppWrapper;
