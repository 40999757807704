//@flow

import styles from "./Item.scss";

import React, { Component } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Menu from "./Menu";

import type { Config, ConfigItem } from "./Table";

type Props = {
    index: number,
    contentItem: Object,
    translate: *,
    config: Config,
};

export default class Item extends Component<Props> {
    render() {
        const { config, index, contentItem } = this.props;

        return (
            <TableRow
                id={`${config.id}-list-item-${index}`}
                className={styles.item}
            >
                {(config.configItems: any).map(
                    (item: ConfigItem, index: number) => {
                        if (
                            config.onClick &&
                            typeof config.onClick == "function"
                        ) {
                            return (
                                item.permission && (
                                    <TableCell
                                        align={
                                            item.isNumeric ? "right" : undefined
                                        }
                                        key={item.key}
                                        onClick={() =>
                                            //$FlowFixMe
                                            config.onClick(contentItem)
                                        }
                                    >
                                        {item.customRender
                                            ? item.customRender(
                                                  contentItem,
                                                  index,
                                              )
                                            : contentItem[item.key]}
                                    </TableCell>
                                )
                            );
                        } else {
                            return (
                                item.permission && (
                                    <TableCell
                                        align={
                                            item.isNumeric ? "right" : undefined
                                        }
                                        key={item.key}
                                    >
                                        {item.customRender
                                            ? item.customRender(
                                                  contentItem,
                                                  index,
                                              )
                                            : contentItem[item.key]}
                                    </TableCell>
                                )
                            );
                        }
                    },
                )}
                {config.hasMenu && (
                    <TableCell align="right">
                        <Menu
                            id={`memberlist-menu`}
                            item={contentItem}
                            index={index}
                            menuOptions={config.menuOptions}
                        />
                    </TableCell>
                )}
            </TableRow>
        );
    }
}
