// @flow

import React, { Component, Fragment } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import { type State as OrganisationState } from "../../../redux/modules/details.d";
import { type State as AddMemberState } from "../../../redux/modules/details-member-add.d";

import { format as formatPhone } from "../../../utils/phonenumber";

import PhoneNumberField from "../../PhoneNumberField/PhoneNumberField";

type Value = {
    phoneNumber: string,
    description: string,
    noSmartphone: boolean,
    firstName: string,
    lastName: string,
};

type Props = {
    busy?: ?boolean,
    translate: *,
    organisation: OrganisationState,
    addMemberState: AddMemberState,
    load: () => void,
    onSubmit: (values: Value) => *,
    onClose: () => void,
};

type State = Value;

export default class AddMember extends Component<Props, State> {
    // Initial state
    state = {
        phoneNumber: "",
        description: "",
        noSmartphone: false,
        firstName: "",
        lastName: "",
    };

    /**
     * Name getter
     */
    get name(): string {
        const { organisation } = this.props;

        if (!organisation.data) {
            return "...";
        }

        return organisation.data.name;
    }

    /**
     * Get fieldErrors for field
     */
    getErrors(field: string): ?string {
        const { addMemberState, organisation } = this.props;

        if (organisation.fieldErrors && organisation.fieldErrors[field]) {
            return organisation.fieldErrors[field];
        }

        if (addMemberState.fieldErrors && addMemberState.fieldErrors[field]) {
            return addMemberState.fieldErrors[field];
        }
    }

    /**
     * Trigger load on mount
     */
    componentDidMount() {
        const { load } = this.props;

        load();
    }

    /**
     * Render
     */
    render() {
        const {
            busy,
            onSubmit,
            onClose,
            translate,
            organisation,
            addMemberState,
        } = this.props;
        const { phoneNumber, description, noSmartphone, firstName, lastName } =
            this.state;

        return (
            <Dialog onClose={onClose} open>
                <form
                    onSubmit={event => {
                        event.preventDefault();
                        onSubmit({
                            phoneNumber: formatPhone(phoneNumber),
                            description,
                            noSmartphone,
                            firstName,
                            lastName,
                        });
                    }}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h2">
                            {translate("Add member")}
                        </Typography>
                        <DialogContentText>
                            {translate("Invite a new member to %{name}", {
                                name: this.name,
                            })}
                        </DialogContentText>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            {organisation.loading && (
                                <Grid item xs={12}>
                                    <CircularProgress />
                                </Grid>
                            )}
                            {organisation.data && (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <PhoneNumberField
                                            id="modal-addmember-phonenumber"
                                            label={translate(
                                                "Mobile phone number",
                                            )}
                                            onChange={value =>
                                                this.setState({
                                                    phoneNumber: value,
                                                })
                                            }
                                            value={phoneNumber}
                                            error={
                                                !!this.getErrors("phoneNumber")
                                            }
                                            helperText={this.getErrors(
                                                "phoneNumber",
                                            )}
                                            disabled={
                                                busy || organisation.loading
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="modal-addmember-desc"
                                            label={translate("Description")}
                                            onChange={value =>
                                                this.setState({
                                                    description:
                                                        value.target.value,
                                                })
                                            }
                                            value={description}
                                            error={
                                                !!this.getErrors("description")
                                            }
                                            helperText={this.getErrors(
                                                "description",
                                            )}
                                            disabled={
                                                busy || organisation.loading
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="modal-addmember-nosmartphone"
                                                    checked={noSmartphone}
                                                    onChange={(
                                                        event,
                                                        checked,
                                                    ) =>
                                                        this.setState({
                                                            noSmartphone:
                                                                checked,
                                                        })
                                                    }
                                                    disabled={
                                                        busy ||
                                                        organisation.loading
                                                    }
                                                    value={noSmartphone.toString()}
                                                />
                                            }
                                            label={translate("No smartphone")}
                                        />
                                    </Grid>
                                    {noSmartphone && (
                                        <Grid item xs={12}>
                                            <TextField
                                                id="modal-addmember-firstname"
                                                label={translate("First name")}
                                                onChange={value =>
                                                    this.setState({
                                                        firstName:
                                                            value.target.value,
                                                    })
                                                }
                                                value={firstName}
                                                error={
                                                    !!this.getErrors(
                                                        "firstName",
                                                    )
                                                }
                                                helperText={this.getErrors(
                                                    "firstName",
                                                )}
                                                disabled={
                                                    busy || organisation.loading
                                                }
                                                fullWidth
                                            />
                                            <TextField
                                                id="modal-addmember-lastname"
                                                label={translate("Last name")}
                                                onChange={value =>
                                                    this.setState({
                                                        lastName:
                                                            value.target.value,
                                                    })
                                                }
                                                value={lastName}
                                                error={
                                                    !!this.getErrors("lastName")
                                                }
                                                helperText={this.getErrors(
                                                    "lastName",
                                                )}
                                                disabled={
                                                    busy || organisation.loading
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                </Fragment>
                            )}
                            {(organisation.error || addMemberState.error) && (
                                <Grid item xs={12}>
                                    <DialogContentText color="error">
                                        {organisation.error ||
                                            addMemberState.error}
                                    </DialogContentText>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            id="modal-addmember-cancel"
                            disabled={busy || organisation.loading}
                            onClick={onClose}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="modal-addmember-confirm"
                            disabled={busy || organisation.loading}
                            type="submit"
                            color="primary"
                        >
                            {translate("Send invitation")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
