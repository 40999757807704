// @flow

import React, { Component } from "react";
import classnames from "classnames";
// Import styles
import styles from "./MemberPDFModal.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import { type State as MemberQRState } from "../../../redux/modules/event-line-member-qrcode.d";

type Props = {
    translate: *,
    disabled: boolean,
    member: MemberQRState,
    onClose: () => void,
};

export default class MemberPDFModal extends Component<Props> {
    /**
     * Render
     */
    render() {
        const { onClose, member, translate, disabled } = this.props;

        return (
            <Dialog
                onClose={onClose}
                open
                maxWidth="md"
                className={classnames(styles.wrapper)}
            >
                <DialogTitle disableTypography>
                    <Typography variant="h2">{translate("Preview")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <PDFGenerator
                        type="MEMBER"
                        disabled={disabled}
                        loading={member.loading}
                        eventMemberQRCode={member.data}
                        translate={translate}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}
