// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z6ZBw\\+OD7Ycw4aHRbh-YJg\\=\\={margin:2em 5em 5em}.d72WvqmT3DHH\\+eF-ErQ2Fw\\=\\={padding:2em;margin:2em}\n", "",{"version":3,"sources":["webpack://./../EventBriefings.scss"],"names":[],"mappings":"AAAA,6BACI,kBAAmB,CACtB,6BAGG,WAAY,CACZ,UAAW","sourcesContent":[".wrapper {\n    margin: 2em 5em 5em;\n}\n\n.content {\n    padding: 2em;\n    margin: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Z6ZBw+OD7Ycw4aHRbh-YJg==",
	"content": "d72WvqmT3DHH+eF-ErQ2Fw=="
};
export default ___CSS_LOADER_EXPORT___;
