// @flow

import styles from "./OrganisationSelector.scss";

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import SearchIcon from "@material-ui/icons/Search";

import { type State as OrganisationState } from "../../../redux/modules/organisations.d";

import List from "./List";

type Props = {
    /** Busy flag */
    busy: ?boolean,
    /** A translator function */
    translate: *,
    /** When a submit action is done */
    onSubmit: (selected: string) => Promise<void>,
    /** The list database */
    organisations: OrganisationState,
    /** Close / Cancel handler */
    onClose: () => void,
    /** Load organisations */
    load: (filter: string) => void,
    /** Organisations to exclude from the list */
    excluded: string[],
    /** Preferred */
    preferred: string[],
};

type State = {
    filter: string,
};

export default class OrganisationSelector extends Component<Props, State> {
    // Default props
    static defaultProps = {
        busy: false,
        preferred: [],
    };

    // Initial state
    state = {
        filter: "",
    };

    /**
     * On initial load
     */
    componentDidMount() {
        this.load();
    }

    /**
     * Load
     */
    load() {
        const { load } = this.props;
        const { filter } = this.state;
        load(filter);
    }

    /**
     * Render component
     */
    render() {
        const {
            onSubmit,
            onClose,
            translate,
            busy,
            organisations,
            excluded,
            preferred,
        } = this.props;

        const { filter } = this.state;

        return (
            <Dialog onClose={onClose} open>
                <DialogTitle disableTypography>
                    <Typography variant="h2">
                        {translate("Add organisation")}
                    </Typography>
                    <Typography>
                        {translate(
                            "Choose an organisation to add to this line:",
                        )}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="organisationselector-search"
                        disabled={!!busy}
                        value={filter}
                        onChange={({ target: { value } }) =>
                            this.setState(
                                {
                                    filter: value,
                                },
                                this.load.bind(this),
                            )
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        placeholder={translate("Filter by organisation name")}
                        autoFocus
                        fullWidth
                    />
                    <Divider />
                    <div className={styles.scroll}>
                        <List
                            busy={busy}
                            excluded={excluded}
                            preferred={preferred}
                            organisations={organisations}
                            onClick={selected => {
                                onSubmit(selected);
                                onClose();
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="addorganisationlist-cancel"
                        onClick={onClose}
                        disabled={!!busy}
                    >
                        {translate("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
