// @flow
import { connect } from "react-redux";
import { withRouter, type Match } from "react-router";

import {
    load as loadHistory,
    clear as clearHistory,
    ERROR as HISTORY_ERROR,
} from "../../redux/modules/event-history";

import History from "../../components/Events/Calendar/CalendarView/CalendarViewBody/History";
import { withTranslate } from "../Translate";
import { mapState } from "../../redux/utils";
import { bindActionCreators } from "redux";

export default withTranslate(
    withRouter(
        connect(
            ({ eventHistory }) => ({
                eventHistory: mapState(eventHistory, HISTORY_ERROR),
            }),
            (dispatch: *, { match }: { match: Match }) =>
                bindActionCreators(
                    {
                        loadHistory: () => loadHistory(match.params.uuid),
                        clearHistory,
                    },
                    dispatch,
                ),
        )(History),
    ),
);
