// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-recruitment-add.d";

const { reducer, actions } = createApiModule(
    "showRecruitsAdd",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/recruitment-pools/${uuid}/recruits`,
    { method: "POST" },
);

export const load = (uuid: string, uuids: string[]) => (dispatch: *) => {
    return dispatch(
        actions.load({ path: { uuid }, data: { membershipUuids: uuids } }),
    );
};
export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
