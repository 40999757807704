// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

import { type Team } from "../../../redux/modules/event-line-team.d";

const BASE_URL = "ays-series-event-management";

export const Client = {
    /**
     * Fetch all venues
     */
    getShowLineTeam(uuid: string): Promise<Team> {
        return this.instance
            .get(`/${BASE_URL}/event-lines/${uuid}/team`)
            .then(({ data }) => data)
            .catch(normalizeError);
    },
    /**
     * Delete teammember
     */
    deleteTeamMember(teamMemberUuid: string) {
        return this.instance
            .delete(`/${BASE_URL}/teams/members/${teamMemberUuid}`)
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * promote teammember
     */
    promoteTeamMember(memberUuid: string) {
        return this.instance
            .post(
                `/${BASE_URL}/teams/members/${memberUuid}/_make-leader
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * demote teamleader
     */
    demoteTeamLeader(memberUuid: string) {
        return this.instance
            .post(
                `/${BASE_URL}/teams/members/${memberUuid}/_make-member
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * assign parking
     */
    assignParking(memberUuid: string) {
        return this.instance
            .post(
                `/${BASE_URL}/teams/members/${memberUuid}/_assign-premium-parking-spot
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * unassign parking
     */
    unassignParking(memberUuid: string) {
        return this.instance
            .post(
                `/${BASE_URL}/teams/members/${memberUuid}/_unassign-premium-parking-spot
`,
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * add teamleader
     */
    addTeamMember(teamUuid: string, membershipUuids: string[]) {
        return this.instance
            .post(`/${BASE_URL}/teams/${teamUuid}/members`, { membershipUuids })
            .then(() => undefined)
            .catch(normalizeError);
    },
    /**
     * add recruit
     */
    addFromRecruits(teamUuid: string, recruitUuids: string[]) {
        return this.instance
            .post(`/${BASE_URL}/teams/${teamUuid}/recruits`, { recruitUuids })
            .then(() => undefined)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
