// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5PhFkDOFRggv7e7mdbpidw\\=\\= tbody tr{vertical-align:bottom}._5PhFkDOFRggv7e7mdbpidw\\=\\= tbody td{padding:0.5em 0}._5PhFkDOFRggv7e7mdbpidw\\=\\= .Mqttvd-\\+7-PC7ea3Gv6O6Q\\=\\={min-width:12em}._5PhFkDOFRggv7e7mdbpidw\\=\\= ._4fgAYO1JEDt2HsYIpXiV7A\\=\\={min-width:24em;margin-top:-0.75em}._5PhFkDOFRggv7e7mdbpidw\\=\\= ._4fgAYO1JEDt2HsYIpXiV7A\\=\\= .vOfXw2hYG\\+Kl9wzaIgwTkw\\=\\={padding:0}\n", "",{"version":3,"sources":["webpack://./../Options.scss"],"names":[],"mappings":"AAAA,sCAGY,qBAAsB,CAHlC,sCAOY,eAAgB,CAP5B,0DAYQ,cAAe,CAZvB,0DAgBQ,cAAe,CACf,kBAAmB,CAjB3B,uFAoBY,SAAU","sourcesContent":[".element {\n    tbody {\n        tr {\n            vertical-align: bottom;\n        }\n\n        td {\n            padding: 0.5em 0;\n        }\n    }\n\n    .date {\n        min-width: 12em;\n    }\n\n    .venue {\n        min-width: 24em;\n        margin-top: -0.75em;\n\n        .item {\n            padding: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "_5PhFkDOFRggv7e7mdbpidw==",
	"date": "Mqttvd-+7-PC7ea3Gv6O6Q==",
	"venue": "_4fgAYO1JEDt2HsYIpXiV7A==",
	"item": "vOfXw2hYG+Kl9wzaIgwTkw=="
};
export default ___CSS_LOADER_EXPORT___;
