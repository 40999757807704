// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { typeof Client } from "../../utils/api-client/modules/organisation";
import { addNotification } from "./notifications";
import {
    makeLeader as _makeLeader,
    makeMember as _makeMember,
    updateMember,
    load as loadMembers,
} from "./members";
import { type IState, type Action } from "./member.d";

const ERROR_DELETE = "Could not delete member!";
const ERROR_LOAD = "Could not find member!";
const ERROR_UPDATE = "Could not update!";
const ERROR_ROLE_SWITCH = "Could not switch role!";

const PENDING = "member/pending";
const SUCCESS = "member/success";
const FAILURE = "member/failure";
const UPDATE_PENDING = "member/update/pending";
const UPDATE_SUCCESS = "member/update/success";
const UPDATE_FAILURE = "member/update/failure";
const ROLE_SWITCH_PENDING = "member/role-switch/pending";
const ROLE_SWITCH_SUCCESS = "member/role-switch/success";
const ROLE_SWITCH_FAILURE = "member/role-switch/failure";
const DELETE_PENDING = "member/delete/pending";
const DELETE_SUCCESS = "member/delete/success";
const DELETE_FAILURE = "member/delete/failure";

const initialState: IState = (fromJS({
    loading: false,
    data: null,
    error: null,
    fieldErrors: null,
    membershipUuid: null,
}): any);

/**
 * Reducer
 */
export default (state: IState = initialState, { type, payload }: Action): * => {
    if (type === PENDING) {
        invariant(typeof payload === "string", "Payload should be a string!");
        return state.merge({
            loading: true,
            membershipUuid: payload,
        });
    }

    if (type === SUCCESS) {
        invariant(payload, "Payload should be present!");
        return state.merge({
            loading: false,
            data: fromJS(payload),
            error: null,
            fieldErrors: null,
        });
    }

    if (type === FAILURE) {
        return state.merge({
            loading: false,
            error: payload,
        });
    }

    if (type === UPDATE_PENDING) {
        invariant(state.get("data"), "Data should be present for this action!");
        invariant(typeof payload === "object", "Payload should be an object!");

        return (state: any)
            .set("loading", true)
            .setIn(
                ["data", "description"],
                payload ? payload.description : null,
            )
            .setIn(
                ["data", "noSmartphone"],
                payload ? payload.noSmartphone : null,
            )
            .setIn(
                ["data", "userFirstName"],
                payload ? payload.userFirstName : null,
            )
            .setIn(
                ["data", "userLastName"],
                payload ? payload.userLastName : null,
            );
    }

    if (type === UPDATE_SUCCESS) {
        return state.merge({
            loading: false,
            error: null,
            fieldErrors: null,
        });
    }

    if (type === UPDATE_FAILURE) {
        invariant(state.get("data"), "Data should be present for this action!");
        invariant(payload, "Payload should be present!");
        return (state.merge({
            loading: (false: any),
            error: (payload && payload.error) || null,
            fieldErrors:
                (payload &&
                    payload.fieldErrors &&
                    fromJS((payload.fieldErrors: any))) ||
                null,
        }): any).setIn(["data", "description"], payload.description);
    }

    if (type === ROLE_SWITCH_PENDING) {
        invariant(typeof payload === "string", "Wrong payload!");
        invariant(state.get("data"), "No data!");

        return (state: any)
            .set("loading", true)
            .setIn(["data", "membershipRole"], payload);
    }

    if (type === ROLE_SWITCH_SUCCESS) {
        return state.set("loading", false);
    }

    if (type === ROLE_SWITCH_FAILURE) {
        invariant(payload && payload.error && payload.role, "Wrong payload!");
        invariant(state.get("data"), "No data!");

        return state.set("loading", false).mergeDeep({
            error: payload.error,
            data: {
                membershipRole: payload.role,
            },
        });
    }

    if (type === DELETE_PENDING) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === DELETE_SUCCESS) {
        return state.merge({
            loading: false,
        });
    }

    if (type === DELETE_FAILURE) {
        return state.merge({
            loading: false,
            error: payload,
        });
    }

    return state;
};

/**
 * Actioncreator: load
 */
export const load =
    (uuid: string, force: boolean = false) =>
    (dispatch: *, getState: *, client: Client) => {
        const state = getState().member;

        if (state.get("loading")) {
            return;
        }

        if (state.get("membershipUuid") === uuid && !force) {
            return;
        }

        dispatch({
            type: PENDING,
            payload: uuid,
        });

        return client
            .getMembership(uuid)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error =>
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR_LOAD,
                }),
            );
    };

/**
 * ActionCreator: delete
 */
export const remove =
    () =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().member;
        const members = getState().members;

        if (state.get("loading")) {
            return Promise.resolve();
        }

        dispatch({
            type: DELETE_PENDING,
        });

        return client
            .deleteMembership(state.get("membershipUuid"))
            .then(() => {
                dispatch({
                    type: DELETE_SUCCESS,
                });

                return dispatch(
                    loadMembers(
                        members.get("organisationUUID"),
                        members.get("page"),
                        members.get("filter"),
                        members.get("sorting"),
                        true,
                    ),
                );
            })
            .catch(error =>
                dispatch({
                    type: DELETE_FAILURE,
                    payload: (error && error.message) || ERROR_DELETE,
                }),
            );
    };

/**
 * ActionCreator: update
 */
export const update =
    (
        description: string,
        noSmartphone: boolean,
        userFirstName: string,
        userLastName: string,
    ) =>
    (dispatch: *, getState: *, client: Client): Promise<*> => {
        const state = getState().member;
        const members = getState().members;

        if (state.get("loading")) {
            return Promise.resolve();
        }

        dispatch({
            type: UPDATE_PENDING,
            payload: {
                description,
                noSmartphone,
                userFirstName,
                userLastName,
            },
        });

        // Sync page
        dispatch(
            updateMember(
                state.get("membershipUuid"),
                description,
                noSmartphone,
                userFirstName,
                userLastName,
            ),
        );

        return client
            .putMembership(
                state.get("membershipUuid"),
                description,
                noSmartphone,
                userFirstName,
                userLastName,
            )
            .then(() => {
                dispatch({
                    type: UPDATE_SUCCESS,
                });

                dispatch(
                    loadMembers(
                        members.get("organisationUUID"),
                        members.get("page"),
                        members.get("filter"),
                        members.get("sorting"),
                        true,
                    ),
                );
            })
            .catch(error => {
                const payload = {
                    description: state.getIn(["data", "description"]),
                    noSmartphone: state.getIn(["data", "noSmartphone"]),
                    userFirstName: state.getIn(["data", "userFirstName"]),
                    userLastName: state.getIn(["data", "userLastName"]),
                    fieldErrors: null,
                    error: null,
                };

                if (error.messages) {
                    dispatch(
                        addNotification(
                            JSON.stringify(error.messages),
                            "error",
                        ),
                    );
                    payload.fieldErrors = error.messages;
                } else {
                    dispatch(
                        addNotification(
                            (error && error.message) || ERROR_UPDATE,
                            "error",
                        ),
                    );
                    payload.error = (error && error.message) || ERROR_UPDATE;
                }

                dispatch({
                    type: UPDATE_FAILURE,
                    payload,
                });

                dispatch(load(state.get("membershipUuid"), true));

                dispatch(
                    updateMember(
                        state.get("membershipUuid"),
                        state.getIn(["data", "description"]),
                        state.getIn(["data", "noSmartphone"]),
                        state.getIn(["data", "userFirstName"]),
                        state.getIn(["data", "userLastName"]),
                    ),
                );

                throw error;
            });
    };

/**
 * ActionCreator: make leader
 *
 * Calls makeLeader from members, or falls back on internal implementation
 * when not found there.
 */
export const makeLeader = () => (dispatch: *, getState: *) => {
    const state = getState().member;

    if (state.get("loading")) {
        return;
    }

    if (!state.get("data")) {
        return;
    }

    if (state.getIn(["data", "role"]) === "LEADER") {
        return;
    }

    dispatch({
        type: ROLE_SWITCH_PENDING,
        payload: "LEADER",
    });

    dispatch(_makeLeader(state.getIn(["data", "uuid"])))
        .then(() =>
            dispatch({
                type: ROLE_SWITCH_SUCCESS,
                payload: state.getIn(["data", "role"]),
            }),
        )
        .catch(error =>
            dispatch({
                type: ROLE_SWITCH_FAILURE,
                payload: {
                    error: (error && error.message) || ERROR_ROLE_SWITCH,
                    role: state.getIn(["data", "role"]),
                },
            }),
        );
};

/**
 * ActionCreator: makeMember
 *
 * Calls makeMember from members module, or falls back on internal
 * implementation when not found there.
 */
export const makeMember = () => (dispatch: *, getState: *) => {
    const state = getState().member;

    if (state.get("loading")) {
        return;
    }

    if (!state.get("data")) {
        return;
    }

    if (state.getIn(["data", "role"]) === "MEMBER") {
        return;
    }

    dispatch({
        type: ROLE_SWITCH_PENDING,
        payload: "MEMBER",
    });

    dispatch(_makeMember(state.getIn(["data", "uuid"])))
        .then(() =>
            dispatch({
                type: ROLE_SWITCH_SUCCESS,
                payload: state.getIn(["data", "role"]),
            }),
        )
        .catch(error =>
            dispatch({
                type: ROLE_SWITCH_FAILURE,
                payload: {
                    error: (error && error.message) || ERROR_ROLE_SWITCH,
                    role: state.getIn(["data", "role"]),
                },
            }),
        );
};
