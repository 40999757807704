// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-qrcode.d";

const { reducer, actions } = createApiModule(
    "eventQRCode",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/events/${uuid}/qr-data`,
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid } }));
};
export const ERROR = "Could not get QR code!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
