// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { typeof Client } from "../../utils/api-client/modules/event-linetype-briefing";
import {
    type ImmutableState,
    type Action,
    type FullState,
} from "./event-linetype-briefing.d";

const ERROR = "Could not fetch the briefings";
const ERROR_UPDATE = "Could not update the briefings";

const ZONE = "event-briefing-linetypes";
const CLEAR = `${ZONE}/clear`;

const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const UPDATE_SUCCESS = `${ZONE}/update/success`;
const UPDATE_PENDING = `${ZONE}/update/pending`;
const UPDATE_FAILED = `${ZONE}/update/failure`;

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    data: null,
    uuid: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        invariant(typeof payload === "string", `Wrong payload for ${PENDING}`);

        return state.merge({
            loading: true,
            error: null,
            uuid: payload,
        });
    }

    if (type === UPDATE_PENDING) {
        return state.merge({
            updating: true,
            error: null,
        });
    }

    if (type === SUCCESS) {
        invariant(payload, `Wrong payload for ${SUCCESS}`);

        return state.merge({
            loading: false,
            updating: false,
            error: null,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        invariant(typeof payload === "string", `Wrong payload for ${FAILURE}`);

        return state.merge({
            loading: false,
            updating: false,
            error: payload,
            data: null,
        });
    }

    if (type === UPDATE_FAILED) {
        invariant(
            typeof payload === "string",
            `Wrong payload for ${UPDATE_FAILED}`,
        );

        return state.merge({
            loading: false,
            updating: false,
            error: payload,
            //data: null,
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load
 */
export const load =
    (uuid: string, force: boolean = false) =>
    (
        dispatch: (action: Action) => void,
        getState: () => FullState,
        client: Client,
    ): Promise<void> => {
        const { eventLinesBriefing: state } = getState();

        if (!force && (state.get("loading") || state.get("uuid") === uuid)) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: uuid,
        });

        return client
            .getShowLineTypeBriefing(uuid)
            .then(payload => {
                dispatch({
                    type: SUCCESS,
                    payload,
                });
            })
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };

/**
 * Actioncreator: delete member from team
 */
export const update =
    (
        uuid: string,
        briefing: string,
        preBriefing: string,
        preBriefingNotification: boolean,
        briefingNotification: boolean,
        lineUuid: string,
        applySeries: { applyForSeries: boolean, eventUuids: string[] },
    ) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const { eventLinesBriefing: state } = getState();

        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Remove from list and set to loading
        dispatch({
            type: UPDATE_PENDING,
        });
        return client
            .updateShowLineTypeBriefing(
                uuid,
                briefing,
                preBriefing,
                preBriefingNotification,
                briefingNotification,
                lineUuid,
                applySeries,
            )
            .then(() => {
                dispatch({
                    type: UPDATE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true));
            })
            .catch(error => {
                dispatch({
                    type: UPDATE_FAILED,
                    payload: (error && error.message) || ERROR_UPDATE,
                });

                return false;
            });
    };
