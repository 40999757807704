// @flow
import styles from "./DefineEvents.scss";

import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
//import Components
import Options from "./Options/Options";
import SelectList from "./SelectList/SelectList";

type Props = {
    translate: *,
    /** Trigger modal close */
    onClose: () => void,
    /** Trigger */
    onSubmit: (confirmedOptions: *, events: *, seriesName: string) => void,
    options: *,
    loading: boolean,
    id: string,
    /** Define event state */
    defineEvetsState: *,
};

const DefineEvents = ({
    translate,
    onClose,
    loading,
    id,
    onSubmit,
    defineEvetsState,
    options,
}: Props) => {
    const [seriesName, setSeriesName] = useState("");
    const [globalStatus, setGlobalStatus] = useState("");
    const [showPast, setShowPast] = useState(false);

    const optionsRef: * = React.createRef();

    /** Add end data to all options */

    const getOptions = () => {
        if (options.data?.options)
            return options.data.options.map(option => ({
                ...option,
                date: moment(option.date).add(18, "hours"),
                end: moment(option.date).add(20, "hours"),
            }));
        return [];
    };
    return (
        <Dialog open={true} maxWidth="lg" fullWidth className={styles.element}>
            <DialogTitle disableTypography className={styles.title}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" color="inherit">
                            {translate("Define events")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="inherit" style={{ opacity: "0.5" }}>
                            {translate(
                                "Define events based on confirmed options from the series:",
                            )}
                        </Typography>
                        <FormControlLabel
                            label={translate("Show past events")}
                            color="inherit"
                            style={{ opacity: "0.5" }}
                            control={
                                <Checkbox
                                    onChange={e =>
                                        setShowPast(e.target.checked)
                                    }
                                    style={{ color: "#fff" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={
                                options.data?.seriesName
                                    ? styles.disabledInput
                                    : styles.enabledInput
                            }
                            disabled={!!options.data?.seriesName}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="confirmoptions-seriesname"
                            label={translate("Series Name")}
                            value={options.data?.seriesName || seriesName}
                            onChange={({ target: { value } }) =>
                                setSeriesName(value)
                            }
                            margin="normal"
                            fullWidth
                            error={defineEvetsState.fieldErrors?.seriesName}
                            helperText={
                                defineEvetsState.fieldErrors?.seriesName
                            }
                        />
                        <TextField
                            className={styles.disabledInput}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="confirmoptions-optioncode"
                            label={translate("Option Code")}
                            value={options.data?.seriesOptionCode}
                            margin="normal"
                            error={
                                defineEvetsState.fieldErrors?.seriesOptionCode
                            }
                            helperText={
                                defineEvetsState.fieldErrors?.seriesOptionCode
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid
                        color="inherit"
                        style={{
                            opacity: "0.5",
                            display: "flex",
                            alignItems: "center",
                        }}
                        item
                        xs={6}
                        offset={6}
                    >
                        <Typography
                            color="inherit"
                            style={{ flexShrink: 0, marginRight: "1em" }}
                        >
                            {translate("Change all to: ")}
                        </Typography>
                        <SelectList
                            id="confirmoptions-setStatusToAll"
                            onSelect={value => {
                                optionsRef.current &&
                                    optionsRef.current.onAllSelect(value);

                                setGlobalStatus(value);
                            }}
                            ranking={0}
                            index={0}
                            venueCode="SEA-BLUE"
                            optionStatus={globalStatus}
                            type="ALL"
                            color="dark"
                        />
                    </Grid>
                </Grid>
            </DialogTitle>

            {!!loading && (
                <DialogContent className={styles.spinner}>
                    <CircularProgress />
                </DialogContent>
            )}
            {!loading && (
                <Options
                    onSubmit={onSubmit}
                    options={getOptions()}
                    seriesName={options.data?.seriesName || seriesName}
                    optionCode={options.data?.seriesOptionCode}
                    translate={translate}
                    onClose={onClose}
                    loading={loading}
                    id={id}
                    defineEvetsState={defineEvetsState}
                    ref={optionsRef}
                    showPast={showPast}
                />
            )}
        </Dialog>
    );
};

export default DefineEvents;
