// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { withTranslate } from "../Translate";
import { type State as OptionState } from "../../redux/modules/option.d";
import {
    load as loadOption,
    clear as clearOption,
} from "../../redux/modules/option";
import {
    load as confirmOptions,
    clear as clearConfirmOption,
    ERROR as CONFIRM_OPTIONS_ERROR,
} from "../../redux/modules/confirm-options";
import { refetch as refetchCalendar } from "../../redux/modules/calendar";
import { type State as ConfirmOptionsState } from "../../redux/modules/confirm-options.d";
import Cancel from "../../components/Events/Cancel";
import { mapState } from "../../redux/utils";

type Props = {
    onClose: () => void,
    translate: *,
    match: Match,
    refetchCalendar: *,
    confirmOptions: (options: *) => Promise<*>,
    confirmOptionState: ConfirmOptionsState,
    clearConfirmOption: *,
    option: OptionState,
    loadOption: *,
    clearOption: *,
};

class CancelConfirmedOptionContainer extends Component<Props> {
    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    componentDidMount() {
        const { loadOption } = this.props;
        loadOption(this.uuid);
    }

    render() {
        const {
            translate,
            onClose,
            refetchCalendar,
            confirmOptions,
            confirmOptionState,
            option,
        } = this.props;

        return (
            <Cancel
                type="OPTION_CONFIRMED"
                translate={translate}
                onClose={onClose}
                error={confirmOptionState.error}
                loading={confirmOptionState.loading}
                uuid={this.uuid}
                option={option}
                dataLoading={option.loading}
                onSubmit={option =>
                    confirmOptions(option).then(() => {
                        refetchCalendar();
                        onClose();
                        clearOption();
                    })
                }
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ confirmOptions, option }) => ({
                confirmOptionState: mapState(
                    confirmOptions,
                    CONFIRM_OPTIONS_ERROR,
                ),
                option,
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        clearConfirmOption,
                        loadOption,
                        clearOption,
                        confirmOptions: options => confirmOptions(options),
                        refetchCalendar: () => refetchCalendar(),
                    },
                    dispatch,
                ),
        )(CancelConfirmedOptionContainer),
    ),
);
