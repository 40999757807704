// @flow

import React from "react";
import { Page, Document } from "@react-pdf/renderer";

import Footer from "./Footer";
import Table from "./Table";
import process from "./process";

type Props = {
    start: *,
    end: *,
    dates: *[],
    venues: *[],
    translate: *,
};

/**
 * Exports all calendar data
 */
const Calendar = ({ start, end, dates, venues, translate }: Props) => (
    <Document>
        <Page
            size="A4"
            orientation="landscape"
            style={{
                fontSize: 8,
                padding: 2,
                paddingBottom: 30,
                paddingTop: 5,
            }}
        >
            <Table
                venues={venues}
                dates={process(start, end, dates, venues)}
                translate={translate}
            />
            <Footer translate={translate} />
        </Page>
    </Document>
);

export default Calendar;
