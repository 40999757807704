// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";
import {
    load as loadSeriesEventsPaged,
    clear as clearSeriesEventsPaged,
} from "../../redux/modules/series-events-paged";
import { type State as seriesEventsPaged } from "../../redux/modules/series-events-paged.d";
import {
    load as loadEventDetails,
    clear as clearEventDetails,
} from "../../redux/modules/event-details";
import { type State as eventDetails } from "../../redux/modules/event-details.d";
import {
    load as moveEvent,
    clear as clearMoveEvent,
    ERROR as SHOW_DETAILS_MOVE_ERROR,
} from "../../redux/modules/event-move";
import { mapState } from "../../redux/utils";
import { type State as eventMoveUpdate } from "../../redux/modules/event-move.d";
import {
    load as loadCalendar,
    refetch as refetchCalendar,
} from "../../redux/modules/calendar";
import MoveEvent from "../../components/Events/MoveEvent/MoveEvent";
import {
    load as findSeries,
    clear as clearSeries,
    ERROR as SERIES_ERROR,
} from "../../redux/modules/events";
import { type State as SeriesState } from "../../redux/modules/events.d";

import { withTranslate } from "../Translate";

type Props = {
    onClose: () => void,
    translate: *,
    match: Match,
    moveEvent: *,
    eventDetails: eventDetails,
    seriesEventsPaged: seriesEventsPaged,
    eventMoveUpdate: eventMoveUpdate,
    loadSeriesEventsPaged: (uuid: string, page: number) => Promise<*>,
    loadEventDetails: (uuid: string) => Promise<*>,
    clearSeriesEventsPaged: *,
    clearMoveEvent: *,
    clearEventDetails: *,
    loadCalendar: *,
    refetchCalendar: () => Promise<*>,
    findSeries: (filter: string) => *,
    clearSeries: () => void,
    series: SeriesState,
};

type State = {
    seriesUuid: string,
};

/**
 * Move event
 */
class MoveEventContainer extends Component<Props, State> {
    state = {
        seriesUuid: "",
    };

    componentDidMount() {
        const {
            clearMoveEvent,
            clearEventDetails,
            clearSeries,
            loadEventDetails,
            loadSeriesEventsPaged,
        } = this.props;
        clearMoveEvent();
        clearSeriesEventsPaged();
        clearEventDetails();
        clearSeries();
        loadEventDetails(this.uuid).then(result => {
            this.setState({
                seriesUuid: result.payload.seriesUuid,
            });
            loadSeriesEventsPaged(result.payload.seriesUuid, 0);
        });
    }

    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            onClose,
            translate,
            moveEvent,
            clearMoveEvent,
            clearSeriesEventsPaged,
            clearEventDetails,
            refetchCalendar,
            seriesEventsPaged,
            eventMoveUpdate,
            findSeries,
            clearSeries,
            series,
        } = this.props;

        const { seriesUuid } = this.state;

        const handleClose = () => {
            onClose();
            clearMoveEvent();
            clearEventDetails();
            clearSeries();
            clearSeriesEventsPaged();
            refetchCalendar();
        };

        return (
            <MoveEvent
                onClose={handleClose}
                translate={translate}
                series={series}
                events={seriesEventsPaged}
                movedEventState={eventMoveUpdate}
                id={"move-event"}
                loadEvents={seriesEventsPaged}
                findSeries={findSeries}
                seriesUuid={seriesUuid}
                onSubmit={(newSeriesUuid, eventUuids) =>
                    moveEvent(newSeriesUuid, eventUuids).then(() => {
                        onClose();
                        clearMoveEvent();
                        clearEventDetails();
                        clearSeries();
                        clearSeriesEventsPaged();
                        refetchCalendar();
                    })
                }
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ eventMoveUpdate, eventDetails, events, seriesEventsPaged }) => ({
                eventDetails,
                seriesEventsPaged,
                series: mapState(events, SERIES_ERROR, "content"),
                eventMoveUpdate: mapState(
                    eventMoveUpdate,
                    SHOW_DETAILS_MOVE_ERROR,
                ),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadEventDetails: uuid => loadEventDetails(uuid),
                        moveEvent: (uuid, eventUuids) =>
                            moveEvent(uuid, eventUuids),
                        loadSeriesEventsPaged: (uuid, page) =>
                            loadSeriesEventsPaged(uuid, page),
                        clearEventDetails,
                        clearSeriesEventsPaged,
                        clearMoveEvent,
                        loadCalendar: () => loadCalendar(),
                        refetchCalendar,
                        findSeries,
                        clearSeries,
                    },
                    dispatch,
                ),
        )(MoveEventContainer),
    ),
);
