// @flow

// Import libs
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { AsYouType } from "libphonenumber-js";

// Define props
type Props = {
    /** on change handler */
    onChange?: ?(value: string) => void,
    /** country for the formatting of the phone number */
    defaultCountry?: string,
    /** If true, the input will take up the full width of its container. */
    fullWidth?: boolean,
};

// Define state
type State = {
    inputValue: string,
};

/**
 * PhoneNumberField component
 */
export default class PhoneNumberField extends Component<Props, State> {
    static defaultProps = {
        defaultCountry: "BE",
        fullWidth: false,
    };

    state = {
        inputValue: "",
    };

    /**
     * Change handler
     */
    handleChange({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) {
        const { onChange, defaultCountry } = this.props;
        if (onChange) {
            onChange(new AsYouType(defaultCountry).input(value));
        }
    }

    /**
     * Render
     */
    render() {
        /* eslint no-unused-vars: 0 */
        const { defaultCountry, fullWidth, onChange: _, ...other } = this.props;

        return (
            <TextField
                {...other}
                fullWidth={fullWidth}
                type="text"
                onChange={this.handleChange.bind(this)}
            />
        );
    }
}
