// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import stripslash from "../../../utils/stripslash";

import debounce from "lodash.debounce";

import { type State as LinesState } from "../../../redux/modules/lines.d";
import {
    load as loadLines,
    ERROR as LINES_ERROR,
} from "../../../redux/modules/lines";
import { type State } from "../../../redux/modules/organisationlines.d";
import {
    load,
    deleteLine,
    add as addLines,
} from "../../../redux/modules/organisationlines";
import { load as getDefaultOrganisation } from "../../../redux/modules/event-line-default-organisation";
import { load as setDefaultOrganisation } from "../../../redux/modules/set-event-line-default-organisation";
import type { State as DefaultOrganisationState } from "../../../redux/modules/event-line-default-organisation.d";
import LineSelector from "../../../components/LineSelector";
import FilterField from "../../../components/Authenticated/FilterField";
import LineList from "../../../components/Organisations/LineList/LineList";

import { withTranslate } from "../../Translate";
import type { State as DetailsState } from "../../../redux/modules/details.d";
import { ERROR as DETAILS_ERROR } from "../../../redux/modules/details";
import type { Organisation } from "../../../redux/modules/event-line-default-organisation.d";
import { withAcl } from "../../Acl";
import { mapState } from "../../../redux/utils";
import isEqual from "lodash.isequal";

const DEBOUNCE_WAIT = 250;
const DEBOUNCE_MAX_WAIT = 500;

type Props = {
    acl: *,
    translate: *,
    match: Match,
    organisationlines: State,
    lines: LinesState,
    load: (
        organisationUUID: string,
        page: number,
        filter: string,
        sorting: string[],
    ) => void,
    loadLines: () => void,
    deleteLine: (lineUUID: string) => *,
    history: RouterHistory,
    eventLineDefaultOrganisation: DefaultOrganisationState,
    onPageChange: (page: number) => void,
    addLines: (lines: string[]) => *,
    getDefaultOrganisation: (
        lineUUID: string,
        organisationUUID: string,
    ) => Promise<Organisation>,
    setDefaultOrganisation: (
        lineUUID: string,
        organisationUUID: ?string,
    ) => Promise<void>,
    details: DetailsState,
};

type StateType = {
    filter: string,
    sorting: string[],
};

class Lines extends Component<Props, StateType> {
    // Initial state
    state: StateType = {
        filter: "",
        sorting: [],
    };

    /**
     * Trigger load on update
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;

        if (
            prevProps.match.params.page !== match.params.page ||
            !isEqual(prevState, this.state)
        ) {
            this.reload();
        }
    }

    /**
     * Get current lines
     */
    get currentLines(): string[] {
        const { organisationlines } = this.props;

        if (organisationlines.data) {
            return organisationlines.data.content.map(line => line.lineUuid);
        }

        return [];
    }

    handleSortChange(sortString: string[]) {
        this.setState({
            sorting: sortString,
        });
    }

    /**
     * Normalize page parameter
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    /**
     * Trigger update
     */
    reload = debounce(
        () => {
            const { load, match } = this.props;
            const { filter, sorting } = this.state;
            if (this.pageParamIsNumber && match.params.id) {
                load(
                    match.params.id,
                    parseInt(match.params.page),
                    filter,
                    sorting,
                );
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    /**
     * Render
     */
    render() {
        const {
            acl,
            translate,
            match,
            organisationlines,
            lines,
            eventLineDefaultOrganisation,
            onPageChange,
            history,
            deleteLine,
            loadLines,
            addLines,
            getDefaultOrganisation,
            setDefaultOrganisation,
            details,
        } = this.props;

        const { filter } = this.state;

        const canEdit = acl("organisations.update");

        return (
            <div>
                <span style={{ marginTop: "1em", display: "block" }} />
                <FilterField
                    onChange={filter => this.setState({ filter })}
                    placeholder={translate("Filter by name or venues")}
                    id="organisationlines-list"
                    value={filter}
                />
                <LineList
                    onSortChange={this.handleSortChange.bind(this)}
                    canEdit={canEdit}
                    organisationDetails={details}
                    translate={translate}
                    onAdd={() => history.push(`${stripslash(match.url)}/add/`)}
                    lines={organisationlines}
                    onPageChange={onPageChange}
                    onDeleteLine={deleteLine}
                    eventLineDefaultOrganisation={eventLineDefaultOrganisation}
                    onGetDefaultOrganisation={getDefaultOrganisation}
                    onSetDefaultOrganisation={(lineUUID, organisationUUID) =>
                        setDefaultOrganisation(lineUUID, organisationUUID).then(
                            () => this.reload(),
                        )
                    }
                    filter={filter}
                />
                <Switch>
                    <Route path={`${match.path}add/`}>
                        <LineSelector
                            busy={organisationlines.loading}
                            translate={translate}
                            load={loadLines}
                            lines={lines}
                            excluded={this.currentLines}
                            onClose={() =>
                                history.push(`${stripslash(match.url)}/`)
                            }
                            onSubmit={lines =>
                                addLines(lines).then(() =>
                                    history.push(`${stripslash(match.url)}/`),
                                )
                            }
                        />
                    </Route>
                    <Route path={`${match.path}:id/delete/`}>
                        <p>Delete Line Dialog</p>
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withAcl(
    withTranslate(
        withRouter(
            connect(
                ({
                    lines,
                    organisationlines,
                    details,
                    eventLineDefaultOrganisation,
                }) => ({
                    details: {
                        ...mapState(details, DETAILS_ERROR),
                        uuid:
                            details.params &&
                            details.params.path &&
                            details.params.path.uuid,
                    },
                    eventLineDefaultOrganisation,
                    organisationlines: organisationlines.toJS(),
                    lines: mapState(lines, LINES_ERROR, "lineTypes"),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load,
                            loadLines,
                            deleteLine,
                            addLines,
                            getDefaultOrganisation,
                            setDefaultOrganisation,
                        },
                        dispatch,
                    ),
            )(Lines),
        ),
    ),
);
