// @flow

import React, { type Node } from "react";

import Typography from "@material-ui/core/Typography";

type Props = {
    children: Node,
    value: number,
    index: number,
    id: string,
    style?: *,
};
/**
 *
 * @param { Props }
 */
const TabPanel = ({ value, index, children, id, style }: Props) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={id}
        style={style}
    >
        {value === index && children}
    </Typography>
);

export default TabPanel;
