// @flow

import styles from "./Header.scss";

import React from "react";
import Typography from "@material-ui/core/Typography";

type Props = {
    title: string,
    subtitle?: ?string,
};

const Header = ({ title, subtitle }: Props) => (
    <div className={styles.wrapper}>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        <div className={styles.title}>
            <Typography id="header-title" variant="h2">
                {title.length <= 25 ? title : title.substring(0, 25) + "..."}
            </Typography>
        </div>
    </div>
);

export default Header;
