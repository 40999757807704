// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uh5Gd4BTLVobqaJMHH-EfA\\=\\={margin:2em}\n", "",{"version":3,"sources":["webpack://./../Title.scss"],"names":[],"mappings":"AAEA,4BACI,UAAW","sourcesContent":["// Line Staffing title\n\n.wrapper {\n    margin: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "uh5Gd4BTLVobqaJMHH-EfA=="
};
export default ___CSS_LOADER_EXPORT___;
