// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "staffingTemplateLines",
    ({ uuid }) => `/ays-venue-facility-management/lines/${uuid}`,
    { method: "POST" },
);

export const load = (uuid: string, lineTypeUuid: string) => (dispatch: *) =>
    dispatch(actions.load({ path: { uuid }, data: [lineTypeUuid] }));
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Couldn't fetch lines for this staffing template";

export default reducer;
