// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DhRnSkabAiJnnGQuOKyvMg\\=\\=>div{min-width:24em}.DhRnSkabAiJnnGQuOKyvMg\\=\\= .X29a1JEUyXgvjMdO30Pljw\\=\\={text-align:center}.DhRnSkabAiJnnGQuOKyvMg\\=\\= .S426B3DMPBrCa-PZHpwJ7w\\=\\={font-weight:bold}\n", "",{"version":3,"sources":["webpack://./../Cancel.scss"],"names":[],"mappings":"AACA,gCAEQ,cAAe,CAFvB,wDAKQ,iBAAkB,CAL1B,wDAQQ,gBAAiB","sourcesContent":["//\n.element {\n    > div {\n        min-width: 24em;\n    }\n    .spinner {\n        text-align: center;\n    }\n    .bold {\n        font-weight: bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "DhRnSkabAiJnnGQuOKyvMg==",
	"spinner": "X29a1JEUyXgvjMdO30Pljw==",
	"bold": "S426B3DMPBrCa-PZHpwJ7w=="
};
export default ___CSS_LOADER_EXPORT___;
