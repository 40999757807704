// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

export const Client = {
    /**
     * Fetch all venues
     */
    getVenueParking(uuid: string) {
        return this.instance
            .get(`/ays-venue-facility-management/venues/${uuid}/parkings`)
            .then(({ data }) => data)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
