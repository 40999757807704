// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "addPromoter",
    () => `/ays-user-management/promoters`,
    {
        method: "POST",
    },
);

export const load = (name: string) => (dispatch: *) =>
    dispatch(
        actions.load({
            data: {
                name,
            },
        }),
    );
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not add new organiser!";
export default reducer;
