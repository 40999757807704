// @flow

/**
 * Defines a mixin
 *
 * Source: https://gist.github.com/addyosmani/a0ccf60eae4d8e5290a0#comment-1489585
 */
export default function mixin(behaviour: Object, sharedBehaviour: Object = {}) {
    const instanceKeys = Reflect.ownKeys(behaviour);
    const sharedKeys = Reflect.ownKeys(sharedBehaviour);
    const typeTag = Symbol("isa");

    function _mixin(clazz: Object) {
        for (let property of instanceKeys) {
            Object.defineProperty(clazz.prototype, property, {
                value: behaviour[property],
            });
        }
        Object.defineProperty(clazz.prototype, typeTag, { value: true });
        return clazz;
    }
    for (let property of sharedKeys) {
        Object.defineProperty(_mixin, property, {
            value: sharedBehaviour[property],
            // eslint-disable-next-line no-prototype-builtins
            enumerable: sharedBehaviour.propertyIsEnumerable(property),
        });
    }
    Object.defineProperty(_mixin, Symbol.hasInstance, {
        value: i => !!i[typeTag],
    });
    return _mixin;
}
