// @flow

import React from "react";
import {
    Switch,
    Route,
    Redirect,
    type Match,
    type RouterHistory,
    withRouter,
} from "react-router";

import stripslash from "../../utils/stripslash";

import { withTranslate } from "../Translate";
import AdminList from "./AdminList";

type Props = {
    translate: *,
    match: Match,
    history: RouterHistory,
};

const Admin = ({ match, history, translate }: Props) => (
    <Switch>
        <Route path={match.path} exact>
            <Redirect to={`${stripslash(match.url)}/page/1/`} />
        </Route>
        <Route path={`${match.path}page/:page/`}>
            <AdminList
                onSelect={id => history.push(`${stripslash(match.url)}/${id}/`)}
                onPageChange={page =>
                    history.push(`${stripslash(match.url)}/page/${page}/`)
                }
            />
        </Route>
        <Route>
            <div>
                <h1>{translate("404: Page not found!")}</h1>
            </div>
        </Route>
    </Switch>
);

export default withTranslate(withRouter(Admin));
