// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";
import qs from "qs";

export const Client = {
    /**
     * Get preffered lines
     */
    getPrefferedLines(
        organisationUUID: string,
        page: number,
        filter: string,
        sorting: string[],
    ) {
        return this.instance
            .post(
                `/ays-searches/searches/organisations/${organisationUUID}/lines`,
                {
                    filter,
                },
                {
                    params: {
                        page: page - 1,
                        size: 30,
                        sort: sorting,
                    },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                },
            )
            .then(({ data }) => data)
            .catch(normalizeError);
    },

    /**
     * Add preferred lines
     */
    addPreferredLines(organisation: string, lines: string[]) {
        return this.instance
            .post(
                `/ays-organisation-management/organisations/${organisation}/lines`,
                {
                    lineUuids: lines,
                },
            )
            .then(() => undefined)
            .catch(normalizeError);
    },

    /**
     * Delete a preferred line
     */
    deletePrefferedLine(organisationUUID: string, lineUUID: string) {
        return this.instance
            .delete(
                `/ays-organisation-management/organisations/${organisationUUID}/lines/${lineUUID}`,
            )
            .then(({ data }) => data)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
