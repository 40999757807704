// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./organisation-delete.d";

const { reducer, actions } = createApiModule(
    "organisationDelete",
    ({ uuid }: PathParams) =>
        `/ays-organisation-management/organisations/${uuid}`,
    {
        method: "DELETE",
    },
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid } }));
};
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
