// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

import { type Data } from "../../../redux/modules/event-recruitment.d";

export const Client = {
    /**
     * Fetch all venues
     */
    getEventRecruitmentPools(uuid: string): Promise<Data> {
        return this.instance
            .get(
                `/ays-series-event-management/events/${uuid}/recruitment-pools`,
            )
            .then(({ data: { recruitmentPools } }) => recruitmentPools)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
