// @flow

import styles from "./FilterHeader.scss";

import React, { Fragment } from "react";
import Header from "./Header";
import FilterField from "./FilterField";

type Props = {
    title: string,
    subtitle?: ?string,
    placeholder: string,
    onChange: (value: string) => void,
    value: string,
    id: string,
};

const FilterHeader = ({
    title,
    subtitle,
    onChange,
    placeholder,
    value,
    id,
}: Props) => (
    <Fragment>
        <Header title={title} subtitle={subtitle} />
        <div className={styles.field}>
            <FilterField
                onChange={onChange}
                placeholder={placeholder}
                id={id}
                value={value}
            />
        </div>
    </Fragment>
);

export default FilterHeader;
