// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";
import moment from "moment";
import qs from "qs";

export const Client = {
    /**
     * Events getter
     */
    getEvents(
        page: number,
        filter: string = "",
        sorting: string[],
        tab: number,
    ): Promise<*> {
        const now = new Date();

        return this.instance
            .post(
                "/ays-searches/searches/series-events",
                {
                    filter,
                    startDate:
                        tab === 0
                            ? moment
                                  .utc(now.setHours(now.getHours() - 6))
                                  .format()
                            : null,
                    endDate:
                        tab === 1
                            ? moment
                                  .utc(now.setHours(now.getHours() - 6))
                                  .format()
                            : null,
                },
                {
                    params: {
                        page: page - 1,
                        sort: sorting,
                    },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                },
            )
            .then(({ data }) => data)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
