// @flow

import React, { PureComponent, type Node } from "react";
import Downshift from "downshift";
import debounce from "lodash.debounce";
import styles from "./AsyncFiltered.scss";

import {
    TextField,
    MenuList,
    MenuItem,
    InputAdornment,
    CircularProgress,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

type ItemToString<ItemType> = (item: ItemType) => string;
type ItemToLabel<ItemType> = (
    item: ItemType,
    matchWord: string,
) => string | Node;
type GetItemProps<ItemType> = ({ item: ItemType }) => Object;

type MenuProps<ItemType> = {
    id: string,
    data: ItemType[],
    highlightedIndex: ?number,
    itemToLabel: ItemToLabel<ItemType>,
    getItemProps: GetItemProps<ItemType>,
    inputValue: string,
};

type AsyncState<ItemType> = {
    loading: boolean,
    error: ?string,
    data?: ?(ItemType[]),
};

type Props<ItemType> = {
    asyncState: AsyncState<ItemType>,
    id: string,
    lookup: (filter: string) => *,
    onSelect: (item: ItemType) => void,
    itemToString: ItemToString<ItemType>,
    itemToLabel: ItemToLabel<ItemType>,
    filter: (filter: string, items: ItemType[]) => ItemType[],
    currentItem?: *,
    onOuterClick?: () => void,
};

/**
 * Menu component
 */
const Menu = ({
    id,
    data,
    itemToLabel,
    getItemProps,
    highlightedIndex,
    inputValue,
}: MenuProps<*>) => {
    return (
        <MenuList id={`${id}-menu`}>
            {data.map((item, index) => (
                <MenuItem
                    {...getItemProps({
                        item,
                    })}
                    key={index}
                    id={`${id}-item-${index}`}
                    selected={highlightedIndex === index}
                >
                    {itemToLabel(item, inputValue)}
                </MenuItem>
            ))}
        </MenuList>
    );
};

/**
 * An async filter select field
 */
export default class AsyncFiltered extends PureComponent<Props<*>> {
    // Default props
    static defaultProps = {
        filter: (_: string, items: *) => items,
        lookup: () => undefined,
        currentItem: null,
    };

    lookup = debounce(this.props.lookup, 250);

    /**
     * Get the filtered items
     */
    getListing(inputValue: string, getItemProps: *, highlightedIndex: *) {
        const { asyncState, id, itemToLabel, filter } = this.props;

        if (!asyncState.data) {
            return null;
        }

        const filtered = filter(inputValue, asyncState.data);

        if (!filtered.length) {
            return null;
        }

        return (
            <Menu
                id={id}
                data={filtered}
                itemToLabel={itemToLabel}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                inputValue={inputValue}
            />
        );
    }

    preventReset(state, changes) {
        if (changes.type !== Downshift.stateChangeTypes.changeInput) {
            delete changes.inputValue;
        }

        return changes;
    }

    /**
     * Render
     */
    render() {
        /* eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }] */

        const {
            id,
            onSelect,
            itemToString,
            asyncState,
            lookup,
            filter,
            itemToLabel,
            currentItem,
            onOuterClick,
            ...rest
        } = this.props;

        return (
            <Downshift
                onChange={onSelect}
                itemToString={itemToString}
                defaultSelectedItem={currentItem}
                stateReducer={this.preventReset}
                onOuterClick={onOuterClick}
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    highlightedIndex,
                    inputValue,
                    openMenu,
                }) => (
                    <div className={styles.element}>
                        <TextField
                            {...rest}
                            InputProps={{
                                ...getInputProps({
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                className={styles.searchIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: asyncState.loading && (
                                        <InputAdornment position="end">
                                            <CircularProgress size={16} />
                                        </InputAdornment>
                                    ),

                                    onFocus: openMenu,

                                    onChange: ({ target: { value } }) =>
                                        this.lookup(value),

                                    id: `${id}-input`,
                                }),
                                disableUnderline: true,
                            }}
                            id={`${id}-text-field`}
                        />
                        {isOpen &&
                            this.getListing(
                                inputValue,
                                getItemProps,
                                highlightedIndex,
                            )}
                    </div>
                )}
            </Downshift>
        );
    }
}
