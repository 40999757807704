// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eS\\+FP57mlC7oMeypBlZO3A\\=\\=>div:nth-child(2){overflow-y:visible}\n", "",{"version":3,"sources":["webpack://./../AddGroup.scss"],"names":[],"mappings":"AAAA,8CAEQ,kBAAmB","sourcesContent":[".element {\n    & > div:nth-child(2) {\n        overflow-y: visible;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "eS+FP57mlC7oMeypBlZO3A=="
};
export default ___CSS_LOADER_EXPORT___;
