// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./option.d";

const { reducer, actions } = createApiModule(
    "option",
    ({ uuid }: PathParams) => `/ays-series-event-management/options/${uuid}`,
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(actions.load({ path: { uuid } }));
};
export const refetch = actions.refetch;
export const clear = actions.clear;

export default reducer;
