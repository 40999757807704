// @flow
import styles from "./ListItem.scss";
import classnames from "classnames";
import React, { Component } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { type EventLineOrganisation } from "../../../redux/modules/event-line-organisations.d";

import IconMenu from "../../IconMenu/IconMenu";

type Props = {
    id: string,
    index: number,
    translate: *,
    organisation: EventLineOrganisation,
    onDelete: () => void,
    onDeactivate: () => void,
    onActivate: () => void,
    canEdit: boolean,
    active: boolean,
    isMyMember: boolean,
    canDelete: boolean,
};

export default class ListItem extends Component<Props> {
    /**
     * Get the label of the toggle
     */
    get toggleLabel(): string {
        const { organisation, translate } = this.props;

        if (organisation.organisationStatus === "ACTIVE") {
            return translate("Deactivate organisation");
        }

        return translate("Activate organisation");
    }

    /**
     * Test if org is primary
     */
    get isPrimary() {
        const { organisation } = this.props;
        return organisation.organisationType === "PRIMARY";
    }

    /**
     * Menu options getter
     */
    get menuOptions() {
        const {
            translate,
            organisation,
            onDelete,
            onActivate,
            onDeactivate,
            canEdit,
            canDelete,
        } = this.props;

        const options = [];

        if (canEdit && !this.isPrimary) {
            options.push({
                id: `toggle-organisation`,
                label: this.toggleLabel,
                onClick: () =>
                    organisation.organisationStatus === "ACTIVE"
                        ? onDeactivate()
                        : onActivate(),
            });
        }

        if (canDelete) {
            options.push({
                id: `delete-organisation`,
                label: translate("Delete organisation"),
                onClick: () => onDelete(),
            });
        }

        return options;
    }

    /**
     * Render
     */
    render() {
        const {
            organisation,
            index,
            id,
            canEdit,
            canDelete,
            active,
            isMyMember,
        } = this.props;

        return (
            <TableRow
                id={`${id}-item-${index}`}
                key={organisation.uuid}
                className={classnames(styles.teammember, {
                    [styles.isMyMember]: isMyMember,
                })}
            >
                <TableCell
                    className={classnames(styles.membername, {
                        [styles.isMyMember]: isMyMember,
                    })}
                >
                    {organisation.organisationName &&
                    organisation.organisationName.length > 50
                        ? `${organisation.organisationName.substring(0, 50)}...`
                        : organisation.organisationName}
                </TableCell>

                <TableCell
                    style={{ textAlign: "right" }}
                    className={classnames(styles.menu, {
                        [styles.isMyMember]: isMyMember,
                    })}
                >
                    <div className={styles.menuinner}>
                        {organisation.organisationType === "PRIMARY"
                            ? organisation.organisationType
                            : organisation.organisationStatus}
                        {((canEdit && !this.isPrimary) || canDelete) &&
                        active ? (
                            <IconMenu
                                id={`${id}-menu-${index}`}
                                options={this.menuOptions}
                                className={styles.icon}
                            />
                        ) : (
                            <div
                                style={{
                                    visibility: "hidden",
                                    opacity: "0.0",
                                    overflow: "hidden",
                                }}
                            >
                                <IconMenu
                                    id={`${id}-menu-${index}`}
                                    options={this.menuOptions}
                                />
                            </div>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        );
    }
}
