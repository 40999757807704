// @flow

import React from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const style = {
    border: "0.06em solid #888",
    borderRadius: "0.1em",
    height: ".6em",
    width: ".65em",
};

const Collapse = () => <ChevronLeft style={style} />;

export default Collapse;
