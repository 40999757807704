// @flow

import styles from "./Organisations.scss";

import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { type State as SelectedState } from "../../../redux/modules/event-line-organisations.d";
import { type State as OrganisationsState } from "../../../redux/modules/organisations.d";
import { type User as UserState } from "../../../redux/modules/user.d";
import OrganisationsList from "./OrganisationsList";
import OrganisationSelector from "../../Modals/AddOrganisation";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import { Box } from "@material-ui/core";

type Props = {
    id: string,
    variant?: "default" | "secondary",
    organisations: OrganisationsState,
    selected: SelectedState,
    acl: *,
    translate: *,
    user: UserState,
    active: boolean,
    disabled: boolean,
    load: (filter: string) => void,
    loadSelected: () => void,
    clearSelected: () => void,
    onDelete: (uuid: string) => void,
    onDeactivate: (uuid: string) => void,
    onActivate: (uuid: string) => void,
    onAdd: (uuid: string, type: string) => Promise<void>,
};

type State = {
    open: boolean,
    type: string,
};

export default class Organisations extends Component<Props, State> {
    // Initial state
    state = {
        open: false,
        type: "backup",
    };

    /**
     * Clear and load on mount
     */
    componentDidMount() {
        const { clearSelected } = this.props;
        clearSelected();
        this.load();
    }

    /**
     * Load on update
     */
    componentDidUpdate() {
        this.load();
    }

    /**
     * Load
     */
    load() {
        const { loadSelected } = this.props;
        loadSelected();
    }

    /**
     * Render
     */
    render() {
        const {
            id,
            acl,
            active,
            onDelete,
            organisations,
            selected,
            onDeactivate,
            load,
            onActivate,
            translate,
            onAdd,
            disabled,
            user,
            variant = "default",
        } = this.props;

        const { open, type } = this.state;

        const canAdd = !disabled && acl("events.organisations.create");
        const canEdit = !disabled && acl("events.organisations.update");
        const canDelete = !disabled && acl("events.organisations.delete");

        return (
            <div className={classnames(styles.wrapper)}>
                <Grid container spacing={3}>
                    <Grid item sm={variant === "default" ? 12 : 3}>
                        <Typography>
                            <Box fontWeight="600">
                                {translate("Organisations")}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item sm={variant === "default" ? 12 : 9}>
                        {selected.data && (
                            <OrganisationsList
                                id={`${id}-selected`}
                                onToggle={type => {
                                    this.setState({
                                        open: !this.state.open,
                                        type: type,
                                    });
                                }}
                                user={user}
                                onActivate={onActivate}
                                onDeactivate={onDeactivate}
                                onDelete={onDelete}
                                active={active}
                                translate={translate}
                                organisations={selected.data}
                                canAdd={canAdd}
                                canEdit={canEdit}
                                canDelete={canDelete}
                            />
                        )}
                    </Grid>
                </Grid>
                {(!selected.uuid || selected.loading) && <CircularProgress />}
                {open && canAdd && (
                    <OrganisationSelector
                        id={`${id}-available`}
                        busy={selected.loading}
                        onSubmit={uuid => onAdd(uuid, type)}
                        onClose={() =>
                            this.setState({
                                open: false,
                            })
                        }
                        translate={translate}
                        organisations={organisations}
                        load={load}
                        excluded={
                            (selected.data &&
                                selected.data.map(
                                    organisation =>
                                        organisation.organisationUuid,
                                )) ||
                            []
                        }
                    />
                )}
            </div>
        );
    }
}
