// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    load,
    ERROR as ORGANISATIONS_ERROR,
} from "../../../redux/modules/organisations";

import {
    load as loadSelected,
    clear as clearSelected,
    remove,
    add,
    activate,
    deactivate,
} from "../../../redux/modules/event-line-organisations";

import Organisations from "../../../components/Events/Organisations";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
import { mapState } from "../../../redux/utils";

export default withTranslate(
    withAcl(
        connect(
            // Map state to props
            ({ organisations, eventLineOrganisations, user }) => ({
                organisations: mapState(organisations, ORGANISATIONS_ERROR),
                user: user.toJS(),
                selected: eventLineOrganisations.toJS(),
            }),
            // Map dispatch to props
            (dispatch: *, { eventLineUuid }) =>
                bindActionCreators(
                    {
                        load: filter => load(1, filter, []),
                        loadSelected: () => loadSelected(eventLineUuid),
                        clearSelected,
                        onDelete: remove,
                        onDeactivate: deactivate,
                        onActivate: activate,
                        onAdd: add,
                    },
                    dispatch,
                ),
        )(Organisations),
    ),
);
