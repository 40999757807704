// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WaUKZRQ1PMN6Ff-fNdkYsQ\\=\\= .MuiList-root>hr{display:none}.WaUKZRQ1PMN6Ff-fNdkYsQ\\=\\= .MuiList-root li+hr{display:block}.MuiPopover-root{pointer-events:none}.MuiPopover-root>*{pointer-events:auto}\n", "",{"version":3,"sources":["webpack://./../ContextMenu.scss"],"names":[],"mappings":"AAAA,6CAGY,YAAa,CAHzB,gDAOY,aAAc,CACjB,iBAKL,mBAAoB,CADxB,mBAIQ,mBAAoB","sourcesContent":[".contextMenu {\n    :global .MuiList-root {\n        > hr {\n            display: none;\n        }\n\n        li + hr {\n            display: block;\n        }\n    }\n}\n\n:global .MuiPopover-root {\n    pointer-events: none;\n\n    > * {\n        pointer-events: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextMenu": "WaUKZRQ1PMN6Ff-fNdkYsQ=="
};
export default ___CSS_LOADER_EXPORT___;
