// @flow

// Import styles
import styles from "../PDFGenerator.scss";
import logo from "../../../logo.svg";
// Import libs
import React, { Component } from "react";
import VenueBox from "../../VenueBox/VenueBox";
import classnames from "classnames";
import moment from "moment";
import Legend from "../../Events/Calendar/Legend";
import CalendarIcon from "../../Events/Calendar/Icons";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { getColor } from "../../../utils/VenueColors";

// Define props
type Props = {
    translate: *,
    data: *,
    start: *,
    end: *,
    translate: *,
    venues: *,
    exportFor?: string,
};

/**
 * A component that generates a pdf
 */
export default class ExportDataPDF extends Component<Props> {
    eventsByVenue(day: *, venue: *) {
        const {
            data: { data },
        } = this.props;
        let val = [];
        const dayToCheck = moment(day).format("YYYY-MM-DD");

        const foundDays =
            dayToCheck &&
            data &&
            data.dates &&
            data.dates.filter(foundDay => foundDay.date === dayToCheck);

        if (foundDays && foundDays.length > 0) {
            val = foundDays[0].venues.filter(el => el.venueUuid === venue.uuid);
        }
        if (val.length > 0) {
            return val;
        } else {
            return [];
        }
    }

    /**Truncate  */
    truncate = (length: number = 35, ...args: Array<*>) => {
        const concatenated = args.filter(string => !!string).join("");

        if (concatenated.length > length) {
            return `${concatenated}`.substring(0, 25) + "...";
        } else {
            return concatenated;
        }
    };

    /**
     * Render
     */
    render() {
        const { start, end, venues, exportFor, translate } = this.props;

        return (
            <Paper id="pdf" className={styles.exportDataWrapper} elevation={2}>
                <div className={classnames(styles.header)}>
                    <img src={logo} />
                </div>
                <Legend variant="row" translate={translate} />
                <Table
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{ tableLayout: "auto" }}
                >
                    <TableHead wrap="nowrap">
                        <TableRow>
                            <TableCell
                                style={{
                                    width: "8%",
                                    whiteSpace: "normal",
                                }}
                            >
                                <div
                                    style={{
                                        width: "10em",
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography style={{ display: "inline" }}>
                                        {translate("DATE")}
                                    </Typography>
                                </div>
                            </TableCell>
                            {(venues || []).map(venue => (
                                <TableCell
                                    key={venue.uuid}
                                    style={{
                                        textAlign: "center",
                                        minWidth: "12,5px",
                                        maxWidth: "12,5px",
                                        width: "12.5px",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    <VenueBox venue={venue} />

                                    <Typography>{venue.name}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <Divider />
                    <TableBody>
                        {Array.from(
                            moment
                                // $FlowFixMe[prop-missing]
                                .range(
                                    moment(start).format("YYYY/MM/DD"),
                                    moment(end).format("YYYY/MM/DD"),
                                )
                                .by("day"),
                        ).map(day => (
                            <TableRow
                                style={{
                                    minHeight: "3em",
                                }}
                                key={moment(day).format("YYYY/MM/DD")}
                            >
                                <TableCell
                                    style={{
                                        width: "10%",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    <div style={{ width: "10em" }}>
                                        <Typography
                                            style={{
                                                opacity: "0.5",
                                                display: "inline-block",
                                                width: "50%",
                                            }}
                                        >
                                            {moment(day).format("ddd")}
                                        </Typography>

                                        <Typography
                                            style={{
                                                fontWeight: "bold",
                                                display: "inline-block",
                                                textAlign: "right",
                                                width: "50%",
                                            }}
                                        >
                                            {moment(day).format("DD/MM/YY")}
                                        </Typography>
                                    </div>
                                </TableCell>

                                {(venues || []).map(venue =>
                                    this.eventsByVenue(day, venue).length >
                                    0 ? (
                                        this.eventsByVenue(day, venue).map(
                                            eventInVenue => {
                                                return (
                                                    <TableCell
                                                        key={eventInVenue.date}
                                                        style={{
                                                            minWidth: "12,5px",
                                                            maxWidth: "12,5px",
                                                            width: "12.5px",
                                                            whiteSpace:
                                                                "normal",
                                                        }}
                                                    >
                                                        {eventInVenue.events.map(
                                                            (event, index) => {
                                                                return (
                                                                    <div
                                                                        className={classnames(
                                                                            styles.cellContent,
                                                                            exportFor ===
                                                                                "anonymous" &&
                                                                                styles.anonymous,
                                                                        )}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <CalendarIcon
                                                                            status={
                                                                                event.status
                                                                            }
                                                                            color={getColor(
                                                                                venue.code,
                                                                            )}
                                                                        />
                                                                        <Typography>
                                                                            {event &&
                                                                            event.seriesName &&
                                                                            (event.status ===
                                                                                "OPTION_CONFIRMED" ||
                                                                                "LIMITED_AVAILABILITY") &&
                                                                            event.name
                                                                                ? this.truncate(
                                                                                      35,
                                                                                      `${event.name} - `,
                                                                                      event.seriesName,
                                                                                      event.internal &&
                                                                                          ` (${event.internal})`,
                                                                                  )
                                                                                : event &&
                                                                                  event.seriesName &&
                                                                                  this.truncate(
                                                                                      35,
                                                                                      event.seriesName,
                                                                                      event.internal &&
                                                                                          ` (${event.internal})`,
                                                                                  )}
                                                                        </Typography>
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </TableCell>
                                                );
                                            },
                                        )
                                    ) : (
                                        <TableCell />
                                    ),
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}
