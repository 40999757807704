// @flow

import React, { useState, useEffect } from "react";
// style
import styles from "./style.module.scss";

import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import AsyncSelect from "../../AsyncSelect";
import Grid from "@material-ui/core/Grid";

import { type Venue } from "../../../redux/modules/venues.d";
import { type Profile } from "../../../redux/modules/user.d";
import { type LineType } from "../../../redux/modules/facilities-lineTypes.d";
import { type State } from "../../../redux/modules/add-staffing-template.d";

import VenueBox from "../../VenueBox/VenueBox";

// ROLES
import {
    ROLE_ADMINISTRATOR,
    ROLE_FACILITY_MANAGER,
    ROLE_EVENT_MANAGER,
} from "../../../permissions";

type Props = {
    onClose: () => void,
    onSubmit: () => void,
    translate: *,
    venues: Venue[],
    lineTypes: LineType[],
    user: Profile,
    addState: State,
};

const Add = ({
    onClose,
    onSubmit,
    translate,
    user,
    lineTypes: { lineTypes },
    venues: { venues },
    addState,
}: Props) => {
    const [name, setName] = useState("");
    const [venueUuid, setVenueUuid] = useState("");
    const [lineTypeUuid, setLineTypeUuid] = useState("");
    const [_venues, setVenues] = useState(venues);
    const [_lineTypes, setLineTypes] = useState(lineTypes);

    const filterOnEventManager = () => {
        const filteredVenues = _venues.filter(venue =>
            user["event_manager_venues"].includes(venue.uuid),
        );
        setVenues(filteredVenues);
    };

    const filterOnFacilityManager = () => {
        const filteredVenues = _venues.filter(venue =>
            user["facility_manager_venues"].includes(venue.uuid),
        );

        const filteredLineTypes = _lineTypes.filter(lineType =>
            user["facility_manager_line_types"].includes(lineType.uuid),
        );

        setVenues(filteredVenues);
        setLineTypes(filteredLineTypes);
    };

    const userRole = () => {
        const roles = user.authorities;

        if (roles.includes(ROLE_ADMINISTRATOR)) return;
        if (roles.includes(ROLE_EVENT_MANAGER)) {
            filterOnEventManager();
            return;
        }
        if (roles.includes(ROLE_FACILITY_MANAGER)) {
            filterOnFacilityManager();
            return;
        }
    };

    useEffect(() => {
        userRole();
    }, []);

    const fieldErrors = addState.fieldErrors;
    return (
        <Dialog open onClose={onClose}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit(name, venueUuid, lineTypeUuid);
                }}
            >
                <DialogTitle disableTypography className={styles.title}>
                    <Typography variant="h2" color="inherit">
                        {translate("Add Staffing Template")}
                    </Typography>
                    <Typography color="inherit" className={styles.subtitle}>
                        {translate("Add a new staffing template:")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                id="modal-addstaffingtemplate-name"
                                label={translate("Staffing template name")}
                                value={name}
                                error={fieldErrors?.name}
                                helperText={fieldErrors?.name}
                                onChange={({ target: { value } }) =>
                                    setName(value)
                                }
                                disabled={addState.loading}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncSelect
                                id="modal-addstaffingtemplate-venue"
                                label={translate("Venue")}
                                fullWidth
                                value={venueUuid}
                                disabled={addState.loading}
                                error={fieldErrors?.venue}
                                options={_venues.map(venue => [
                                    venue.uuid,

                                    <Grid
                                        key={venue.uuid}
                                        container
                                        alignItems="center"
                                        spacing={3}
                                    >
                                        <Grid item xs>
                                            <Typography>
                                                {venue.name}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            justifyContent="flex-end"
                                            container
                                        >
                                            <VenueBox venue={venue} />
                                        </Grid>
                                    </Grid>,
                                ])}
                                onChange={venueUuid => setVenueUuid(venueUuid)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncSelect
                                id="modal-addstaffingtemplate-facility"
                                label={translate("Facility")}
                                fullWidth
                                value={lineTypeUuid}
                                disabled={addState.loading}
                                error={fieldErrors?.lineType}
                                options={_lineTypes.map(lineType => [
                                    lineType.uuid,
                                    <Typography key={lineType.uuid}>
                                        {lineType.name}
                                    </Typography>,
                                ])}
                                onChange={lineTypeUuid =>
                                    setLineTypeUuid(lineTypeUuid)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {addState.error && (
                                <FormHelperText
                                    id={`add-staffingtemplate-helper-text`}
                                    error
                                >
                                    {addState.error}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="modal-addstaffingtemplate-cancel"
                        onClick={onClose}
                        disabled={addState.loading}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id="modal-addstaffingtemplate-confirm"
                        type="submit"
                        color="primary"
                        disabled={addState.loading}
                        className={styles.action}
                    >
                        {translate("Add Staffing Template")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default Add;
