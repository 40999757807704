// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fe5i9-6TsujTLlGL1D5aOg\\=\\={float:right}.Bey3xQ3MtbsuLU3hVG4asg\\=\\={padding-bottom:1.2em}.Bey3xQ3MtbsuLU3hVG4asg\\=\\= span{color:#181c20}\n", "",{"version":3,"sources":["webpack://./../ConfirmMove.scss","webpack://./../../../../styles/_variables.scss"],"names":[],"mappings":"AAMA,4BACI,WAAY,CACf,4BAGG,oBAAqB,CADzB,iCAGQ,aCPM","sourcesContent":["/**\n * Modals styling\n */\n\n@import \"../../../styles/variables\";\n\n.actionButtons {\n    float: right;\n}\n\n.msg {\n    padding-bottom: 1.2em;\n    span {\n        color: $onyx;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": "fe5i9-6TsujTLlGL1D5aOg==",
	"msg": "Bey3xQ3MtbsuLU3hVG4asg=="
};
export default ___CSS_LOADER_EXPORT___;
