// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, type Match } from "react-router";

import {
    load,
    ERROR as ADMIN_USER_ERROR,
} from "../../redux/modules/admin-user";
import {
    load as remove,
    ERROR as ADMIN_USER_ERROR_DELETE,
} from "../../redux/modules/admin-user-delete";

import { type State as AdminUserState } from "../../redux/modules/admin-user.d";
import { type State as DeleteUserState } from "../../redux/modules/admin-user-delete.d";

import _DeleteUser from "../../components/Modals/DeleteUser/DeleteUser";

import { withTranslate } from "../Translate";
import { mapState } from "../../redux/utils";

type Props = {
    translate: *,
    match: Match,
    load: (uuid: string) => void,
    remove: () => Promise<void>,
    adminuser: AdminUserState,
    deleteUserState: DeleteUserState,
    onBack: () => void,
};

class DeleteUser extends Component<Props> {
    /**
     * Initial load
     */
    componentDidMount() {
        this.reload();
    }

    /**
     * Reload on props change
     */
    componentDidUpdate() {
        this.reload();
    }

    /**
     * Reload
     */
    reload() {
        const {
            match: {
                params: { id },
            },
            load,
        } = this.props;

        if (id) {
            load(id);
        }
    }

    /**
     * Render
     */
    render() {
        const { adminuser, onBack, translate, remove, deleteUserState } =
            this.props;

        return (
            <_DeleteUser
                translate={translate}
                adminuser={adminuser}
                deleteUserState={deleteUserState}
                onSubmit={() => remove().then(() => onBack())}
                onClose={onBack}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            ({ adminUser, adminUserDelete }) => ({
                adminuser: mapState(adminUser, ADMIN_USER_ERROR),
                deleteUserState: mapState(
                    adminUserDelete,
                    ADMIN_USER_ERROR_DELETE,
                ),
            }),
            (
                dispatch: *,
                {
                    match: {
                        params: { id: uuid },
                    },
                },
            ) => ({
                load: (userUuid: string) => dispatch(load(userUuid)),
                remove: () => dispatch(remove(uuid)),
            }),
        )(DeleteUser),
    ),
);
