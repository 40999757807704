// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w8wF0IxS3owMMGFuXdwPWw\\=\\={background-color:#eee}.w8wF0IxS3owMMGFuXdwPWw\\=\\= .QPndA7L9SnzeqeK5agvlJw\\=\\={background-color:#651f82;color:#fff}.w8wF0IxS3owMMGFuXdwPWw\\=\\= .mg3Q5FIqmLKxduNardO6VA\\=\\={font-weight:500}.w8wF0IxS3owMMGFuXdwPWw\\=\\= .\\+uVyeGakeLt9XEcW\\+zeK2w\\=\\={display:flex;align-items:center}.w8wF0IxS3owMMGFuXdwPWw\\=\\= .OBc98o0yh00SyuWfoScuow\\=\\={width:2em;height:2em;border-radius:50%;margin-right:0.5em}.DkoAK8EotJ2\\+suBqogPTWQ\\=\\= ._7ia\\+gAHwtpwVZvEC0Q3cvA\\=\\={text-align:right;display:flex;flex-direction:row;justify-content:flex-end;align-items:center}.DkoAK8EotJ2\\+suBqogPTWQ\\=\\= ._7ia\\+gAHwtpwVZvEC0Q3cvA\\=\\= .pkZaZ9u0jt-e\\+BZe03hkeg\\=\\= div{overflow:hidden;visibility:hidden;height:0;width:0}\n", "",{"version":3,"sources":["webpack://./../ListItem.scss"],"names":[],"mappings":"AAEA,4BACI,qBAAsB,CAD1B,wDAIQ,wBAAyB,CACzB,UAAW,CALnB,wDASQ,eAAgB,CATxB,0DAYQ,YAAa,CACb,kBAAmB,CAb3B,wDAgBQ,SAAU,CACV,UAAW,CACX,iBAAkB,CAClB,kBAAmB,CACtB,2DAIG,gBAAiB,CACjB,YAAa,CACb,kBAAmB,CACnB,wBAAyB,CACzB,kBAAmB,CAN3B,4FASgB,eAAgB,CAChB,iBAAkB,CAClB,QAAS,CACT,OAAQ","sourcesContent":["@import \"../../../styles/variables\";\n\n.teammember {\n    background-color: #eee;\n\n    .isMyMember {\n        background-color: #651f82;\n        color: #fff;\n    }\n\n    .membername {\n        font-weight: 500;\n    }\n    .user {\n        display: flex;\n        align-items: center;\n    }\n    .avatar {\n        width: 2em;\n        height: 2em;\n        border-radius: 50%;\n        margin-right: 0.5em;\n    }\n}\n.menu {\n    .menuinner {\n        text-align: right;\n        display: flex;\n        flex-direction: row;\n        justify-content: flex-end;\n        align-items: center;\n        .filler {\n            div {\n                overflow: hidden;\n                visibility: hidden;\n                height: 0;\n                width: 0;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teammember": "w8wF0IxS3owMMGFuXdwPWw==",
	"isMyMember": "QPndA7L9SnzeqeK5agvlJw==",
	"membername": "mg3Q5FIqmLKxduNardO6VA==",
	"user": "+uVyeGakeLt9XEcW+zeK2w==",
	"avatar": "OBc98o0yh00SyuWfoScuow==",
	"menu": "DkoAK8EotJ2+suBqogPTWQ==",
	"menuinner": "_7ia+gAHwtpwVZvEC0Q3cvA==",
	"filler": "pkZaZ9u0jt-e+BZe03hkeg=="
};
export default ___CSS_LOADER_EXPORT___;
