// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QqttRij8IZvHHzN0pBU-qw\\=\\={display:flex}.QqttRij8IZvHHzN0pBU-qw\\=\\= .mW\\+ylLGXywsATbgUCeFkqw\\=\\={z-index:50;width:16em;position:fixed}.QqttRij8IZvHHzN0pBU-qw\\=\\= .F3kSeU6RmgjZVsquB8ClYQ\\=\\={width:calc(100% - 16em);min-height:100vh;margin-left:16em;flex-grow:1;position:relative}.QqttRij8IZvHHzN0pBU-qw\\=\\= .N2L1u3ZPj2qMWWvB30hubg\\=\\={z-index:50;width:5em;position:fixed}.QqttRij8IZvHHzN0pBU-qw\\=\\= .XNjyLR195U3Fke9Ocyt3SQ\\=\\={max-width:calc(100% - 5em);min-height:100vh;margin-left:5em;flex-grow:1}\n", "",{"version":3,"sources":["webpack://./../Shell.scss"],"names":[],"mappings":"AAEA,4BACI,YAAa,CADjB,yDAIQ,UAAW,CACX,UAAW,CACX,cAAe,CANvB,wDAUQ,uBAAwB,CACxB,gBAAiB,CACjB,gBAAiB,CACjB,WAAY,CACZ,iBAAkB,CAd1B,wDAkBQ,UAAW,CACX,SAAU,CACV,cAAe,CApBvB,wDAwBQ,0BAA2B,CAC3B,gBAAiB,CACjB,eAAgB,CAChB,WAAY","sourcesContent":["// Shell style\n\n.wrapper {\n    display: flex;\n\n    .navigation {\n        z-index: 50;\n        width: 16em;\n        position: fixed;\n    }\n\n    .content {\n        width: calc(100% - 16em);\n        min-height: 100vh;\n        margin-left: 16em;\n        flex-grow: 1;\n        position: relative;\n    }\n\n    .navigation_collapsed {\n        z-index: 50;\n        width: 5em;\n        position: fixed;\n    }\n\n    .content_collapsed {\n        max-width: calc(100% - 5em);\n        min-height: 100vh;\n        margin-left: 5em;\n        flex-grow: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "QqttRij8IZvHHzN0pBU-qw==",
	"navigation": "mW+ylLGXywsATbgUCeFkqw==",
	"content": "F3kSeU6RmgjZVsquB8ClYQ==",
	"navigation_collapsed": "N2L1u3ZPj2qMWWvB30hubg==",
	"content_collapsed": "XNjyLR195U3Fke9Ocyt3SQ=="
};
export default ___CSS_LOADER_EXPORT___;
