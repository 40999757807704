// @flow

import React from "react";
import moment from "moment";
import {
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
type Props = {
    value: *,
    onChange: (value: *) => void,
};

/**
 * DateTimeField
 */
export const DateTimeField = ({ value, onChange, ...rest }: Props) => (
    <KeyboardDateTimePicker
        {...rest}
        autoOk
        ampm={false}
        value={value ? moment(value) : null}
        onChange={onChange}
        format="DD/MM/YYYY HH:mm"
        variant="inline"
    />
);

/**
 * DateField
 */
export const DateField = ({ value, onChange, ...rest }: Props) => (
    <KeyboardDatePicker
        {...rest}
        autoOk
        value={moment(value)}
        onChange={onChange}
        format="DD/MM/YYYY"
        variant="inline"
    />
);

/**
 * TimeField
 */
export const TimeField = ({ value, onChange, ...rest }: Props) => (
    <KeyboardTimePicker
        {...rest}
        ampm={false}
        autoOk
        format="HH:mm"
        value={moment(value)}
        onChange={onChange}
        disableOpenOnEnter
    />
);
