// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TXxFfIwjVTnmOw08TA2n1w\\=\\={margin:2em 1em}._4KfRZCdxe6l-G9F4yN2urg\\=\\={padding:2em}\n", "",{"version":3,"sources":["webpack://./../Pool.scss"],"names":[],"mappings":"AAEA,4BACI,cAAe,CAClB,6BAGG,WAAY","sourcesContent":["// Pool style\n\n.wrapper {\n    margin: 2em 1em;\n}\n\n.title {\n    padding: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TXxFfIwjVTnmOw08TA2n1w==",
	"title": "_4KfRZCdxe6l-G9F4yN2urg=="
};
export default ___CSS_LOADER_EXPORT___;
