// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import debounce from "lodash.debounce";

import stripslash from "../../../utils/stripslash";

import { type State } from "../../../redux/modules/members.d";
import { type State as DetailsType } from "../../../redux/modules/details.d";
import {
    load,
    clear,
    makeLeader,
    makeMember,
} from "../../../redux/modules/members";

import {
    approveNonSmartphoneUser,
    denyNonSmartphoneUser,
} from "../../../redux/modules/members";
import {
    load as loadDetails,
    ERROR as DETAILS_ERROR,
} from "../../../redux/modules/details";
import {
    load as addMember,
    ERROR as DETAILS_MEMBER_ADD_ERROR,
} from "../../../redux/modules/details-member-add";
import { type State as AddMemberState } from "../../../redux/modules/details-member-add.d";
import { mapState } from "../../../redux/utils";

import NotFound from "../../../components/NotFound";
import FilterField from "../../../components/Authenticated/FilterField";
import MemberList from "../../../components/Organisations/MemberList/MemberList";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
import EditMember from "./EditMember";
import DeleteMember from "./DeleteMember";
import AddMember from "../../../components/Modals/AddMember/AddMember";
import isEqual from "lodash.isequal";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    members: State,
    details: DetailsType,
    addMemberState: AddMemberState,
    load: (
        organisationUUID: string,
        page: number,
        filter: string,
        sorting: string[],
    ) => void,
    clear: () => void,
    addMember: (
        phoneNumber: string,
        description: string,
        noSmartphone: boolean,
        firstName: string,
        lastName: string,
    ) => Promise<void>,
    approveNonSmartphoneUser: (uuid: string) => void,
    denyNonSmartphoneUser: (uuid: string) => void,
    loadDetails: (organisationUUID: string) => void,
    makeLeader: (uuid: string) => void,
    makeMember: (uuid: string) => void,
    history: RouterHistory,
    onPageChange: (page: number) => void,
    onTabChange: (s: string) => void,
};

type StateType = {
    filter: string,
    sorting: string[],
};

const DEBOUNCE_WAIT = 250;
const DEBOUNCE_MAX_WAIT = 500;

class Members extends Component<Props, StateType> {
    // Initial state
    state: StateType = {
        filter: "",
        sorting: [],
    };

    /**
     * Initial load
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
    }

    /**
     * Trigger load on update
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;

        if (
            prevProps.match.params.page !== match.params.page ||
            !isEqual(prevState, this.state)
        ) {
            this.reload();
        }
    }

    /**
     * Test if the page is a number
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    handleSortChange(sortString: string[]) {
        this.setState({
            sorting: sortString,
        });
    }

    /**
     * Handle role toggle
     */
    handleRoleToggle(uuid: string, promotion: boolean) {
        const { makeLeader, makeMember } = this.props;

        if (promotion) {
            makeLeader(uuid);
        } else {
            makeMember(uuid);
        }
    }

    /**
     * Trigger update
     */
    reload = debounce(
        () => {
            const { load, match } = this.props;
            const { filter, sorting } = this.state;
            if (this.pageParamIsNumber && match.params.id) {
                load(
                    match.params.id,
                    parseInt(match.params.page),
                    filter,
                    sorting,
                );
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );

    render() {
        const {
            match,
            members,
            onPageChange,
            history,
            translate,
            details,
            loadDetails,
            addMember,
            approveNonSmartphoneUser,
            denyNonSmartphoneUser,
            acl,
            addMemberState,
        } = this.props;
        const { filter } = this.state;

        return (
            <div>
                <span style={{ marginTop: "1em", display: "block" }} />
                <FilterField
                    onChange={filter => this.setState({ filter })}
                    placeholder={translate(
                        "Filter by name, description or phone number",
                    )}
                    id="member-list-filter"
                    value={filter}
                />
                <MemberList
                    acl={acl}
                    translate={translate}
                    loading={members.loading}
                    page={members.page}
                    data={members.data}
                    error={members.error}
                    onPageChange={onPageChange}
                    onSortChange={this.handleSortChange.bind(this)}
                    onToggleRole={this.handleRoleToggle.bind(this)}
                    onApproveMember={uuid => approveNonSmartphoneUser(uuid)}
                    onDenyMember={uuid => denyNonSmartphoneUser(uuid)}
                    filter={filter}
                />
                <Switch>
                    <Route path={`${match.path}add/`}>
                        {(acl("organisations.members.create") && (
                            <AddMember
                                busy={members.loading}
                                translate={translate}
                                organisation={details}
                                load={() => loadDetails((match.params: any).id)}
                                addMemberState={addMemberState}
                                onSubmit={({
                                    phoneNumber,
                                    description,
                                    noSmartphone,
                                    firstName,
                                    lastName,
                                }) =>
                                    addMember(
                                        phoneNumber,
                                        description,
                                        noSmartphone,
                                        firstName,
                                        lastName,
                                    ).then(() =>
                                        history.push(
                                            `${stripslash(match.url)}/`,
                                        ),
                                    )
                                }
                                onClose={() =>
                                    history.push(`${stripslash(match.url)}/`)
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}:id/delete/`}>
                        {(acl("organisations.members.delete") && (
                            <DeleteMember
                                onBack={() =>
                                    history.push(`${stripslash(match.url)}/`)
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}:id/edit/`}>
                        <EditMember
                            onBack={() =>
                                history.push(`${stripslash(match.url)}/`)
                            }
                        />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ members, details, detailsMemberAdd }) => ({
                    members: members.toJS(),
                    details: {
                        ...mapState(details, DETAILS_ERROR),
                        uuid:
                            details.params &&
                            details.params.path &&
                            details.params.path.uuid,
                    },
                    addMemberState: mapState(
                        detailsMemberAdd,
                        DETAILS_MEMBER_ADD_ERROR,
                    ),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load,
                            clear,
                            makeLeader,
                            makeMember,
                            loadDetails,
                            addMember,
                            approveNonSmartphoneUser,
                            denyNonSmartphoneUser,
                        },
                        dispatch,
                    ),
            )(Members),
        ),
    ),
);
