// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UVcYLPkRZwKEtFqt3X\\+SZw\\=\\={margin:1em 0}.UVcYLPkRZwKEtFqt3X\\+SZw\\=\\=>table thead th{padding-left:2em;padding-right:1em;text-transform:none;font-size:0.8125rem;font-family:inherit}.UVcYLPkRZwKEtFqt3X\\+SZw\\=\\=>table tbody td{text-transform:none;font-size:0.8125rem;font-family:inherit}.-uZpiakwHKwrOvSroCapYw\\=\\={color:#651f82;font-size:0.8em}.me6j-dTjslbPwO4zD-t3\\+g\\=\\={display:block;background-color:#eee;width:1.75em;height:1.75em;border-radius:50%;margin-right:0.5em}\n", "",{"version":3,"sources":["webpack://./../TeamList.scss"],"names":[],"mappings":"AAIA,6BACI,YAAa,CADjB,4CAIY,gBAAiB,CACjB,iBAAkB,CAClB,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CARhC,4CAWY,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CACvB,4BAKL,aAAc,CACd,eAAgB,CACnB,6BAEG,aAAc,CACd,qBAAsB,CACtB,YAAa,CACb,aAAc,CACd,iBAAkB,CAClB,kBAAmB","sourcesContent":["// TeamList Styling\n\n@import \"../../../styles/variables\";\n\n.wrapper {\n    margin: 1em 0;\n    & > table {\n        thead th {\n            padding-left: 2em;\n            padding-right: 1em;\n            text-transform: none;\n            font-size: 0.8125rem;\n            font-family: inherit;\n        }\n        tbody td {\n            text-transform: none;\n            font-size: 0.8125rem;\n            font-family: inherit;\n        }\n    }\n}\n\n.add {\n    color: #651f82;\n    font-size: 0.8em;\n}\n.emptyAvatar {\n    display: block;\n    background-color: #eee;\n    width: 1.75em;\n    height: 1.75em;\n    border-radius: 50%;\n    margin-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UVcYLPkRZwKEtFqt3X+SZw==",
	"add": "-uZpiakwHKwrOvSroCapYw==",
	"emptyAvatar": "me6j-dTjslbPwO4zD-t3+g=="
};
export default ___CSS_LOADER_EXPORT___;
