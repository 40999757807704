//@flow
import styles from "./CalendarViewHeader.scss";

import moment from "moment";
import React, { type Node, useEffect, useState } from "react";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";

import { type State as VenueState } from "../../../../redux/modules/venues.d";

import AsyncFiltered from "../../../AsyncFiltered";
import CalenderIcon from "../Icons/index";
import { getColor } from "../../../../utils/VenueColors";

type Props = {
    translate: *,
    venues: VenueState,
    activeMonth: *,
    isShowCanceled: boolean,
    handleShowCanceled: (value: boolean) => void,
    findSeries: (filter: string) => *,
    clearSeries: () => void,
    series: *,
    scrolToDate: (date: *, eventUuid?: string, serieUuid?: string) => void,
    showLoadingProgress: boolean,
};

const CalendarViewHeader = ({
    translate,
    activeMonth,
    isShowCanceled,
    handleShowCanceled,
    series,
    scrolToDate,
    findSeries,
    clearSeries,
    venues,
    showLoadingProgress,
}: Props) => {
    const [charactersNumber, setCharactersNumber] = useState(0);

    /**
     * get filtered venue
     */
    const getVenue = (venueUuid: string) => {
        const IVenues = venues.data;
        return IVenues.find(venue => venue.uuid === venueUuid);
    };

    /**Truncate  */
    const truncate = (length: number = 35, ...args: Array<*>) => {
        const concatenated = args.filter(string => !!string).join("");

        if (concatenated.length > length) {
            return `${concatenated}`.substring(0, length) + "...";
        } else {
            return concatenated;
        }
    };

    /**
     * Get how many characters should be on screen
     */
    const screenWidth = () => {
        const width = Math.max(window.innerWidth || 0);
        if (width === 1590 || width > 1590) setCharactersNumber(40);
        if (width < 1590) setCharactersNumber(32);
        if (width < 1380) setCharactersNumber(30);
    };

    useEffect(() => {
        screenWidth();
    }, []);

    // useEffect(() => {
    //     setActiveMonth(selectedDate);
    // }, [selectedDate]);
    /**
     * getHighlight matched text
     */
    const getHighlightText = (item: Object, matched: string): string | Node => {
        const splittedText = `${item.seriesName || ""} ${
            item.seriesName && item.seriesOptionCode ? "-" : ""
        } ${item.seriesOptionCode || ""}`.split(
            new RegExp(`(${matched})`, "gi"),
        );
        return (
            <span style={{ width: "35%" }}>
                {splittedText.map((highlighted, i) => (
                    <bold
                        key={i}
                        style={{
                            borderRadius: "5%",
                            backgroundColor:
                                highlighted &&
                                highlighted.toLowerCase() ===
                                    matched.toLowerCase()
                                    ? "#F2EEF6"
                                    : "inherit",
                        }}
                    >
                        {truncate(45, highlighted)}
                    </bold>
                ))}
                {item.seriesInternal ? ` (${item.seriesInternal})` : ""}
            </span>
        );
    };
    /**
     * format date instead of instead formating
     */
    const getTimeFormat = (date: *, key?: string) => {
        const days = moment(date).format("ddd");
        const defaultDate = moment(date).format("D MMM YYYY");
        return key === "days" ? days : defaultDate;
    };
    /**
     * Returns the result as a list
     */
    const itemToLabel = (item: *, matchedValue: string) => {
        const venue = getVenue(item.venueUuid);

        return (
            <>
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "14%",
                    }}
                >
                    <span style={{ color: "#898989", marginRight: ".2em" }}>
                        {getTimeFormat(item.date, "days")}
                    </span>
                    {getTimeFormat(item.date)}
                </span>
                <span style={{ width: "5%" }}>
                    <CalenderIcon
                        status={item.status}
                        color={venue && getColor(venue.code)}
                    />
                </span>
                <span style={{ width: "12%" }}>
                    {truncate(13, item.promoterName)}
                </span>
                {getHighlightText(item, matchedValue)}
                <span style={{ width: "28%" }}>
                    {truncate(charactersNumber, item.name && item.name)}
                </span>

                <span style={{ width: "5%" }}>
                    <div className={styles.item}>
                        <div
                            className={styles.venue}
                            style={{
                                backgroundColor: venue && getColor(venue.code),
                            }}
                        >
                            <p className={styles.inner}>
                                {venue && venue.code}
                            </p>
                        </div>
                    </div>
                </span>
            </>
        );
    };
    return (
        <>
            <div className={classnames(styles.element)}>
                <Grid
                    container
                    spacing={3}
                    style={{
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid item xs={5} className={styles.left}>
                        <div className={styles.dateContainer}>
                            <DatePicker
                                id="calendar-period"
                                value={activeMonth}
                                openTo="month"
                                onChange={date =>
                                    scrolToDate(
                                        moment(date.toDate()).startOf("month"),
                                        undefined,
                                        undefined,
                                    )
                                }
                                views={["year", "month"]}
                            />
                            <ArrowDropDown className={styles.arrow} />
                        </div>

                        <Button
                            className={styles.today}
                            onClick={() => {
                                scrolToDate(moment(), undefined, undefined);
                            }}
                        >
                            {translate("To today").toLowerCase()}
                        </Button>
                        <Divider
                            className={styles.divider}
                            orientation="vertical"
                        />

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                color="default"
                                value="checkedG"
                                checked={isShowCanceled}
                                onChange={() =>
                                    handleShowCanceled(!isShowCanceled)
                                }
                                style={{
                                    height: "1.575em",
                                }}
                            />
                            <Typography
                                style={{ opacity: "0.5", fontSize: "0.875em" }}
                            >
                                {translate("Display canceled")}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid className={styles.searchBar}>
                        <AsyncFiltered
                            className={styles.filterField}
                            id={`find-serie`}
                            disabled={false}
                            placeholder={translate("Filter by name")}
                            asyncState={series}
                            lookup={filter => findSeries(filter)}
                            onSelect={serie =>
                                serie &&
                                scrolToDate(
                                    serie.date,
                                    serie.uuid,
                                    serie.seriesUuid,
                                )
                            }
                            itemToString={item => item && ""}
                            itemToLabel={(item, value) =>
                                item && itemToLabel(item, value)
                            }
                            filter={(value, items) =>
                                items.filter(item =>
                                    (
                                        item.seriesName || item.seriesOptionCode
                                    ).toLowerCase(),
                                )
                            }
                            fullWidth
                            onOuterClick={clearSeries}
                        />
                    </Grid>
                </Grid>
            </div>
            {showLoadingProgress && <LinearProgress />}
        </>
    );
};

export default CalendarViewHeader;
