// @flow

// Import styles
import styles from "./PDFGenerator.scss";

// Import libs
import React, { Component, Fragment } from "react";
import classnames from "classnames";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { type EventQRCode } from "../../redux/modules/event-qrcode.d";
import { type EventMemberQRCode } from "../../redux/modules/event-line-member-qrcode.d";
import { type AttendeeQRCode } from "../../redux/modules/attendees-qrcode.d";
import GetApp from "@material-ui/icons/GetApp";
import ShowPDF from "./Templates/ShowPDF";
import MemberPDF from "./Templates/MemberPDF";
import AttendeePDF from "./Templates/AttendeePDF";
import ExportDataPDF from "./Templates/ExportDataPDF";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";

// Define props
type PropsType = {
    translate: *,
    loading: boolean,
    eventQRCode?: EventQRCode,
    eventMemberQRCode?: ?EventMemberQRCode,
    attendeeQRCode?: ?AttendeeQRCode,
    disabled: boolean,
    data?: *,
    start?: *,
    end?: *,
    venues?: *,
    exportFor?: string,
    type: "SHOW" | "MEMBER" | "ATTENDEE" | "EXPORT-DATA",
    onClose?: () => void,
};

type StateType = {
    rendering: boolean,
    fileName: string,
};

/**
 * A component that generates a pdf
 */
export default class PDFGenerator extends Component<PropsType, StateType> {
    state = {
        rendering: false,
        fileName: "default",
    };

    handleClick(name: string) {
        const { type, onClose } = this.props;
        this.setState(
            {
                rendering: true,
            },
            () => {
                const filename = name + ".pdf";

                if (type === "EXPORT-DATA") {
                    html2pdf()
                        .set({
                            jsPDF: {
                                orientation: "l",
                                unit: "mm",
                                format: "a3",
                                putOnlyUsedFonts: true,
                            },
                            image: { type: "jpeg", quality: 0.8 },
                            html2canvas: {
                                scale: 2,
                                windowWidth: document.getElementById("root")
                                    ? document.getElementById("root")
                                          ?.offsetWidth
                                    : 100,
                                windowHeight:
                                    document.getElementById("root")
                                        ?.offsetHeight,
                            },
                            margin: 3,
                            pagebreak: { avoid: "tr" },
                        })
                        .from(document.getElementById("pdf"))
                        .save(filename)
                        .then(() => {
                            this.setState({
                                rendering: false,
                            });
                            onClose && onClose();
                        });
                    return;
                }

                html2canvas(document.querySelector("#pdf"), {
                    scale: 4,
                    useCORS: true,
                    allowTaint: false,
                })
                    .then(canvas => {
                        var imgWidth = type === "EXPORT-DATA" ? 297 : 210;
                        var pageHeight = type === "EXPORT-DATA" ? 210 : 297;

                        var imgHeight =
                            (canvas.height * imgWidth) / canvas.width;
                        var heightLeft = imgHeight;
                        let pdf = "";

                        type === "EXPORT-DATA"
                            ? (pdf = new jsPDF("l", "mm", "a4", true))
                            : (pdf = new jsPDF("p", "mm", "a4", true));
                        var position = 0;

                        pdf.addImage(
                            canvas.toDataURL("image/png"),
                            "PNG",
                            0,
                            position,
                            imgWidth,
                            imgHeight,
                            "",
                            "FAST",
                        );
                        heightLeft -= pageHeight;

                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            pdf.addPage();
                            pdf.addImage(
                                canvas.toDataURL("image/png"),
                                "PNG",
                                0,
                                position,
                                imgWidth,
                                imgHeight,
                                "",
                                "FAST",
                            );
                            heightLeft -= pageHeight;
                        }
                        pdf.save(filename);

                        /* let pdf = new jsPDF("p", "mm", "a4", true);
                        pdf.addImage(
                            canvas.toDataURL("image/png"),
                            "PNG",
                            0,
                            0,
                            211,
                            298,
                            "",
                            "FAST",
                        );
                        pdf.save(filename);*/
                    })
                    .then(() => {
                        this.setState({
                            rendering: false,
                        });
                        onClose && onClose();
                    });
            },
        );
    }
    /**
     * Render
     */
    render() {
        const { rendering, fileName } = this.state;
        const {
            loading,
            disabled,
            translate,
            eventQRCode,
            eventMemberQRCode,
            attendeeQRCode,
            data,
            type,
            venues,
            start,
            end,
            exportFor,
        } = this.props;

        return (
            <div
                className={classnames(
                    styles.wrapper,
                    type === "EXPORT-DATA" ? styles.landscape : undefined,
                )}
            >
                {(eventQRCode ||
                    eventMemberQRCode ||
                    attendeeQRCode ||
                    data) && (
                    <div
                        className={
                            type === "EXPORT-DATA" ? styles.actions : undefined
                        }
                    >
                        <Fab
                            variant="extended"
                            color="primary"
                            aria-label="Add"
                            id="download-qr-code"
                            disabled={rendering || disabled || loading}
                            onClick={() =>
                                this.handleClick(
                                    eventQRCode
                                        ? eventQRCode.eventName
                                        : eventMemberQRCode
                                        ? eventMemberQRCode.eventName
                                        : fileName,
                                )
                            }
                        >
                            {rendering ? (
                                <CircularProgress />
                            ) : (
                                <Fragment>
                                    <GetApp />
                                    {type == "EXPORT-DATA"
                                        ? translate("Download data.")
                                        : translate("Download QR code.")}
                                </Fragment>
                            )}
                        </Fab>
                        {type === "EXPORT-DATA" && (
                            <TextField
                                label={translate("File name:")}
                                id="file-name"
                                value={fileName || ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            .PDF
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={({ target: { value } }) =>
                                    this.setState({
                                        ...this.state,
                                        fileName: value,
                                    })
                                }
                            />
                        )}
                    </div>
                )}

                {type === "SHOW" && eventQRCode && (
                    <ShowPDF data={eventQRCode} translate={translate} />
                )}
                {type === "MEMBER" && eventMemberQRCode && (
                    <MemberPDF data={eventMemberQRCode} translate={translate} />
                )}
                {type === "ATTENDEE" && attendeeQRCode && (
                    <AttendeePDF data={attendeeQRCode} translate={translate} />
                )}
                {type === "EXPORT-DATA" && data && venues && start && end && (
                    <ExportDataPDF
                        exportFor={exportFor}
                        venues={venues}
                        data={data}
                        start={start}
                        end={end}
                        translate={translate}
                    />
                )}
            </div>
        );
    }
}
