// @flow

import createApiModule from "../create-api-module";

import type { ApplyStaffingTemplatesData } from "./apply-staffing-templates.d";

const { reducer, actions } = createApiModule(
    "staffingTemplatesApply",
    ({ eventUuid }) =>
        `/ays-series-event-management/events/${eventUuid}/staffing-templates`,
    {
        method: "POST",
    },
);

export const ERROR = "Could not apply staffing templates";
export const load =
    (eventUuid: string, data: ApplyStaffingTemplatesData) => (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { eventUuid },
                data,
            }),
        );
    };
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
