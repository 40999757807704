// @flow

import styles from "./EventBriefings.scss";

import React, { Component } from "react";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import BriefingEditor from "./BriefingEditor";
import MdViewer from "../../MdViewer/MdViewer";
import { type State as EventBriefingState } from "../../../redux/modules/event-briefing.d";
import { type State as EventLinesBriefingState } from "../../../redux/modules/event-linetype-briefing.d";
import { type State as SeriesEvents } from "../../../redux/modules/series-events.d";

type Props = {
    translate: *,
    acl: *,
    disabled: boolean,
    loadEventBriefing: () => *,
    loadEventLineTypesBriefing: () => *,
    updateEventBriefing: (briefing: string, prebriefing: string) => *,
    updateEventLineTypesBriefing: (
        briefing: string,
        prebriefing: string,
        uuid: string,
    ) => void,
    eventLinesBriefing: EventLinesBriefingState,
    eventBriefing: EventBriefingState,
    refetchEventBriefing: () => *,
    loadSeriesEvents: () => void,
    seriesEvents: SeriesEvents,
    clearEventBriefing: () => void,
    clearSeriesEvents: () => void,
    loadSecurityReport: () => void,
    eventSecurityReport: *,
};

export default class EventBriefings extends Component<Props> {
    componentDidMount() {
        const {
            loadEventBriefing,
            loadEventLineTypesBriefing,
            loadSecurityReport,
        } = this.props;

        loadEventBriefing();
        loadEventLineTypesBriefing();
        loadSecurityReport();
    }
    componentWillUnmount() {
        const { clearEventBriefing } = this.props;

        clearEventBriefing();
    }
    render() {
        const {
            translate,
            acl,
            eventLinesBriefing,
            eventBriefing,
            updateEventBriefing,
            updateEventLineTypesBriefing,
            disabled,
            refetchEventBriefing,
            loadSeriesEvents,
            seriesEvents,
            clearSeriesEvents,
            eventSecurityReport,
        } = this.props;

        return (
            <div className={styles.wrapper} elevation={2}>
                <Paper
                    className={styles.content}
                    id="show-briefings-show"
                    elevation={2}
                >
                    {eventBriefing.loading && !eventBriefing.data && (
                        <CircularProgress />
                    )}
                    {eventBriefing.error && (
                        <Typography color="error">
                            {eventBriefing.error}
                        </Typography>
                    )}
                    {eventBriefing.data && (
                        <BriefingEditor
                            acl={acl}
                            translate={translate}
                            title={"Event"}
                            updating={eventBriefing.updating}
                            canEdit={
                                !disabled &&
                                !eventBriefing.updating &&
                                acl("events.briefing")
                            }
                            briefing={
                                eventBriefing.data
                                    ? eventBriefing.data.briefing
                                    : null
                            }
                            prebriefing={
                                eventBriefing.data
                                    ? eventBriefing.data.preBriefing
                                    : null
                            }
                            onSubmit={(
                                briefing,
                                prebriefing,
                                preBriefingNotification,
                                briefingNotification,
                                applySeries,
                            ) =>
                                updateEventBriefing(
                                    briefing,
                                    prebriefing,
                                    preBriefingNotification,
                                    briefingNotification,
                                    applySeries,
                                ).then(() => refetchEventBriefing())
                            }
                            loadSeriesEvents={loadSeriesEvents}
                            seriesEvents={seriesEvents}
                            clearSeriesEvents={clearSeriesEvents}
                        />
                    )}
                </Paper>
                {eventLinesBriefing.loading && <CircularProgress />}
                {eventLinesBriefing.error && (
                    <Typography color="error">
                        {eventLinesBriefing.error}
                    </Typography>
                )}
                {!eventLinesBriefing.loading &&
                    eventLinesBriefing.data &&
                    eventLinesBriefing.data.briefings.map(lineBriefing => {
                        return (
                            <Paper
                                key={lineBriefing.lineTypeUuid}
                                className={styles.content}
                                id={`show-briefings-${lineBriefing.lineTypeName}`}
                                elevation={2}
                            >
                                <BriefingEditor
                                    acl={acl}
                                    translate={translate}
                                    title={lineBriefing.lineTypeName}
                                    updating={eventLinesBriefing.updating}
                                    canEdit={
                                        !disabled &&
                                        !eventLinesBriefing.updating &&
                                        lineBriefing.canEdit
                                    }
                                    briefing={lineBriefing.briefing}
                                    prebriefing={lineBriefing.preBriefing}
                                    onSubmit={(
                                        briefing,
                                        prebriefing,
                                        preBriefingNotification,
                                        briefingNotification,
                                        applySeries,
                                    ) =>
                                        updateEventLineTypesBriefing(
                                            briefing,
                                            prebriefing,
                                            lineBriefing.lineTypeUuid,
                                            preBriefingNotification,
                                            briefingNotification,
                                            applySeries,
                                        )
                                    }
                                    loadSeriesEvents={loadSeriesEvents}
                                    seriesEvents={seriesEvents}
                                    clearSeriesEvents={clearSeriesEvents}
                                />
                            </Paper>
                        );
                    })}
                <Paper
                    className={styles.content}
                    id="show-briefings-security-report"
                    elevation={2}
                >
                    {eventSecurityReport.loading &&
                        !eventSecurityReport.data && <CircularProgress />}
                    {eventSecurityReport.error && (
                        <Typography color="error">
                            {eventSecurityReport.error}
                        </Typography>
                    )}
                    {eventSecurityReport.data && (
                        <>
                            <Typography
                                variant="h2"
                                color="inherit"
                                className={styles.title}
                            >
                                {translate("Security Report")}
                            </Typography>
                            <MdViewer translate={translate}>
                                {eventSecurityReport.data.securityReport}
                            </MdViewer>
                        </>
                    )}
                </Paper>
            </div>
        );
    }
}
