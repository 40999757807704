// @flow

import styles from "./Shell.scss";

import { type Node } from "react";
import { type RouterHistory, type Location } from "react-router-dom";
import React, { Component } from "react";

import { type Profile } from "../../redux/modules/user.d";

import { NavBar } from "../NavBar";
import { type ItemType } from "../NavBar/NavBarItem.js";

type Props = {
    profile: Profile,
    children: Node,
    location: Location,
    language: string,
    setLanguage: (language: string) => void,
    history: RouterHistory,
    main: ItemType[],
    bottom: ItemType[],
    translate: *,
};

type State = {
    collapsed: boolean,
};

/**
 * App shell
 */
export default class Shell extends Component<Props, State> {
    /**
     * Get active item
     */
    state = {
        collapsed: false,
    };

    toggleCollapse() {
        const { collapsed } = this.state;
        this.setState({
            collapsed: !collapsed,
        });
    }

    get activeItemValue(): ?string {
        const {
            location: { pathname },
            main,
            bottom,
        } = this.props;
        return (
            main.concat(bottom).find(item => pathname.startsWith(item.value)) ||
            {}
        ).value;
    }

    /**
     * Render
     */
    render() {
        const {
            children,
            history,
            main,
            bottom,
            language,
            setLanguage,
            translate,
        } = this.props;
        const { collapsed } = this.state;

        return (
            <div className={styles.wrapper}>
                <div
                    className={
                        !collapsed
                            ? styles.navigation
                            : styles.navigation_collapsed
                    }
                >
                    <NavBar
                        main={main}
                        bottom={bottom}
                        language={language}
                        setLanguage={setLanguage}
                        onClick={value => {
                            if (value !== "/profile/") history.push(value);
                        }}
                        onCollapse={() => this.toggleCollapse()}
                        activeItem={this.activeItemValue}
                        collapsed={collapsed}
                        translate={translate}
                    />
                </div>
                <div
                    className={
                        !collapsed ? styles.content : styles.content_collapsed
                    }
                >
                    {children}
                </div>
            </div>
        );
    }
}
