// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Switch,
    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import stripslash from "../../utils/stripslash";

import Calendar from "../../components/Events/Calendar";

import { type State as VenuesState } from "../../redux/modules/venues.d";
import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";
import { type State as CalendarState } from "../../redux/modules/calendar.d";
import { load as loadCalendar } from "../../redux/modules/calendar";
import EditOptionContainer from "./EditOption";

import { withAcl } from "../Acl";
import { withTranslate } from "../Translate";

import ConfirmOptionsContainer from "./ConfirmOptions";
import EditSeriesContainer from "./EditSeries";
import EditEventContainer from "./EditEvent";
import MoveEventContainer from "./MoveEvent";
import EditConfirmedOptionContainer from "./EditConfirmedOption";
import AddOption from "./AddOption";
import AddEvent from "./AddEvent";
import History from "./History";
import DefineEventsContainer from "./DefineEvents";
import ExportDataContainer from "./ExportData";
import CancelOptionContainer from "./CancelOption";
import CancelEventContainer from "./CancelEvent";
import CancelConfirmedOptionContainer from "./CancelConfirmedOption";
import Legend from "../../components/Events/Calendar/Legend/Legend";
import {
    load as findSeries,
    clear as clearSeries,
    ERROR as SERIES_ERROR,
} from "../../redux/modules/series";
import { type State as SeriesState } from "../../redux/modules/series.d";
import { type Profile } from "../../../redux/modules/user.d";
import { mapState } from "../../redux/utils";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    history: RouterHistory,
    venues: VenuesState,
    calendar: CalendarState,
    confirmOptions: (options: *) => Promise<void>,
    onClose: () => void,
    loadCalendar: *,
    loadVenues: () => *,
    findSeries: (filter: string) => *,
    clearSeries: () => void,
    series: SeriesState,
    user: Profile,
};

class CalendarView extends Component<Props> {
    /**
     * Render
     */

    render() {
        const {
            match,
            translate,
            history,
            venues,
            loadVenues,
            calendar,
            user,
            acl,
            loadCalendar,
            series,
            findSeries,
            clearSeries,
        } = this.props;

        return (
            <div>
                <span style={{ marginTop: "0.75em", display: "block" }} />
                <Calendar
                    loadVenues={loadVenues}
                    loadCalendar={loadCalendar}
                    venues={venues}
                    calendar={calendar}
                    translate={translate}
                    acl={acl}
                    series={series}
                    findSeries={findSeries}
                    clearSeries={clearSeries}
                    user={user}
                    onCancelEvent={uuid =>
                        history.push(
                            `${stripslash(match.url)}/cancel/event/${uuid}`,
                        )
                    }
                    onCancelConfirmedOption={uuid =>
                        history.push(
                            `${stripslash(match.url)}/cancel/confirmed/${uuid}`,
                        )
                    }
                    onAddOption={() =>
                        history.push(`${stripslash(match.url)}/add/option/`)
                    }
                    onAddOptionToSeries={(uuid, date) =>
                        history.push(
                            `${stripslash(
                                match.url,
                            )}/add/option/${uuid}/${date}`,
                        )
                    }
                    onAddEvent={() =>
                        history.push(`${stripslash(match.url)}/add/event/`)
                    }
                    onShowHistory={uuid =>
                        history.push(`${stripslash(match.url)}/history/${uuid}`)
                    }
                    onDoubleClickEvent={uuid =>
                        history.push(
                            `${stripslash(match.url).substring(
                                0,
                                7,
                            )}/detail/${uuid}`,
                        )
                    }
                    onAddEventToSeries={(uuid, date) =>
                        history.push(
                            `${stripslash(
                                match.url,
                            )}/add/event/${uuid}/${date}`,
                        )
                    }
                    onEditOption={uuid =>
                        history.push(
                            `${stripslash(match.url)}/edit/option/${uuid}`,
                        )
                    }
                    onEditConfirmedOption={uuid =>
                        history.push(
                            `${stripslash(match.url)}/edit/confirmed/${uuid}/`,
                        )
                    }
                    onConfirmOptions={uuid =>
                        history.push(
                            `${stripslash(match.url)}/confirm/options/${uuid}`,
                        )
                    }
                    onOpenLegend={() =>
                        history.push(`${stripslash(match.url)}/legend/`)
                    }
                    onExportData={() =>
                        history.push(`${stripslash(match.url)}/export/data/`)
                    }
                    onEditSeries={uuid =>
                        history.push(
                            `${stripslash(match.url)}/edit/series/${uuid}`,
                        )
                    }
                    onEditEvent={uuid =>
                        history.push(
                            `${stripslash(match.url)}/edit/event/${uuid}`,
                        )
                    }
                    onMoveEvent={uuid => {
                        history.push(
                            `${stripslash(match.url)}/move/event/${uuid}`,
                        );
                    }}
                    onDefineEvents={uuid =>
                        history.push(
                            `${stripslash(match.url)}/define/event/${uuid}`,
                        )
                    }
                    onCancelOption={uuid =>
                        history.push(
                            `${stripslash(match.url)}/cancel/option/${uuid}`,
                        )
                    }
                />

                <Switch>
                    <Route
                        path={`${stripslash(
                            match.path,
                        )}/confirm/options/:uuid/`}
                    >
                        <ConfirmOptionsContainer
                            type={"SERIES"}
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(match.path)}/edit/option/:uuid/`}
                    >
                        <EditOptionContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(match.path)}/cancel/event/:uuid/`}
                    >
                        <CancelEventContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(
                            match.path,
                        )}/cancel/confirmed/:uuid/`}
                    >
                        <CancelConfirmedOptionContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route path={`${stripslash(match.path)}/legend/`}>
                        <Legend
                            translate={translate}
                            variant="modal"
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(match.path)}/edit/confirmed/:uuid/`}
                    >
                        <EditConfirmedOptionContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route
                        path={`${stripslash(match.path)}/cancel/option/:uuid/`}
                    >
                        <CancelOptionContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(match.path)}/define/event/:uuid/`}
                    >
                        <DefineEventsContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(match.path)}/edit/series/:uuid/`}
                    >
                        <EditSeriesContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route path={`${stripslash(match.path)}/edit/event/:uuid/`}>
                        <EditEventContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route path={`${stripslash(match.path)}/move/event/:uuid/`}>
                        <MoveEventContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route path={`${stripslash(match.path)}/export/data/`}>
                        <ExportDataContainer
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>

                    <Route
                        path={`${stripslash(
                            match.path,
                        )}/add/option/:uuid?/:date?/`}
                    >
                        <AddOption
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route path={`${match.path}/add/event/:uuid?/:date?/`}>
                        <AddEvent
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                    <Route path={`${match.path}/history/:uuid`}>
                        <History
                            onClose={() =>
                                history.push(`${stripslash(match.url)}`)
                            }
                        />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ venues, calendar, series, user }) => ({
                    venues: mapState(venues, VENUES_ERROR, "venues"),
                    calendar: calendar,
                    series: mapState(series, SERIES_ERROR, "content"),
                    user:
                        user &&
                        user.get("data") &&
                        user.getIn(["data", "profile"]).toJS(),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            loadVenues,
                            findSeries,
                            clearSeries,
                            loadCalendar: (
                                startDate,
                                endDate,
                                promoterUuid,
                                showCanceled,
                            ) =>
                                loadCalendar(
                                    startDate,
                                    endDate,
                                    promoterUuid,
                                    showCanceled,
                                ),
                        },
                        dispatch,
                    ),
            )(CalendarView),
        ),
    ),
);
