// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    load as update,
    ERROR,
} from "../../../redux/modules/event-line-details";
import EventLineDetails from "../../../components/Events/EventLineDetails";
import { load as loadLines } from "../../../redux/modules/event-lines";

import { withTranslate } from "../../Translate";
import { withAcl } from "../../Acl";
import { mapState } from "../../../redux/utils";

export default withTranslate(
    withAcl(
        connect(
            // Map state to props
            ({ eventLineDetails }) => ({
                details: mapState(eventLineDetails, ERROR),
            }),
            // Map dispatch to props
            (dispatch: *, { showuuid }) => ({
                ...bindActionCreators({}, dispatch),
                update: (uuid, eventLine) =>
                    dispatch(update(uuid, eventLine)).then(() =>
                        dispatch(loadLines(showuuid, true)),
                    ),
            }),
        )(EventLineDetails),
    ),
);
