// @flow
import styles from "./AsyncFilteredSelect.scss";
import React, { PureComponent, type Node } from "react";
import Downshift from "downshift";
import Divider from "@material-ui/core/Divider";
import debounce from "lodash.debounce";
import classnames from "classnames";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

type ItemToString<ItemType> = (item: ItemType) => string;
type ItemToLabel<ItemType> = (item: ItemType) => string | Node;
type GetItemProps<ItemType> = ({ item: ItemType }) => Object;

type MenuProps<ItemType> = {
    id: string,
    data: ItemType[],
    highlightedIndex: ?number,
    itemToLabel: ItemToLabel<ItemType>,
    getItemProps: GetItemProps<ItemType>,
    newItem: ?ItemType,
    parentWidth?: *,
};

type AsyncState<ItemType> = {
    loading: boolean,
    error: ?string,
    data?: ?(ItemType[]),
};

type Props<ItemType> = {
    asyncState: AsyncState<ItemType>,
    id: string,
    lookup: (filter: string) => *,
    onSelect: (item: ItemType) => *,
    itemToString: ItemToString<ItemType>,
    itemToLabel: ItemToLabel<ItemType>,
    filter?: (filter: string, items: ItemType[]) => ItemType[],
    createNewItem?: (value: string) => ?ItemType,
    currentItem?: *,
    variant?: "default" | "white",
};

/**
 * Menu component
 */
const Menu = ({
    id,
    data,
    itemToLabel,
    getItemProps,
    highlightedIndex,
    newItem,
    parentWidth,
}: MenuProps<*>) => (
    <MenuList
        id={`${id}-menu`}
        className={styles.menu}
        style={{ width: parentWidth ? parentWidth : "inherit" }}
    >
        {data.map((item, index) => (
            <MenuItem
                {...getItemProps({
                    item,
                })}
                key={index}
                id={`${id}-item-${index}`}
                selected={highlightedIndex === index}
            >
                {itemToLabel(item)}
            </MenuItem>
        ))}
        {data.length > 0 && newItem && <Divider />}
        {newItem && (
            <MenuItem
                {...getItemProps({
                    item: newItem,
                })}
                key={data.length}
                id={`${id}-item-add`}
                selected={highlightedIndex === data.length}
                className={styles.newItem}
                style={{ width: parentWidth && parentWidth - 32 }}
            >
                {itemToLabel(newItem)}
            </MenuItem>
        )}
    </MenuList>
);

/**
 * An async filter select field
 */
export default class AsyncFilteredSelect extends PureComponent<Props<*>> {
    // input ref to get parent width
    inputRef: { current: null | * } = React.createRef();
    // Default props
    static defaultProps = {
        filter: (_: string, items: *) => items,
        lookup: () => undefined,
        currentItem: null,
        variant: "default",
    };

    lookup = debounce(this.props.lookup, 250);

    /**
     * Get the filtered items
     */
    getListing(inputValue: string, getItemProps: *, highlightedIndex: *) {
        const { asyncState, id, itemToLabel, filter, createNewItem } =
            this.props;

        if (!asyncState.data) {
            return null;
        }

        const filtered = filter(inputValue, asyncState.data);

        if (!filtered.length && !createNewItem) {
            return null;
        }

        return (
            <Paper className={styles.paper} elevation={2}>
                <Menu
                    id={id}
                    parentWidth={
                        this.inputRef &&
                        this.inputRef.current &&
                        this.inputRef.current.offsetParent &&
                        this.inputRef.current.offsetParent &&
                        this.inputRef.current.offsetParent.offsetParent &&
                        this.inputRef.current.offsetParent.offsetParent
                            .offsetWidth
                    }
                    data={filtered}
                    itemToLabel={itemToLabel}
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                    newItem={createNewItem && createNewItem(inputValue)}
                />
            </Paper>
        );
    }

    /**
     * Render
     */
    render() {
        /* eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }] */
        const {
            id,
            onSelect,
            itemToString,
            asyncState,
            lookup,
            filter,
            createNewItem,
            itemToLabel,
            currentItem,
            variant,
            ...rest
        } = this.props;

        return (
            <Downshift
                onChange={onSelect}
                itemToString={itemToString}
                selectedItem={currentItem}
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    highlightedIndex,
                    inputValue,
                }) => (
                    <div className={styles.element}>
                        <TextField
                            {...rest}
                            // $FlowFixMe
                            inputRef={this.inputRef}
                            InputProps={getInputProps({
                                endAdornment: asyncState.loading && (
                                    <InputAdornment position="end">
                                        <CircularProgress size={16} />
                                    </InputAdornment>
                                ),
                                onChange: ({ target: { value } }) =>
                                    this.lookup(value),
                                id: `${id}-input`,
                                classes: {
                                    underline: styles.underline,
                                    disabled: styles.disabled,
                                    focused: styles.focused,
                                    error: styles.error,
                                },
                            })}
                            id={`${id}-text-field`}
                            className={classnames({
                                [styles.whiteField]: variant === "white",
                            })}
                        />
                        {isOpen &&
                            this.getListing(
                                inputValue,
                                getItemProps,
                                highlightedIndex,
                            )}
                    </div>
                )}
            </Downshift>
        );
    }
}
