// @flow

import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { type State as VenuesState } from "../../redux/modules/venues.d";
import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";
import { type State as ExportDataState } from "../../redux/modules/export-data.d";
import { load as loadData } from "../../redux/modules/export-data";
import Grid from "@material-ui/core/Grid";
import { withAcl } from "../Acl";
import { withTranslate } from "../Translate";
import ExportData from "../../components/Events/ExportData";
import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import {
    load as loadOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";

import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";
import { type State as AddPromoterState } from "../../redux/modules/add-promoter.d";
import { mapState } from "../../redux/utils";

type Props = {
    acl: *,
    translate: *,
    venues: VenuesState,
    exportData: ExportDataState,
    onClose: () => void,
    organisers: OrganisersState,
    addPromoter: *,
    addPromoterState: AddPromoterState,
    loadOrganisers: *,
    loadData: (data: *, exportFor: *) => Promise<*>,
    loadVenues: () => *,
    clearAddPromoter: *,
};

class ExportDataContainer extends Component<Props> {
    /**
     * Render
     */

    componentDidMount() {
        const { loadOrganisers, loadVenues } = this.props;
        loadOrganisers();
        loadVenues();
    }
    render() {
        const {
            translate,
            acl,
            venues,
            exportData,
            loadData,
            organisers,
            addPromoterState,
            addPromoter,
            onClose,
        } = this.props;

        return (
            <div>
                <Grid container spacing={3}>
                    <Fragment>
                        <Grid item xs={12}>
                            <ExportData
                                acl={acl}
                                onClose={onClose}
                                organisers={organisers}
                                addPromoter={addPromoter}
                                addPromoterState={addPromoterState}
                                promoters={organisers}
                                disabled={false}
                                loadData={loadData}
                                loading={exportData.loading}
                                data={exportData}
                                translate={translate}
                                loadVenues={loadVenues}
                                venues={venues}
                                id="export-data"
                                exportData={exportData}
                                onSubmit={(data, exportFor) =>
                                    loadData(data, exportFor)
                                }
                            />
                        </Grid>
                    </Fragment>
                </Grid>
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            // Map state to props
            connect(
                ({ venues, exportData, organisers, addPromoter }) => ({
                    venues: mapState(venues, VENUES_ERROR, "venues"),
                    organisers: mapState(
                        organisers,
                        ORGANISERS_ERROR,
                        "promoters",
                    ),
                    exportData,
                    addPromoterState: mapState(
                        addPromoter,
                        ADD_PROMOTER_ERROR,
                        "uuid",
                    ),
                }),
                // Map dispatch to props
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            loadVenues,
                            loadOrganisers,
                            clearAddPromoter,
                            addPromoter,
                            loadData: (data, exportTo) =>
                                loadData(data, exportTo),
                        },
                        dispatch,
                    ),
            )(ExportDataContainer),
        ),
    ),
);
