// @flow

import React, { type Node } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

type Props = {
    translate?: *,
    disabled?: ?boolean,
    fullWidth?: ?boolean,
    id: string,
    label?: ?string,
    value: string,
    onChange: (value: string) => void,
    loading: boolean,
    options: ?([string, Node][]),
    error?: ?string,
    hasNone?: boolean,
};

/**
 * An async select component
 */
const AsyncSelect = ({
    id,
    disabled,
    fullWidth,
    label,
    value,
    onChange,
    loading,
    options,
    error,
    hasNone,
    translate,
}: Props) => (
    <FormControl disabled={!!disabled} fullWidth={!!fullWidth} error={!!error}>
        {label && <InputLabel htmlFor={`${id}-input`}>{label}</InputLabel>}
        <Select
            id={`${id}-input`}
            SelectDisplayProps={{
                id,
            }}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
        >
            {loading && (
                <MenuItem>
                    <CircularProgress />
                </MenuItem>
            )}
            {hasNone && translate && (
                <MenuItem id={`${id}-option-none`} value={null}>
                    <Typography>
                        <em>{translate("None")}</em>
                    </Typography>
                </MenuItem>
            )}
            {(options || []).map(([value, item], index) => (
                <MenuItem
                    id={`${id}-option-${index}`}
                    key={index}
                    value={value}
                >
                    {item}
                </MenuItem>
            ))}
            {error && (
                <MenuItem>
                    <Typography color="error">{error}</Typography>
                </MenuItem>
            )}
        </Select>
        {error && (
            <FormHelperText id={`${id}-helper-text`}>{error}</FormHelperText>
        )}
    </FormControl>
);

export default AsyncSelect;
