// @flow

import createApiModule from "../create-api-module";

import { type PathParams } from "./confirmed-options.d";

const { reducer, actions } = createApiModule(
    "confirmedOptions",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/series/${uuid}/options/confirmed`,
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load(
            {
                path: { uuid },
            },
            true,
        ),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
/** REDUCER **/
export default reducer;
