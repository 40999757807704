// @flow

import styles from "./style.module.scss";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";

import { type State as UpdateNameState } from "../../../redux/modules/update-staffing-template-name.d";
type Props = {
    translate: *,
    name: string,
    updateName: (name: string) => void,
    updateNameState: UpdateNameState,
    uuid: string,
    refetchTemplate: () => void,
};

const EditableTitle = ({
    name,
    updateName,
    updateNameState,
    uuid,
    refetchTemplate,
}: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(name);

    const disableSubmit = value === name || value === "";
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                {isEditing ? (
                    <TextField
                        fullWidth
                        autoFocus
                        multiline
                        error={
                            updateNameState.error ||
                            updateNameState.fieldErrors?.name
                        }
                        helperText={
                            updateNameState.error ||
                            updateNameState.fieldErrors?.name
                        }
                        value={value}
                        disabled={updateNameState.loading}
                        onChange={({ target: { value } }) => setValue(value)}
                    />
                ) : (
                    <Typography>{value}</Typography>
                )}
            </div>
            {isEditing ? (
                <>
                    <CheckIcon
                        className={classnames(
                            styles.icon,
                            styles["icon--check"],
                            {
                                [styles["icon--disabled"]]: disableSubmit,
                            },
                        )}
                        onClick={() =>
                            !disableSubmit &&
                            updateName(uuid, value).then(() => {
                                refetchTemplate();
                                setIsEditing(false);
                            })
                        }
                    />
                    <CloseIcon
                        className={classnames(
                            styles.icon,
                            styles["icon--close"],
                        )}
                        onClick={() => {
                            setIsEditing(false);
                            setValue(name);
                        }}
                    />
                </>
            ) : (
                <EditIcon
                    className={styles.icon}
                    onClick={() => setIsEditing(true)}
                />
            )}
        </div>
    );
};

export default EditableTitle;
