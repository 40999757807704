// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-line-member-qrcode.d";

const { reducer, actions } = createApiModule(
    "eventMemberQRCode",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/teams/members/${uuid}/qr-code`,
);

export const load =
    (uuid: string, force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(actions.load({ path: { uuid } }, force));
    };
export const ERROR = "Could not get QR code!";
export const refetch = actions.refetch;
export const clear = actions.clear;

export default reducer;
