// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./series-details.d";

const { reducer, actions } = createApiModule(
    "seriesDetails",

    ({ uuid, isAddToSeriesEvent }: PathParams) =>
        isAddToSeriesEvent
            ? `/ays-series-event-management/series/${uuid}/promoter`
            : `/ays-series-event-management/series/${uuid}`,
);

export const load =
    (uuid: string, isAddToSeriesEvent: boolean = false) =>
    (dispatch: *) => {
        return dispatch(actions.load({ path: { uuid, isAddToSeriesEvent } }));
    };

export const refetch = actions.refetch;

export const clear = actions.clear;

export default reducer;
