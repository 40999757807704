// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vMsAUI8FIWBQbEoEKr3Eag\\=\\= :first-child>div{display:flex}.vMsAUI8FIWBQbEoEKr3Eag\\=\\= :first-child>div span{align-self:center}.hBSQNAtd00KjyJAN9s7DVw\\=\\= p{color:white}.cD\\+Htv-PafhKnOiMAvXkTQ\\=\\= p{color:black}\n", "",{"version":3,"sources":["webpack://./../SelectList.scss"],"names":[],"mappings":"AAAA,6CAGY,YAAa,CAHzB,kDAKgB,iBAAkB,CACrB,8BAML,WAAY,CACf,+BAIG,WAAY","sourcesContent":[".element {\n    & :first-child {\n        & > div {\n            display: flex;\n            span {\n                align-self: center;\n            }\n        }\n    }\n}\n.light {\n    p {\n        color: white;\n    }\n}\n.dark {\n    p {\n        color: black;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "vMsAUI8FIWBQbEoEKr3Eag==",
	"light": "hBSQNAtd00KjyJAN9s7DVw==",
	"dark": "cD+Htv-PafhKnOiMAvXkTQ=="
};
export default ___CSS_LOADER_EXPORT___;
