//@flow
import styles from "./DetailsForm.scss";
import React, { Component, Fragment } from "react";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import {
    type OrganisationDetail,
    type State as DetailsState,
} from "../../../redux/modules/details.d";
import UploadPhoto from "../../UploadPhoto/UploadPhoto";
import { type Profile } from "../../../redux/modules/user.d";

type OnSubmit = (data: OrganisationDetail) => void;

type Props = {
    disabled: boolean,
    translate: *,
    details: DetailsState,
    onSubmit: OnSubmit,
    user: Profile,
};

type State = {
    name: string,
    maxLeaders: number,
    description: string,
    image: File,
    previewImage: string,
};

/**
 * Loader component
 */
const Loading = () => (
    <div className={styles.spinner}>
        <CircularProgress />
    </div>
);

/**
 * Form component
 */
class Form extends Component<Props, State> {
    // Initial state
    state = {
        name: ((this.props.details.data: any): OrganisationDetail).name,
        maxLeaders: ((this.props.details.data: any): OrganisationDetail)
            .maxLeaders,
        description: ((this.props.details.data: any): OrganisationDetail)
            .description,
        image: null,
        previewImage:
            ((this.props.details.data: any): OrganisationDetail)
                .organisationImagePath + "/64x64.png",
    };

    /**
     * Get the disabled state of this form
     */
    get disabled(): boolean {
        const { details, disabled } = this.props;
        return disabled || !details.uuid || details.loading || !details.data;
    }

    get canLeaderEditOrganisation(): boolean {
        const { user } = this.props;
        return user && user.authorities.includes("ROLE_ORGANISATION_LEADER");
    }

    /**
     * Reset the form into the original state
     */
    reset() {
        const { details } = this.props;
        const { name, maxLeaders, description }: OrganisationDetail =
            (details.data: any);

        this.setState({
            name,
            maxLeaders,
            description,
        });
    }

    /**
     * Retrieve the field error if present
     */
    getFieldError(field: string): ?string {
        const {
            details: { fieldErrors },
        } = this.props;
        return fieldErrors && fieldErrors[field];
    }

    /**
     * Get the change handler
     */
    getChangeHandler(
        field: string,
        postProcess?: (value: string) => string | number,
    ) {
        return ({ target: { value } }: *) =>
            this.setState({
                [field]: postProcess ? postProcess(value) : value,
            });
    }

    handleImagePreview = e => {
        let image_as_files = e.target.files[0];
        let preview = URL.createObjectURL(e.target.files[0]);

        this.setState({
            image: image_as_files,
            previewImage: preview,
        });
    };

    /**
     * Submit handler
     */
    handleSubmit(event: *) {
        event.preventDefault();
        const {
            onSubmit,
            details: { uuid },
        } = this.props;

        const { name, maxLeaders, image, description } = this.state;

        const parsedMax = parseInt(maxLeaders);

        onSubmit({
            maxLeaders: isNaN(parsedMax) ? parsedMax : maxLeaders,
            name,
            uuid,
            image,
            description: description,
        });
    }

    /**
     * Render
     */
    render() {
        const { translate, details } = this.props;
        const { name, maxLeaders, description, previewImage } = this.state;

        return (
            <form
                className={styles.form}
                onSubmit={this.handleSubmit.bind(this)}
            >
                {details.error && (
                    <Typography color="error">{details.error}</Typography>
                )}
                <TextField
                    id="detailsform-name"
                    label={translate("Name")}
                    onChange={this.getChangeHandler("name")}
                    value={name}
                    error={!!this.getFieldError("name")}
                    helperText={this.getFieldError("name")}
                    disabled={this.disabled}
                    fullWidth
                />
                <TextField
                    id="detailsform-leaders"
                    label={translate("Max. Leaders")}
                    type="number"
                    onChange={this.getChangeHandler("maxLeaders")}
                    value={maxLeaders}
                    error={!!this.getFieldError("maxLeaders")}
                    helperText={this.getFieldError("maxLeaders")}
                    disabled={this.disabled}
                    fullWidth
                />
                <TextField
                    id="detailsform-description"
                    label={translate("Description")}
                    onChange={this.getChangeHandler("description")}
                    type="text"
                    value={description}
                    error={!!this.getFieldError("description")}
                    helperText={this.getFieldError("description")}
                    disabled={this.disabled && !this.canLeaderEditOrganisation}
                    fullWidth
                />
                <UploadPhoto
                    onChange={this.handleImagePreview}
                    previewImage={previewImage}
                    disabled={this.disabled && !this.canLeaderEditOrganisation}
                />
                <div className={styles.actionButtons}>
                    <Button
                        id="detailsform-cancel"
                        disabled={
                            this.disabled && !this.canLeaderEditOrganisation
                        }
                        onClick={this.reset.bind(this)}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id="detailsform-confirm"
                        disabled={
                            this.disabled && !this.canLeaderEditOrganisation
                        }
                        type="submit"
                        color="primary"
                    >
                        {translate("Save changes")}
                    </Button>
                </div>
            </form>
        );
    }
}

const DetailsForm = ({
    details,
    translate,
    onSubmit,
    disabled,
    user,
}: Props) => (
    <div className={styles.main}>
        <Paper elevation={2}>
            <Fragment>
                {details.error && !details.data && (
                    <Typography color="error">{details.error}</Typography>
                )}
                {!details.uuid ||
                    (details.loading && !details.data && <Loading />)}
                {details.data && (
                    <Form
                        disabled={disabled}
                        details={details}
                        translate={translate}
                        onSubmit={onSubmit}
                        key={details.uuid}
                        user={user}
                    />
                )}
            </Fragment>
        </Paper>
    </div>
);

DetailsForm.defaultProps = {
    disabled: false,
};

export default DetailsForm;
