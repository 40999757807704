// @flow

import React, { Component, Fragment, type Node } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    type Match,
    type RouterHistory,
    Switch,
    Route,
    Redirect,
    withRouter,
} from "react-router";

import stripslash from "../utils/stripslash";

import {
    ChangePassword,
    ConfigurePassword,
    ForgotPassword,
    Hi,
    Password,
    Shell,
} from "../components/Login";

import {
    loadStatus,
    loadToken,
    register,
    resendVerification,
    clearErrors,
} from "../redux/modules/user";
import { type User } from "../redux/modules/user.d";

import { withTranslate } from "./Translate";

type ClearErrorsOnFormChangeProps = {
    children: Node | Node[] | string,
    clear: () => *,
    form: string,
};

type ClearErrorsOnFormChangeState = {
    form: ?string,
};

type Props = {
    translate: *,
    user: User,
    match: Match,
    history: RouterHistory,
    loadStatus: (value: string) => *,
    loadToken: (value: string) => *,
    register: (password: string, verificationToken: string) => *,
    resendVerification: () => Promise<void>,
    clearErrors: () => *,
};

/**
 * Clears errors on component mount
 */
class ClearErrorsOnFormChange extends Component<
    ClearErrorsOnFormChangeProps,
    ClearErrorsOnFormChangeState,
> {
    state = {
        form: null,
    };

    /**
     * Clear errors when props change
     */
    static getDerivedStateFromProps(
        props: ClearErrorsOnFormChangeProps,
        state: ClearErrorsOnFormChangeState,
    ) {
        if (props.form !== state.form) {
            props.clear();
        }

        return {
            form: props.form,
        };
    }

    /**
     * Render children
     */
    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

const Login = withTranslate(
    withRouter(
        connect(
            state => ({
                user: state.user.toJS(),
            }),
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadStatus,
                        loadToken,
                        register,
                        resendVerification,
                        clearErrors,
                    },
                    dispatch,
                ),
        )(
            ({
                translate,
                match,
                history,
                user,
                loadStatus,
                loadToken,
                register,
                resendVerification,
                clearErrors,
            }: Props) => {
                return (
                    <Shell>
                        <Switch>
                            <Route path={`${match.path}verify/`} exact>
                                {() => {
                                    if (!user.id) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(match.url)}/`}
                                            />
                                        );
                                    }

                                    if (user.registered) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(
                                                    match.url,
                                                )}/password/`}
                                            />
                                        );
                                    }

                                    return (
                                        <ClearErrorsOnFormChange
                                            clear={clearErrors}
                                            form="ConfigurePassword"
                                        >
                                            <ConfigurePassword
                                                translate={translate}
                                                onSubmit={register}
                                                onSendAgain={resendVerification}
                                                phone={user.id}
                                                fieldErrors={user.fieldErrors}
                                                error={user.error}
                                                busy={user.loading}
                                            />
                                        </ClearErrorsOnFormChange>
                                    );
                                }}
                            </Route>
                            <Route
                                path={`${match.path}password/forgotten/`}
                                exact
                            >
                                {() => {
                                    if (!user.id) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(match.url)}/`}
                                            />
                                        );
                                    }

                                    if (!user.registered) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(
                                                    match.url,
                                                )}/verify/`}
                                            />
                                        );
                                    }

                                    return (
                                        <ClearErrorsOnFormChange
                                            clear={clearErrors}
                                            form="ForgotPassword"
                                        >
                                            <ForgotPassword
                                                translate={translate}
                                                onSubmit={() =>
                                                    resendVerification().then(
                                                        () =>
                                                            history.push(
                                                                `${match.path}password/change/`,
                                                            ),
                                                    )
                                                }
                                                phone={user.id}
                                            />
                                        </ClearErrorsOnFormChange>
                                    );
                                }}
                            </Route>
                            <Route path={`${match.path}password/change/`} exact>
                                {() => {
                                    if (!user.id) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(match.url)}/`}
                                            />
                                        );
                                    }

                                    return (
                                        <ClearErrorsOnFormChange
                                            clear={clearErrors}
                                            form="ChangePassword"
                                        >
                                            <ChangePassword
                                                translate={translate}
                                                onSubmit={register}
                                                onSendAgain={resendVerification}
                                                phone={user.id}
                                                fieldErrors={user.fieldErrors}
                                                error={user.error}
                                                busy={user.loading}
                                            />
                                        </ClearErrorsOnFormChange>
                                    );
                                }}
                            </Route>
                            <Route path={`${match.path}password/`} exact>
                                {() => {
                                    if (!user.id) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(match.url)}/`}
                                            />
                                        );
                                    }

                                    if (!user.registered) {
                                        return (
                                            <Redirect
                                                to={`${stripslash(
                                                    match.url,
                                                )}/verify/`}
                                            />
                                        );
                                    }

                                    return (
                                        <ClearErrorsOnFormChange
                                            clear={clearErrors}
                                            form="Password"
                                        >
                                            <Password
                                                translate={translate}
                                                onSubmit={loadToken}
                                                error={user.error}
                                                busy={user.loading}
                                                forgottenLink={`${match.path}password/forgotten/`}
                                            />
                                        </ClearErrorsOnFormChange>
                                    );
                                }}
                            </Route>
                            <Route path={match.path} exact>
                                {() => {
                                    if (!user.error && !user.loading) {
                                        if (user.id && user.registered) {
                                            return (
                                                <Redirect
                                                    to={`${stripslash(
                                                        match.url,
                                                    )}/password/`}
                                                />
                                            );
                                        }

                                        if (user.id) {
                                            return (
                                                <Redirect
                                                    to={`${stripslash(
                                                        match.url,
                                                    )}/verify/`}
                                                />
                                            );
                                        }
                                    }

                                    return (
                                        <ClearErrorsOnFormChange
                                            clear={clearErrors}
                                            form="Hi"
                                        >
                                            <Hi
                                                translate={translate}
                                                onSubmit={value => {
                                                    clearErrors();
                                                    loadStatus(value);
                                                }}
                                                error={user.error}
                                                busy={user.loading}
                                            />
                                        </ClearErrorsOnFormChange>
                                    );
                                }}
                            </Route>
                            <Route path="*">
                                <h1>NOT FOUND</h1>
                            </Route>
                        </Switch>
                    </Shell>
                );
            },
        ),
    ),
);

export default Login;
