// @flow

import moment from "moment";
import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { type State as OrganisationState } from "../../../redux/modules/organisations.d";

import {
    type State as AsyncState,
    type NewPool,
} from "../../../redux/modules/event-recruitment-add.d";

import AsyncFilteredSelect from "../../AsyncFilteredSelect";
import { DateTimeField } from "../../DatePickers";
import styles from "./AddGroup.scss";

type Props = {
    /** Form disabled flag */
    disabled: boolean,
    /** Dialog id */
    id: string,
    /** translate api */
    translate: *,
    /** onClose */
    onClose: () => void,
    /** Submit handler */
    onSubmit: (data: NewPool) => Promise<void>,
    /** The form state */
    asyncState: AsyncState,
    /** The show name */
    showName: string,
    /** The organisation state */
    organisations: OrganisationState,
    /** Find organisations */
    findOrganisations: *,
    /** Show startdate */
    startDate: ?string,
    /** Show enddate */
    endDate: ?string,
};

/**
 * Fetches the value from an event
 */
const getValueFromEvent = (event: * | string): string => {
    if (typeof event === "string") {
        return event;
    }
    if (moment.isMoment(event)) {
        return moment.utc(event).toDate();
    }

    return event.target.value;
};

/**
 * Label map
 */
const getLabel = (field, translate) => {
    if (field === "name") {
        return translate("Group name");
    }

    if (field === "description") {
        return translate("Description");
    }

    if (field === "start") {
        return translate("Start");
    }

    if (field === "end") {
        return translate("End");
    }

    if (field === "organisationUuid") {
        return translate("Organisation");
    }

    return "";
};

const AddGroup = ({
    disabled,
    startDate,
    endDate,
    asyncState,
    id,
    translate,
    organisations,
    findOrganisations,
    onSubmit,
    onClose,
    showName,
}: Props) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [organisationUuid, setOrganisationUuid] = useState("");
    const [organisation, setOrganisation] = useState(null);

    useEffect(() => {
        setStart(startDate || moment().utc().toISOString());
        setEnd(endDate || moment().utc().toISOString());
    }, []);

    useEffect(() => {
        moment(end).isBefore(start) && setEnd(start);
    }, [start]);

    const isDisabled = () => disabled || asyncState.loading;

    /**
     * Can submit test
     */
    const canSubmit = () => {
        if (isDisabled()) return false;

        if (
            !name.trim().length ||
            !description.trim().length ||
            !organisationUuid.trim().length
        ) {
            return false;
        }

        return true;
    };

    /**
     * Get a field error if present
     */
    const getFieldError = (field: string) =>
        asyncState.fieldErrors && asyncState.fieldErrors[field];

    /**
     * Render a field
     */
    const renderField = (field: string, value: any, handleChange: Function) => {
        const error = getFieldError(field);

        if (field === "organisationUuid") {
            return (
                <AsyncFilteredSelect
                    id={`${id}-${field}`}
                    error={!!error}
                    helperText={error}
                    label={getLabel(field, translate)}
                    value={
                        (organisation && organisation.organisationName) || ""
                    }
                    onSelect={organisation => {
                        setOrganisationUuid(organisation.organisationUuid);
                        setOrganisation(organisation);
                    }}
                    asyncState={(organisations: any)}
                    lookup={findOrganisations}
                    itemToString={item => (item && item.organisationName) || ""}
                    itemToLabel={item => (item && item.organisationName) || ""}
                    filter={(value, items) =>
                        (items: any).content.filter(item =>
                            item.organisationName
                                .toLowerCase()
                                .includes(value.toLowerCase()),
                        )
                    }
                    fullWidth
                />
            );
        }

        const Component =
            (["start", "end"].includes(field) && DateTimeField) || TextField;

        return (
            <Component
                id={`${id}-${field}`}
                label={getLabel(field, translate)}
                value={value}
                error={!!error}
                helperText={error}
                disabled={isDisabled()}
                onChange={event =>
                    handleChange(getValueFromEvent(event), field)
                }
                fullWidth
            />
        );
    };

    /**
     * Submit handler
     */
    const handleSubmit = (event: *) => {
        event.preventDefault();

        if (canSubmit) {
            onSubmit({
                start,
                end,
                name,
                description,
                organisationUuid,
            }).then(onClose);
        }
    };

    /**
     * Render
     */
    return (
        <Dialog onClose={onClose} open className={styles.element}>
            <form onSubmit={handleSubmit}>
                <DialogTitle disableTypography>
                    <Typography variant="h2">
                        {translate("Add group")}
                    </Typography>
                    <Typography>
                        {translate(
                            "Create a new recruitment group for %{show}",
                            { show: showName },
                        )}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        {asyncState.error && (
                            <Grid item xs={12}>
                                <Typography color="error">
                                    {asyncState.error}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item sm={12}>
                            {renderField("name", name, setName)}
                        </Grid>
                        <Grid item sm={12}>
                            {renderField(
                                "description",
                                description,
                                setDescription,
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {renderField("start", start, setStart)}
                        </Grid>
                        <Grid item xs={6}>
                            {renderField("end", end, setEnd)}
                        </Grid>
                        <Grid item sm={12}>
                            {renderField(
                                "organisationUuid",
                                organisationUuid,
                                setOrganisationUuid,
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        id={`${id}-cancel`}
                        onClick={onClose}
                        disabled={isDisabled()}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id={`${id}-submit`}
                        type="submit"
                        disabled={!canSubmit()}
                        color="primary"
                    >
                        {translate("Add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddGroup;
