// @flow

import styles from "./Shell.scss";

import logo from "../../logo.svg";
import backdrop from "./backdrop.jpg";

import React, { type Node } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

type Props = {
    children: Node,
    classes: {
        [key: string]: string,
    },
};

/**
 * Shell component
 */
const Shell = withStyles(theme => ({
    splash: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${backdrop})`,
    },
}))(({ children, classes }: Props) => (
    <Grid className={styles.wrapper} container spacing={0}>
        <Grid className={styles.splash} item lg={7} md={6} sm={5} xs={12}>
            <div className={classes.splash}>
                <img className={styles.logo} src={logo} />
            </div>
        </Grid>
        <Grid item lg={5} md={6} sm={7} xs={12}>
            <div className={styles.content}>{children}</div>
        </Grid>
    </Grid>
));

export default Shell;
