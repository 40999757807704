// @flow
import createApiModule from "../create-api-module";

import { load as loadMemberships } from "./admin-user-memberships";
import type { PathParams } from "./admin-user.d";

const { reducer, actions } = createApiModule(
    "adminUser",
    ({ uuid }: PathParams) => `ays-user-management/users/${uuid}`,
);

export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
        }),
    ).then(() => dispatch(loadMemberships(uuid)));
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not find member!";
/** REDUCER **/
export default reducer;
