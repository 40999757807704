// @flow

import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
    load,
    clear,
    ERROR as ADMIN_USER_ERROR,
} from "../../redux/modules/admin-user";
import { refetch as reload } from "../../redux/modules/admin";
import {
    load as update,
    clear as clearAdminUserEdit,
    ERROR as ADMIN_USER_EDIT_ERROR,
} from "../../redux/modules/admin-user-edit";
import { ERROR as LOAD_MEMBERSHIPS_ERROR } from "../../redux/modules/admin-user-memberships";

import { User } from "../../components/Admin";

import { withTranslate } from "../Translate";
import { mapState } from "../../redux/utils";

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ adminUserEdit, adminUser, adminUserMemberships }) => ({
                asyncState: mapState(adminUserEdit, ADMIN_USER_EDIT_ERROR),
                userMemberships: mapState(
                    adminUserMemberships,
                    LOAD_MEMBERSHIPS_ERROR,
                    "memberships",
                ),
                userState: {
                    ...mapState(adminUser, ADMIN_USER_ERROR),
                    uuid:
                        adminUser.params &&
                        adminUser.params.path &&
                        adminUser.params.path.uuid,
                },
            }),
            // Map dispatch to props
            (
                dispatch: *,
                {
                    match: {
                        params: { id: uuid },
                    },
                },
            ) => ({
                loadUser: (() => dispatch(load(uuid)): any),
                clearUser: (() => dispatch(clear()): any),
                clearUserEdit: () => dispatch(clearAdminUserEdit()),
                onSubmit: data =>
                    dispatch(update(uuid, data)).then(() => dispatch(reload())),
            }),
        )(User),
    ),
);
