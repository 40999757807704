// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import {
    type ImmutableState,
    type Action,
    type FullState,
} from "./event-recruits-interested.d";
import { typeof Client } from "../../utils/api-client/modules/event-recruits";

const ERROR = "Could not find any recruits!";
const ZONE = "event-recruits-interested";
const CLEAR = `${ZONE}/clear`;
const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const checkPayload = (test: any, actionType: string): void =>
    invariant(test, `Wrong payload for ${actionType}`);

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    filter: "",
    data: null,
    page: null,
    pool: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        checkPayload(payload, PENDING);

        return state.merge({
            loading: true,
            pool: (payload: any).pool,
            filter: (payload: any).filter,
            page: (payload: any).page,
            error: null,
        });
    }

    if (type === SUCCESS) {
        checkPayload(payload, SUCCESS);

        return state.merge({
            loading: false,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        checkPayload(typeof payload === "string", FAILURE);

        return state.merge({
            loading: false,
            error: payload,
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load recruits
 */
export const load =
    (
        pool: string,
        page: number,
        filter: string,
        recruitmentStatuses: string[],
        force: boolean = false,
    ) =>
    (
        dispatch: (action: Action) => *,
        getState: () => FullState,
        client: Client,
    ): Promise<void> => {
        const { showRecruitsInterested: state } = getState();

        if (
            state.get("loading") ||
            (!force &&
                state.get("filter") === filter &&
                state.get("page") === page &&
                state.get("pool") === pool &&
                state.get("recruitmentStatuses") === recruitmentStatuses)
        ) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: {
                pool,
                filter,
                page,
                recruitmentStatuses,
            },
        });

        return client
            .getPoolRecruits(pool, page, filter, recruitmentStatuses, false)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };

/**
 * Actioncreator: reload
 */
export const reload = () => (dispatch: *, getState: () => FullState) => {
    const { showRecruitsInterested: state } = getState();

    invariant(state.get("page") && state.get("pool"), "Wrong usage of reload!");

    return dispatch(
        load(
            (state.get("pool"): any),
            (state.get("page"): any),
            (state.get("filter"): any),
            (state.get("recruitmentStatuses"): any),
            true,
        ),
    );
};
