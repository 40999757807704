// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-lines.d";

const { reducer, actions } = createApiModule(
    "eventLines",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/events/${uuid}/lines`,
);

export const load =
    (uuid: string, force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(actions.load({ path: { uuid } }, force));
    };

export const ERROR = "Could not fetch event lines";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
