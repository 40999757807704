// @flow

//impoet styles
import styles from "./AttendeeDetail.scss";

//import libs
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { format, mapToLocalTimezone } from "../../../utils/date";

type ITEM = {
    firstName: string,
    lastName: string,
    profilePicturePath: string,
    presenceStatus: string,
    checkInTime: string | null,
    checkOutTime: string | null,
    geofencingStatus: string,
};

type Props = {
    item: ITEM,
    onClose: () => void,
    translate: *,
};

export default class AttendeeDetail extends Component<Props> {
    /**
     * get attendee fullname
     */
    get name(): string {
        const { item } = this.props;

        if (!item) {
            return "...";
        }

        return item.firstName + " " + item.lastName;
    }

    render() {
        const { item, onClose, translate } = this.props;

        return (
            <Dialog open onClose={onClose} className={styles.element}>
                <DialogTitle
                    disableTypography
                    className={styles.titleContainer}
                >
                    <Typography variant="h3" className={styles.title}>
                        {this.name}
                    </Typography>
                </DialogTitle>

                <div className={styles.contentContainer}>
                    <DialogContent>
                        {item.profilePicturePath && (
                            <span>
                                <img
                                    alt={`${this.name}`}
                                    src={`${item.profilePicturePath}/256x256.png`}
                                />
                            </span>
                        )}
                    </DialogContent>
                    {(item.checkInTime || item.checkOutTime) && (
                        <div className={styles.infoContainer}>
                            {item.checkInTime && (
                                <div className={styles.valueContainer}>
                                    <Typography
                                        variant="h4"
                                        className={styles.subtitle2}
                                    >
                                        {`${translate("First check in")}:`}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        className={styles.subtitle2}
                                    >
                                        {format(
                                            mapToLocalTimezone(
                                                item.checkInTime,
                                            ),
                                        )}
                                    </Typography>
                                </div>
                            )}
                            {item.checkOutTime && (
                                <div className={styles.valueContainer}>
                                    <Typography
                                        variant="h4"
                                        className={styles.subtitle2}
                                    >
                                        {`${translate("Last check out")}: `}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        className={styles.subtitle2}
                                    >
                                        {format(
                                            mapToLocalTimezone(
                                                item.checkOutTime,
                                            ),
                                        )}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {item.presenceStatus === "CHECKED_IN" && (
                    <Typography variant="h3" className={styles.subtitle}>
                        {translate("Checked in")}
                    </Typography>
                )}
                {item.presenceStatus === "NOT_CHECKED" && (
                    <Typography variant="h3" className={styles.subtitle}>
                        {translate("Not checked")}
                    </Typography>
                )}
                {item.presenceStatus === "CHECKED_OUT" && (
                    <Typography variant="h3" className={styles.subtitle}>
                        {translate("Checked out")}
                    </Typography>
                )}
            </Dialog>
        );
    }
}
