// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5deL\\+F0-ATot30c3fdXd7A\\=\\={display:inline-block}._6P5-2pWmFTL-UvKWgKqqrg\\=\\={cursor:default;display:flex;align-items:center;justify-content:center;text-align:center;align-content:center;border-radius:0.2em;flex-direction:row;margin:0.2em;width:1.5em;height:1.5em}._6P5-2pWmFTL-UvKWgKqqrg\\=\\= .QyZisEmcTcEQQKyiNNNPLA\\=\\={font-size:0.8em;font-weight:bold}\n", "",{"version":3,"sources":["webpack://./../VenueBox.scss"],"names":[],"mappings":"AAKA,8BACI,oBAAqB,CACxB,6BAEG,cAAe,CACf,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,iBAAkB,CAClB,oBAAqB,CACrB,mBAAoB,CACpB,kBAAmB,CACnB,YAAa,CACb,WAAY,CACZ,YAAa,CAXjB,yDAcQ,eAAgB,CAEhB,gBAAiB","sourcesContent":["/**\n* LineList styling\n*/\n\n@import \"../../styles/variables\";\n.item {\n    display: inline-block;\n}\n.venue {\n    cursor: default;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    align-content: center;\n    border-radius: 0.2em;\n    flex-direction: row;\n    margin: 0.2em;\n    width: 1.5em;\n    height: 1.5em;\n\n    .inner {\n        font-size: 0.8em;\n        // color: $white;\n        font-weight: bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "_5deL+F0-ATot30c3fdXd7A==",
	"venue": "_6P5-2pWmFTL-UvKWgKqqrg==",
	"inner": "QyZisEmcTcEQQKyiNNNPLA=="
};
export default ___CSS_LOADER_EXPORT___;
