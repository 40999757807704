// @flow
import styles from "./EditSeries.scss";

import React, { Component } from "react";

import get from "lodash.get";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { type State as OrganisersState } from "../../../redux/modules/organisers.d";
import AsyncFilteredSelect from "../../AsyncFilteredSelect";
import isEqual from "lodash.isequal";

type Props = {
    translate: *,
    onClose: () => void,
    onSubmit: (event: *) => *,

    /** Disables the form */
    disabled: boolean,
    eventDetails: *,
    /** Organisers */
    loadOrganisers: *,
    organisers: OrganisersState,
    id: string,
    /** Promoters */
    addPromoter: (value: *) => Promise<*>,
    addPromoterState: *,
    /** Series */
    editSeriesState: *,
    error?: *,
    refetchOrganisers: () => Promise<*>,
};

type State = {
    seriesName: *,
    serieUuid: *,
    internal: *,
    promoterUuid: *,
    promoterName: *,
    optionCode: ?string,
};

/**
 * Add dialog
 */
export default class EditSeries extends Component<Props, State> {
    // Initial state
    state: State = {
        seriesName: null,
        serieUuid: null,
        promoterName: null,
        promoterUuid: null,
        internal: null,
        event: null,
        optionCode: null,
    };

    componentDidUpdate(prevProps: *) {
        const {
            eventDetails: { data },
        } = this.props;

        if (!isEqual(prevProps.eventDetails.data, data)) {
            this.setState({
                ...this.state,
                serieUuid: data.uuid,
                seriesName: data.name || "",
                internal: data.internal || "",
                optionCode: data.optionCode || "",
            });
        }
    }

    /**
     * Disabled getter
     */
    get disabled() {
        const { disabled, editSeriesState } = this.props;

        return !!(disabled || (editSeriesState && editSeriesState.loading));
    }

    /**
     * Get errors
     */
    getError(key: string) {
        const { editSeriesState, addPromoterState } = this.props;

        let transformedKey = key;

        if (editSeriesState.fieldErrors) {
            return get(editSeriesState.fieldErrors, transformedKey);
        }

        if (addPromoterState.fieldErrors) {
            return get(addPromoterState.fieldErrors, transformedKey);
        }
    }

    /**
     * Test if submission is possible
     */
    get canSubmit() {
        const { seriesName } = this.state;

        if (!seriesName) {
            return false;
        }

        return true;
    }

    /**
     * Submit handler
     */
    handleSubmit(event: *) {
        const { internal, seriesName, promoterUuid, optionCode } = this.state;
        const { onSubmit } = this.props;
        event.preventDefault();
        // if (seriesName && this.canSubmit) {
        const newEvent = {
            internal: internal,
            seriesName: seriesName,
            promoterUuid: promoterUuid,
            optionCode,
        };
        onSubmit(newEvent);
        //  }
    }

    /**
     * Render
     */
    render() {
        const {
            onClose,
            translate,
            organisers,
            id,
            addPromoterState,
            editSeriesState: { error },
        } = this.props;
        const { internal, seriesName, promoterName, promoterUuid, optionCode } =
            this.state;

        return (
            <Dialog open>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <DialogTitle disableTypography className={styles.title}>
                        <Typography variant="h2" color="inherit">
                            {translate("Edit event series")}
                        </Typography>
                        <Typography>
                            {translate(
                                "Edit all of the event at once in this series",
                            )}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={styles.content}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    className={styles.field}
                                    id={`${id}-serie`}
                                    label={translate("Event series")}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            seriesName: value,
                                        })
                                    }
                                    value={seriesName || ""}
                                    error={!!this.getError("name")}
                                    helperText={this.getError("name")}
                                    InputLabelProps={{
                                        style: { color: "white" },
                                    }}
                                    InputProps={{
                                        classes: {
                                            underline: styles.underline,
                                            disabled: styles.disabled,
                                            focused: styles.focused,
                                            error: styles.error,
                                        },
                                    }}
                                    disabled={this.disabled}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={styles.field}
                                    id={`${id}-optionCode`}
                                    label={translate("Option Code")}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            optionCode: value,
                                        })
                                    }
                                    value={optionCode || ""}
                                    error={!!this.getError("optionCode")}
                                    helperText={this.getError("optionCode")}
                                    disabled={this.disabled}
                                    InputLabelProps={{
                                        style: {
                                            color: "white",
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            underline: styles.underline,
                                            disabled: styles.disabled,
                                            focused: styles.focused,
                                            error: styles.error,
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={styles.field}
                                    id={`${id}-internal`}
                                    label={translate("Memo")}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            internal: value,
                                        })
                                    }
                                    value={internal || ""}
                                    error={!!this.getError("internal")}
                                    helperText={this.getError("internal")}
                                    disabled={this.disabled}
                                    InputLabelProps={{
                                        style: {
                                            color: "white",
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            underline: styles.underline,
                                            disabled: styles.disabled,
                                            focused: styles.focused,
                                            error: styles.error,
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <AsyncFilteredSelect
                                    id={`${id}-promoter`}
                                    disabled={this.disabled}
                                    variant="white"
                                    error={
                                        !!(
                                            addPromoterState.error ||
                                            (addPromoterState.fieldErrors &&
                                                addPromoterState.fieldErrors
                                                    .name) ||
                                            this.getError("promoterUuid")
                                        )
                                    }
                                    helperText={
                                        (!!addPromoterState.error &&
                                            addPromoterState.error) ||
                                        (addPromoterState.fieldErrors &&
                                            addPromoterState.fieldErrors
                                                .name) ||
                                        this.getError("promoterUuid")
                                    }
                                    label={translate("Organiser")}
                                    asyncState={organisers}
                                    onSelect={promoter =>
                                        this.setState({
                                            promoterUuid:
                                                (promoter: any).uuid || null,
                                            promoterName: (promoter: any).uuid
                                                ? null
                                                : (promoter: any).name,
                                        })
                                    }
                                    itemToString={item =>
                                        (item && item.name) || ""
                                    }
                                    itemToLabel={item =>
                                        (item && (item.label || item.name)) ||
                                        ""
                                    }
                                    filter={(value, items) =>
                                        items
                                            .filter(item =>
                                                (item: any).name
                                                    .toLowerCase()
                                                    .includes(
                                                        value.toLowerCase(),
                                                    ),
                                            )
                                            .splice(0, 5)
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogContent>
                        <Grid container spacing={3}>
                            {
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            fontWeight: "bold",
                                            marginTop: "1em",
                                        }}
                                    >
                                        {translate("Caution")}:
                                    </Typography>
                                    <Typography
                                        style={{
                                            opacity: "0.75",
                                        }}
                                    >
                                        {translate("Warning Edit Series")}.
                                    </Typography>
                                    {promoterName ||
                                        (promoterUuid && (
                                            <Typography
                                                style={{
                                                    fontWeight: "bold",
                                                    marginTop: "0.5em",
                                                }}
                                            >
                                                {translate(
                                                    "Warning Save Changes",
                                                )}
                                            </Typography>
                                        ))}
                                </Grid>
                            }
                            {error && (
                                <Typography color="error">{error}</Typography>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            id={`${id}-addshow-cancel`}
                            onClick={onClose}
                            disabled={this.disabled}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id={`${id}-submit`}
                            type="submit"
                            disabled={this.disabled}
                            color="primary"
                        >
                            {translate("Save changes")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
