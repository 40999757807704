//@flow

import { fromJS } from "immutable";
import { typeof Client } from "../../utils/api-client/modules/lines";
import { type IState, type Action } from "./organisationlines.d";
import invariant from "invariant";

const ERROR_ORGANISATIONLINES =
    "Could not fetch the available organisation lines.";
const ERROR_DELETELINE = "Could not delete the line.";
const ERROR_ADDLINES = "Could not add lines.";

const PENDING = "organisationlines/pending";
const SUCCESS = "organisationlines/success";
const FAILURE = "organisationlines/failure";

const UPDATE_LINE_DEFAULT = "organisationlines/default/update";

const DELETE_PENDING = "lines/delete/pending";
const DELETE_FAILED = "lines/delete/failure";

const ADD_PENDING = "lines/add/pending";
const ADD_FAILURE = "lines/add/failure";

const initialState: IState = (fromJS({
    loading: false,
    data: null,
    error: null,
    page: 0,
    organisationUUID: null,
    filter: "",
    sorting: [],
}): any);

/**
 * Reducer
 */
export default (state: IState = initialState, { type, payload }: Action): * => {
    if (type === PENDING) {
        const { organisationUUID, page, filter, sorting } = (payload: any);
        invariant(page, "Wrong action format!");
        return state.merge({
            loading: true,
            page,
            filter,
            sorting,
            organisationUUID,
            error: null,
        });
    }

    if (type === SUCCESS) {
        return state.merge({
            loading: false,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        return state.merge({
            loading: false,
            error: payload,
        });
    }

    if (type === DELETE_PENDING) {
        invariant(typeof payload === "string", "Wrong payload!");
        invariant(state.get("data"), "No data!");

        return (state: any)
            .merge({
                loading: true,
                error: null,
            })
            .updateIn(["data", "content"], content =>
                content.map(item =>
                    item.get("lineUuid") === payload
                        ? item.set("pendingDelete", true)
                        : item,
                ),
            );
    }

    if (type === DELETE_FAILED) {
        invariant(payload && payload.error && payload.uuid, "Wrong payload!");
        invariant(state.get("data"), "No data!");

        return (state: any)
            .merge({
                error: payload.error,
                loading: false,
            })
            .updateIn(["data", "content"], content =>
                content.map(item =>
                    item.get("lineUuid") === payload.uuid
                        ? item.set("pendingDelete", false)
                        : item,
                ),
            );
    }

    if (type === ADD_PENDING) {
        return state.set("loading", true);
    }

    if (type === ADD_FAILURE) {
        invariant(typeof payload === "string", "Wrong payload!");

        return state.merge({
            loading: false,
            error: payload,
        });
    }

    if (type === UPDATE_LINE_DEFAULT) {
        invariant(payload && payload.uuid, "Wrong payload!");

        if (!state.get("data")) {
            return;
        }

        return (state: any).updateIn(["data", "content"], content =>
            content.map(item =>
                item.get("lineUuid") === payload.uuid
                    ? item.set("default", payload.isDefault)
                    : item,
            ),
        );
    }

    return state;
};

/**
 * ActionCreator: update the default field of a line
 */
export const updateLineDefault = (uuid: string, isDefault: boolean) => ({
    type: UPDATE_LINE_DEFAULT,
    payload: {
        uuid,
        isDefault,
    },
});

/**
 * ActionCreator: load
 */
export const load =
    (
        organisationUUID: string,
        page: number,
        filter: string,
        sorting: string[],
        force: boolean = false,
    ) =>
    (dispatch: *, getState: *, client: Client): Promise<*> => {
        const state = getState().organisationlines;

        if (!force && state.get("loading")) {
            return Promise.resolve();
        }

        if (
            !force &&
            state.get("organisationUUID") === organisationUUID &&
            state.get("page") === page &&
            state.get("sorting") === sorting &&
            state.get("filter") === filter
        ) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: {
                organisationUUID,
                page,
                filter,
                sorting,
            },
        });

        return client
            .getPrefferedLines(organisationUUID, page, filter, sorting)
            .then(payload =>
                dispatch({
                    type: SUCCESS,
                    payload,
                }),
            )
            .catch(error =>
                dispatch({
                    type: FAILURE,
                    payload:
                        (error && error.message) || ERROR_ORGANISATIONLINES,
                }),
            );
    };

/**
 * ActionCreator: add
 */
export const add =
    (lines: string[]) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().organisationlines;

        if (!lines.length || state.get("loading")) {
            return Promise.resolve();
        }

        dispatch({
            type: ADD_PENDING,
        });

        return client
            .addPreferredLines(state.get("organisationUUID"), lines)
            .then(() =>
                dispatch(
                    load(
                        state.get("organisationUUID"),
                        state.get("page"),
                        state.get("filter"),
                        state.get("sorting"),
                        true,
                    ),
                ),
            )
            .catch(error =>
                dispatch({
                    type: ADD_FAILURE,
                    payload: (error && error.message) || ERROR_ADDLINES,
                }),
            );
    };

/**
 * Actioncreator: delete line
 */
export const deleteLine =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<boolean> => {
        const state = getState().organisationlines;

        if (state.get("loading")) {
            return Promise.resolve(false);
        }

        if (!state.get("data")) {
            return Promise.resolve(false);
        }

        // Remove from list and set to loading
        dispatch({
            type: DELETE_PENDING,
            payload: uuid,
        });

        return client
            .deletePrefferedLine(state.get("organisationUUID"), uuid)
            .then(() => {
                return dispatch(
                    load(
                        state.get("organisationUUID"),
                        state.get("page"),
                        state.get("filter"),
                        state.get("sorting"),
                        true,
                    ),
                )
                    .then(() => true)
                    .catch(() => false);
            })
            .catch(error => {
                dispatch({
                    type: DELETE_FAILED,
                    payload: {
                        error: (error && error.message) || ERROR_DELETELINE,
                        uuid,
                    },
                });

                return false;
            });
    };
