// @flow

import styles from "./Wrapper.scss";
import React, { type Node } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

type Props = {
    /** Id */
    id: string,
    /** Disabled flag */
    disabled: boolean,
    /** A translator function */
    translate: *,
    /** Close / Cancel handler */
    onClose: () => void,
    /** Tab tuples */
    tabs: [string, string][],
    /** Selected tab */
    active: string,
    /** On tab change */
    onTab: (key: string) => void,
    /** Children */
    children: Node,
    /** show add button */
    onAdd?: () => void,
    /** show bulk add button */
    onBulkAdd?: () => void,
    onPoolAdd?: () => void,
    isAddLoading: boolean,
    isAddPoolLoading: Boolean,
    isAddBulkLoading: boolean,
};

/**
 * Dialog Wrapper Component
 */
const Wrapper = ({
    id,
    disabled,
    translate,
    onClose,
    tabs,
    active,
    onTab,
    children,
    onAdd,
    onBulkAdd,
    onPoolAdd,
    isAddLoading,
    isAddPoolLoading,
    isAddBulkLoading,
}: Props) => (
    <Dialog onClose={onClose} open maxWidth={false}>
        <DialogTitle
            disableTypography
            className={styles.title}
            style={{ paddingBottom: "0" }}
        >
            <Typography variant="h2" color="inherit">
                {translate("Assign team member")}
            </Typography>
            <Typography color="inherit" style={{ opacity: "0.75" }}>
                {translate("Choose the user you wish to assign to this team:")}
            </Typography>
            <Tabs
                value={tabs.findIndex(tab => tab[0] === active)}
                onChange={(_, index) => onTab(tabs[index][0])}
                TabIndicatorProps={{
                    className: styles.indicator,
                }}
            >
                {tabs.map(tab => (
                    <Tab key={tab[0]} label={tab[1]} id={`${id}-${tab[1]}`} />
                ))}
            </Tabs>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <Button id={`${id}-cancel`} onClick={onClose} disabled={disabled}>
                {translate("Cancel")}
            </Button>
            {onAdd && (
                <Button
                    id={`${id}-add`}
                    color="primary"
                    onClick={onAdd}
                    disabled={disabled || isAddLoading}
                >
                    {translate("Add")}
                </Button>
            )}
            {onPoolAdd && (
                <Button
                    id={`${id}-add`}
                    color="primary"
                    onClick={onPoolAdd}
                    disabled={disabled || isAddPoolLoading}
                >
                    {translate("Add")}
                </Button>
            )}
            {onBulkAdd && (
                <Button
                    id={`${id}-bulk-add`}
                    color="primary"
                    onClick={onBulkAdd}
                    disabled={disabled || isAddBulkLoading}
                >
                    {translate("Add Found")}
                </Button>
            )}
        </DialogActions>
    </Dialog>
);

export default Wrapper;
