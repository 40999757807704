// @flow

import React, { Component, Fragment } from "react";

import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import moment from "moment-timezone";

import { type Issue } from "../../../redux/modules/event-issues.d";

type Props = {
    issue: Issue,
    onClose: () => void,
    translate: *,
};

export default class AddMember extends Component<Props> {
    /**
     * Render
     */
    render() {
        const { onClose, issue, translate } = this.props;
        return (
            <Dialog onClose={onClose} open>
                <DialogTitle disableTypography>
                    <Typography variant="h4">{issue.showLineName}</Typography>
                    <Typography variant="h5" id="issue-description">
                        {issue.description}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Fragment>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    id="issue-subheading"
                                >
                                    {translate(
                                        "Issue logged by %{firstName} %{lastName} on %{creationTime}",
                                        {
                                            firstName: issue.firstName,
                                            lastName: issue.lastName,
                                            creationTime: moment(
                                                issue.creationTime,
                                            ).format("ddd D MMM YYYY, HH:mm"),
                                        },
                                    )}
                                </Typography>
                            </Grid>
                            {issue.issuePictures.map(picture => (
                                <Fragment key={picture.uuid}>
                                    <Divider />
                                    <Grid item xs={12}>
                                        <img
                                            src={`${picture.path}/280x280.png`}
                                            alt={picture.uuid}
                                        />
                                    </Grid>
                                </Fragment>
                            ))}
                        </Fragment>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}
