// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cI3sKrdwfD0j\\+YuQVd32ww\\=\\={margin:1em}.BGg-DDqr99wGXVebdME-Bg\\=\\={overflow:hidden;height:calc(100vh - 15.5em);overflow-y:scroll}\n", "",{"version":3,"sources":["webpack://./../index.scss"],"names":[],"mappings":"AAEA,6BACI,UAAW,CACd,4BAGG,eAAgB,CAChB,2BAA4B,CAC5B,iBAAkB","sourcesContent":["// Line staffing details\n\n.wrapper {\n    margin: 1em;\n}\n\n.inner {\n    overflow: hidden;\n    height: calc(100vh - 15.5em);\n    overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "cI3sKrdwfD0j+YuQVd32ww==",
	"inner": "BGg-DDqr99wGXVebdME-Bg=="
};
export default ___CSS_LOADER_EXPORT___;
