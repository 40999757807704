// @flow

import React, { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";

import SelectTemplates, {
    type SelectedTemplatesType,
} from "./SelectTemplates/SelectTemplates";
import SelectEvents from "./SelectEvents/SelectEvents";
import BusinessRules from "./BusinessRules/BusinessRules";
import Summary from "./Summary/Summary";
import type { VenueStaffingTemplate } from "../../../redux/modules/venue-staffing-templates.d";
import type { ApplyStaffingTemplatesData } from "../../../redux/modules/apply-staffing-templates.d";
import type { SeriesEvent } from "../../../redux/modules/series-events.d";

type Props = {
    translate: *,
    onClose: () => void,
    onInit: (lineTypeUuids: string[]) => void,
    lineTypes: {
        name: string,
        uuid: string,
    }[],
    staffingTemplates: VenueStaffingTemplate[],
    onApply: (data: ApplyStaffingTemplatesData) => void,
    onApplySeries: () => void,
    busy: boolean,
    events: SeriesEvent[],
    currentEventDisplayName: string,
};

const ApplyTemplates = ({
    translate,
    onClose,
    onInit,
    lineTypes,
    staffingTemplates,
    onApply,
    onApplySeries,
    busy,
    events,
    currentEventDisplayName,
}: Props) => {
    const [step, setStep] = useState<"select" | "series" | "rules" | "summary">(
        "select",
    );
    const [data, setData] = useState<
        ApplyStaffingTemplatesData & {
            selectedTemplates: SelectedTemplatesType,
            eventNames: string[],
        },
    >({
        applyForSeries: false,
        eventUuids: [],
        eventNames: [],
        keepExistingTimingsAndOccupation: false,
        keepExistingStaffing: false,
        keepExistingBriefings: false,
        staffingTemplates: [],
        selectedTemplates: {},
    });

    useEffect(() => {
        onInit(lineTypes.map(el => el.uuid));
    }, []);

    return (
        <Dialog onClose={() => !busy && onClose()} open>
            {step === "select" && (
                <SelectTemplates
                    translate={translate}
                    onNext={selected => {
                        setData({
                            ...data,
                            eventUuids: [],
                            eventNames: [],
                            selectedTemplates: selected,
                            staffingTemplates: Object.values(selected).map(
                                v => v.uuid,
                            ),
                        });
                        setStep("rules");
                    }}
                    onApplySeries={selected => {
                        onApplySeries();
                        setData({
                            ...data,
                            selectedTemplates: selected,
                            staffingTemplates: Object.values(selected).map(
                                v => v.uuid,
                            ),
                        });
                        setStep("series");
                    }}
                    onClose={onClose}
                    lineTypes={lineTypes}
                    selected={data.selectedTemplates}
                    staffingTemplates={staffingTemplates}
                />
            )}
            {step === "series" && (
                <SelectEvents
                    translate={translate}
                    onNext={eventUuids => {
                        setData({
                            ...data,
                            applyForSeries: true,
                            eventNames: eventUuids.map(
                                uuid =>
                                    events.find(e => e.uuid === uuid)
                                        .fullDisplayName,
                            ),
                            eventUuids,
                        });
                        setStep("rules");
                    }}
                    onBack={eventUuids => {
                        setData({
                            ...data,
                            applyForSeries: false,
                            eventNames: eventUuids.map(
                                uuid =>
                                    events.find(e => e.uuid === uuid)
                                        .fullDisplayName,
                            ),
                            eventUuids,
                        });
                        setStep("select");
                    }}
                    onClose={onClose}
                    events={events}
                    selected={data.eventUuids}
                />
            )}
            {step === "rules" && (
                <BusinessRules
                    translate={translate}
                    onNext={rules => {
                        setData({ ...data, ...rules });
                        setStep("summary");
                    }}
                    onBack={rules => {
                        setData({ ...data, ...rules });
                        setStep(data.applyForSeries ? "series" : "select");
                    }}
                    rules={{
                        keepExistingTimingsAndOccupation:
                            data.keepExistingTimingsAndOccupation,
                        keepExistingStaffing: data.keepExistingStaffing,
                        keepExistingBriefings: data.keepExistingBriefings,
                    }}
                    onClose={onClose}
                    applyForSeries={data.applyForSeries}
                />
            )}
            {step === "summary" && (
                <Summary
                    translate={translate}
                    currentEventDisplayName={currentEventDisplayName}
                    data={data}
                    onBack={() => setStep("rules")}
                    onApply={() => {
                        const dataCopy = { ...data };
                        delete dataCopy.selectedTemplates;
                        delete dataCopy.eventNames;
                        onApply(dataCopy);
                    }}
                    onClose={onClose}
                    busy={busy}
                />
            )}
        </Dialog>
    );
};

export default ApplyTemplates;
