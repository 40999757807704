// @flow

import styles from "./EditMember.scss";

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { type State as MemberShipState } from "../../../redux/modules/member.d";

import PhoneNumberField from "../../PhoneNumberField/PhoneNumberField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

type Props = {
    disabled: boolean,
    translate: *,
    member: MemberShipState,
    onSubmit: (
        description: string,
        noSmartphone: boolean,
        userFirstName: string,
        userLastName: string,
    ) => *,
    onClose: () => void,
};

type State = {
    description: string,
    noSmartphone: boolean,
    userFirstName: string,
    userLastName: string,
};

const STATUSES = {
    MEMBER: "Member",
    LEADER: "Leader",
};

export default class EditMember extends Component<Props, State> {
    // Default props
    static defaultProps = {
        disabled: false,
    };

    // Initial state
    state = {
        description: "",
        noSmartphone: false,
        userFirstName: "",
        userLastName: "",
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                noSmartphone:
                    (this.props.member.data &&
                        this.props.member.data.noSmartphone) ||
                    false,
                description:
                    (this.props.member.data &&
                        this.props.member.data.description) ||
                    "",
                userFirstName:
                    (this.props.member.data &&
                        this.props.member.data.userFirstName) ||
                    "",
                userLastName:
                    (this.props.member.data &&
                        this.props.member.data.userLastName) ||
                    "",
            });
        }, 2000);
    }

    /**
     * Name getter
     */
    get name(): string {
        const { member, translate } = this.props;

        if (!member.data) {
            return "...";
        }

        if (member.data.userFirstName || member.data.userLastName) {
            return `${member.data.userFirstName || ""} ${
                member.data.userLastName || ""
            }`.trim();
        }

        return translate("(Unknown)");
    }

    /**
     * Get if form is disabled
     */
    get disabled(): boolean {
        const { disabled, member } = this.props;
        return disabled || member.loading;
    }

    /**
     * Get the current role
     */
    get role(): string {
        const { member } = this.props;

        if (member.data && member.data.membershipRole) {
            return member.data.membershipRole;
        }

        return "";
    }

    get inviteStatus(): string {
        const { member } = this.props;

        return member.data?.membershipInviteStatus || "";
    }

    /**
     * Get the email address
     */
    get email(): ?string {
        const { member } = this.props;

        if (member.data && member.data.emailAddress) {
            return member.data.emailAddress;
        }

        return null;
    }

    /**
     * Get fieldErrors for field
     */
    getErrors(field: string): ?string {
        const {
            member: { fieldErrors },
        } = this.props;

        if (fieldErrors && (fieldErrors: any)[field]) {
            return ((fieldErrors: any)[field]: any);
        }
    }

    /**
     * Render
     */
    render() {
        const { description, noSmartphone, userFirstName, userLastName } =
            this.state;
        const { onSubmit, onClose, member, translate } = this.props;

        return (
            <Dialog open={true} onClose={onClose}>
                <form
                    onSubmit={event => {
                        event.preventDefault();
                        onSubmit(
                            description,
                            noSmartphone,
                            userFirstName,
                            userLastName,
                        );
                    }}
                >
                    <DialogTitle disableTypography>
                        <div className={styles.header}>
                            <Typography variant="h2">{this.name}</Typography>
                            {member.data && (
                                <span>
                                    <img
                                        alt={`${this.name}`}
                                        src={`${member.data.profilePicturePath}/256x256.png`}
                                    />
                                </span>
                            )}
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={3}>
                            {member.error && (
                                <Grid item xs={12}>
                                    <Typography color="error">
                                        {member.error}
                                    </Typography>
                                </Grid>
                            )}
                            {member.data && (
                                <>
                                    <Grid item sm={8}>
                                        <PhoneNumberField
                                            id="modal-editmember-phonenumber"
                                            label={translate("Phone Number")}
                                            value={
                                                (member.data: any).phoneNumber
                                            }
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <TextField
                                            id="modal-editmember-status-input"
                                            label={translate("Status")}
                                            value={translate(
                                                STATUSES[this.role],
                                            )}
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>
                                    {this.email && (
                                        <Grid item xs={12}>
                                            <TextField
                                                id="modal-editmember-email"
                                                label={translate("E-Mail")}
                                                value={this.email || ""}
                                                disabled={true}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <TextField
                                            id="modal-editmember-desc"
                                            label={translate("Description")}
                                            onChange={value =>
                                                this.setState({
                                                    description:
                                                        value.target.value,
                                                })
                                            }
                                            value={description}
                                            error={
                                                !!this.getErrors("description")
                                            }
                                            helperText={this.getErrors(
                                                "description",
                                            )}
                                            disabled={this.disabled}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={noSmartphone}
                                                    id="modal-editmember-nosmartphone"
                                                    onChange={(
                                                        event,
                                                        checked,
                                                    ) =>
                                                        this.setState({
                                                            noSmartphone:
                                                                checked,
                                                        })
                                                    }
                                                    disabled={
                                                        this.disabled ||
                                                        (this.inviteStatus !==
                                                            "INVITED" &&
                                                            this.role !==
                                                                "MEMBER")
                                                    }
                                                    value={noSmartphone.toString()}
                                                />
                                            }
                                            label={translate("No smartphone")}
                                        />
                                    </Grid>
                                    {noSmartphone &&
                                        this.inviteStatus === "INVITED" &&
                                        this.role === "MEMBER" && (
                                            <>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="modal-addmember-firstname"
                                                        label={translate(
                                                            "First name",
                                                        )}
                                                        onChange={value =>
                                                            this.setState({
                                                                userFirstName:
                                                                    value.target
                                                                        .value,
                                                            })
                                                        }
                                                        value={userFirstName}
                                                        error={
                                                            !!this.getErrors(
                                                                "userFirstName",
                                                            )
                                                        }
                                                        helperText={this.getErrors(
                                                            "userFirstName",
                                                        )}
                                                        disabled={this.disabled}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="modal-addmember-lastname"
                                                        label={translate(
                                                            "Last name",
                                                        )}
                                                        onChange={value =>
                                                            this.setState({
                                                                userLastName:
                                                                    value.target
                                                                        .value,
                                                            })
                                                        }
                                                        value={userLastName}
                                                        error={
                                                            !!this.getErrors(
                                                                "userLastName",
                                                            )
                                                        }
                                                        helperText={this.getErrors(
                                                            "userLastName",
                                                        )}
                                                        disabled={this.disabled}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                </>
                            )}
                            {member.loading && (
                                <Grid item xs={12} className={styles.loading}>
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            id="modal-editmember-cancel"
                            onClick={onClose}
                            disabled={member.loading}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="modal-editmember-confirm"
                            type="submit"
                            color="primary"
                            disabled={this.disabled}
                        >
                            {translate("Save")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
