// @flow

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    //    Redirect,
    //    Switch,
    //    Route,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";

import { type State as showIssuesState } from "../../../../redux/modules/event-issues.d";

import {
    load,
    clear,
    ERROR as SHOW_ISSUES_ERROR,
} from "../../../../redux/modules/event-issues";

import debounce from "lodash.debounce";

//import stripslash from "../../../../utils/stripslash";
import FilterField from "../../../../components/Authenticated/FilterField";

import { withTranslate } from "../../../Translate";
import Issues from "../../../../components/Events/Issues";

import { withAcl, type Acl } from "../../../Acl";
import { mapState } from "../../../../redux/utils";

type Props = {
    acl: Acl,
    translate: *,
    match: Match,
    eventIssues: showIssuesState,
    clear: () => void,
    load: (page: number, uuid: string, filter: string, force: boolean) => void,
    history: RouterHistory,
    onPageChange: (page: number) => void,
};

type StateType = {
    filter: string,
};

const DEBOUNCE_WAIT = 250;
const DEBOUNCE_MAX_WAIT = 500;

class List extends Component<Props, StateType> {
    // Initial state
    state = {
        filter: "",
    };

    /**
     * Initial load
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
        this.reload();
    }

    /**
     * Trigger load on update
     */
    componentDidUpdate() {
        this.reload();
    }

    /**
     *
     */
    get pageParamIsNumber() {
        const { match } = this.props;
        const parsed = parseInt(match.params.page);
        return !isNaN(parsed) && parsed > 0;
    }

    /**
     * Trigger update
     */
    reload = debounce(
        (force: boolean = false) => {
            const { load, match } = this.props;
            const { filter } = this.state;
            if (this.pageParamIsNumber) {
                load(
                    parseInt(match.params.page),
                    match.params.id ? match.params.id : "",
                    filter,
                    force,
                );
            }
        },
        DEBOUNCE_WAIT,
        {
            leading: true,
            maxWait: DEBOUNCE_MAX_WAIT,
            trailing: true,
        },
    );
    /**
     * Render
     */
    render() {
        const { acl, translate, onPageChange, eventIssues } = this.props;
        const { filter } = this.state;
        if (!this.pageParamIsNumber) {
            return <h1>404: Not Found</h1>;
        }

        return (
            <div>
                <span style={{ marginTop: "1em", display: "block" }} />
                <FilterField
                    onChange={filter => this.setState({ filter })}
                    placeholder={translate("Filter by name or venues")}
                    id="issues-list-filter"
                    value={filter}
                />
                <Issues
                    acl={acl}
                    translate={translate}
                    loading={eventIssues.loading}
                    page={eventIssues.page}
                    data={eventIssues.data}
                    error={eventIssues.error}
                    onPageChange={onPageChange}
                />
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                // Map state to props
                ({ eventIssues }) => ({
                    eventIssues: {
                        ...mapState(eventIssues, SHOW_ISSUES_ERROR),
                        page:
                            eventIssues.params &&
                            eventIssues.params.path &&
                            eventIssues.params.path.page,
                    },
                }),
                // Map dispatch to props
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            load: (page, uuid, filter, force) =>
                                load(page, uuid, filter, force),
                            clear,
                        },
                        dispatch,
                    ),
            )(List),
        ),
    ),
);
