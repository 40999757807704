// @flow

import styles from "./LineSelector.scss";

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SearchIcon from "@material-ui/icons/Search";

import { type State as LinesState } from "../../redux/modules/lines.d";

import List from "./List";

type PropsType = {
    /** Busy flag */
    busy?: ?boolean,
    /** A translator function */
    translate: *,
    /** A list of uuid that need to be checked */
    selected: string[],
    /** When a submit action is done */
    onSubmit: (lines: string[]) => void,
    /** The list database */
    lines: LinesState,
    /** Close / Cancel handler */
    onClose: () => void,
    /** Load callback */
    load: () => void,
    /** Excluded lines */
    excluded?: ?(string[]),
    isLoading: boolean,
};

type StateType = {
    selected: string[],
    filter: string,
};

export default class LineSelector extends Component<PropsType, StateType> {
    static defaultProps = {
        selected: [],
    };

    state = {
        selected: [],
        filter: "",
    };

    /**
     * Update state on props update
     */
    static getDerivedStateFromProps(
        { selected }: PropsType,
        state: StateType,
    ): StateType {
        return {
            ...state,
            selected: selected.concat(state.selected),
        };
    }

    /**
     * Constructor
     */
    constructor(props: PropsType) {
        super(props);

        // Re-load list
        props.load();
    }

    /**
     * Add or edit mode
     */
    get inAddMode() {
        const { selected } = this.props;

        return !selected.length;
    }

    /**
     * Get filtered lines
     */
    get lines(): LinesState {
        const { lines, excluded } = this.props;
        const { filter } = this.state;

        if (!lines.data) {
            return lines;
        }

        if ((!filter || !filter.length) && !excluded) {
            return lines;
        }

        const lowerFilter = filter.toLowerCase();

        return {
            ...lines,
            data: (lines.data: any)
                .map(group => {
                    const lowerGroup = group.name.toLowerCase();

                    return {
                        ...group,
                        lines: group.lines.filter(line => {
                            if (excluded && excluded.includes(line.uuid)) {
                                return false;
                            }

                            if (lowerGroup.includes(lowerFilter)) {
                                return true;
                            }

                            if (line.name.toLowerCase().includes(lowerFilter)) {
                                return true;
                            }

                            return line.venues.reduce(
                                (accumulator, current) =>
                                    accumulator ||
                                    current.name
                                        .toLowerCase()
                                        .includes(lowerFilter),
                                false,
                            );
                        }),
                    };
                })
                .filter(group => group.lines.length),
        };
    }

    /**
     * Render component
     */
    render() {
        const { onSubmit, onClose, translate, busy, isLoading } = this.props;
        const { selected, filter } = this.state;
        const error = this.lines.error;

        return (
            <Dialog open={true} onClose={onClose}>
                <form
                    onSubmit={event => {
                        event.preventDefault();
                        onSubmit(selected);
                    }}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h2">
                            {this.inAddMode
                                ? translate("Add lines")
                                : translate("Edit lines")}
                        </Typography>
                        <Typography>
                            {translate(
                                "Choose one or more preferred lines to add " +
                                    "to this organisation:",
                            )}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            id="lineselector-search"
                            disabled={!!busy}
                            value={filter}
                            onChange={({ target: { value } }) =>
                                this.setState({
                                    filter: value,
                                })
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={translate(
                                "Filter by name, type or venue",
                            )}
                            autoFocus
                            fullWidth
                        />
                        {error && (
                            <Typography color="error">{error}</Typography>
                        )}
                        <div className={styles.scroll}>
                            <List
                                busy={busy}
                                lines={this.lines}
                                selected={selected}
                                onChange={selected =>
                                    this.setState({
                                        selected,
                                    })
                                }
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            id="lineselector-cancel"
                            onClick={onClose}
                            disabled={!!busy}
                            className={styles.cancel}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="lineselector-confirm"
                            disabled={busy || isLoading}
                            type="submit"
                            color="primary"
                        >
                            {translate("Add")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
