// @flow

import styles from "./Pool.scss";

import moment from "moment-timezone";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { type Pool } from "../../../redux/modules/event-recruitment.d";

type Props = {
    pool: Pool,
};

/**
 * title
 */
const PoolTitle = ({ pool }: Props) => (
    <div className={styles.title}>
        <Grid container spacing={3} alignItems="center">
            <Grid item sm={8}>
                <Typography variant="h4">{pool.name}</Typography>
                <Typography variant="caption">
                    {pool.organisationName}
                </Typography>
            </Grid>
            <Grid item sm={4}>
                <Typography variant="body2" align="right">
                    {moment(pool.start).format("HH:mm")} -{" "}
                    {moment(pool.end).format("HH:mm")}
                </Typography>
            </Grid>
        </Grid>
    </div>
);

export default PoolTitle;
