// @flow

/* eslint react/display-name: 0 */

import React, { type Node } from "react";
import { connect } from "react-redux";
import Polyglot from "node-polyglot";

import en from "../../locales/en.po";
import nl from "../../locales/nl.po";
import fr from "../../locales/fr.po";

const DICTIONARIES = {
    en,
    nl,
    fr,
};

const polyglot = new Polyglot({ phrases: {} });

const Context = React.createContext<any>("en");

const getDictionary = language =>
    language in DICTIONARIES ? DICTIONARIES[language] : DICTIONARIES["en"];

const getTranslator = (language: string) => {
    polyglot.replace(getDictionary(language));
    polyglot.locale(language);
    return polyglot.t.bind(polyglot);
};

/**
 * Language provider component
 */
const TranslationProvider = ({
    translate,
    children,
}: {
    translate: any,
    children: Node,
}) => <Context.Provider value={translate}>{children}</Context.Provider>;

export const Provider = connect(({ language }) => ({
    translate: getTranslator(language),
}))(TranslationProvider);

/**
 * Language consumer
 */
export const withTranslate = (Component: *) => (props: *) =>
    (
        <Context.Consumer>
            {translate => <Component {...props} translate={translate} />}
        </Context.Consumer>
    );
