//@flow
import React, { Component } from "react";

import Day from "./Day";

import MOMENT from "moment";
import { extendMoment } from "moment-range";

import { type State as VenueState } from "../../../../../redux/modules/venues.d";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    translate: *,
    DATE: *,
    venues: VenueState,
    calendar: *,
    collapsedVenues: string[],
    activeEvent: ?{
        event: string,
        series: string,
    },
    clearActiveEvent: () => void,
    activateEvent: (eventUuid: string, seriesUuid: string) => void,
    toggleActiveEvent: (eventUuid: string, seriesUuid: string) => void,
    onConfirmOptions: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: string) => void,
    isShowCanceled: boolean,
    acl: *,
    user: Profile,
};

const moment = extendMoment(MOMENT);

export default class Month extends Component<Props> {
    calendarDataByDay(day: string) {
        const { calendar } = this.props;
        const calendarDay =
            calendar &&
            calendar.filter(calendarDay => calendarDay.date === day)[0];

        return calendarDay ? calendarDay : null;
    }

    /**
     * Render
     */
    render() {
        const {
            DATE,
            translate,
            venues,
            collapsedVenues,
            activateEvent,
            activeEvent,
            clearActiveEvent,
            toggleActiveEvent,
            onConfirmOptions,
            onEditOption,
            onCancelOption,
            onEditSeries,
            onEditEvent,
            onMoveEvent,
            onEditConfirmedOption,
            onDefineEvents,
            onCancelEvent,
            onCancelConfirmedOption,
            isShowCanceled,
            onAddOptionToSeries,
            onAddEventToSeries,
            onShowHistory,
            onDoubleClickEvent,
            acl,
            user,
        } = this.props;

        return (
            <div>
                {Array.from(
                    moment
                        .range(
                            moment(DATE).startOf("month").format("YYYY/MM/DD"),
                            moment(DATE).endOf("month").format("YYYY/MM/DD"),
                        )
                        .by("day"),
                ).map(day => (
                    <Day
                        acl={acl}
                        user={user}
                        translate={translate}
                        onCancelEvent={onCancelEvent}
                        isShowCanceled={isShowCanceled}
                        onCancelConfirmedOption={onCancelConfirmedOption}
                        key={moment(day).format("YYYY-MM-DD")}
                        day={day}
                        activeEvent={activeEvent}
                        activateEvent={activateEvent}
                        clearActiveEvent={clearActiveEvent}
                        toggleActiveEvent={toggleActiveEvent}
                        venues={venues}
                        collapsedVenues={collapsedVenues}
                        onConfirmOptions={onConfirmOptions}
                        onEditOption={onEditOption}
                        onCancelOption={onCancelOption}
                        onEditSeries={onEditSeries}
                        onEditEvent={onEditEvent}
                        onMoveEvent={onMoveEvent}
                        onEditConfirmedOption={onEditConfirmedOption}
                        onAddOptionToSeries={onAddOptionToSeries}
                        onAddEventToSeries={onAddEventToSeries}
                        onDefineEvents={onDefineEvents}
                        onShowHistory={onShowHistory}
                        onDoubleClickEvent={onDoubleClickEvent}
                        calendarDay={this.calendarDataByDay(
                            moment(day).format("YYYY-MM-DD"),
                        )}
                    />
                ))}
            </div>
        );
    }
}
