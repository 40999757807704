// @flow

import styles from "./Add.scss";

import React, { Component } from "react";

// import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconMenu from "../../IconMenu";

import DeleteIcon from "@material-ui/icons/Delete";

import { type FieldErrors } from "../../../utils/FormError";

import {
    type State as LinesState,
    type Line as LineType,
} from "../../../redux/modules/lines.d";
import { type NewOrganisation } from "../../../redux/modules/organisation-add.d";

import LineSelector from "../../LineSelector";

import Persons from "./Persons";

type Props = {
    translate: *,
    busy?: boolean,
    errors: ?FieldErrors,
    onSubmit: (values: NewOrganisation) => void,
    onClose: () => void,
    lines: LinesState,
    loadLines: () => void,
};

type State = {
    name: string,
    maxLeaders: number,
    lineUuids: string[],
    organisationLeaderPhoneNumber: string[],
    selectLines: boolean,
};

type LineProps = {
    id: string,
    uuid: string,
    lines: LineType[],
    onDelete: () => void,
    disabled?: ?boolean,
};

const PHONENUMBER_ERROR_TEST = /^organisationLeaderPhoneNumber\[(\d)\]$/;

/**
 * Line
 */
const Line = ({ id, uuid, lines, onDelete, disabled }: LineProps) => {
    const line = lines.find(line => line.uuid === uuid);

    return (
        <ListItem key={uuid} id={id}>
            <ListItemText primary={(line && line.name) || uuid} />
            <ListItemSecondaryAction>
                <IconMenu
                    id={`delete-${id}`}
                    options={[
                        {
                            id: `delete-line`,
                            label: "Delete",
                            onClick: onDelete,
                        },
                    ]}
                    aria-label="Delete"
                    disabled={!!disabled}
                >
                    <DeleteIcon />
                </IconMenu>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

/**
 * Add Organisation modal
 */
export default class Add extends Component<Props, State> {
    state = {
        name: "",
        maxLeaders: 1,
        lineUuids: [],
        organisationLeaderPhoneNumber: [""],
        selectLines: false,
    };

    /**
     * Handle submit
     */
    handleSubmit(event: SyntheticEvent<*>) {
        event.preventDefault();

        const { busy, onSubmit } = this.props;
        const { name, maxLeaders, lineUuids, organisationLeaderPhoneNumber } =
            this.state;
        const numbers = organisationLeaderPhoneNumber
            .filter(number => number.length)
            .map(number => number.replace(/\s/g, ""));

        if (!busy) {
            onSubmit({
                name,
                maxLeaders,
                lineUuids,
                organisationLeaderPhoneNumber: numbers,
            });
        }
    }

    /**
     * Handle change
     * it changes the leaders value
     * it generates phone number fields
     */
    handleChange = (value: *) => {
        this.setState({
            maxLeaders: parseInt(value) || 1,
            organisationLeaderPhoneNumber: Array(parseInt(value)).fill(""),
        });
    };

    /**
     * Render
     */
    render() {
        const {
            name,
            maxLeaders,
            lineUuids,
            organisationLeaderPhoneNumber,
            selectLines,
        } = this.state;

        const { translate, busy, errors, onClose, lines, loadLines } =
            this.props;

        const flattenedLines: LineType[] =
            (lines.data &&
                lines.data.reduce(
                    (accumulator, current) => accumulator.concat(current.lines),
                    [],
                )) ||
            [];

        if (selectLines) {
            return (
                <LineSelector
                    translate={translate}
                    selected={lineUuids}
                    lines={lines}
                    load={loadLines}
                    excluded={lineUuids}
                    onSubmit={lineUuids =>
                        this.setState({
                            lineUuids,
                            selectLines: false,
                        })
                    }
                    onClose={() =>
                        this.setState({
                            selectLines: false,
                        })
                    }
                />
            );
        }

        return (
            <Dialog open={true} onClose={onClose}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {/* Header */}
                    <DialogTitle disableTypography className={styles.title}>
                        <Typography variant="h2" color="inherit">
                            {translate("Add Organisation")}
                        </Typography>
                        <Typography color="inherit" style={{ opacity: "0.75" }}>
                            {translate(
                                "Add a new organisation to @YourService:",
                            )}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={styles.content}>
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <TextField
                                    id="modal-addorganisation-name"
                                    label={translate("Organisation name")}
                                    value={name}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            name: value,
                                        })
                                    }
                                    error={!!errors && !!errors.name}
                                    disabled={busy}
                                    helperText={errors && errors.name}
                                    fullWidth
                                    className={styles.whiteField}
                                    InputLabelProps={{
                                        style: { color: "white" },
                                    }}
                                    InputProps={{
                                        classes: {
                                            underline: styles.underline,
                                            disabled: styles.disabled,
                                            focused: styles.focused,
                                            error: styles.error,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    id="modal-addorganisation-amount"
                                    label={translate(
                                        "Max. organisational leaders",
                                    )}
                                    value={maxLeaders}
                                    onChange={({ target: { value } }) =>
                                        this.handleChange(value)
                                    }
                                    error={!!errors && !!errors.maxLeaders}
                                    type="number"
                                    disabled={busy}
                                    helperText={errors && errors.maxLeaders}
                                    fullWidth
                                    className={styles.whiteField}
                                    InputLabelProps={{
                                        style: { color: "white" },
                                    }}
                                    InputProps={{
                                        classes: {
                                            underline: styles.underline,
                                            disabled: styles.disabled,
                                            focused: styles.focused,
                                            error: styles.error,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogContent>
                        {/* Leaders */}
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginBottom: "-2em",
                                    marginTop: "1em",
                                }}
                            >
                                <DialogContentText>
                                    {translate("Organisational leaders")}
                                </DialogContentText>
                            </Grid>

                            <Grid item xs>
                                <Persons
                                    placeholder={translate("Phonenumber")}
                                    disabled={busy}
                                    value={organisationLeaderPhoneNumber}
                                    max={maxLeaders}
                                    onChange={organisationLeaderPhoneNumber =>
                                        this.setState({
                                            organisationLeaderPhoneNumber,
                                        })
                                    }
                                    errors={
                                        (errors &&
                                            Object.keys(errors).reduce(
                                                (accumulator, current) => {
                                                    const matched =
                                                        PHONENUMBER_ERROR_TEST.exec(
                                                            current,
                                                        );
                                                    if (matched && matched[1]) {
                                                        accumulator[
                                                            matched[1]
                                                        ] =
                                                            (errors &&
                                                                errors[
                                                                    current
                                                                ]) ||
                                                            undefined;
                                                    }
                                                    return accumulator;
                                                },
                                                [],
                                            )) ||
                                        []
                                    }
                                />
                            </Grid>
                            {/* Lines */}
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                xs={12}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: ".3em" }}
                                >
                                    <DialogContentText>
                                        {translate("Preferred lines")}
                                    </DialogContentText>
                                </Grid>
                                <Grid item xs={12}>
                                    {errors && errors.lineUuids && (
                                        <Typography
                                            color="error"
                                            id={
                                                "line-item-modal-addorganisation-error"
                                            }
                                        >
                                            {errors.lineUuids}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} className={styles.lines}>
                                    {lineUuids.length ? (
                                        <List>
                                            {Array.from(new Set(lineUuids)).map(
                                                (line, index) => (
                                                    <Line
                                                        id={`line-item-modal-addorganisation-${index}`}
                                                        disabled={busy}
                                                        key={line}
                                                        uuid={line}
                                                        lines={flattenedLines}
                                                        onDelete={() =>
                                                            this.setState({
                                                                lineUuids:
                                                                    lineUuids.filter(
                                                                        uuid =>
                                                                            uuid !==
                                                                            line,
                                                                    ),
                                                            })
                                                        }
                                                    />
                                                ),
                                            )}
                                        </List>
                                    ) : (
                                        <ListItem>
                                            <ListItemText>
                                                {translate("Select Line(s)")}
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                </Grid>

                                <Grid item xs={12} className={styles.action}>
                                    <Typography
                                        id="modal-addorganisation-add-lines"
                                        onClick={() =>
                                            this.setState({
                                                selectLines: true,
                                            })
                                        }
                                    >
                                        {translate(
                                            "Add one or more preferred lines",
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    {/* Actions */}
                    <DialogActions>
                        <Button
                            id="modal-addorganisation-cancel"
                            onClick={onClose}
                            disabled={busy}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="modal-addorganisation-confirm"
                            type="submit"
                            color="primary"
                            disabled={busy}
                            className={styles.action}
                        >
                            {translate("Add Organisation")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
