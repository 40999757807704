// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "addStaffingTemplateLines",
    ({ uuid }) =>
        `/ays-venue-facility-management/staffing-templates/${uuid}/lines`,
    { method: "POST" },
);

export const ERROR = "Couldn't add staffing template lines";

export const load = (uuid: string, lineUuids: string[]) => (dispatch: *) =>
    dispatch(actions.load({ path: { uuid }, data: { lineUuids } }));

export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
