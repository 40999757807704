// @flow
import styles from "./History.scss";
import React, { useEffect } from "react";
import Dialog from "../../../../Dialog/Dialog";
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
} from "@material-ui/core";
import moment from "moment";

import { type State as EventHistory } from "../../../../../redux/modules/event-history.d";

type Props = {
    translate: *,
    loadHistory: () => void,
    clearHistory: () => void,
    eventHistory: EventHistory,
    onClose: () => void,
};

const History = ({
    translate,
    loadHistory,
    eventHistory,
    onClose,
    clearHistory,
}: Props) => {
    useEffect(() => {
        loadHistory();
    }, []);

    const headers = [
        translate("Date & Time"),
        translate("Action"),
        translate("User"),
    ];
    const actions = {
        OPTION_CREATED: translate("OPTION_CREATED"),
        OPTION_UPDATED: translate("OPTION_UPDATED"),
        OPTION_CANCELED: translate("OPTION_CANCELED"),
        OPTION_CONFIRMED: translate("OPTION_CONFIRMED"),
        OPTION_CONFIRMED_CANCELED: translate("OPTION_CONFIRMED_CANCELED"),
        OPTION_CONFIRMED_TO_EVENT: translate("OPTION_CONFIRMED_TO_EVENT"),
        EVENT_CREATED: translate("EVENT_CREATED"),
        EVENT_CANCELED: translate("EVENT_CANCELED"),
        SERIES_UPDATED: translate("SERIES_UPDATED"),
    };
    return (
        <Dialog
            id="event-history-dialog"
            translate={translate}
            title={translate("Change Log History")}
            onClose={() => {
                onClose();
                clearHistory();
            }}
            maxWidth={"xl"}
        >
            <div className={styles.tableWrapper}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headers.map((header, i) => (
                                    <TableCell key={i} align="left">
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventHistory.loading &&
                            !eventHistory.data?.accessLogResponses.length ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress />
                                </div>
                            ) : eventHistory.data?.accessLogResponses.length ? (
                                eventHistory.data?.accessLogResponses.map(
                                    (
                                        {
                                            createdDate,
                                            accessLogCode,
                                            userName,
                                        },
                                        i,
                                    ) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                {createdDate
                                                    ? moment(
                                                          createdDate,
                                                      ).format(
                                                          "DD/MM/YYYY HH:mm:ss",
                                                      )
                                                    : translate(
                                                          "No info found",
                                                      )}
                                            </TableCell>
                                            <TableCell>
                                                {actions[accessLogCode]}
                                            </TableCell>
                                            <TableCell>{userName}</TableCell>
                                        </TableRow>
                                    ),
                                )
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        {translate("There are no changes yet")}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Dialog>
    );
};

export default History;
