// @flow

import createApiModule from "../create-api-module";

import { type PathParams } from "./attendees-qrcode.d";

const { reducer, actions } = createApiModule(
    "attendeesQRCode",
    ({ uuid, eventUuid }: PathParams) =>
        `/ays-series-event-management/events/${eventUuid}/user-qr-data/${uuid}`,
);

export const load =
    (uuid: string, eventUuid: string, force: boolean = true) =>
    (dispatch: *) => {
        return dispatch(actions.load({ path: { uuid, eventUuid } }, force));
    };

export const refetch = actions.refetch;
export const clear = actions.clear;

export default reducer;
