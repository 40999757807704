// @flow

import styles from "./CustomLine.scss";

import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { DateTimeField } from "../../DatePickers";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {
    type State as CustomLineState,
    type CustomEventLine,
} from "../../../redux/modules/event-line-custom.d";

type PropsType = {
    /** Busy flag */
    busy?: ?boolean,
    /** CustomLineState */
    customLineState: CustomLineState,
    /** A translator function */
    translate: *,
    /** When a submit action is done */
    onSubmit: (line: CustomEventLine) => void,
    /** Close / Cancel handler */
    onClose: () => void,
    /** ShowlineData */
    showLineData: Object[],
    /** start */
    start: string,
    /** end */
    end: string,
};

type StateType = {
    briefing: string,
    end: string,
    maxOccupation: number,
    minOccupation: number,
    lineTypeUuid: string,
    name: string,
    preBriefing: string,
    eventLineStatus: string,
    start: string,
};

export default class CustomLine extends Component<PropsType, StateType> {
    state = {
        eventLineStatus: "OPEN",
        briefing: "",
        preBriefing: "",
        name: "",
        maxOccupation: 0,
        minOccupation: 0,
        lineTypeUuid: "",
        end: this.props.end || moment().utc().toISOString(),
        start: this.props.start || moment().utc().toISOString(),
    };

    get isDisabled(): boolean {
        const { busy } = this.props;
        const { lineTypeUuid, name } = this.state;

        return !!busy || lineTypeUuid === "" || name === "";
    }

    getUniqLines() {
        const { showLineData } = this.props;
        const tempLines = [];
        if (showLineData) {
            showLineData.map(line => {
                tempLines.push({
                    lineTypeName: line.lineTypeName,
                    lineTypeUuid: line.lineTypeUuid,
                });
            });
        }

        return (tempLines.filter(
            (line, index, self) =>
                self.findIndex(
                    t =>
                        t.lineTypeName === line.lineTypeName &&
                        t.lineTypeUuid === line.lineTypeUuid,
                ) === index,
        ): Object[]);
    }

    /**
     * Retrieve the field error if present
     */
    getFieldError(field: string): ?string {
        const {
            customLineState: { fieldErrors },
        } = this.props;
        return fieldErrors && fieldErrors[field];
    }

    handleSubmit() {
        const {
            eventLineStatus,
            briefing,
            preBriefing,
            name,
            maxOccupation,
            minOccupation,
            lineTypeUuid,
            start,
            end,
        } = this.state;

        const { onSubmit } = this.props;

        const customline = {
            eventLineStatus,
            briefing,
            preBriefing,
            name,
            maxOccupation,
            minOccupation,
            lineTypeUuid,
            start,
            end,
        };

        onSubmit(customline);
    }

    /**
     * Render component
     */
    render() {
        const { onClose, translate, busy, customLineState } = this.props;
        const { lineTypeUuid, name, minOccupation, maxOccupation, start, end } =
            this.state;

        return (
            <Dialog open={true} onClose={onClose}>
                <div className={styles.header}>
                    <DialogTitle disableTypography>
                        <Typography variant="h2">
                            {translate("Add custom line")}
                        </Typography>
                        <Typography>
                            {translate(
                                "Choose one linetype to add " +
                                    "this custom line to:",
                            )}
                        </Typography>
                    </DialogTitle>
                </div>
                <DialogContent>
                    <form
                        className={styles.form}
                        onSubmit={event => {
                            event.preventDefault();
                            this.handleSubmit();
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {customLineState && customLineState.error && (
                                    <Typography color="error">
                                        {customLineState.error}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="linetype-select">
                                    Line type
                                </InputLabel>
                                <Select
                                    fullWidth
                                    disabled={!!busy}
                                    value={lineTypeUuid}
                                    onChange={event => {
                                        this.setState({
                                            lineTypeUuid: event.target.value,
                                        });
                                    }}
                                    inputProps={{
                                        name: "linetype",
                                        id: "linetype-select",
                                    }}
                                >
                                    {this.getUniqLines().map(line => {
                                        return (
                                            <MenuItem
                                                key={`linetype-menu-item-${line.lineTypeName}`}
                                                id={`linetype-menu-item-${line.lineTypeName}`}
                                                value={line.lineTypeUuid}
                                            >
                                                {line.lineTypeName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="modal-customline-name"
                                    label={translate("Name")}
                                    value={name}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            name: value,
                                        })
                                    }
                                    error={!!this.getFieldError("name")}
                                    disabled={!!busy}
                                    helperText={this.getFieldError("name")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    id="modal-customline-minOccupation"
                                    label={translate("Min. occupation")}
                                    value={minOccupation}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            minOccupation: parseInt(value) || 1,
                                        })
                                    }
                                    error={
                                        !!this.getFieldError("minOccupation")
                                    }
                                    type="number"
                                    disabled={!!busy}
                                    helperText={this.getFieldError(
                                        "minOccupation",
                                    )}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    id="modal-customline-maxOccupation"
                                    label={translate("Max. occupation")}
                                    value={maxOccupation}
                                    onChange={({ target: { value } }) =>
                                        this.setState({
                                            maxOccupation: parseInt(value) || 1,
                                        })
                                    }
                                    error={
                                        !!this.getFieldError("maxOccupation")
                                    }
                                    type="number"
                                    disabled={!!busy}
                                    helperText={this.getFieldError(
                                        "maxOccupation",
                                    )}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimeField
                                    id="customline-start"
                                    disabled={!!busy}
                                    value={start}
                                    onChange={value =>
                                        this.setState({
                                            start: moment(value)
                                                .utc()
                                                .toISOString(),
                                        })
                                    }
                                    label={translate("Start")}
                                    error={!!this.getFieldError("start")}
                                    helperText={this.getFieldError("start")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimeField
                                    id="customline-end"
                                    disabled={!!busy}
                                    value={end ? end : start}
                                    onChange={value =>
                                        this.setState({
                                            end: moment(value)
                                                .utc()
                                                .toISOString(),
                                        })
                                    }
                                    label={translate("End")}
                                    error={!!this.getFieldError("end")}
                                    helperText={this.getFieldError("end")}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <DialogActions>
                            <div className={styles.actionButtons}>
                                <Button
                                    id="customline-cancel"
                                    onClick={onClose}
                                    disabled={!!busy}
                                >
                                    {translate("Cancel")}
                                </Button>
                                <Button
                                    id="customline-confirm"
                                    disabled={this.isDisabled}
                                    type="submit"
                                    color="primary"
                                >
                                    {translate("Add")}
                                </Button>
                            </div>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}
