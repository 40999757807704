// @flow

import { connect } from "react-redux";

import { refetch as reload } from "../../redux/modules/admin";
import {
    load as add,
    ERROR as ADMIN_USER_ADD_ERROR,
} from "../../redux/modules/admin-user-add";

import { User } from "../../components/Admin";

import { withTranslate } from "../Translate";
import { mapState } from "../../redux/utils";

export default withTranslate(
    connect(
        // Map state to props
        ({ adminUserAdd }) => ({
            asyncState: mapState(adminUserAdd, ADMIN_USER_ADD_ERROR),
        }),
        // Map dispatch to props
        (dispatch: *) => ({
            onSubmit: data =>
                dispatch(add(data)).then(() => dispatch(reload())),
        }),
    )(User),
);
