// @flow
// Import styles
import styles from "./DeleteModal.scss";

import React, { Fragment } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

type Props = {
    translate: *,
    onSubmit: () => *,
    onClose: () => void,
    name: string,
    loading: boolean,
    error: ?string,
    title: string,
    cancelText: string,
    confirmText: string,
};

export const DeleteModal = ({
    translate,
    onSubmit,
    onClose,
    name,
    loading,
    error,
    title,
    cancelText,
    confirmText,
}: Props) => {
    const msg = translate(
        "Are you sure you want to permanently delete " +
            "%{name} from At Your Service?",
        { name: name },
    );

    return (
        <Dialog onClose={onClose} open>
            <DialogTitle disableTypography>
                <Typography variant="h2">{title}</Typography>
            </DialogTitle>

            <DialogContent>
                {error && (
                    <DialogContentText color="error">{error}</DialogContentText>
                )}
                {name && (
                    <Fragment>
                        <DialogContentText
                            dangerouslySetInnerHTML={{ __html: msg }}
                            className={styles.warning}
                        ></DialogContentText>
                        <DialogContentText>
                            {translate("(This action cannot be undone.)")}
                        </DialogContentText>
                    </Fragment>
                )}
                {loading && (
                    <div style={{ textAlign: "center", margin: "1em 0" }}>
                        <CircularProgress />
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    id="modal-deleteuser-cancel"
                    onClick={onClose}
                    disabled={loading}
                >
                    {cancelText}
                </Button>
                <Button
                    id="modal-deleteuser-confirm"
                    onClick={onSubmit}
                    color="secondary"
                    disabled={loading}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
