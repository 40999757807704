// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "updateLineStaffingTemplate",
    ({ uuid }) =>
        `/ays-venue-facility-management/staffing-template-lines/${uuid}`,
    { method: "PUT" },
);

export const ERROR = "Couldn't update staffing template line";

export const load = (uuid, data) => (dispatch: *) =>
    dispatch(
        actions.load({
            path: { uuid },
            data,
        }),
    );

export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
