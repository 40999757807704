// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T0K0dXgMuMGjDdEfmj9h-w\\=\\={background-color:#651f82;color:white;margin-bottom:1em}.T0K0dXgMuMGjDdEfmj9h-w\\=\\= .Y\\+L6o9Cn8plLhohie7Vrag\\=\\={background-color:white;height:0.2em}\n", "",{"version":3,"sources":["webpack://./../MoveEvent.scss"],"names":[],"mappings":"AAAA,4BACI,wBAAyB,CACzB,WAAY,CACZ,iBAAkB,CAHtB,yDAKQ,sBAAuB,CACvB,YAAa","sourcesContent":[".title {\n    background-color: #651f82;\n    color: white;\n    margin-bottom: 1em;\n    .indicator {\n        background-color: white;\n        height: 0.2em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "T0K0dXgMuMGjDdEfmj9h-w==",
	"indicator": "Y+L6o9Cn8plLhohie7Vrag=="
};
export default ___CSS_LOADER_EXPORT___;
