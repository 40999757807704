// @flow

import styles from "./Login.scss";

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import { parsePhoneNumber } from "libphonenumber-js";

import type { FieldErrors } from "../../utils/FormError";

// Define props
type Props = {
    /** Translate interface */
    translate: *,
    /** on submit handler */
    onSubmit: (password: string, verification: string) => void,
    /** on send sms-code again handler */
    onSendAgain: () => *,
    /** phone number of the user */
    phone: string,
    /** error message */
    error: ?string,
    /** field error messages */
    fieldErrors: ?FieldErrors,
    /** is the form submitting */
    busy: boolean,
};

// Define State
type State = {
    password: string,
    verification: string,
    shown: boolean,
};

/**
 * ConfigurePassword component
 */
export default class ConfigurePassword extends Component<Props, State> {
    state = {
        password: "",
        verification: "",
        shown: false,
    };

    /**
     * Get formatted phone number
     */
    get formattedPhone(): string {
        const { phone } = this.props;
        return parsePhoneNumber(phone).formatInternational();
    }

    /**
     * Has password field an error
     */
    get hasPasswordError(): boolean {
        const { fieldErrors } = this.props;
        return !!fieldErrors && !!fieldErrors.password;
    }

    /**
     * Has verification token field an error
     */
    get hasVerificationTokenError(): boolean {
        const { fieldErrors } = this.props;
        return !!fieldErrors && !!fieldErrors.verificationToken;
    }

    /**
     * Render
     */
    render() {
        const { translate, onSubmit, onSendAgain, error, fieldErrors, busy } =
            this.props;
        const { password, verification, shown } = this.state;

        return (
            <form
                className={styles.form}
                onSubmit={event => {
                    event.preventDefault();
                    onSubmit(password, verification);
                }}
            >
                <Typography variant="h2">
                    {translate("Let’s configure your account")}
                </Typography>
                <Typography>
                    {translate(
                        "Choose a new password to secure your account. Next, enter the SMS-code we just sent to %{phoneNumber} to confirm your identity.",
                        { phoneNumber: this.formattedPhone },
                    )}
                </Typography>
                <div className={styles.cell}>
                    <FormControl
                        error={!!fieldErrors && !!fieldErrors.password}
                        disabled={busy}
                        fullWidth
                    >
                        <InputLabel htmlFor="login-configurepassword-password">
                            {translate("Password")}
                        </InputLabel>
                        <Input
                            id="login-configurepassword-password"
                            type={shown ? "text" : "password"}
                            value={password}
                            onChange={({ target: { value } }) =>
                                this.setState({ password: value })
                            }
                            autoComplete="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        id="login-configurepassword-password-show-hide"
                                        onClick={() =>
                                            this.setState({ shown: !shown })
                                        }
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        {shown ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {this.hasPasswordError && (
                            <FormHelperText id="login-configurepassword-password-helper-text">
                                {fieldErrors ? fieldErrors.password : ""}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
                <div className={styles.cell}>
                    <FormControl
                        error={!!fieldErrors && !!fieldErrors.verificationToken}
                        disabled={busy}
                        fullWidth
                    >
                        <InputLabel htmlFor="login-configurepassword-verification">
                            {translate("SMS-code")}
                        </InputLabel>
                        <Input
                            id="login-configurepassword-verification"
                            type="text"
                            value={verification}
                            onChange={({ target: { value } }) =>
                                this.setState({ verification: value })
                            }
                            autoComplete="off"
                        />
                        {this.hasVerificationTokenError && (
                            <FormHelperText id="login-configurepassword-verification-helper-text">
                                {fieldErrors
                                    ? fieldErrors.verificationToken
                                    : ""}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
                <div className={styles.cell}>
                    {error && (
                        <FormHelperText
                            id="login-configurepassword-error"
                            error={!!error}
                        >
                            {error}
                        </FormHelperText>
                    )}
                </div>
                <div className={styles.cell}>
                    <Typography>
                        {translate("Didn’t get an SMS?")}{" "}
                        <a
                            id="login-configurepassword-resend"
                            onClick={onSendAgain}
                        >
                            {translate("Send again")}
                        </a>
                    </Typography>
                </div>
                <div className={styles.cell}>
                    <Button
                        id="login-configurepassword-submit"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={busy}
                    >
                        {translate("Configure account")}
                    </Button>
                </div>
            </form>
        );
    }
}
