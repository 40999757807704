//@flow

import React, { useState } from "react";
import {
    DialogTitle,
    Typography,
    Button,
    DialogActions,
    DialogContent,
    FormGroup,
    Checkbox,
} from "@material-ui/core";
import StyledFormControlLabel from "../../../StyledFormControlLabel";

type Rules = {
    keepExistingTimingsAndOccupation: boolean,
    keepExistingStaffing: boolean,
    keepExistingBriefings: boolean,
};

type Props = {
    translate: *,
    onBack: (rules: Rules) => void,
    onNext: (rules: Rules) => void,
    onClose: () => void,
    rules: Rules,
    applyForSeries: boolean,
};

export const ruleMapper = (translate: *) => ({
    keepExistingTimingsAndOccupation: translate(
        "Keep existing timings & line capacity",
    ),
    keepExistingStaffing: translate("Keep existing staffing"),
    keepExistingBriefings: translate("Keep existing briefings"),
});

const BusinessRules = ({
    translate,
    onBack,
    onNext,
    onClose,
    rules: rulesProp,
    applyForSeries,
}: Props) => {
    const [rules, setRules] = useState(rulesProp);

    return (
        <>
            <DialogTitle disableTypography>
                <Typography variant="h2" color="inherit">
                    {applyForSeries
                        ? translate("Apply templates to series")
                        : translate("Apply templates")}
                </Typography>
                <Typography color="inherit">
                    {translate(
                        "How to apply these templates? Choose one or more options below.",
                    )}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormGroup>
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={rules.keepExistingTimingsAndOccupation}
                                name="rule1"
                                onChange={e =>
                                    setRules({
                                        ...rules,
                                        keepExistingTimingsAndOccupation:
                                            e.target.checked,
                                    })
                                }
                            />
                        }
                        label={
                            ruleMapper(translate)
                                .keepExistingTimingsAndOccupation
                        }
                    />
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={rules.keepExistingStaffing}
                                name="rule2"
                                onChange={e =>
                                    setRules({
                                        ...rules,
                                        keepExistingStaffing: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={ruleMapper(translate).keepExistingStaffing}
                    />
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={rules.keepExistingBriefings}
                                name="rule3"
                                onChange={e =>
                                    setRules({
                                        ...rules,
                                        keepExistingBriefings: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={ruleMapper(translate).keepExistingBriefings}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    id="applytemplates-rules-back"
                    onClick={() => onBack(rules)}
                    style={{ marginRight: "auto" }}
                >
                    {translate("Back")}
                </Button>
                <Button id="applytemplates-rules-cancel" onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button
                    id="applytemplates-rules-next"
                    color="primary"
                    onClick={() => onNext(rules)}
                >
                    {translate("Next")}
                </Button>
            </DialogActions>
        </>
    );
};

export default BusinessRules;
