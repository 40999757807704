// @flow

import createApiModule from "../create-api-module";
import qs from "qs";
import { type PathParams } from "./series.d";

const { reducer, actions } = createApiModule(
    "series",
    ({ size }: PathParams) =>
        `/ays-searches/searches/calendar/options-events?${qs.stringify({
            size,
        })}`,
    {
        method: "POST",
    },
);

export const load = (filter: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { size: 50 },
            data: { filter },
        }),
    );
};
export const ERROR = "Could not find any serie!";
export const clear = actions.clear;
export const refetch = actions.refetch;

/** REDUCER**/
export default reducer;
