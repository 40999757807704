//@flow
import React, { Component } from "react";
import IconMenu from "../IconMenu/IconMenu";

type Props = {
    id: string,
    index: number,
    item: Object,
    menuOptions: Object[],
};

export default class Menu extends Component<Props> {
    render() {
        const { menuOptions, id, index, item } = this.props;
        const options = [];

        menuOptions.map(option => {
            if (option.permission(item)) {
                options.push({
                    id: option.id,
                    label: option.label(item),
                    onClick: () => option.onClick(item),
                });
            }
        });

        return <IconMenu id={`${id}-${index}`} options={options} />;
    }
}
