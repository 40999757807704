// @flow

import styles from "./TabbedHeader.scss";

import React, { Component, Fragment } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import CallMadeRoundedIcon from "@material-ui/icons/CallMade";

import Header from "./Header";

type Props = {
    title: string,
    subtitle?: ?string,
    active: number,
    tabs: string[],
    onChange: (index: number) => void,
    onExportData?: () => void,
    id: string,
    actions?: boolean,
    translate?: *,
};

export default class TabbedHeader extends Component<Props> {
    /**
     * Render
     */
    render() {
        const {
            title,
            subtitle,
            active,
            tabs,
            onChange,
            id,
            translate,
            onExportData,
            actions,
        } = this.props;

        return (
            <Fragment>
                <Header title={title} subtitle={subtitle} />
                <div className={styles.wrapper}>
                    <Tabs
                        value={active}
                        onChange={(_, index) =>
                            active !== index ? onChange(index) : undefined
                        }
                        TabIndicatorProps={{ className: styles.indicator }}
                    >
                        {tabs.map(label => (
                            <Tab
                                id={`${id}-tab-${label.toLowerCase()}`}
                                key={label}
                                label={label}
                            />
                        ))}
                        {actions && (
                            <Button
                                className={styles.buttonStyle}
                                onClick={onExportData && (() => onExportData())}
                            >
                                <CallMadeRoundedIcon
                                    className={styles.iconStyle}
                                />
                                {translate && translate("EXPORT DATA")}
                            </Button>
                        )}
                    </Tabs>
                </div>
            </Fragment>
        );
    }
}
