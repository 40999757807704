// @flow
import createApiModule from "../create-api-module";
import { type PathParams } from "./event-move.d";
/**
 * Module: Add Organisation
 */

const { reducer, actions } = createApiModule(
    "eventMoveUpdate",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/series/${uuid}/move-events`,
    {
        method: "PUT",
    },
);

export const load = (uuid: string, eventUuids: string[]) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
            data: {
                eventUuids,
            },
        }),
    ).then(({ payload }) => payload.uuid);
};

export const ERROR = "Could not move series!";
export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
