// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TxVSVHHwtXIuk00glhZ22g\\=\\={padding:2em;clear:both;overflow:hidden}.wZ2a\\+fBRcyj6q3V5xkeDQg\\=\\={float:right;padding-top:1em;overflow:hidden}\n", "",{"version":3,"sources":["webpack://./../PoolSettings.scss"],"names":[],"mappings":"AAEA,4BACI,WAAY,CACZ,UAAW,CACX,eAAgB,CACnB,6BAGG,WAAY,CACZ,eAAgB,CAChB,eAAgB","sourcesContent":["// Poolsetting styles\n\n.form {\n    padding: 2em;\n    clear: both;\n    overflow: hidden;\n}\n\n.actionButtons {\n    float: right;\n    padding-top: 1em;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "TxVSVHHwtXIuk00glhZ22g==",
	"actionButtons": "wZ2a+fBRcyj6q3V5xkeDQg=="
};
export default ___CSS_LOADER_EXPORT___;
