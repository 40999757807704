//@flow
import { Typography } from "@material-ui/core";
import styles from "./MdViewer.scss";

import React from "react";
import ReactMarkdown from "react-markdown";

type Props = {
    children: any,
    translate: *,
};

const MdViewer = ({ children, translate }: Props) => {
    if (!children) {
        return (
            <Typography variant="body1">
                <i>{translate("No report")}</i>
            </Typography>
        );
    }

    return (
        <div className={styles.viewer}>
            <ReactMarkdown>{children}</ReactMarkdown>
        </div>
    );
};

export default MdViewer;
