// @flow

import styles from "./Title.scss";

import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import { type EventLine } from "../../../redux/modules/event-lines.d";

import { type State as ShowLineOpenCloseState } from "../../../redux/modules/event-line-openclose.d";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";

type Props = {
    id: string,
    translate: *,
    eventLine: EventLine,
    disabled?: boolean,
    onToggle: () => *,
    canToggle: boolean,
    eventLineOpenClose: ShowLineOpenCloseState,
    active: boolean,
    withConfirmation: boolean,
};

type ModalProps = {
    translate: *,
    active: boolean,
    showLineName: string,
    onClose: () => void,
    onSubmit: () => void,
};

type State = {
    active: boolean,
    showToggleDialog: boolean,
};

/**
 * Toggle dialog
 * Checks if the user really wants to open/close a line.
 */
const ToggleDialog = ({
    translate,
    active,
    showLineName,
    onSubmit,
    onClose,
}: ModalProps) => {
    let message = "";

    if (active) {
        message = translate("Are you sure you want to close %{line}?", {
            line: showLineName,
        });
    } else {
        message = translate("Are you sure you want to open %{line}?", {
            line: showLineName,
        });
    }

    return (
        <Dialog onClose={onClose} open>
            <DialogTitle disableTypography>
                <Typography variant="h2">
                    {translate("Toggle eventline")}
                </Typography>
                <Typography>{message}</Typography>
            </DialogTitle>
            <DialogActions>
                <Button id="modal-showlinetoggle-cancel" onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button
                    id="modal-showlinetoggle-confirm"
                    type="submit"
                    color="primary"
                    onClick={onSubmit}
                >
                    {translate("Yes")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

/**
 * Title component
 */
export default class Title extends Component<Props, State> {
    state = {
        active: true,
        showToggleDialog: false,
    };

    /**
     * Populate status
     */
    static getDerivedStateFromProps(props: Props, state: State): State {
        return {
            ...state,
            active: props.active,
        };
    }

    /**
     * Get status
     */
    get status() {
        const { translate } = this.props;
        const { active } = this.state;

        if (active) {
            return translate("Open");
        }

        return translate("Closed");
    }

    /**
     * Handle toggle
     */
    handleToggle() {
        const { onToggle } = this.props;
        const { active } = this.state;

        onToggle();
        this.setState({
            active: !active,
        });
    }

    /**
     * Render
     */
    render() {
        const {
            translate,
            eventLine,
            disabled,
            id,
            canToggle,
            eventLineOpenClose,
            withConfirmation = false,
        } = this.props;
        const { active, showToggleDialog } = this.state;

        return (
            <div className={styles.wrapper}>
                <Grid container spacing={3} alignItems="center">
                    {eventLineOpenClose.error && (
                        <Grid item xs={12}>
                            <Typography color="error">
                                {eventLineOpenClose.error}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={8}>
                        <Typography variant="h4" id={id}>
                            {eventLine.name}
                        </Typography>
                    </Grid>
                    {canToggle && (
                        <Grid item xs={4}>
                            <Typography variant="body1" align="right">
                                {this.status}{" "}
                                <Switch
                                    id="eventline-state-toggle"
                                    disabled={disabled}
                                    checked={active}
                                    color="primary"
                                    onChange={() => {
                                        withConfirmation
                                            ? this.setState({
                                                  showToggleDialog: true,
                                              })
                                            : this.handleToggle();
                                    }}
                                />
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                {showToggleDialog && (
                    <ToggleDialog
                        translate={translate}
                        active={active}
                        showLineName={eventLine.name}
                        onClose={() =>
                            this.setState({ showToggleDialog: false })
                        }
                        onSubmit={() => {
                            this.handleToggle();
                            this.setState({ showToggleDialog: false });
                        }}
                    />
                )}
            </div>
        );
    }
}
