// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yjbm8AtBdONxPvIkWn4Vuw\\=\\={text-align:center;color:#888}.Yjbm8AtBdONxPvIkWn4Vuw\\=\\=:hover{color:#fff;cursor:pointer}.Yjbm8AtBdONxPvIkWn4Vuw\\=\\=.zE5UZ92fFPb\\+NNe0vH1dAA\\=\\={color:#fff}\n", "",{"version":3,"sources":["webpack://./../LanguageSelector.scss","webpack://./../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,4BACI,iBAAkB,CAClB,UCIe,CDNnB,kCAKQ,UCHI,CDIJ,cAAe,CANvB,wDASQ,UCPI","sourcesContent":["@import \"../../styles/variables\";\n\n.item {\n    text-align: center;\n    color: $neutral-grey;\n\n    &:hover {\n        color: $white;\n        cursor: pointer;\n    }\n    &.active {\n        color: $white;\n    }\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "Yjbm8AtBdONxPvIkWn4Vuw==",
	"active": "zE5UZ92fFPb+NNe0vH1dAA=="
};
export default ___CSS_LOADER_EXPORT___;
