// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-line-details.d";

const { reducer, actions } = createApiModule(
    "eventLineDetails",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/event-lines/${eventUuid}`,
    {
        method: "PUT",
    },
);

export const load = (eventUuid: string, eventLine: *) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { eventUuid },
            data: eventLine,
        }),
    );
};

export const ERROR = "Could not update eventline details";
export const clear = actions.clear;
export const refetch = actions.refetch;
/** REDUCER **/
export default reducer;
