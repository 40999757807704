//@flow
import styles from "./CalendarView.scss";

import React, { Component } from "react";
import classnames from "classnames";
import MOMENT from "moment";
import { type State as VenueState } from "../../../../redux/modules/venues.d";
import { type State as CalendarState } from "../../../../redux/modules/calendar.d";
import CircularProgress from "@material-ui/core/CircularProgress";

import CalendarViewBody from "./CalendarViewBody/CalendarViewBody";
import CalendarViewHeader from "./CalendarViewHeader";
import CalendarViewActions from "./CalendarViewActions";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    acl: *,
    user: Profile,
    translate: *,
    venues: VenueState,
    calendar: CalendarState,
    loadCalendar: (
        startDate: string,
        endDate: string,
        promoterUuid?: *,
        showCanceled?: boolean,
    ) => any,
    onConfirmOptions: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: string) => void,
    findSeries: *,
    clearSeries: () => void,
    series: *,
    onOpenLegend: *,
    onAddEvent: *,
    onAddOption: *,
};

type State = {
    activeMonth: *,
    scrollToToday: boolean,
    calendarData: *,
    isShowCanceled: boolean,
    scrollToDate: *,
    isScrollToDate: boolean,
    seriesUuid: string,
    firstShowUuid: string,
    loading: boolean,
    showLoadingProgress: boolean,
};

export default class CalendarView extends Component<Props, State> {
    state = {
        scrollToToday: true,
        activeMonth: MOMENT(new Date()),
        calendarData: null,
        isShowCanceled: false,
        scrollToDate: null,
        isScrollToDate: false,
        seriesUuid: "",
        firstShowUuid: "",
        loading: false,
        showLoadingProgress: false,
    };

    /**
     * Show canceled
     */
    checkCanceledShows = () => {
        const { activeMonth, firstShowUuid, seriesUuid } = this.state;
        return this.onScrolToDate(activeMonth, firstShowUuid, seriesUuid);
    };
    /**
     * Initial view
     */
    initialView = () => {
        const { activeMonth, isShowCanceled } = this.state;
        this.setState({
            loading: true,
        });
        return this.loadCalendar(activeMonth, isShowCanceled).then(() => {
            this.setState({
                scrollToToday: true,
                loading: false,
            });
        });
    };

    componentDidMount() {
        this.initialView();
    }

    /**
     * Scroll to date
     */
    onScrolToDate = (date: *, firstShowUuid?: string, seriesUuid?: string) => {
        const { isShowCanceled } = this.state;
        this.setState({
            showLoadingProgress: true,
        });
        return this.loadCalendar(date, isShowCanceled).then(() =>
            this.setState({
                scrollToDate: date,
                firstShowUuid: firstShowUuid,
                seriesUuid: seriesUuid,
                isScrollToDate: true,
                activeMonth: MOMENT(date).startOf("month"),
                showLoadingProgress: false,
            }),
        );
    };

    /**
     * Load calendar
     */
    loadCalendar = (month: *, isShowCanceled: boolean) => {
        const { loadCalendar } = this.props;

        return loadCalendar(
            MOMENT(month)
                .startOf("month")
                .subtract(1, "months")
                .format("YYYY-MM-DD"),
            MOMENT(month)
                .add(1, "months")
                .endOf("month")
                .add(1, "day")
                .subtract(1, "second")
                .format("YYYY-MM-DD"),
            null,
            isShowCanceled,
        );
    };

    /**
     * Render
     */
    render() {
        const {
            activeMonth,
            scrollToToday,
            isShowCanceled,
            scrollToDate,
            isScrollToDate,
            seriesUuid,
            firstShowUuid,
            loading,
            showLoadingProgress,
        } = this.state;

        const {
            acl,
            translate,
            venues,
            calendar,
            user,
            onConfirmOptions,
            onEditOption,
            onCancelOption,
            onEditSeries,
            onEditEvent,
            onMoveEvent,
            onEditConfirmedOption,
            onDefineEvents,
            onCancelEvent,
            onCancelConfirmedOption,
            findSeries,
            clearSeries,
            series,
            onAddOptionToSeries,
            onAddEventToSeries,
            onAddEvent,
            onOpenLegend,
            onAddOption,
            onShowHistory,
            onDoubleClickEvent,
        } = this.props;

        return (
            <div className={classnames(styles.element)}>
                <CalendarViewHeader
                    translate={translate}
                    venues={venues}
                    acl={acl}
                    handleShowCanceled={value => {
                        this.setState(
                            {
                                isShowCanceled: value,
                                showLoadingProgress: true,
                            },
                            () => this.checkCanceledShows(),
                        );
                    }}
                    isShowCanceled={isShowCanceled}
                    activeMonth={activeMonth}
                    findSeries={findSeries}
                    clearSeries={clearSeries}
                    series={series}
                    scrolToDate={(date, firstShowUuid, seriesUuid) =>
                        this.onScrolToDate(date, firstShowUuid, seriesUuid)
                    }
                    showLoadingProgress={showLoadingProgress}
                />
                {loading ? (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                ) : (
                    <CalendarViewBody
                        acl={acl}
                        user={user}
                        isShowCanceled={isShowCanceled}
                        onCancelEvent={onCancelEvent}
                        onCancelConfirmedOption={onCancelConfirmedOption}
                        translate={translate}
                        calendar={calendar.data}
                        venues={venues}
                        onDefineEvents={onDefineEvents}
                        activeMonth={activeMonth}
                        onConfirmOptions={onConfirmOptions}
                        onEditOption={onEditOption}
                        onCancelOption={onCancelOption}
                        toToday={scrollToToday}
                        scrollToDate={scrollToDate}
                        isScrollToDate={isScrollToDate}
                        onEditSeries={onEditSeries}
                        firstShowUuid={firstShowUuid}
                        seriesUuid={seriesUuid}
                        onEditEvent={onEditEvent}
                        onMoveEvent={onMoveEvent}
                        onAddOptionToSeries={onAddOptionToSeries}
                        onEditConfirmedOption={onEditConfirmedOption}
                        onAddEventToSeries={onAddEventToSeries}
                        onShowHistory={onShowHistory}
                        onDoubleClickEvent={onDoubleClickEvent}
                        afterToToday={() =>
                            this.setState({
                                scrollToToday: false,
                                isScrollToDate: false,
                            })
                        }
                        onLoadMore={(date, firstShowUuid, seriesUuid) =>
                            this.onScrolToDate(date, firstShowUuid, seriesUuid)
                        }
                    />
                )}
                <CalendarViewActions
                    onOpenLegend={onOpenLegend}
                    onAddEvent={onAddEvent}
                    onAddOption={onAddOption}
                    acl={acl}
                    translate={translate}
                />
            </div>
        );
    }
}
