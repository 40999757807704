//@flow

import React, { useEffect, useState } from "react";
import StyledFormControlLabel from "../StyledFormControlLabel";
import { Checkbox } from "@material-ui/core";

type Props = {
    onCheckboxChange: () => {},
    className?: String,
    isChecked: Boolean,
    label: string,
    disabled: boolean,
};

const NotificationCheckbox = ({
    onCheckboxChange,
    className,
    isChecked,
    label,
    disabled,
}: Props) => {
    const [selected, setSelected] = useState(isChecked);

    useEffect(() => {
        setSelected(isChecked);
    }, [isChecked]);

    const handleCheckboxChange = event => {
        const checked = event.target.checked;
        setSelected(checked);
        onCheckboxChange(checked);
    };

    return (
        <StyledFormControlLabel
            className={className}
            control={
                <Checkbox
                    color="primary"
                    checked={selected}
                    onChange={handleCheckboxChange}
                    disabled={disabled}
                />
            }
            label={label}
        />
    );
};

export default NotificationCheckbox;
