// @flow

import styles from "./LanguageSelector.scss";

import classnames from "classnames";
import React, { Component } from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";

//Define props
type Props = {
    /** change language function */
    onClick: (language: string) => void,
    /** is the language active? */
    active: string,
};

/**
 * NavBarItem component
 */
export default class LanguageSelector extends Component<Props> {
    /**
     * Render
     */
    render() {
        const { active, onClick } = this.props;

        return (
            <ListItem id="navbar-language-switcher">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <ListItemText
                            onClick={() => onClick("nl")}
                            className={classnames(styles.item, {
                                [styles.active]: active === "nl",
                            })}
                            primaryTypographyProps={{
                                color: "inherit",
                            }}
                            primary={"NL"}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ListItemText
                            onClick={() => onClick("en")}
                            className={classnames(styles.item, {
                                [styles.active]: active === "en",
                            })}
                            primaryTypographyProps={{
                                color: "inherit",
                            }}
                            primary={"EN"}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ListItemText
                            onClick={() => onClick("fr")}
                            className={classnames(styles.item, {
                                [styles.active]: active === "fr",
                            })}
                            primaryTypographyProps={{
                                color: "inherit",
                            }}
                            primary={"FR"}
                        />
                    </Grid>
                </Grid>
            </ListItem>
        );
    }
}
