// @flow

import React, { Fragment, Component } from "react";
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    type Match,
    type RouterHistory,
    type Location,
} from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import stripslash from "../../../utils/stripslash";

import { type State as DetailsState } from "../../../redux/modules/details.d";
import { load, clear, ERROR } from "../../../redux/modules/details";
import { mapState } from "../../../redux/utils";

import NotFound from "../../../components/NotFound";
import TabbedHeader from "../../../components/Authenticated/TabbedHeader";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
import Details from "./Details";
import Lines from "./Lines";
import Members from "./Members";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    history: RouterHistory,
    details: DetailsState,
    load: (organisationUUID: string) => void,
    clear: () => void,
    location: Location,
};

type State = {
    selected: number,
};

const TABS = (translate, acl) => {
    const tabs = [];

    if (acl("organisations.members.read")) {
        tabs.push(["/members/page/1/", translate("Members"), "members"]);
    }

    if (acl("organisations.lines.read")) {
        tabs.push(["/lines/page/1/", translate("Preferred lines"), "lines"]);
    }

    if (acl("organisations.read")) {
        tabs.push(["/details/", translate("Details"), "details"]);
    }

    return tabs;
};

class Detail extends Component<Props, State> {
    state = {
        selected: 0,
    };

    static getDerivedStateFromProps(props: Props) {
        const path = props.location.pathname;
        const tabs = TABS(props.translate, props.acl);
        const found = tabs.findIndex(tab => path.includes(tab[2]));
        return {
            selected: Math.max(Math.min(found, tabs.length - 1), 0),
        };
    }

    /**
     * Initial load
     */
    componentDidMount() {
        const { clear } = this.props;
        clear();
        this.reload();
    }

    /**
     * Refresh
     */
    componentDidUpdate() {
        this.reload();
    }

    /**
     * Trigger update
     */
    reload() {
        const { load, match } = this.props;
        if (match.params.id) {
            load(match.params.id);
        }
    }

    /**
     * Handle tab change
     */
    handleTabChange(tabIndex: number) {
        const { translate, acl, history, match } = this.props;
        history.push(
            `${stripslash(match.url)}${TABS(translate, acl)[tabIndex][0]}`,
        );
        this.setState({
            selected: tabIndex,
        });
    }

    /**
     * Render
     */
    render() {
        const { match, history, details, translate, acl } = this.props;
        const { selected } = this.state;

        return (
            <Fragment>
                <TabbedHeader
                    id="detail-header"
                    title={details.data ? details.data.name : "..."}
                    subtitle={translate("Organisations")}
                    tabs={TABS(translate, acl).map(tab => tab[1])}
                    active={selected}
                    onChange={this.handleTabChange.bind(this)}
                />
                <Switch>
                    <Route path={`${match.path}members/page/:page/`}>
                        {(acl("organisations.members.read") && (
                            <Members
                                onTabChange={path =>
                                    history.push(
                                        `${stripslash(match.url)}${path}`,
                                    )
                                }
                                onPageChange={page =>
                                    history.push(
                                        `${stripslash(
                                            match.url,
                                        )}/members/page/${page + 1}/`,
                                    )
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}lines/page/:page/`}>
                        {(acl("organisations.lines.read") && (
                            <Lines
                                onTabChange={path =>
                                    history.push(
                                        `${stripslash(match.url)}${path}`,
                                    )
                                }
                                onPageChange={page =>
                                    history.push(
                                        `${stripslash(
                                            match.url,
                                        )}/lines/page/${page}/`,
                                    )
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}details/`} exact>
                        {(acl("organisations.read") && (
                            <Details
                                onTabChange={path =>
                                    history.push(
                                        `${stripslash(match.url)}${path}`,
                                    )
                                }
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route>
                        <Redirect
                            to={`${stripslash(match.url)}/members/page/1/`}
                        />
                    </Route>
                </Switch>
            </Fragment>
        );
    }
}

export default withAcl(
    withTranslate(
        withRouter(
            connect(
                ({ details }) => ({
                    details: {
                        ...mapState(details, ERROR),
                        uuid:
                            details.params &&
                            details.params.path &&
                            details.params.path.uuid,
                    },
                }),
                (dispatch: *) => bindActionCreators({ load, clear }, dispatch),
            )(Detail),
        ),
    ),
);
