// @flow

import React from "react";
import { connect } from "react-redux";
import { withRouter, type Match } from "react-router";

import { load as remove } from "../../redux/modules/organisation-delete";
import {
    refetch,
    ERROR as ORGANISATIONS_ERROR,
} from "../../redux/modules/organisations";
import { type State as OrganisationState } from "../../redux/modules/organisations.d";

import { withTranslate } from "../Translate";
import { bindActionCreators } from "redux";
import { withAcl } from "../Acl";
import { mapState } from "../../redux/utils";
import { DeleteModal } from "../../components/Modals/DeleteModal/DeleteModal";

type Props = {
    translate: *,
    match: Match,
    remove: () => Promise<void>,
    organisations: OrganisationState,
    onBack: () => void,
    refetch: () => void,
};

const DeleteOrganisation = ({
    translate,
    match,
    remove,
    organisations,
    onBack,
    refetch,
}: Props) => {
    return (
        <DeleteModal
            translate={translate}
            data={organisations.data.content.find(
                org => org.organisationUuid === match.params.id,
            )}
            name={
                organisations.data.content.find(
                    org => org.organisationUuid === match.params.id,
                ).organisationName
            }
            loading={organisations.loading}
            error={organisations.error}
            onSubmit={() =>
                remove(match.params.id).then(() => {
                    onBack();
                    refetch();
                })
            }
            onClose={onBack}
            title={translate("Delete organisation")}
            cancelText={translate("Cancel")}
            confirmText={translate("Delete")}
        />
    );
};

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ organisations }) => {
                    return {
                        organisations: {
                            ...mapState(organisations, ORGANISATIONS_ERROR),
                            page:
                                organisations.params &&
                                organisations.params.path &&
                                organisations.params.path.page + 1,
                        },
                    };
                },
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            refetch,
                            remove,
                        },
                        dispatch,
                    ),
            )(DeleteOrganisation),
        ),
    ),
);
