// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "staffingTemplateDelete",
    ({ uuid }) => `/ays-venue-facility-management/staffing-templates/${uuid}`,
    { method: "DELETE" },
);

export const ERROR = "Couldn't delete staffing template";
export const load = (uuid: string) => (dispatch: *) =>
    dispatch(actions.load({ path: { uuid } }));

export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
