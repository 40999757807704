// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ool-K331HTclPE8-Qy36bQ\\=\\={height:calc(100vh - 13.0625em);overflow-y:scroll}.Ool-K331HTclPE8-Qy36bQ\\=\\= h5{padding-top:1em}\n", "",{"version":3,"sources":["webpack://./../Lines.scss"],"names":[],"mappings":"AAGA,4BACI,8BAA+B,CAC/B,iBAAkB,CAFtB,+BAKQ,eAAgB","sourcesContent":["// Add styles\n@import \"../../styles/variables\";\n\n.list {\n    height: calc(100vh - 13.0625em);\n    overflow-y: scroll;\n\n    h5 {\n        padding-top: 1em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "Ool-K331HTclPE8-Qy36bQ=="
};
export default ___CSS_LOADER_EXPORT___;
