// @flow

import { fromJS } from "immutable";
import warning from "warning";
import invariant from "invariant";

import { format as formatPhone } from "../../utils/phonenumber";
import b64Decode from "../../utils/b64-decode-utf8";
import { typeof Client } from "../../utils/api-client/modules/user";

import {
    type ImmutableUser,
    type Action,
    type Auth,
    type Profile,
    type ClearAction,
    type SetIdAction,
    type SetRegisteredAction,
} from "./user.d";

const ERROR_STATUS =
    "This phone number seems invalid, please contact your administrator.";

const ERROR_LOGIN = "Incorrect password, please try again.";

const ERROR_REGISTER =
    "This SMS-code seems invalid, please fill in the correct SMS-code.";

const ERROR_RESEND = "Something went wrong, please try again.";

const CLEAR = "user/clear";
const CLEAR_ERRORS = "user/errors/clear";
const DATA_PENDING = "user/data/pending";
const DATA_SUCCESS = "user/data/success";
const DATA_FAILURE = "user/data/failure";
const STATUS_PENDING = "user/status/pending";
const STATUS_SUCCESS = "user/status/success";
const STATUS_FAILURE = "user/status/failure";
const REGISTER_PENDING = "user/register/pending";
const REGISTER_SUCCESS = "user/register/success";
const REGISTER_FAILURE = "user/register/failure";
const RESEND_PENDING = "user/resend/pending";
const RESEND_SUCCESS = "user/resend/success";
const RESEND_FAILURE = "user/resend/failure";
const SET_ID = "user/id/set";
const SET_REGISTERED = "user/registered/set";
const REFRESH_TOKEN = "user/data/refresh";

/**
 * Get Profile from token
 */
const readProfile = (token: string): ?Profile => {
    try {
        return JSON.parse(b64Decode(token.split(".")[1]));
    } catch (error) {
        warning(false, error);
        return null;
    }
};

/**
 * Save the tokens in localstorage if available
 */
const saveTokens = (auth: Auth) => {
    if (!localStorage) {
        return;
    }

    localStorage.setItem("ays.token", auth.token);
    localStorage.setItem("ays.refreshToken", auth.refreshToken);
};

/**
 * Clear the tokens in localstorage if available
 */
const clearTokens = () => {
    if (localStorage) {
        localStorage.removeItem("ays.token");
        localStorage.removeItem("ays.refreshToken");
    }
};

// Fetch auth tokens from system
const TOKENS: ?Auth = (() => {
    if (!localStorage) {
        return null;
    }

    const token = localStorage.getItem("ays.token");
    const refreshToken = localStorage.getItem("ays.refreshToken");

    if (token && refreshToken) {
        return {
            token,
            refreshToken,
        };
    }

    return null;
})();

// Fetch profile from tokens
const PROFILE: ?Profile = (() => {
    if (!TOKENS) {
        return null;
    }

    return readProfile(TOKENS.token);
})();

// Fetch the data
const DATA = (() => {
    if (!TOKENS || !PROFILE) {
        return null;
    }

    return {
        auth: TOKENS,
        profile: PROFILE,
    };
})();

// Set initial state
const initialState: ImmutableUser = (fromJS({
    registered: !!TOKENS,
    loading: false,
    error: null,
    fieldErrors: null,
    data: DATA,
    id: (PROFILE && PROFILE.userPhoneNumber) || null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableUser = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return fromJS({
            registered: false,
            loading: false,
            error: null,
            fieldErrors: null,
            data: null,
            id: null,
        });
    }

    if (type === CLEAR_ERRORS) {
        return state.merge({
            error: null,
            fieldErrors: null,
        });
    }

    // Raw set id
    if (type === SET_ID) {
        return state.set("id", payload);
    }

    if (type === SET_REGISTERED) {
        return state.set("registered", payload);
    }

    if (type === STATUS_PENDING) {
        return state.merge({
            loading: true,
        });
    }

    if (type === REGISTER_PENDING) {
        return state.merge({
            loading: true,
        });
    }

    if (type === DATA_PENDING) {
        return state.merge({
            loading: true,
            data: null,
        });
    }

    if (type === RESEND_PENDING) {
        return state.merge({
            loading: true,
            error: null,
        });
    }

    if (type === STATUS_SUCCESS) {
        invariant(payload !== undefined, "Payload required!");

        return state.merge({
            registered: payload.registered,
            loading: false,
            error: null,
            fieldErrors: null,
            id: payload.id,
        });
    }

    if (type === REGISTER_SUCCESS) {
        return state.merge({
            registered: true,
            error: null,
            fieldErrors: null,
        });
    }

    if (type === DATA_SUCCESS || type === REFRESH_TOKEN) {
        invariant(payload, "Payload required!");
        invariant(
            payload.token && payload.refreshToken,
            "Wrong response body!",
        );

        const profile = readProfile(payload.token);

        return state.merge({
            registered: true,
            loading: false,
            data: fromJS({
                auth: payload,
                profile,
            }),
            error: null,
            fieldErrors: null,
            id: profile && profile.userPhoneNumber,
        });
    }

    if (type === RESEND_SUCCESS) {
        return state.merge({
            loading: false,
        });
    }

    if (type === STATUS_FAILURE) {
        invariant(payload, "Payload required");

        return state.merge({
            loading: false,
            error: payload,
            fieldErrors: null,
            registered: false,
        });
    }

    if (type === REGISTER_FAILURE) {
        invariant(payload, "Payload required");

        return state.merge({
            loading: false,
            error: payload.error,
            fieldErrors: payload.fieldErrors,
        });
    }

    if (type === DATA_FAILURE) {
        invariant(payload, "Payload required");

        return state.merge({
            loading: false,
            error: payload.message || payload,
            fieldErrors: null,
            data: null,
        });
    }

    if (type === RESEND_FAILURE) {
        return state.merge({
            loading: false,
            error: ERROR_RESEND,
        });
    }

    return state;
};

/**
 * ActionCreator: clear user
 */
export const clear = (): ClearAction => {
    clearTokens();
    return {
        type: CLEAR,
    };
};

/**
 * ActionCreator: clear errors
 */
export const clearErrors = () => ({
    type: CLEAR_ERRORS,
});

/**
 * ActionCreator: sets id
 */
export const setId = (id: string): SetIdAction => ({
    type: SET_ID,
    payload: id,
});

/**
 * ActionCreator: set registered
 */
export const setRegistered = (value: boolean): SetRegisteredAction => ({
    type: SET_REGISTERED,
    payload: value,
});

/**
 * ActionCreator: Load user status
 *  - Set id, return cached status if already present
 *  - Check if profile is present for that id, clear if not
 *  - Check registration state and set registration flag
 */
export const loadStatus =
    (id: string) => (dispatch: *, getState: *, client: Client) => {
        const { user } = getState();

        // Formatted id
        const formatted = formatPhone(id);

        // Short circuit if already loaded
        if (user.get("id") === formatted) {
            return;
        }
        // Short circuit if already loading
        if (user.get("loading")) {
            return;
        }

        // Flag loading
        dispatch({
            type: STATUS_PENDING,
            payload: formatted || id,
        });

        return client
            .login(formatted || id)
            .then(payload =>
                dispatch({
                    type: STATUS_SUCCESS,
                    payload: {
                        registered: payload,
                        id: formatted || id,
                    },
                }),
            )
            .catch(error =>
                dispatch({
                    type: STATUS_FAILURE,
                    payload:
                        (error &&
                            ((error.messages && error.messages.phoneNumber) ||
                                error.message)) ||
                        ERROR_STATUS,
                }),
            );
    };

/**
 * ActionCreator: login (lazy)
 *  - Check for id, crash if not present
 *  - Clear current token and refresh profile (because of the pw param)
 */
export const loadToken =
    (password: string) => (dispatch: *, getState: *, client: Client) => {
        const { user } = getState();

        // Error when no id is present. Should not attempt to load
        invariant(user.get("id"), "Id is required");

        // Short circuit if user is not registered
        if (!user.get("registered")) {
            return;
        }

        // Set loading flag and clear current profile
        dispatch({
            type: DATA_PENDING,
        });

        // Call the api
        return client
            .getTokenWithPassword(user.get("id"), password)
            .then(payload => {
                // Save to browser
                saveTokens(payload);

                // Dispatch to store
                dispatch({
                    type: DATA_SUCCESS,
                    payload,
                });
            })
            .catch(() => {
                // Clear tokens
                clearTokens();

                // Dispatch to store
                dispatch({
                    type: DATA_FAILURE,
                    payload: ERROR_LOGIN,
                });
            });
    };

/**
 * ActionCreator: refresh token
 */
export const refreshToken =
    () => (dispatch: *, getState: *, client: Client) => {
        return client
            .refreshToken()
            .then(payload => {
                saveTokens(payload);

                dispatch({
                    type: REFRESH_TOKEN,
                    payload,
                });

                // Pass it on
                return payload;
            })
            .catch(error => {
                // Clear localstorage
                clearTokens();

                dispatch({
                    type: CLEAR,
                });

                // Pass it on...
                throw error;
            });
    };

/**
 * ActionCreator: register
 *  - Check for id, crash if not present
 *  - Do register and update state accordingly to request
 */
export const register =
    (password: string, verification: string) =>
    (dispatch: *, getState: *, client: Client) => {
        const { user } = getState();

        // Error when no id is present. Should not attempt to load
        invariant(user.get("id"), "Id is required");

        // Short circuit if loading
        if (user.get("loading")) {
            return;
        }

        // Set registration pending
        dispatch({
            type: REGISTER_PENDING,
        });

        client
            .register(user.get("id"), verification, password)
            // Set registration success
            .then(() => {
                // Set registered
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: true,
                });

                // Attempt login
                dispatch(loadToken(password));
            })
            // Set registration failure
            .catch(error => {
                const payload = {};
                if (error.messages) {
                    payload.fieldErrors = error.messages;
                } else {
                    payload.error = (error && error.message) || ERROR_REGISTER;
                }
                dispatch({
                    type: REGISTER_FAILURE,
                    payload,
                });
            });
    };

/**
 * Actioncreator: resend verification
 */
export const resendVerification =
    () => (dispatch: *, getState: *, client: *) => {
        const id = getState().user.get("id");

        invariant(id, "Id is required for this action!");

        dispatch({
            type: RESEND_PENDING,
        });

        return client
            .resendVerification(id)
            .then(() =>
                dispatch({
                    type: RESEND_SUCCESS,
                }),
            )
            .catch(() =>
                dispatch({
                    type: RESEND_FAILURE,
                }),
            );
    };
