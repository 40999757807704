// @flow

import React, { Component, Fragment } from "react";
import get from "lodash.get";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";

//import Components
import Option from "../Option/Option";
import Event from "../Event/Event";
import moment from "moment";

import styles from "./Options.scss";
import { TableFooter, Typography } from "@material-ui/core";

type Props = {
    translate: *,
    /** Trigger modal close */
    onClose: () => void,
    /** Trigger */
    onSubmit: (confirmedOptions: *, events: *, seriesName: string) => void,
    options: *,
    loading: boolean,
    id: string,
    /** Define event state */
    defineEvetsState: *,
    seriesName: string,
    showPast: boolean,
    optionCode: string,
};

type State = {
    options: *,
    subEvents: Array<*>,
    seriesName: string,
    internalLoader: boolean,
    optionCode: string,
};

/**
 * Add dialog
 */
export default class Options extends Component<Props, State> {
    state = {
        options: this.props.options,
        seriesName: this.props.seriesName,
        optionCode: this.props.optionCode,
        subEvents: [],
        internalLoader: false,
    };

    /**
     * Set Status for all options (change all to)
     * and update end and start for the options
     */
    onAllSelect = (value: string) => {
        const { options, subEvents } = this.state;
        const { showPast } = this.props;

        if (showPast) {
            this.setState({
                options: options.map(option => ({
                    ...option,
                    status: value ? value : option.status,
                })),
            });
        } else {
            this.setState({
                options: options.map(option =>
                    moment(option.date).diff(moment(), "days") >= 0
                        ? {
                              ...option,
                              status: value ? value : option.status,
                          }
                        : {
                              ...option,
                              status: option.status,
                          },
                ),
            });
        }

        this.setState({
            subEvents: subEvents.map(subEvent => {
                subEvent.status = value;

                return subEvent;
            }),
        });
    };

    /**
     * simple function to genetate uuids
     */
    get newUuid() {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            c => {
                const r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            },
        );
        return uuid;
    }

    /**
     * onAddEvent
     */

    onAddEvent = (optionIndex: *) => {
        const { options, subEvents } = this.state;

        this.setState(
            {
                subEvents: [
                    ...subEvents,
                    {
                        foreignUuid: options[optionIndex].uuid,
                        name: options[optionIndex].name,
                        status: options[optionIndex].status,
                        venueCode: options[optionIndex].venueCode,
                        venueName: options[optionIndex].venueName,
                        venueUuid: options[optionIndex].venueUuid,
                        date: options[optionIndex].date,
                        end: options[optionIndex].end,
                        uuid: this.newUuid,
                    },
                ],
            },
            () => {
                //$FlowFixMe[prop-missing]
                this.optionsEnd?.scrollIntoView();
            },
        );
    };

    /**
     * Disable
     */
    get disabled() {
        const { defineEvetsState, loading } = this.props;

        return defineEvetsState.loading || loading;
    }

    /**
     * Get the change handler
     */
    getChangeHandler = (type: string, field: string, value: *, key: *) => {
        const { subEvents, options } = this.state;

        if (type && type === "EVENT") {
            const IsubEvents = subEvents;
            IsubEvents.map(event =>
                event.uuid === key ? (event[field] = value) : event,
            );

            this.setState({
                subEvents: IsubEvents,
            });
        }

        if (type && type === "OPTION") {
            const Ioptions = options;

            Ioptions[Number(key)][field] = value;
            this.setState({
                options: Ioptions,
            });

            //Remove all events that have been generated when the option has no more public or private
            if (
                field === "status" &&
                (value !== "PUBLIC" || value !== "PRIVATE")
            ) {
                this.onResetEvent(key);
            }
        }
    };

    /**
     * Reset event via option index
     * */

    onResetEvent = (index: *) => {
        const { options, subEvents } = this.state;
        const Ioptions = options;
        const IsubEvents = subEvents;

        const updatedEvents = IsubEvents.filter(
            event => event.foreignUuid !== Ioptions[Number(index)].uuid,
        );

        this.setState({
            subEvents: updatedEvents,
        });
    };

    /**
     * Get errors
     */
    getError(key: string, type: string, index: *) {
        const { defineEvetsState } = this.props;

        let transformedKey = key;

        if (defineEvetsState.fieldErrors && type === "EVENT") {
            return get(
                defineEvetsState.fieldErrors,
                `events[${index}].${transformedKey}`,
            );
        }

        if (defineEvetsState.fieldErrors && type === "OPTION") {
            return get(defineEvetsState.fieldErrors, transformedKey);
        }
    }

    /** check the status if public or private */
    get isEvent() {
        const { options } = this.state;

        if (options) {
            const Ioption = options.find(
                option =>
                    option.status === "PUBLIC" || option.status === "PRIVATE",
            );
            if (Ioption) {
                return true;
            }
        }

        return false;
    }

    /**
     * onDeleteEvent
     **/

    onDeleteEvent = (uuid: *) => {
        const { subEvents } = this.state;

        const IsubEvents = subEvents;

        const updatedEvents = IsubEvents.filter(event => event.uuid !== uuid);

        this.setState({
            subEvents: updatedEvents,
        });
    };

    /**
     * Filter Data
     * Parse date and time to utc before send
     */

    filterData = (data: *) => {
        //event represent all data that has public/private status

        return data.reduce((events, option) => {
            const newOption = {
                date: moment.utc(option.date).toISOString(),
                end: moment.utc(option.end).toISOString(),
                name: option.name,
                promoterUuid: option.promoterUuid,
                ranking: option.ranking,
                seriesInternal: option.seriesInternal,
                seriesName: option.seriesName,
                start: moment.utc(option.date).toISOString(),
                status: option.status,
                uuid: option.uuid,
                venueCode: option.venueCode,
                venueName: option.venueName,
                venueUuid: option.venueUuid,
            };
            events.push(newOption);

            return events;
        }, []);
    };

    /**
     * Handle submit
     */
    handleSubmit = (e: *) => {
        e.preventDefault();
        this.setState({
            internalLoader: true,
        });
        const { onSubmit, seriesName } = this.props;
        const { options, subEvents, optionCode } = this.state;

        const Ioptions = options;
        const IsubEvents = subEvents;

        const confirmedOptions = this.filterData(Ioptions);
        const events = this.filterData(IsubEvents);
        onSubmit(confirmedOptions, events, seriesName, optionCode)
            ?.then(() => {
                this.setState({
                    internalLoader: false,
                });
            })
            .catch(() => {
                this.setState({
                    internalLoader: false,
                });
            });
    };

    /**
     * Component render
     */
    render() {
        const {
            translate,
            id,
            defineEvetsState,
            seriesName,
            onClose,
            showPast,
            optionCode,
        } = this.props;
        const { options, subEvents, internalLoader } = this.state;
        return (
            <form onSubmit={event => this.handleSubmit(event)}>
                {!!internalLoader && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
                {!internalLoader && (
                    <Fragment>
                        <DialogContent className={styles.middlecontent}>
                            <Table className={styles.tablebelow}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate("Event Name")}
                                        </TableCell>
                                        <TableCell>
                                            {translate("Venue")}
                                        </TableCell>
                                        <TableCell>
                                            {translate("Status")}
                                        </TableCell>
                                        <TableCell>
                                            {translate("From")}
                                        </TableCell>
                                        {this.isEvent && (
                                            <Fragment>
                                                <TableCell>
                                                    {translate("Until")}
                                                </TableCell>
                                                <TableCell />
                                            </Fragment>
                                        )}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {options &&
                                        options.map((option, index) => {
                                            if (
                                                !showPast &&
                                                moment(option.date).isBefore()
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <Fragment key={option.uuid}>
                                                    <Option
                                                        index={index}
                                                        translate={translate}
                                                        seriesName={
                                                            seriesName ||
                                                            optionCode
                                                        }
                                                        option={option}
                                                        defineEvetsState={
                                                            defineEvetsState
                                                        }
                                                        getError={field =>
                                                            this.getError(
                                                                field,
                                                                "OPTION",
                                                                index,
                                                            )
                                                        }
                                                        options={options}
                                                        onChange={(
                                                            field,
                                                            value,
                                                            index,
                                                        ) =>
                                                            this.getChangeHandler(
                                                                "OPTION",
                                                                field,
                                                                value,
                                                                index,
                                                            )
                                                        }
                                                        id={`${id}-option`}
                                                        onSelect={(
                                                            field,
                                                            value,
                                                        ) =>
                                                            this.getChangeHandler(
                                                                "OPTION",
                                                                field,
                                                                value,
                                                                index,
                                                            )
                                                        }
                                                        onAddEvent={optionIndex =>
                                                            this.onAddEvent(
                                                                optionIndex,
                                                            )
                                                        }
                                                    />
                                                    {subEvents.length > 0 &&
                                                        (option.status ===
                                                            "PUBLIC" ||
                                                            option.status ===
                                                                "PRIVATE") &&
                                                        subEvents
                                                            .filter(
                                                                event =>
                                                                    event.foreignUuid ===
                                                                    option.uuid,
                                                            )
                                                            .map(
                                                                (
                                                                    event,
                                                                    index,
                                                                ) => (
                                                                    <Event
                                                                        uuid={
                                                                            event.uuid
                                                                        }
                                                                        translate={
                                                                            translate
                                                                        }
                                                                        defineEvetsState={
                                                                            defineEvetsState
                                                                        }
                                                                        getError={field =>
                                                                            this.getError(
                                                                                field,
                                                                                "EVENT",
                                                                                index,
                                                                            )
                                                                        }
                                                                        seriesName={
                                                                            seriesName
                                                                        }
                                                                        id={`${id}-event`}
                                                                        key={
                                                                            event.uuid
                                                                        }
                                                                        event={
                                                                            event
                                                                        }
                                                                        onChange={(
                                                                            field,
                                                                            value,
                                                                            uuid,
                                                                        ) =>
                                                                            this.getChangeHandler(
                                                                                "EVENT",
                                                                                field,
                                                                                value,
                                                                                uuid,
                                                                            )
                                                                        }
                                                                        onSelect={(
                                                                            field,
                                                                            value,
                                                                        ) =>
                                                                            this.getChangeHandler(
                                                                                "EVENT",
                                                                                field,
                                                                                value,
                                                                                event.uuid,
                                                                            )
                                                                        }
                                                                        onDelete={uuid =>
                                                                            this.onDeleteEvent(
                                                                                uuid,
                                                                            )
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                </Fragment>
                                            );
                                        })}
                                </TableBody>
                                {options?.length === 0 ||
                                (!showPast &&
                                    options?.filter(
                                        o =>
                                            moment(o.date).diff(
                                                moment(),
                                                "days",
                                            ) >= 0,
                                    ).length === 0) ? (
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colspan={4}>
                                                <Typography
                                                    align="center"
                                                    style={{ fontSize: "1em" }}
                                                >
                                                    {translate(
                                                        "No future events",
                                                    )}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                ) : null}
                            </Table>
                            {/* $FlowFixMe[prop-missing] */}
                            <div ref={el => (this.optionsEnd = el)} />
                        </DialogContent>

                        <DialogActions className={styles.flexbasis}>
                            {defineEvetsState.error && (
                                <FormHelperText
                                    id={`${id}-helper-text`}
                                    error={!!defineEvetsState.error}
                                >
                                    {defineEvetsState.error}
                                </FormHelperText>
                            )}
                            <Grid item xs="auto">
                                <Button id={`${id}-cancel`} onClick={onClose}>
                                    {translate("Cancel")}
                                </Button>
                            </Grid>

                            <Grid item xs="auto">
                                <Button
                                    disabled={this.disabled}
                                    id={`${id}-submit`}
                                    type="submit"
                                    color="primary"
                                >
                                    {translate("Confirm")}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Fragment>
                )}
            </form>
        );
    }
}
