// @flow

import createApiModule from "../create-api-module";
import qs from "qs";
import { type PathParams } from "./organisations.d";

const { reducer, actions } = createApiModule(
    "organisations",
    ({ page, sort }: PathParams) =>
        `ays-searches/searches/organisations?${qs.stringify(
            { page, sort },
            { arrayFormat: "repeat" },
        )}`,
    {
        method: "POST",
    },
);

export const load =
    (page: number, filter: string, sorting: string[], force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(
            actions.load(
                {
                    path: { page: page - 1, sort: sorting },
                    data: { filter },
                },
                force,
            ),
        );
    };

export const ERROR = "No organisations found!";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
