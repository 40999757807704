// @flow
import styles from "./EditEvent.scss";

import React, { useEffect, useState } from "react";

import get from "lodash.get";
import moment from "moment";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";

import { type State as OrganisersState } from "../../../redux/modules/organisers.d";

import AsyncFilteredSelect from "../../AsyncFilteredSelect";
import SelectStatus from "../../SelectStatus";
import { usePrevious } from "../../../hooks";

type Props = {
    translate: *,
    onClose: () => void,
    /** submit */
    onSubmit: (uuid: ?string, show: *) => *,
    event: *,
    /** Disables the form */
    disabled: boolean,
    /** Load Organisers */
    loadOrganisers: *,
    /**  Organisers state */
    organisers: OrganisersState,
    addPromoter: (value: *) => Promise<*>,
    addPromoterState: *,
    editedEventState: *,
    id: string,
    refetchOrganisers: () => Promise<*>,
};

type EventDataType = {
    name?: string,
    promoterUuid?: string,
    end?: *,
    premiumParkingUuid?: string,
    staffParkingUuid?: string,
    start?: *,
    status?: *,
    uuid?: string,
    venueUuid?: string,
    seriesName?: string,
    venueName?: string,
    venueCode?: string,
};

/**
 * EditEvent
 */
const EditEvent = ({
    event,
    editedEventState,
    addPromoterState,
    disabled,
    organisers,
    onSubmit,
    onClose,
    translate,
    id,
    addPromoter,
    editedEventState: { error },
    refetchOrganisers,
}: Props) => {
    const [eventData, setEventData] = useState<EventDataType>({});
    const [currentPromoter, setCurrentPromoter] = useState(null);

    const prevPromoterUuid = usePrevious(eventData.promoterUuid);

    useEffect(() => {
        organisers.data &&
            eventData.promoterUuid &&
            (prevPromoterUuid !== eventData.promoterUuid || !currentPromoter) &&
            setCurrentPromoter(
                organisers.data.find(p => p.uuid === eventData.promoterUuid),
            );
    }, [eventData.promoterUuid, organisers]);

    useEffect(() => {
        event.data && setEventData(event.data);
    }, [event]);

    /**
     * Get errors
     */
    const getError = (key: string) => {
        let transformedKey = key;

        if (
            editedEventState &&
            editedEventState.fieldErrors &&
            editedEventState.fieldErrors
        ) {
            return get(editedEventState.fieldErrors, transformedKey);
        }

        if (
            editedEventState &&
            editedEventState.error &&
            editedEventState.error
        ) {
            return get(editedEventState.error);
        }

        if (addPromoterState.fieldErrors) {
            return get(addPromoterState.fieldErrors, transformedKey);
        }
    };

    /**
     * Disabled getter
     */
    const isDisabled = () =>
        disabled || event.loading || editedEventState.loading;

    /**
     * Submit handler
     */
    const handleSubmit = (event: *) => {
        const {
            end,
            name,
            premiumParkingUuid,
            promoterUuid,
            staffParkingUuid,
            start,
            status,
            venueUuid,
            uuid,
        } = eventData;
        event.preventDefault();

        onSubmit(uuid, {
            end,
            name,
            premiumParkingUuid,
            promoterUuid,
            staffParkingUuid,
            start,
            status,
            venueUuid,
        });
    };

    /**
     * Render
     */

    return (
        <Dialog open>
            <form onSubmit={handleSubmit}>
                {/* Headers */}
                <DialogTitle disableTypography className={styles.title}>
                    <Typography variant="h2" color="inherit">
                        {translate("Edit Event")}
                    </Typography>
                    <Typography color="inherit" style={{ opacity: "0.75" }}>
                        {translate("Edit this event on the calendar:")}
                    </Typography>
                </DialogTitle>
                {/* Purple-Content */}
                <DialogContent className={styles.content}>
                    <Grid container spacing={3} style={{ marginTop: ".4em" }}>
                        <Grid item xs={12}>
                            <TextField
                                id={`${id}-series-name`}
                                label={translate("Series Name")}
                                value={eventData.seriesName || ""}
                                disabled={true}
                                fullWidth
                                className={styles.whiteField}
                                InputProps={{
                                    classes: {
                                        underline: styles.underline,
                                        focused: styles.focused,
                                        error: styles.error,
                                        disabled: styles.disabled,
                                        input: styles.input,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id={`${id}-event-name`}
                                label={translate("Event Name")}
                                value={eventData.name || ""}
                                onChange={event =>
                                    setEventData({
                                        ...eventData,
                                        name: event.target.value,
                                    })
                                }
                                disabled={isDisabled()}
                                fullWidth
                                className={styles.whiteField}
                                InputLabelProps={{
                                    style: { color: "#C8BBD1" },
                                }}
                                InputProps={{
                                    classes: {
                                        underline: styles.underline,
                                        disabled: styles.disabled,
                                        focused: styles.focused,
                                        error: styles.error,
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AsyncFilteredSelect
                                variant={"white"}
                                id={`${id}-promoter`}
                                disabled={isDisabled()}
                                error={
                                    !!(
                                        addPromoterState.error ||
                                        (addPromoterState.fieldErrors &&
                                            addPromoterState.fieldErrors
                                                .name) ||
                                        getError("promoterUuid")
                                    )
                                }
                                helperText={
                                    (!!addPromoterState.error &&
                                        addPromoterState.error) ||
                                    (addPromoterState.fieldErrors &&
                                        addPromoterState.fieldErrors.name) ||
                                    getError("promoterUuid")
                                }
                                currentItem={currentPromoter}
                                label={translate("Organiser")}
                                asyncState={organisers}
                                onSelect={promoter => {
                                    promoter.uuid
                                        ? setEventData({
                                              ...eventData,
                                              promoterUuid: promoter.uuid,
                                          })
                                        : addPromoter(promoter.name).then(
                                              async ({ payload }) => {
                                                  await refetchOrganisers();
                                                  setEventData({
                                                      ...eventData,
                                                      promoterUuid:
                                                          payload.uuid,
                                                  });
                                              },
                                          );
                                }}
                                itemToString={item => (item && item.name) || ""}
                                itemToLabel={item =>
                                    (item && (item.label || item.name)) || ""
                                }
                                filter={(value, items) =>
                                    items
                                        .filter(item =>
                                            (item: any).name
                                                .toLowerCase()
                                                .includes(value.toLowerCase()),
                                        )
                                        .splice(0, 5)
                                }
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: styles.label,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {/* White-content */}
                <DialogContent>
                    <Grid container spacing={3} style={{ marginTop: "0.4em" }}>
                        <Grid item sm={6}>
                            <KeyboardDateTimePicker
                                format={"DD/MM/YYYY, HH:mm"}
                                id={`${id}-show-start`}
                                disabled={isDisabled()}
                                value={moment(eventData.start)}
                                onChange={value =>
                                    setEventData({
                                        ...eventData,
                                        start: value && moment(value.toDate()),
                                    })
                                }
                                label={translate("Start")}
                                error={!!getError("start")}
                                helperText={getError("start")}
                                ampm={false}
                                fullWidth
                                autoOk
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <KeyboardDateTimePicker
                                format={"DD/MM/YYYY, HH:mm"}
                                id={`${id}-show-end`}
                                disabled={isDisabled()}
                                value={
                                    moment(eventData.end) ||
                                    moment(eventData.start)
                                }
                                onChange={value =>
                                    setEventData({
                                        ...eventData,
                                        end: value && moment(value.toDate()),
                                    })
                                }
                                label={translate("End")}
                                error={!!getError("end")}
                                helperText={getError("end")}
                                ampm={false}
                                fullWidth
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id={`${id}-venue-name`}
                                label={translate("Venue")}
                                value={eventData.venueName || ""}
                                error={!!getError("venueName")}
                                helperText={getError("venueName")}
                                disabled={true}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectStatus
                                id={`${id}-status`}
                                error={getError("status")}
                                helperText={getError("status")}
                                label={translate("Status")}
                                fullWidth
                                venueCode={eventData.venueCode || "#298fc2"}
                                optionsToSelect={["PUBLIC", "PRIVATE"]}
                                value={eventData.status ? eventData.status : ""}
                                onSelect={value => {
                                    setEventData({
                                        ...eventData,
                                        status: value.toString(),
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {error && (
                                <FormHelperText
                                    style={{ textAlign: "center" }}
                                    id={`${id}-helper-text`}
                                    color="error"
                                    error
                                >
                                    {error}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        id={`${id}-cancel`}
                        onClick={onClose}
                        disabled={isDisabled()}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id={`${id}-submit`}
                        type="submit"
                        disabled={isDisabled()}
                        color="primary"
                    >
                        {translate("Edit event")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
export default EditEvent;
