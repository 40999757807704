// @flow

import React, { useEffect, useState } from "react";
import Dialog from "../Dialog/Dialog";
import CheckboxGroup from "../CheckboxGroup";
import { CircularProgress, FormControl, FormLabel } from "@material-ui/core";

import { type State as SeriesEvent } from "../../redux/modules/series-events.d";

type Props = {
    translate: *,
    onClose: () => void,
    seriesEvents: SeriesEvent,
    loadSeriesEvents: () => void,
    onSubmit: (selected: string[]) => void,
    loading: boolean,
};

const SaveForSeries = ({
    translate,
    onClose,
    loadSeriesEvents,
    seriesEvents: { loading, data },
    onSubmit,
    loading: detailsLoading,
}: Props) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        loadSeriesEvents();
    }, []);

    return (
        <Dialog
            translate={translate}
            id="dialog-save-details-for-series"
            title={translate("Save For Series")}
            description={translate("Choose one or more events in this series.")}
            submitLabel={translate("Save")}
            onClose={onClose}
            busy={detailsLoading}
            onSubmit={e => onSubmit(e, selected)}
        >
            <FormControl margin="normal" fullWidth>
                <FormLabel>{translate("Other events in this venue")}</FormLabel>
                {loading || !data?.events ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <CheckboxGroup
                        translate={translate}
                        data={data.events.map(event => ({
                            id: event.uuid,
                            label: event.fullDisplayName,
                        }))}
                        onChange={selected => setSelected(selected)}
                        emptyLabel={translate("No events found")}
                    />
                )}
            </FormControl>
        </Dialog>
    );
};

export default SaveForSeries;
