// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qTz7grggjbBc6C5Qvs\\+-pA\\=\\= .JMy2uGHJUC4BmdthFsMAog\\=\\={background-color:#298fc2;color:white}.qTz7grggjbBc6C5Qvs\\+-pA\\=\\= form{max-width:60em}.qTz7grggjbBc6C5Qvs\\+-pA\\=\\= ._3QKFAQ8L6QH6UjVZnUV6aA\\=\\= *{color:white;opacity:0.5}.oEz6POBpIzqofj7Q3YZJEQ\\=\\={margin:auto;margin-top:2em}\n", "",{"version":3,"sources":["webpack://./../ConfirmOptions.scss"],"names":[],"mappings":"AAEA,yDAEQ,wBAAyB,CACzB,WAAY,CAHpB,kCAOQ,cAAe,CAPvB,4DAYY,WAAY,CACZ,WAAY,CACf,4BAIL,WAAY,CACZ,cAAe","sourcesContent":["@import \"../../../styles/variables\";\n\n.element {\n    .title {\n        background-color: #298fc2;\n        color: white;\n    }\n\n    form {\n        max-width: 60em;\n    }\n\n    .disabledInput {\n        * {\n            color: white;\n            opacity: 0.5;\n        }\n    }\n}\n.spinner {\n    margin: auto;\n    margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "qTz7grggjbBc6C5Qvs+-pA==",
	"title": "JMy2uGHJUC4BmdthFsMAog==",
	"disabledInput": "_3QKFAQ8L6QH6UjVZnUV6aA==",
	"spinner": "oEz6POBpIzqofj7Q3YZJEQ=="
};
export default ___CSS_LOADER_EXPORT___;
