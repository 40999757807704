// @flow

import styles from "./ProfilePic.scss";

import classnames from "classnames";
import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";

type Props = {
    image?: ?string,
};

const FORMAT = "64x64.png";

/**
 * ProfilePic component
 */
const ProfilePic = ({ image }: Props) =>
    image ? (
        <Avatar
            src={`${image}/${FORMAT}`}
            className={classnames(styles.override, styles.avatar)}
        />
    ) : (
        <Person className={classnames(styles.override, styles.person)} />
    );

export default ProfilePic;
