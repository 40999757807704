// @flow

// Import styles
import styles from "../PDFGenerator.scss";
import logo from "../../../logo.svg";
// Import libs
import React, { Component } from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { type AttendeeQRCode } from "../../../redux/modules/attendees-qrcode.d";
import moment from "moment";
import QRCode from "qrcode.react";

// Define props
type PropsType = {
    data: AttendeeQRCode,
    translate: *,
};

/**
 * A component that generates a pdf
 */
export default class AttendeePDF extends Component<PropsType> {
    /**
     * Render
     */
    render() {
        const { data, translate } = this.props;
        return (
            <Paper id="pdf" className={classnames(styles.pdf)} elevation={2}>
                <div className={classnames(styles.header)}>
                    <img src={logo} />
                    {data ? (
                        <div>
                            <Typography
                                color="inherit"
                                className={classnames(styles.title)}
                            >
                                {data.eventName}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classnames(styles.time)}
                            >
                                {moment(data.eventStart).format(
                                    "ddd D MMM YYYY",
                                )}
                            </Typography>
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
                <div className={classnames(styles.content)}>
                    <Typography
                        variant="body1"
                        color="inherit"
                        className={classnames(styles.subtitle)}
                    >
                        Member Details
                    </Typography>
                    {data ? (
                        <div className={classnames(styles.container)}>
                            <div className={classnames(styles.left)}>
                                <div>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Name:
                                        </span>
                                        {`${data.lastName} ${data.firstName}`}
                                    </Typography>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Phone number:
                                        </span>
                                        {data.phoneNumber}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classnames(styles.right)}>
                                <img
                                    src={`${data.profilePicturePath}/280x280.png`}
                                    alt="profilepicture"
                                />
                            </div>
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                    <br />
                    <Typography
                        variant="body1"
                        color="inherit"
                        className={classnames(styles.subtitle)}
                    >
                        Event Details
                    </Typography>
                    {data ? (
                        <div className={classnames(styles.container)}>
                            <div className={classnames(styles.left)}>
                                <div>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Venue:
                                        </span>
                                        {data.venueName}
                                    </Typography>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Series name:
                                        </span>
                                        {data.seriesName}
                                    </Typography>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Event name:
                                        </span>
                                        {data.eventName}
                                    </Typography>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Event start:
                                        </span>
                                        {moment(data.eventStart).format(
                                            "ddd D MMM YYYY, H:mm",
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classnames(styles.item)}
                                    >
                                        <span
                                            className={classnames(styles.key)}
                                        >
                                            Event end:
                                        </span>
                                        {moment(data.eventEnd).format(
                                            "ddd D MMM YYYY, H:mm",
                                        )}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classnames(styles.right)}>
                                <QRCode
                                    value={data.qrCodeData}
                                    size={175}
                                    className={classnames(styles.qrcode)}
                                />
                            </div>
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                    {data ? (
                        data.eventLines &&
                        data.eventLines.map((item, index) => {
                            return (
                                <div key={index}>
                                    <br />
                                    <Typography
                                        variant="body1"
                                        color="inherit"
                                        className={classnames(styles.subtitle)}
                                    >
                                        {item.eventLineName}
                                    </Typography>
                                    <div
                                        className={classnames(styles.container)}
                                    >
                                        <div
                                            className={classnames(styles.left)}
                                        >
                                            <div>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Organisation:
                                                    </span>
                                                    {item.organisationName}
                                                </Typography>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Team leader:
                                                    </span>
                                                    {item.teamLeader
                                                        ? translate("Yes")
                                                        : translate("No")}
                                                </Typography>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Type:
                                                    </span>
                                                    {item.teamMemberType}
                                                </Typography>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Line start:
                                                    </span>
                                                    {moment(
                                                        item.eventLineStart,
                                                    ).format(
                                                        "ddd D MMM YYYY, H:mm",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Line end:
                                                    </span>
                                                    {moment(
                                                        item.eventLineEnd,
                                                    ).format(
                                                        "ddd D MMM YYYY, H:mm",
                                                    )}
                                                </Typography>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Briefing:
                                                    </span>
                                                    {item.briefing}
                                                </Typography>
                                                <Typography
                                                    className={classnames(
                                                        styles.item,
                                                    )}
                                                >
                                                    <span
                                                        className={classnames(
                                                            styles.key,
                                                        )}
                                                    >
                                                        Prebriefing:
                                                    </span>
                                                    {item.preBriefing}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <CircularProgress />
                    )}
                </div>
            </Paper>
        );
    }
}
