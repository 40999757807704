// @flow

import React from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";

const style = {
    border: "0.06em solid #888",
    borderRadius: "0.1em",
    height: ".6em",
    width: ".65em",
};

const Expand = () => <ChevronRight style={style} />;

export default Expand;
