// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "addStaffingTemplate",
    () => "ays-venue-facility-management/staffing-templates",
    { method: "POST" },
);

export const load =
    (name: string, venue: string, lineType: string) => (dispatch: *) =>
        dispatch(actions.load({ data: { name, venue, lineType } }));

export const ERROR = "Couldn't add staffing template!";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
