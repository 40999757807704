// @flow

import React from "react";
import {
    Switch,
    Route,
    Redirect,
    type Match,
    type RouterHistory,
    withRouter,
} from "react-router";

import stripslash from "../../utils/stripslash";

import Detail from "./Detail";
import List from "./List";

type Props = {
    match: Match,
    history: RouterHistory,
};

const Organisations = ({ match, history }: Props) => (
    <Switch>
        <Route path={match.path} exact>
            <Redirect to={`${stripslash(match.url)}/page/1/`} />
        </Route>
        <Route path={`${match.path}page/:page/`}>
            <List
                onSelect={uuid =>
                    history.push(`${stripslash(match.url)}/${uuid}/`)
                }
                onPageChange={page =>
                    history.push(`${stripslash(match.url)}/page/${page}/`)
                }
            />
        </Route>
        <Route path={`${match.path}:id/`}>
            <Detail />
        </Route>
    </Switch>
);

export default withRouter<any>(Organisations);
