//@flow
import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";
import CheckBox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import styles from "./LineList.scss";
import VenueBox from "../../VenueBox/VenueBox";
import Table from "../../Table/Table";

import {
    type State,
    type OrganisationLinesLine,
} from "../../../redux/modules/organisationlines.d";
import type { State as DefaultOrganisationState } from "../../../redux/modules/event-line-default-organisation.d";
import type { State as DetailsState } from "../../../redux/modules/details.d";
import type { Organisation } from "../../../redux/modules/event-line-default-organisation.d";
import FabContainer from "../../FabContainer/FabContainer";

type OnDeleteLine = (uuid: string) => *;

type ModalProps = {
    translate: *,
    onClose: () => void,
    onSubmit: () => void,
    eventLineDefaultOrganisation: DefaultOrganisationState,
    line: ?OrganisationLinesLine,
};

type Props = {
    translate: *,
    lines: State,
    onAdd: () => void,
    onPageChange: (n: number) => void,
    onDeleteLine: OnDeleteLine,
    eventLineDefaultOrganisation: DefaultOrganisationState,
    onGetDefaultOrganisation: (
        lineUUID: string,
        organsationUUID: string,
    ) => Promise<Organisation>,
    onSetDefaultOrganisation: (
        lineUUID: string,
        organsationUUID: ?string,
    ) => Promise<void>,
    organisationDetails: DetailsState,
    onSortChange: (sortString: string[]) => void,
    canEdit: boolean,
    filter: string,
};

type StateType = {
    showDefaultOrganisationDialog: boolean,
    lineToChange: ?OrganisationLinesLine,
};

const DefaultOrganisationDialog = ({
    translate,
    onSubmit,
    eventLineDefaultOrganisation,
    onClose,
    line,
}: ModalProps) => (
    <Dialog onClose={onClose} open>
        <DialogTitle disableTypography>
            <Typography variant="h2">{translate("Default")}</Typography>
            {eventLineDefaultOrganisation &&
            eventLineDefaultOrganisation.data &&
            eventLineDefaultOrganisation.data.organisationName ? (
                <Typography>
                    {translate(
                        "Are you sure you want to change the default organisation of %{line} from %{organisation}?",
                        {
                            line: line ? line.lineName : "",
                            organisation: eventLineDefaultOrganisation.data
                                .organisationName
                                ? eventLineDefaultOrganisation.data
                                      .organisationName
                                : "",
                        },
                    )}
                </Typography>
            ) : (
                <Typography>
                    {translate(
                        "Are you sure you want to change the default organisation of %{line}?",
                        {
                            line: line ? line.lineName : "",
                        },
                    )}
                </Typography>
            )}
        </DialogTitle>
        <DialogActions>
            <Button id="modal-defaultorganisation-cancel" onClick={onClose}>
                {translate("Cancel")}
            </Button>
            <Button
                id="modal-defaultorganisation-confirm"
                type="submit"
                color="primary"
                onClick={onSubmit}
            >
                {translate("Set default organisation")}
            </Button>
        </DialogActions>
    </Dialog>
);

export default class LineList extends Component<Props, StateType> {
    state = {
        showDefaultOrganisationDialog: false,
        lineToChange: null,
    };

    /**
     * Get lines or empty array
     */
    get lines(): OrganisationLinesLine[] {
        const { lines } = this.props;

        if (lines.data) {
            return lines.data.content;
        }

        return [];
    }

    /**
     * Change page
     */
    handleChangePage(_: *, page: number) {
        const { onPageChange } = this.props;
        onPageChange(page + 1);
    }

    handleChangeDefaultOrganisation = (line: OrganisationLinesLine) => {
        const { onGetDefaultOrganisation, organisationDetails } = this.props;

        if (organisationDetails.data) {
            const organisationUuid = organisationDetails.data.uuid;

            onGetDefaultOrganisation(line.lineUuid, organisationUuid).then(
                (existingOrganisation: Organisation) => {
                    if (
                        !existingOrganisation ||
                        !existingOrganisation.organisationUuid
                    ) {
                        this.handleSetDefaultOrganisation(
                            line.lineUuid,
                            organisationUuid,
                        );
                    } else if (
                        existingOrganisation.organisationUuid ===
                        organisationUuid
                    ) {
                        this.handleSetDefaultOrganisation(line.lineUuid, null);
                    } else {
                        this.setState({
                            showDefaultOrganisationDialog: true,
                            lineToChange: line,
                        });
                    }
                },
            );
        }
    };

    handleSetDefaultOrganisation = (
        lineUUID: string,
        organisationUUID: ?string,
    ) => {
        const { onSetDefaultOrganisation } = this.props;
        onSetDefaultOrganisation(lineUUID, organisationUUID);
        this.setState({
            showDefaultOrganisationDialog: false,
            lineToChange: null,
        });
    };

    /**
     * Render
     */
    render() {
        const {
            translate,
            canEdit,
            lines,
            onAdd,
            eventLineDefaultOrganisation,
            organisationDetails,
            onDeleteLine,
            onPageChange,
            onSortChange,
            filter,
        } = this.props;
        const { showDefaultOrganisationDialog, lineToChange } = this.state;
        const organisationUuid = organisationDetails.data
            ? organisationDetails.data.uuid
            : null;

        const config = {
            id: "preferred-lines",
            uuidKey: "lineUuid",
            hasMenu: true,
            onClick: item => {
                item;
            },
            configItems: [
                {
                    key: "lineName",
                    displayName: translate("Name"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                    defaultSort: "asc",
                },
                {
                    key: "lineTypeName",
                    displayName: translate("Type"),
                    sortable: true,
                    permission: true,
                    isNumeric: false,
                },
                {
                    key: "venueCode",
                    customRender: (item: Object, index: number) => {
                        return item.venues.map(venue => {
                            return (
                                <VenueBox
                                    key={`${venue.uuid}-${index}`}
                                    venue={venue}
                                    color={"#298fc2"}
                                />
                            );
                        });
                    },
                    displayName: translate("Venue"),
                    sortable: true,
                    permission: true,
                    isNumeric: false,
                },
                {
                    key: "minOccupation",
                    displayName: translate("Min. Staff"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "maxOccupation",
                    displayName: translate("Max. Staff"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
                {
                    key: "isDefault",
                    customRender: (item: Object, index: number) => {
                        return (
                            <CheckBox
                                id={`preferred-lines-checkbox-${index}`}
                                checked={item.default}
                                disabled={!canEdit}
                                onChange={() => {
                                    this.handleChangeDefaultOrganisation(item);
                                }}
                            />
                        );
                    },
                    displayName: translate("Default"),
                    sortable: true,
                    isNumeric: false,
                    permission: true,
                },
            ],
            menuOptions: [
                {
                    id: `preferred-lines-item-delete`,
                    label: () => translate("Remove as preferred line"),
                    onClick: item => onDeleteLine(item.lineUuid),
                    permission: () => true,
                },
            ],
        };

        return (
            <div className={styles.main}>
                <Table
                    config={config}
                    loading={lines.loading}
                    data={lines.data}
                    page={lines.page}
                    error={lines.error}
                    translate={translate}
                    getSortString={string => onSortChange(string)}
                    onPageChange={p => onPageChange(p + 1)}
                    filter={filter}
                />
                <FabContainer>
                    <Fab
                        variant="extended"
                        color="primary"
                        id="preferred-lines-add"
                        onClick={onAdd}
                        disabled={lines.loading}
                    >
                        <AddIcon />
                        {translate("Add preferred line")}
                    </Fab>
                </FabContainer>
                {lines.loading && (
                    <div className={styles.spinner}>
                        <CircularProgress />
                    </div>
                )}
                {showDefaultOrganisationDialog && (
                    <DefaultOrganisationDialog
                        translate={translate}
                        eventLineDefaultOrganisation={
                            eventLineDefaultOrganisation
                        }
                        onClose={() =>
                            this.setState({
                                showDefaultOrganisationDialog: false,
                                lineToChange: null,
                            })
                        }
                        onSubmit={() => {
                            if (lineToChange && lineToChange.lineUuid) {
                                this.handleSetDefaultOrganisation(
                                    lineToChange.lineUuid,
                                    organisationUuid,
                                );
                            }
                        }}
                        line={lineToChange}
                    />
                )}
            </div>
        );
    }
}
