// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rh2eUurAuCrI7iJA6luZeg\\=\\={margin:2em;border-radius:0.25em}.lRVDJj7H3sEB-3aWVMXjHg\\=\\={clear:both;overflow:hidden}._2K5qqwYn7rYfvSggfUWWyg\\=\\={float:right;padding-top:1em;overflow:hidden}.e4iyUyOwmRU0OqEsrWUvlw\\=\\={margin:1em;display:block;height:1em}.JAp4p1XpG3jZTxLE6ASTVw\\=\\={display:block;height:0.5em}.Dgv5tGXEqO5lQTxlHEgH8g\\=\\={text-align:center}.umgfDaYc6afv5cnfx0mXYA\\=\\={position:relative;margin-top:1.1em !important}.umgfDaYc6afv5cnfx0mXYA\\=\\= .JHJDSfNondpjE11bg8gdiQ\\=\\={position:absolute;top:-1em;right:0.5em}\n", "",{"version":3,"sources":["webpack://./../EventLineDetails.scss"],"names":[],"mappings":"AAMA,4BACI,UAAW,CACX,oBAAqB,CACxB,4BAGG,UAAW,CACX,eAAgB,CACnB,6BAEG,WAAY,CACZ,eAAgB,CAChB,eAAgB,CACnB,4BAGG,UAAW,CACX,aAAc,CACd,UAAW,CACd,4BAGG,aAAc,CACd,YAAa,CAChB,4BAGG,iBAAkB,CACrB,4BAGG,iBAAkB,CAClB,2BAA4B,CAFhC,wDAKQ,iBAAkB,CAClB,QAAS,CACT,WAAY","sourcesContent":["/**\n * Detailsform styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    margin: 2em;\n    border-radius: 0.25em;\n}\n\n.form {\n    clear: both;\n    overflow: hidden;\n}\n.actionButtons {\n    float: right;\n    padding-top: 1em;\n    overflow: hidden;\n}\n\n.divider {\n    margin: 1em;\n    display: block;\n    height: 1em;\n}\n\n.titleDivider {\n    display: block;\n    height: 0.5em;\n}\n\n.spinner {\n    text-align: center;\n}\n\n.briefingContainer {\n    position: relative;\n    margin-top: 1.1em !important;\n\n    .notificationCheckbox {\n        position: absolute;\n        top: -1em;\n        right: 0.5em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Rh2eUurAuCrI7iJA6luZeg==",
	"form": "lRVDJj7H3sEB-3aWVMXjHg==",
	"actionButtons": "_2K5qqwYn7rYfvSggfUWWyg==",
	"divider": "e4iyUyOwmRU0OqEsrWUvlw==",
	"titleDivider": "JAp4p1XpG3jZTxLE6ASTVw==",
	"spinner": "Dgv5tGXEqO5lQTxlHEgH8g==",
	"briefingContainer": "umgfDaYc6afv5cnfx0mXYA==",
	"notificationCheckbox": "JHJDSfNondpjE11bg8gdiQ=="
};
export default ___CSS_LOADER_EXPORT___;
