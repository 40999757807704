// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gjjf1xC8CM3NJ6ypnZBVwQ\\=\\={width:100%}.Gjjf1xC8CM3NJ6ypnZBVwQ\\=\\=>:first-child{margin:2em 6em;border-radius:0.25em}.aj9w1HxBXmCYxzlcwhnh0A\\=\\={display:flex;align-items:center}.p\\+5sGIsilbIvhn0QVyWRzQ\\=\\={display:block;margin-right:0.5em;border-radius:50%;width:0.6em;height:0.6em;background-color:#56c271}._8jrzklNIr79lxMeqFvHGIA\\=\\={display:block;margin-right:0.5em;border-radius:50%;width:0.6em;height:0.6em;background-color:#888}.N\\+KrLcgPLaC6YRpEI5kd-w\\=\\={text-align:center}.VrtNYj0OD2Z5P72dEN4NAg\\=\\={width:2em;height:2em;border-radius:50%;margin-right:0.5em}.Dt94DbdFZtuO8BgdV4vREA\\=\\={display:flex;align-items:center}\n", "",{"version":3,"sources":["webpack://./../UserList.scss"],"names":[],"mappings":"AAMA,4BACI,UAAW,CADf,yCAGQ,cAAe,CACf,oBAAqB,CACxB,4BAGD,YAAa,CACb,kBAAmB,CACtB,6BAEG,aAAc,CACd,kBAAmB,CACnB,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,wBAAyB,CAC5B,6BAEG,aAAc,CACd,kBAAmB,CACnB,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,qBAAsB,CACzB,6BAEG,iBAAkB,CACrB,4BAEG,SAAU,CACV,UAAW,CACX,iBAAkB,CAClB,kBAAmB,CACtB,4BAEG,YAAa,CACb,kBAAmB","sourcesContent":["/**\n * EVENTLIST styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    width: 100%;\n    & > :first-child {\n        margin: 2em 6em;\n        border-radius: 0.25em;\n    }\n}\n.status {\n    display: flex;\n    align-items: center;\n}\n.verified {\n    display: block;\n    margin-right: 0.5em;\n    border-radius: 50%;\n    width: 0.6em;\n    height: 0.6em;\n    background-color: #56c271;\n}\n.pending {\n    display: block;\n    margin-right: 0.5em;\n    border-radius: 50%;\n    width: 0.6em;\n    height: 0.6em;\n    background-color: #888;\n}\n.spinner {\n    text-align: center;\n}\n.avatar {\n    width: 2em;\n    height: 2em;\n    border-radius: 50%;\n    margin-right: 0.5em;\n}\n.user {\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Gjjf1xC8CM3NJ6ypnZBVwQ==",
	"status": "aj9w1HxBXmCYxzlcwhnh0A==",
	"verified": "p+5sGIsilbIvhn0QVyWRzQ==",
	"pending": "_8jrzklNIr79lxMeqFvHGIA==",
	"spinner": "N+KrLcgPLaC6YRpEI5kd-w==",
	"avatar": "VrtNYj0OD2Z5P72dEN4NAg==",
	"user": "Dt94DbdFZtuO8BgdV4vREA=="
};
export default ___CSS_LOADER_EXPORT___;
