// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rsyqDG-Bfl1-FqAyG0LhkQ\\=\\={background-color:transparent}\n", "",{"version":3,"sources":["webpack://./../Calendar.scss"],"names":[],"mappings":"AAEA,4BACI,4BAA6B","sourcesContent":["@import \"../../../styles/variables\";\n\n.element {\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "rsyqDG-Bfl1-FqAyG0LhkQ=="
};
export default ___CSS_LOADER_EXPORT___;
