//@flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "details",
    ({ uuid }) => `/ays-organisation-management/organisations/${uuid}`,
);

export const load = (uuid: string) => (dispatch: *) =>
    dispatch(
        actions.load({
            path: {
                uuid,
            },
        }),
    );
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "No details found!";
export default reducer;
