// @flow

//import styles from "./List.scss";

import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import _List from "@material-ui/core/List";

import {
    type State as OrganisationState,
    type Organisation as OrganisationType,
} from "../../../redux/modules/organisations.d";

import Organisation from "./Organisation";

// Define props
type PropsType = {
    busy?: ?boolean,
    organisations: OrganisationState,
    preferred: string[],
    excluded: string[],
    onClick: (selected: string) => void,
};

/**
 * A preferredlineslist component
 */
export default class List extends Component<PropsType> {
    /**
     * Get the preferred organisations
     */
    get preferred(): OrganisationType[] {
        const { organisations } = this.props;

        if (organisations.data) {
            return organisations.data.content.filter(
                organisation =>
                    this.isPreferred(organisation) &&
                    !this.isExcluded(organisation),
            );
        }

        return [];
    }

    /**
     * Get the regular ones
     */
    get regular(): OrganisationType[] {
        const { organisations } = this.props;

        if (organisations.data) {
            return organisations.data.content.filter(
                organisation =>
                    !this.isPreferred(organisation) &&
                    !this.isExcluded(organisation),
            );
        }

        return [];
    }

    /**
     * Test if current line is selected
     */
    isPreferred(organisation: OrganisationType) {
        const { preferred } = this.props;
        return preferred.includes(organisation.organisationUuid);
    }

    /**
     * Test if is excluded
     */
    isExcluded(organisation: OrganisationType) {
        const { excluded } = this.props;
        return excluded.includes(organisation.organisationUuid);
    }

    /**
     * Handle a line toggle
     */
    handleClick(uuid: string) {
        const { onClick } = this.props;
        onClick(uuid);
    }

    /**
     * Render
     */
    render() {
        const { organisations, busy } = this.props;
        return (
            <_List>
                {this.preferred.map(organisation => (
                    <Organisation
                        busy={busy}
                        key={organisation.organisationUuid}
                        organisation={organisation}
                        preferred={this.isPreferred(organisation)}
                        onClick={() =>
                            this.handleClick(organisation.organisationUuid)
                        }
                    />
                ))}
                <Divider />
                {this.regular.map(organisation => (
                    <Organisation
                        busy={busy}
                        key={organisation.organisationUuid}
                        organisation={organisation}
                        preferred={this.isPreferred(organisation)}
                        onClick={() =>
                            this.handleClick(organisation.organisationUuid)
                        }
                    />
                ))}
                {organisations.loading && <CircularProgress />}
            </_List>
        );
    }
}
