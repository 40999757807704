// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UJE4DX5XllbHU-zLJCdoZA\\=\\={background-color:#ee3124;padding:1em;display:flex;justify-content:space-evenly;justify-items:center;color:#fff;font-weight:600;font-size:1.25em}.UJE4DX5XllbHU-zLJCdoZA\\=\\= span{margin:auto 0 auto 0}\n", "",{"version":3,"sources":["webpack://./../PlanableBanner.scss","webpack://./../../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,4BACI,wBAAyB,CACzB,WAAY,CACZ,YAAa,CACb,4BAA6B,CAC7B,oBAAqB,CACrB,UCJQ,CDKR,eAAgB,CAChB,gBAAiB,CACpB,iCAGG,oBAAqB","sourcesContent":["@import \"../../../styles/variables\";\n\n.planable {\n    background-color: #ee3124;\n    padding: 1em;\n    display: flex;\n    justify-content: space-evenly;\n    justify-items: center;\n    color: $white;\n    font-weight: 600;\n    font-size: 1.25em;\n}\n\n.planable span {\n    margin: auto 0 auto 0;\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planable": "UJE4DX5XllbHU-zLJCdoZA=="
};
export default ___CSS_LOADER_EXPORT___;
