// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-details-update.d";

const { reducer, actions } = createApiModule(
    "eventDetailsUpdate",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/events/${eventUuid}`,
    {
        method: "PUT",
    },
);

export const load = (eventUuid: string, show: *) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { eventUuid },
            data: show,
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not update event";
/** REDUCER **/
export default reducer;
