import api from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../../../node_modules/resolve-url-loader/index.js!../../../../../node_modules/postcss-loader/dist/cjs.js??ref--6-3!../../../../../node_modules/sass-loader/dist/cjs.js??ref--6-4!./index.style.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};