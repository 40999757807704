// @flow

import React, { Component, Fragment } from "react";
/** redux container */
import { connect } from "react-redux";
import { withRouter } from "react-router";
import stripslash from "../../../utils/stripslash";
// actions creator
import {
    load as findOrganisations,
    ERROR as ORGANISATIONS_ERROR,
} from "../../../redux/modules/organisations";
import { load, clear, reload } from "../../../redux/modules/event-recruitment";
import {
    load as add,
    ERROR as ADD_POOL_ERROR,
} from "../../../redux/modules/event-recruitment-add";
import {
    load as remove,
    ERROR as DELETE_RECRUITMENT_ERROR,
} from "../../../redux/modules/event-recruitment-delete";
import {
    load as update,
    ERROR as SHOW_RECRUITMENT_UPDATE_ERROR,
} from "../../../redux/modules/event-recruitment-update";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
/** recruitment component */
// types
import { type Match, type RouterHistory } from "react-router";
import {
    type Pool as PoolType,
    type State as PoolsState,
} from "../../../redux/modules/event-recruitment.d";

import { type State as AddPoolState } from "../../../redux/modules/event-recruitment-add.d";
import {
    type State as UpdatePoolState,
    type Pool as PoolFields,
} from "../../../redux/modules/event-recruitment-update.d";
import { type State as DeletePoolState } from "../../../redux/modules/event-recruitment-delete.d";
import { type State as showDetailsState } from "../../../redux/modules/event-details.d";
import { type State as OrganisationState } from "../../../redux/modules/organisations.d";
import { mapState } from "../../../redux/utils";
// components
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Pool from "./Pool";

import { AddGroup, Pools } from "../../../components/Events/Recruitment";

type Props = {
    /** A show uuid */
    uuid: string,
    /** The show name */
    showName: string,
    /** clear the pools */
    clearPools: () => *,
    /** load the pools */
    loadPools: () => *,
    /** Add a pool */
    addPool: () => *,
    /** Adding state */
    addPoolState: AddPoolState,
    /** Delete a pool */
    deletePool: (uuid: string) => *,
    /** Delete pool state */
    deleteState: DeletePoolState,
    /** route matching */
    match: Match,
    /** Pools state */
    pools: PoolsState,
    /** Acl */
    acl: *,
    /** Translation */
    translate: *,
    /** Root uri */
    root: string,
    /** history api */
    history: RouterHistory,
    /** Organisation state */
    organisations: OrganisationState,
    /** show details state */
    eventDetails: showDetailsState,
    /** Find organisations */
    findOrganisations: *,
    /** Update state */
    updatePoolState: UpdatePoolState,
    /** disabled */
    disabled: boolean,
    /** update callback */
    updatePool: (uuid: string, data: PoolFields) => *,
    planable: boolean,
};

type State = {
    adding: boolean,
    selectedItem: string,
};

class Recruitment extends Component<Props, State> {
    // Initial state
    state = {
        adding: false,
        selectedItem: "",
    };

    selectFirstLine(uuid: string) {
        const { selectedItem } = this.state;
        const { history, root } = this.props;
        if (selectedItem === "" && uuid !== "undefined") {
            this.setState(
                {
                    selectedItem: uuid,
                },
                () => {
                    history.push(`${stripslash(root)}/${uuid}/`);
                },
            );
        }
    }

    /**
     * On component mount
     */
    componentDidMount() {
        const { clearPools, loadPools } = this.props;
        clearPools();
        loadPools();
    }

    /**
     * On component update
     */
    componentDidUpdate() {
        const { loadPools } = this.props;
        loadPools();
    }

    componentWillUnmount() {
        const { clearPools } = this.props;
        clearPools();
    }

    /**
     * Render
     */
    render() {
        const {
            uuid,
            match,
            pools,
            acl,
            translate,
            root,
            history,
            addPool,
            deletePool,
            showName,
            addPoolState,
            deleteState,
            organisations,
            findOrganisations,
            updatePoolState,
            clearPools,
            updatePool,
            eventDetails,
            disabled,
            planable,
        } = this.props;

        const { adding } = this.state;

        if (!pools.uuid || (!pools.data && pools.loading)) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <CircularProgress />
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        if (!pools.data && pools.error) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center" color="error">
                            {pools.error}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        const poolsData: PoolType[] = (pools.data: any);
        const selectedPool: ?PoolType = poolsData.find(
            pool => pool.uuid === match.params.selected,
        );

        return (
            <Fragment key={uuid}>
                <Grid container>
                    <Grid item sm={4}>
                        <Pools
                            selected={match.params.selected}
                            pools={poolsData}
                            onAdd={() => this.setState({ adding: true })}
                            onSelect={uuid => history.push(`${root}${uuid}/`)}
                            onDelete={deletePool}
                            selectFirst={uuid => this.selectFirstLine(uuid)}
                            disabled={disabled}
                            acl={acl}
                            clearPools={clearPools}
                            translate={translate}
                            deleteState={deleteState}
                            planable={planable}
                        />
                    </Grid>
                    <Grid item sm={8}>
                        {selectedPool && (
                            <Pool
                                key={selectedPool.uuid}
                                uuid={selectedPool.uuid}
                                organisationUuid={selectedPool.organisationUuid}
                                id="event-recruitment-pool"
                                pool={selectedPool}
                                acl={acl}
                                translate={translate}
                                onSave={data =>
                                    updatePool(selectedPool.uuid, data)
                                }
                                editState={updatePoolState}
                                isExpired={disabled}
                                disabled={pools.loading}
                            />
                        )}
                    </Grid>
                </Grid>
                {adding && (
                    <AddGroup
                        id="event-recruitment-add"
                        showName={showName}
                        translate={translate}
                        disabled={!pools.uuid || !pools.data || pools.loading}
                        onClose={() => this.setState({ adding: false })}
                        onSubmit={addPool}
                        startDate={
                            eventDetails
                                ? eventDetails.data
                                    ? eventDetails.data.start
                                        ? eventDetails.data.start
                                        : null
                                    : null
                                : null
                        }
                        endDate={
                            eventDetails
                                ? eventDetails.data
                                    ? eventDetails.data.end
                                        ? eventDetails.data.end
                                        : null
                                    : null
                                : null
                        }
                        asyncState={addPoolState}
                        organisations={organisations}
                        findOrganisations={findOrganisations}
                    />
                )}
            </Fragment>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                // Map state to props
                ({
                    eventRecruitment: pools,
                    showRecruitmentAdd: addPoolState,
                    eventDetails,
                    organisations,
                    showRecruitmentUpdate: updatePoolState,
                    showRecruitmentDelete: deleteState,
                }) => ({
                    pools: pools.toJS(),
                    addPoolState: mapState(addPoolState, ADD_POOL_ERROR),
                    organisations: mapState(organisations, ORGANISATIONS_ERROR),
                    eventDetails,
                    deleteState: mapState(
                        deleteState,
                        DELETE_RECRUITMENT_ERROR,
                    ),
                    updatePoolState: mapState(
                        updatePoolState,
                        SHOW_RECRUITMENT_UPDATE_ERROR,
                    ),
                }),
                // Map dispatch to props
                (dispatch: *, { uuid }) => ({
                    loadPools: () => dispatch(load(uuid)),
                    clearPools: () => dispatch(clear()),
                    addPool: data =>
                        dispatch(add(uuid, data)).then(() =>
                            dispatch(reload()),
                        ),
                    deletePool: uuid =>
                        dispatch(remove(uuid)).then(() => dispatch(reload())),
                    findOrganisations: filter =>
                        dispatch(findOrganisations(1, filter, [])),
                    updatePool: (poolUuid, data) =>
                        dispatch(update(poolUuid, data)).then(() =>
                            dispatch(reload()),
                        ),
                }),
            )(Recruitment),
        ),
    ),
);
