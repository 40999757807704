// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, type Match, type RouterHistory } from "react-router";

import { load, update } from "../../../redux/modules/member";
import { type State as MemberShipState } from "../../../redux/modules/member.d";

import _EditMember from "../../../components/Modals/EditMember/EditMember";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    history: RouterHistory,
    load: (uuid: string) => void,
    update: (
        description: string,
        noSmartphone: boolean,
        userFirstName: string,
        userLastName: string,
    ) => *,
    member: MemberShipState,
    onBack: () => void,
    uuid?: string,
    disabled?: boolean,
};

class EditMember extends Component<Props> {
    /**
     * Initial load
     */
    componentDidMount() {
        this.reload();
    }

    /**
     * Reload on props change
     */
    componentDidUpdate() {
        this.reload();
    }

    /**
     * Reload
     */
    reload() {
        const {
            match: {
                params: { id },
            },
            load,
            uuid,
        } = this.props;

        if (id && !uuid) {
            load(id);
        } else {
            if (uuid) {
                load(uuid);
            }
        }
    }

    /**
     * Render
     */
    render() {
        const { acl, member, update, onBack, translate, disabled } = this.props;

        return (
            <_EditMember
                disabled={
                    disabled ? disabled : !acl("organisations.members.update")
                }
                translate={translate}
                member={member}
                onSubmit={(
                    description,
                    noSmartphone,
                    userFirstName,
                    userLastName,
                ) =>
                    update(
                        description,
                        noSmartphone,
                        userFirstName,
                        userLastName,
                    ).then(() => onBack())
                }
                onClose={onBack}
            />
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ member }) => ({
                    member: member.toJS(),
                }),
                (dispatch: *) => bindActionCreators({ load, update }, dispatch),
            )(EditMember),
        ),
    ),
);
