// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-line-add.d";

const { reducer, actions } = createApiModule(
    "eventLineAdd",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/events/${eventUuid}/event-lines`,
    {
        method: "POST",
    },
);

export const load =
    (eventUuid: string, lineUuids: String[]) => (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { eventUuid },
                data: {
                    lineUuids,
                },
            }),
        );
    };

export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not create show lines!";
export default reducer;
