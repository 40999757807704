// @flow

/**
 * Module: Add Show
 */

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "eventRecruitsUpdate",
    ({ uuid }: *) => `/ays-series-event-management/recruits/${uuid}`,
    {
        method: "PUT",
    },
);

export const load = (uuid: string, staffingStatus: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
            data: { staffingStatus },
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not update recruit!";
/** REDUCER **/
export default reducer;
