// @flow

import React, { Component } from "react";
import classnames from "classnames";
// Import styles
import styles from "./AttendeePDFModal.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import { type State as AttendeeQRState } from "../../../redux/modules/attendees-qrcode.d";

type Props = {
    translate: *,
    disabled: boolean,
    attendee: AttendeeQRState,
    onClose: () => void,
};

export default class AttendeePDFModal extends Component<Props> {
    /**
     * Render
     */
    render() {
        const { onClose, attendee, translate, disabled } = this.props;
        return (
            <Dialog
                onClose={onClose}
                open
                maxWidth="md"
                className={classnames(styles.wrapper)}
            >
                <DialogTitle disableTypography>
                    <Typography variant="h2">{translate("Preview")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <PDFGenerator
                        type="ATTENDEE"
                        disabled={disabled}
                        loading={attendee.loading}
                        attendeeQRCode={attendee.data}
                        translate={translate}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}
