// @flow

import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/AddCircle";

// components
import Table from "../../Table/Table";
import VenueBox from "../../VenueBox/VenueBox";
import { type Acl } from "../../../containers/Acl";

// styles
import styles from "./style.module.scss";
import FabContainer from "../../FabContainer/FabContainer";

type Props = {
    translate: *,
    onAdd: () => void,
    onDelete: (uuid: string) => void,
    onClick: (uuid: string) => void,
    staffingTemplates: *,
    onPageChange: (p: number) => void,
    acl: Acl,
    filter: string,
};

const StaffingTemplatesList = ({
    translate,
    onAdd,
    onDelete,
    onClick,
    onPageChange,
    acl,
    filter,
    staffingTemplates: { loading, data, page, error },
}: Props) => {
    // TODO: Add Translation eys
    const config = {
        id: "staffing-templates",
        uuidKey: "staffing-templates",
        hasMenu: true,
        hasTabs: false,
        onClick: item => onClick(item.uuid),
        configItems: [
            {
                key: "name",
                displayName: translate("Staffing Template"),
                sortable: false,
                isNumeric: false,
                permission: true,
            },
            {
                key: "lineType",
                // eslint-disable-next-line react/display-name
                customRender: ({ lineType }: Object) => (
                    <span>{lineType.name}</span>
                ),
                displayName: translate("Facility"),
                sortable: false,
                isNumeric: false,
                permission: true,
            },
            {
                key: "venueCode",
                // eslint-disable-next-line react/display-name
                customRender: ({ venue: { uuid, name, code } }: Object) => (
                    <VenueBox
                        venue={{
                            uuid,
                            name,
                            code,
                        }}
                    />
                ),
                displayName: translate("Venue"),
                sortable: false,
                isNumeric: false,
                permission: true,
            },
        ],
        menuOptions: [
            {
                id: "delete-template",
                label: () => translate("Delete template"),
                onClick: item => onDelete(item),
                permission: () => true,
            },
        ],
    };

    return (
        <div className={styles.wrapper}>
            <Table
                config={config}
                data={data}
                page={page}
                loading={loading}
                error={error}
                translate={translate}
                filter={filter}
                onPageChange={p => onPageChange(p + 1)}
            />

            {acl("staffingTemplates.create") && (
                <FabContainer>
                    <Fab
                        id="organisation-list-add"
                        variant="extended"
                        color="primary"
                        onClick={onAdd}
                    >
                        <AddIcon />
                        {translate("Add staffing template")}
                    </Fab>
                </FabContainer>
            )}
        </div>
    );
};

export default StaffingTemplatesList;
