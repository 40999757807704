// @flow

import React, { Component } from "react";
import { withRouter, type RouterHistory, type Match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import Scrollbar from "../../components/Scrollbar";

import styles from "./FacilityView.scss";

import { withTranslate } from "../Translate";
import { withAcl } from "../Acl";

import FilterHeader from "./FilterHeader";
import FacilityViewList from "../../components/Organisations/FacilityViewList/FacilityViewList";

import {
    clear,
    load,
    ERROR as FACILITY_VIEW_EVENTS_ERROR,
} from "../../redux/modules/facility-view-events";
import type { State } from "../../redux/modules/facility-view-events.d";
import { type Profile } from "../../redux/modules/user.d";
import { mapState } from "../../redux/utils";

type PropsType = {
    acl: *,
    translate: *,
    match: Match,
    history: RouterHistory,
    translate: *,
    user: Profile,
    events: State,
    loadEvents: (
        venue: string,
        lineType: string,
        startDate: string,
        endDate: string,
    ) => void,
    clearEvents: () => void,
};

type StateType = {
    startDate: string,
    endDate: string,
};

class FacilityView extends Component<PropsType, StateType> {
    state = {
        startDate:
            localStorage.getItem("ays.facility-view.startDate") ||
            moment().toISOString(),
        endDate:
            localStorage.getItem("ays.facility-view.endDate") ||
            moment().add(6, "months").toISOString(),
    };

    componentDidMount() {
        this.loadEvents();
    }

    componentDidUpdate() {
        this.loadEvents();
    }

    /**
     * Load the events with the correct parameters
     */
    loadEvents() {
        const { loadEvents, clearEvents, events } = this.props;
        const { startDate, endDate } = this.state;
        const { venueUuid, lineTypeUuid } = this.props.match.params;

        if (venueUuid && lineTypeUuid) {
            loadEvents(venueUuid, lineTypeUuid, startDate, endDate);
        } else if (events.loading || events.data || events.error) {
            clearEvents();
        }
    }

    /**
     * Render
     */
    render() {
        const { match, history, translate, acl, events, user } = this.props;
        const { startDate, endDate } = this.state;

        return (
            <div className={styles.wrapper}>
                <FilterHeader
                    history={history}
                    selectedVenue={match.params.venueUuid || ""}
                    selectedLineType={match.params.lineTypeUuid || ""}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(field, value) => {
                        this.setState({ [field]: value }, () =>
                            this.loadEvents(),
                        );
                    }}
                    user={user}
                />
                <Scrollbar h="calc(100vh - 321px)" w="100%">
                    <FacilityViewList
                        onClick={uuid => history.push(`/events/detail/${uuid}`)}
                        onDetail={(eventUuid, lineUuid) =>
                            history.push(
                                `/events/detail/${eventUuid}/staffing/${lineUuid}`,
                            )
                        }
                        translate={translate}
                        user={user}
                        loading={events.loading}
                        data={events.data}
                        error={events.error}
                        acl={acl}
                    />
                </Scrollbar>
            </div>
        );
    }
}

export default withAcl(
    withTranslate(
        withRouter(
            connect(
                ({ facilityViewEvents, user }) => ({
                    events: {
                        ...mapState(
                            facilityViewEvents,
                            FACILITY_VIEW_EVENTS_ERROR,
                        ),
                        data: facilityViewEvents.data &&
                            facilityViewEvents.data.events && {
                                content: facilityViewEvents.data.events,
                            },
                    },
                    user:
                        user &&
                        user.get("data") &&
                        user.getIn(["data", "profile"]).toJS(),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            loadEvents: load,
                            clearEvents: clear,
                        },
                        dispatch,
                    ),
            )(FacilityView),
        ),
    ),
);
