// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";
import { withTranslate } from "../Translate";

import {
    load as editSeries,
    clear as clearEditSeries,
    ERROR as EDIT_SERIES_ERROR,
} from "../../redux/modules/edit-series";

import { type State as EditSeriesState } from "../../redux/modules/edit-series.d";
import { type State as eventDetails } from "../../redux/modules/event-details.d";

import {
    load as loadEventDetails,
    clear as clearEventDetails,
} from "../../redux/modules/event-series";
import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";

import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";
import { type State as AddPromoterState } from "../../redux/modules/add-promoter.d";
import { mapState } from "../../redux/utils";

import EditSeries from "../../components/Events/EditSeries/EditSeries";
import { refetch as reloadCalendar } from "../../redux/modules/calendar";

type Props = {
    onClose: () => void,
    translate: *,
    match: Match,
    editSeries: *,
    addPromoter: *,
    addPromoterState: AddPromoterState,
    loadOrganisers: *,
    eventDetails: eventDetails,
    loadEventDetails: (uuid: string) => *,
    clearEventDetails: () => *,
    organisers: OrganisersState,
    editSeriesState: EditSeriesState,
    clearEditSeries: () => *,
    reloadCalendar: *,
    refetchOrganisers: () => Promise<*>,
};

class EditSeriesContainer extends Component<Props> {
    componentDidMount() {
        const { loadOrganisers, clearEditSeries, loadEventDetails } =
            this.props;
        clearEditSeries();
        clearAddPromoter();
        loadOrganisers();
        loadEventDetails(this.uuid);
    }

    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            translate,
            organisers,
            onClose,
            addPromoter,
            addPromoterState,
            loadOrganisers,
            editSeriesState,
            eventDetails,
            editSeries,
            reloadCalendar,
            clearEventDetails,
            refetchOrganisers,
        } = this.props;

        return (
            <EditSeries
                onClose={() => {
                    onClose();
                    clearEditSeries();
                    clearAddPromoter();
                    clearEventDetails();
                }}
                eventDetails={eventDetails}
                organisers={organisers}
                translate={translate}
                addPromoter={addPromoter}
                addPromoterState={addPromoterState}
                editSeriesState={editSeriesState}
                onSubmit={event => {
                    editSeries(event, this.uuid).then(() => {
                        onClose();
                        clearEditSeries();
                        clearAddPromoter();
                        clearEventDetails();
                        reloadCalendar();
                        onClose();
                    });
                }}
                loadOrganisers={loadOrganisers}
                id="edit-series"
                disabled={false}
                refetchOrganisers={refetchOrganisers}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ organisers, addPromoter, editSeries, eventSeries }) => ({
                organisers: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                addPromoterState: mapState(
                    addPromoter,
                    ADD_PROMOTER_ERROR,
                    "uuid",
                ),
                editSeriesState: mapState(editSeries, EDIT_SERIES_ERROR),
                eventDetails: eventSeries,
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadOrganisers,
                        loadEventDetails,
                        addPromoter,
                        clearAddPromoter,
                        clearEditSeries,
                        clearEventDetails,
                        reloadCalendar: () => reloadCalendar(),
                        editSeries,
                        refetchOrganisers,
                    },
                    dispatch,
                ),
        )(EditSeriesContainer),
    ),
);
