// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IxMbvMa\\+E15KjIQPecqq-w\\=\\={width:100%}.IxMbvMa\\+E15KjIQPecqq-w\\=\\=>:first-child{margin:2em 6em;border-radius:0.25em}.P\\+JHYoH1Oa1GIwBY4cAbPg\\=\\={margin:1em;text-align:center}\n", "",{"version":3,"sources":["webpack://./../LineList.scss"],"names":[],"mappings":"AAMA,6BACI,UAAW,CADf,0CAGQ,cAAe,CACf,oBAAqB,CACxB,6BAGD,UAAW,CACX,iBAAkB","sourcesContent":["/**\n * LineList styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    width: 100%;\n    & > :first-child {\n        margin: 2em 6em;\n        border-radius: 0.25em;\n    }\n}\n.spinner {\n    margin: 1em;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "IxMbvMa+E15KjIQPecqq-w==",
	"spinner": "P+JHYoH1Oa1GIwBY4cAbPg=="
};
export default ___CSS_LOADER_EXPORT___;
