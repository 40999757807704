// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { load as loadOption } from "../../redux/modules/option";
import { type State as OptionsState } from "../../redux/modules/options.d";
import {
    load as loadOptions,
    clear as clearOptions,
} from "../../redux/modules/options";
import { type State as ConfirmOptionsState } from "../../redux/modules/confirm-options.d";
import {
    load as confirmOptions,
    clear as clearConfirmOption,
    ERROR as CONFIRM_OPTIONS_ERROR,
} from "../../redux/modules/confirm-options";
import { refetch as reloadCalendar } from "../../redux/modules/calendar";
import { withTranslate } from "../Translate";
import ConfirmOptions from "../../components/Events/ConfirmOptions";
import { mapState } from "../../redux/utils";

type Props = {
    type: "OPTION" | "SERIES",
    onClose: () => void,
    translate: *,
    /** option */
    options: OptionsState,
    loadOption: (uuid: string) => *,
    loadOptions: (uuid: string) => *,
    clearOptions: () => void,
    /** confirm options */
    confirmOptions: (options: Object[]) => *,
    clearConfirmOption: *,
    confirmOptionsState: ConfirmOptionsState,
    /** calendar */
    reloadCalendar: *,
    /** match */
    match: Match,
};

class ConfirmOptionsContainer extends Component<Props> {
    componentDidMount() {
        const { loadOptions } = this.props;
        this.clear();
        loadOptions(this.uuid);
    }

    clear() {
        const { clearConfirmOption, clearOptions } = this.props;
        clearConfirmOption();
        clearOptions();
    }

    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            translate,
            options,
            onClose,
            type,
            confirmOptions,
            clearConfirmOption,
            reloadCalendar,
            confirmOptionsState,
        } = this.props;

        return (
            <ConfirmOptions
                onClose={onClose}
                translate={translate}
                onSubmit={options =>
                    confirmOptions(options).then(() => {
                        reloadCalendar();
                        onClose();
                        clearConfirmOption();
                    })
                }
                options={options.data}
                confirmOptionsState={confirmOptionsState}
                type={type}
                loading={options.loading}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({ options, confirmOptions }) => ({
                options,
                confirmOptionsState: mapState(
                    confirmOptions,
                    CONFIRM_OPTIONS_ERROR,
                ),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadOption: uuid => loadOption(uuid),
                        loadOptions: uuid => loadOptions(uuid),
                        confirmOptions: options => confirmOptions(options),
                        reloadCalendar: () => reloadCalendar(),
                        clearConfirmOption,
                        clearOptions,
                    },
                    dispatch,
                ),
        )(ConfirmOptionsContainer),
    ),
);
