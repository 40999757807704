// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sz0asd2kDrskGfm8TMQbsw\\=\\={display:flex;flex-direction:row;align-items:center;height:2em}.-I2ucmqwwjmYz796QaVePQ\\=\\={width:0.45em;height:0.75em;border-radius:0.2em;margin-right:0.35em}\n", "",{"version":3,"sources":["webpack://./../StaffingIndicator.scss"],"names":[],"mappings":"AACA,4BACI,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,UAAW,CACd,4BAEG,YAAa,CACb,aAAc,CACd,mBAAoB,CACpB,mBAAoB","sourcesContent":["@import \"../../styles/variables\";\n.wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: 2em;\n}\n.indicator {\n    width: 0.45em;\n    height: 0.75em;\n    border-radius: 0.2em;\n    margin-right: 0.35em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "sz0asd2kDrskGfm8TMQbsw==",
	"indicator": "-I2ucmqwwjmYz796QaVePQ=="
};
export default ___CSS_LOADER_EXPORT___;
