// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, type Match, type RouterHistory } from "react-router";

import { type State } from "../../../redux/modules/details.d";
import { load, ERROR } from "../../../redux/modules/details";
import { load as update } from "../../../redux/modules/details-update";
import { mapState } from "../../../redux/utils";

import DetailForm from "../../../components/Organisations/DetailsForm/DetailsForm";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    details: State,
    load: (organisationUUID: string) => void,
    update: (
        organisationUUID: string,
        name: string,
        maxLeaders: number,
    ) => void,
    history: RouterHistory,
    onTabChange: (s: string) => void,
    user: Profile,
};

class Details extends Component<Props> {
    /**
     * Initial load
     */
    componentDidMount() {
        this.reload();
    }

    /**
     * Refresh
     */
    componentDidUpdate() {
        this.reload();
    }

    /**
     * Trigger update
     */
    reload() {
        const { load, match } = this.props;
        if (match.params.id) {
            load(match.params.id);
        }
    }

    render() {
        const { details, update, translate, acl, user } = this.props;

        return (
            <div>
                <span style={{ marginTop: "1em", display: "block" }} />
                <DetailForm
                    disabled={!acl("organisations.update")}
                    translate={translate}
                    details={details}
                    onSubmit={d => {
                        if (d)
                            update(
                                d.uuid,
                                d.name.toString(),
                                d.maxLeaders,
                                d.description,
                                d.image,
                            );
                    }}
                    user={user}
                />
            </div>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ details, user }) => ({
                    details: {
                        ...mapState(details, ERROR),
                        uuid:
                            details.params &&
                            details.params.path &&
                            details.params.path.uuid,
                    },
                    user:
                        user &&
                        user.get("data") &&
                        user.getIn(["data", "profile"]).toJS(),
                }),
                (dispatch: *) => bindActionCreators({ load, update }, dispatch),
            )(Details),
        ),
    ),
);
