// @flow
import createApiModule from "../create-api-module";
import { type NewOrganisation } from "./organisation-add.d";

/**
 * Module: Add Organisation
 */

const { reducer, actions } = createApiModule(
    "organisationAdd",
    () => "ays-organisation-management/organisations",
    {
        method: "POST",
    },
);

export const load = (data: NewOrganisation) => (dispatch: *) => {
    return dispatch(
        actions.load({
            data,
        }),
    ).then(({ payload }) => payload.uuid);
};

export const ERROR = "Could not add organisation!";
export const refetch = actions.refetch;
export const clear = actions.clear;
export default reducer;
