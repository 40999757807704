// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x43SdYs9wwzMnCdHVrFA4g\\=\\={max-height:28em;overflow-y:auto}.iRRWO-aYjokV6deB8AuvYg\\=\\= span,.O7NcpwKNHEQZaLTx\\+WmsgA\\=\\= span{color:#651f82}._7BgrvJnLkcQKnUCTMLomfw\\=\\= span{color:#888}\n", "",{"version":3,"sources":["webpack://./../style.module.scss","webpack://./../../../../../styles/_variables.scss"],"names":[],"mappings":"AAEA,4BACI,eAAgB,CAChB,eAAgB,CACnB,mEAIG,aCAY,CDCf,kCAEG,UCJe","sourcesContent":["@import \"../../../../styles/_variables.scss\";\n\n.scroll {\n    max-height: 28em;\n    overflow-y: auto;\n}\n\n.add span,\n.selected span {\n    color: $purple;\n}\n.cancel span {\n    color: $neutral-grey;\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "x43SdYs9wwzMnCdHVrFA4g==",
	"add": "iRRWO-aYjokV6deB8AuvYg==",
	"selected": "O7NcpwKNHEQZaLTx+WmsgA==",
	"cancel": "_7BgrvJnLkcQKnUCTMLomfw=="
};
export default ___CSS_LOADER_EXPORT___;
