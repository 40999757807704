//@flow
import styles from "./EventDetails.scss";
import React, { useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import AsyncSelect from "../../AsyncSelect";
import AsyncFilteredSelect from "../../AsyncFilteredSelect";
import SelectStatus from "../../SelectStatus";
import {
    type EventDetails,
    type State as DetailsState,
} from "../../../redux/modules/event-details-update.d";
import { DateTimeField } from "../../DatePickers";

import { type State as OrganisersState } from "../../../redux/modules/organisers.d";
import { type State as VenueState } from "../../../redux/modules/venues.d";
import { type State as ParkingState } from "../../../redux/modules/venue-parking.d";
import {
    type State as EventState,
    type Event as EventType,
} from "../../../redux/modules/event-details.d";
import { type State as AddPromoterState } from "../../../redux/modules/add-promoter.d";

import { usePrevious } from "../../../hooks";

type Props = {
    disabled: boolean,
    translate: *,
    acl: *,
    details: DetailsState,
    venueParking: ParkingState,
    eventUuid: string,
    show: EventState,
    venues: VenueState,
    loadVenues: () => *,
    clearAddPromoter: *,
    addPromoter: (value: *) => Promise<*>,
    loadOrganisers: *,
    loadParking: (uuid: string) => *,
    update: (uuid: string, show: EventDetails) => void,
    organisers: OrganisersState,
    addPromoterState: AddPromoterState,
    loadOrganisers: () => Promise<*>,
    refetchOrganisers: () => Promise<*>,
};

/**
 * Loader component
 */
const Loading = () => (
    <div className={styles.spinner}>
        <CircularProgress />
    </div>
);

/**
 * Form component
 */
const Form = ({
    loadVenues,
    loadParking,
    show,
    loadOrganisers,
    clearAddPromoter,
    organisers,
    details,
    disabled,
    venueParking,
    update,
    eventUuid,
    translate,
    acl,
    addPromoterState,
    addPromoter,
    details: { fieldErrors },
    refetchOrganisers,
}: Props) => {
    const [event, setEvent] = useState<EventType>(show.data);
    const [currentPromoter, setCurrentPromoter] = useState(null);
    const [promoters, setPromoters] = useState(organisers);

    const prevPromoterUuid = usePrevious(event.promoterUuid);
    /**
     * Get the disabled state of this form
     */
    const isDisabled = (): boolean => {
        if (details) {
            return disabled || details.loading;
        }
        return false;
    };

    useEffect(() => {
        promoters.data &&
            event.promoterUuid &&
            (prevPromoterUuid !== event.promoterUuid || !currentPromoter) &&
            setCurrentPromoter(
                promoters.data.find(p => p.uuid === event.promoterUuid),
            );
    }, [event.promoterUuid, promoters]);

    useEffect(() => {
        setPromoters(organisers);
    }, [organisers]);

    const parkingDisabled = (): boolean =>
        venueParking && (disabled || venueParking.loading);

    const setInitialValues = () => {
        setEvent(show.data);
    };

    useEffect(() => {
        loadVenues();
        event && loadParking(event.venueUuid);
        loadOrganisers();
        clearAddPromoter();
    }, []);

    const PREMIUM_PARKING = translate("Premium parking");
    const DEFAULT_PARKING = translate("Default parking");
    /**
     * Retrieve the field error if present
     */
    const getFieldError = (field: string): ?string => {
        return fieldErrors && fieldErrors[field];
    };

    /**
     * Submit handler
     */
    const handleSubmit = (e: *) => {
        e.preventDefault();

        update(eventUuid, {
            venueUuid: event.venueUuid,
            name: event.name,
            start: event.start,
            end: event.end,
            staffParkingUuid: event.staffParkingUuid,
            premiumParkingUuid: event.premiumParkingUuid,
            promoterUuid: event.promoterUuid,
            status: event.status,
            earliestShiftTime: event.earliestShiftTime,
            latestShiftTime: event.latestShiftTime,
            startEvent: event.startEvent,
            endEvent: event.endEvent,
        });
    };

    // if (!currentPromoter) {
    //     return <div>Spinner</div>;
    // }

    /**
     * Render
     */
    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    {translate("Event Details")}
                </Typography>
                {details.error && (
                    <Typography color="error">{details.error}</Typography>
                )}
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {details && details.error && (
                        <Typography color="error">{details.error}</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="show-details-name"
                        label={translate("Name")}
                        onChange={e =>
                            setEvent({ ...event, name: e.target.value })
                        }
                        value={event.name}
                        error={!!getFieldError("name")}
                        helperText={getFieldError("name")}
                        disabled={!acl("events.details.update") || isDisabled()}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField
                        id="show-details-start"
                        disabled={!acl("events.details.update") || isDisabled()}
                        value={event.start}
                        onChange={start => setEvent({ ...event, start })}
                        label={translate("Start")}
                        error={!!getFieldError("start")}
                        helperText={getFieldError("start")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField
                        allowKeyboardControl={true}
                        format="yyyy/MM/dd HH:mm"
                        id="show-details-end"
                        disabled={!acl("events.details.update") || isDisabled()}
                        value={event.end}
                        onChange={end => setEvent({ ...event, end })}
                        label={translate("End")}
                        error={!!getFieldError("end")}
                        helperText={getFieldError("end")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField
                        allowKeyboardControl={true}
                        format="yyyy/MM/dd HH:mm"
                        id="show-details-startEvent"
                        disabled={!acl("events.details.update") || isDisabled()}
                        value={event.startEvent}
                        onChange={startEvent =>
                            setEvent({ ...event, startEvent })
                        }
                        label={translate("Start Event")}
                        error={!!getFieldError("startEvent")}
                        helperText={getFieldError("startEvent")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField
                        allowKeyboardControl={true}
                        format="yyyy/MM/dd HH:mm"
                        id="show-details-endEvent"
                        disabled={!acl("events.details.update") || isDisabled()}
                        value={event.endEvent}
                        onChange={endEvent => setEvent({ ...event, endEvent })}
                        label={translate("End Event")}
                        error={!!getFieldError("endEvent")}
                        helperText={getFieldError("endEvent")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField
                        allowKeyboardControl={true}
                        format="yyyy/MM/dd HH:mm"
                        id="show-details-earliestShiftTime"
                        disabled={!acl("events.details.update") || isDisabled()}
                        value={event.earliestShiftTime}
                        onChange={earliestShiftTime =>
                            setEvent({ ...event, earliestShiftTime })
                        }
                        label={translate("Earliest Shift Time")}
                        error={!!getFieldError("earliestShiftTime")}
                        helperText={getFieldError("earliestShiftTime")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField
                        allowKeyboardControl={true}
                        format="yyyy/MM/dd HH:mm"
                        id="show-details-latestShiftTime"
                        disabled={!acl("events.details.update") || isDisabled()}
                        value={event.latestShiftTime}
                        onChange={latestShiftTime =>
                            setEvent({ ...event, latestShiftTime })
                        }
                        label={translate("Latest Shift Time")}
                        error={!!getFieldError("latestShiftTime")}
                        helperText={getFieldError("latestShiftTime")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="showdetails-venue-name"
                        label={translate("Venue")}
                        value={event.venueName}
                        error={!!getFieldError("venueName")}
                        helperText={getFieldError("venueName")}
                        disabled={true}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <AsyncFilteredSelect
                        id={`showdetails-promoter`}
                        disabled={isDisabled()}
                        error={
                            !!(
                                addPromoterState.error ||
                                (addPromoterState.fieldErrors &&
                                    addPromoterState.fieldErrors.name) ||
                                getFieldError("promoterUuid")
                            )
                        }
                        helperText={
                            (!!addPromoterState.error &&
                                addPromoterState.error) ||
                            (addPromoterState.fieldErrors &&
                                addPromoterState.fieldErrors.name) ||
                            getFieldError("promoterUuid")
                        }
                        currentItem={currentPromoter}
                        label={translate("Organiser")}
                        asyncState={promoters}
                        onSelect={promoter =>
                            promoter.uuid
                                ? setEvent({
                                      ...event,
                                      promoterUuid: promoter.uuid,
                                  })
                                : addPromoter(promoter.name).then(
                                      async ({ payload }) => {
                                          await refetchOrganisers();
                                          setEvent({
                                              ...event,
                                              promoterUuid: payload.uuid,
                                          });
                                      },
                                  )
                        }
                        itemToString={item => (item && item.name) || ""}
                        itemToLabel={item =>
                            (item && (item.label || item.name)) || ""
                        }
                        filter={(value, items) =>
                            items
                                .filter(item =>
                                    (item: any).name
                                        .toLowerCase()
                                        .includes(value.toLowerCase()),
                                )
                                .splice(0, 5)
                        }
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: styles.label,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectStatus
                        id="showdetails-venue-list"
                        label={translate("Status")}
                        venueCode={
                            event.venueCode ? event.venueCode : "#298fc2"
                        }
                        optionsToSelect={["PUBLIC", "PRIVATE"]}
                        value={event.status}
                        onSelect={status => setEvent({ ...event, status })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">{translate("Parking")}</Typography>
                    {venueParking.error && (
                        <Typography color="error">
                            {venueParking.error}
                        </Typography>
                    )}
                </Grid>
                {!venueParking.data && <Loading />}
                {venueParking.data && (
                    <>
                        <Grid item xs={12}>
                            <AsyncSelect
                                id="addshow-default-parking-venue"
                                label={DEFAULT_PARKING}
                                disabled={
                                    !acl("events.details.parking") ||
                                    parkingDisabled()
                                }
                                value={event.staffParkingUuid}
                                loading={venueParking.loading}
                                error={venueParking.error}
                                hasNone
                                translate={translate}
                                options={(
                                    (venueParking.data: any).parkings || []
                                )
                                    .filter(parking => !parking.premiumParking)
                                    .map(parking => [
                                        parking.uuid,
                                        <Grid
                                            key={parking.uuid}
                                            container
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <Grid item xs>
                                                <Typography>
                                                    {parking.name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    textAlign: "right",
                                                }}
                                                item
                                                xs
                                            />
                                        </Grid>,
                                    ])}
                                onChange={staffParkingUuid =>
                                    setEvent({
                                        ...event,
                                        staffParkingUuid,
                                    })
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AsyncSelect
                                id="addshow-premium-parking-venue"
                                label={PREMIUM_PARKING}
                                disabled={
                                    !acl("events.details.parking") ||
                                    parkingDisabled()
                                }
                                value={event.premiumParkingUuid}
                                loading={venueParking.loading}
                                error={venueParking.error}
                                hasNone
                                translate={translate}
                                options={(
                                    (venueParking.data: any).parkings || []
                                )
                                    .filter(parking => parking.premiumParking)
                                    .map(parking => [
                                        parking.uuid,
                                        <Grid
                                            key={parking.uuid}
                                            container
                                            alignItems="center"
                                            spacing={3}
                                        >
                                            <Grid item xs>
                                                <Typography>
                                                    {parking.name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    textAlign: "right",
                                                }}
                                                item
                                                xs
                                            />
                                        </Grid>,
                                    ])}
                                onChange={premiumParkingUuid =>
                                    setEvent({
                                        ...event,
                                        premiumParkingUuid,
                                    })
                                }
                                fullWidth
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <div className={styles.actionButtons}>
                        <Button
                            id="show-details-cancel"
                            disabled={isDisabled()}
                            onClick={() => setInitialValues()}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id="show-details-confirm"
                            disabled={isDisabled()}
                            type="submit"
                            color="primary"
                        >
                            {translate("Save changes")}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

const Details = ({
    details,
    show,
    eventUuid,
    translate,
    venues,
    disabled,
    acl,
    update,
    loadVenues,
    loadParking,
    loadOrganisers,
    venueParking,
    organisers,
    addPromoter,
    addPromoterState,
    clearAddPromoter,
    refetchOrganisers,
}: Props) => (
    <Paper className={styles.main} elevation={2}>
        <>
            {!show.data && <Loading />}
            {show.data && (
                <Form
                    disabled={disabled || !acl("events.update")}
                    details={details}
                    venueParking={venueParking}
                    show={show}
                    eventUuid={eventUuid}
                    loadVenues={loadVenues}
                    loadParking={loadParking}
                    loadOrganisers={loadOrganisers}
                    translate={translate}
                    acl={acl}
                    update={update}
                    venues={venues}
                    key={show.uuid}
                    organisers={organisers}
                    addPromoter={addPromoter}
                    addPromoterState={addPromoterState}
                    clearAddPromoter={clearAddPromoter}
                    refetchOrganisers={refetchOrganisers}
                />
            )}
        </>
    </Paper>
);

Details.defaultProps = {
    disabled: false,
};

export default Details;
