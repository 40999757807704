// @flow
import createApiModule from "../create-api-module";
import { type PathParams } from "./event-csvdata.d";

const { reducer, actions } = createApiModule(
    "eventCSVData",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/events/${eventUuid}/team/export-csv`,
);

// Action creators
export const load =
    (eventUuid: string, force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(
            actions.load(
                {
                    path: { eventUuid },
                    headers: {
                        "Content-Type": "text/csv;charset=utf-8",
                        Accept: "text/csv",
                    },
                },
                force,
            ),
        ).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response.payload]),
            );
            // OPEN IN NEW TAB //
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.setAttribute("download", "CSV_" + eventUuid + ".csv");
            //$FlowFixMe
            document.body.appendChild(link);
            link.click();
            //$FlowFixMe
            document.body.removeChild(link);
        });
    };
export const refetch = actions.refetch;
export const clear = actions.clear;
// reducer
export default reducer;
