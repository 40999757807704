// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    load,
    clear,
    ERROR as SHOW_QRCODE_ERROR,
} from "../../../redux/modules/event-qrcode";
import EventQRCode from "../../../components/Events/EventQRCode";

import { withTranslate } from "../../Translate";
import { withAcl } from "../../Acl";
import { mapState } from "../../../redux/utils";

export default withAcl(
    withTranslate(
        connect(
            // Map state to props
            ({ eventQRCode }) => ({
                eventQRCode: mapState(eventQRCode, SHOW_QRCODE_ERROR),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        load: uuid => load(uuid),
                        clear: () => clear(),
                    },
                    dispatch,
                ),
        )(EventQRCode),
    ),
);
