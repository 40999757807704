// @flow

import createApiModule from "../create-api-module";

import { type PathParams } from "./define-events.d";

const { reducer, actions } = createApiModule(
    "defineEvents",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/series/${uuid}/options/_define`,
    { method: "POST" },
);

export const edit =
    (uuid: string, options: *, events: *, seriesName: *, optionCode: *) =>
    (dispatch: *) => {
        return dispatch(
            actions.load({
                path: { uuid },
                data: {
                    confirmedOptions: options,
                    events,
                    seriesName,
                    seriesOptionCode: optionCode,
                },
            }),
        );
    };

export const refetch = actions.refetch;

export const clear = actions.clear;

export default reducer;

export const ERROR = "Could not define the events!";
