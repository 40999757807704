// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";

import { type State as eventDetails } from "../../redux/modules/event-details.d";
import {
    load as loadEventDetails,
    clear as clearShowDetails,
} from "../../redux/modules/event-details";
import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";
import { type State as AddPromoterState } from "../../redux/modules/add-promoter.d";

import {
    load as editEvent,
    clear as clearEditEvent,
    ERROR as SHOW_DETAILS_UPDATE_ERROR,
} from "../../redux/modules/event-details-update";
import { type State as eventDetailsUpdate } from "../../redux/modules/event-details-update.d";
import { mapState } from "../../redux/utils";
import {
    load as loadCalendar,
    refetch as refetchCalendar,
} from "../../redux/modules/calendar";
import EditEvent from "../../components/Events/EditEvent/EditEvent";

import { withTranslate } from "../Translate";

type Props = {
    onClose: () => void,
    translate: *,
    match: Match,
    eventDetailsUpdate: eventDetailsUpdate,
    editEvent: *,
    eventDetails: eventDetails,
    loadEventDetails: (uuid: string) => *,
    /**promoters */
    organisers: OrganisersState,
    loadOrganisers: *,
    addPromoter: *,
    addPromoterState: AddPromoterState,
    clearAddPromoter: *,
    loadCalendar: *,
    clearEditEvent: *,
    clearShowDetails: *,
    refetchOrganisers: () => Promise<*>,
    refetchCalendar: () => Promise<*>,
};

/**
 * Edit event
 */
class EditEventContainer extends Component<Props> {
    componentDidMount() {
        const {
            loadEventDetails,
            loadOrganisers,
            clearEditEvent,
            clearShowDetails,
        } = this.props;
        clearEditEvent();
        clearShowDetails();
        loadEventDetails(this.uuid);
        loadOrganisers();
    }

    /** get uuid */
    get uuid() {
        const { match } = this.props;
        return match.params.uuid ? match.params.uuid.toString() : "";
    }

    render() {
        const {
            onClose,
            translate,
            loadOrganisers,
            organisers,
            addPromoter,
            clearAddPromoter,
            addPromoterState,
            refetchCalendar,
            eventDetails,
            eventDetailsUpdate,
            editEvent,
            clearEditEvent,
            clearShowDetails,
            refetchOrganisers,
        } = this.props;

        return (
            <EditEvent
                event={eventDetails}
                disabled={false}
                onClose={onClose}
                editedEventState={eventDetailsUpdate}
                translate={translate}
                addPromoterState={addPromoterState}
                loadOrganisers={loadOrganisers}
                organisers={organisers}
                addPromoter={addPromoter}
                id="edit-event"
                onSubmit={(uuid, show) =>
                    editEvent(uuid, show).then(() => {
                        onClose();
                        clearAddPromoter();
                        clearEditEvent();
                        clearShowDetails();
                        refetchCalendar();
                    })
                }
                refetchOrganisers={refetchOrganisers}
            />
        );
    }
}

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({
                organisers,
                addPromoter,
                eventDetails,
                eventDetailsUpdate,
            }) => ({
                organisers: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                addPromoterState: mapState(
                    addPromoter,
                    ADD_PROMOTER_ERROR,
                    "uuid",
                ),
                eventDetails,
                eventDetailsUpdate: mapState(
                    eventDetailsUpdate,
                    SHOW_DETAILS_UPDATE_ERROR,
                ),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadOrganisers,
                        loadEventDetails: uuid => loadEventDetails(uuid),
                        addPromoter,
                        clearAddPromoter,
                        editEvent: (uuid, show) => editEvent(uuid, show),
                        clearEditEvent,
                        clearShowDetails,
                        loadCalendar: () => loadCalendar(),
                        refetchOrganisers,
                        refetchCalendar,
                    },
                    dispatch,
                ),
        )(EditEventContainer),
    ),
);
