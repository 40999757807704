// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./staffing-template-line-delete.d";

const { reducer, actions } = createApiModule(
    "staffingTemplateLineDelete",
    ({ uuid }: PathParams) =>
        `/ays-venue-facility-management/staffing-template-lines/${uuid}`,
    { method: "DELETE" },
);

export const ERROR = "Couldn't delete this staffing template line";

export const load = (uuid: string) => (dispatch: *) =>
    dispatch(actions.load({ path: { uuid } }));
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
