// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "lines",
    () => `/ays-venue-facility-management/lines`,
);
export const ERROR = "Could not fetch the available lines.";
export const load = actions.load;
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
