// @flow

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { type Match, withRouter } from "react-router";

import { type State as VenuesState } from "../../redux/modules/venues.d";
import { type NewEvent } from "../../redux/modules/add-show.d";
import { type State as EventsState } from "../../redux/modules/events.d";

import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../redux/modules/venues";
import { type State as OrganisersState } from "../../redux/modules/organisers.d";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../redux/modules/organisers";
import {
    load as loadEventDetails,
    clear as clearEventDetails,
} from "../../redux/modules/event-series";
import { type State as EventDetails } from "../../redux/modules/event-details.d";
import {
    load as addSerie,
    clear as clearAddSerie,
    ERROR as SERIE_ERROR,
} from "../../redux/modules/add-serie";
import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../redux/modules/add-promoter";
import { type State as AddPromoterState } from "../../redux/modules/add-promoter.d";

import {
    load as addShow,
    clear as clearAddShow,
    ERROR as ADD_SHOW_ERROR,
} from "../../redux/modules/add-show";
import {
    load as addEvent,
    clear as clearAddEvent,
    ERROR as ADD_EVENT_ERROR,
} from "../../redux/modules/add-event";

import { type State as AddEventState } from "../../redux/modules/add-event.d";
import { type State as AddSerieState } from "../../redux/modules/add-serie.d";
import {
    load as findEvents,
    ERROR as EVENTS_ERROR,
} from "../../redux/modules/events";
import {
    load as loadCalendar,
    refetch as reloadCalendar,
} from "../../redux/modules/calendar";
import { mapState } from "../../redux/utils";
import { Add } from "../../components/Events";

import { withTranslate } from "../Translate";

type Props = {
    match: Match,
    onClose: () => void,
    translate: *,
    venues: VenuesState,
    loadVenues: () => *,
    organisers: OrganisersState,
    loadOrganisers: *,
    addShowState: AddEventState,
    addEventState: AddEventState,
    addShow: (eventUuid: string, data: NewEvent) => Promise<void>,
    clearAddShow: () => void,
    events: EventsState,
    addSerie: *,
    addSerieState: AddSerieState,
    clearAddSerie: *,
    clearAddEvent: *,
    addEvent: *,
    addPromoter: *,
    addPromoterState: AddPromoterState,
    clearAddPromoter: *,
    loadCalendar: *,
    findEvents: (filter: string) => *,
    /**event details */
    loadEventDetails: *,
    clearEventDetails: *,
    eventDetails: EventDetails,
    reloadCalendar: () => void,
    refetchOrganisers: () => Promise<*>,
};

/**
 * AddEvent
 */
const AddEvent = ({
    onClose,
    match,
    translate,
    venues,
    loadVenues,
    /** promoters */
    loadOrganisers,
    organisers,
    /** show */
    addShow,
    addShowState,
    clearAddShow,
    events,
    findEvents,
    /** promoter */
    addPromoter,
    clearAddPromoter,
    addPromoterState,
    /** series */
    addSerie,
    addSerieState,
    clearAddSerie,
    /** new event */
    addEvent,
    clearAddEvent,
    addEventState,
    loadCalendar,
    loadEventDetails,
    clearEventDetails,
    eventDetails,
    reloadCalendar,
    refetchOrganisers,
}: Props) => (
    <Add
        events={(events: any)}
        findEvents={findEvents}
        disabled={false}
        onClose={() => {
            clearAddShow();
            clearAddSerie();
            clearAddPromoter();
            clearAddEvent();
            loadCalendar();
            clearEventDetails();
            onClose();
        }}
        match={match}
        translate={translate}
        addShowState={addShowState}
        venues={venues}
        addPromoterState={addPromoterState}
        addSerieState={addSerieState}
        loadVenues={loadVenues}
        loadOrganisers={loadOrganisers}
        organisers={organisers}
        addPromoter={addPromoter}
        addEventState={addEventState}
        eventDetails={eventDetails}
        loadEventDetails={loadEventDetails}
        id="add-event"
        addSerie={addSerie}
        onSubmit={(show, internal, seriesName, serieUuid, optionCode) => {
            if (serieUuid && seriesName) {
                addShow(serieUuid, show).then(() => {
                    clearAddShow();
                    clearAddSerie();
                    clearAddPromoter();
                    reloadCalendar();
                    clearEventDetails();
                    onClose();
                });
            } else {
                addEvent(show, internal, seriesName, optionCode).then(() => {
                    clearAddShow();
                    clearAddSerie();
                    clearAddPromoter();
                    clearAddEvent();
                    reloadCalendar();
                    clearEventDetails();
                    onClose();
                });
            }
        }}
        refetchOrganisers={refetchOrganisers}
    />
);

export default withTranslate(
    withRouter(
        connect(
            // Map state to props
            ({
                venues,
                addShow,
                events,
                organisers,
                addSerie,
                addPromoter,
                addEvent,
                eventSeries,
            }) => ({
                venues: mapState(venues, VENUES_ERROR, "venues"),
                addShowState: mapState(addShow, ADD_SHOW_ERROR),
                events: mapState(events, EVENTS_ERROR, "content"),
                organisers: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                eventDetails: eventSeries,
                addSerieState: mapState(addSerie, SERIE_ERROR),
                addPromoterState: mapState(
                    addPromoter,
                    ADD_PROMOTER_ERROR,
                    "uuid",
                ),
                addEventState: mapState(addEvent, ADD_EVENT_ERROR),
            }),
            // Map dispatch to props
            (dispatch: *) =>
                bindActionCreators(
                    {
                        loadVenues,
                        loadOrganisers,
                        addShow,
                        clearAddShow,
                        findEvents,
                        addSerie,
                        addEvent,
                        addPromoter,
                        clearAddEvent,
                        clearAddSerie,
                        reloadCalendar,
                        clearAddPromoter,
                        loadEventDetails,
                        clearEventDetails,
                        loadCalendar: () => loadCalendar(),
                        refetchOrganisers,
                    },
                    dispatch,
                ),
        )(AddEvent),
    ),
);
