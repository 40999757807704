// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wn5dxQc0eAEMF-EVT2qdtw\\=\\={margin:2em}\n", "",{"version":3,"sources":["webpack://./../EventQRCode.scss"],"names":[],"mappings":"AAAA,4BACI,UAAW","sourcesContent":[".main {\n    margin: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Wn5dxQc0eAEMF-EVT2qdtw=="
};
export default ___CSS_LOADER_EXPORT___;
