// @flow

import moment from "moment";
import React, { Fragment, Component } from "react";
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    type Match,
    type RouterHistory,
    type Location,
} from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import stripslash from "../../../utils/stripslash";

import { type State as ShowDetailState } from "../../../redux/modules/event-details.d";
import {
    load,
    clear as clearShowDetails,
} from "../../../redux/modules/event-details";
import { load as makeEventPlanable } from "../../../redux/modules/event-detail-planable";

import NotFound from "../../../components/NotFound";
import TabbedHeader from "../../../components/Authenticated/TabbedHeader";

import { withAcl } from "../../Acl";
import { withTranslate } from "../../Translate";

import Recruitment from "./Recruitement";
import Staffing from "./Staffing";
import Issues from "./Issues/Issues";
import EventQRCode from "./EventQRCode";
import EventDetails from "./EventDetails";
import Attendees from "./Attendees/Attendees";
import EventBriefings from "./EventBriefings";
import { PlanableBanner } from "../../../components/Events/LineStaffing/PlanableBanner";

type Props = {
    acl: *,
    translate: *,
    match: Match,
    history: RouterHistory,
    eventDetails: ShowDetailState,
    load: (eventUuid: string) => *,
    location: Location,
    clearShowDetails: () => *,
    makeEventPlanable: (uuid: string) => void,
};

type State = {
    selected: number,
};
const isExpired = timespan => {
    if (timespan) {
        return moment.utc().isSameOrAfter(moment.utc(timespan.end));
    }
    return false;
};

const isStarted = timespan => {
    if (timespan) {
        return moment.utc().isSameOrAfter(moment.utc(timespan.start));
    }

    return false;
};
const TABS = (translate, acl) => {
    const tabs = [];

    if (acl("events.lines.read")) {
        tabs.push(["/staffing/", translate("Staffing"), "staffing"]);
    }

    if (acl("events.recruitment.read")) {
        tabs.push(["/recruitment/", translate("Recruitment"), "recruitment"]);
    }

    if (acl("events.issues.read")) {
        tabs.push(["/issues/", translate("Reported issues"), "issues"]);
    }

    if (acl("events.update")) {
        tabs.push(["/details/", translate("Details"), "details"]);
    }

    if (acl("events.update")) {
        tabs.push(["/briefings/", translate("Briefings"), "briefings"]);
    }
    if (acl("events.attendees.read")) {
        tabs.push(["/attendees/", translate("Attendees"), "attendees"]);
    }
    if (acl("events.qrcode.read")) {
        tabs.push(["/qr/", "Print Event QR-code", "qr"]);
    }

    return tabs;
};

class Detail extends Component<Props, State> {
    state = {
        selected: 0,
    };

    static getDerivedStateFromProps(props: Props) {
        const path = props.location.pathname;
        const tabs = TABS(props.translate, props.acl);
        const found = tabs.findIndex(tab => path.includes(tab[2]));
        return {
            selected: Math.max(Math.min(found, tabs.length - 1), 0),
        };
    }

    /**
     * Get show name
     */
    get name() {
        const { eventDetails } = this.props;
        if (eventDetails.data) {
            return eventDetails.data.name;
        }

        return "...";
    }

    /**
     * Get show end time
     */
    get timespan() {
        const { eventDetails } = this.props;
        if (eventDetails.data) {
            return {
                start: eventDetails.data.start,
                end: eventDetails.data.end,
            };
        }

        return null;
    }

    /**
     * Get event name
     */
    get location() {
        const { eventDetails } = this.props;
        if (eventDetails.data) {
            return eventDetails.data.venueName;
        }

        return "...";
    }

    /**
     * Get event name
     */
    get series() {
        const { eventDetails } = this.props;
        if (eventDetails.data) {
            return eventDetails.data.seriesName;
        }

        return "...";
    }

    /**
     * Get start date
     */
    get start() {
        const { eventDetails } = this.props;
        if (eventDetails.data) {
            return moment.utc(eventDetails.data.start).format("ddd D MMM YYYY");
        }

        return "...";
    }

    /**
     * Initial load
     */
    componentDidMount() {
        this.reload();
    }

    /**
     * Refresh
     */
    componentDidUpdate() {
        this.reload();
    }

    componentWillUnmount() {
        this.props.clearShowDetails();
    }

    /**
     * Trigger update
     */
    reload() {
        const { load, match } = this.props;
        if (match.params.id) {
            load(match.params.id);
        }
    }

    /**
     * Handle tab change
     */
    handleTabChange(tabIndex: number) {
        const { translate, acl, history, match } = this.props;
        history.push(
            `${stripslash(match.url)}${TABS(translate, acl)[tabIndex][0]}`,
        );
        this.setState({
            selected: tabIndex,
        });
    }

    /**
     * Render
     */
    render() {
        const { match, translate, acl, eventDetails, makeEventPlanable } =
            this.props;
        const { selected } = this.state;
        const canReadLines = acl("events.lines.read");
        return (
            <Fragment>
                <TabbedHeader
                    id="detail-header"
                    title={this.name}
                    subtitle={translate("%{event} on %{date} | %{location}", {
                        event: this.series,
                        date: this.start,
                        location: this.location,
                    })}
                    tabs={TABS(translate, acl).map(tab => tab[1])}
                    active={selected}
                    onChange={this.handleTabChange.bind(this)}
                />
                {eventDetails.data && eventDetails.data?.planable !== true && (
                    <PlanableBanner
                        makeEventPlanable={makeEventPlanable}
                        translate={translate}
                    />
                )}
                <Switch>
                    <Route path={`${match.path}staffing/:selected?/`}>
                        {(canReadLines && (
                            <Staffing
                                uuid={match.params.id ? match.params.id : ""}
                                disabled={isExpired(this.timespan)}
                                root={`${stripslash(match.url)}/staffing/`}
                                venueUuid={
                                    this.props.eventDetails.data?.venueUuid
                                }
                                seriesUuid={
                                    this.props.eventDetails.data?.seriesUuid
                                }
                                planable={eventDetails.data?.planable}
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}recruitment/:selected?/`}>
                        {(acl("events.recruitment.read") && (
                            <Recruitment
                                uuid={match.params.id ? match.params.id : ""}
                                showName={this.name}
                                disabled={isStarted(this.timespan)}
                                root={`${stripslash(match.url)}/recruitment/`}
                                planable={eventDetails.data?.planable}
                            />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}details/`}>
                        <EventDetails
                            id="show-details"
                            key={`show-details-${
                                match.params.id ? match.params.id : ""
                            }`}
                            disabled={isExpired(this.timespan)}
                            show={this.props.eventDetails}
                            eventUuid={match.params.id}
                        />
                    </Route>
                    <Route path={`${match.path}briefings/`}>
                        <EventBriefings
                            eventUuid={match.params.id}
                            disabled={isExpired(this.timespan)}
                            venueUuid={this.props.eventDetails.data?.venueUuid}
                            seriesUuid={
                                this.props.eventDetails.data?.seriesUuid
                            }
                        />
                    </Route>
                    <Route path={`${match.path}qr/`}>
                        <EventQRCode
                            uuid={match.params.id}
                            disabled={isExpired(this.timespan)}
                        />
                    </Route>
                    <Route path={`${match.path}attendees/`}>
                        <Attendees uuid={match.params.id} />
                    </Route>
                    <Route path={`${match.path}issues/`}>
                        <Issues />
                    </Route>
                    <Route>
                        <Redirect
                            to={`${stripslash(match.url)}/${
                                (canReadLines && "staffing") || "details"
                            }/`}
                        />
                    </Route>
                    <Route>
                        <h1>{translate("404: Page not found!")}</h1>
                    </Route>
                </Switch>
            </Fragment>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                ({ eventDetails }) => ({
                    eventDetails,
                }),
                (dispatch: *, { match }) => ({
                    ...bindActionCreators({ load, clearShowDetails }, dispatch),
                    makeEventPlanable: () =>
                        dispatch(makeEventPlanable(match.params.id)),
                }),
            )(Detail),
        ),
    ),
);
