// @flow

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-security-report.d";

const { reducer, actions } = createApiModule(
    "eventSecurityReport",
    ({ eventUuid }: PathParams) =>
        `/ays-series-event-management/events/${eventUuid}/security-report`,
);

export const load = (eventUuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { eventUuid },
        }),
    );
};

export const ERROR = "Could not fetch the briefings";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
