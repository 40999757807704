// @flow

import styles from "./PlanableBanner.scss";
import { Button } from "@material-ui/core";
import React from "react";

type Props = {
    makeEventPlanable: (uuid: string) => void,
    translate: *,
};

export const PlanableBanner = ({ makeEventPlanable, translate }: Props) => {
    const submitPlanable = () => {
        makeEventPlanable().then(() => {
            window.location.reload();
        });
    };
    return (
        <div className={styles.planable}>
            <span>{translate("Staffable")}</span>
            <Button
                onClick={submitPlanable}
                variant="contained"
                color="primary"
            >
                {translate("Make Staffable")}
            </Button>
        </div>
    );
};
