// @flow

import styles from "./Event.scss";
import React, { Component } from "react";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";

import VenueBox from "../../../VenueBox/VenueBox";
import SelectList from "../SelectList/SelectList";
import { TimeField } from "../../../DatePickers";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

/** props */
type Props = {
    translate: *,
    uuid: *,
    seriesName?: string,
    id: string,
    onChange: *,
    onSelect: (field: string, value: *) => void,
    event: *,
    onDelete: (uuid: *) => *,
    getError: *,
    defineEvetsState: *,
};

export default class Event extends Component<Props, State> {
    /**
     * disable
     */
    get disabled() {
        return false;
    }

    /**
     * Clip to event.date
     */
    handleChangeStartTime(value: *) {
        const { uuid, onChange, event } = this.props;

        if (value) {
            if (value.isAfter(event.end.clone().subtract(1, "hours"))) {
                onChange("end", moment(value.clone().add(1, "hours")), uuid);
            }

            onChange("date", moment(value), uuid);
        }
    }

    /**
     * Ensure one hour from start
     */
    handleChangeEndTime(value: *) {
        const { uuid, onChange } = this.props;

        onChange("end", value, uuid);
    }

    /**
     * get maximum date
     */
    get maxDate() {
        const { event } = this.props;

        return moment(moment(event.date).add(1, "days")).toISOString();
    }

    get minDate() {
        const { event } = this.props;
        return moment(event.date).toISOString();
    }

    render() {
        const {
            event,
            id,
            uuid,
            seriesName,
            onSelect,
            onChange,
            onDelete,
            getError,
        } = this.props;

        return (
            <TableRow className={styles.event}>
                <TableCell>
                    <TextField
                        uuid={uuid}
                        id={`${id}-event-name`}
                        value={event.name ? event.name : ""}
                        onChange={e => onChange("name", e.target.value, uuid)}
                        error={!!getError("name")}
                        helperText={getError("name")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {seriesName && seriesName}
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </TableCell>
                <TableCell>
                    <VenueBox
                        venue={{
                            code: event.venueCode,
                            name: event.venueName,
                            uuid: event.venueUuid,
                        }}
                    />
                </TableCell>
                <TableCell>
                    <SelectList
                        id={`${id}-event-selectevent`}
                        onSelect={value => onSelect("status", value)}
                        uuid={uuid}
                        venueCode={event.venueCode}
                        optionStatus={event.status}
                        type="EVENT"
                        color="dark"
                    />
                </TableCell>
                <TableCell>
                    <div className={styles.time}>
                        <TextField
                            value={event.date.format("DD/MM/YYYY") + ","}
                            disabled={true}
                            error={!!getError("start")}
                            helperText={getError("start")}
                            InputProps={{
                                endAdornment: (
                                    <TimeField
                                        value={event.date}
                                        id={`${id}-event-from`}
                                        disabled={this.disabled}
                                        onChange={value =>
                                            this.handleChangeStartTime(value)
                                        }
                                    />
                                ),
                            }}
                        />
                    </div>
                </TableCell>
                <TableCell className={styles.dateset}>
                    {event.date && (
                        <KeyboardDateTimePicker
                            id={`${id}-event-until`}
                            disabled={this.disabled}
                            value={moment(event.end)}
                            maxDate={this.maxDate}
                            minDate={this.minDate}
                            onChange={value => this.handleChangeEndTime(value)}
                            format={"DD/MM/YYYY, HH:mm"}
                            ampm={false}
                            autoOk
                        />
                    )}
                </TableCell>

                <TableCell className={styles.add}>
                    <Button
                        size="small"
                        id={`${id}-event-delete`}
                        variant="text"
                        color="primary"
                        onClick={() => onDelete(uuid)}
                    >
                        <CancelIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}
