// @flow

/**
 * Module: Add Show
 */

import createApiModule from "../create-api-module";
import { type PathParams } from "./event-recruitment-delete.d";

const { reducer, actions } = createApiModule(
    "showRecruitmentDelete",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/recruitment-pools/${uuid}`,
    {
        method: "DELETE",
    },
);
export const load = (uuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
        }),
    );
};
export const ERROR = "Could not remove recruitment pool!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
