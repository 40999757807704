// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sbZ\\+Pm2OjGJGG2k7DzEb5Q\\=\\={margin:2em 6em;border-radius:0.25em}.sbZ\\+Pm2OjGJGG2k7DzEb5Q\\=\\=>div>div>div>table thead th{padding-left:2em;padding-right:1em;text-transform:none;font-size:0.8125rem;font-family:inherit}.sbZ\\+Pm2OjGJGG2k7DzEb5Q\\=\\=>div>div>div>table tbody td{text-transform:none;font-size:0.8125rem;font-family:inherit}._0-48vg4VEZeUuRK2hG8Chg\\=\\={float:right;right:-1em;top:-0.5em;width:2em;height:2em}._2Z-Qrp7SzqYaO8YJadciGQ\\=\\=:hover{cursor:pointer}.r0vgD3I709w7HBwH3QKP2w\\=\\={text-align:center}\n", "",{"version":3,"sources":["webpack://./../Issues.scss"],"names":[],"mappings":"AAMA,6BACI,cAAe,CACf,oBAAqB,CAFzB,wDAMgB,gBAAiB,CACjB,iBAAkB,CAClB,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CAVpC,wDAagB,mBAAoB,CACpB,mBAAoB,CACpB,mBAAoB,CACvB,6BAMT,WAAY,CACZ,UAAW,CACX,UAAW,CACX,SAAU,CACV,UAAW,CACd,mCAGG,cAAe,CAClB,4BAGG,iBAAkB","sourcesContent":["/**\n * Issues styling\n */\n\n@import \"../../../styles/variables\";\n\n.main {\n    margin: 2em 6em;\n    border-radius: 0.25em;\n    & > div > div {\n        & > div > table {\n            thead th {\n                padding-left: 2em;\n                padding-right: 1em;\n                text-transform: none;\n                font-size: 0.8125rem;\n                font-family: inherit;\n            }\n            tbody td {\n                text-transform: none;\n                font-size: 0.8125rem;\n                font-family: inherit;\n            }\n        }\n    }\n}\n\n.button {\n    float: right;\n    right: -1em;\n    top: -0.5em;\n    width: 2em;\n    height: 2em;\n}\n\n.item:hover {\n    cursor: pointer;\n}\n\n.spinner {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "sbZ+Pm2OjGJGG2k7DzEb5Q==",
	"button": "_0-48vg4VEZeUuRK2hG8Chg==",
	"item": "_2Z-Qrp7SzqYaO8YJadciGQ==",
	"spinner": "r0vgD3I709w7HBwH3QKP2w=="
};
export default ___CSS_LOADER_EXPORT___;
