// @flow

import createApiModule from "../create-api-module";
import { updateLineDefault } from "./organisationlines";
import { type PathParams } from "./set-event-line-default-organisation.d";

const { reducer, actions } = createApiModule(
    "setEventLineDefaultOrganisation",
    ({ lineUUID }: PathParams) =>
        `/ays-venue-facility-management/lines/${lineUUID}/default-organisation`,
    {
        method: "POST",
    },
);

export const load =
    (lineUUID: string, organisationUUID: string) => (dispatch: *) => {
        return dispatch(
            actions.load({
                path: {
                    lineUUID,
                },
                data: {
                    defaultOrganisationUuid: organisationUUID,
                },
            }),
        ).then(() => dispatch(updateLineDefault(lineUUID, !!organisationUUID)));
    };
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
