//@flow

import {
    DialogTitle,
    Typography,
    DialogActions,
    Button,
    DialogContent,
    FormGroup,
    FormControl,
    FormLabel,
    CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";

import type { SeriesEvent } from "../../../../redux/modules/series-events.d";
import CheckboxGroup from "../../../CheckboxGroup";

type Props = {
    translate: *,
    onBack: (events: string[]) => void,
    onNext: (events: string[]) => void,
    onClose: () => void,
    events: SeriesEvent[],
    selected: string[],
};

const SelectEvents = ({
    translate,
    onBack,
    onNext,
    onClose,
    events,
    selected: selectedProp = [],
}: Props) => {
    const [selected, setSelected] = useState(selectedProp);
    return (
        <>
            <DialogTitle disableTypography>
                <Typography variant="h2" color="inherit">
                    {translate("Apply templates to series")}
                </Typography>
                <Typography color="inherit">
                    {translate("Choose one or more events in this series.")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {!events ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <FormControl margin="normal" fullWidth>
                        <FormLabel>
                            {translate("Other events in this venue")}
                        </FormLabel>
                        <FormGroup>
                            <CheckboxGroup
                                translate={translate}
                                data={events.map(event => ({
                                    label: event.fullDisplayName,
                                    id: event.uuid,
                                }))}
                                selected={selected}
                                onChange={setSelected}
                                emptyLabel={translate("No events found")}
                            />
                        </FormGroup>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    id="applytemplates-series-back"
                    onClick={() => onBack(selected)}
                    style={{ marginRight: "auto" }}
                >
                    {translate("Back")}
                </Button>
                <Button id="applytemplates-series-cancel" onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button
                    id="applytemplates-series-next"
                    color="primary"
                    disabled={selected.length === 0}
                    onClick={() => onNext(selected)}
                >
                    {translate("Next")}
                </Button>
            </DialogActions>
        </>
    );
};

export default SelectEvents;
