// @flow

import styles from "./index.style.scss";

import React, { useState } from "react";

import LineStaffing from "../../../Events/LineStaffing";
import Occupancy from "./Occupancy";
import Hours from "./Hours";
import Briefings from "./Briefings";
import Organisations from "../../../Events/Organisations/Organisations";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";

import { type Profile } from "../../../../redux/modules/user.d";
import { type StaffingTemplateLine } from "../../../../redux/modules/staffing-template-details.d";

type Props = {
    translate: *,
    line: StaffingTemplateLine,
    acl: *,
    user: Profile,
    organisations: *,
    loadOrganisations: (filter: string) => void,
    updateLine: (uuid: string, obj: *) => void,
    deleteLine: (uuid: string) => void,
    deleteLoading: boolean,
    refetchTemplate: () => void,
    updateLoading: boolean,
    updateFieldErrors: ?{ [key: string]: string },
    clearLine: () => void,
    error: ?string,
};

const LineDetails = ({
    translate,
    line,
    user,
    acl,
    organisations,
    loadOrganisations,
    updateLine,
    clearLine,
    deleteLine,
    deleteLoading,
    refetchTemplate,
    updateLoading,
    updateFieldErrors,
    error,
}: Props) => {
    const {
        hourType,
        absoluteEnd,
        absoluteStart,
        offsetEnd,
        offsetStart,
        preBriefing,
        briefing,
    } = line;

    const mapPrimaryOrg = org => ({
        ...org,
        uuid: org.organisationUuid,
        organisationName: org.organisationName || org.name,
        organisationType: "PRIMARY",
        organisationStatus: "ACTIVE",
    });

    const mapBackupOrg = org => ({
        ...org,
        uuid: org.organisationUuid,
        organisationName: org.organisationName || org.name,
        organisationType: "BACKUP",
        organisationStatus: org.organisationStatus || "INACTIVE",
    });

    const [status, setStatus] = useState(line.status === "OPEN");

    const [occupancy, setOccupancy] = useState({
        min: line.minOccupancy,
        max: line.maxOccupancy,
    });

    const [hoursState, setHoursState] = useState({
        hourType,
        absoluteStart,
        absoluteEnd,
        offsetEnd,
        offsetStart,
    });

    const [briefings, setBriefings] = useState({
        preBriefing,
        briefing,
    });

    const [primaryOrganisation, setPrimaryOrganisation] = useState(
        line.primaryOrganisation
            ? mapPrimaryOrg(line.primaryOrganisation)
            : null,
    );

    const [backupOrganisations, setBackupOrganisations] = useState(
        line.backupOrganisations
            ? line.backupOrganisations.map(org => mapBackupOrg(org))
            : [],
    );

    const mapOrganisationsData = () => {
        const data = [];

        if (primaryOrganisation) {
            data.push(primaryOrganisation);
        }
        if (backupOrganisations.length > 0) {
            return [...data, ...backupOrganisations];
        }

        return data;
    };

    const onAdd = (uuid, type) => {
        const foundOrg = organisations.data.content.find(
            org => org.organisationUuid === uuid,
        );
        if (type === "main") {
            setPrimaryOrganisation(mapPrimaryOrg(foundOrg));
            return;
        }
        setBackupOrganisations([
            ...backupOrganisations,
            mapBackupOrg(foundOrg),
        ]);
    };

    const onActDeact = (uuid: string, orgStatus: string) => {
        const orgs = [...backupOrganisations];
        orgs.find(org => org.organisationUuid === uuid).organisationStatus =
            orgStatus;
        setBackupOrganisations(orgs);
    };

    const onDeleteOrg = (uuid: string) => {
        if (primaryOrganisation?.uuid === uuid) {
            setPrimaryOrganisation(null);
            return;
        }
        const orgs = [...backupOrganisations];
        const foundOrg = orgs.findIndex(org => org.organisationUuid === uuid);
        orgs.splice(foundOrg, 1);
        setBackupOrganisations(orgs);
        return;
    };

    const handleSubmit = () =>
        updateLine(line.staffingTemplateLineUuid, {
            status: status ? "OPEN" : "CLOSED",
            primaryOrganisation: primaryOrganisation?.organisationUuid || null,
            backupOrganisations: backupOrganisations?.map(org => ({
                uuid: org.organisationUuid,
                organisationStatus: org.organisationStatus,
            })),
            minOccupancy: occupancy.min,
            maxOccupancy: occupancy.max,
            ...briefings,
            ...hoursState,
        }).then(() => {
            refetchTemplate();
            clearLine();
        });

    return (
        line && (
            <LineStaffing
                id={line.staffingTemplateLineUuid}
                active={status}
                onToggle={() => setStatus(!status)}
                canToggle
                eventLineOpenClose={{ loading: updateLoading }}
                eventLine={{ name: line.lineName }}
                translate={translate}
            >
                <>
                    <Occupancy
                        translate={translate}
                        min={occupancy.min}
                        max={occupancy.max}
                        onChange={(min, max) => setOccupancy({ min, max })}
                        disabled={updateLoading}
                        fieldErrors={updateFieldErrors}
                    />
                    <Divider />
                    <Hours
                        translate={translate}
                        state={hoursState}
                        onChange={setHoursState}
                        disabled={updateLoading}
                    />
                    <Divider />
                    <Organisations
                        id={line.staffingTemplateLineUuid}
                        acl={acl}
                        active={true}
                        translate={translate}
                        user={user}
                        organisations={organisations}
                        load={loadOrganisations}
                        variant="secondary"
                        loadSelected={() => null}
                        clearSelected={() => null}
                        onDelete={onDeleteOrg}
                        onActivate={uuid => onActDeact(uuid, "ACTIVE")}
                        onDeactivate={uuid => onActDeact(uuid, "INACTIVE")}
                        onAdd={onAdd}
                        disabled={updateLoading}
                        selected={{
                            data: mapOrganisationsData(),
                            loading: false,
                            uuid: line.staffingTemplateLineUuid,
                        }}
                    />
                    <Divider />
                    <Briefings
                        translate={translate}
                        briefings={briefings}
                        onChange={setBriefings}
                        disabled={updateLoading}
                    />
                    <Divider />
                    {error && (
                        <FormHelperText
                            id="staffing-template-details-error"
                            className={styles.helperText}
                            error
                        >
                            {error}
                        </FormHelperText>
                    )}
                    <div className={styles.buttonsWrapper}>
                        <Button
                            id="update-line-delete"
                            color="secondary"
                            onClick={() =>
                                deleteLine(line.staffingTemplateLineUuid).then(
                                    refetchTemplate,
                                )
                            }
                            disabled={deleteLoading}
                        >
                            {translate("Delete")}
                        </Button>
                        <Button
                            id="update-line-save"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={updateLoading}
                        >
                            {translate("Save changes")}
                        </Button>
                    </div>
                </>
            </LineStaffing>
        )
    );
};

export default LineDetails;
