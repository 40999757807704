// @flow

import Base from "./Base";

import {
    user,
    organisation,
    lines,
    events,
    templates,
    eventLineTeam,
    eventLineOrganisations,
    eventRecruitment,
    eventRecruits,
    venueParking,
    eventLineBriefings,
    eventRecruitmentAddAll,
} from "./modules";

@user
@organisation
@lines
@events
@templates
@eventLineTeam
@eventLineOrganisations
@eventRecruitment
@eventRecruits
@venueParking
@eventLineBriefings
@eventRecruitmentAddAll
class ApiClient extends Base {}

export default ApiClient;
