// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import {
    type ImmutableState,
    type Action,
    //type FullState,
} from "./notifications.d";

const ZONE = "notification";
const CLEAR = `${ZONE}/clear`;
const ADD = `${ZONE}/add`;
const REMOVE = `${ZONE}/remove`;

const initialState: ImmutableState = (fromJS({
    data: [],
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === ADD) {
        invariant(payload, `Wrong payload for ${ADD}`);

        return state.merge({
            data: [].concat(state.toJS().data, [fromJS(payload)]),
        });
    }

    if (type === REMOVE) {
        invariant(payload, `Wrong payload for ${ADD}`);

        return state.merge({
            data: state.toJS().data.filter(notif => notif.uuid != payload),
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: addNotification
 */
export const addNotification =
    (
        message: string,
        type: string = "INFORMATION",
        uuid: string = Date.now().toString(),
    ) =>
    (dispatch: (action: Action) => void): * => {
        dispatch({
            type: ADD,
            payload: { message, type, uuid },
        });
    };

/**
 * ActionCreator: addNotification
 */
export const removeNotification =
    (uuid: string) =>
    (dispatch: (action: Action) => void): * => {
        dispatch({
            type: REMOVE,
            payload: uuid,
        });
    };
