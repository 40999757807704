// @flow
import styles from "../../../components/Events/Recruitment/Pool.scss";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Switch,
    Route,
    Redirect,
    withRouter,
    type Match,
    type RouterHistory,
} from "react-router";
import stripslash from "../../../utils/stripslash";
import { withAcl } from "../../Acl";
import { withTranslate } from "../../../containers/Translate";
/** Action Creators */
import { load as loadMembers } from "../../../redux/modules/members";
import {
    clear,
    load,
    refetch as reload,
    ERROR as SHOW_RECRUITS_ERROR,
} from "../../../redux/modules/event-recruits";
import { load as addRecruits } from "../../../redux/modules/event-recruits-add";
import {
    load as updateRecruit,
    ERROR as SHOW_RECRUITS_UPDATE_ERROR,
} from "../../../redux/modules/event-recruits-update";
import {
    load as deleteRecruit,
    ERROR as SHOW_RECRUITS_DELETE_ERROR,
} from "../../../redux/modules/event-recruits-delete";
import { addAll } from "../../../redux/modules/event-recruits-add-all";
/** Types */
import { type State as MembersState } from "../../../redux/modules/members.d";
import { type State as ShowRecruitsState } from "../../../redux/modules/event-recruits.d";
import { type State as ShowRecruitsAddState } from "../../../redux/modules/event-recruits-add.d";
import { type State as ShowRecruitsUpdateState } from "../../../redux/modules/event-recruits-update.d";
import { type State as ShowRecruitsDeleteState } from "../../../redux/modules/event-recruits-delete.d";
import { type Pool as PoolType } from "../../../redux/modules/event-recruitment.d";
import {
    type State as UpdateState,
    type Pool as PoolFields,
} from "../../../redux/modules/event-recruitment-update.d";
import { mapState } from "../../../redux/utils";
/**
 *  import Components
 */
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import {
    PoolRecruits,
    PoolTitle,
    PoolSettings,
    AddRecruit,
} from "../../../components/Events/Recruitment";

type Props = {
    id: string,
    pool: PoolType,
    acl: *,
    uuid: string,
    translate: *,
    onSave: (data: PoolFields) => *,
    editState: UpdateState,
    disabled: boolean,
    isExpired: boolean,
    match: Match,
    history: RouterHistory,
    members: MembersState,
    recruitsState: ShowRecruitsState,
    showRecruitsAdd: ShowRecruitsAddState,
    eventRecruitsUpdate: ShowRecruitsUpdateState,
    eventRecruitsDelete: ShowRecruitsDeleteState,
    loadRecruits: (page: number, filter: string) => *,
    clearRecruits: () => *,
    onPageChange: (page: number) => void,
    loadMembers: (filter: string) => *,
    addRecruits: (uuids: string[]) => Promise<void>,
    updateRecruit: (uuid: string, status: string) => Promise<void>,
    deleteRecruit: (uuid: string) => *,
    addAll: (uuid: string) => *,
};
class Pool extends Component<Props> {
    // Defaults
    static defaultProps = {
        disabled: false,
    };

    /**
     * Render
     */
    render() {
        const {
            id,
            pool,
            acl,
            translate,
            onSave,
            editState,
            disabled,
            isExpired,
            match,
            history,
            members,
            recruitsState,
            showRecruitsAdd,
            eventRecruitsUpdate,
            eventRecruitsDelete,
            loadRecruits,
            clearRecruits,
            onPageChange,
            loadMembers,
            addRecruits,
            updateRecruit,
            deleteRecruit,
            addAll,
        } = this.props;

        return (
            <div className={styles.wrapper}>
                <Paper elevation={2}>
                    <PoolTitle pool={pool} />
                    <Divider />
                    <PoolRecruits
                        id={`${id}-recruits`}
                        uuid={pool.uuid}
                        acl={acl}
                        translate={translate}
                        disabled={isExpired}
                        match={match}
                        history={history}
                        recruitsState={recruitsState}
                        eventRecruitsUpdate={eventRecruitsUpdate}
                        eventRecruitsDelete={eventRecruitsDelete}
                        loadRecruits={loadRecruits}
                        clearRecruits={clearRecruits}
                        onPageChange={onPageChange}
                        updateRecruit={updateRecruit}
                        deleteRecruit={deleteRecruit}
                        addAll={addAll}
                    >
                        <Switch>
                            <Route path={`${match.path}add/`}>
                                <AddRecruit
                                    id={`${id}-recruits-add`}
                                    translate={translate}
                                    asyncState={showRecruitsAdd}
                                    members={members}
                                    load={loadMembers}
                                    onSubmit={addRecruits}
                                    addAll={addAll}
                                    onClose={() =>
                                        history.push(
                                            `${stripslash(match.url)}/`,
                                        )
                                    }
                                    excluded={
                                        (recruitsState.data &&
                                            recruitsState.data.content.map(
                                                recruit =>
                                                    recruit.membershipUuid,
                                            )) ||
                                        []
                                    }
                                    disabled={recruitsState.loading}
                                />
                            </Route>
                        </Switch>
                    </PoolRecruits>
                    <Divider />
                    <PoolSettings
                        id={`${id}-settings`}
                        pool={pool}
                        translate={translate}
                        disabled={
                            isExpired ||
                            disabled ||
                            !acl("events.recruitment.update")
                        }
                        onSubmit={onSave}
                        asyncState={editState}
                    />
                </Paper>
            </div>
        );
    }
}

const Wrapper = withRouter<any>(
    withAcl(
        withTranslate(
            connect(
                // Map state to props
                ({
                    eventRecruits: recruitsState,
                    showRecruitsAdd,
                    eventRecruitsUpdate,
                    eventRecruitsDelete,
                    members,
                }) => ({
                    recruitsState: {
                        ...mapState(recruitsState, SHOW_RECRUITS_ERROR),
                        page:
                            recruitsState.params &&
                            recruitsState.params.path &&
                            recruitsState.params.path.page,
                    },
                    showRecruitsAdd,
                    members: members.toJS(),
                    eventRecruitsUpdate: {
                        ...mapState(
                            eventRecruitsUpdate,
                            SHOW_RECRUITS_UPDATE_ERROR,
                        ),
                        error: eventRecruitsUpdate.error
                            ? eventRecruitsUpdate.error.messages &&
                              eventRecruitsUpdate.error.messages.staffingStatus
                                ? eventRecruitsUpdate.error.messages
                                      .staffingStatus
                                : eventRecruitsUpdate.error.message
                                ? eventRecruitsUpdate.error.message
                                : SHOW_RECRUITS_UPDATE_ERROR
                            : null,
                    },
                    eventRecruitsDelete: {
                        ...mapState(
                            eventRecruitsDelete,
                            SHOW_RECRUITS_DELETE_ERROR,
                        ),
                        error: eventRecruitsDelete.error
                            ? eventRecruitsDelete.error.messages &&
                              eventRecruitsDelete.error.messages.staffingStatus
                                ? eventRecruitsDelete.error.messages
                                      .staffingStatus
                                : eventRecruitsDelete.error.message
                                ? eventRecruitsDelete.error.message
                                : SHOW_RECRUITS_DELETE_ERROR
                            : null,
                    },
                }),
                // Map dispatch to props
                (dispatch: *, { uuid, organisationUuid }) => ({
                    clearRecruits: () => dispatch(clear),
                    loadRecruits: (page, filter) =>
                        dispatch(load(uuid, page, filter)),
                    loadMembers: filter =>
                        dispatch(
                            loadMembers(
                                organisationUuid,
                                1,
                                filter,
                                [],
                                false,
                                true,
                            ),
                        ),
                    addRecruits: uuids =>
                        dispatch(addRecruits(uuid, uuids)).then(() =>
                            dispatch(reload()),
                        ),
                    updateRecruit: (uuid, status) =>
                        dispatch(updateRecruit(uuid, status)).then(() =>
                            dispatch(reload()),
                        ),
                    deleteRecruit: uuid =>
                        dispatch(deleteRecruit(uuid)).then(() =>
                            dispatch(reload()),
                        ),
                    addAll: uuid =>
                        dispatch(addAll(uuid)).then(() => dispatch(reload())),
                }),
            )(Pool),
        ),
    ),
);
const Routes = withRouter<any>(
    ({
        match,
        history,
        uuid,
        ...rest
    }: {
        match: Match,
        history: RouterHistory,
        uuid: string,
    }) => (
        <Switch>
            <Route path={`${match.path}page/:page/`}>
                <Wrapper
                    {...rest}
                    key={uuid}
                    uuid={uuid}
                    onPageChange={page =>
                        history.push(`${stripslash(match.url)}/page/${page}/`)
                    }
                />
            </Route>
            <Route>
                <Redirect to={`${stripslash(match.url)}/page/1/`} />
            </Route>
        </Switch>
    ),
);

export default Routes;
