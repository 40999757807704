// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ap1rTj0BsM0owyTbz8pTyQ\\=\\={max-height:9em;overflow-y:auto}.Ap1rTj0BsM0owyTbz8pTyQ\\=\\= li{padding-left:0;padding-right:0;margin-left:0;margin-right:0}.y4Su3eSOMt1fLZ7OcZW2ig\\=\\={background-color:#ededed;color:black;display:flex;align-items:center;justify-content:center;height:2em;width:2.1em;border-radius:1em;margin-right:1em}\n", "",{"version":3,"sources":["webpack://./../Persons.scss"],"names":[],"mappings":"AAAA,4BACI,cAAe,CACf,eAAgB,CAFpB,+BAIQ,cAAe,CACf,eAAgB,CAChB,aAAc,CACd,cAAe,CAClB,4BAGD,wBAAyB,CACzB,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,gBAAiB","sourcesContent":[".list {\n    max-height: 9em;\n    overflow-y: auto;\n    li {\n        padding-left: 0;\n        padding-right: 0;\n        margin-left: 0;\n        margin-right: 0;\n    }\n}\n.circle {\n    background-color: #ededed;\n    color: black;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 2em;\n    width: 2.1em;\n    border-radius: 1em;\n    margin-right: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "Ap1rTj0BsM0owyTbz8pTyQ==",
	"circle": "y4Su3eSOMt1fLZ7OcZW2ig=="
};
export default ___CSS_LOADER_EXPORT___;
