// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

export const Client = {
    /**
     * Show briefing getter
     */
    getShowLineTypeBriefing(uuid: string) {
        return this.instance
            .get(
                `/ays-series-event-management/events/${uuid}/line-types/briefings`,
            )
            .then(({ data }) => data)
            .catch(normalizeError);
    },

    updateShowLineTypeBriefing(
        uuid: string,
        briefing: string,
        preBriefing: string,
        lineTypeUuid: string,
        preBriefingNotification: boolean,
        briefingNotification: boolean,
        applySeries: { applyForSeries: boolean, eventUuids: string[] },
    ) {
        return this.instance
            .post(
                `/ays-series-event-management/events/${uuid}/line-types/briefings`,
                {
                    briefing,
                    lineTypeUuid,
                    preBriefing,
                    preBriefingNotification,
                    briefingNotification,
                    ...applySeries,
                },
            )
            .then(() => undefined)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
