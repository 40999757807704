// @flow

import createApiModule from "../create-api-module";
import qs from "qs";

/// import types

const { reducer, actions } = createApiModule(
    "staffingTemplates",
    ({ page }) =>
        `ays-venue-facility-management/staffing-templates/_search?${qs.stringify(
            { page: page - 1 },
        )}`,
    { method: "POST" },
);

export const load =
    (page: number, filter: string, force: boolean = false) =>
    (dispatch: *) =>
        dispatch(
            actions.load(
                {
                    path: { page },
                    data: { filter },
                },
                force,
            ),
        );

export const ERROR = "No staffing template found!";
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
