//@flow
import style from "./CalendarViewBody.scss";

import React, { Component } from "react";

import MOMENT from "moment";
import { extendMoment } from "moment-range";

import { type State as VenueState } from "../../../../../redux/modules/venues.d";
import { type Profile } from "../../../redux/modules/user.d";
import Button from "@material-ui/core/Button";

import Header from "./Header";
import Month from "./Month";
import Scrollbar from "../../../../Scrollbar";

type Props = {
    translate: *,
    calendar: *,
    venues: VenueState,
    activeMonth: *,
    toToday: boolean,
    afterToToday: () => void,
    onConfirmOptions: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: string) => void,

    isShowCanceled: boolean,
    scrollToDate: *,
    isScrollToDate: boolean,
    firstShowUuid: *,
    seriesUuid: *,
    acl: *,
    user: Profile,
    onLoadMore: (date: *, eventUuid?: string, serieUuid?: string) => void,
};

type State = {
    collapsedVenues: string[],
    activeEvent: ?{
        event: string,
        series: string,
    },
    scrollToDate: *,
};

const moment = extendMoment(MOMENT);

export default class CalendarViewBody extends Component<Props, State> {
    state = {
        collapsedVenues: [],
        activeEvent: null,
        scrollToDate: null,
    };

    static getDerivedStateFromProps(props: Props) {
        return {
            scrollToDate:
                props.scrollToDate &&
                MOMENT(props.scrollToDate).format("YYYY/MM/DD"),
            collapsedVenues: localStorage.getItem(
                "ays.calendar-overview.collapsedVenues",
            )
                ? JSON.parse(
                      localStorage.getItem(
                          "ays.calendar-overview.collapsedVenues",
                      ) || "[]",
                  )
                : [],
        };
    }

    container: *;

    constructor() {
        super();
        this.container = React.createRef();
    }

    componentDidMount() {
        this.initialView();
    }

    calendarDataByMonth(month: string) {
        const { calendar } = this.props;
        return (
            calendar &&
            calendar.dates &&
            calendar.dates.filter(day => day.date.includes(month))
        );
    }

    scrollTo(element: HTMLElement, top: boolean) {
        element.scrollIntoView(top);
    }

    initialView() {
        const {
            toToday,
            afterToToday,
            isScrollToDate,
            firstShowUuid,
            seriesUuid,
        } = this.props;
        const { scrollToDate } = this.state;

        if (isScrollToDate) {
            if (scrollToDate) {
                const todate = document.querySelector(
                    //$FlowFixMe
                    `#${CSS.escape(moment(scrollToDate).format("YYYY/MM/DD"))}`,
                );

                if (todate) this.scrollToToday(todate);
                afterToToday();

                this.toggleActiveEvent(firstShowUuid, seriesUuid);
            }
        }

        if (toToday) {
            const today = document.querySelector(
                //$FlowFixMe
                `#${CSS.escape(moment().format("YYYY/MM/DD"))}`,
            );

            if (today) this.scrollToToday(today);

            afterToToday();
        }
    }

    scrollToToday(element: HTMLElement) {
        element.scrollIntoView(true);
        if (this.container.current)
            this.container.current.element.current.scrollTop -= 40;
    }

    componentDidUpdate() {
        this.initialView();
    }

    activateEvent(eventUuid: string, seriesUuid: string) {
        this.setState({
            activeEvent: {
                event: eventUuid,
                series: seriesUuid,
            },
        });
    }

    clearActiveEvent() {
        this.setState({
            activeEvent: null,
        });
    }

    toggleActiveEvent(eventUuid: string, seriesUuid: string) {
        const { activeEvent } = this.state;

        if (activeEvent && activeEvent.event === eventUuid) {
            this.clearActiveEvent();
        } else {
            this.activateEvent(eventUuid, seriesUuid);
        }
    }

    collapsedVenueToggle(venueCode: string) {
        const { collapsedVenues } = this.state;
        const IcollapsedVenues = collapsedVenues;
        if (collapsedVenues.find(venue => venue === venueCode)) {
            IcollapsedVenues.splice(
                collapsedVenues.findIndex(venue => venue === venueCode),
                1,
            );
        } else {
            IcollapsedVenues.push(venueCode);
        }
        this.setState({
            collapsedVenues: IcollapsedVenues,
        });
        localStorage.setItem(
            "ays.calendar-overview.collapsedVenues",
            JSON.stringify(IcollapsedVenues),
        );
    }

    checkInView(element: HTMLElement) {
        if (this.container.current) {
            //Get container properties
            var rect = element.getBoundingClientRect(),
                top = rect.top,
                height = rect.height,
                el = element.parentNode;
            // Check if bottom of the element is off the page
            if (rect.bottom < 0) return false;
            // Check its within the document viewport
            //$FlowFixMe
            if (top > document.documentElement.clientHeight) return false;
            do {
                //$FlowFixMe
                rect = el.getBoundingClientRect();
                if (top <= rect.bottom === false) return false;
                // Check if the element is out of view due to a container scrolling
                if (top + height <= rect.top) return false;
                //$FlowFixMe
                el = el.parentNode;
            } while (el != document.body);
            return true;
        } else {
            return false;
        }
    }

    onEditOption = (uuid: string) => {
        const { onEditOption } = this.props;
        onEditOption(uuid);
    };
    /**
     * Render
     */
    render() {
        const { collapsedVenues, activeEvent } = this.state;
        const {
            venues,
            onConfirmOptions,
            onEditOption,
            onCancelOption,
            onEditSeries,
            onEditEvent,
            onMoveEvent,
            onEditConfirmedOption,
            onDefineEvents,
            onCancelEvent,
            onCancelConfirmedOption,
            isShowCanceled,
            translate,
            onAddOptionToSeries,
            onAddEventToSeries,
            onShowHistory,
            onDoubleClickEvent,
            acl,
            user,
            activeMonth,
        } = this.props;

        return (
            <Scrollbar ref={this.container} w={"100%"} h="calc(100vh - 310px)">
                <div className={style.inner}>
                    <Header
                        venues={venues}
                        collapsedVenues={collapsedVenues}
                        onHeadingClick={venueCode =>
                            this.collapsedVenueToggle(venueCode)
                        }
                    />
                    <div className={style.loadPreviousWrapper}>
                        <Button
                            id="load-previous-month"
                            className={style.loadMoreButton}
                            onClick={() =>
                                this.props.onLoadMore(
                                    moment(activeMonth)
                                        .startOf("month")
                                        .subtract(2, "months"),
                                    undefined,
                                    undefined,
                                )
                            }
                        >
                            {translate("Load Previous Month")}
                        </Button>
                    </div>
                    <div id="prevMonth">
                        <Month
                            acl={acl}
                            user={user}
                            translate={translate}
                            calendar={this.calendarDataByMonth(
                                moment(activeMonth)
                                    .subtract(1, "months")

                                    .format("YYYY-MM"),
                            )}
                            DATE={moment(activeMonth).subtract(1, "months")}
                            venues={venues}
                            collapsedVenues={collapsedVenues}
                            activeEvent={activeEvent}
                            toggleActiveEvent={(eventUuid, seriesUuid) =>
                                this.toggleActiveEvent(eventUuid, seriesUuid)
                            }
                            activateEvent={(eventUuid, seriesUuid) =>
                                this.activateEvent(eventUuid, seriesUuid)
                            }
                            clearActiveEvent={() => this.clearActiveEvent()}
                            onConfirmOptions={onConfirmOptions}
                            onEditOption={onEditOption}
                            onDefineEvents={onDefineEvents}
                            onCancelEvent={onCancelEvent}
                            onCancelConfirmedOption={onCancelConfirmedOption}
                            onCancelOption={onCancelOption}
                            onEditSeries={onEditSeries}
                            onEditEvent={onEditEvent}
                            onMoveEvent={onMoveEvent}
                            onEditConfirmedOption={onEditConfirmedOption}
                            isShowCanceled={isShowCanceled}
                            onAddOptionToSeries={onAddOptionToSeries}
                            onAddEventToSeries={onAddEventToSeries}
                            onShowHistory={onShowHistory}
                            onDoubleClickEvent={onDoubleClickEvent}
                        />
                    </div>
                    <div id="actualMonth">
                        <Month
                            acl={acl}
                            user={user}
                            translate={translate}
                            calendar={this.calendarDataByMonth(
                                moment(activeMonth).format("YYYY-MM"),
                            )}
                            DATE={activeMonth}
                            venues={venues}
                            collapsedVenues={collapsedVenues}
                            activeEvent={activeEvent}
                            toggleActiveEvent={(eventUuid, seriesUuid) =>
                                this.toggleActiveEvent(eventUuid, seriesUuid)
                            }
                            activateEvent={(eventUuid, seriesUuid) =>
                                this.activateEvent(eventUuid, seriesUuid)
                            }
                            clearActiveEvent={() => this.clearActiveEvent()}
                            onConfirmOptions={onConfirmOptions}
                            onEditOption={onEditOption}
                            onCancelOption={onCancelOption}
                            onDefineEvents={onDefineEvents}
                            onEditSeries={onEditSeries}
                            onEditEvent={onEditEvent}
                            onMoveEvent={onMoveEvent}
                            onEditConfirmedOption={onEditConfirmedOption}
                            onCancelEvent={onCancelEvent}
                            onCancelConfirmedOption={onCancelConfirmedOption}
                            isShowCanceled={isShowCanceled}
                            onAddOptionToSeries={onAddOptionToSeries}
                            onAddEventToSeries={onAddEventToSeries}
                            onShowHistory={onShowHistory}
                            onDoubleClickEvent={onDoubleClickEvent}
                        />
                    </div>
                    <div id="nextMonth">
                        <Month
                            acl={acl}
                            user={user}
                            translate={translate}
                            calendar={this.calendarDataByMonth(
                                moment(activeMonth)
                                    .add(1, "months")

                                    .format("YYYY-MM"),
                            )}
                            DATE={moment(activeMonth).add(1, "months")}
                            venues={venues}
                            collapsedVenues={collapsedVenues}
                            onDefineEvents={onDefineEvents}
                            activeEvent={activeEvent}
                            toggleActiveEvent={(eventUuid, seriesUuid) =>
                                this.toggleActiveEvent(eventUuid, seriesUuid)
                            }
                            activateEvent={(eventUuid, seriesUuid) =>
                                this.activateEvent(eventUuid, seriesUuid)
                            }
                            clearActiveEvent={() => this.clearActiveEvent()}
                            onConfirmOptions={onConfirmOptions}
                            onEditOption={onEditOption}
                            onCancelOption={onCancelOption}
                            onEditSeries={onEditSeries}
                            onEditEvent={onEditEvent}
                            onMoveEvent={onMoveEvent}
                            onEditConfirmedOption={onEditConfirmedOption}
                            onCancelEvent={onCancelEvent}
                            onCancelConfirmedOption={onCancelConfirmedOption}
                            isShowCanceled={isShowCanceled}
                            onAddOptionToSeries={onAddOptionToSeries}
                            onAddEventToSeries={onAddEventToSeries}
                            onShowHistory={onShowHistory}
                            onDoubleClickEvent={onDoubleClickEvent}
                        />
                    </div>
                    <div className={style.loadMoreWrapper}>
                        <Button
                            id="load-next-month"
                            className={style.loadMoreButton}
                            onClick={() =>
                                this.props.onLoadMore(
                                    moment(activeMonth)
                                        .startOf("month")
                                        .add(2, "months"),
                                    undefined,
                                    undefined,
                                )
                            }
                        >
                            {translate("Load Next Month")}
                        </Button>
                    </div>
                </div>
            </Scrollbar>
        );
    }
}
