// @flow

// Import styles
import styles from "./VenueBox.scss";

// Import libs
import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { type Venue } from "../../redux/modules/venues.d";
import { getColor, getFontColor } from "../../utils/VenueColors";

// Define props
type PropsType = {
    /** Venue code */
    venue: Venue,
};
/**
 * A dummy component
 */
export default class VenueBox extends Component<PropsType> {
    static defaultProps = {
        color: "#7343B9",
    };

    /**
     * Render
     */
    render() {
        const { venue } = this.props;

        return (
            <Tooltip
                title={venue.name}
                PopperProps={{ style: { pointerEvents: "none" } }}
            >
                <div className={styles.item}>
                    <div
                        className={styles.venue}
                        style={{
                            backgroundColor: getColor(venue.code),
                            color: getFontColor(venue.code),
                        }}
                    >
                        <p className={styles.inner}>{venue.code}</p>
                    </div>
                </div>
            </Tooltip>
        );
    }
}
