// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5n4218iBq-MHw9ykAFu5hA\\=\\={background-color:#fff;min-height:calc(100% - 13.25em)}\n", "",{"version":3,"sources":["webpack://./../FacilityView.scss","webpack://./../../../styles/_variables.scss"],"names":[],"mappings":"AAGA,6BACI,qBCAQ,CDCR,+BAAgC","sourcesContent":["// Styling\n\n@import \"../../styles/variables\";\n.wrapper {\n    background-color: $white;\n    min-height: calc(100% - 13.25em);\n}\n","/**\n * Variables\n */\n\n$white: #fff;\n$black: #000;\n$onyx: #181c20;\n$deep-grey: #383e42;\n$neutral-grey: #888;\n$purple: #651f82;\n$light-gray: #ccc;\n$dark-blue: #298fc2;\n$yellow: #fed141;\n$orange: #ff9618;\n$red: #f00;\n$deep-red: #ae1616;\n$green: #56c271;\n$near-white: #eee;\n\n/**\n * Some additional color variants out of color palette\n * a bit lighter or darker for hover etc.\n */\n$darker-purple: #521a6b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_5n4218iBq-MHw9ykAFu5hA=="
};
export default ___CSS_LOADER_EXPORT___;
