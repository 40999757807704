// @flow

import styles from "./TeamList.scss";

import React, { Component } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { type State as TeamState } from "../../../redux/modules/event-line-team.d";
import { type User as UserState } from "../../../redux/modules/user.d";

import TeamMember from "./TeamMember";

type Props = {
    id: string,
    translate: *,
    active: boolean,
    team: TeamState,
    toggle: () => void,
    user: UserState,
    remove: (uuid: string) => *,
    promote: (uuid: string) => *,
    demote: (uuid: string) => *,
    assign: (uuid: string) => *,
    unassign: (uuid: string) => *,
    generateQRCode: (uuid: string) => *,
    onClick: (uuid: string) => void,
    canAdd: boolean,
    canEdit: boolean,
    canDelete: boolean,
    canGenerate: boolean,
    planable: boolean,
};

export default class TeamList extends Component<Props> {
    /**
     * Render
     */
    render() {
        const {
            translate,
            team,
            id,
            remove,
            promote,
            demote,
            assign,
            unassign,
            toggle,
            canAdd,
            canEdit,
            canDelete,
            canGenerate,
            active,
            generateQRCode,
            onClick,
            user,
            planable,
        } = this.props;

        return (
            <Paper className={styles.wrapper} elevation={2}>
                <Table>
                    <TableBody>
                        {team.data &&
                            team.data.teamMembers.map((member, index) => (
                                <TeamMember
                                    key={member.teamMemberUuid}
                                    id={`${id}-member`}
                                    index={index}
                                    translate={translate}
                                    member={member}
                                    onDelete={remove}
                                    onPromote={promote}
                                    isMyMember={
                                        user &&
                                        user.data &&
                                        user.data.profile &&
                                        user.data.profile
                                            .organisation_leader_organisations
                                            ? (user.data.profile
                                                  .organisation_leader_organisations: string).includes(
                                                  member.organisationUuid,
                                              )
                                                ? true
                                                : false
                                            : true
                                    }
                                    onDemote={demote}
                                    onAssign={assign}
                                    onUnassign={unassign}
                                    canEdit={canEdit}
                                    canDelete={
                                        canDelete &&
                                        member.presenceStatus !== "CHECKED_IN"
                                    }
                                    onClick={onClick}
                                    canGenerate={canGenerate}
                                    generateQRCode={generateQRCode}
                                    active={active}
                                />
                            ))}
                    </TableBody>
                </Table>
                <List>
                    {canAdd && active && planable && (
                        <ListItem
                            button
                            id="assign-team-member"
                            onClick={() => toggle()}
                        >
                            <span className={styles.emptyAvatar} />
                            <ListItemText
                                className={styles.add}
                                color={"inherit"}
                                disableTypography
                                primary={translate("Assign team member")}
                            />
                        </ListItem>
                    )}
                </List>
            </Paper>
        );
    }
}
