// @flow

import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

import HeaderRow from "./HeaderRow";
import DateRow from "./DateRow";

type Props = {
    venues: *[],
    dates: *[],
    translate: *,
};

/**
 * Styling
 */
const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        padding: 10,
    },
});

/**
 * Renders a table with options and events
 */
const Table = ({ venues, dates, translate }: Props) => (
    <View style={styles.table}>
        <HeaderRow venues={venues} translate={translate} />
        {dates.map((date, index) => (
            <DateRow key={index} date={date} venues={venues} />
        ))}
    </View>
);

export default Table;
