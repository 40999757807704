// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "organisers",
    () => "/ays-user-management/promoters",
);

export const ERROR = "Could not fetch promoter";
export const load = actions.load;
export const clear = actions.clear;
export const refetch = actions.refetch;
export default reducer;
