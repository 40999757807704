// @flow

import createApiModule from "../create-api-module";
import qs from "qs";
import { type PathParams } from "./event-recruits.d";

const { reducer, actions } = createApiModule(
    "eventRecruits",
    ({ uuid, page }: PathParams) =>
        `/ays-searches/searches/recruitment-pools/${uuid}/recruits?${qs.stringify(
            { page: page - 1 },
            { arrayFormat: "repeat" },
        )}`,
    { method: "POST" },
);

export const load =
    (uuid: string, page: number, filter: string, force: boolean = false) =>
    (dispatch: *) => {
        return dispatch(
            actions.load(
                {
                    path: {
                        uuid,
                        page,
                    },
                    data: { filter },
                },
                force,
            ),
        );
    };

export const ERROR = "Could not find any recruits!";
export const clear = actions.clear;
export const refetch = actions.refetch;

export default reducer;
