// @flow

/**
 * User roles
 */
export const ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR";
export const ROLE_FACILITY_MANAGER = "ROLE_FACILITY_MANAGER";
export const ROLE_EVENT_MANAGER = "ROLE_EVENT_MANAGER";
export const ROLE_ORGANISATION_LEADER = "ROLE_ORGANISATION_LEADER";
export const ROLE_ACCESS_CONTROL = "ROLE_ACCESS_CONTROL";

/**
 * User permissions mapping
 */
export default {
    organisations: {
        create: [ROLE_FACILITY_MANAGER],
        read: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
        update: [ROLE_FACILITY_MANAGER],
        delete: [],
        members: {
            create: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
            read: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
            update: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
            delete: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
            approve: [ROLE_FACILITY_MANAGER],
        },
        lines: {
            create: [ROLE_FACILITY_MANAGER],
            read: [ROLE_FACILITY_MANAGER],
            update: [ROLE_FACILITY_MANAGER],
            delete: [ROLE_FACILITY_MANAGER],
        },
    },
    events: {
        delete: [ROLE_EVENT_MANAGER],
        create: [ROLE_EVENT_MANAGER],
        read: [
            ROLE_ORGANISATION_LEADER,
            ROLE_FACILITY_MANAGER,
            ROLE_EVENT_MANAGER,
            ROLE_ACCESS_CONTROL,
        ],
        update: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
        details: {
            parking: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
            update: [ROLE_EVENT_MANAGER],
        },
        briefing: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
        lines: {
            create: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
            read: [
                ROLE_FACILITY_MANAGER,
                ROLE_ORGANISATION_LEADER,
                ROLE_ACCESS_CONTROL,
            ],
            update: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
            updateoverride: [],
        },
        qrcode: {
            read: [ROLE_FACILITY_MANAGER],
        },
        exportData: {
            read: [ROLE_EVENT_MANAGER, ROLE_ADMINISTRATOR],
        },
        attendees: {
            read: [
                ROLE_FACILITY_MANAGER,
                ROLE_EVENT_MANAGER,
                ROLE_ACCESS_CONTROL,
            ],
        },
        csv: {
            read: [ROLE_FACILITY_MANAGER, ROLE_EVENT_MANAGER],
        },
        organisations: {
            create: [ROLE_FACILITY_MANAGER],
            read: [
                ROLE_EVENT_MANAGER,
                ROLE_FACILITY_MANAGER,
                ROLE_ORGANISATION_LEADER,
            ],
            update: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
            delete: [ROLE_FACILITY_MANAGER],
        },
        issues: {
            read: [ROLE_FACILITY_MANAGER],
        },
        team: {
            create: [ROLE_ORGANISATION_LEADER],
            read: [
                ROLE_ORGANISATION_LEADER,
                ROLE_FACILITY_MANAGER,
                ROLE_EVENT_MANAGER,
                ROLE_ACCESS_CONTROL,
            ],
            qrcode: [ROLE_ACCESS_CONTROL, ROLE_FACILITY_MANAGER],
            update: [ROLE_ORGANISATION_LEADER, ROLE_FACILITY_MANAGER],
            delete: [ROLE_ORGANISATION_LEADER],
        },
        recruitment: {
            create: [ROLE_ORGANISATION_LEADER],
            read: [ROLE_ORGANISATION_LEADER],
            update: [ROLE_ORGANISATION_LEADER],
            delete: [ROLE_ORGANISATION_LEADER],
            recruit: {
                create: [ROLE_ORGANISATION_LEADER],
                read: [ROLE_ORGANISATION_LEADER],
                update: [ROLE_ORGANISATION_LEADER],
                delete: [ROLE_ORGANISATION_LEADER],
            },
        },
    },
    calendarOverview: {
        read: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
        update: [ROLE_EVENT_MANAGER],
    },
    facilityView: {
        read: [ROLE_FACILITY_MANAGER, ROLE_ORGANISATION_LEADER],
        seeOpen: [ROLE_FACILITY_MANAGER],
    },
    admin: {
        create: [],
        read: [],
        update: [],
        delete: [],
    },
    staffingTemplates: {
        create: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
        read: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
        update: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
        delete: [ROLE_EVENT_MANAGER, ROLE_FACILITY_MANAGER],
    },
};
