// @flow

import React from "react";
import { Image, View, Text, StyleSheet } from "@react-pdf/renderer";

import VenueLogo from "./VenueLogo";

type Props = {
    venues: *[],
};

const getStyles = venues =>
    StyleSheet.create({
        row: {
            margin: "auto",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "#d6d6d6",
            // height: 32,
        },
        dateColumn: {
            width: "10%",
        },
        image: {
            height: 64,
            width: 52,
            marginTop: 2.4,
            marginBottom: 10,
        },
        venueColumn: {
            width: `${90 / venues.length}%`,
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
        },
    });

/**
 * Renders the header row
 */
const HeaderRow = ({ venues }: Props) => {
    const styles = getStyles(venues);
    return (
        <View style={styles.row} fixed>
            <View style={styles.dateColumn}>
                <Image src="/logo.jpg" style={styles.image} />
            </View>
            {venues.map(venue => (
                <View key={venue.uuid} style={styles.venueColumn}>
                    <VenueLogo venueCode={venue.code} />
                    <Text style={{ textAlign: "center", width: "100%" }}>
                        {venue.name}
                    </Text>
                </View>
            ))}
        </View>
    );
};

export default HeaderRow;
