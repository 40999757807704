// @flow

import { fromJS } from "immutable";
import invariant from "invariant";

import { load as loadLines } from "./event-lines";

import { typeof Client } from "../../utils/api-client/modules/event-line-organisations";
import {
    type ImmutableState,
    type Action,
    type FullState,
} from "./event-line-organisations.d";

const ERROR = "Could not fetch eventline organisations";
const ERROR_DELETESHOWLINEORGANISATION =
    "Could not delete the eventline organisation";
const ERROR_ACTIVATESHOWLINEORGANISATION =
    "Could not activate the eventline organisation";
const ERROR_DEACTIVATESHOWLINEORGANISATION =
    "Could not deactivate the eventline organisation";
const ERROR_ADDSHOWLINEORGANISATION =
    "Could not deactivate the eventline organisation";

const ZONE = "event-line-organisations";

const CLEAR = `${ZONE}/clear`;

const PENDING = `${ZONE}/pending`;
const SUCCESS = `${ZONE}/success`;
const FAILURE = `${ZONE}/failure`;

const DELETE_SUCCESS = `${ZONE}/delete/success`;
const DELETE_PENDING = `${ZONE}/delete/pending`;
const DELETE_FAILED = `${ZONE}/delete/failure`;

const ACTIVATE_SUCCESS = `${ZONE}/activate/success`;
const ACTIVATE_PENDING = `${ZONE}/activate/pending`;
const ACTIVATE_FAILED = `${ZONE}/activate/failure`;

const ADD_SUCCESS = `${ZONE}/add/success`;
const ADD_PENDING = `${ZONE}/add/pending`;
const ADD_FAILED = `${ZONE}/add/failure`;

const DEACTIVATE_SUCCESS = `${ZONE}/deactivate/success`;
const DEACTIVATE_PENDING = `${ZONE}/deactivate/pending`;
const DEACTIVATE_FAILED = `${ZONE}/deactivate/failure`;

const initialState: ImmutableState = (fromJS({
    loading: false,
    error: null,
    data: null,
    uuid: null,
}): any);

/**
 * Reducer
 */
export default (
    state: ImmutableState = initialState,
    { type, payload }: Action,
): * => {
    if (type === CLEAR) {
        return initialState;
    }

    if (type === PENDING) {
        invariant(typeof payload === "string", `Wrong payload for ${PENDING}`);

        return state.merge({
            loading: true,
            error: null,
            uuid: payload,
        });
    }

    if (type === SUCCESS) {
        invariant(payload, `Wrong payload for ${SUCCESS}`);

        return state.merge({
            loading: false,
            error: null,
            data: fromJS(payload),
        });
    }

    if (type === FAILURE) {
        invariant(typeof payload === "string", `Wrong payload for ${FAILURE}`);

        return state.merge({
            loading: false,
            error: payload,
            data: null,
        });
    }

    return state;
};

/**
 * ActionCreator: clear
 */
export const clear = () => ({
    type: CLEAR,
});

/**
 * ActionCreator: load
 */
export const load =
    (uuid: string, force: boolean = false) =>
    (
        dispatch: (action: Action) => void,
        getState: () => FullState,
        client: Client,
    ) => {
        const { eventLineOrganisations: state } = getState();

        if (!force && (state.get("loading") || state.get("uuid") === uuid)) {
            return Promise.resolve();
        }

        dispatch({
            type: PENDING,
            payload: uuid,
        });

        return client
            .getShowLineOrganisations(uuid)
            .then(payload => {
                dispatch({
                    type: SUCCESS,
                    payload,
                });
            })
            .catch(error => {
                dispatch({
                    type: FAILURE,
                    payload: (error && error.message) || ERROR,
                });

                throw error;
            });
    };

/**
 * Actioncreator: delete line organisation
 */
export const remove =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().eventLineOrganisations;
        const stateShowLine = getState().eventLines;
        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Remove from list and set to loading
        dispatch({
            type: DELETE_PENDING,
        });

        return client
            .deleteShowLineOrganisation(state.get("uuid"), uuid)
            .then(() => {
                dispatch({
                    type: DELETE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(stateShowLine.params.path.uuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: DELETE_FAILED,
                    payload: {
                        error:
                            (error && error.message) ||
                            ERROR_DELETESHOWLINEORGANISATION,
                    },
                });

                return false;
            });
    };

/**
 * Actioncreator: make line organisation active
 */
export const activate =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().eventLineOrganisations;
        const stateShowLine = getState().eventLines;
        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: ACTIVATE_PENDING,
        });

        return client
            .activateShowLineOrganisation(uuid)
            .then(() => {
                dispatch({
                    type: ACTIVATE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(stateShowLine.params.path.uuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: ACTIVATE_FAILED,
                    payload: {
                        error:
                            (error && error.message) ||
                            ERROR_ACTIVATESHOWLINEORGANISATION,
                    },
                });

                return false;
            });
    };

/**
 * Actioncreator: make line organisation inactive
 */
export const deactivate =
    (uuid: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().eventLineOrganisations;
        const stateShowLine = getState().eventLines;
        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: DEACTIVATE_PENDING,
        });

        return client
            .deactivateShowLineOrganisation(uuid)
            .then(() => {
                dispatch({
                    type: DEACTIVATE_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(stateShowLine.params.path.uuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: DEACTIVATE_FAILED,
                    payload: {
                        error:
                            (error && error.message) ||
                            ERROR_DEACTIVATESHOWLINEORGANISATION,
                    },
                });

                return false;
            });
    };
/**
 * Actioncreator: make line organisation inactive
 */
export const add =
    (uuid: string, type: string) =>
    (dispatch: *, getState: *, client: Client): Promise<void> => {
        const state = getState().eventLineOrganisations;
        const stateShowLine = getState().eventLines;
        if (state.get("loading")) {
            return Promise.resolve();
        }

        // Change status and set to loading
        dispatch({
            type: ADD_PENDING,
        });

        return client
            .addShowLineOrganisation(state.get("uuid"), uuid, type)
            .then(() => {
                dispatch({
                    type: ADD_SUCCESS,
                });
                return dispatch(load(state.get("uuid"), true)).then(
                    dispatch(loadLines(stateShowLine.params.path.uuid, true)),
                );
            })
            .catch(error => {
                dispatch({
                    type: ADD_FAILED,
                    payload: {
                        error:
                            (error && error.message) ||
                            ERROR_ADDSHOWLINEORGANISATION,
                    },
                });

                return false;
            });
    };
