// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";
import qs from "qs";

export const Client = {
    /**
     * Organisation members getter
     */
    getOrganisationMembers(
        organisationUUID: string,
        page: number,
        filter: string,
        filterDeclined: boolean = false,
        sorting: string[],
    ) {
        return this.instance
            .post(
                `/ays-searches/searches/organisations/${organisationUUID}/memberships`,
                {
                    filter,
                    filterDeclined,
                },
                {
                    params: {
                        page: page - 1,
                        sort: sorting,
                    },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                },
            )
            .then(({ data }) => data)
            .catch(normalizeError);
    },

    /**
     * Get membership
     */
    getMembership(uuid: string) {
        return this.instance
            .get(`/ays-organisation-management/memberships/${uuid}`)
            .then(({ data }) => data)
            .catch(normalizeError);
    },

    /**
     * Update membership
     */
    putMembership(
        uuid: string,
        description: string,
        noSmartphone: boolean,
        firstName: string,
        lastName: string,
    ) {
        return this.instance
            .put(`/ays-organisation-management/memberships/${uuid}`, {
                description,
                noSmartphone,
                firstName,
                lastName,
            })
            .then(() => undefined)
            .catch(normalizeError);
    },

    /**
     * Delete membership
     */
    deleteMembership(uuid: string) {
        return this.instance
            .delete(`/ays-organisation-management/memberships/${uuid}`)
            .then(() => undefined)
            .catch(normalizeError);
    },

    /**
     * Make a member an organisation leader
     */
    makeLeader(uuid: string) {
        return this.instance
            .post(
                `/ays-organisation-management/memberships/${uuid}/make-leader`,
                {},
            )
            .then(() => null)
            .catch(normalizeError);
    },

    /**
     * Make a leader a member
     */
    makeMember(uuid: string) {
        return this.instance
            .post(
                `/ays-organisation-management/memberships/${uuid}/make-member`,
                {},
            )
            .then(() => null)
            .catch(normalizeError);
    },

    /**
     * Approve a non smartphone member
     */
    approveNonSmartphoneUser(uuid: string) {
        return this.instance
            .post(
                `/ays-organisation-management/memberships/${uuid}/_approve-no-smartphone-user`,
                {},
            )
            .then(() => null)
            .catch(normalizeError);
    },

    /**
     * Deny a non smartphone member
     */
    denyNonSmartphoneUser(uuid: string) {
        return this.instance
            .post(
                `/ays-organisation-management/memberships/${uuid}/_deny-no-smartphone-user`,
                {},
            )
            .then(() => null)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
