// @flow
import createApiModule from "../create-api-module";
import type { PathParams } from "./admin-user-delete.d";

import { refetch as reload } from "./admin";
/**
 * AdminUserDelete Module
 */
const { reducer, actions } = createApiModule(
    "adminUserDelete",
    ({ userUuid }: PathParams) => `ays-user-management/users/${userUuid}`,
    {
        method: "DELETE",
    },
);

export const load = (userUuid: string) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { userUuid },
        }),
    ).then(() => dispatch(reload()));
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not delete member!";
/**  reducer**/
export default reducer;
