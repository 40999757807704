// @flow

import React from "react";

type Props = {
    translate: *,
};

const NotFound = ({ translate }: Props) => (
    <h1>{translate("404: Page not found!")}</h1>
);

export default NotFound;
