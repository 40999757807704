// @flow

/**
 * Module: Add Show
 */
import createApiModule from "../create-api-module";
import { type Pool, type PathParams } from "./event-recruitment-update.d";

const { reducer, actions } = createApiModule(
    "showRecruitmentUpdate",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/recruitment-pools/${uuid}`,
    {
        method: "PUT",
    },
);
export const load = (uuid: string, data: Pool) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
            data,
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not update recruitment pool!";

export default reducer;
