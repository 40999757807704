//@flow
import styles from "./DayItem.scss";

import React, { Component } from "react";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";

import EmptyDayItem from "./EmptyDayItem";
import MenuTrigger from "./MenuTrigger";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    translate: *,
    venue: *,
    id: string,
    day: *,
    calendarDay: *,
    collapsedVenues: string[],
    activeEvent: ?{
        event: string,
        series: string,
    },
    clearActiveEvent: () => void,
    activateEvent: (eventUuid: string, seriesUuid: string) => void,
    toggleActiveEvent: (eventUuid: string, seriesUuid: string) => void,
    onConfirmOptions: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: string) => void,
    isShowCanceled: boolean,
    acl: *,
    user: Profile,
};

export default class DayItem extends Component<Props> {
    handleClick(data: *) {
        const { clearActiveEvent } = this.props;
        alert(data);
        clearActiveEvent();
    }

    get EventsByVenue() {
        const { calendarDay, venue } = this.props;
        return (
            calendarDay &&
            calendarDay.venues &&
            calendarDay.venues.filter(test => test.venueUuid === venue.uuid)
        );
    }

    /**
     * Render
     */
    render() {
        const {
            translate,
            venue,
            collapsedVenues,
            id,
            activateEvent,
            activeEvent,
            clearActiveEvent,
            toggleActiveEvent,
            day,
            onConfirmOptions,
            onEditOption,
            onCancelOption,
            onEditSeries,
            onEditEvent,
            onMoveEvent,
            onEditConfirmedOption,
            onDefineEvents,
            onCancelEvent,
            onCancelConfirmedOption,
            isShowCanceled,
            onAddOptionToSeries,
            onAddEventToSeries,
            onShowHistory,
            onDoubleClickEvent,
            acl,
            user,
        } = this.props;
        const canUpdate = acl("calendarOverview.update");

        return (
            <Grid
                id={id}
                item
                xs
                className={classnames(styles.element, {
                    collapsed: collapsedVenues.find(
                        venueItem => venueItem === venue.code,
                    ),
                })}
            >
                {this.EventsByVenue && this.EventsByVenue.length > 0
                    ? this.EventsByVenue.map(eventVenue => (
                          <div className={styles.lines} key={`${id}`}>
                              {eventVenue.events.map((event, index) => (
                                  <div
                                      id={`${id}-${event.uuid}`}
                                      key={event.uuid || index}
                                  >
                                      <MenuTrigger
                                          user={user}
                                          event={event}
                                          isShowCanceled={isShowCanceled}
                                          venue={venue}
                                          id={id}
                                          collapsedVenues={collapsedVenues}
                                          activateEvent={activateEvent}
                                          activeEvent={activeEvent}
                                          clearActiveEvent={clearActiveEvent}
                                          toggleActiveEvent={toggleActiveEvent}
                                          onConfirmOptions={onConfirmOptions}
                                          onEditOption={onEditOption}
                                          onCancelOption={onCancelOption}
                                          onEditSeries={onEditSeries}
                                          onEditEvent={onEditEvent}
                                          onMoveEvent={onMoveEvent}
                                          onEditConfirmedOption={
                                              onEditConfirmedOption
                                          }
                                          onDefineEvents={onDefineEvents}
                                          onCancelEvent={onCancelEvent}
                                          onCancelConfirmedOption={
                                              onCancelConfirmedOption
                                          }
                                          onAddOptionToSeries={
                                              onAddOptionToSeries
                                          }
                                          onAddEventToSeries={
                                              onAddEventToSeries
                                          }
                                          canUpdate={canUpdate}
                                          translate={translate}
                                          onShowHistory={onShowHistory}
                                          onDoubleClickEvent={
                                              onDoubleClickEvent
                                          }
                                      />
                                  </div>
                              ))}
                          </div>
                      ))
                    : canUpdate && (
                          <EmptyDayItem
                              id={id}
                              translate={translate}
                              day={day}
                              onAddEventToSeries={onAddEventToSeries}
                              onAddOptionToSeries={onAddOptionToSeries}
                          />
                      )}
            </Grid>
        );
    }
}
