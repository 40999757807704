// @flow

import styles from "./style.module.scss";
import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import SearchIcon from "@material-ui/icons/Search";
import FormHelperText from "@material-ui/core/FormHelperText";

import Line from "../../../LineSelector/Line";

type Props = {
    translate: *,
    onClose: () => void,
    onSubmit: (selected: []) => void,
    lines: {
        name: string,
        uuid: string,
    }[],
    addState: *,
};

const LineSelector = ({
    translate,
    onClose,
    onSubmit,
    lines,
    addState,
}: Props) => {
    const [selected, setSelected] = useState([]);
    const [filter, setFilter] = useState("");

    const onToggle = (uuid: string) => {
        const selectedList = selected;

        if (selectedList.includes(uuid)) {
            selectedList.splice(selectedList.indexOf(uuid), 1);
            setSelected([...selectedList]);
            return;
        }
        selectedList.push(uuid);
        setSelected([...selectedList]);
        return;
    };

    return (
        <Dialog open onClose={onClose}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit(selected);
                }}
            >
                <DialogTitle disableTypography>
                    <Typography variant="h2">
                        {translate("Add lines")}
                    </Typography>
                    <Typography>
                        {translate(
                            "Choose one or more lines to add to this staffing template",
                        )}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        id="staffingtemplate-lineselector-search"
                        // disabled
                        value={filter}
                        onChange={({ target: { value } }) => setFilter(value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        placeholder={translate("Filter by name")}
                    />
                    <div className={styles.scroll}>
                        <List>
                            {lines &&
                                lines
                                    .filter(line =>
                                        line.name
                                            .toLowerCase()
                                            .includes(filter.toLowerCase()),
                                    )
                                    .map(line => (
                                        <Line
                                            key={line.uuid}
                                            line={line}
                                            onToggle={() => onToggle(line.uuid)}
                                            selected={selected.includes(
                                                line.uuid,
                                            )}
                                        />
                                    ))}
                        </List>
                    </div>
                    {addState.error && (
                        <FormHelperText
                            id={`add-staffingtemplate-lines-helper-text`}
                            error
                        >
                            {addState.error}
                        </FormHelperText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        id="lineselector-cancel"
                        onClick={onClose}
                        disabled={addState.loading}
                        className={styles.cancel}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id="lineselector-confirm"
                        disabled={addState.loading}
                        type="submit"
                        className={styles.add}
                    >
                        {translate("Add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default LineSelector;
