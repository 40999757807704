// @flow

import styles from "./index.style.scss";

import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { type FieldErrors } from "../../../../utils/FormError";

type Props = {
    translate: *,
    briefings: {
        preBriefing: string,
        briefing: string,
    },
    onChange: (obj: { preBriefing: string, briefing: string }) => void,
    disabled: boolean,
    lite: boolean,
    fieldErrors: ?FieldErrors,
};

const Briefings = ({
    translate,
    briefings,
    onChange,
    disabled,
    lite,
    fieldErrors,
}: Props) => (
    <Grid container spacing={3} className={!lite ? styles.wrapper : ""}>
        {!lite && (
            <Grid item sm={3}>
                <Typography>{translate("Briefings")}</Typography>
            </Grid>
        )}
        <Grid item sm={lite ? 12 : 9}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <TextField
                        disabled={disabled}
                        fullWidth
                        rows={4}
                        multiline
                        label={translate("Prebriefing")}
                        value={briefings.preBriefing}
                        onChange={({ target: { value } }) =>
                            onChange({ ...briefings, preBriefing: value })
                        }
                        error={fieldErrors?.preBriefing}
                        helperText={fieldErrors?.preBriefing}
                    />
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        disabled={disabled}
                        rows={4}
                        multiline
                        fullWidth
                        label={translate("Briefing")}
                        value={briefings.briefing}
                        onChange={({ target: { value } }) =>
                            onChange({ ...briefings, briefing: value })
                        }
                        error={fieldErrors?.briefing}
                        helperText={fieldErrors?.briefing}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default Briefings;
