// @flow

import styles from "./UploadPhoto.scss";

import React from "react";

type Props = {
    onChange: () => void,
    previewImage: File,
    disabled: boolean,
};

const UploadPhoto = ({ onChange, previewImage, disabled }: Props) => (
    <div className={styles.container}>
        <div className={styles.title}>Logo</div>
        {previewImage && (
            <img
                width="100"
                height="100"
                className={styles.preview}
                src={previewImage}
                alt="image preview"
            />
        )}
        <div className={styles.buttonWrapper}>
            <label htmlFor="fileUpload" className={styles.button}>
                Select logo
            </label>
            <input
                id="fileUpload"
                type="file"
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    </div>
);

export default UploadPhoto;
