//@flow

import React, { useEffect, useState } from "react";
import { CircularProgress, FormControl, FormLabel } from "@material-ui/core";

import Dialog from "../../Dialog/Dialog";
import type { SeriesEvent } from "../../../redux/modules/series-events.d";
import type {
    State,
    LineTypeOccupationBulkUpdateData,
    LineTypeHoursBulkUpdateData,
    LineTypeBriefingsBulkUpdateData,
} from "../../../redux/modules/event-linetype-bulk-update.d";
import CheckboxGroup from "../../CheckboxGroup";
import Hours from "../../StaffingTemplates/Lines/LineDetails/Hours";
import Occupancy from "../../StaffingTemplates/Lines/LineDetails/Occupancy";
import Briefings from "../../StaffingTemplates/Lines/LineDetails/Briefings";

type BulkEditAction = "updateHours" | "updateOccupation" | "updateBriefings";
type BulkEditLineType = {
    uuid: string,
    name: string,
};

export type LineTypeBulkEditProps = {
    action: BulkEditAction,
    lineType: BulkEditLineType,
};

type Props = {
    translate: *,
    update: (
        lineTypeUuid: string,
        data:
            | LineTypeOccupationBulkUpdateData
            | LineTypeHoursBulkUpdateData
            | LineTypeBriefingsBulkUpdateData,
    ) => Promise<void>,
    clear: () => void,
    onClose: () => void,
    onInit: () => void,
    lineType: BulkEditLineType,
    action: BulkEditAction,
    events?: SeriesEvent[],
    bulkUpdateState: State,
    eventHours: {
        start: string,
        end: string,
    },
};

const TITLE_MAP = {
    updateHours: "Update hours",
    updateOccupation: "Update occupation",
    updateBriefings: "Update briefings",
};

const LineTypeBulkEdit = ({
    translate,
    update,
    clear,
    onClose,
    onInit,
    lineType,
    action,
    events,
    bulkUpdateState,
    eventHours,
}: Props) => {
    const title = TITLE_MAP[action];
    const id = title.toLowerCase().replace(/ /g, "-");

    const [occupancy, setOccupancy] = useState({
        min: 1,
        max: 1,
    });

    const [hoursState, setHoursState] = useState({
        hourType: "ABSOLUTE_HOURS",
        absoluteStart: eventHours.start,
        absoluteEnd: eventHours.end,
        offsetStart: 0,
        offsetEnd: 0,
    });

    const [briefings, setBriefings] = useState({
        preBriefing: "",
        briefing: "",
    });

    const [eventUuids, setEventUuids] = useState([]);

    useEffect(() => {
        onInit();

        return () => {
            clear();
        };
    }, []);

    translate("Update hours");
    translate("Update occupation");
    translate("Update briefings");

    return (
        <Dialog
            id={id}
            translate={translate}
            title={translate(title)}
            description={`${translate("Facility")}: ${lineType.name}`}
            submitLabel="Apply"
            onClose={onClose}
            error={bulkUpdateState.error}
            onSubmit={() => {
                let bulkUpdateData = {
                    eventUuids,
                    applyForSeries: !!eventUuids.length,
                };

                if (action === "updateHours") {
                    bulkUpdateData = ({
                        ...bulkUpdateData,
                        ...hoursState,
                    }: LineTypeHoursBulkUpdateData);
                }

                if (action === "updateOccupation") {
                    bulkUpdateData = ({
                        ...bulkUpdateData,
                        minOccupation: occupancy.min,
                        maxOccupation: occupancy.max,
                    }: LineTypeOccupationBulkUpdateData);
                }

                if (action === "updateBriefings") {
                    bulkUpdateData = ({
                        ...bulkUpdateData,
                        ...briefings,
                    }: LineTypeBriefingsBulkUpdateData);
                }

                update(lineType.uuid, bulkUpdateData).then(() => {
                    onClose();
                });
            }}
            busy={bulkUpdateState.loading}
        >
            {action === "updateHours" && (
                <Hours
                    translate={translate}
                    state={hoursState}
                    onChange={data => {
                        setHoursState(data);
                    }}
                    disabled={bulkUpdateState.loading}
                    lite
                />
            )}
            {action === "updateOccupation" && (
                <Occupancy
                    translate={translate}
                    {...occupancy}
                    onChange={(min, max) => {
                        setOccupancy({ min, max });
                    }}
                    disabled={bulkUpdateState.loading}
                    lite
                    fieldErrors={bulkUpdateState.fieldErrors}
                />
            )}
            {action === "updateBriefings" && (
                <Briefings
                    translate={translate}
                    briefings={briefings}
                    onChange={data => {
                        setBriefings(data);
                    }}
                    disabled={bulkUpdateState.loading}
                    lite
                />
            )}
            {!events ? (
                <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                    <CircularProgress />
                </div>
            ) : (
                <FormControl
                    margin="normal"
                    fullWidth
                    style={{ marginTop: "2em" }}
                >
                    <FormLabel>
                        {translate("Also apply on other events in this venue")}
                    </FormLabel>
                    <CheckboxGroup
                        translate={translate}
                        data={events.map(event => ({
                            label: event.fullDisplayName,
                            id: event.uuid,
                        }))}
                        onChange={selected => {
                            setEventUuids(selected);
                        }}
                        emptyLabel={translate("No events found")}
                        busy={bulkUpdateState.loading}
                    />
                </FormControl>
            )}
        </Dialog>
    );
};

export default LineTypeBulkEdit;
