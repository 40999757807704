// @flow

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    load as loadVenues,
    ERROR as VENUES_ERROR,
} from "../../../redux/modules/venues";
import {
    load as update,
    ERROR as SHOW_DETAILS_UPDATE_ERROR,
} from "../../../redux/modules/event-details-update";
import { load as loadParking } from "../../../redux/modules/venue-parking";
import { refetch as reloadShowDetails } from "../../../redux/modules/event-details";
import EventDetails from "../../../components/Events/EventDetails";
import {
    load as loadOrganisers,
    refetch as refetchOrganisers,
    ERROR as ORGANISERS_ERROR,
} from "../../../redux/modules/organisers";
import {
    load as addPromoter,
    clear as clearAddPromoter,
    ERROR as ADD_PROMOTER_ERROR,
} from "../../../redux/modules/add-promoter";
import { mapState } from "../../../redux/utils";

import { withTranslate } from "../../Translate";
import { withAcl } from "../../Acl";

export default withAcl(
    withTranslate(
        connect(
            // Map state to props
            ({
                eventDetailsUpdate,
                venueParking,
                venues,
                organisers,
                addPromoter,
            }) => ({
                venues: mapState(venues, VENUES_ERROR, "venues"),
                details: mapState(
                    eventDetailsUpdate,
                    SHOW_DETAILS_UPDATE_ERROR,
                ),
                venueParking: venueParking.toJS(),
                organisers: mapState(organisers, ORGANISERS_ERROR, "promoters"),
                addPromoterState: mapState(
                    addPromoter,
                    ADD_PROMOTER_ERROR,
                    "uuid",
                ),
            }),
            // Map dispatch to props
            (dispatch: *) => ({
                ...bindActionCreators(
                    {
                        loadVenues,
                        loadParking: uuid => loadParking(uuid),
                        addPromoter,
                        loadOrganisers,
                        refetchOrganisers,
                        clearAddPromoter,
                    },
                    dispatch,
                ),
                update: (uuid, show) =>
                    dispatch(update(uuid, show)).then(() =>
                        dispatch(reloadShowDetails()),
                    ),
            }),
        )(EventDetails),
    ),
);
