// @flow

import styles from "./SelectList.scss";

import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CalenderIcon from "../../Calendar/Icons";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { getColor } from "../../../../utils/VenueColors";

type Props = {
    onSelect: (status: *) => void,
    venueCode: string,
    optionStatus?: string,
    color?: "dark" | "light",
    error?: *,
    disabled: boolean,
};

const allOptions = [
    {
        value: "OPTION_CREATED",
        title: "Option",
    },
    {
        value: "OPTION_CONFIRMED",
        title: "Confirm",
    },
    {
        value: "LIMITED_AVAILABILITY",
        title: "Confirm (Limited)",
    },
    {
        value: "OPTION_CANCELED",
        title: "Cancel",
    },
];

const SelectList = ({
    optionStatus,
    onSelect,
    venueCode,
    color,
    error,
    disabled,
}: Props) => {
    return (
        <FormControl error={error} fullWidth>
            <Select
                disabled={disabled}
                value={optionStatus || ""}
                onChange={event => onSelect(event.target.value)}
                name="status"
                displayEmpty
                className={classnames(
                    styles.element,
                    color === "dark" && styles.dark,
                    color === "light" && styles.light,
                )}
                fullWidth
            >
                {allOptions.map(element => {
                    return (
                        <MenuItem value={element.value} key={element.value}>
                            <CalenderIcon
                                status={element.value}
                                color={getColor(venueCode)}
                            />
                            <Typography align="center">
                                {element.title}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectList;
