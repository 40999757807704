// @flow
import styles from "./AddOption.scss";

import moment from "moment";
import get from "lodash.get";
import React, { Component } from "react";
import { type Match } from "react-router";

//import Material-ui components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";

import AsyncFilteredSelect from "../../AsyncFilteredSelect";

import Options from "./Options";
import { type State as AddOptionState } from "../../../redux/modules/add-option.d";
import { type State as EventDetails } from "../../../redux/modules/event-details.d";
import isEqual from "lodash.isequal";

type SubmitFields = {
    seriesName: ?string,
    serieUuid: ?string,
    internal: string,
    promoterUuid: ?string,
    options: Option[],
};

type Props = {
    id: string,
    match: Match,
    disabled?: boolean,
    translate: *,
    onClose: () => void,
    onSubmit: (fields: SubmitFields) => Promise<*>,
    /** Venue state */
    venues: *,
    loadVenues: () => *,
    /** Promoters */
    promoters: *,
    addPromoter: *,
    clearAddPromoter: *,
    loadOrganisers: *,
    /**event details */
    loadEventDetails: (uuid: string, isAddToSeriesEvent?: boolean) => void,
    clearEventDetails: *,
    eventDetails: EventDetails,
    /**events */
    series: *,
    findEvents: (filter: string) => *,
    /** state */
    state: {
        add: AddOptionState,
        create: *,
        addPromoter: *,
    },
    clearAddSerie: *,
    clearAddOption: *,
    refetchOrganisers: () => Promise<*>,
};

type Option = {
    dateFrom: *,
    dateTo: *,
    venueUuid: string,
};

type State = {
    seriesName: ?string,
    serieUuid: ?string,
    internal: string,
    promoterUuid: ?string,
    promoterName: ?string,
    options: Option[],
    optionCode: string,
};

/**
 * Add dialog
 */
export default class AddOption extends Component<Props, State> {
    /**
     * Update state when props update
     */

    // default props

    state = {
        seriesName: null,
        serieUuid: null,
        internal: "",
        promoterUuid: null,
        promoterName: null,
        options: [],
        optionCode: null,
    };

    /**
     * Get Series uuid if Add option to series has been clicked
     */
    get uuid() {
        const { match } = this.props;
        const id = match.params.uuid;

        return id ? (id === "none" ? null : id.toString()) : null;
    }

    /**
     * Get date from params
     */
    get date() {
        const { match } = this.props;
        const id = match.params.date;
        let date = id ? id.slice(0, -3).toString() : null;
        if (date) {
            date = moment.utc(date);
        }

        return date;
    }

    /**
     * Get venue Uuid from params
     */
    get venueUuid() {
        const { match, venues } = this.props;
        const id = match.params.date;

        const venueCode = id ? id.slice(-2) : null;
        const venue =
            !venues.loading &&
            venues.data &&
            venueCode &&
            venues.data.find(venue => venue.code === venueCode);
        if (venue) return venue.uuid;

        return null;
    }

    /**
     * Get event details
     */
    get eventDetails() {
        const { eventDetails } = this.props;

        if (!eventDetails.loading && eventDetails.data) {
            return eventDetails.data;
        }

        return null;
    }

    /**
     * Test if disabled
     */
    get disabled() {
        const {
            disabled,
            state: { add, create },
            eventDetails,
        } = this.props;

        return (
            disabled || add.loading || create.loading || eventDetails.loading
        );
    }

    /**
     * Get errors
     */
    getError(key: string) {
        const {
            state: { add, create },
        } = this.props;

        const { serieUuid } = this.state;

        let transformedKey = key;

        if (serieUuid && add.fieldErrors) {
            if (key === "serie") {
                return;
            }

            if (key === "internal") {
                return;
            }

            return get(add.fieldErrors, transformedKey);
        }

        if (create.fieldErrors) {
            if (key === "serie") {
                transformedKey = "name";
            }

            return (
                get(create.fieldErrors, transformedKey) ||
                get(create.fieldErrors, `createOptionRequest.${transformedKey}`)
            );
        }
        if (key === "formErrors" && (add.error || create.error)) {
            if (add.error) return add.error;
            if (create.error) return create.error;
        }
    }

    /**
     * Add option with same venue as previously selected options
     */
    addOption() {
        const { venues } = this.props;
        const { options } = this.state;

        if (!venues.data || !venues.data.length) {
            return;
        }

        this.setState(
            {
                options: [
                    ...options,
                    {
                        dateFrom: this.date
                            ? this.date
                            : (options.length &&
                                  options[options.length - 1].dateFrom) ||
                              moment().startOf("day").add(1, "days"),
                        dateTo: this.date
                            ? this.date
                            : (options.length &&
                                  options[options.length - 1].dateTo) ||
                              moment().startOf("day").add(1, "days"),
                        venueUuid: this.venueUuid
                            ? this.venueUuid
                            : (options.length &&
                                  options[options.length - 1].venueUuid) ||
                              (venues.data.length && venues.data[0].uuid),
                    },
                ],
            },
            () => {
                this.optionsEnd?.scrollIntoView();
            },
        );
    }

    /**
     * Disabled button
     */
    ensureDefaultOption() {
        const { venues } = this.props;
        const { options } = this.state;

        if (!options.length && venues.data) {
            this.addOption();
        }
    }

    /** Get default promoter */
    get defaultPromoter() {
        const { promoters } = this.props;
        const { promoterUuid } = this.state;

        return (
            promoterUuid &&
            promoters.data &&
            promoters.data.find(p => p.uuid === promoterUuid)
        );
    }

    /**
     * Clear states
     */
    clear = () => {
        const {
            clearAddOption,
            clearAddSerie,
            clearAddPromoter,
            clearEventDetails,
        } = this.props;
        clearAddOption();
        clearAddSerie();
        clearAddPromoter();
        clearEventDetails();
    };

    /**
     * On Component mount
     */
    componentDidMount() {
        const { loadVenues, loadOrganisers, loadEventDetails } = this.props;

        this.clear();
        loadVenues();
        loadOrganisers();

        this.ensureDefaultOption();
        if (this.uuid) {
            loadEventDetails(this.uuid, true);
        }
    }

    /**
     * On component Update
     */
    componentDidUpdate(prevProps: Props) {
        this.ensureDefaultOption();

        const {
            eventDetails: { data },
        } = this.props;

        if (data && !isEqual(prevProps.eventDetails.data, data)) {
            this.setState({
                ...this.state,
                promoterUuid: data.firstPromoterUuid,
                serieUuid: data.uuid,
                internal: data.internal || "",
                optionCode: data.optionCode,
            });
        }
    }

    /**
     * Parsing dates
     */
    get parsedOptions() {
        const { options } = this.state;

        return (options: any).map(option => {
            const offsetFrom = moment(option.dateFrom).utcOffset();
            const offsetTo = moment(option.dateTo).utcOffset();
            return {
                ...option,
                dateFrom: moment
                    .utc(option.dateFrom)
                    .add(offsetFrom, "m")
                    .toISOString(),

                dateTo: moment
                    .utc(option.dateTo)
                    .add(offsetTo, "m")
                    .toISOString(),
            };
        });
    }

    itemToLabel = (item: *) => {
        if (item) {
            const { uuid, name, internal, optionCode, label } = item;
            if (uuid) {
                const displayName =
                    name && optionCode
                        ? `${name} - ${optionCode}`
                        : `${name || optionCode}`;
                return displayName + (internal ? ` ( ${internal} )` : "");
            }

            return label || name;
        }
        return "";
    };

    /**
     * onSubmit
     */
    handleSubmit = (event: *) => {
        event.preventDefault();
        const { onSubmit, onClose } = this.props;
        const { seriesName, serieUuid, internal, promoterUuid } = this.state;
        //dirty implemntation
        const options = this.parsedOptions;

        onSubmit({
            seriesName,
            serieUuid,
            internal,
            promoterUuid,
            options,
        }).then(() => onClose());
    };

    /**
     * The render method
     */
    render() {
        const {
            id,
            translate,
            venues,
            onClose,
            series,
            findEvents,
            promoters,
            eventDetails,
            state: { addPromoter: addPromoterState },
        } = this.props;

        const { serieUuid, seriesName, internal, options } = this.state;

        return (
            <Dialog
                maxWidth="md"
                className={styles.element}
                open
                ref={el => (this.dialog = el)}
            >
                <form onSubmit={event => this.handleSubmit(event)}>
                    <DialogTitle className={styles.title} disableTypography>
                        <Typography variant="h2" color="inherit">
                            {translate("Add Option")}
                        </Typography>
                        <Typography color="inherit" style={{ opacity: "0.75" }}>
                            {translate("Add an option to the calender")}
                        </Typography>
                    </DialogTitle>

                    {eventDetails.loading ? (
                        <DialogContent
                            style={{ textAlign: "center", width: "38em" }}
                        >
                            <CircularProgress className={styles.spinner} />
                        </DialogContent>
                    ) : (
                        <DialogContent
                            style={{
                                maxHeight: "40em",
                                overflowX: "scroll",
                            }}
                        >
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <AsyncFilteredSelect
                                        id={`${id}-serie`}
                                        disabled={this.disabled}
                                        error={
                                            !!(
                                                this.getError("serie") ||
                                                series.error
                                            )
                                        }
                                        helperText={
                                            series.error ||
                                            this.getError("serie")
                                        }
                                        label={translate("Series")}
                                        currentItem={
                                            (this.uuid && this.eventDetails) ||
                                            ""
                                        }
                                        asyncState={series}
                                        lookup={findEvents}
                                        onSelect={serie =>
                                            serie &&
                                            (serie.uuid
                                                ? this.setState({
                                                      serieUuid: (serie: any)
                                                          .uuid,
                                                      seriesName: serie.name,
                                                      internal: (serie: any)
                                                          .internal,
                                                      promoterUuid:
                                                          serie.firstPromoterUuid,
                                                      optionCode:
                                                          serie.optionCode,
                                                  })
                                                : this.setState({
                                                      serieUuid: null,
                                                      seriesName: (serie: any)
                                                          .name,
                                                      internal: "",
                                                      promoterUuid: null,
                                                  }))
                                        }
                                        itemToString={item =>
                                            (item &&
                                                (item.name && item.optionCode
                                                    ? `${item.name} - ${item.optionCode}`
                                                    : item.name ||
                                                      item.optionCode)) ||
                                            ""
                                        }
                                        itemToLabel={this.itemToLabel}
                                        createNewItem={name =>
                                            name && {
                                                uuid: null,
                                                name,
                                                label: translate(
                                                    'Add "%{name}" as a new series',
                                                    { name },
                                                ),
                                            }
                                        }
                                        fullWidth
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                root: styles.label,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id={`${id}-internal`}
                                        label={translate("Memo")}
                                        error={!!this.getError("internal")}
                                        helperText={this.getError("internal")}
                                        onChange={({ target: { value } }) =>
                                            this.setState({
                                                internal: value,
                                            })
                                        }
                                        value={internal}
                                        disabled={
                                            this.disabled ||
                                            !!serieUuid ||
                                            !seriesName
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AsyncFilteredSelect
                                        id={`${id}-promoter`}
                                        disabled={this.disabled}
                                        error={
                                            !!(
                                                addPromoterState.error ||
                                                this.getError("promoterUuid")
                                            )
                                        }
                                        helperText={
                                            (addPromoterState.error &&
                                                addPromoterState.error.error) ||
                                            (!addPromoterState.error &&
                                                this.getError("promoterUuid"))
                                        }
                                        currentItem={this.defaultPromoter || ""}
                                        label={translate("Organiser")}
                                        asyncState={promoters}
                                        onSelect={promoter => {
                                            this.setState({
                                                promoterUuid:
                                                    promoter.uuid || null,
                                                promoterName: promoter.uuid
                                                    ? null
                                                    : promoter.name,
                                            });
                                        }}
                                        itemToString={item =>
                                            (item && item.name) || ""
                                        }
                                        itemToLabel={item =>
                                            (item &&
                                                (item.label || item.name)) ||
                                            ""
                                        }
                                        filter={(value, items) =>
                                            items
                                                .filter(item =>
                                                    (item: any).name
                                                        .toLowerCase()
                                                        .includes(
                                                            value.toLowerCase(),
                                                        ),
                                                )
                                                .splice(0, 5)
                                        }
                                        fullWidth
                                        InputLabelProps={{
                                            // shrink: true,
                                            classes: {
                                                root: styles.label,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Options
                                id={`${id}-options`}
                                disabled={this.disabled}
                                options={options}
                                venues={venues}
                                translate={translate}
                                onChange={options => this.setState({ options })}
                                getError={this.getError.bind(this)}
                            />
                            <div ref={el => (this.optionsEnd = el)} />
                        </DialogContent>
                    )}

                    <DialogActions>
                        {this.getError("formErrors") && (
                            <FormHelperText id={`${id}-helper-text`} error>
                                {this.getError("formErrors")}
                            </FormHelperText>
                        )}
                        <Button
                            id={`${id}-add-date`}
                            disabled={this.disabled}
                            onClick={this.addOption.bind(this)}
                            color="primary"
                        >
                            {translate("Add Date")}
                        </Button>
                        <Button
                            id={`${id}-cancel`}
                            onClick={() => {
                                onClose();
                                this.clear();
                            }}
                            disabled={this.disabled}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            id={`${id}-submit`}
                            type="submit"
                            color="primary"
                            disabled={this.disabled}
                        >
                            {translate("Add Option")}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}
