// @flow

import React from "react";
import {
    Switch,
    Route,
    withRouter,
    Redirect,
    type Match,
    type RouterHistory,
} from "react-router";
// import { connect } from "react-redux";

import StaffingTemplatesList from "./List";
import Details from "./Details";

import { withTranslate } from "../Translate";

import stripslash from "../../utils/stripslash";

type Props = {
    translate: *,
    history: RouterHistory,
    match: Match,
};

const StaffingTemplates = ({ match, history }: Props) => {
    return (
        <Switch>
            <Route path={match.path} exact>
                <Redirect to={`${stripslash(match.url)}/page/1/`} />
            </Route>
            <Route path={`${match.path}page/:page/`}>
                <StaffingTemplatesList
                    onClick={uuid =>
                        history.push(`${stripslash(match.url)}/detail/${uuid}/`)
                    }
                    onPageChange={page =>
                        history.push(`${stripslash(match.url)}/page/${page}/`)
                    }
                />
            </Route>

            <Route path={`${match.path}detail/:id`}>
                <Details />
            </Route>
        </Switch>
    );
};

export default withTranslate(withRouter(StaffingTemplates));
