// @flow

import styles from "./Options.scss";

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Clear from "@material-ui/icons/Clear";

import AsyncSelect from "../../AsyncSelect";
import VenueBox from "../../VenueBox/VenueBox";
import { DateField } from "../../DatePickers";

/**
 * Change handler generator
 */
const getChangeHandler = (field: string, options, index, onChange) => value =>
    onChange(
        options.map((option, current) => {
            if (current === index) {
                if (field === "dateFrom") {
                    if (value) {
                        return {
                            ...option,
                            dateFrom: value,
                            dateTo: option.dateTo.isBefore(value)
                                ? (value: any).clone()
                                : option.dateTo,
                        };
                    }
                }

                if (field === "dateTo") {
                    if (value) {
                        return {
                            ...option,
                            dateFrom: option.dateFrom.isAfter(value)
                                ? (value: any).clone()
                                : option.dateFrom,
                            dateTo: value,
                        };
                    }
                }

                if (value)
                    return {
                        ...option,
                        [field]:
                            ((value: any).target &&
                                (value: any).target.value) ||
                            value,
                    };
            }

            return option;
        }),
    );

/**
 * Options component
 */
const Options = ({
    id,
    disabled,
    onChange,
    options,
    venues,
    translate,
    getError,
}: *) => (
    <Table className={styles.element}>
        <TableHead>
            <TableRow>
                <TableCell>{translate("From")}</TableCell>
                <TableCell>{translate("Until")}</TableCell>
                <TableCell colSpan={2}>{translate("Venue")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {options.map((option, index) => (
                <TableRow key={index}>
                    <TableCell className={styles.date}>
                        <DateField
                            id={`${id}-${index}-from`}
                            disabled={disabled}
                            value={option.dateFrom}
                            error={!!getError(`dates[${index}].dateFrom`)}
                            helperText={getError(`dates[${index}].dateFrom`)}
                            onChange={getChangeHandler(
                                "dateFrom",
                                options,
                                index,
                                onChange,
                            )}
                        />
                    </TableCell>
                    <TableCell className={styles.date}>
                        <DateField
                            id={`${id}-${index}-to`}
                            disabled={disabled}
                            value={option.dateTo}
                            error={!!getError(`dates[${index}].dateTo`)}
                            helperText={getError(`dates[${index}].dateTo`)}
                            onChange={getChangeHandler(
                                "dateTo",
                                options,
                                index,
                                onChange,
                            )}
                        />
                    </TableCell>
                    <TableCell className={styles.venue}>
                        <AsyncSelect
                            id={`${id}-${index}-venue`}
                            disabled={disabled}
                            value={option.venueUuid || ""}
                            loading={venues.loading}
                            options={(venues.data || []).map(venue => [
                                venue.uuid,
                                <ListItem
                                    key={venue.uuid}
                                    className={styles.item}
                                    disableGutters
                                    dense
                                >
                                    <ListItemIcon>
                                        <VenueBox venue={venue} />
                                    </ListItemIcon>
                                    <ListItemText primary={venue.name} />
                                </ListItem>,
                            ])}
                            onChange={getChangeHandler(
                                "venueUuid",
                                options,
                                index,
                                onChange,
                            )}
                            fullWidth
                        />
                    </TableCell>
                    <TableCell>
                        {index !== 0 && (
                            <IconButton
                                onClick={() =>
                                    onChange(
                                        options.filter(
                                            (_, current) => current != index,
                                        ),
                                    )
                                }
                            >
                                <Clear />
                            </IconButton>
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default Options;
