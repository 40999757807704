// @flow

import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import {
    type State as ShowRecruitsState,
    type Recruit,
} from "../../../redux/modules/event-recruits.d";

import { type State as ShowRecruitsUpdateState } from "../../../redux/modules/event-recruits-update.d";

import { type State as ShowRecruitsDeleteState } from "../../../redux/modules/event-recruits-delete.d";

import PoolRecruit from "./PoolRecruit";

type Props = {
    id: string,
    recruitsState: ShowRecruitsState,
    eventRecruitsUpdate: ShowRecruitsUpdateState,
    eventRecruitsDelete: ShowRecruitsDeleteState,
    onPageChange: (page: number) => void,
    onUpdateRecruit: (uuid: string, status: string) => *,
    onDeleteRecruit: (uuid: string) => *,
    translate: *,
    canEdit: boolean,
    canDelete: boolean,
};

/**
 * Get the page data
 */
const getRecruits = (state: ShowRecruitsState): Recruit[] => {
    if (!state.data) {
        return [];
    }

    return state.data.content;
};

/**
 * Pool Recruits list
 */
const PoolRecruitList = ({
    id,
    recruitsState,
    onPageChange,
    canEdit,
    canDelete,
    translate,
    onUpdateRecruit,
    onDeleteRecruit,
    eventRecruitsUpdate,
    eventRecruitsDelete,
}: Props) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>{translate("Organisation member")}</TableCell>
                <TableCell>{translate("User interest")}</TableCell>
                <TableCell>{translate("Recruitmentpool status")}</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
        <TableBody>
            {getRecruits(recruitsState).map((recruit, index) => (
                <PoolRecruit
                    key={recruit.recruitUuid}
                    id={id}
                    index={index}
                    recruit={recruit}
                    canEdit={canEdit}
                    canDelete={canDelete}
                    disabled={
                        recruitsState.loading ||
                        eventRecruitsUpdate.loading ||
                        eventRecruitsDelete.loading
                    }
                    translate={translate}
                    onUpdate={state =>
                        onUpdateRecruit(recruit.recruitUuid, state)
                    }
                    onDelete={() => onDeleteRecruit(recruit.recruitUuid)}
                />
            ))}
        </TableBody>
        <TableFooter>
            <TableRow>
                <TablePagination
                    disabled={
                        recruitsState.loading ||
                        eventRecruitsUpdate.loading ||
                        eventRecruitsDelete.loading
                    }
                    colSpan={5}
                    count={
                        recruitsState.data
                            ? recruitsState.data.totalElements
                            : 0
                    }
                    rowsPerPage={
                        (recruitsState.data && recruitsState.data.size) || 0
                    }
                    rowsPerPageOptions={[10]}
                    page={(recruitsState: any).page - 1 || 0}
                    onChangePage={(_, page) => onPageChange(page + 1)}
                    onChangeRowsPerPage={() => undefined}
                />
            </TableRow>
        </TableFooter>
    </Table>
);

export default PoolRecruitList;
