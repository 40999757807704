//@flow

import React, { Component } from "react";

import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import type { Config, ConfigItem, SortItem } from "./Table";

type Props = {
    sorting: SortItem[],
    sortHandler: (displayName: string) => void,
    config: Config,
};

export default class Header extends Component<Props> {
    render() {
        const { config, sorting, sortHandler } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {(config.configItems: any).map((item: ConfigItem) => {
                        const sortItem = sorting.find(i => i.name == item.key);
                        return (
                            item.permission && (
                                <TableCell key={item.key}>
                                    <TableSortLabel
                                        active={
                                            item.sortable && sortItem
                                                ? sortItem.active
                                                : false
                                        }
                                        direction={
                                            sortItem ? sortItem.order : "asc"
                                        }
                                        onClick={() => {
                                            if (item.sortable)
                                                sortHandler(item.key);
                                        }}
                                    >
                                        {item.displayName}
                                    </TableSortLabel>
                                </TableCell>
                            )
                        );
                    })}
                    {config.hasMenu && <TableCell />}
                </TableRow>
            </TableHead>
        );
    }
}
