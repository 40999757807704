// @flow

import styles from "./Cancel.scss";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";

import React, { Component, Fragment } from "react";
import { type State as EventDetails } from "../../redux/modules/event-details.d";
import moment from "moment";

type Props = {
    onClose: *,
    translate: *,
    loading: *,
    error: *,
    onSubmit: *,
    uuid: *,
    option?: *,
    event?: EventDetails,
    dataLoading: *,
    type: "OPTION_CREATED" | "OPTION_CONFIRMED" | "EVENT",
};

export default class Cancel extends Component<Props> {
    /**
     * Get option title
     */
    get title(): string {
        const { option, type, event, translate } = this.props;
        if (type === "OPTION_CREATED" || type === "OPTION_CONFIRMED") {
            if (option && option.data && option.data.seriesName) {
                if (option.data.seriesInternal) {
                    if (option.data.name) {
                        option.data.seriesName +
                            " (" +
                            option.data.seriesInternal +
                            " )" +
                            " - " +
                            option.data.name;
                        +" in " +
                            option.data.venueName +
                            " on " +
                            moment(option.data.date).format("DD-MM-YYYY");
                    }

                    return (
                        option.data.seriesName +
                        " (" +
                        option.data.seriesInternal +
                        " )" +
                        " in " +
                        option.data.venueName +
                        " on " +
                        moment(option.data.date).format("DD-MM-YYYY")
                    );
                }

                return (
                    option.data.seriesName +
                    " in " +
                    option.data.venueName +
                    " on " +
                    moment(option.data.date).format("DD-MM-YYYY")
                );
            }

            return translate("this option");
        }
        if (type === "EVENT") {
            if (event && event.data) {
                if (event.data.seriesName) {
                    return (
                        event.data.seriesName +
                        " - " +
                        event.data.name +
                        " in " +
                        event.data.venueName +
                        " on " +
                        moment(event.data.start).format("DD-MM-YYYY")
                    );
                }
                return (
                    event.data.name +
                    " in " +
                    event.data.venueName +
                    " on " +
                    moment(event.data.start).format("DD-MM-YYYY")
                );
            }

            return translate("this event");
        }

        return translate("this item");
    }
    /**
     * Handle submit
     */

    handleSubmit = () => {
        const { onSubmit, uuid, type } = this.props;

        if (type === "OPTION_CREATED") {
            onSubmit([
                {
                    name: null,
                    status: "OPTION_CANCELED",
                    uuid: uuid,
                },
            ]);
        }

        if (type === "OPTION_CONFIRMED") {
            onSubmit([
                {
                    name: null,
                    status: "CANCELED",
                    uuid: uuid,
                },
            ]);
        }

        if (type === "EVENT") {
            onSubmit(uuid);
        }
    };

    render() {
        const { translate, onClose, error, loading, type, dataLoading } =
            this.props;

        const getConfirmMessage = () => {
            const translateEvent = translate(
                "Are you sure you want to cancel %{title}?",
                {
                    title: this.title,
                },
            );

            const translateOption = translate(
                "Are you sure you want to delete %{title}?",
                { title: this.title },
            );

            const len = this.title.length;
            const iEvent = translateEvent.indexOf(this.title);
            const iOption = translateOption.indexOf(this.title);

            if (type === "EVENT") {
                return (
                    <div>
                        {translateEvent.substring(0, iEvent)}
                        <span className={styles.bold}>
                            {translateEvent.substring(iEvent, iEvent + len)}
                        </span>
                        {translateEvent.substring(iEvent + len)}
                    </div>
                );
            }
            return (
                <div>
                    {translateOption.substring(0, iOption)}
                    <span className={styles.bold}>
                        {translateOption.substring(iOption, iOption + len)}
                    </span>
                    {translateOption.substring(iOption + len)}
                </div>
            );
        };

        return (
            <Dialog
                open
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={styles.element}
            >
                <DialogTitle id="alert-dialog-title">
                    {type === "EVENT"
                        ? translate("Cancel Event")
                        : translate("Delete Option")}
                </DialogTitle>
                <DialogContent>
                    {dataLoading ? (
                        <div className={styles.spinner}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <Fragment>
                            <DialogContentText id="alert-dialog-description">
                                {getConfirmMessage()}
                            </DialogContentText>
                            {error && (
                                <FormHelperText
                                    id={`canceloption-helper-text`}
                                    error
                                    style={{ marginTop: "2em" }}
                                >
                                    {error}
                                </FormHelperText>
                            )}
                        </Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{translate("Disagree")}</Button>
                    <Button
                        onClick={this.handleSubmit}
                        color="secondary"
                        autoFocus
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : type === "EVENT" ? (
                            translate("Cancel Event")
                        ) : (
                            translate("Delete Option")
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
