// @flow

import invariant from "invariant";
import React, { useEffect, useState } from "react";
import styles from "./User.scss";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import BusinessCenter from "@material-ui/icons/BusinessCenter";

import { type State as UserState } from "../../redux/modules/admin-user.d";

import {
    type NewUser,
    type State as AddState,
} from "../../redux/modules/admin-user-add.d";

import {
    type User as UserType,
    type State as EditState,
} from "../../redux/modules/admin-user-edit.d";
import { type State as MembershipsState } from "../../redux/modules/admin-user-memberships.d";

import { format as formatPhone } from "../../utils/phonenumber";

import PhoneNumberField from "../PhoneNumberField";
import TabPanel from "./TabPanel";

type Props = {
    /** The id */
    id: string,
    /** Trigger translation */
    translate: *,
    /** Trigger modal close */
    onClose: () => void,
    /** Trigger */
    onSubmit: (user: UserType | NewUser) => Promise<void>,
    /** The async state */
    asyncState: AddState | EditState,
    /** Disabled flag */
    disabled: boolean,
    /** Initial values */
    userState?: UserState,
    /** Load user state */
    loadUser?: () => *,
    /** Clear the user state */
    clearUser?: () => *,
    /** memberships state */
    userMemberships?: MembershipsState,
    clearUserEdit?: () => *,
};

/**
 * Label map
 */
export const getLabel = (field: string, translate: *) => {
    if (field === "firstName") {
        return translate("First name");
    }

    if (field === "lastName") {
        return translate("Last name");
    }

    if (field === "phoneNumber") {
        return translate("Phone Number");
    }

    if (field === "emailAddress") {
        return translate("Email address");
    }

    return "";
};

/**
 * Add modal
 */
const User = ({
    disabled,
    userState,
    asyncState,
    clearUser,
    loadUser,
    id,
    onSubmit,
    onClose,
    translate,
    userMemberships,
    clearUserEdit,
}: Props) => {
    // Initial state
    const [activeTab, setActiveTab] = useState(0);
    const [emailAddress, setEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    /**
     * Get the correct title for this component
     */
    const title = () => {
        if (userState) {
            if (userState.data) {
                if (!userState.data.firstName) {
                    return "Unknown";
                }
                return `${userState.data.firstName} ${userState.data.lastName}`;
            }
            return "...";
        }

        return translate("Add User");
    };

    const isDisabled = (): boolean => {
        return (
            disabled ||
            asyncState.loading ||
            (userState ? userState.loading || !userState.uuid : false)
        );
    };

    /**
     * Can submit test
     */
    const canSubmit = () => {
        if (isDisabled()) return;
        if (
            !emailAddress ||
            !emailAddress.trim().length ||
            !firstName ||
            !firstName.trim().length ||
            !lastName ||
            !lastName.trim().length ||
            !phoneNumber ||
            !phoneNumber.trim().length
        ) {
            return false;
        }
        return true;
    };

    /**
     * Get a field error if present
     */
    const getFieldError = (field: string) =>
        asyncState.fieldErrors && asyncState.fieldErrors[field];

    useEffect(() => {
        if (userState) {
            invariant(
                clearUser,
                "A clear handler is required when a userstate is provided!",
            );
            invariant(
                loadUser,
                "A load handler is required when a userstate is provided!",
            );
            clearUser();
            clearUserEdit ? clearUserEdit() : undefined;
            loadUser();
        }
    }, []);

    useEffect(() => {
        userState && userState.data && setInitialFields();
    }, [userState]);

    const setInitialFields = () => {
        //$FlowFixMe[incompatible-call]
        setEmailAddress(userState?.data?.emailAddress);
        //$FlowFixMe[incompatible-call]
        setFirstName(userState?.data?.firstName);
        //$FlowFixMe[incompatible-call]
        setLastName(userState?.data?.lastName);
        //$FlowFixMe[incompatible-call]
        setPhoneNumber(userState?.data?.phoneNumber);
    };

    /**
     * Render a field
     */
    const renderField = (
        field: string,
        value: string,
        handleChange: function,
    ) => {
        const error = getFieldError(field);

        return (
            <TextField
                id={`${id}-${field}`}
                label={getLabel(field, translate)}
                value={value}
                error={!!error}
                helperText={error}
                disabled={disabled}
                onChange={event => handleChange(event.target.value)}
                fullWidth
            />
        );
    };
    /**
     * Submit handler
     */
    const handleSubmit = (event: *) => {
        event.preventDefault();
        if (canSubmit()) {
            onSubmit({
                firstName,
                emailAddress,
                lastName,
                phoneNumber: formatPhone(phoneNumber),
            }).then(onClose);
        }
    };

    const tabs = [translate("Organisations"), translate("Roles")];

    return (
        <Dialog
            onClose={() => {
                clearUser && clearUser();
                onClose();
            }}
            open
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle disableTypography>
                    <div className={styles.headerWrapper}>
                        <Typography variant="h2">{title()}</Typography>
                        {userState && userState.data && (
                            <span>
                                <img
                                    alt={title()}
                                    src={`${
                                        userState &&
                                        userState.data &&
                                        userState.data.profilePicturePath
                                            ? userState.data.profilePicturePath
                                            : ""
                                    }/256x256.png`}
                                />
                            </span>
                        )}
                    </div>
                    {!userState && (
                        <DialogContentText>
                            {translate("Add a new user to @YourService")}
                        </DialogContentText>
                    )}
                </DialogTitle>
                <DialogContent className={styles.dialogContent}>
                    <Grid container spacing={3}>
                        {asyncState.error && (
                            <Grid item xs={12}>
                                <Typography color="error">
                                    {asyncState.error}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item sm={6}>
                            {renderField("firstName", firstName, setFirstName)}
                        </Grid>
                        <Grid item sm={6}>
                            {renderField("lastName", lastName, setLastName)}
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneNumberField
                                id={`${id}-phoneNumber`}
                                label={getLabel("phoneNumber", translate)}
                                value={phoneNumber}
                                error={!!getFieldError("phoneNumber")}
                                helperText={getFieldError("phoneNumber")}
                                disabled={disabled}
                                onChange={event => setPhoneNumber(event)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderField(
                                "emailAddress",
                                emailAddress,
                                setEmailAddress,
                            )}
                        </Grid>
                        {userMemberships &&
                            !userMemberships.loading &&
                            userMemberships.data && (
                                <Grid item xs={12}>
                                    <Tabs
                                        value={activeTab}
                                        classes={{
                                            indicator: styles.indicator,
                                            root: styles.tabsRoot,
                                        }}
                                        onChange={(_, activeTab) =>
                                            setActiveTab(activeTab)
                                        }
                                    >
                                        {tabs.map((tabTitle, index) => (
                                            <Tab
                                                key={`${id}-tab-${index}`}
                                                id={`${id}-tab-${index}`}
                                                label={tabTitle}
                                                classes={{
                                                    label: styles.tab,
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                    <TabPanel
                                        value={activeTab}
                                        index={0}
                                        id={`${id}-memberships-0`}
                                        style={{
                                            maxHeight: "15em",
                                        }}
                                    >
                                        {userMemberships &&
                                        userMemberships.data &&
                                        !userMemberships.loading &&
                                        userMemberships.data.length !== 0
                                            ? userMemberships.data.map(
                                                  (member, index) => (
                                                      <ListItem
                                                          disableGutters
                                                          key={member.uuid}
                                                          id={`${id}-membership-${index}`}
                                                          style={{
                                                              justifyContent:
                                                                  "space-between",
                                                          }}
                                                      >
                                                          <ListItemAvatar>
                                                              <Avatar>
                                                                  <BusinessCenter />
                                                              </Avatar>
                                                          </ListItemAvatar>
                                                          <ListItemText
                                                              primary={
                                                                  member.organisationName
                                                              }
                                                              secondary={
                                                                  member.description
                                                                      ? member.description
                                                                      : null
                                                              }
                                                          />
                                                          <Typography
                                                              classes={{
                                                                  root: styles.memberRole,
                                                              }}
                                                          >
                                                              {member.membershipRole.toLowerCase()}
                                                          </Typography>
                                                      </ListItem>
                                                  ),
                                              )
                                            : translate(
                                                  "No organizations assigned yet!",
                                              )}
                                    </TabPanel>
                                    <TabPanel
                                        value={activeTab}
                                        index={1}
                                        style={{
                                            maxHeight: "15em",
                                        }}
                                    >
                                        {userState &&
                                            userState.data &&
                                            userState.data.roles?.length !==
                                                0 &&
                                            userState.data.roles?.map(
                                                (role, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText
                                                            primary={role}
                                                        />
                                                    </ListItem>
                                                ),
                                            )}
                                    </TabPanel>
                                </Grid>
                            )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        id={`${id}-cancel`}
                        onClick={() => {
                            clearUser && clearUser();
                            onClose();
                        }}
                        disabled={isDisabled()}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        id={`${id}-submit`}
                        type="submit"
                        disabled={!canSubmit()}
                        color="primary"
                    >
                        {userState ? translate("Save") : translate("Add")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default User;
