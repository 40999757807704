// @flow

import styles from "./MoveEvent.scss";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import Move from "./Move";
import ConfirmMove from "./ConfirmMove";

type Props = {
    translate: *,
    onClose: () => void,
    id: string,
    onSubmit: (uuid: ?string, eventUuids: string[]) => *,
    series: *,
    events: *,
    movedEventState: *,
    loadSeries: *,
    loadEvents: *,
    seriesUuid: string,
    findSeries: (filter: string) => *,
};

const MoveEvent = ({
    translate,
    onClose,
    id,
    series,
    events,
    onSubmit,
    loadEvents,
    seriesUuid,
    findSeries,
}: Props) => {
    const [selectedEvents, setSelectedEvents] = useState<[]>([]);
    const [selectedSerie, setSelectedSerie] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleSelectAllEvents = () => {
        if (events.data?.events) {
            selectedEvents === events.data.events
                ? setSelectedEvents([])
                : setSelectedEvents(events.data.events);
        }
    };

    const isEventSelected = event => {
        return selectedEvents.some(item => event.uuid === item.uuid);
    };

    const mapEventsList = () => {
        return selectedEvents.map(event => event.uuid);
    };

    /**
     * Submit handler
     */
    const handleSubmit = () => {
        onSubmit(selectedSerie.uuid, mapEventsList(selectedEvents));
    };

    const handleEventChange = event => {
        if (!isEventSelected(event)) {
            setSelectedEvents(current => [...current, event]);
        } else {
            setSelectedEvents(previous =>
                previous.filter(_event => _event !== event),
            );
        }
    };

    const handleSerieChange = serie => {
        setSelectedSerie(serie);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    return (
        <Dialog onClose={onClose} open maxWidth={false}>
            <DialogTitle
                disableTypography
                className={styles.title}
                style={{ paddingBottom: "0" }}
            >
                <Typography variant="h2" color="inherit">
                    {translate("Move events/options")}
                </Typography>
                <Typography color="inherit" style={{ opacity: "0.75" }}>
                    {translate(
                        "Choose the events/options that you want to move to another series",
                    )}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {series && events && (
                    <Move
                        translate={translate}
                        events={events}
                        series={series}
                        loadEvents={loadEvents}
                        handleSelectAll={handleSelectAllEvents}
                        numberOfSelectedEvents={selectedEvents.length}
                        isEventSelected={isEventSelected}
                        onEventChange={handleEventChange}
                        onSerieChange={handleSerieChange}
                        findSeries={findSeries}
                        selectedSerie={selectedSerie}
                        seriesUuid={seriesUuid}
                    />
                )}
                {showModal && (
                    <ConfirmMove
                        onClose={handleModalClose}
                        onSubmit={handleSubmit}
                        translate={translate}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button id={`${id}-cancel`} onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button
                    id={`${id}-move`}
                    color="primary"
                    onClick={() => {
                        if (selectedEvents.length > 0) setShowModal(true);
                    }}
                >
                    {translate("Move")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default MoveEvent;
