// @flow

/**
 * Module: Add Show
 */

import createApiModule from "../create-api-module";
import { type NewPool, type PathParams } from "./event-recruitment-add.d";

const { reducer, actions } = createApiModule(
    "showRecruitmentAdd",
    ({ uuid }: PathParams) =>
        `/ays-series-event-management/events/${uuid}/recruitment-pools`,
    {
        method: "POST",
    },
);
export const load = (uuid: string, data: NewPool) => (dispatch: *) => {
    return dispatch(
        actions.load({
            path: { uuid },
            data,
        }),
    );
};
export const clear = actions.clear;
export const refetch = actions.refetch;
export const ERROR = "Could not add recruitment pool!";
/** REDUCER **/
export default reducer;
