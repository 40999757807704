// @flow

import mixin from "../../mixin";
import normalizeError from "../../error-map";

import { type Page } from "../../../redux/modules/event-recruits.d";

export const Client = {
    /**
     * Fetch all venues
     */
    getPoolRecruits(
        uuid: string,
        page: number,
        filter: string,
        recruitmentStatuses: string[],
    ): Promise<Page> {
        return this.instance
            .post(
                `/ays-searches/searches/recruitment-pools/${uuid}/recruits`,
                {
                    filter,
                    recruitmentStatuses: recruitmentStatuses,
                },
                {
                    params: {
                        page: page - 1,
                    },
                },
            )
            .then(({ data }) => data)
            .catch(normalizeError);
    },
};

/**
 * User mixin
 */
export default mixin(Client);
