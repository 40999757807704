// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form .lW9kJHWMXO5NG1rMaoAeyA\\=\\={margin-top:1em;max-height:26em;max-width:60em}form .lW9kJHWMXO5NG1rMaoAeyA\\=\\=>:first-child{align-items:flex-end}form .lW9kJHWMXO5NG1rMaoAeyA\\=\\= .EFQEMn9wf2LcHKY2jrnBDA\\=\\={padding:5em 26em}\n", "",{"version":3,"sources":["webpack://./../Options.scss"],"names":[],"mappings":"AAAA,iCAEQ,cAAe,CACf,eAAgB,CAChB,cAAe,CAJvB,8CAMY,oBAAqB,CANjC,6DASY,gBAAiB","sourcesContent":["form {\n    .content {\n        margin-top: 1em;\n        max-height: 26em;\n        max-width: 60em;\n        & > :first-child {\n            align-items: flex-end;\n        }\n        .loader {\n            padding: 5em 26em;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "lW9kJHWMXO5NG1rMaoAeyA==",
	"loader": "EFQEMn9wf2LcHKY2jrnBDA=="
};
export default ___CSS_LOADER_EXPORT___;
