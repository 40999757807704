// @flow

// Import libs
import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export type Option = {
    id: string,
    label: string,
    onClick: () => *,
};
// Define props
type PropsType = {
    id: string,
    options: Option[],
    disabled?: ?boolean,
};

// Define state
type StateType = {
    anchorEl: ?HTMLElement,
};

/**
 * A dummy component
 */
export default class IconMenu extends Component<PropsType, StateType> {
    // Initial state
    state = {
        anchorEl: null,
    };

    /**
     * Click handler
     */
    handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    /**
     * Render
     */
    render() {
        const { options, disabled, id } = this.props;
        const { anchorEl } = this.state;

        return (
            <div>
                <IconButton
                    id={id}
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    disabled={!!disabled}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    key="long-menu"
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {options.map(option => (
                        <MenuItem
                            id={option.id}
                            key={option.label}
                            onClick={() => {
                                option.onClick();
                                this.handleClose();
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}
