// @flow

import styles from "./PoolRecruits.scss";
import React, { Component, Fragment, type Node } from "react";
import { type Match, type RouterHistory } from "react-router";

import stripslash from "../../../utils/stripslash";

/** Types */
import { type State as ShowRecruitsState } from "../../../redux/modules/event-recruits.d";
import { type State as ShowRecruitsUpdateState } from "../../../redux/modules/event-recruits-update.d";
import { type State as ShowRecruitsDeleteState } from "../../../redux/modules/event-recruits-delete.d";
/** Components */
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/AddCircle";

import { FilterField } from "../../Authenticated";
import PoolRecruitList from "./PoolRecruitsList";
import FabContainer from "../../FabContainer/FabContainer";

type Props = {
    id: string,
    uuid: string,
    match: Match,
    history: RouterHistory,
    acl: *,
    disabled: boolean,
    translate: *,
    recruitsState: ShowRecruitsState,
    eventRecruitsUpdate: ShowRecruitsUpdateState,
    eventRecruitsDelete: ShowRecruitsDeleteState,
    loadRecruits: (page: number, filter: string) => *,
    clearRecruits: () => *,
    onPageChange: (page: number) => void,
    updateRecruit: (uuid: string, status: string) => Promise<void>,
    deleteRecruit: (uuid: string) => *,
    /** Add all recruits*/
    addAll: (uuid: string) => *,
    children?: ?Node,
};

type State = {
    filter: string,
};

/**
 * PoolRecruits component
 *
 * TODO: Move the entire stack into container
 */
export default class PoolRecruits extends Component<Props, State> {
    // Initial state
    state = {
        filter: "",
    };

    /**
     * Get recruits count
     */
    get recruitCount() {
        const { recruitsState } = this.props;

        if (recruitsState.data) {
            return `(${recruitsState.data.totalElements})`;
        }

        return "(...)";
    }

    /**
     * On mount
     */
    componentDidMount() {
        const { clearRecruits } = this.props;
        clearRecruits();
        this.load();
    }

    /**
     * On update
     */
    componentDidUpdate() {
        this.load();
    }

    /**
     * Load
     */
    load() {
        const { loadRecruits, match } = this.props;
        const { filter } = this.state;

        loadRecruits(parseInt(match.params.page || 1), filter);
    }

    /**
     * Render
     */
    render() {
        const {
            id,
            acl,
            uuid,
            translate,
            match,
            history,
            recruitsState,
            eventRecruitsUpdate,
            eventRecruitsDelete,
            onPageChange,
            updateRecruit,
            deleteRecruit,
            disabled,
            addAll,
            children,
        } = this.props;

        const { filter } = this.state;

        return (
            <div className={styles.wrapper}>
                <div>
                    <Typography variant="h4">
                        {translate("Recruits")} {this.recruitCount}
                    </Typography>
                </div>
                <div>
                    <FilterField
                        id={`${id}-filter`}
                        value={filter}
                        disabled={!recruitsState.data}
                        placeholder={translate(
                            "Find recruits by name or phone number",
                        )}
                        onChange={filter => this.setState({ filter })}
                        className={styles.filter}
                    />
                </div>
                <div>
                    {(recruitsState.loading && !recruitsState.data && (
                        <div className={styles.spinner}>
                            <CircularProgress />
                        </div>
                    )) || (
                        <PoolRecruitList
                            id={`${id}-list`}
                            recruitsState={recruitsState}
                            eventRecruitsUpdate={eventRecruitsUpdate}
                            eventRecruitsDelete={eventRecruitsDelete}
                            onUpdateRecruit={updateRecruit}
                            onDeleteRecruit={deleteRecruit}
                            onPageChange={onPageChange}
                            translate={translate}
                            canEdit={
                                !disabled &&
                                acl("events.recruitment.recruit.update")
                            }
                            canDelete={
                                !disabled &&
                                acl("events.recruitment.recruit.delete")
                            }
                        />
                    )}
                </div>
                {!disabled && acl("events.recruitment.recruit.create") && (
                    <Fragment>
                        <FabContainer float={false} center>
                            <Fab
                                id={`${id}-add`}
                                color="primary"
                                variant="extended"
                                onClick={() =>
                                    history.push(
                                        `${stripslash(match.url)}/add/`,
                                    )
                                }
                            >
                                <Add />
                                {translate("Add recruit(s)")}
                            </Fab>
                            <Fab
                                id={`${id}-add`}
                                color="primary"
                                variant="extended"
                                onClick={() => addAll(uuid)}
                            >
                                <Add />
                                {translate("Add all members")}
                            </Fab>
                        </FabContainer>
                        {children}
                    </Fragment>
                )}
            </div>
        );
    }
}
