//@flow
import styles from "./Calendar.scss";

import React, { useEffect } from "react";
import classnames from "classnames";
import CalendarView from "./CalendarView";
import { type State as VenueState } from "../../../redux/modules/venues.d";
import { type State as CalendarState } from "../../../redux/modules/calendar.d";
import { type Profile } from "../../../redux/modules/user.d";

type Props = {
    acl: *,
    translate: *,
    onAddOption: () => void,
    onAddEvent: () => void,
    venues: VenueState,
    calendar: CalendarState,
    loadVenues: () => any,
    loadCalendar: (
        startDate: string,
        endDate: string,
        promoterUuid?: *,
        showCanceled?: boolean,
    ) => any,
    user: Profile,
    onConfirmOptions: (uuid: string) => void,
    onConfirmOption: (uuid: string) => void,
    onEditOption: (uuid: string) => void,
    onEditConfirmedOption: (uuid: string) => void,
    onCancelOption: (uuid: string) => void,
    onEditSeries: (uuid: string) => void,
    onEditEvent: (uuid: string) => void,
    onMoveEvent: (uuid: string) => void,
    onDefineEvents: (uuid: string) => void,
    onExportData: () => void,
    onCancelConfirmedOption: (uuid: string) => void,
    onCancelEvent: (uuid: string) => void,
    onAddOptionToSeries: (uuid: string, date: string) => void,
    onAddEventToSeries: (uuid: string, date: string) => void,
    onShowHistory: (uuid: string) => void,
    onDoubleClickEvent: (uuid: string) => void,
    onOpenLegend: () => void,
    findSeries: (filter: string) => *,
    clearSeries: () => void,
    series: *,
};

const Calendar = ({
    acl,
    translate,
    venues,
    calendar,
    user,
    loadCalendar,
    onConfirmOptions,
    onEditOption,
    onAddOption,
    onAddEvent,
    onCancelOption,
    onEditSeries,
    onEditEvent,
    onMoveEvent,
    onEditConfirmedOption,
    onDefineEvents,
    onExportData,
    onCancelConfirmedOption,
    onCancelEvent,
    onOpenLegend,
    onAddOptionToSeries,
    onShowHistory,
    onDoubleClickEvent,
    onAddEventToSeries,
    findSeries,
    clearSeries,
    series,
    loadVenues,
}: Props) => {
    useEffect(() => {
        loadVenues();
    }, []);

    return (
        <div className={classnames(styles.element)}>
            <CalendarView
                acl={acl}
                user={user}
                translate={translate}
                venues={venues}
                calendar={calendar}
                loadCalendar={loadCalendar}
                onConfirmOptions={onConfirmOptions}
                onEditOption={onEditOption}
                onCancelOption={onCancelOption}
                onDefineEvents={onDefineEvents}
                onEditSeries={onEditSeries}
                onEditEvent={onEditEvent}
                onMoveEvent={onMoveEvent}
                onEditConfirmedOption={onEditConfirmedOption}
                onExportData={onExportData}
                onCancelConfirmedOption={onCancelConfirmedOption}
                onCancelEvent={onCancelEvent}
                findSeries={findSeries}
                clearSeries={clearSeries}
                onAddOptionToSeries={onAddOptionToSeries}
                onAddEventToSeries={onAddEventToSeries}
                series={series}
                onOpenLegend={onOpenLegend}
                onAddEvent={onAddEvent}
                onAddOption={onAddOption}
                onShowHistory={onShowHistory}
                onDoubleClickEvent={onDoubleClickEvent}
            />
        </div>
    );
};

export default Calendar;
