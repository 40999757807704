// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WgneKDcor04Zd4BfNe63vg\\=\\={min-width:100%}.WgneKDcor04Zd4BfNe63vg\\=\\= .x7YQKmUgX\\+97Rxl6YH6KJg\\=\\={border-bottom:1px solid #ccc;flex-wrap:nowrap;display:flex;box-sizing:border-box}.WgneKDcor04Zd4BfNe63vg\\=\\= .-SKWx-tP6h4lKF6wMSlbgA\\=\\={position:-webkit-sticky;position:sticky;top:0;left:0}.WgneKDcor04Zd4BfNe63vg\\=\\= .-YcCvRvh3RfACGmpGYI8tg\\=\\={border-bottom:1px solid #ccc;flex-wrap:nowrap;display:flex;box-sizing:border-box;padding-bottom:8em}\n", "",{"version":3,"sources":["webpack://./../CalendarViewBody.scss"],"names":[],"mappings":"AAAA,4BACI,cAAe,CADnB,yDAGQ,4BAA6B,CAC7B,gBAAiB,CACjB,YAAa,CACb,qBAAsB,CAN9B,wDASQ,uBAAgB,CAAhB,eAAgB,CAChB,KAAM,CACN,MAAO,CAXf,wDAcQ,4BAA6B,CAC7B,gBAAiB,CACjB,YAAa,CACb,qBAAsB,CACtB,kBAAmB","sourcesContent":[".inner {\n    min-width: 100%;\n    .loadPreviousWrapper {\n        border-bottom: 1px solid #ccc;\n        flex-wrap: nowrap;\n        display: flex;\n        box-sizing: border-box;\n    }\n    .loadMoreButton {\n        position: sticky;\n        top: 0;\n        left: 0;\n    }\n    .loadMoreWrapper {\n        border-bottom: 1px solid #ccc;\n        flex-wrap: nowrap;\n        display: flex;\n        box-sizing: border-box;\n        padding-bottom: 8em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "WgneKDcor04Zd4BfNe63vg==",
	"loadPreviousWrapper": "x7YQKmUgX+97Rxl6YH6KJg==",
	"loadMoreButton": "-SKWx-tP6h4lKF6wMSlbgA==",
	"loadMoreWrapper": "-YcCvRvh3RfACGmpGYI8tg=="
};
export default ___CSS_LOADER_EXPORT___;
