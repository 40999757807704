// @flow

import styles from "./PoolSettings.scss";

import moment from "moment";
import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { type Pool as PoolType } from "../../../redux/modules/event-recruitment.d";
import {
    type Pool as PoolFields,
    type State as AsyncState,
} from "../../../redux/modules/event-recruitment-update.d";

import { DateTimeField } from "../../DatePickers";

type Props = {
    id: string,
    pool: PoolType,
    asyncState: AsyncState,
    translate: *,
    disabled: boolean,
    onSubmit: (data: PoolFields) => Promise<void>,
};

type State = {
    fields: PoolFields,
};

/**
 * Fetches the value from an event
 */
const getValueFromEvent = (event: * | string): string => {
    if (typeof event === "string") {
        return event;
    }
    if (moment.isMoment(event)) {
        return moment.utc(event).toISOString();
    }

    return event.target.value;
};

/**
 * Label map
 */
const getLabel = (field, translate) => {
    if (field === "name") {
        return translate("Group name");
    }

    if (field === "description") {
        return translate("Description");
    }

    if (field === "start") {
        return translate("Start");
    }

    if (field === "end") {
        return translate("End");
    }

    return "";
};

/**
 * Settings form
 */
export default class PoolSettings extends Component<Props, State> {
    // Default props
    static defaultProps = {
        disabled: false,
    };

    // Initial state
    state = {
        fields: {
            name: this.props.pool.name,
            description: this.props.pool.description,
            start: this.props.pool.start,
            end: this.props.pool.end,
        },
    };

    /**
     * Disabled getter
     */
    get disabled() {
        const { disabled, asyncState } = this.props;
        return disabled || asyncState.loading;
    }

    /**
     * Can submit test
     */
    get canSubmit() {
        if (this.disabled) {
            return false;
        }

        const { fields } = this.state;

        for (const key of Object.keys(fields)) {
            if (!fields[key].trim().length) {
                return false;
            }
        }

        return true;
    }

    /**
     * Get a field error if present
     */
    getFieldError(field: string) {
        const { asyncState } = this.props;
        return asyncState.fieldErrors && asyncState.fieldErrors[field];
    }

    /**
     * Render a field
     */
    renderField(field: string) {
        const { id, translate } = this.props;
        const { fields } = this.state;
        const error = this.getFieldError(field);

        const Component =
            (["start", "end"].includes(field) && DateTimeField) || TextField;

        return (
            <Component
                id={`${id}-${field}`}
                label={getLabel(field, translate)}
                value={fields[field]}
                error={!!error}
                helperText={error}
                disabled={this.disabled}
                onChange={event =>
                    this.handleChange(getValueFromEvent(event), field)
                }
                fullWidth
            />
        );
    }

    /**
     * Reset the form values
     */
    reset() {
        const {
            pool: { name, description, start, end },
        } = this.props;

        this.setState({
            fields: {
                name,
                description,
                start,
                end,
            },
        });
    }

    /**
     * Handle change
     */
    handleChange(value: string, field: string) {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [field]: value,
            },
        });
    }

    /**
     * Submit handler
     */
    handleSubmit(event: *) {
        event.preventDefault();

        const { onSubmit } = this.props;
        const { fields } = this.state;

        if (this.canSubmit) {
            onSubmit(fields);
        }
    }

    /**
     * Render
     */
    render() {
        const { translate, id, asyncState } = this.props;

        return (
            <div>
                <form
                    onSubmit={this.handleSubmit.bind(this)}
                    className={styles.form}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                {translate("Settings")}
                            </Typography>
                        </Grid>
                        {asyncState.error && (
                            <Grid item xs={12}>
                                <Typography color="error">
                                    {asyncState.error}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {this.renderField("name")}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderField("description")}
                                </Grid>
                                <Grid item sm={6}>
                                    {this.renderField("start")}
                                </Grid>
                                <Grid item sm={6}>
                                    {this.renderField("end")}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={styles.actionButtons}>
                                <Button
                                    id={`${id}-cancel`}
                                    disabled={this.disabled}
                                    onClick={() => this.reset()}
                                >
                                    {translate("Cancel")}
                                </Button>
                                <Button
                                    id={`${id}-save`}
                                    disabled={!this.canSubmit}
                                    type="submit"
                                    color="primary"
                                >
                                    {translate("Save changes")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}
