// @flow

import { formatNumber } from "libphonenumber-js";
import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    withRouter,
    Switch,
    Route,
    Redirect,
    type RouterHistory,
    type Match,
    type Location,
} from "react-router";

import { type Profile } from "../redux/modules/user.d";
import { clear as logout } from "../redux/modules/user";

import { set as setLanguage } from "../redux/modules/language";

import { clear as clearStaffingTemplates } from "../redux/modules/staffing-templates";
import { clear as clearOrganisations } from "../redux/modules/organisations";
import { clear as clearEvents } from "../redux/modules/shows";
import { clear as clearAdmin } from "../redux/modules/admin";

import { type ItemType } from "../components/NavBar/NavBarItem.js";
import { Shell } from "../components/Authenticated";
import { ProfilePic } from "../components/NavBar";
import ExitIcon from "../components/icons/Exit";
import OrganisationsIcon from "../components/icons/Organisations";
import ShowIcon from "../components/icons/Show";
import KeyIcon from "../components/icons/Key";
import NotFound from "../components/NotFound";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";

import { withAcl } from "./Acl";
import { withTranslate } from "./Translate";

import Organisations from "./Organisations";
import Admin from "./Admin";
import Events from "./Events";
import StaffingTemplates from "./StaffingTemplates";
import { fromJS } from "immutable";

type Props = {
    acl: *,
    translate: *,
    profile: Profile,
    id: string,
    language: string,
    setLanguage: (language: string) => void,
    history: RouterHistory,
    match: Match,
    location: Location,
    logout: () => void,
    clearOrganisations: () => void,
    clearStaffingTemplates: () => void,
    clearEvents: () => void,
    clearAdmin: () => void,
};

const USER_NAME_MAX_LENGTH = 20;

class Authenticated extends PureComponent<Props> {
    /**
     * Get the user display name
     */
    get userName(): string {
        const {
            profile: { userFirstName, userLastName },
            id,
        } = this.props;

        const full =
            (userFirstName &&
                userLastName &&
                `${userFirstName} ${userLastName}`) ||
            formatNumber({ phone: id }, "National");

        if (full.length > USER_NAME_MAX_LENGTH) {
            return `${full.substr(0, USER_NAME_MAX_LENGTH - 1)}…`;
        }

        return full;
    }

    /**
     * Fetch main entries
     */
    get main(): ItemType[] {
        const {
            match,
            translate,
            acl,
            clearOrganisations,
            clearAdmin,
            clearStaffingTemplates,
        } = this.props;
        const entries = [];

        if (acl("organisations.read")) {
            entries.push({
                value: `${match.path}organisations/`,
                label: translate("Organisations"),
                icon: OrganisationsIcon,
                afterClick: clearOrganisations,
            });
        }

        //TODO: Translation key;

        if (acl("staffingTemplates.read")) {
            entries.push({
                value: `${match.path}staffing-templates/`,
                label: translate("Staffing Templates"),
                icon: PeopleAltOutlinedIcon,
                afterClick: clearStaffingTemplates,
            });
        }

        if (acl("events.read")) {
            entries.push({
                value: `${match.path}events/`,
                label: translate("Events & Shows"),
                icon: ShowIcon,
            });
        }

        if (acl("admin.read")) {
            entries.push({
                value: `${match.path}admin/`,
                label: translate("Admin"),
                icon: KeyIcon,
                afterClick: clearAdmin,
            });
        }

        return entries;
    }

    /**
     * Fetch bottom entries
     */
    get bottom(): ItemType[] {
        const { match, logout, profile, translate } = this.props;
        return [
            {
                value: `${match.path}profile/`,
                label: this.userName,
                icon: () => (
                    <ProfilePic image={profile.userProfilePicturePath} />
                ),
            },
            {
                value: `${match.path}logout/`,
                label: translate("Log out"),
                icon: ExitIcon,
                onClick: logout,
            },
        ];
    }
    /**
     * Render
     */
    render() {
        const {
            profile,
            history,
            match,
            location,
            language,
            translate,
            setLanguage,
            acl,
        } = this.props;

        return (
            <Shell
                profile={profile}
                history={history}
                match={match}
                language={language}
                setLanguage={setLanguage}
                location={location}
                main={this.main}
                bottom={this.bottom}
                translate={translate}
            >
                <Switch>
                    <Route path={`${match.path}organisations/`}>
                        {(acl("organisations.read") && <Organisations />) || (
                            <NotFound translate={translate} />
                        )}
                    </Route>
                    <Route path={`${match.path}staffing-templates/`}>
                        {(acl("staffingTemplates.read") && (
                            <StaffingTemplates />
                        )) || <NotFound translate={translate} />}
                    </Route>
                    <Route path={`${match.path}events/`}>
                        {(acl("events.read") && <Events />) || (
                            <NotFound translate={translate} />
                        )}
                    </Route>
                    <Route path={`${match.path}`} exact>
                        {this.main.length && (
                            <Redirect to={`${match.path}events/`} />
                        )}
                    </Route>

                    <Route path={`${match.path}admin/`}>
                        {(acl("admin.read") && <Admin />) || (
                            <NotFound translate={translate} />
                        )}
                    </Route>
                    <Route>
                        <h1>{translate("404: Page not found!")}</h1>
                    </Route>
                </Switch>
            </Shell>
        );
    }
}

export default withTranslate(
    withAcl(
        withRouter(
            connect(
                state => ({
                    language: state.language,
                    profile: state.user
                        .getIn(["data", "profile"], fromJS({}))
                        .toJS(),
                    id: state.user.get("id"),
                }),
                (dispatch: *) =>
                    bindActionCreators(
                        {
                            logout,
                            clearOrganisations,
                            clearStaffingTemplates,
                            clearEvents,
                            clearAdmin,
                            setLanguage,
                        },
                        dispatch,
                    ),
            )(Authenticated),
        ),
    ),
);
